<script lang="ts" setup>
import type { HTMLAttributes } from 'vue';
import { DropdownMenuTrigger, type DropdownMenuTriggerProps, useForwardProps } from 'radix-vue';

import { computed } from 'vue';

const props = withDefaults(
  defineProps<
    DropdownMenuTriggerProps & {
      class?: HTMLAttributes['class']
      variant?: 'default' | 'underline' | 'transparent' | 'icon'
      spacing?: string
    }
  >(),
  {
    class: '',
    variant: 'default',
    spacing: 'px-24 py-16',
  },
);

const delegatedProps = computed(() => {
  const { variant: _, spacing: __, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <DropdownMenuTrigger
    class="ui-menu-trigger"
    :class="[
      spacing,
      {
        'ui-trigger-default text-nowrap rounded border ': props.variant === 'default',
        'ui-trigger-underline position-relative': props.variant === 'underline',
        'border-0 bg-transparent shadow-none':
          props.variant === 'underline' || props.variant === 'transparent' || props.variant === 'icon',
        'ui-trigger-icon': props.variant === 'icon',
        'px-12 py-8': props.variant === 'underline' || (props.variant === 'transparent' && !props.spacing),
      },
    ]"
    v-bind="forwardedProps"
  >
    <slot />
  </DropdownMenuTrigger>
</template>

<style lang="scss" scoped>
.ui-menu-trigger {
  :deep(.icon),
  :deep(.mi) {
    transition: transform 0.2s ease-out;

    &.open {
      transform: rotateX(180deg);
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(var(--md-dark), 0.5);
  }

  &.ui-trigger-underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0.125rem;
    left: 0.5rem;
    right: 0.75rem;
    background-color: var(--md-primary);
    border-radius: 5rem;
  }

  &.ui-trigger-icon {
    color: var(--md-gray-500);

    &:hover {
      color: var(--md-primary);
    }
  }
}

[data-bs-theme='dark'] {
  .ui-menu-trigger.ui-trigger-icon:hover {
    color: var(--md-primary-dark);
  }
}
</style>
