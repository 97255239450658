<script lang="ts" setup>
import type { MenuItem } from '@/components/site/menu/TheSiteMainMenu.vue';

import NewBadge from '@/components/global/menu/parts/NewBadge.vue';
import useAppStore from '@/stores/appStore';
import useUserStore from '@/stores/userStore';
import axios from 'axios';
import { onMounted, ref } from 'vue';

defineOptions({
  name: 'TheMenuExtra',
});

const userStore = useUserStore();
const lastReadDate = computed(() => userStore.userLogged?.changelogReadAt || userStore.userLogged?.createdAt);

const { locale } = useI18n();

const newChangelogUnreadPosts = ref(0);

const menuItems = ref<MenuItem[]>([
  {
    id: 'menu-changelog',
    icon: 'weather-stars',
    label: 'menu.changelog',
    renderExtraContent: () => {
      if (newChangelogUnreadPosts.value) {
        return h(
          'span',
          { class: 'badge bg-primary rounded-xs text-light ms-auto lh-xl px-8 py-4' },
          newChangelogUnreadPosts.value,
        );
      } else {
        return null;
      }
    },
    ariaLabel: 'menu.changelog',
    class: 'router-link-parent-active',
    route: { name: 'changelog.index' },
    isNewFeature: false,
  },
]);

const appStore = useAppStore();
const status = computed(() => appStore.status);

async function loadPosts() {
  try {
    // Early return, ensure we have user and correct locale
    if (status.value !== 'loaded')
      return;

    const uri = import.meta.env.VITE_WORDPRESS_URI || 'https://modulards.com';

    const params: any = {
      lang: locale.value,
    };

    if (!!lastReadDate.value) {
      params.after = lastReadDate.value;
    }

    const response: ChangelogRequestResponse = await axios.head(`${uri}/wp-json/wp/v2/changelog`, {
      params,
    });

    newChangelogUnreadPosts.value = Number.parseInt(response.headers['x-wp-total']);
  } catch (error) {
    console.error('Error loading posts:', error);
  }
}

watch(status, async (value) => {
  if (value === 'loaded') {
    await loadPosts();
  }
});

onMounted(() => {
  loadPosts();
});
</script>

<template>
  <div class="sidebar-nav pt-8">
    <ul id="menu-extra" class="nav billing-nav pb-24">
      <li v-for="item in menuItems" :id="item.id" :key="item.id" class="nav-item" role="presentation">
        <router-link :aria-label="$t(item.ariaLabel)" :class="item.class" :to="item.route" class="nav-link py-12">
          <m-icon v-if="!!item.icon" :icon="item.icon" aria-hidden="true" />
          <span class="text-sm">{{ $t(item.label) }}</span>

          <template v-if="!!item.renderExtraContent">
            <component :is="item.renderExtraContent()" />
          </template>

          <NewBadge v-if="item?.isNewFeature" />
        </router-link>
      </li>
    </ul>
  </div>
</template>
