<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
  name: 'MLoader',
});

const props = withDefaults(
  defineProps<{
    size?: 'sm' | 'md'
    animateOnMount?: boolean
  }>(),
  {
    size: 'md',
    animateOnMount: true,
  },
);

const show = defineModel({
  type: Boolean,
  required: false,
});

const computedBoxClasses = computed(() => {
  return `md-svg-box-${props.size}`;
});

const computedSize = computed(() => (props.size === 'sm' ? '60px' : '80px'));

const svgLoader = computed(() =>
  (`<svg height="${computedSize.value}" version="1.1" viewBox="0 0 96 96" width="${computedSize.value}" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g transform=""><g transform=""><path d="m48-5.6693e-7c-26.51-3.1612e-7 -48 21.49-48 48-3.1612e-7 26.51 21.49 48 48 48 26.51 0 48-21.49 48-48 0-26.51-21.49-48-48-48z"/><path d="m36 28.801v33.6c0 2.651-2.1499 4.8008-4.8008 4.8008-2.651 0-4.7988-2.1498-4.7988-4.8008v-24c0-5.3019 4.2976-9.5996 9.5996-9.5996z"/><path d="m60 28.799c5.3019 0 9.5996 4.2997 9.5996 9.6016v24c0 2.651-2.1479 4.7988-4.7988 4.7988-2.651 0-4.8008-2.1478-4.8008-4.7988z"/><path d="m48 19.201c2.651 0 4.8008 2.1479 4.8008 4.7988v28.801c0 2.651-2.1498 4.7988-4.8008 4.7988-2.6509 0-4.8008-2.1478-4.8008-4.7988v-28.801c0-2.6509 2.1499-4.7988 4.8008-4.7988z"/><circle class="md-loader-circle" cx="48" cy="48" r="45"/></g></g></svg>`));
</script>

<template>
  <div v-if="show" :class="{ 'md-loader-animated': animateOnMount }" class="md-loader">
    <div :class="computedBoxClasses" class="md-svg-box">
      <span v-dompurify-html="svgLoader" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.md-loader {
  position: fixed;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  overflow: hidden;
  transition: opacity 0.1s ease-out;

  .md-svg-box {
    position: relative;

    &.md-svg-box-md {
      width: 80px;
      height: 80px;

      svg {
        width: 80px;
        height: 80px;
      }
    }

    &.md-svg-box-sm {
      width: 60px;
      height: 60px;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  :deep(svg) {
    display: block;
    border-radius: 50%;

    path {
      overflow: hidden;
      opacity: 1;
    }

    path:nth-child(1) {
      fill: #610bef;
    }

    path:nth-child(2) {
      fill: #fcfcfc;
    }

    path:nth-child(3) {
      fill: #fcfcfc;
    }

    path:nth-child(4) {
      fill: #fcfcfc;
    }

    .md-loader-circle {
      fill: none;
      stroke: #fcfcfc;
      stroke-width: 4;
      stroke-linecap: round;
      transform-origin: center;
      animation: spin 2s linear infinite,
      dash 1.5s ease-in-out infinite;
    }
  }

  &.md-loader-animated :deep(svg) {
    path {
      opacity: 0;
      animation: fadeIn 0.6s forwards;
    }

    path:nth-child(1) {
      animation-delay: 0s;
    }

    path:nth-child(2) {
      animation-delay: 0.125s;
      transform-origin: center;
      transform: translateY(10px);
    }

    path:nth-child(3) {
      animation-delay: 0.2s;
      transform: translateY(15px);
    }

    path:nth-child(4) {
      animation-delay: 0.1s;
      transform-origin: center;
      transform: scale(0.8);
    }

    .md-loader-circle {
      animation-delay: 0.6s;
      opacity: 0;
      animation: fade 0.5s ease-out 1s forwards,
      spin 2s linear infinite,
      dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0%,
    100% {
      stroke-dasharray: 50, 200;
      stroke-dashoffset: -109;
    }
    50% {
      stroke-dasharray: 20, 293;
      stroke-dashoffset: -131.5;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
}

[data-bs-theme='dark'] {
  .md-loader {
    .md-svg-box :deep(svg) path {
      color: #fcfcfc;
    }

    :deep(svg) path:nth-child(1) {
      fill: #a996ff;
    }
  }
}
</style>
