import type {
  ManualReportIndexRequest,
  ReportItemUpdateRequest,
} from '@/api/services/site/service/report/manual/index';
import type { ScheduledReportNextRequestParams } from '@/api/services/site/service/report/schedule';
import type { PaginationResponse, SimpleResponse } from '@/types/Api';
import type { SiteReportItemTypeEnumKeys } from '@/types/enums/SiteReportItemTypeEnum';
import type { SiteReportItem } from '@/types/models/site/service/report/item/SiteReportItem';

import type { BulkSiteManualReport, SiteReport } from '@/types/models/site/service/report/SiteReport';
import { apiEndpoints } from '@/types/routes/routes';

import { getReportSendStatus, getReportStatus } from '@/views/site/reports/utils/reportFunctions';
import { AbstractService } from '@modular/sdk';

export default class ManualReportService extends AbstractService {
  /**
   * Retrieves all Manual Reports for the current site.
   *
   * @param {ManualReportIndexRequest} params
   * @returns {Promise<PaginationResponse<SiteReport>>}  A promise that resolves to a paginated response containing the manual reports.
   */
  public async all(params: ManualReportIndexRequest): Promise<PaginationResponse<BulkSiteManualReport>> {
    const reportsPaginated = await this.request({
      method: apiEndpoints.siteReportsIndex.method,
      url: this.buildPath(apiEndpoints.siteReportsIndex),
      params,
      options: {
        paginate: true,
      },
    });

    reportsPaginated.data.forEach((report: BulkSiteManualReport) => {
      report.bulkCheck = false;
      report.statusMapped = getReportStatus(report.status);

      if (!!report.notifications && report.notifications.length > 0) {
        report.sendStatusMapped = getReportSendStatus(report.notifications);
      }
    });

    return reportsPaginated;
  }

  /**
   * Retrieves a specific Manual Report.
   *
   * @param {number} reportId
   * @returns {Promise<SiteReport>} A promise that resolves to the retrieved manual report.
   */
  public async retrieve(reportId: number): Promise<BulkSiteManualReport> {
    const report = await this.request({
      method: apiEndpoints.siteReportsShow.method,
      url: this.buildPath(apiEndpoints.siteReportsShow, {
        site_report: reportId,
      }),
    });

    report.statusMapped = getReportStatus(report.status);

    if (!!report.notifications && report.notifications.length > 0) {
      report.sendStatusMapped = getReportSendStatus(report.notifications);
    }

    return report;
  }

  /**
   * Creates a new Manual Report
   *
   * @param {number} siteId
   * @returns {Promise<SiteReport>} A promise that resolves to the created manual report.
   */
  public create(siteId: number): Promise<SiteReport> {
    return this.request({
      method: apiEndpoints.siteReportsStore.method,
      url: this.buildPath(apiEndpoints.siteReportsStore),
      data: {
        site: siteId,
      },
    });
  }

  /**
   * Updates a specific Manual Report.
   *
   * @param {number} reportId
   * @param {Record<string, any>} data
   * @returns {Promise<SiteReport>} A promise that resolves to the updated manual report.
   */
  public update(reportId: number, data: Record<string, any>): Promise<SiteReport> {
    return this.request({
      method: apiEndpoints.siteReportsUpdate.method,
      url: this.buildPath(apiEndpoints.siteReportsUpdate, {
        site_report: reportId,
      }),
      data,
    });
  }

  /**
   * Generates a specific Manual Report.
   *
   * @param {number} reportId
   * @returns {Promise<SiteReport>} A promise that resolves to the generated manual report.
   */
  public generate(reportId: number): Promise<SiteReport> {
    return this.request({
      method: apiEndpoints.siteReportsGenerate.method,
      url: this.buildPath(apiEndpoints.siteReportsGenerate, {
        site_report: reportId,
      }),
    });
  }

  /**
   * Replicates a Manual Report into a Schedule Report.
   *
   * @param {number} reportId
   * @param {ScheduledReportNextRequestParams} data
   * @returns {Promise<SiteReport>} A promise that resolves to the replicated manual report.
   */
  public async replicate(reportId: number, data: ScheduledReportNextRequestParams): Promise<BulkSiteManualReport> {
    const report = await this.request({
      method: apiEndpoints.siteReportsReplicateService.method,
      url: this.buildPath(apiEndpoints.siteReportsReplicateService, {
        site_report: reportId,
      }),
      data,
    });

    report.statusMapped = getReportStatus(report.status);

    if (!!report.notifications && report.notifications.length > 0) {
      report.sendStatusMapped = getReportSendStatus(report.notifications);
    }

    return report;
  }

  /**
   * Deletes specific Manual Reports.
   *
   * @param {number[]} reportIds
   * @returns {Promise<SimpleResponse>} A promise that resolves to a simple response indicating the result of the deletion.
   */
  public delete(reportIds: number[]): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.siteReportsDestroy.method,
      url: this.buildPath(apiEndpoints.siteReportsDestroy),
      params: {
        reports: reportIds,
      },
    });
  }

  /**
   * Updates a specific Manual Report Item.
   *
   * @param {number} reportId
   * @param {SiteReportItemTypeEnumKeys} type
   * @param {ReportItemUpdateRequest} data
   * @returns {Promise<SiteReport>} A promise that resolves to the updated manual report item.
   */
  public updateItem(
    reportId: number,
    type: SiteReportItemTypeEnumKeys,
    data: ReportItemUpdateRequest,
  ): Promise<SiteReport> {
    return this.request({
      method: apiEndpoints.siteReportsItemsUpdate.method,
      url: this.buildPath(apiEndpoints.siteReportsItemsUpdate, {
        report: reportId,
        type,
      }),
      data,
    });
  }

  /**
   * Toggles the status of a specific item in a Site Service Report.
   *
   * @param {number} reportId
   * @param {SiteReportItemTypeEnumKeys} type
   * @returns {Promise<SiteReportItem>} A promise that resolves to the toggled manual report item.
   */
  public toggleItemStatus(reportId: number, type: SiteReportItemTypeEnumKeys): Promise<SiteReportItem> {
    return this.request({
      method: apiEndpoints.siteReportsItemsStatusToggle.method,
      url: this.buildPath(apiEndpoints.siteReportsItemsStatusToggle, {
        report: reportId,
        type,
      }),
    });
  }
}
