<script lang="ts" setup>
import type { HTMLAttributes } from 'vue';
import DateHelper from '@/helpers/dateHelper';
import { computed } from 'vue';

defineOptions({
  name: 'VInputTime',
});

const props = withDefaults(
  defineProps<VInputTypeProps & {
    class?: HTMLAttributes['class']
  }>(),
  {
    class: '',
    label: '',
    labelOnTop: false,
    labelClass: '',
    wrapperClass: '',
    placeholder: '',
    errors: '',
    showPreview: false,
    size: '',
  },
);

interface VInputTypeProps {
  id: string
  label?: string
  labelOnTop?: boolean
  labelClass?: string
  wrapperClass?: string
  errors?: string
  placeholder?: string
  showPreview?: boolean
  size?: '' | 'sm' | 'lg'
}

const model = defineModel({
  type: [Number, null],
  default: () => 0,
});

const delegatedProps = computed(() => {
  const { class: _, label, labelOnTop, labelClass, wrapperClass, errors, size, ...delegated } = props;

  return delegated;
});

const computedClasses = computed(() => ({
  'form-control-sm': props.size === 'sm',
  'form-control-lg': props.size === 'lg',
  'is-invalid': !!props.errors,
}));

const internalValue = ref('00:00');
const lastValidValue = ref('00:00');

const padTime = (num: number) => num.toString().padStart(2, '0');

function formatTime(minutesTotal: number) {
  if (isNaN(minutesTotal) || minutesTotal < 0)
    return '';

  const hours = Math.floor(minutesTotal / 60);
  const minutes = minutesTotal % 60;

  return `${padTime(hours)}:${padTime(minutes)}`;
}

function parseTime(time: string) {
  const [hoursStr, minutesStr] = time.split(':');
  const hours = Number.parseInt(hoursStr) || 0;
  const minutes = Number.parseInt(minutesStr) || 0;
  return hours * 60 + minutes;
}

const isValidMinutes = (minutes: number) => minutes >= 0 && minutes < 60;

const previewText = ref('');

function validateTime(time: string) {
  const cleanedTime = time.replace(/[,.'";-]/g, ':');

  const isValidFormat = /^(\d{1,6}):(\d{1,2})(?::(\d{1,2}))?$/.test(cleanedTime);

  const isSingleNumber = /^\d$/.test(cleanedTime);
  const isTwoDigits = /^\d{2}$/.test(cleanedTime);
  const isThreeDigits = /^\d{3}$/.test(cleanedTime);
  const isFourDigits = /^\d{4}$/.test(cleanedTime);

  if (isValidFormat) {
    const [hours, minutes] = cleanedTime.split(':').map(Number);

    if (isValidMinutes(minutes)) {
      return formatTime(hours * 60 + minutes);
    }
  } else if (isSingleNumber) {
    const hours = Number(cleanedTime);

    return formatTime(hours * 60);
  } else if (isTwoDigits) {
    const minutes = Number(cleanedTime);

    if (isValidMinutes(minutes)) {
      return formatTime(minutes);
    } else {
      const hours = Number(cleanedTime.charAt(0));
      const minutes = Number(cleanedTime.slice(1));

      if (isValidMinutes(minutes)) {
        return formatTime(hours * 60 + minutes);
      }
    }
  } else if (isThreeDigits) {
    const hours = Number(cleanedTime.charAt(0));
    const minutes = Number(cleanedTime.slice(1));

    if (isValidMinutes(minutes)) {
      return formatTime(hours * 60 + minutes);
    }
  } else if (isFourDigits) {
    const hours = Number(cleanedTime.slice(0, 2));
    const minutes = Number(cleanedTime.slice(2));

    if (isValidMinutes(minutes)) {
      return formatTime(hours * 60 + minutes);
    }
  } else if (cleanedTime.length > 4) {
    const hours = Number(cleanedTime.slice(0, -2));
    const minutes = Number(cleanedTime.slice(-2));

    if (isValidMinutes(minutes)) {
      return formatTime(hours * 60 + minutes);
    }
  }

  return '';
}

function onBlur() {
  const validatedTime = validateTime(internalValue.value);

  if (validatedTime) {
    model.value = parseTime(validatedTime);

    internalValue.value = validatedTime;
    previewText.value = DateHelper.parseMinutesToHoursAndMinutes(model.value);
  } else if (!internalValue.value) {
    model.value = 0
  } else {
    internalValue.value = formatTime(Number(model.value));
  }
}

function onInput(event: Event) {
  const target = event.target as HTMLInputElement;
  let newValue = target.value;

  newValue = newValue.replace(/[,.'";-]/g, ':');

  const validatedTime = validateTime(newValue);

  if (validatedTime) {
    const minutes = parseTime(validatedTime);
    lastValidValue.value = validatedTime;
    internalValue.value = newValue;
    previewText.value = DateHelper.parseMinutesToHoursAndMinutes(minutes);
  } else {
    previewText.value = '';
    internalValue.value = newValue;
  }
}

function onTab(event: KeyboardEvent) {
  const validatedTime = validateTime(internalValue.value);

  if (!validatedTime) {
    event.preventDefault();
    onBlur();
  }
}

watch(
  () => model.value,
  (newVal) => {
    if (newVal !== null && newVal !== undefined && !isNaN(Number(newVal))) {
      const formattedTime = formatTime(Number(newVal));

      internalValue.value = formattedTime;
      lastValidValue.value = formattedTime;
      previewText.value = DateHelper.parseMinutesToHoursAndMinutes(Number(newVal));
    } else {
      internalValue.value = '00:00';
      lastValidValue.value = '00:00';
      previewText.value = '';
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="form-group text-sm" :class="wrapperClass">
    <label class="form-label mb-0" :class="[labelClass]" :for="id">
      <span v-if="labelOnTop && !!label" class="d-block mb-8">
        {{ label }}
      </span>

      <span :class="{ 'd-flex': showPreview }">
        <input
          v-model="internalValue"
          :class="computedClasses"
          class="form-control"
          v-bind="delegatedProps"
          @input="onInput"
          @blur="onBlur"
          @keyup.enter="onBlur"
          @keydown.tab="onTab"
        >

        <template v-if="showPreview">
          <span class="d-inline-flex align-items-center text-gray-600 text-xs text-nowrap ms-8">{{ previewText }}</span>
        </template>
      </span>

      <span v-if="!labelOnTop && !!label" class="form-label" :class="[labelClass]">
        {{ label }}
      </span>
    </label>
    <span v-if="!!errors" class="invalid-feedback">
      {{ errors }}
    </span>
  </div>
</template>
