const strings = {
  userCanUpdateData: 'me.update.data',
  userCanManualBackup: 'organization.backup.manual.store',
  userCanEnable2FA: 'organization.user.2fa',
  userCanEnableSSL: 'check.ssl.enabled',
  userCanEnableBackupExclusionFiles: 'organization.backup.exclusion.files',
  userCanEnableBackupExclusionDatabase: 'organization.backup.exclusion.database',
  userCanUseScheduledReports: 'organization.sites.report.schedule.allowed',
  userCanShowSiteHealth: 'health-services.show',
  userCanRefreshSiteHealth: 'health-services.refresh',
  userCanShowVulnerabilities: 'team.site.service.vulnerability.show',
  userCanInstallComponents: 'site-items.install',
  userCanInstallIa: 'site-items.install.ia',
  errorStatusForBackupRetry: ['failed_in_processing', 'failed_partial', 'failed'],
  userCanUpdateWhiteLabel: 'manager.white-label.update',
  userCanCreateEmailDomains: 'manager.domain.store',
  errorMaxAttemptsForBackupRetry: 4,
  whiteLabelStatusEnabled: 'enabled',
  whiteLabelStatusDisabled: 'disabled',
  defaultSendingAddress: 'no-reply@modulards.com',
};

const opinions = [
  {
    name: 'Jaime Gármar',
    opinion: 'jaimeGarmar',
    image: 'jaime-garmar.jpg',
    showOnEnglish: true,
  },
  {
    name: 'Marta Torre',
    opinion: 'martaTorre',
    image: 'marta-torre.jpg',
    showOnEnglish: false,
  },
  {
    name: 'Pablo Moratinos',
    opinion: 'pabloMoratinos',
    image: 'pablo-moratinos.jpg',
    showOnEnglish: true,
  },
  {
    name: 'Sebastián Echeverri',
    opinion: 'sebastianEchevarri',
    image: 'sebastian-echevarri.jpg',
    showOnEnglish: true,
  },
];
const faqs = [
  {
    title: 'faq1Title',
    content: 'faq1Content',
  },
  {
    title: 'faq2Title',
    content: 'faq2Content',
  },
  {
    title: 'faq3Title',
    content: 'faq3Content',
  },
  {
    title: 'faq4Title',
    content: 'faq4Content',
  },
  {
    title: 'faq5Title',
    content: 'faq5Content',
  },
  {
    title: 'faq6Title',
    content: 'faq6Content',
  },
  {
    title: 'faq7Title',
    content: 'faq7Content',
  },
];

export interface FeatureKeys {
  free: string[]
  pro: string[]
  earlyAdopter: string[]
  starter: string[]
  business: string[]
  appsumo_tier_1: string[]
  appsumo_tier_2: string[]
  appsumo_tier_3: string[]
  appsumo_tier_4: string[]
  appsumo_tier_5: string[]
};

const featureKeys: FeatureKeys = {
  free: [
    'upTo5Websites',
    'uptimeMonitorEvery15Minutes',
    'emailNotifications',
    'reportsClients',
    'basicSecurityAnalysis',
    'backupsEveryMonth',
    'storage10GB',
  ],
  pro: [
    'unlimitedWebsites',
    'uptimeMonitorEvery2Minutes',
    'emailAndSmsNotifications',
    'whiteLabelReports',
    'scheduledReports',
    'proSecurityAnalysis',
    'backupsEvery24h',
    'instantBackups',
    'storage100GB',
    'twoFactorAuthentication',
    'prioritySupportViaChatAndEmail',
  ],
  earlyAdopter: [
    'unlimitedWebsites',
    'uptimeMonitorEvery2Minutes',
    'emailAndSmsNotifications',
    'whiteLabelReports',
    'scheduledReports',
    'proSecurityAnalysis',
    'backupsEvery24h',
    'instantBackups',
    'storage100GB',
    'twoFactorAuthentication',
    'prioritySupportViaChatAndEmail',
  ],
  starter: [
    'upTo50Websites',
    'uptimeMonitorEvery5Minutes',
    'emailAndSmsNotifications',
    'whiteLabelReports',
    'scheduledReports',
    'proSecurityAnalysis',
    'backupsEvery24h',
    'instantBackups',
    'storage100GB',
    'twoFactorAuthentication',
    'prioritySupportViaChatAndEmail',
  ],
  business: [
    'unlimitedWebsites',
    'uptimeMonitorEvery2Minutes',
    'emailAndSmsNotifications',
    'whiteLabelReports',
    'scheduledReports',
    'proSecurityAnalysis',
    'backupsEvery24h',
    'instantBackups',
    'storage250GB',
    'twoFactorAuthentication',
    'prioritySupportViaChatAndEmail',
    'whiteLabelPlugin',
  ],
  appsumo_tier_1: [
    'upTo7Websites',
    'storage35GB',
    'user1',
    'uptimeMonitorEvery5Minutes',
  ],
  appsumo_tier_2: [
    'upTo15Websites',
    'storage70GB',
    'user1',
    'uptimeMonitorEvery5Minutes',
  ],
  appsumo_tier_3: [
    'upTo30Websites',
    'storage110GB',
    'user2',
    'uptimeMonitorEvery2Minutes',
    'whiteLabelPlugin',
  ],
  appsumo_tier_4: [
    'upTo50Websites',
    'storage175GB',
    'user3',
    'uptimeMonitorEvery2Minutes',
    'whiteLabelPlugin',
  ],
  appsumo_tier_5: [
    'upTo90Websites',
    'storage250GB',
    'user5',
    'uptimeMonitorEvery2Minutes',
    'whiteLabelPlugin',
  ],
};

const config = {
  updaterReloadTimeout: 300000,
  connectorMinVersionConst: '1.2.0',
};

const metrics = {
  metricsRouteCallbackKey: 'connect-account-association',
};

export { config, faqs, featureKeys, metrics, opinions, strings };
export default strings;
