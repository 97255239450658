import type { SimpleResponse } from '@/types/Api';
import type {
  PaginatedTags,
  Tag,
  TagPayload,
  TagsRequestsFilters,
  TagsRequestsParams,
} from '@/types/models/organization/tag/Tag';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class TagsService extends AbstractService {
  /**
   * Retrieves all Tags
   *
   * @returns {Promise<Tag>}
   */
  public all(params?: TagsRequestsParams): Promise<PaginatedTags | Tag[]> {
    const filters: TagsRequestsFilters = {};

    if (!!params) {
      if (params.order)
        filters.order = params.order;
      if (params.page)
        filters.page = params.page;
      if (params.per_page)
        filters.per_page = params.per_page;
      if (params.append)
        filters.append = params.append;
    }

    const options = {
      cancellable: !!params && params.options?.cancelKey || false,
      cancelKey: !!params && params.options?.cancelKey || 'organizationTagsKey',
      paginate: !!params && params.options?.paginate || false,
    };

    return this.request({
      method: apiEndpoints.tagsIndex.method,
      url: this.buildPath(apiEndpoints.tagsIndex),
      params: filters,
      options: options as any,
    });
  }

  /**
   * Creates a new Tag
   *
   * @param {TagPayload} data
   * @returns {Promise<Tag>}
   */
  public create(data: TagPayload): Promise<Tag> {
    return this.request({
      method: apiEndpoints.tagsStore.method,
      url: this.buildPath(apiEndpoints.tagsStore),
      data,
    });
  }

  /**
   * Updated the specified Tag
   *
   * @param {number} tag
   * @param {TagPayload} data
   *
   * @returns {Promise<Tag>}
   */
  public update(tag: number, data: TagPayload): Promise<Tag> {
    return this.request({
      method: apiEndpoints.tagsUpdate.method,
      url: this.buildPath(apiEndpoints.tagsUpdate, { tag }),
      data,
    });
  }

  /**
   * Deletes a specified Tag
   *
   * @param {number} tag
   *
   * @returns {Promise<SimpleResponse>}
   */
  public delete(tag: number): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.tagsDestroy.method,
      url: this.buildPath(apiEndpoints.tagsDestroy, { tag }),
    });
  }
}
