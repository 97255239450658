import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class SiteManagerService extends AbstractService {
  /**
   * Retrieves a URL to accessing site WordPress page
   *
   * @returns {Promise<{redirectUri: string}>}
   * @param siteId
   */
  public loginInWordpress(siteId: number): Promise<{ redirectUri: string }> {
    return this.request({
      method: apiEndpoints.sitesManagerLogin.method,
      url: this.buildPath(apiEndpoints.sitesManagerLogin, {
        site: siteId,
      }),
    });
  }
}
