/* eslint-disable regexp/no-unused-capturing-group */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export default function initDatadogRUM() {
  const isEnabled = import.meta.env.VITE_DATADOG_RUM_ENABLED || false;

  if (isEnabled === 'false')
    return;

  // eslint-disable-next-line regexp/no-dupe-disjunctions
  const botPattern = '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';

  const regex = new RegExp(botPattern, 'i');
  const conditionalSampleRate = regex.test(navigator.userAgent) ? 0 : 100;

  datadogRum.init({
    applicationId: import.meta.env.VITE_DATAGOG_RUM_ID,
    clientToken: import.meta.env.VITE_DATAGOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'modular',
    env: import.meta.env.VITE_NODE_ENV === 'production' ? 'prod' : 'staging', // <prod|staging>
    version: import.meta.env.VITE_CI_VERSION || import.meta.env.VITE_APP_VERSION, // From Gitlab CI
    sessionSampleRate: conditionalSampleRate,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: ['https://api.modulards.com', 'https://api.staging.modulards.com'],
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event, context) => {
      return !(event.type === 'error' && (event.error.message.includes('499') || (context?.error as any)?.status === 499));
    },
  });

  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATAGOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'modular',
    env: import.meta.env.VITE_NODE_ENV === 'production' ? 'prod' : 'staging', // <prod|staging>
    version: import.meta.env.VITE_CI_VERSION || import.meta.env.VITE_APP_VERSION, // From Gitlab CI
    sessionSampleRate: conditionalSampleRate,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    storeContextsAcrossPages: true,
    beforeSend: (event, context: any) => {
      return !event.message.includes('499') || context?.isAborted;
    },
  });
}
