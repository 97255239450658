import type { SimpleResponse } from '@/types/Api';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class NoteService extends AbstractService {
  /**
   * Creates or updates the note of the site.
   *
   * @param siteId
   * @param {Record<string, string>} data
   * @returns {Promise<SimpleResponse>}
   */
  public update(siteId: number, data?: { note: string }): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.siteNotesUpdate.method,
      url: this.buildPath(apiEndpoints.siteNotesUpdate, {
        site: siteId,
      }),
      data,
    });
  }
}
