import type { PaginationResponse, SimpleResponse } from '@/types/Api';
import type { SiteTask, SiteTaskMapped } from '@/types/models/site/task/SiteTask';
import { AbstractService } from '@modular/sdk';
import { apiEndpoints } from '@/types/routes/routes';
import type { TaskIndexRequest, TaskStoreRequest, TaskUpdateRequest } from '@/api/services/site/service/report/task/index';

export default class TaskService extends AbstractService {
  /**
   * Retrieves all Tasks for the current site.
   *
   * @param {TaskIndexRequest} params
   * @param {boolean} map
   * @returns {Promise<PaginationResponse<SiteTask | SiteTaskMapped>>} A promise that resolves to a paginated response containing the tasks.
   */
  public async all(params: TaskIndexRequest, map: boolean): Promise<PaginationResponse<SiteTask> | PaginationResponse<SiteTaskMapped>> {
    const response = await this.request({
      method: apiEndpoints.tasksIndex.method,
      url: this.buildPath(apiEndpoints.tasksIndex),
      params,
      options: {
        paginate: true,
      },
    });

    if (map) {
      response.data.forEach((task: SiteTaskMapped) => {
        task.expanded = false;
        task.bulkCheck = false;
      });
    }

    return response;
  }

  /**
   * Retrieves a specific Task for the current site.
   *
   * @param {number} task
   * @returns {Promise<SiteTaskMapped>} A promise that resolves to the task.
   */
  public retrieve(task: number): Promise<SiteTaskMapped> {
    return this.request({
      method: apiEndpoints.tasksShow.method,
      url: this.buildPath(apiEndpoints.tasksShow, {
        task,
      }),
    });
  }

  /**
   * Creates a new Task for the current site.
   *
   * @param {TaskStoreRequest} data
   * @returns {Promise<SiteTask>} A promise that resolves to the created task.
   */
  public create(data: TaskStoreRequest): Promise<SiteTask> {
    return this.request({
      method: apiEndpoints.tasksStore.method,
      url: this.buildPath(apiEndpoints.tasksStore),
      data,
    });
  }

  /**
   * Updates a specific Task for the current site.
   *
   * @param {number} task
   * @param {TaskUpdateRequest} data
   * @returns {Promise<SiteTask>} A promise that resolves to the updated task.
   */
  public update(task: number, data: TaskUpdateRequest): Promise<SiteTask> {
    return this.request({
      method: apiEndpoints.tasksUpdate.method,
      url: this.buildPath(apiEndpoints.tasksUpdate, {
        task,
      }),
      data,
    });
  }

  /**
   * Deletes a specific Task for the current site.
   *
   * @param {number[]} taskIds
   * @returns {Promise<SimpleResponse>} A promise that resolves to a simple response.
   */
  public delete(taskIds: number[]): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.tasksDestroy.method,
      url: this.buildPath(apiEndpoints.tasksDestroy),
      data: {
        tasks: taskIds,
      },
    });
  }
}
