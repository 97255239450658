export default {
  fromPriceInterval: 'Desde <span class="d-flex align-items-center h4 lh-lg mb-0">{price}€</span>/<span class="text-lowercase">{interval}</span>',
  shared: {
    getAPlan: 'Consigue tu plan',
    getAPlanPrice: 'Contratar desde 29 €/mes',
    includes: 'Incluye',
  },
  plan: {
    choosePlanText:
      'Nuestros planes PRO crecen contigo, para ayudarte a llevar tus servicios de mantenimiento web al siguiente nivel. Sin complicaciones.',
    continueBilling: 'Continuar con el pago',
    confirmText:
      'Tu subscripción se renovará automáticamente según lo establecido y se cobrará el importe del plan que hayas seleccionado. Puedes cancelar en cualquier momento antes del siguiente período de facturación.',
    hasEverything: 'Lo tiene todo',
    saveAnnualPayment: 'Ahorra {percentage}% con un pago anual',
    yourPlanName: 'Tu Plan: {name}',
    selectTheBestPlan: 'Elige el plan que mejor se adapta a las necesidades de tu negocio.',
    eachYearPayment: 'Al mes, pagando anualmente',
    eachMonthPayment: 'Al mes, pagando mensualmente',
    contractedPlan: 'Plan contratado',
    currentPlan: 'Plan actual',
    selectedPlan: 'Plan seleccionado',
    startingAt: 'Desde',
    noPlan: 'No tienes un plan contratado',
    nonePlan: 'Ningún plan contratado',
    myPlan: 'Plan y facturación',
    myPlanAppSumo: 'Plan',
    saveUpTo: 'Ahorra hasta {percent}%',
    saveUp: 'Ahorra {percent}%',
    yearlyPayment: 'Pago Anual',
    yearly: 'El pago se realiza una vez al año',
    monthlyPayment: 'Mes a mes',
    monthly: 'El pago se realiza mes a mes',
    paymentEvery: 'Pago cada {interval}',
    paymentOnlyOnce: 'Solo la pagas la primera vez',
    initialFee: 'Cuota inicial',
    initialFeePrice: 'Cuota inicial: {price}',
    byYearlyDiscount: 'Descuento plan anual',
    payNow: 'Total',
    choosePlan: 'Elige el plan que mejor se adapta a tu negocio',
    completeBuy: 'Completa tu compra',
    billingCycle: 'Selecciona un ciclo de facturación',
    paymentInfo: 'Datos de facturación',
    servicesIncluded: 'Servicios incluidos',
    addBillingInfo: 'Debes añadir los datos de facturación',
    editingBillingInfo: 'Debes guardar los datos de facturación',
    summary: 'Resumen',
    confirmingPurchase: 'Confirmando pago',
    mostPopular: 'Más popular',
    discountQuestion: '¿Tienes un código de descuento?',
    discountAnswer: 'Podrás aplicarlo en la siguiente página.',
    features: {
      upTo5Websites: 'Hasta 5 webs',
      upTo7Websites: 'Hasta 7 webs',
      upTo15Websites: 'Hasta 15 webs',
      upTo30Websites: 'Hasta 30 webs',
      upTo50Websites: 'Hasta 50 webs',
      upTo90Websites: 'Hasta 90 webs',
      unlimitedWebsites: 'Webs ilimitadas',
      uptimeMonitorEvery2Minutes: 'Uptime monitor cada 2 minutos',
      uptimeMonitorEvery5Minutes: 'Uptime monitor cada 5 minutos',
      uptimeMonitorEvery15Minutes: 'Uptime monitor cada 15 minutos',
      emailNotifications: 'Notificaciones por email',
      emailAndSmsNotifications: 'Notificaciones por email y SMS',
      whiteLabelReports: 'Reportes White Label',
      scheduledReports: 'Reportes programados',
      reportsClients: 'Reportes para clientes',
      proSecurityAnalysis: 'Análisis de seguridad PRO',
      basicSecurityAnalysis: 'Análisis de seguridad básico',
      backupsEvery24h: 'Copias de seguridad cada 24h',
      backupsEveryMonth: 'Copias de seguridad cada mes',
      instantBackups: 'Copias de seguridad instantáneas',
      storage10GB: '10GB de almacenamiento',
      storage35GB: '35GB de almacenamiento',
      storage70GB: '70GB de almacenamiento',
      storage100GB: '100GB de almacenamiento',
      storage110GB: '110GB de almacenamiento',
      storage175GB: '175GB de almacenamiento',
      storage250GB: '250GB de almacenamiento',
      twoFactorAuthentication: 'Autenticación 2FA',
      prioritySupportViaChatAndEmail: 'Soporte prioritario por chat y email',
      whiteLabelPlugin: 'White Label del plugin',
      user1: '1 usuario',
      user2: '2 usuarios',
      user3: '3 usuarios',
      user5: '5 usuarios',
    },
    seePlan: 'Ver mi plan',
    actual: 'Actual',
    saveOffPercent: 'Ahorra un {percent}%',
    withAnnualPayment: 'Con el pago anual',
    changeHere: 'Cámbialo aquí',
    concept: 'Concepto',
    renewalDate: 'F. Renovación',
    amount: 'Importe',
    planProratedCredit: 'Crédito prorrateado de {plan_name}',
    expirationDate: 'Caduca el {date}',
    cancelSubscription: 'Cancelar suscripción',
    cancelPlan: 'Cancelar Plan {plane_name}',
    subscriptionOptions: 'Opciones de la suscripción',
    cancelAlertTitle: 'Tus webs dejarán de estar disponibles',
    cancelAddonAlertTitle: 'Perderás tu paquete de GB extras',
    cancelAlertText: 'Al cancelar tu plan, puede que algunas de tus web dejen de estar disponibles a partir del {date}',
    cancelAddonAlertText:
      'Al cancelar, volverás al límite de GB original de tu plan de Modular y, en caso de superarlo, no se harán nuevas copias de seguridad hasta que tengas espacio disponible.',
    reactivate: 'Reactivar',
    toastResumeText: 'Se ha reactivado tu suscripción',
    cancelPlanHasAddonsModalTitle: 'Acción necesaria',
    cancelPlanHasAddonsModalAlertTitle: 'Cancela tu paquete de GB extras para eliminar tu plan',
    cancelPlanHasAddonsModalAlertText:
      'Debes cancelar primero tu paquete de GB extras. Una vez que lo hagas, podrás cancelar la suscripción a tu plan PRO.',
    reactiveAddonNotPlanModalTitle: 'Acción necesaria',
    reactiveAddonNotPlanModalAlertTitle: 'Activa tu plan para recuperar tu paquete de GB extras',
    reactiveAddonNotPlanModalAlertText:
      'Debes activar primero tu plan PRO. Una vez que lo hagas, podrás reactivar tu paquete de GB extras.',
    storage: {
      title: 'Almacenamiento ¡Que no falte!',
      subtitle:
        'Todos los planes de pago vienen con una cantidad de Gigas que puedes complementar en función de tus necesidades de almacenamiento.',
      adjustYourPlan: '¡Ajusta tu plan en función de lo que necesitas!',
    },
    included: 'Incluido',
    whatPlanInclude: '¿Qué incluye tu plan {plan}?',
    appsumo_tier_1: 'AppSumo Tier 1',
    appsumo_tier_2: 'AppSumo Tier 2',
    appsumo_tier_3: 'AppSumo Tier 3',
    appsumo_tier_4: 'AppSumo Tier 4',
    appsumo_tier_5: 'AppSumo Tier 5',
  },
  discount: {
    forever: 'para siempre',
    months: 'durante {months} meses',
    percent: 'Descuento del {percent}%',
    amount: 'Descuento de {amount}{unit}',
  },
  stripeStatus: {
    draft: 'Borrador',
    open: 'Pendiente de Pago',
    paid: 'Pagado',
    void: 'Cancelado',
    uncollectible: 'Deuda Incobrable',
  },
  sidebar: {
    becomeAPro: 'Hazte PRO',
    sidebartext: 'Descubre cómo llevar tu mantenimiento web al siguiente nivel',
    totalPlan: 'Total plan',
    creditCard: 'Tarjeta de crédito',
    getAPlanDescription: 'Contrata un plan para poder publicar webs ilimitadas con ModularDS',
    getAPlanButton: 'Hazte PRO por 29 €/mes',
    planName: 'Plan {plan_name}',
    extraStorage: 'Almacenamiento extra',
    notIncludeVAT: '*Los precios no incluyen IVA',
    commercialDiscount: 'Descuento comercial',
  },
  team: {
    billingData: 'Datos de facturación',
    addBilling: 'Añadir datos de facturación',
    addPayment: 'Añadir método de pago',
    paymentAdded: 'Método de pago añadido',
    noPaymentAdded: 'No hay un método de pago añadido',
    cardNumber: 'Número de tarjeta',
    cardDate: 'F. Caducidad',
    cardCode: 'CVC',
    city: 'Población',
    postalCode: 'Zip/Código postal',
    province: 'Estado/Provincia/Región',
    vat: 'VAT',
    direction1: 'Dirección de facturación 1',
    direction2: 'Dirección de facturación 2',
    bussinessName: 'Nombre o Razón social',
    nif: 'NIF / VAT / IVA Number',
    managePlan: 'Gestionar plan',
    noBillingData: 'No hay datos de facturación añadidos',
    paymentMethod: 'Medio de pago',
    editBillingData: 'Editar datos de facturación',
    saveBillingData: 'Guardar datos de facturación',
  },
  invoices: {
    lastInvoices: 'Últimas facturas',
    number: 'Nº',
    concept: 'Concepto',
    amount: 'Importe',
    downloadInvoice: 'Descargar factura',
    status: 'Estado',
  },
  freeState: {
    free: 'Gratuito',
    viewPlan: 'Ver plan',
    yourProgress: 'Tu progreso',
    newbie: 'Newbie',
    planFreeProgress: 'Te queda sólo 1 web para agotar tu plan FREE | Te quedan {number} webs para agotar tu plan FREE',
    proReviews: 'Lo que dicen los <span class="fw-semi">PRO</span>',
    qaProTitle: '¿Para qué hacerse <span class="fw-semi">PRO</span>?',
    cofounderText:
      '“Queremos ayudar a los profesionales y agencias de WordPress a ahorrar tiempo en la gestión de sus webs y ganar más contratos recurrentes. Con una herramienta donde centralizar todos sus proyectos y tareas de mantenimiento y demostrar el valor de su trabajo diario a sus clientes. Para que estos puedan entenderlo y estén dispuestos a pagar por él.”',
    hectorDePradaCofounder: 'Héctor de Prada, Cofundador de Modular DS',
    planAppSumoProgress: 'Te queda sólo 1 web para alcanzar tu límite de webs conectadas | Te quedan {number} webs para alcanzar tu límite de webs conectadas',
  },
  checkout: {
    paymentInProgressTitle: 'Estamos realizando el pago',
    paymentInProgressText: 'El pago se está procesando...',
    successPayment: 'Se ha realizado el pago con éxito',
    addExtraSpacing: 'Paquetes de almacenamiento',
    customPricing: 'A medida',
    customPricingPrice: 'desde 49,90 €/mes',
    modal: {
      planTitle: '¿Seguro que no quieres subir de nivel?',
      planText:
        'Si completas la contratación podrás conectar más webs a Modular, disfrutar de más almacenamiento y funcionalidades extras para ayudarte con el mantenimiento.',
      addonTitle: '¿Seguro que no quieres esos GB extras?',
      addonText: 'Piensa en lo bien que te vendrían para poder alojar todas las copias de seguridad de tus webs.',
      forTimeNot: 'Por ahora no',
      continuePurchase: 'Continuar mi compra',
    },
  },
};
