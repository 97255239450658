export default {
  mainNavigation: 'Main navigation',
  home: 'Home',
  recents: 'Recents',
  mySites: 'My websites',
  drafts: 'Drafts',
  design: 'Website design',
  designAndThemes: 'Design and Templates',
  logoAndColors: 'Logo, colors...',
  overview: 'Overview',
  analytics: 'Analytics',
  domain: 'Domain',
  billingManagement: 'Management and Administration',
  plains: 'Plan and billing',
  admin: 'Manager',
  recruitmentLinks: 'Recruitment links',
  usersPanel: 'Users panel',
  uptimeMonitor: 'Uptime Monitor',
  backups: 'Backups',
  reports: 'Reports',
  updates: 'Updates',
  healthSafety: 'Health & Safety',
  metrics: 'Metrics',
  searchConsole: 'Search Console',
  metricsAriaLabel: 'Toggle metrics navigation',
  toggleTeamAriaLabel: 'Toggle team navigation',
  settings: 'Settings',
  changelog: 'Changelog',
  siteManagement: 'Site management',
  siteSettings: 'Site settings',
  alerting: 'Notifications',
  wpManagement: 'Updates & manager',
};
