import { Concept } from '@/types/models/Concept';

export type SiteItemStatusEnumKeys = 'active' | 'inactive' | 'uninstalled';

export const SiteItemStatusEnumConst = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  UNINSTALLED: 'uninstalled',
} as const;

export const SiteItemStatusEnum: Record<SiteItemStatusEnumKeys, Concept> = {
  'active': {
    key: 'active',
    value: null,
    name: 'concept.site.item.status.activeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.item.status'
  },
  'inactive': {
    key: 'inactive',
    value: null,
    name: 'concept.site.item.status.inactiveName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.item.status'
  },
  'uninstalled': {
    key: 'uninstalled',
    value: null,
    name: 'concept.site.item.status.uninstalledName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.item.status'
  }
} as const;

export type SiteItemStatusEnumType = (typeof SiteItemStatusEnumConst)[keyof typeof SiteItemStatusEnumConst];

export const SiteItemStatusEnumArray = Object.values(SiteItemStatusEnum);
