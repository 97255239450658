<script lang="ts" setup>
import type { RouteRecord } from 'vue-router';
import { changeLanguage, getAnyValidLanguage } from '@/helpers/langHelper';
import { useRoute } from 'vue-router';

defineOptions({
  name: 'VAuthLayout',
});

const route = useRoute();

const typeLayout = computed(() => {
  const currentRoute = route.matched.find((routeAux: RouteRecord) => routeAux.name === route.name);

  if (!!currentRoute && !!currentRoute.meta.typeLayout) {
    return currentRoute.meta.typeLayout;
  }

  return 'fixed-container';
});

onMounted(() => {
  const firstLang = getAnyValidLanguage();

  if (firstLang) {
    changeLanguage(firstLang);
  }

  // Force dissapear loader on loggin out and auth reload
  const loader = document.querySelector('#md-main-loader') as HTMLElement;

  if (!!loader) {
    loader.style.opacity = '0';

    setTimeout(() => {
      loader.style.display = 'none';
    }, 100);
  }
});
</script>

<template>
  <main :class="typeLayout" class="position-relative">
    <div :class="typeLayout === 'fixed-container' ? 'container' : 'container-fluid px-0'">
      <div
        :class="{ ' py-48': typeLayout === 'fixed-container' }"
        class="auth-container min-vh-100"
      >
        <div
          :class="{ 'pt-lg-64 px-sm-48 px-lg-64 px-24 pb-32 pt-48': typeLayout === 'fixed-container' }"
          class="auth-content"
        >
          <template v-if="typeLayout === 'fixed-container'">
            <div id="login-logo" class="logo mx-auto mb-16">
              <v-picture class="d-flex justify-content-center">
                <img
                  :alt="$t('general.shared.appLogoAlt')"
                  src="@assets/img/modular-purple-logo.png"
                  srcset="@assets/img/modular-purple-logo.png 2x, @assets/img/modular-purple-logo.png 1x"
                >
              </v-picture>
            </div>

            <router-view v-slot="{ Component }">
              <Transition appear mode="out-in" name="fade-in">
                <component :is="Component" />
              </Transition>
            </router-view>
          </template>

          <router-view v-else v-slot="{ Component }">
            <Transition appear mode="out-in" name="fade-in">
              <component :is="Component" />
            </Transition>
          </router-view>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.fixed-container {
  background-color: var(--md-primary);

  .auth-container {
    display: grid;
    place-items: center;

    .auth-content {
      background-color: var(--md-gray-100);
      border-radius: 1rem;
      width: 100%;

      @media (width >= 575px) {
        width: 28.5rem;
      }

      #login-logo {
        max-width: 175px;
      }

      .section-text,
      .remember-section,
      .info-text {
        text-align: left;
      }

      .form-floating,
      .form-group {
        .form-control,
        label {
          font-size: 1rem;
        }

        .form-control {
          &:not(.is-invalid) {
            background: var(--md-gray-300);
            border-color: var(--md-gray-300);
          }
        }
      }

      .form-check {
        .form-check-input {
          border-radius: 1rem;
        }
      }

      .info-text {
        color: var(--md-gray-500);
      }
    }
  }
}
</style>
