/**
 * Encodes an object containing values or arrays into a URL-encoded query string.
 * @param {Record<string, any>} data - The object containing data to be encoded into query parameters.
 * @returns {string} A URL-encoded string representing the query parameters.
 */
function encodeQueryData(data: any) {
  const ret: string[] = [];

  Object.keys(data).forEach((d) => {
    const value: any[] | any = data[d];

    if (Array.isArray(value)) {
      value.forEach((val) => {
        ret.push(`${encodeURIComponent(d)}[]=${encodeURIComponent(val)}`);
      });
    } else {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  });

  return ret.join('&');
}

/**
 * Deeply merges two objects, with properties from the second object overwriting those of the first.
 * @param {Record<string, any>} obj1 - The base object.
 * @param {Record<string, any>} obj2 - The object to merge into the base object.
 * @returns {Record<string, any>} The resulting object after merging.
 */
function mergeObjects(obj1: any, obj2: any) {
  const keys = Object.keys(obj2);
  let nextObj = { ...obj1 };

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = obj2[key];
    if (typeof value === 'object' && value !== null) {
      nextObj = { ...nextObj, ...mergeObjects(nextObj, value) };
    } else {
      nextObj = {
        ...nextObj,
        [key]: value,
      };
    }
  }

  return nextObj;
}

/**
 * Converts a file size in bytes into a human-readable format.
 * @param {number|string} size - The size in bytes.
 * @returns {string} The size formatted as a string with appropriate units.
 */
function getHummanSize(size: any) {
  size = Number.parseInt(size);
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (size === 0) {
    return `0 ${units[1]}`;
  }

  let i = 0;

  for (i; size > 1024; i++) {
    size /= 1024;
  }

  return `${size.toFixed(2)} ${units[i]}`;
}

function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key]))
      return false;
  }

  return true;
}

export default {
  encodeQueryData,
  mergeObjects,
  getHummanSize,
  deepEqual,
};

export { deepEqual, encodeQueryData, getHummanSize, mergeObjects };
