<script lang="ts" setup>
import useUptime from '@/composables/uptime/useUptime';
import useUptimeFunctions from '@/composables/uptime/useUptimeFunctions';

import usePermissions from '@/composables/usePermissions';
import { useUptimeStore } from '@/stores/site/uptime/uptimeStore';
import { ChangeStatusEnumConst } from '@/types/enums/ChangeStatusEnum';
import { DateTime, Interval } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { PermissionEnumConst } from '@/types/PermissionEnum';
import type { SiteServiceUptime } from '@/types/models/site/service/uptime/SiteServiceUptime';
import useToggleServiceStatus from '@/composables/services/useToggleServiceStatus';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import { SiteServiceStatusEnumConst } from '@/types/enums/SiteServiceStatusEnum';

defineOptions({
  name: 'TheSiteOverviewUptimeActivatedState',
});

const uptimeStore = useUptimeStore();

const { can } = usePermissions();

const userCanUptimeServiceUpdate = computed(() => can(PermissionEnumConst.UPTIME_SERVICES_UPDATE));

const uptimeServiceStatus = ref(false);
const uptimeLastEventsCollapse = ref(true);
const justDeactivatedUptime = ref(false);

const { uptimeService } = storeToRefs(uptimeStore);

const uptimeIsEnabled = computed(() => uptimeStore.uptimeIsEnabled);

const {
  uptimeChangeStatus,
  uptimeSslChangeStatus,
  isUptimeEnabled,
  isUptimeSslEnabled,
  parseDuration,
} = useUptimeFunctions(uptimeService.value);

const {
  enableService,
  disableService,
} = useToggleServiceStatus(ServiceTypeEnumConst.UPTIME);

async function handleToggleUptimeServiceStatus() {
  if (uptimeIsEnabled.value) {
    const disabledServiceResponse = await disableService<SiteServiceUptime>({
      title: 'site.uptime.shared.deactivateUptimeMonitorTitle',
      text: 'site.uptime.shared.deactivateUptimeMonitorText',
    });

    if (disabledServiceResponse) {
      uptimeStore.uptimeService = disabledServiceResponse;
    } else {
      // Means the service was not disabled
      uptimeServiceStatus.value = uptimeIsEnabled.value;
    }
  } else {
    uptimeStore.uptimeService = await enableService<SiteServiceUptime>();
  }
}

const currentChangeTotalTime = computed(() => {
  const now = DateTime.now();
  const start = DateTime.fromISO(uptimeService.value!.currentChange.startedAt);

  const diff = Interval.fromDateTimes(start, now);

  return diff.toDuration().shiftTo('day', 'hour', 'minute', 'second').toObject();
});

const { ACTIVE } = SiteServiceStatusEnumConst;

watchImmediate(
  () => uptimeStore.uptimeIsConfigured,
  (value) => {
    if (value) {
      uptimeServiceStatus.value = uptimeService.value?.status === ACTIVE
    } else {
      uptimeServiceStatus.value = false;
    }
  },
);
</script>

<template>
  <div id="the-site-overview-uptime-activated" data-cy="site-overview-uptime-activated">
    <div class="d-flex align-items-center justify-content-between mb-16">
      <div class="fw-semi text-gray-600">
        {{ $t('site.sidebar.uptimeMonitor') }}
      </div>

      <router-link
        v-if="userCanUptimeServiceUpdate"
        :aria-label="$t('general.button.configure')"
        :to="{ name: 'sites.site.uptime.check.store' }"
        class="btn btn-link btn-sm text-decoration-none fw-semi"
        data-cy="link-overview-uptime-configuration"
      >
        <span>{{ $t('general.button.configure') }}</span>
      </router-link>
    </div>

    <div class="uptime-status d-flex flex-column justify-content-between position-relative mb-16 pb-16">
      <div class="d-flex justify-content-between mb-16">
        <div v-if="!!uptimeChangeStatus" class="d-flex align-items-center">
          <v-icon v-if="justDeactivatedUptime" class="text-warning status-icon me-12" icon="pause" />
          <v-icon
            v-else
            :class="[uptimeChangeStatus.color, uptimeChangeStatus.class]"
            :icon="uptimeChangeStatus.icon"
            class="status-icon me-12"
          />

          <div v-if="!!uptimeService && !!uptimeService.currentChange" class="uptime-status-duration fw-medium text-xs">
            <span v-if="!!uptimeChangeStatus?.text" class="me-4">
              <template
                v-if="
                  justDeactivatedUptime
                    || (uptimeService?.currentChange?.status === 'unknown'
                      && !currentChangeTotalTime?.days
                      && !currentChangeTotalTime?.hours
                      && !currentChangeTotalTime?.minutes)
                "
              >
                {{ $t(`site.uptime.activity.paused`) }}
              </template>
              <template v-else>{{ $t(`${uptimeChangeStatus.text}`) }}</template>
            </span>

            <span v-else-if="!!uptimeService" class="me-4">
              {{ $t(`site.uptime.activity.${uptimeService.currentChange.status}`) }}
            </span>

            <span v-if="!!currentChangeTotalTime?.days && currentChangeTotalTime.days > 0" class="me-4">
              {{ `${currentChangeTotalTime.days}${$t('general.shared.days')}` }}
            </span>

            <span
              v-if="
                !!currentChangeTotalTime?.hours
                  && currentChangeTotalTime.hours > 0
                  && (!!currentChangeTotalTime?.days ? currentChangeTotalTime?.days <= 7 : true)
              "
              class="me-4"
            >
              {{ `${currentChangeTotalTime.hours}${$t('general.shared.hours')}` }}
            </span>

            <span
              v-if="
                !!currentChangeTotalTime?.minutes
                  && currentChangeTotalTime.minutes > 0
                  && (!!currentChangeTotalTime?.days ? currentChangeTotalTime?.days <= 7 : true)
              "
            >
              {{ `${currentChangeTotalTime.minutes}${$t('general.shared.minutes')}` }}
            </span>
          </div>

          <div v-else class="uptime-status-duration fw-medium text-sm">
            ---
          </div>
        </div>

        <v-input-switch
          v-if="userCanUptimeServiceUpdate"
          id="uptime-status-switch"
          v-model="uptimeServiceStatus"
          data-cy="site-overview-uptime-switch"
          false-color="text-danger"
          @click="handleToggleUptimeServiceStatus"
        />
      </div>

      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <v-icon class="ssl-icon" icon="pdf" size="md" />
          <span class="me-8">{{ $t('site.uptime.ssl.sslKeyName') }}</span>

          <template v-if="!!uptimeService && !!uptimeService.currentChangeSsl && isUptimeSslEnabled && isUptimeEnabled">
            <v-icon
              :class="[uptimeSslChangeStatus.color, uptimeSslChangeStatus.class]"
              :icon="uptimeSslChangeStatus.icon"
              class="me-4"
            />

            <span :class="[uptimeSslChangeStatus.color]" class="text-uppercase fw-medium text-sm">
              {{ uptimeService.currentChangeSsl?.status }}
            </span>
          </template>

          <span v-else class="text-success-dark">- ??</span>
        </div>

        <div
          v-if="!!uptimeService && !!uptimeService.currentChangeSsl && isUptimeSslEnabled && isUptimeEnabled"
          class="d-flex align-items-center fw-medium text-sm text-gray-500"
        >
          <v-icon icon="clock" size="md" space="me-4" />

          <span v-if="!uptimeSslChangeStatus.isExpired">
            {{ $t('site.uptime.shared.checkStatusSSLValid', { time: uptimeSslChangeStatus?.validCertificateDate }) }}
          </span>
          <span v-else>
            {{ $t('site.uptime.shared.checkStatusSSLInvalid', { time: uptimeSslChangeStatus?.validCertificateDate }) }}
          </span>
        </div>

        <div v-else class="uptime-status-duration fw-medium text-sm">
          ---
        </div>
      </div>
    </div>

    <div class="collapseLastUptimeEvents">
      <VButton
        aria-controls="collapseLastUptimeEvents"
        aria-expand="false"
        class="btn-icon justify-content-between btn-collapse w-100 collapsed mb-4 px-4 py-4 text-gray-500"
        data-bs-target="#collapseLastUptimeEvents"
        data-bs-toggle="collapse"
        variant="transparent"
        @click="uptimeLastEventsCollapse = !uptimeLastEventsCollapse"
      >
        <span class="fw-medium text-sm">{{ $t('site.sidebar.latestEvents') }}</span>
        <v-icon icon="chevron-down" />
      </VButton>

      <div id="collapseLastUptimeEvents" class="collapse">
        <div class="px-0 pb-0 pt-16">
          <div class="events mb-4">
            <div v-if="!uptimeService?.changes || uptimeService.changes.length === 0" class="text-sm">
              {{ $t('site.uptime.shared.checkNoChanges') }}
            </div>

            <template v-else>
              <div
                v-for="change in uptimeService.changes"
                :key="change.id"
                class="d-flex justify-content-between align-items-center mb-8"
              >
                <div
                  v-if="change.status === ChangeStatusEnumConst.UP"
                  :title="$t('report.uptime.activity.up')"
                  class="text-success fw-semi text-uppercase me-16 w-auto text-sm"
                >
                  <v-icon icon="arrow-up" />
                  <span class="position-absolute invisible">{{ $t('report.uptime.activity.up') }}</span>
                </div>

                <div
                  v-else-if="change.status === ChangeStatusEnumConst.DOWN"
                  :title="$t('report.uptime.activity.down')"
                  class="text-danger fw-semi text-uppercase me-16 w-auto text-sm"
                >
                  <v-icon icon="arrow-down" />
                  <span class="position-absolute invisible">{{ $t('report.uptime.activity.down') }}</span>
                </div>

                <div
                  v-else-if="change.status === ChangeStatusEnumConst.UNKNOWN"
                  :title="$t('report.uptime.activity.paused')"
                  class="text-warning fw-semi text-uppercase me-16 w-auto text-sm"
                >
                  <v-icon icon="pause" />
                  <span class="position-absolute invisible">{{ $t('report.uptime.activity.paused') }}</span>
                </div>

                <div
                  v-else
                  :title="$t('report.uptime.activity.unknown')"
                  class="fw-semi text-uppercase me-16 w-auto text-sm text-gray-600"
                >
                  <v-icon icon="question" />
                  <span class="position-absolute invisible">{{ $t('report.uptime.activity.unknown') }}</span>
                </div>

                <div
                  v-if="!!change?.startedAt || !!change?.endedAt"
                  class="fw-medium w-100 text-end text-xs text-gray-500"
                >
                  {{
                    $t('site.uptime.shared.checkDateDuration', {
                      start: $str.formatDateTime(change.startedAt, 'MM/dd/yyyy'),
                      duration: parseDuration(change.startedAt, change.endedAt ?? ''),
                    })
                  }}
                </div>
              </div>
            </template>
          </div>

          <router-link
            :aria-label="$t('general.button.viewAll')"
            :to="{ name: 'sites.site.uptime.check.stats.show' }"
            class="btn btn-link btn-sm text-decoration-none align-self-end fw-semi"
          >
            <span>{{ $t('general.button.viewAll') }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.uptime-status {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--md-gray-400);
  }

  .status-icon {
    &.icon-arrow-up {
      rotate: 45deg;
    }

    &.icon-arrow-up,
    &.icon-arrow-down,
    &.icon-question {
      padding: 0.5rem;
      border: 0.125rem solid;
      border-radius: 50rem;
    }

    &.icon-question {
      padding: 0.65rem;
    }

    &.icon-log-in-circle {
      font-size: 2.75rem;
    }
  }

  .uptime-status-duration::first-letter {
    text-transform: uppercase;
  }
}

.collapseLastUptimeEvents {
  .btn-collapse {
    .icon-chevron-down {
      fill: var(--md-gray-500);
      transition: transform 0.3s ease-out;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn-collapse[aria-expanded='true'] {
    .icon-chevron-down {
      transform: rotate(180deg);
    }
  }
}

.ssl-icon {
  margin-right: 2px;
}
</style>
