import type { SimpleResponse } from '@/types/Api';
import type { OrganizationDomain } from '@/types/models/organization/domain/OrganizationDomain';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class DomainService extends AbstractService {
  /**
   * Retrieves all the domains of the organization.
   *
   * @returns {Promise<OrganizationDomain[]>}
   */
  public all(): Promise<OrganizationDomain[]> {
    return this.request({
      method: apiEndpoints.domainsIndex.method,
      url: this.buildPath(apiEndpoints.domainsIndex),
    });
  }

  /**
   * Retrieves the specified domain.
   *
   * @param {number} domainId
   * @returns {Promise<OrganizationDomain>}
   */
  public retrieve(domainId: number): Promise<OrganizationDomain> {
    return this.request({
      method: apiEndpoints.domainsShow.method,
      url: this.buildPath(apiEndpoints.domainsShow, { domain: domainId }),
    });
  }

  /**
   * Verifies the status of the domain in the SES service.
   *
   * @param {number} domainId
   * @returns {Promise<OrganizationDomain>}
   */
  public verify(domainId: number): Promise<OrganizationDomain> {
    return this.request({
      method: apiEndpoints.domainsVerify.method,
      url: this.buildPath(apiEndpoints.domainsVerify, { domain: domainId }),
    });
  }

  /**
   * Creates a new domain for the organization.
   *
   * @param {{domain: string}} data
   * @returns {Promise<OrganizationDomain>}
   */
  public create(data: { domain: string }): Promise<OrganizationDomain> {
    return this.request({
      method: apiEndpoints.domainsStore.method,
      url: this.buildPath(apiEndpoints.domainsStore),
      data,
    });
  }

  /**
   * Toggles the default status of the specified domain.
   *
   * @param {number} domainId
   * @returns {Promise<OrganizationDomain>}
   */
  public select(domainId: number): Promise<OrganizationDomain> {
    return this.request({
      method: apiEndpoints.domainsToggleDefault.method,
      url: this.buildPath(apiEndpoints.domainsToggleDefault, { domain: domainId }),
    });
  }

  /**
   * Deletes the specified domain.
   *
   * @param {number} domainId
   * @returns {Promise<SimpleResponse>}
   */
  public delete(domainId: number): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.domainsDestroy.method,
      url: this.buildPath(apiEndpoints.domainsDestroy, { domain: domainId }),
    });
  }

  /**
   * Retrieves the default domain of the organization.
   *
   * @returns {Promise<OrganizationDomain>}
   */
  public default(): Promise<OrganizationDomain> {
    return this.request({
      method: apiEndpoints.domainsDefault.method,
      url: this.buildPath(apiEndpoints.domainsDefault),
    });
  }
}
