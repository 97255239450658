<script lang="ts" setup>
import { MProgress } from '@/components/ui/progress';
import { useDownloadStore } from '@/stores/downloadStore';
import { DownloadStatusConst } from '@/types/DownloadStatus';
import { CollapsibleRoot } from 'radix-vue';

defineOptions({
  name: 'TheDownloader',
});

const { PENDING, DOWNLOADING, ERROR, COMPRESSING } = DownloadStatusConst;

const downloadStore = useDownloadStore();

const {
  isDownloading,
  downloadingItems,
} = storeToRefs(downloadStore);

const downloadingItemsSorted = computed(() => {
  const items = Object.values(downloadingItems.value).flat();

  return items.sort((a, b) => {
    if (a.downloadProgress === 100 && b.downloadProgress !== 100)
      return -1;
    if (a.downloadProgress !== 100 && b.downloadProgress === 100)
      return 1;

    if (a.status === DOWNLOADING && b.status !== DOWNLOADING)
      return -1;
    if (a.status !== DOWNLOADING && b.status === DOWNLOADING)
      return 1;

    if (a.status === PENDING && b.status !== PENDING)
      return -1;
    if (a.status !== PENDING && b.status === PENDING)
      return 1;

    return 0;
  });
});

const isCompressing = computed(() => (downloadStore.isCompressing));

const calculateDownloadPercentage = computed(() => {
  if (!downloadingItems.value || Object.keys(downloadingItems.value).length === 0)
    return 0;

  const allItems = Object.values(downloadingItems.value).flat();

  if (allItems.length === 0)
    return 0;

  const totalProgress = allItems.reduce((acc, item) => acc + item.downloadProgress, 0);
  const totalItems = allItems.length;

  return Math.round(totalProgress / totalItems);
});

const collapsibleOpen = ref(false);

watch(isCompressing, (value, oldValue) => {
  if (!oldValue && value) {
    collapsibleOpen.value = false;
  }
});

function handleBeforeUnload(event: BeforeUnloadEvent) {
  if (isDownloading.value) {
    const message = 'Hay descargas en curso. ¿Seguro que quieres salir?';
    event.preventDefault();
    event.returnValue = message;

    return message;
  }
}

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>

<template>
  <Transition
    appear
    enter-active-class="slide-up-enter-active"
    enter-from-class="slide-up-enter-from"
    leave-active-class="slide-up-leave-active"
    leave-to-class="slide-up-leave-to"
    name="slide-up"
  >
    <v-alert id="the-downloader" classes="p-8" content-spacing="" variant="black" :show-icon="false">
      <CollapsibleRoot
        ref="collapsible"
        v-model:open="collapsibleOpen"
      >
        <CollapsibleContent class="download-collapsible fw-medium lh-xl text-sm text-gray-500">
          <ul v-auto-animate class="list-unstyled text-light px-8 pt-16">
            <li
              v-for="item in downloadingItemsSorted"
              :key="item.name"
              :class="{ 'opacity-75': item.status === PENDING }"
              class="md-download-item d-flex align-items-center mb-4 bg-transparent rounded-xs px-8 py-8"
            >
              <span>
                <v-icon v-if="item.status === ERROR" class="text-danger" icon="close-x" />
                <VDot v-else-if="item.status === PENDING" :delay="0" status="pending" />
                <span
                  v-else-if="item.status === DOWNLOADING && item.downloadProgress !== 100"
                >
                  {{ `${item.downloadProgress}%` }}
                </span>
                <span
                  v-else-if="item.status === COMPRESSING && item.compressProgress !== 100"
                >
                  {{ `C: ${item.compressProgress}%` }}
                </span>
                <v-icon v-else class="text-success" icon="check" />
              </span>

              <span class="ms-8">{{ item.name }}</span>
            </li>
          </ul>
        </CollapsibleContent>

        <CollapsibleTrigger
          class="md-download-trigger btn d-flex justify-content-between align-items-center w-100 fw-medium lh-xl border-0 p-8 text-sm shadow-none w-100 opacity-100"
        >
          <template v-if="isCompressing">
            {{ $t('general.download.compressing') }}
          </template>
          <template v-else-if="isDownloading">
            {{ $t('general.download.downloadingFiles', { num: Object.keys(downloadingItems).length }) }}
          </template>

          <div class="ms-auto" style="width: 100px;">
            <MProgress
              v-if="isDownloading"
              variant="secondary"
              :model-value="calculateDownloadPercentage"
            />
          </div>

          <v-icon
            :class="{ open: collapsibleOpen }"
            class="text-gray-500 ms-16"
            icon="chevron-up"
          />
        </CollapsibleTrigger>
      </CollapsibleRoot>
    </v-alert>
  </Transition>
</template>

<style lang="scss">
#the-downloader {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1060;
  width: 80%;
  max-width: 600px;

  @media (width <= 992px) {
    width: 90%;
  }

  @media (width <= 768px) {
    width: calc(100% - 2rem);
  }

  .download-collapsible {
    max-height: 18.75rem;
    overflow-y: auto;
  }

  .md-download-item {
    background-color: var(--md-dark);
  }

  .progress-bar {
    background-color: var(--md-primary);
  }

  .md-download-trigger {
    color: var(--md-light);
  }
}

[data-bs-theme='dark'] {
  #the-downloader {
    .md-download-item {
      color: var(--md-dark);
    }

    .md-download-trigger {
      color: var(--md-dark);
    }
  }
}
</style>
