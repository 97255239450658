export default {
  overview: 'Overview',
  errorContacts: 'You must add at least one recipient to continue',
  errorLogoFile: 'You must add a logo to continue',
  errorTitle: 'You must add a name to continue',
  reports: 'Reports',
  report: 'Report',
  newReport: 'New Report',
  createReport: 'Create report',
  reportsImage: 'Reports Image',
  makeWorkStandOut: 'Make your work stand out',
  makeWorkStandOutDescription:
    'Get the most out of your work by sending automatic reports to your clients with statistics, details of maintenance tasks and much more...',
  firstReport: 'Create your first report!',
  firstReportDescription:
    'Create a team to start building your websites with ModularDS. You can create as many work teams and websites as you need.',
  totalReports: '{total} Reports',
  totalReportsSent: 'Sent ({total})',
  totalReportsPendingShipment: 'Pending Shipment ({total})',
  sent: 'Sent',
  draft: 'Draft',
  reportPeriod: 'Report period',
  statusAndActions: 'Status and actions',
  status: 'Status',
  sending: 'Sending',
  send: 'Send',
  searchReport: 'Search report',
  searchTask: 'Search task',
  generalConfiguration: 'General configuration',
  generalConfigurationDescription: 'Name, customization...',
  content: 'Content',
  contentDescription: 'Select what it includes',
  reportingShipping: 'Report and sending',
  reportingShippingDescription: 'PDF, sending, schedule...',
  reportName: 'Report name',
  language: 'Language',
  selectLanguage: 'Select a language',
  dates: 'Dates',
  lastWeek: 'Last week',
  lastQuarter: 'Last Quarter',
  customize: 'Customize',
  template: 'Template',
  included: 'Included',
  logo: 'Logo of your client',
  logoRecommendedSize:
    'Add the client\'s website logo to appear in the center of the front page (recommended size 800x800px).',
  addImage: 'Add Image',
  changeImage: 'Change Image',
  addImageDescription: 'or drag and drop image here',
  mainColor: 'Main Color',
  mainColorDescription:
    'Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra. Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra',
  secondaryColor: 'Secondary Color',
  secondaryColorDescription:
    'Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra. Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra',
  offBranding: 'Customize the report with your logo',
  offBrandingDescription:
    'Add your logo to replace the “Built with Modular” branding on report pages (recommended size 800x800px).',
  offBrandingEmptyStateTitle: 'Custom Branding',
  offBrandingEmptyStateDescription:
    'Go PRO to add your logo and replace the “Built with Modular” branding on your report pages.',
  welcomeTitle: 'Welcome message',
  welcomeDescription: 'Write the welcome message here for your client to read on the first page of the report.',
  closingTitle: 'Final message',
  closingDescription:
    'Write the conclusions or closing that you want to appear at the end of the report for your client.',
  messageContent: 'Message content',
  introductionReportTitle: 'Write the title of the introduction',
  introductionReportContent: 'Add a short explanatory text for this section of the report',
  introductionReportAnalyticsLogo: 'Include Google Analytics logo',
  introductionReportSearchLogo: 'Include Google Search Console logo',
  closingReportTitle: 'Write the closing title',
  closingReportContent: 'Enter a closing report message',
  summaryAndClosing: 'Summary and Closing',
  notes: 'Annotations',
  notesOrAnnexesText:
    'Have you done other work that you have not created as tasks? Do you want to add something for your client? Here you can write what you need to appear in the report.',
  addTask: 'Additional tasks',
  addTaskDescriptionScheduled:
    'By activating this option, additional tasks that you have entered in the "Additional tasks" section on the reports page and that correspond to the specific dates or period to be covered by the report will be included.',
  addTaskDescriptionScheduled2Line:
    'Including additional tasks allows you to add value to your customer-facing work and to communicate more accurately all those things you do on a day-to-day basis for your customers that are not always visible.',
  addTaskDescriptionManual:
    'Activating this option will include in the report the tasks that you have entered in the "Additional tasks" section and that match the dates marked in the report',
  addTaskNotTask:
    'You have no additional tasks in the selected period. For more information on additional tasks <a href="https://support.modulards.com/en/task-manager-reports" id="task-help" target="_blank" aria-label="Open task support" rel="noreferrer noopener">click here</a>.',
  viewTasks: 'View tasks ({count})',
  selectTheSections: 'Select the sections you want in your report',
  performance: 'Performance',
  uptime: 'Activate Uptime monitor',
  uptimeText: 'Monitor the status of your website and receive alerts when it is down.',
  analytics: 'Analytics',
  updates: 'Updates',
  annotationTitle: 'Annotation title',
  addAnnotation: 'Add Annotation',
  unverified: 'Unverified',
  pendingConnection: 'Pending connection',
  pendingEnableFeature: 'Pending to activate',
  verified: 'Verified',
  sectionIntroductionTitle: 'Section Introduction:',
  taskIntroductionTitle: 'Message to client',
  taskIntroductionText: 'Enter a description of the work done',
  backups: {
    noBackupsShort: 'Configure backups',
    noBackupsConfigure:
      'Set up recurring backups for this website choosing what to include, recurrence, time of the day, etc…',
  },
  customWork: {
    addWork: 'Add Annotation',
    hoursSpent: 'Hours spent',
    addImages: 'Attach images',
  },
  emptyState: {
    emptyYTLink: 'https://www.youtube.com/embed/GcXGD668JpM',
    reportDescription:
      'Show your clients the value of your work by generating reports that collect all the tasks and data from their website. Updates made, backups, Uptime summary, Google Analytics statistics and performance summary.',
    reportTitle1: 'A complete and versatile report',
    reportReason1:
      'Choose which sections you want to show in the report and which not and in just a couple of minutes we will generate all the information regarding the web and its tasks and statistics.',
    reportTitle2: 'Customize it with your brand',
    reportReason2:
      'Add your logo and your client\'s logo to the Modular template and customize the report to make it feel 100% yours.',
    reportTitle3: 'Add any extra tasks',
    reportReason3:
      'With the additional tasks module you can add any task you have done including name, description, date, hours used and even screenshots.',
    reportTitle4: 'View in PDF or web format',
    reportReason4:
      'With Modular you can generate your reports in PDF and send the file to your clients or send them directly a link so they can see it in web format. So that you stand out from the competition.',
    taskPretitle:
      'Tasks allow you to share with your clients specific and punctual actions for the maintenance of their websites',
    taskPretitleDescription: 'Create as many tasks as actions or maintenance activities you need.',
  },
  deleteReportTitle: 'Do you want to delete this report? | Do you want to delete all selected reports?',
  deleteReportText:
    'If you delete this report from Modular you will not be able to access it from the tool again. We recommend you download it beforehand so you don\'s lose it. | If you delete these reports from Modular you will not be able to access them again from the tool. We recommend that you download them beforehand to avoid losing them.',
  deleteReportScheduleTitle: 'Do you want to delete this schedule?',
  deleteReportScheduleText:
    'If you delete this report schedule from Modular you will no longer be able to access it from the tool.',
  deleteReportCompleted: 'The report has been deleted successfully',
  deleteReportScheduleCompleted: 'The schedule has been deleted successfully',
  finalSection: {
    yourReport: 'Your report',
    yourReportText:
      'Here is your web report. You can download it in PDF or view and share it in web format so that anyone can see it without downloading it.',
    recipientsTitle: 'Recipients',
    recipientsDescription: 'Who you want to send the report to.',
    sendDetailsTitle: 'Sending details',
    sendDetailsText:
      'Choose if you want the report to be sent automatically every time it is generated.',
    sendMessageTitle: 'Message',
    sendMessageText: 'Type the name of the sender of the email, the subject and the text you want to include.',
    viewReport: 'View report',
    sendNow: 'Send now',
    scheduleSend: 'Schedule report/auto send',
    emailFrom: 'Sender\'s name',
    emailFromPlaceholder: 'Sender',
    emailSubject: 'Email subject',
    emailSubjectPlaceholder: 'Subject',
    emailBody: 'Message body',
    emailBodyPlaceholder: 'Enter the content of the message you want to send',
    emailBodyPdf: 'Send PDF file as attachment',
    notReadyReport: 'Looks like your report isn\'s ready yet...',
    notReadyReportDescription:
      'This process may take a few minutes. If you go out you can check the status from the list of reports.',
    reportErrorStatusTitle: 'There was an error generating your report',
    reportErrorStatusText:
      'Some of the report sections could not be created. You can try generating a new report or contact us at <a href=\'mailto:{url}\' aria-label=\'Contact by email\'>{url}</a> so we can help you resolve it. .',
    smallPreviewReport: 'This is a small preview of your report',
    noSend: 'Don\'t send',
    sendEmail: 'Send by email',
    addRecipients: 'Add recipients',
    alertMessage:
      'Indicate where you want the link to the report to appear using: [report_link]<br/>If you don\'t add it or don\'t check the PDF attachment option, it will appear at the end of the body of the message.',
    generateReportSend: 'Schedule report',
    errorEmailSubject: 'The email subject is required',
    errorEmailMessage: 'The email message is required',
    sendingAddress: 'Address from which you are sending',
    sendingAddressDescription: 'The e-mail address that will be shown as the sender of the emails.',
    sendingAddressText:
      'To send emails from a custom domain, you need to add and verify one in the general settings of your account.',
    responseAddress: 'Reply address',
    responseAddressDescription: 'Address to which recipients can reply.',
    goSettingsDomain: 'Go to settings to change the domain',
  },
  generateReport: 'Generate report',
  scheduledReport: 'Schedule report',
  generateReportDescription: 'Get your report',
  generateScheduledReportDescription: 'Sending, recipients...',
  errorScheduleRange: 'You must choose a date range to continue',
  errorScheduleHour: 'You must choose a time range to continue',
  errorContentEmpty: 'You must select at least one item to continue',
  generating: {
    almostReady: 'Your report is almost ready!',
    savingChanges: 'Saving your changes!',
    generatingReport: 'We are generating the report...',
    generated: 'Your report has been generated!',
    scheduled: 'Report scheduled!',
    generatedDescription:
      'Here you have the report of your website. You can download it in PDF or view it and share it in web format so that anyone can see it without downloading it.',
    generateScheduleWeekly: 'Your report will be generated every week on { day }, starting { date }.',
    generateScheduleMonthly: 'Your report will be generated every month on { day }, starting { date }.',
    generateScheduleDaily: 'Your report will be generated every day at { time }, starting { date }.',
    repeatEach: 'Repeat each',
    saveAsScheduledReport: 'Save as a scheduled report',
    convertReportRecurring: 'Convert to scheduled report',
  },
  upcomingReports: {
    title: 'Scheduled reports',
    newReport: 'Create scheduled report',
    scheduleRecurrenceWeekly: 'Every week on { time }',
    scheduleRecurrenceDaily: 'Every day at { time }',
    scheduleRecurrenceMonthly: 'Every month on day { time }',
    scheduleRecurrenceQuarterly: 'Every quarter',
    knowMoreAboutScheduledReports: 'Learn more about scheduled reports',
    knowMoreAboutScheduledReportsLink: 'https://support.modulards.com/en/scheduled-reports',
    emptyState: {
      title: 'Create your first scheduled report',
      description:
        'Clients who receive scheduled reports understand your work better and <span class="text-primary fw-medium">are more loyal.</span>',
    },
  },
  preview: {
    yourReport: 'Your report',
    editReport: 'Edit report',
    shareReport: 'Share report',
  },
  yourReports: 'Your reports',
  stillNoReports: 'No report has been generated yet.',
  recurring: 'Scheduled',
  monthly: 'Monthly',
  weekly: 'Weekly',
  annual: 'Annual',
  noRecurrence: 'Not scheduled',
  generatedLastYear: 'Generated in the last year',
  generated: 'Generated',
  generateds: 'Generated',
  recurringReport: 'Scheduled Report',
  recurringReportDescription: 'You can schedule it to be generated on a daily, weekly or quarterly basis.',
  timelyReport: 'Timely Report',
  timelyReportDescription:
    'Once created, it is generated only once.<br/>It will not be generated on a scheduled basis.',
  generatingTitle: 'generating',
  becomeAPro: 'Become PRO here',
  reportDates: 'Report dates',
  reportData: 'Report data',
  reportFrequencyDescription: 'The period of each report will be from { dayStart } at 00:00 to { dayEnd } at 23:59',
  scheduleRecurrenceQuarterly: 'Every 3 months starting on',
  updateReport: 'Update report',
  quarterShort: '(Q{ number })',
  thisWeek: 'This week',
  custom: 'Custom',
  additionalTasks: 'Additional tasks',
  creatingReport: 'Creating a report',
  editingReport: 'Editing report',
  previewReport: 'Preview report',
  downloadReport: 'Download report',
  deleteReport: 'Delete report',
  error: 'error',
  schedule: {
    reportScheduleFrecuencyWeeklyCalc:
      'It will be generated every week on {day}. Date of next report: {date} at {hour}.',
    reportScheduleFrecuencyMonthlyCalc: 'It will be generated every month. Date of next report: {date} at {hour}.',
    reportScheduleFrecuencyQuarterlyCalc: 'It will be generated every quarter. Date of next report: {date} at {hour}.',
  },
  tasks: {
    task: 'Task',
    tasks: 'Additional tasks',
    createAdditionalTask: 'Create additional task',
    editAdditionalTask: 'Edit additional task',
    successCreate: 'Successfully created task',
    successEdit: 'Successfully edited task',
    introductionReportContent: 'Introduce an explanation of the work done',
    createTask: 'Create task',
    tasksCreated: 'Created tasks ({ count })',
    taskCreatedDate: 'Creation date',
    taskDate: 'Task date',
    hours: 'Hours',
    deleteTaskTitle: 'Do you want to delete this task | Do you want to delete all selected tasks?',
    deleteTaskText:
      'If you delete this task from Modular, you will not be able to access it again. | If you delete these tasks from Modular, you will no longer be able to access them.',
    deleteTaskCompleted: 'The task has been successfully deleted',
  },
  details: {
    reportDetails: 'Report details',
    creation: 'Creation',
    recurrence: 'Recurrence',
    sendingDetails: 'Sending details',
    recipients: 'Recipients',
    convertReportScheduled: 'Convert report to scheduled',
    reportSections: 'Sections contained in the report',
    attemptSend: 'Attempt to send { date }',
    overview: 'Overview',
    analytics: 'Statistics',
    performance: 'Performance',
    upgrade: 'Upgrades',
    uptime: 'Uptime Monitor',
    backup: 'Backups',
    task: 'Additional tasks',
    sent: 'Sent',
    received: 'Received',
    opened: 'Opened {date}',
    error: 'Delivery failure',
    saveRecurrence: 'Save recurrence',
    searchConsole: 'Search Console',
  },
};
