export default {
  backup: {
    schedule: {
      frequency: {
        dailyName: 'Diario',
        weeklyName: 'Semanal',
        monthlyName: 'Mensual',
        weekly: {
          day: {
            weeks: {
              mondayName: 'Lunes',
              tuesdayName: 'Martes',
              wednesdayName: 'Miércoles',
              thursdayName: 'Jueves',
              fridayName: 'Viernes',
              saturdayName: 'Sábado',
              sundayName: 'Domingo',
            },
          },
        },
      },
    },
    retention: {
      period: {
        monthly: {
          '45Name': '45 días',
        },
        weekly: {
          '14Name': '2 semanas',
          '30Name': '30 días',
          '45Name': '45 días',
        },
        daily: {
          '3Name': '3 días',
          '7Name': '1 semana',
          '14Name': '2 semanas',
          '30Name': '30 días',
          '45Name': '45 días',
        },
      },
    },
  },
  user: {
    language: {
      esName: 'Español',
      enName: 'Inglés',
    },
  },
  site: {
    report: {
      status: {
        draftName: 'Borrador',
        inProgressName: 'Generando',
        doneName: 'Generado',
        errorName: 'Error',
        partialErrorName: 'Generación parcial',
      },
      language: {
        esName: 'Español',
        enName: 'Inglés',
        caName: 'Catalán',
        frName: 'Francés',
        ptName: 'Portugués',
        fiName: 'Finlandés',
        deName: 'Alemán',
      },
    },
    service: {
      status: {
        activeName: 'Activo',
        inactiveName: 'Inactivo',
      },
      report: {
        schedule: {
          frequency: {
            weeklyName: 'Semanal',
            monthlyName: 'Mensual',
            quarterlyName: 'Trimestral',
          },
        },
      },
    },
    status: {
      pendingName: 'Estamos preparando el proceso de conexión...',
      reachedLoginPageName: 'Estamos preparando el proceso de conexión...',

      loginInName: 'Vamos allá, estableciendo conexión con tu web...',

      loggedInName: '¡Conexión establecida! Vamos a instalar el plugin Modular Connector...',
      reachedSecondaryActionRequiredName: '¡Conexión establecida! Vamos a instalar el plugin Modular Connector...',
      reachedInstallationPageName: '¡Conexión establecida! Vamos a instalar el plugin Modular Connector...',
      installingPluginName: '¡Conexión establecida! Vamos a instalar el plugin Modular Connector...',

      pluginInstalledName: 'Hemos instalado correctamente Modular Connector, ya queda poco...',
      pluginReplacedName: 'Hemos instalado correctamente Modular Connector, ya queda poco...',
      searchingPluginUpgradeName: 'Hemos instalado correctamente Modular Connector, ya queda poco...',
      pluginUpgradingName: 'Hemos instalado correctamente Modular Connector, ya queda poco...',
      pluginUpgradedName: 'Hemos instalado correctamente Modular Connector, ya queda poco...',
      reachedActivationPageName: 'Hemos instalado correctamente Modular Connector, ya queda poco...',
      activatingPluginName: 'Hemos instalado correctamente Modular Connector, ya queda poco...',

      pluginActivatedName: '¡Plugin activado! Estamos comprobando la conexión...',
      reachedCredentialsPageName: '¡Plugin activado! Estamos comprobando la conexión...',
      establishedCredentialsName: '¡Plugin activado! Estamos comprobando la conexión...',
    },
    backup: {
      status: {
        excludedName: 'Excluido',
        pendingName: 'En cola',
        inProgressName: 'Creando',
        readyName: 'Procesando',
        uploadPendingName: 'Subida pendiente',
        uploadingName: 'Subiendo...',
        doneName: 'Hecho',
        failedInCreationName: 'Fallo en la creación',
        failedExportDatabaseName: 'Fallo al exporter la base de datos',
        failedExportFilesName: 'Fallo al exporter los archivos',
        failedInProcessingName: 'Fallo en el procesamiento',
        failedUploadedName: 'Fallo en la recepción',
        failedPartialName: 'Fallo parcial',
        failedName: 'Fallo',
        failedFileNotFoundName: 'Archivo no encontrado',
        storageExceededName: 'Almacenamiento excedido',
      },
    },
  },
  uptime: {
    service: {
      interval: {
        '120Name': '2 minutos',
        '300Name': '5 minutos',
        '900Name': '15 minutos',
        '1800Name': '30 minutos',
      },
      timeout: {
        '250Name': '250 ms',
        '500Name': '500 ms',
        '750Name': '750 ms',
        '1000Name': '1s',
        '2000Name': '2s',
        '5000Name': '5s',
        '10000Name': '10s',
        '20000Name': '20s',
        '30000Name': '30s',
        '45000Name': '45s',
      },
      keyword: {
        type: {
          someName: 'Modo no estricto',
          someDescription:
            'Al menos una de las palabras debe estar presente en el contenido de la página',
          everyName: 'Modo estricto',
          everyDescription:
            'Todas las palabras deben estar presentes en el contenido de la página',
        },
      },
    },
  },
  check: {
    ssl: {
      frequency: {
        notification: {
          '1Name': 'Un día antes',
          '7Name': '7 días antes',
          '14Name': '14 días antes',
          '30Name': '30 días antes',
        },
      },
    },
    notification: {
      interval: {
        '60Name': '1 Minuto',
        '120Name': '2 Minutos',
        '300Name': '5 Minutos',
        '900Name': '15 Minutos',
        '1800Name': '30 Minutos',
      },
    },
  },
  schedule: {
    frequency: {
      weekly: {
        days: {
          mondayName: 'Lunes',
          tuesdayName: 'Martes',
          wednesdayName: 'Miércoles',
          thursdayName: 'Jueves',
          fridayName: 'Viernes',
          saturdayName: 'Sábado',
          sundayName: 'Domingo',
        },
      },
    },
  },
  organization: {
    domain: {
      status: {
        pendingName: 'Sin verificar',
        successName: 'Verificado',
        failedName: 'Fallido',
        temporaryFailureName: 'Fallo temporal',
        notStartedName: 'Sin verificar',
      },
      error: {
        type: {
          serviceErrorName: 'Error de servicio',
          dnsServerErrorName: 'Error del servidor DNS',
          hostNotFoundName: 'Registros DNS no encontrados',
          typeNotFoundName: 'Tipo no encontrado',
          invalidValueName: 'Algún registro DNS tiene un valor no válido',
        },
      },
    },
  },
};
