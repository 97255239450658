import type { BackupServiceStoreRequest, BackupServiceUpdateRequest } from '@/api/services/site/service/backup/index';
import type { SiteBackupExtendedType } from '@/stores/site/backup';
import type { SimpleResponse } from '@/types/Api';
import type { SiteBackupItemTypeEnumType } from '@/types/enums/SiteBackupItemTypeEnum';
import type { SiteBackup } from '@/types/models/site/service/backup/SiteBackup';

import type { SiteServiceBackup } from '@/types/models/site/service/backup/SiteServiceBackup';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class BackupService extends AbstractService {
  /**
   * Retrieves all backups for the current site.
   *
   * @param {string} siteId
   * @returns {Promise<SiteBackup[]>}
   */
  public async index(siteId: number): Promise<SiteBackupExtendedType[]> {
    return this.request({
      method: apiEndpoints.siteBackupsIndex.method,
      url: this.buildPath(apiEndpoints.siteBackupsIndex),
      params: {
        sites: [siteId],
      },
    });
  }

  /**
   * Retrieves the details of a specific site backup.
   *
   * @param {number} backupId
   * @returns {Promise<SiteBackup>}
   */
  public show(backupId: number): Promise<SiteBackup> {
    return this.request({
      method: apiEndpoints.siteBackupsShow.method,
      url: this.buildPath(apiEndpoints.siteBackupsShow, {
        site_backup: backupId,
      }),
    });
  }

  /**
   * Creates a new backup for the current site.
   *
   * @param {string} backupsServiceId
   * @returns {Promise<SiteBackup>}
   */
  public create(backupsServiceId: number): Promise<SiteBackup> {
    return this.request({
      method: apiEndpoints.siteBackupsStore.method,
      url: this.buildPath(apiEndpoints.siteBackupsStore),
      data: {
        backup_service: backupsServiceId,
      },
    });
  }

  /**
   * Deletes the selected backups for the current site.
   *
   * @param backupsIdsToDelete
   * @returns {Promise<SimpleResponse>}
   */
  public delete(backupsIdsToDelete: Array<number>): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.siteBackupsDestroy.method,
      url: this.buildPath(apiEndpoints.siteBackupsDestroy),
      data: {
        backups: backupsIdsToDelete,
      },
    });
  }

  /**
   * Retrieves the backup service for the current site.
   *
   * @returns {Promise<SiteServiceBackup>}
   * @param backupsServiceId
   */
  public retrieveService(backupsServiceId: number): Promise<SiteServiceBackup> {
    return this.request({
      method: apiEndpoints.backupServicesShow.method,
      url: this.buildPath(apiEndpoints.backupServicesShow, {
        backup_service: backupsServiceId,
      }),
    });
  }

  /**
   * Retries the backup process for a given site backup.
   *
   * @param {number} backupId
   * @returns {Promise<{message: string}>}
   */
  public retry(backupId: number): Promise<{ message: string }> {
    return this.request({
      method: apiEndpoints.siteBackupsRetry.method,
      url: this.buildPath(apiEndpoints.siteBackupsRetry, {
        site_backup: backupId,
      }),
    });
  }

  /**
   * Creates or updates the backup service settings for the current site.
   *
   * @param backupsServiceId
   * @param settings
   * @param create
   * @returns {Promise<SiteServiceBackup>}
   */
  public updateSettings(settings: BackupServiceStoreRequest | BackupServiceUpdateRequest, create = false, backupsServiceId?: number): Promise<SiteServiceBackup> {
    const localMethod = create ? apiEndpoints.backupServicesStore.method : apiEndpoints.backupServicesUpdate.method;
    const localUrl = create ? apiEndpoints.backupServicesStore : apiEndpoints.backupServicesUpdate;

    return this.request({
      method: localMethod,
      url: this.buildPath(localUrl, {
        ...((!create && !!backupsServiceId) && { backup_service: backupsServiceId }),
      }),
      data: settings,
    });
  }

  /**
   * Toggles the favorite status of a given site backup.
   *
   * @returns {Promise<SiteServiceBackup>}
   * @param backupId
   */
  public markAsFavorite(backupId: number): Promise<SiteServiceBackup> {
    return this.request({
      method: apiEndpoints.siteBackupsFavoriteToggle.method,
      url: this.buildPath(apiEndpoints.siteBackupsFavoriteToggle, {
        site_backup: backupId,
      }),
    });
  }

  /**
   * Retrieves the download links for the backup files of a given site backup.
   *
   * @param backupId
   * @param {SiteBackupItemTypeEnumType} types
   * @returns {Promise<Array<string>>}
   */
  public downloadBackup(
    backupId: number,
    types?: SiteBackupItemTypeEnumType,
  ): Promise<Array<string | null>> {
    return this.request({
      method: apiEndpoints.siteBackupsDownload.method,
      url: this.buildPath(apiEndpoints.siteBackupsDownload, {
        site_backup: backupId,
      }),
      ...(types !== undefined && { params: { type: types } }),
    });
  }

  /**
   *  Retrieves the download link for a specific backup item.
   *
   * @param {number} partId
   * @returns {Promise<string>}
   */
  public downloadBackupSingleItem(partId: number): Promise<string> {
    return this.request({
      method: apiEndpoints.siteBackupsItemDownload.method,
      url: this.buildPath(apiEndpoints.siteBackupsItemDownload, {
        item: partId,
      }),
    });
  }
}
