import NotificationComponent from '@/helpers/notifications/component/NotificationComponent.vue';
import { NotificationBase } from '@/helpers/notifications/NotificationBase';
import { h } from 'vue';

export class ReportAutomaticFailedNotification extends NotificationBase {
  data: {
    site: {
      team: string
      slug: string
      url: string
      name: string
    }
    report: {
      id: number
      title: string
    }
  };

  constructor(
    createdAt: string,
    readAt: string | null,
    updatedAt: string | null,
    data: {
      site: { team: string, slug: string, url: string, name: string }
      report: { id: number, title: string }
    },
  ) {
    super('report.automatic.failed', createdAt, readAt, updatedAt);
    this.data = data;
  }

  render() {
    const route = {
      name: 'sites.site.report.details',
      params: {
        team: this.data.site.team,
        site: this.data.site.slug,
        reportType: 'manual', // TODO Mapear type
        reportId: this.data.report.id,
      },
    };

    const { t } = useI18n();

    const title = t('notifications.notifications.reportAutomaticFailed.title', { name: this.data.site.name });
    const bodyText = t('notifications.notifications.reportAutomaticFailed.body', { url: this.data.site.url });

    return h(NotificationComponent, {
      route,
      title,
      icon: 'bullets',
      bodyText,
      createdAt: this.createdAt,
    });
  }
}
