import type { Contact } from '@/types/models/organization/contact/Contact';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class ContactService extends AbstractService {
  /**
   * Retrieves all the contacts of an organization that match the search param value.
   *
   * @param {{s: string}} params
   * @returns {Promise<Contact[]>}
   */
  public all(params?: { s?: string, type?: 'email' | 'phone' } | undefined): Promise<Contact[]> {
    return this.request({
      method: apiEndpoints.contactsIndex.method,
      url: this.buildPath(apiEndpoints.contactsIndex),
      params,
      options: {
        cancellable: true,
        cancelKey: 'searchContacts',
      },
    });
  }

  /**
   * Creates a new contact for the organization
   *
   * @param {Record<string, string>} data
   * @returns {Promise<Contact>}
   */
  public create(data: Record<string, string>): Promise<Contact> {
    return this.request({
      method: apiEndpoints.contactsStore.method,
      url: this.buildPath(apiEndpoints.contactsStore),
      data,
    });
  }
}
