import { useStorage } from '@vueuse/core';

export interface IUserConfig {
  siteOrderColumn?: string
  siteOrderSort?: string
  siteViewMode?: string
  language?: string
  timezone?: string
}

export type IUserConfigDashboard = Pick<IUserConfig, 'siteViewMode' | 'siteOrderSort' | 'siteOrderColumn'>;

export const defaultConfig: IUserConfigDashboard = {
  siteOrderColumn: 'visited_at',
  siteOrderSort: 'desc',
  siteViewMode: 'grid',
};

export function useUserConfig() {
  const userConfig = useStorage<Partial<IUserConfig>>('userConfig', defaultConfig);

  const cleanConfig = (config: Partial<IUserConfig>) =>
    Object.fromEntries(Object.entries(config).filter(([_, v]) => v !== null && v !== undefined));

  const getConfig = () => userConfig.value;

  const setConfig = async (config: Partial<IUserConfig>) => {
    userConfig.value = {
      ...defaultConfig,
      ...cleanConfig(userConfig.value),
      ...cleanConfig(config),
    };
  };

  const removeConfig = () => {
    userConfig.value = { ...defaultConfig };
  };

  return { userConfig, getConfig, setConfig, removeConfig };
}
