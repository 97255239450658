<script setup lang="ts">
import { TabsTrigger, type TabsTriggerProps, useForwardProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<TabsTriggerProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <TabsTrigger
    v-bind="forwardedProps"
    class="ui-tabs-trigger position-relative lh-xl d-inline-flex align-items-center justify-content-center border-0
      bg-transparent shadow-none"
    :class="[
      props.class,
    ]"
  >
    <slot />
  </TabsTrigger>
</template>
