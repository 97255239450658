import type { ISiteReportsStoreState } from '@/stores/site/report/index';

import type { PaginationResponse } from '@/types/Api';
import type { Pagination } from '@/types/models/Pagination';
import type { BulkSiteManualReport } from '@/types/models/site/service/report/SiteReport';
import type { SiteScheduledReport } from '@/types/models/site/service/report/SiteScheduledReport';
import useSiteStore from '@/stores/site/siteStore';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const useReportsStore = defineStore('report', (): ISiteReportsStoreState => {
  const siteStore = useSiteStore();

  // region Manual & Scheduled reports
  const loadedCurrentReport = ref(false);
  const currentReport = ref<BulkSiteManualReport | SiteScheduledReport | null>(null);
  // endregion Manual & Scheduled reports

  // region Manual reports
  const loadedManualReports = ref(false);
  const loadedManualReportsWithFilters = ref(true);
  const manualReportsPaginated = ref<PaginationResponse<BulkSiteManualReport> | null>(null);
  const manualReportsMap = ref<Map<number, BulkSiteManualReport>>(new Map());
  const thereAreManualReports = ref(false);
  // endregion Manual reports

  // region Scheduled reports
  const loadedScheduledReports = ref(false);
  const scheduledReports = ref<SiteScheduledReport[] | null>(null);
  const scheduledReportsMap = ref<Map<number, SiteScheduledReport>>(new Map());

  const hasReportService = computed(
    () => (siteStore.siteServices?.some(service => service.type === ServiceTypeEnumConst.REPORT)),
  );
  // endregion Scheduled reports

  // region Manual reports computed
  const manualReportsPagination: ComputedRef<Pagination | null> = computed(() => {
    if (!!manualReportsPaginated.value) {
      return {
        meta: manualReportsPaginated.value.meta,
        links: manualReportsPaginated.value?.links,
      }
    }

    return null;
  });
  // endregion Manual reports computed

  // region Reports functions
  const setCurrentReport = (report: BulkSiteManualReport | SiteScheduledReport) => {
    currentReport.value = report;
  }

  const clearManualReports = () => {
    loadedManualReports.value = false;
    // Defaults to true
    loadedManualReportsWithFilters.value = true;
    manualReportsPaginated.value = null;
    manualReportsMap.value.clear();
    thereAreManualReports.value = false;
  };

  const clearScheduledReports = () => {
    loadedScheduledReports.value = false;
    scheduledReports.value = null;
    scheduledReportsMap.value = new Map();
  };

  const clearCurrentReport = () => {
    currentReport.value = null;
    loadedCurrentReport.value = false;
  };

  const reset = () => {
    clearCurrentReport();
    clearManualReports();
    clearScheduledReports();
  };
  // endregion Reports functions

  return {
    // Manual & Scheduled reports
    loadedCurrentReport,
    currentReport,

    setCurrentReport,
    reset,
    clearCurrentReport,

    // Manual reports
    loadedManualReports,
    thereAreManualReports,
    loadedManualReportsWithFilters,
    manualReportsPaginated,
    manualReportsMap,

    manualReportsPagination,

    // Scheduled reports
    loadedScheduledReports,
    scheduledReports,
    scheduledReportsMap,

    hasReportService,

    clearScheduledReports,
  };
});

export { useReportsStore };

export default useReportsStore;
