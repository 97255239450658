import type { Vulnerability } from '@/types/models/site/service/vulnerabilities/Vulnerability';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class GeneralVulnerabilitiesService extends AbstractService {
  /**
   * Returns server information from connected site
   *
   * @param {string} type
   * @param {string} slug
   * @param {string} version
   * @returns {Promise<Vulnerability[]>}
   */
  public retrieve(type: string, slug: string, version: string): Promise<Vulnerability[]> {
    return this.request({
      method: apiEndpoints.vulnerabilitiesIndex.method,
      url: this.buildPath(apiEndpoints.vulnerabilitiesIndex),
      params: {
        type,
        slug,
        version,
      },
    });
  }
}
