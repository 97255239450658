import { Concept } from '@/types/models/Concept';

export type BackupScheduleFrequencyEnumKeys = 'daily' | 'weekly' | 'monthly';

export const BackupScheduleFrequencyEnumConst = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
} as const;

export const BackupScheduleFrequencyEnum: Record<BackupScheduleFrequencyEnumKeys, Concept> = {
  'daily': {
    key: 'daily',
    value: null,
    name: 'concept.backup.schedule.frequency.dailyName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: 'organization.backup.daily',
    type: 'backup.schedule.frequency'
  },
  'weekly': {
    key: 'weekly',
    value: null,
    name: 'concept.backup.schedule.frequency.weeklyName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: 'organization.backup.weekly',
    type: 'backup.schedule.frequency'
  },
  'monthly': {
    key: 'monthly',
    value: null,
    name: 'concept.backup.schedule.frequency.monthlyName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: 'organization.backup.monthly',
    type: 'backup.schedule.frequency'
  }
} as const;

export type BackupScheduleFrequencyEnumType = (typeof BackupScheduleFrequencyEnumConst)[keyof typeof BackupScheduleFrequencyEnumConst];

export const BackupScheduleFrequencyEnumArray = Object.values(BackupScheduleFrequencyEnum);
