export default {
  title: 'Changelog',
  offer: {
    title: 'Vote for upcoming features',
    text: 'In case you didn\'t know... you are part of Modular! So, will you help us build our future?',
  },
  noMorePosts: 'This is all for now.',
  noMorePostsText: 'Thank you for joining us in this adventure!',
  new: 'New release',
};
