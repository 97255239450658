export default {
  countdown: {
    countdownMode:
      '¡La semana <span class="text-primary">bronceadora</span> llega con <span class="text-primary">descuentazos de hasta el 50%!</span> ⏳ <span class="time">{time}</span>',
    moreInfo: 'Más info',
  },
  serverDown: 'Estamos experimentando problemas técnicos. Puede que algunas funciones no estén disponibles.',
  maintenanceMode: 'Mantenimiento programado el 13 de Noviembre de 16:30 a 17:00h (UTC).',
  disconnectedSocket: 'Por inactividad, la actualización de datos en tiempo real se ha detenido. La información puede no estar actualizada.',
  shared: {
    // Needed for report
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
    days: 'd',
    daysLong: 'días',
    hours: 'h',
    hoursLong: 'horas',
    minutes: 'min',
    minutesShort: 'm',
    minutesLong: 'minutos',
    seconds: 's',
    secondsLong: 'segundos',
    year: 'año',
    yearly: 'anual',
    month: 'mes',
    monthly: 'mensual',
    since: 'Desde',
    date: 'Fecha',
    state: 'Estado',
    to: 'Hasta',
    duration: 'Duración',

    title: 'Título',
    text: 'Texto',
    advancedSettings: 'Opciones avanzadas',
    options: 'Opciones',
    config: 'Configuración',
    general: 'General',
    actions: 'Acciones',
    contact: 'Contactar',
    cannotUndo: 'Esta operación no se puede deshacer',
    settings: 'Ajustes',
    name: 'Nombre',
    older: 'Más antiguas',
    lastName: 'Apellido',
    lastNames: 'Apellidos',
    Slug: 'Slug',
    primary: 'Principal',
    error: 'Ha ocurrido un error',
    frequenlyAsked: 'Preguntas frecuentes',
    see: 'Ver',
    now: 'Ahora',
    paid: 'Pagado',
    pending: 'Pendiente',
    day: 'día | días',
    country: 'País',
    selectCountry: 'Selecciona un País',
    updatedData: 'Datos actualizados',
    savedData: 'Datos actualizados',
    savedChanges: 'Cambios guardados',
    updatedEmail: 'Correo actualizado',
    updatedPassword: 'Contraseña actualizada',
    price: 'Precio',
    ohNo: '¡Oh no!',
    appLogoAlt: 'Logo Modular',
    teamImageAlt: 'Team work',
    userImageAlt: 'User image',
    trustPersonImageAlt: 'Imagen de Persona de confianza',
    tableShowNumber: 'Mostrar: {number}',
    pdf: 'PDF',
    pagination: 'Paginación',
    new: 'Nuevo',
    last: 'Última',
    next: 'Siguiente',
    retry: 'Retry',
    on: 'on',
    off: 'off',
    up: 'Up',
    down: 'Down',
    paused: 'Paused',
    unknown: 'Unknown',
    for: 'for',
    comingSoon: 'Próximamente',
    alreadyAvailable: 'Ya disponible',
    proTag: 'PRO',
    proVersion: 'Pro',
    hour: 'Hora',
    size: 'Tamaño',
    stateAndActions: 'Estado y acciones',
    from: 'Desde',
    search: 'Search',
    soon: 'Soon',
    and: 'y',
    frequencyError: 'Frecuencia no soportada',
    filterBy: 'Filtrar por:',
    searchFor: 'Busca...',
    uploadImageText: 'Selecciona o arrastra 1 archivo',
    becomePro: 'Hazte PRO',
    becomeBusiness: 'Hazte Business',
  },
  webs: {
    websCount: '{num} webs',
  },
  header: {
    search: 'Buscar webs, equipos...',
    support: 'Soporte',
    supportLink: 'https://soporte.modulards.com/es?utm_source=app&utm_medium=support&utm_campaign=menu',
    myProfile: 'Mi perfil',
    logout: 'Cerrar sesión',
    helpUs: '¿Nos ayudas?',
    reviewUs: 'Dejar review',
  },
  download: {
    downloadingFiles: 'Descargando {num} archivos',
    compressing: 'Comprimiendo..',
  },
  form: {
    user: 'Usuario',
    password: 'Contraseña',
  },
  button: {
    add: 'Añadir',
    adding: 'Añadiendo...',
    agree: 'Aceptar',
    update: 'Actualizar',
    delete: 'Eliminar',
    deleteAlt: 'Borrar',
    deleting: 'Eliminando...',
    remove: 'Quitar',
    removing: 'Quitando...',
    apply: 'Aplicar',
    deleteSite: 'Eliminar web',
    enable: 'Activar',
    disable: 'Desactivar',
    disabling: 'Desactivando...',
    confirm: 'Confirmar',
    continue: 'Continuar',
    cancel: 'Cancelar',
    dashboard: 'Ir al dashboard',
    noCancel: 'No cancelar',
    loading: 'Cargando...',
    processing: 'Procesando...',
    checking: 'Comprobando...',
    connect: 'Conectar',
    reconnectShort: 'Reconectar',
    retry: 'Reintentar',
    close: 'Cerrar',
    save: 'Guardar',
    saveAndSchedule: 'Guardar y Programar',
    saveChanges: 'Guardar cambios',
    saving: 'Guardando...',
    edit: 'Editar',
    back: 'Volver',
    backToTeam: 'Volver al equipo',
    viewAll: 'Ver todos',
    toWordPress: 'Ir a WordPress',
    updateNow: 'Actualizar ahora',
    select: 'Seleccionar',
    selected: 'Seleccionado',
    unlink: 'Desvincular',
    unlinking: 'Desvinculando...',
    goToModular: 'Ir a ModularDS',
    reconnect: 'Volver a conectar',
    configure: 'Configurar',
    learnMore: 'Saber más',
    checkConnection: 'Probar la conexión',
    exclude: 'Excluir',
    getMoreSpace: 'Consigue más espacio',
    recover: 'Recuperar',
    seeDetails: 'Ver detalles',
    hire: 'Contratar',
    next: 'Siguiente',
    saveAndSend: 'Guardar y Enviar',
    checkState: 'Comprobar estado',
    include: 'Incluir',
    addAnotherWebsite: 'Añadir otra web',
    disconnectOtherSessions: 'Cerrar otras sesiones del navegador',
    disconnectSessions: 'Cerrar sesiones',
    disconnect: 'Desconectar',
    disconnecting: 'Desconectando',
    saveDraft: 'Guardar borrador',
    generateReport: 'Generar reporte',
    generateOtherReport: 'Generar otro reporte',
    finish: 'Finalizar',
    saveReport: 'Guardar reporte',
    share: 'Compartir',
    saveAsDraft: 'Guardar como borrador',
    restoreDefaults: 'Restaurar valores por defecto',
    download: 'Descargar',
    downloadAll: 'Descargar todo',
    deactivated: 'Desactivado',
    updatePlugins: 'Actualizar plugins',
    discard: 'Descartar',
    preview: 'Previsualizar',
    view: 'Ver',
    change: 'Cambiar',
    addExtraSpacing: 'Añadir Gigas a mi plan',
    verify: 'Verificar',
    verifying: 'Verificando...',
    changing: 'Cambiando...',
    viewWebsite: 'Visitar web',
    viewReports: 'Ver reportes',
    createReport: 'Crear reporte',
    createTask: 'Crear tarea',
    new: 'Nueva',
    removeTag: 'Eliminar',
    clearFilters: 'Limpiar filtros',
    search: 'Buscar',
    searching: 'Buscando...',
  },
  modal: {
    close: 'Cerrar',
    delete: 'Eliminar {name}',
    deletePaymentLink: 'Eliminar enlace de pago',
    deletePaymentExplain: 'Al borrar el enlace este desaparecerá para siempre.',
    deletePaymentMethod: 'Eliminar enlace de pago',
    deletePaymentMethodExplain: 'Al borrar el enlace este desaparecerá para siempre.',
    confirmDeleteSite:
      'Al desconectar esta web de Modular no podrás consultarla desde la aplicación y tendrás que volver a empezar el proceso de conexión.',
    confirmDeleteTeam: 'Se eliminará el equipo de manera permanente.',
    successDeleteSite: 'Web eliminada con éxito.',
    successDeleteLink: 'Enlace eliminado',
    successDeletePaymentMethod: 'Método de pago eliminado',
    deleteUser: 'Eliminar usuario {email}',
    deleteUserExplain: 'Al borrar el usuario se perderán todos los datos.',
    successDeleteUser: 'Usuario eliminado',
  },
  sidebar: {
    offerTitle: 'Vota por las próximas funcionalidades',
    offerText: 'Tú decides con Modular',
    offerLink: 'Ver roadmap',
    offerLinkUrl: 'https://trello.com/b/wyWQUpbV',
    trustedPerson: 'Tu persona de confianza',
    trustedPersonLink: 'Habla con Héctor',
    activateUptimeMonitor: 'Activar Uptime Monitor',
    activating: 'Activando...',
  },
  verifyEmail: {
    title: 'Recuerda verificar tu correo electrónico en el email que te hemos enviado.',
    resend: 'Reenviar email',
    confirmEmailSent: 'Correo de confirmación enviado',
    error: 'Ha habido un error al solicitar tu petición.',
    tryLater: 'Por favor, inténtalo más adelante o ponte en contacto con nosotros.',
    successVerified: 'Tu correo se ha verificado satisfactoriamente.',
    goBack: 'Puedes hacer clic en el siguiente enlace para volver a Modular DS.',
  },
  screenVerify: {
    title: '¡Modular es mejor en pantalla grande!',
    workingOn: 'Estamos trabajando en ofrecerte una versión móvil de calidad. De momento podemos ayudarte desde la pantalla grande.',
    openOnDesktop: '¡Cámbiate al portátil o la tablet para acceder!',
    needHelp: '¿Necesitas ayuda?',
  },
  error: {
    lostConnection: '403, hemos perdido contacto con el servidor...',
    lostConnectionImgAlt: 'Error 403',
    lostPage: '404, algo nos hemos perdido...',
    lostPageImgAlt: 'Error 404',
  },
  toast: {
    successSaving: 'Guardado con éxito',
    successUpdated: 'Actualizado con éxito',
    successDeleting: 'Borrado con éxito',
  },
  organizationLimit: {
    title: 'Has alcanzado el límite',
    alertTitle: 'Ya has conectado {number} webs en tu plan {plan}',
    alertText: 'Suscríbete a un plan {nextPlan} para conectar webs ilimitadas.',
    cancelButton: 'En otro momento',
    acceptButton: 'Pásate a {nextPlan}',
  },
  text: {
    maxLimit: '{characters} de {max_characters} caracteres utilizados',
  },
  placeholder: {
    noDataToShow: 'No hay datos que mostrar',
  },
  dayweek: {
    monday: 'lunes | lunes',
    tuesday: 'martes | martes',
    wednesday: 'miércoles | miércoles',
    thursday: 'jueves | jueves',
    friday: 'viernes | viernes',
    saturday: 'sábado | sábados',
    sunday: 'domingo | domingos',
  },
  downloader: {
    complete: 'Descarga completada',
  },
};
