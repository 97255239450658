import type { CheckoutPrice } from '@/types/models/organization/subscription/checkout/CheckoutPrice';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class CheckoutService extends AbstractService {
  /**
   * Creates a new checkout for the organization.
   *
   * @param {{price: string}} price
   * @returns {Promise<string>}
   */
  public create(price: { price: string }): Promise<string> {
    return this.request({
      method: apiEndpoints.checkoutsStore.method,
      url: this.buildPath(apiEndpoints.checkoutsStore),
      data: price,
    });
  }

  /**
   * Calculates the price of the checkout specified in the request.
   *
   * @param price
   * @returns {Promise<CheckoutPrice>}
   */
  public calculatePrice(price: any): Promise<CheckoutPrice> {
    return this.request({
      method: apiEndpoints.checkoutsPriceTotalCalculate.method,
      url: this.buildPath(apiEndpoints.checkoutsPriceTotalCalculate),
      params: { price },
    });
  }
}
