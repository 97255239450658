<script lang="ts" setup>
import type { MIconTypes } from '@/components/vendor/basic/icon/MIcon.vue';
import { computed, type HTMLAttributes } from 'vue';

defineOptions({
  name: 'VAlert',
});

const props = withDefaults(
  defineProps<{
    iconClass?: HTMLAttributes['class']
    variant?: 'danger' | 'warning' | 'secondary' | 'tiger' | 'black' | 'danger-dark'
    classes?: HTMLAttributes['class']
    iconVariant?: MIconTypes
    role?: string
    showIcon?: boolean
    contentSpacing?: string
  }>(),
  {
    iconClass: 'text-xxl',
    variant: 'danger',
    classes: '',
    iconVariant: 'circle-actions-alert-info',
    role: 'default',
    showIcon: true,
    contentSpacing: 'ms-8',
  },
);

const alertVariantComputed = computed(() => `alert-${props.variant}`);
</script>

<template>
  <div :class="[alertVariantComputed, classes]" :role="role" class="alert d-flex">
    <m-icon v-if="showIcon" :class="[iconClass]" :icon="iconVariant" class="main-icon" />

    <div :class="contentSpacing" class="d-flex flex-column justify-content-center w-100">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.alert {
  &.alert-black {
    background-color: var(--md-gray-900);
    color: var(--md-light);
  }
}

[data-bs-theme='dark'] {
  .alert {
    &.alert-black {
      background-color: var(--md-gray-300);
      color: var(--md-dark);
    }
  }
}
</style>
