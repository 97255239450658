import type { DownloadStatusType } from '@/types/DownloadStatus';
import type { Ref } from 'vue';
import { defineStore } from 'pinia';

import { ref } from 'vue';

export interface DownloadingItemStatus {
  name: string
  downloadProgress: number
  compressProgress: number
  status: DownloadStatusType
  url: string
}

interface IDownloadStore {
  isDownloading: Ref<boolean>
  isCompressing: Ref<boolean>
  downloadingItems: Ref<Record<string, DownloadingItemStatus>>
  startDownload: (name: string, url: string, status: string) => void
  updateDownloadProgress: (name: string, downloadProgress: number) => void
  updateDownloadStatus: (name: string, status: string) => void
  finishDownload: (name: string) => void
  clearDownloads: () => void
}

const useDownloadStore = defineStore('download', (): IDownloadStore => {
  const isDownloading = ref(false);
  const isCompressing = ref(false);
  const downloadingItems = ref<Record<string, DownloadingItemStatus>>({});

  const startDownload = (name: string, url: string, status: DownloadStatusType) => {
    isDownloading.value = true;

    downloadingItems.value[name] = { name, url, downloadProgress: 0, compressProgress: 0, status };
  };
  const updateDownloadProgress = (name: string, downloadProgress: number) => {
    if (
      !!downloadingItems.value
      && downloadingItems.value[name]
      && downloadingItems.value[name].downloadProgress < downloadProgress
    ) {
      downloadingItems.value[name].downloadProgress = downloadProgress;
    }
  };
  /*  const updateCompressProgress = (name: string, compressProgress: number) => {
    if (!!downloadingItems.value && downloadingItems.value[name] && downloadingItems.value[name].compressProgress < compressProgress) {
      downloadingItems.value[name].compressProgress = compressProgress;
    }
  }; */

  const updateDownloadStatus = (name: string, status: DownloadStatusType) => {
    if (!!downloadingItems.value && downloadingItems.value[name]) {
      downloadingItems.value[name].status = status;
    }
  };

  const finishDownload = (name: string) => {
    if (!!downloadingItems.value && downloadingItems.value[name]) {
      downloadingItems.value[name].downloadProgress = 100;
    }

    isDownloading.value = Object.values(downloadingItems.value).some(item => item.downloadProgress < 100);
  };
  const clearDownloads = () => {
    isDownloading.value = false;
    isCompressing.value = false;
    downloadingItems.value = {};
  };

  return {
    isDownloading,
    downloadingItems,
    startDownload,
    updateDownloadProgress,
    updateDownloadStatus,
    finishDownload,
    clearDownloads,
    isCompressing,
  };
});

export { useDownloadStore };

export default useDownloadStore;
