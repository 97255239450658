<script lang="ts" setup>
import type { SiteItemChangelog } from '@/views/manager';
import type { HTMLAttributes } from 'vue';

import api from '@/api';

import VButton from '@/components/vendor/basic/button/VButton.vue';

import { PREMIUM_PLUGINS } from '@/resources/constants/PremiumPluginsConstants';

import useManagerStore from '@/stores/manager/managerStore';

import { storeToRefs } from 'pinia';

defineOptions({
  name: 'TheManagerUpdateChangelogButton',
});

defineProps<{
  classes?: HTMLAttributes['class']
}>();

const emit = defineEmits(['openChangelog']);

const itemChangelog = defineModel<SiteItemChangelog>({
  required: true,
});

const managerStore = useManagerStore();
const { isGlobal } = storeToRefs(managerStore);

function showChangelog() {
  api.mixpanel.track('View changelog', {
    type: itemChangelog.value.type,
    name: itemChangelog.value.name,
    slug: itemChangelog.value.slug,
    updater: isGlobal.value ? 'global' : 'web',
  });

  const plugin = PREMIUM_PLUGINS.find(plugin => plugin.key === itemChangelog.value.slug);

  if (!!plugin) {
    window.open(plugin.changelog, '_blank');
  } else {
    emit('openChangelog');
  }
}
</script>

<template>
  <VButton
    class="d-flex fw-medium new-version text-decoration-underline lh-xl text-nowrap border-0 p-0"
    :class="[classes]"
    emit-event-data
    size="2xs"
    variant="link"
    @click.stop="showChangelog"
  >
    {{ itemChangelog.version }}
  </VButton>
</template>

<style lang="scss" scoped>
:deep(.new-version.text-decoration-underline) {
  text-underline-offset: 2px;
}
</style>
