import type { SocialNetwork } from '@/types/models/user/social/SocialNetwork';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class SocialAccountsService extends AbstractService {
  /**
   * Retrieves all User Social Networks
   * @protected
   */
  public all(filter: '' | 'analytics' | 'search_console' = ''): Promise<SocialNetwork[]> {
    const data: { service?: '' | 'analytics' | 'search_console' } = {};

    if (!!filter) {
      data.service = filter;
    }

    return this.request({
      method: apiEndpoints.organizationSocialIndex.method,
      url: this.buildPath(apiEndpoints.organizationSocialIndex),
      params: data,
    });
  }

  /**
   * Route to connect to provider account
   * @param redirect
   * @param provider
   * @protected
   */
  public connect(redirect: string, provider = 'google'): Promise<{ status: string, redirectUri: string }> {
    return this.request({
      method: apiEndpoints.organizationSocialConnectRedirect.method,
      url: this.buildPath(apiEndpoints.organizationSocialConnectRedirect, {
        provider,
      }),
      params: {
        redirect_to: JSON.stringify(redirect),
      },
    });
  }

  /**
   * Get Analytics report
   * @protected
   * @param data
   */
  public confirm(data: {
    state: string
    code: string
    scope: string
    authuser: string
    hd: string
    prompt: string
    redirect_to: string
    provider: string
  }): Promise<{ status: string, message: string }> {
    return this.request({
      method: apiEndpoints.organizationSocialConnectConfirm.method,
      url: this.buildPath(apiEndpoints.organizationSocialConnectConfirm),
      params: data,
    });
  }

  /**
   * Deletes Google Account
   * @protected
   */
  public delete(socialNetwork: string | number): Promise<any> {
    return this.request({
      method: apiEndpoints.organizationSocialDestroy.method,
      url: this.buildPath(apiEndpoints.organizationSocialDestroy, {
        socialNetwork,
      }),
    });
  }

  /**
   * Get GA accounts
   * @param site
   * @param serviceType
   * @param socialId
   * @protected
   */
  public associateAccount(
    siteId: number,
    serviceType: 'analytics' | 'search_console',
    socialId: string | number,
  ): Promise<any> {
    return this.request({
      method: apiEndpoints.servicesAssignSocial.method,
      url: this.buildPath(apiEndpoints.servicesAssignSocial, {
        site: siteId,
        type: serviceType,
      }),
      data: {
        social_network: socialId,
      },
    });
  }
}
