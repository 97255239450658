<script lang="ts" setup>
import { ScrollAreaScrollbar, type ScrollAreaScrollbarProps, ScrollAreaThumb } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

// import { cn } from '@/lib/utils'

const props = withDefaults(
  defineProps<
    ScrollAreaScrollbarProps & {
      class?: HTMLAttributes['class']
      barSize?: '' | 'sm'
    }
  >(),
  {
    orientation: 'vertical',
    class: '',
    barSize: '',
  },
);

const delegatedProps = computed(() => {
  const { class: _, barSize: __, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ScrollAreaScrollbar
    class="ui-scrollbar d-flex transition-colors"
    :class="[
      orientation === 'vertical' && 'ui-scrollbar-vert h-100',
      orientation === 'horizontal' && 'ui-scrollbar-hori d-flex flex-column',
      barSize === 'sm' && 'ui-scrollbar-sm',
      props.class,
    ]"
    v-bind="delegatedProps"
  >
    <ScrollAreaThumb class="ui-scrollbar-thumb position-relative flex-grow-1 flex-shrink-1 flex-basis-0 rounded-pill" />
  </ScrollAreaScrollbar>
</template>

<style lang="scss" scoped>
.ui-scrollbar {
  user-select: none;
  touch-action: none;
  padding: 0.0625rem;
  z-index: 10;
  --ui-scroll-width: 0.625rem;

  &.ui-scrollbar-vert {
    width: var(--ui-scroll-width);
    border-left: 0 solid transparent;
  }

  &.ui-scrollbar-hori {
    height: var(--ui-scroll-width);
    border-top: 0 solid transparent;
  }

  &.ui-scrollbar-sm {
    --ui-scroll-width: 0.5rem;
  }
}

.ui-scrollbar-thumb {
  background-color: var(--md-gray-400);
}
</style>
