import type { Subscription } from '@/types/models/organization/subscription/Subscription';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class SubscriptionService extends AbstractService {
  /**
   * Returns a list of all active subscriptions of the organization.
   *
   * @returns {Promise<Subscription[]>}
   */
  public all(): Promise<Subscription[]> {
    return this.request({
      method: apiEndpoints.subscriptionsIndex.method,
      url: this.buildPath(apiEndpoints.subscriptionsIndex),
    });
  }
}
