import { Concept } from '@/types/models/Concept';

export type UserSourceEnumKeys = 'default' | 'appsumo';

export const UserSourceEnumConst = {
  DEFAULT: 'default',
  APPSUMO: 'appsumo',
} as const;

export const UserSourceEnum: Record<UserSourceEnumKeys, Concept> = {
  'default': {
    key: 'default',
    value: null,
    name: 'concept.user.source.defaultName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'user.source'
  },
  'appsumo': {
    key: 'appsumo',
    value: null,
    name: 'concept.user.source.appsumoName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'user.source'
  }
} as const;

export type UserSourceEnumType = (typeof UserSourceEnumConst)[keyof typeof UserSourceEnumConst];

export const UserSourceEnumArray = Object.values(UserSourceEnum);
