import type {
  BasicTestsItemsKeys,
  BasicTestsResumeItem,
  BasicTestsVersionKeys,
  HealthItem,
  SiteServiceHealthParams,
} from '@/types/models/site/service/health/SiteHealth';
import type { SiteInformationGrouped } from '@/types/models/site/service/information/SiteInformation';
import type { Vulnerability } from '@/types/models/site/service/vulnerabilities/Vulnerability';

import type {
  BasicTestsItemsType,
  BasicTestsVersionItemType,
  HealthItemStatusType,
  HealthTabItems,
} from '@/views/site/health/types';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import api from '@/api';

import { createHealthStatusItem } from '@/views/site/health/utils/healthFunctions';

import useSiteStore from '@/stores/site/siteStore';

import { storeToRefs } from 'pinia';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import { useToast } from '@/composables/useToast';

export const statusConfig: Record<'recommended' | 'critical' | 'good', HealthItemStatusType> = {
  recommended: { background: 'warning-light', color: 'warning-dark', icon: 'neutral-emoji' },
  critical: { background: 'danger-light', color: 'danger-dark', icon: 'sad-emoji' },
  good: { background: 'success-light', color: 'success-dark', icon: 'smile-emoji' },
};

function useSiteInformation() {
  const { toastSuccess } = useToast();

  const siteStore = useSiteStore();

  const siteServicesHealthService = computed(() => (siteStore.siteServices?.find(service => service.type === ServiceTypeEnumConst.HEALTH)));
  const healthServiceId = computed(() => (siteServicesHealthService.value?.id));

  const healthLastRequest: Ref<string | null> = ref(null);
  const healthNextRequest: Ref<string | null> = ref(null);

  const criticalErrors = ref<HealthItem[] | []>([]);
  const healthTabItems = ref<HealthTabItems[]>([]);

  const siteServerInformation: Ref<SiteInformationGrouped[] | null> = ref(null);

  const healthVersionsStatus: Ref<BasicTestsVersionItemType[]> = ref([
    {
      test: 'wordpress_version' as BasicTestsVersionKeys,
      status: 'loading',
      background: 'gray-300',
      color: 'gray-600',
      text: 'health.wpVersion',
      currentVersion: '--',
      icon: 'smile-emoji',
    },
    {
      test: 'php_version' as BasicTestsVersionKeys,
      status: 'loading',
      background: 'gray-300',
      color: 'gray-600',
      text: 'health.phpVersion',
      currentVersion: '--',
      icon: 'smile-emoji',
    },
    {
      test: 'modular_version' as BasicTestsVersionKeys,
      status: 'loading',
      background: 'gray-300',
      color: 'gray-600',
      text: 'health.modularVersion',
      currentVersion: '--',
      icon: 'smile-emoji',
    },
  ]);

  const healthItemsStatus: Ref<BasicTestsItemsType[]> = ref([
    {
      test: 'plugin_not_updated' as BasicTestsItemsKeys,
      text: 'health.generalStatus.title.plugin_not_updated',
      status: 'loading',
      color: 'success-dark',
      icon: 'smile-emoji',
    },
    {
      test: 'plugin_inactive' as BasicTestsItemsKeys,
      text: 'health.generalStatus.title.plugin_inactive',
      status: 'loading',
      color: 'success-dark',
      icon: 'smile-emoji',
    },
    {
      test: 'theme_not_updated' as BasicTestsItemsKeys,
      text: 'health.generalStatus.title.theme_not_updated',
      status: 'loading',
      color: 'success-dark',
      icon: 'smile-emoji',
    },
    {
      test: 'has_ssl' as BasicTestsItemsKeys,
      text: 'health.generalStatus.title.has_ssl',
      status: 'loading',
      color: 'success-dark',
      icon: 'smile-emoji',
    },
    {
      test: 'is_in_debug_mode' as BasicTestsItemsKeys,
      text: 'health.generalStatus.title.is_in_debug_mode',
      status: 'loading',
      color: 'success-dark',
      icon: 'smile-emoji',
    },
    {
      test: 'is_public' as BasicTestsItemsKeys,
      text: 'health.generalStatus.title.is_public',
      status: 'loading',
      color: 'success-dark',
      icon: 'smile-emoji',
    },
  ]);

  const siteVulnerabilities: Ref<Vulnerability[] | null> = ref(null);

  const { currentSiteTeamSlug, currentSiteSlug } = storeToRefs(siteStore);

  const mapHealthVersionsStatus = (resumeItems: BasicTestsResumeItem[]) => {
    healthVersionsStatus.value.forEach((item, index) => {
      const itemFound = resumeItems.find((test: BasicTestsResumeItem) => test.test === item.test);

      if (!itemFound)
        return;

      healthVersionsStatus.value[index] = {
        ...item,
        ...itemFound,
        ...statusConfig[itemFound.status],
      } as BasicTestsVersionItemType;
    });
  };

  function mapHealthItemsStatus(resumeItems: BasicTestsResumeItem[]) {
    healthItemsStatus.value.forEach((item, index) => {
      const itemFound = resumeItems.find((test: BasicTestsResumeItem) => test.test === item.test);

      if (!itemFound)
        return;

      healthItemsStatus.value[index] = {
        ...item,
        ...itemFound,
        ...statusConfig[itemFound.status],
      } as BasicTestsItemsType;
    });
  }

  const loadHealth = async (params?: SiteServiceHealthParams) => {
    if (!healthServiceId.value)
      return;

    try {
      const response = await api.site.service.health.retrieve(
        healthServiceId.value,
        params,
      );

      healthLastRequest.value = response.lastRequest;
      healthNextRequest.value = response.nextRequest;

      if (!params || params.only === 'basic_tests') {
        mapHealthVersionsStatus(response.resume)
      }

      if (!!params && params.only === 'critical') {
        criticalErrors.value = response.tests;
      }

      if (!params) {
        mapHealthItemsStatus(response.resume)

        healthTabItems.value = ['security', 'performance'].map(type => createHealthStatusItem(type, response.tests));
      }
    } catch (e: any) {
      console.error('Error loading site health', e);

      throw e;
    }
  }

  const loadInformation = async () => {
    if (!siteStore.currentSiteId)
      return;

    try {
      siteServerInformation.value = await api.site.service.health.retrieveInformation(siteStore.currentSiteId);
    } catch (e) {
      console.error('Error loading site information', e);
    }
  };

  const loadVulnerabilities = async (canShowHealth = false) => {
    if (!canShowHealth || !siteStore.currentSiteId)
      return;

    try {
      if (!!currentSiteTeamSlug.value && !!currentSiteSlug.value) {
        siteVulnerabilities.value = ((await api.site.service.vulnerabilities.retrieve(
          siteStore.currentSiteId,
        )).componentVulnerabilities);
      }
    } catch (e) {
      console.error('Error loading site vulnerabilities', e);
    }
  };

  const refreshHealth = async () => {
    if (!healthServiceId.value)
      return;

    try {
      await api.site.service.health.refresh(healthServiceId.value);

      toastSuccess('health.toastMessage');

      await api.mixpanel.track('Manual site health check', {
        'Website name': siteStore.currentSiteName,
        'Website url': siteStore.currentSiteUri,
      });
    } catch (e) {
      console.error('Error refreshing site health', e);
    }
  }

  return {
    healthServiceId,

    healthLastRequest,
    healthNextRequest,

    healthVersionsStatus,
    healthItemsStatus,

    criticalErrors,

    healthTabItems,
    siteServerInformation,
    siteVulnerabilities,

    loadHealth,
    loadInformation,
    loadVulnerabilities,
    refreshHealth,
  };
}

export { useSiteInformation };
export default useSiteInformation;
