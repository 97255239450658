<script lang="ts" setup>
import type { StrInterface } from '@/helpers/stringHelper';
import type { SiteReportSearchConsole } from '@/types/models/site/service/report/item/SiteReportSearchConsole';
import type {
  ISearchConsoleReport,
  SearchConsoleReportItemDataType,
} from '@/types/models/site/service/search-console/ISiteServiceSearchConsole';

import type { SearchConsoleStatusType } from '@/views/site/metrics/search-console/TheSiteSearchConsole.vue';
import type { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import type { Reactive, Ref } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

import useDate from '@/composables/useDate';

import { useAppStore } from '@/stores/appStore';
import { DateTime } from 'luxon';

import { computed, defineAsyncComponent, inject, onMounted, reactive, ref, toRefs } from 'vue';

import { useI18n } from 'vue-i18n';

defineOptions({
  name: 'VWidgetSearchConsole',
});

const props = withDefaults(defineProps<Props>(), {
  showTitle: false,
  showChart: true,
  dataSelectable: true,
  searchConsoleStatus: null,
  chartHeight: 225,
  classChartContainer: '',
  classMainPanel: 'mb-72',
  classGraphicStat: '',
  showMetricsDate: false,
  dateRange: 7,
});

const VChart = defineAsyncComponent(() => import('@/components/vendor/components/VChart.vue'));

const { t } = useI18n();
const $str: StrInterface = inject('str')!;

const { parseDateToPreferredFormat } = useDate();

type LocalChartDataset = ChartDataset & {
  id: string
  order: string
};

interface SearchConsoleDatasetType {
  id: string
  label: string
  backgroundColor: string
  borderColor: string
  borderWidth: number
  pointStyle: boolean
  data: number[]
}

export interface SearchConsoleDataType {
  clicks: SearchConsoleDatasetType
  impressions: SearchConsoleDatasetType
  ctr: SearchConsoleDatasetType
  position: SearchConsoleDatasetType
}

interface Props {
  searchConsole: ISearchConsoleReport | SiteReportSearchConsole
  searchConsoleStatus?: SearchConsoleStatusType | null
  startedDate: string
  endedDate: string
  showTitle?: boolean
  showChart?: boolean
  dataSelectable?: boolean
  chartHeight?: number
  classChartContainer?: string
  classMainPanel?: string
  classGraphicStat?: string
  showMetricsDate?: boolean
  dateRange?: number
}

const { searchConsoleStatus: localSearchConsoleStatus } = toRefs(props);

const chart: Ref<InstanceType<typeof VChart> | null> = ref(null);
const showGraphic = ref(false);

const datasets: SearchConsoleDataType = reactive({
  clicks: {
    id: 'clicks',
    order: 1,
    label: t('report.searchConsole.clicks'),
    backgroundColor: '#00BAFF',
    borderColor: '#00BAFF',
    borderWidth: 2,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    data: [],
  },
  impressions: {
    id: 'impressions',
    order: 2,
    label: t('report.searchConsole.impressions'),
    backgroundColor: '#DF25B8',
    borderColor: '#DF25B8',
    borderWidth: 2,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    data: [],
  },
  ctr: {
    id: 'ctr',
    order: 3,
    label: t('report.searchConsole.ctr'),
    backgroundColor: '#57D916',
    borderColor: '#57D916',
    borderWidth: 2,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    data: [],
  },
  position: {
    id: 'position',
    order: 4,
    label: t('report.searchConsole.position'),
    backgroundColor: '#FF7500',
    borderColor: '#FF7500',
    borderWidth: 2,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    data: [],
  },
});

const { darkMode } = useAppStore();

const ticksColor = computed(() => (darkMode ? '#D9DBE9' : '#6E7191'));

const lineChart = reactive({
  id: 'siteSearchConsoleChart',
  data: {
    labels: [],
    datasets: [] as LocalChartDataset[],
  } as ChartData,
  options: {
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        itemSort(a: any, b: any) {
          return b.order - a.order;
        },
      },
    },
    elements: {
      line: {
        cubicInterpolationMode: 'monotone',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: ticksColor.value,
        },
      },
      y: {
        type: 'logarithmic',
        beginAtZero: true,
        grace: '0%',
        ticks: {
          display: false,
          padding: 0,
        },
        grid: {
          display: false,
        },
      },
    },
  } as ChartOptions,
} as any);

const searchConsoleReportAll: Ref<SearchConsoleReportItemDataType | null> = ref(null);

function check(variable: string, updateChart = false) {
  if (props.dataSelectable) {
    if (!!localSearchConsoleStatus.value && localSearchConsoleStatus.value[variable as keyof SearchConsoleStatusType]) {
      lineChart.data.datasets.push(datasets[variable as keyof SearchConsoleDataType]);
    } else {
      const indexToDelete = lineChart.data.datasets.findIndex((dataset: LocalChartDataset) => dataset.id === variable);
      lineChart.data.datasets.splice(indexToDelete, 1);
    }

    if (lineChart.data.datasets.length === 1) {
      lineChart.options.scales.y.ticks.display = true;
      lineChart.options.scales.y.type = 'linear';
    } else {
      lineChart.options.scales.y.ticks.display = false;
      lineChart.options.scales.y.type = 'logarithmic';
    }

    if (!!chart.value && updateChart) {
      chart.value.update();
    }
  } else {
    lineChart.data.datasets.push(datasets[variable as keyof SearchConsoleDataType]);
  }
}

async function loadLineGraphic() {
  showGraphic.value = false;

  lineChart.data.labels = [];
  datasets.clicks.data = [];
  datasets.impressions.data = [];
  datasets.ctr.data = [];
  datasets.position.data = [];

  // Build new data
  if (
    !!props.searchConsole
    && !!props.searchConsole.graphDates
    && !!props.searchConsole.graphDates.data
    && props.searchConsole.graphDates.data.length > 0
  ) {
    props.searchConsole.graphDates.data.forEach((item: SearchConsoleReportItemDataType) => {
      if (!!item.date) {
        lineChart.data.labels.push(parseDateToPreferredFormat(item.date, 'd MMM'));

        datasets.clicks.data.push(item.clicks);
        datasets.impressions.data.push(item.impressions);
        datasets.ctr.data.push(item.ctr);
        datasets.position.data.push(item.position);
      }
    });

    check('clicks');
    check('impressions');
    check('ctr');
    check('position');

    if (!!chart.value) {
      chart.value.update();
    }

    showGraphic.value = true;
  } else {
    let date = DateTime.fromISO(props.startedDate);
    const endDate = DateTime.fromISO(props.endedDate);

    while (date <= endDate) {
      lineChart.data.labels.push(parseDateToPreferredFormat(date, 'd MMM'));

      date = date.plus({ day: 1 });
    }

    const numLabels = lineChart.data.labels.length;

    lineChart.data.datasets.forEach((dataset: LocalChartDataset) => {
      dataset.data = new Array(numLabels).fill(0);
    });

    lineChart.options.scales.y.type = 'linear';

    showGraphic.value = true;
  }
}

const searchConsoleItems: Reactive<
  {
    id: string
    label: string
    btnColor: string
    handlerClick: () => void
    tooltipText: string
    graphicStatTitle: (searchConsole: SearchConsoleReportItemDataType | null) => string
  }[]
> = reactive([
  {
    id: 'clicks',
    label: 'report.searchConsole.totalClicks',
    btnColor: 'btn-statistics-ocean',
    handlerClick: () => {
      if (props.dataSelectable && !!localSearchConsoleStatus.value) {
        localSearchConsoleStatus.value.clicks = !localSearchConsoleStatus.value.clicks;
        check('clicks', true);
      }
    },
    tooltipText: 'report.searchConsole.clicksTooltip',
    graphicStatTitle: (searchConsole: SearchConsoleReportItemDataType) =>
      !!searchConsole && searchConsole.clicks ? $str.formatNumberToCompact(searchConsole.clicks) : '0',
  },
  {
    id: 'impressions',
    label: 'report.searchConsole.impressions',
    btnColor: 'btn-statistics-magenta',
    handlerClick: () => {
      if (props.dataSelectable && !!localSearchConsoleStatus.value) {
        localSearchConsoleStatus.value.impressions = !localSearchConsoleStatus.value.impressions;
        check('impressions', true);
      }
    },
    tooltipText: 'report.searchConsole.impressionsTooltip',
    graphicStatTitle: (searchConsole: SearchConsoleReportItemDataType) =>
      !!searchConsole && searchConsole.impressions ? $str.formatNumberToCompact(searchConsole.impressions) : '0',
  },
  {
    id: 'ctr',
    label: 'report.searchConsole.averageCtr',
    btnColor: 'btn-statistics-lime',
    handlerClick: () => {
      if (props.dataSelectable && !!localSearchConsoleStatus.value) {
        localSearchConsoleStatus.value.ctr = !localSearchConsoleStatus.value.ctr;
        check('ctr', true);
      }
    },
    tooltipText: 'report.searchConsole.ctrTooltip',
    graphicStatTitle: (searchConsole: SearchConsoleReportItemDataType) =>
      !!searchConsole && searchConsole.ctr ? $str.formatNumber(searchConsole.ctr, $str.numberFormats.PERCENTAGE) : '0',
  },
  {
    id: 'position',
    label: 'report.searchConsole.averagePosition',
    btnColor: 'btn-statistics-tiger',
    handlerClick: () => {
      if (props.dataSelectable && !!localSearchConsoleStatus.value) {
        localSearchConsoleStatus.value.position = !localSearchConsoleStatus.value.position;
        check('position', true);
      }
    },
    tooltipText: 'report.searchConsole.positionTooltip',
    graphicStatTitle: (searchConsole: SearchConsoleReportItemDataType) =>
      !!searchConsole && searchConsole.position ? $str.formatDecimals(searchConsole.position, 2) : '0',
  },
]);

onMounted(() => {
  searchConsoleReportAll.value = props.searchConsole.all.data;

  loadLineGraphic();
});
</script>

<template>
  <div class="search-console-body">
    <div v-if="showTitle" class="d-flex justify-content-between align-items-center mb-24">
      <div class="metrics-title fw-semi">
        {{ $t('site.overview.searchConsole.title') }}
      </div>

      <div v-if="showMetricsDate" class="metrics-date fw-medium text-sm text-gray-500">
        {{ $t('site.overview.metrics.since', { date_range: dateRange }) }}
      </div>
    </div>

    <div :class="classMainPanel" class="row gx-8">
      <div
        v-for="item in searchConsoleItems"
        :key="`metric-${item.id}`"
        class="col-12 col-md-6 col-xl-3 col-print mb-xl-0 mb-8"
      >
        <div class="position-relative">
          <component
            :is="dataSelectable ? VButton : 'div'"
            :aria-label="$t(item.label)"
            :class="[
              item.btnColor,
              {
                active:
                  dataSelectable
                  && !!localSearchConsoleStatus
                  && localSearchConsoleStatus[item.id as keyof SearchConsoleStatusType],
              },
            ]"
            class="btn-statistics d-flex flex-column w-100 px-16 pb-8 pt-12 shadow-none"
            @click="item.handlerClick"
          >
            <span class="w-100 d-flex align-items-center justify-content-between fw-medium py-2">
              <v-checkbox
                v-if="dataSelectable && !!localSearchConsoleStatus"
                :label="$t(item.label)"
                :model-value="localSearchConsoleStatus[item.id as keyof SearchConsoleStatusType]"
                class="report-item-check d-flex align-items-center stretched-link mb-0 ps-0"
                input-class="mt-0"
                label-class="text-xs ps-20"
                size="sm"
              />
              <span v-else class="text-xs">{{ $t(item.label) }}</span>

              <m-icon
                v-tooltip="{
                  content: $t(item.tooltipText),
                  html: true,
                }"
                class="d-print-none text-gray-500"
                icon="circle-actions-alert-question"
              />
            </span>

            <span
              :class="classGraphicStat"
              :title="item.graphicStatTitle(searchConsoleReportAll)"
              class="graphic-stat lh-lg fw-bold"
            >
              {{ item.graphicStatTitle(searchConsoleReportAll) }}
            </span>
          </component>
        </div>
      </div>
    </div>

    <div v-if="showChart" :class="classChartContainer" class="search-console-chart-container">
      <VChart
        v-if="showGraphic"
        id="site-search-console-chart"
        ref="chart"
        :data="lineChart.data"
        :height="chartHeight"
        :options="lineChart.options"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-console-body {
  .search-console-chart-container {
    height: 13.5rem;

    &.search-console-chart-container-sm {
      height: 9rem !important;
    }

    &:deep(#site-search-console-chart) {
      width: 100% !important;
    }
  }

  @media print {
    .col-print {
      flex: 0 0 auto !important;
      width: 25% !important;
    }
  }

  .btn-statistics {
    border: rem(1) solid var(--md-gray-400) !important;
    border-radius: 0 0 rem(4) rem(4);

    &::after {
      content: '';
      height: 6px;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      transition: background-color 0.15s ease-out;
    }

    &.btn-statistics-tiger::after {
      background-color: var(--md-tiger);
    }

    &.btn-statistics-lime::after {
      background-color: var(--md-lime);
    }

    &.btn-statistics-ocean::after {
      background-color: var(--md-ocean);
    }

    &.btn-statistics-magenta::after {
      background-color: var(--md-pink);
    }

    &.btn:not(.active) {
      color: var(--md-gray-600);

      &::after {
        background-color: var(--md-gray-500);
      }
    }
  }
}
</style>
