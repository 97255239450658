<script lang="ts" setup>
import type { MenuItem } from '@/components/site/menu/TheSiteMainMenu.vue';

import { ref } from 'vue';

defineOptions({
  name: 'TheSiteManagementMenu',
});

const menuItems = ref<MenuItem[]>([
  {
    id: 'site-settings-menu',
    icon: 'settings',
    label: 'menu.siteSettings',
    ariaLabel: 'menu.siteSettings',
    route: { name: 'sites.site.settings.show' },
    isNewFeature: false,
  },
]);
</script>

<template>
  <div class="sidebar-nav pt-8">
    <p class="sidebar-heading text-uppercase fw-semi mb-8 ps-16 text-gray-500">
      {{ $t('menu.siteManagement') }}
    </p>

    <ul id="site-management" class="nav">
      <li v-for="item in menuItems" :id="item.id" :key="item.id" class="nav-item" role="presentation">
        <router-link :aria-label="$t(item.ariaLabel)" :class="item.class" :to="item.route" class="nav-link py-12">
          <m-icon :icon="item.icon" aria-hidden="true" />
          <span class="text-sm">{{ $t(item.label) }}</span>

          <new-badge v-if="item?.isNewFeature" />
        </router-link>
      </li>
    </ul>
  </div>
</template>
