import type { PermissionEnumType } from '@/types/PermissionEnum';
import useUserStore from '@/stores/userStore';

/**
 * Checks if a given permission exists in the predefined array of permission keys.
 *
 * @returns boolean - Returns true if the permission exists, otherwise false.
 * @param permission
 */
export function can(permission: string) {
  const userStore = useUserStore();
  const permissions = computed(() => userStore.permissions);

  return permissions.value.includes(permission);
}
