import type { Invoice } from '@/types/models/organization/subscription/invoice/Invoice';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class InvoiceService extends AbstractService {
  /**
   * retrieves all the invoices associated with the organization's billing account.
   *
   * @returns {Promise<Invoice[]>}
   */
  public all(): Promise<Invoice[]> {
    return this.request({
      method: apiEndpoints.invoicesIndex.method,
      url: this.buildPath(apiEndpoints.invoicesIndex),
    });
  }

  /**
   * Returns a download link for the PDF of the specified invoice.
   *
   * @param {number} invoice
   * @returns {Promise<any>}
   */
  public download(invoice: number | string): Promise<any> {
    return this.request({
      method: apiEndpoints.invoicesDownload.method,
      url: this.buildPath(apiEndpoints.invoicesDownload, {
        invoice,
      }),
      options: {
        responseType: 'blob',
      },
    });
  }
}
