<script lang="ts" setup>
import type { ProductPrice, StripeProduct } from '@/types/models/organization/billing/product/StripeProduct';
import useModal from '@/composables/useModal';
import useAppStore from '@/stores/appStore';

import useBillingStore from '@/stores/billingStore';
import { BillingProductTypeEnumConst } from '@/types/enums/BillingProductTypeEnum';
import { watchImmediate } from '@vueuse/core';

import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import { useRoute, useRouter } from 'vue-router';

defineOptions({
  name: 'VCheckoutLayout',
});

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const { PLAN, ADDON } = BillingProductTypeEnumConst;

const isInPlanSelectionPage = computed(() => route.name === 'billing.plans.select');
const isInCheckoutPage = computed(() => route.name === 'billing.plans.checkout');
const isInCheckoutConfirmPage = computed(() => route.name === 'billing.plans.checkout.confirm');

const billingStore = useBillingStore();

const { products, addons, selectedPlan, selectedPrice, billingAccount } = storeToRefs(billingStore);

const productIsPlan = computed(() => selectedPlan.value?.type === PLAN);

const { Modal } = useModal();

async function goBack() {
  if (isInCheckoutPage.value) {
    Modal.delete({
      title: productIsPlan.value ? t('billing.checkout.modal.planTitle') : t('billing.checkout.modal.addonTitle'),
      alertTitle: productIsPlan.value ? t('billing.checkout.modal.planText') : t('billing.checkout.modal.addonText'),
      text: '',
      leftButtonText: t('billing.checkout.modal.forTimeNot'),
      leftButtonLoadingText: t('billing.checkout.modal.forTimeNot'),
      leftButtonCallback: async () => {
        if (route.query.origin) {
          await router.push(String(route.query.origin));
        } else {
          await router.push({ name: 'billing.show' });
        }
      },
      rightButtonText: t('billing.checkout.modal.continuePurchase'),
    });
  } else if (route.query.origin) {
    await router.push(String(route.query.origin));
  } else {
    await router.push({ name: 'billing.show' });
  }
}

/**
 * Tries to reload the selected product and price from the query params
 * if type is addon, it will look for the selected product in the addons list
 */
function reloadSelectedProduct() {
  const { plan, price, type }: any = router.currentRoute.value.query;
  if (plan && price && !selectedPlan.value && !selectedPrice.value && (!!products.value || !!addons.value)) {
    const localType = type || PLAN;

    if (localType === ADDON) {
      if (!!addons.value && addons.value.length > 0) {
        const addonSelected = addons.value.find((p: StripeProduct) => p.id === plan);
        if (!!addonSelected) {
          selectedPlan.value = addonSelected;
        }
      }

      if (!!selectedPlan.value && !!selectedPlan.value.prices && selectedPlan.value.prices.length > 0) {
        const priceSelected = selectedPlan.value?.prices.find((p: ProductPrice) => p.id === price);
        if (!!priceSelected) {
          selectedPrice.value = priceSelected;
        }
      }
    } else {
      if (!!products.value && products.value.length > 0) {
        const productSelected = products.value.find((p: StripeProduct) => p.id === plan);
        if (!!productSelected) {
          selectedPlan.value = productSelected;
        }
      }

      if (!!selectedPlan.value && !!selectedPlan.value.prices && selectedPlan.value.prices.length > 0) {
        const priceSelected = selectedPlan.value?.prices.find((p: ProductPrice) => p.id === price);
        if (!!priceSelected) {
          selectedPrice.value = priceSelected;
        }
      }
    }
  }
}

watchEffect(() => {
  if (!!products.value || !!addons.value) {
    reloadSelectedProduct();
  }
});

/**
 * Scrolls to the checkout modal body when the route changes to avoid CLS
 */
watchImmediate(
  () => route.name,
  () => {
    const container = document.getElementById('checkout-modal-body');

    if (container) {
      setTimeout(() => {
        container.scrollIntoView({ behavior: 'instant' });
      }, 300);
    }
  },
);

const appStore = useAppStore();
const { darkMode } = storeToRefs(appStore);

onMounted(() => {
  if (!products.value) {
    billingStore.getProducts();

    if (!billingAccount.value) {
      billingStore.getBilling();
    }
  }
});

onUnmounted(() => {
  selectedPlan.value = null;
  selectedPrice.value = null;
});
</script>

<template>
  <main class="position-relative">
    <v-modal
      id="the-billing-plans"
      backdrop="false"
      :backdrop-static="false"
      size="fullscreen"
      content-class="p-0"
      disable-teleport
      modal-classes="modal-fullscreen-index"
    >
      <modal-body class="p-0" style="overflow-x: hidden">
        <modal-title
          :class="{ 'bg-primary': isInPlanSelectionPage }"
          class="position-relative z-index-2 text-xxl fw-bold px-40 py-32"
        >
          <div class="container-fluid d-flex justify-content-between">
            <v-picture v-if="isInPlanSelectionPage" class="modular-logo">
              <img
                v-if="darkMode"
                alt="Logo ModularDS"
                src="@assets/img/ModularDS-logo.png"
                srcset="@assets/img/ModularDS-logo.png 2x, @assets/img/ModularDS-logo.png 1x"
              >
              <img
                v-else
                alt="Logo ModularDS"
                src="@assets/img/ModularDS-logo-light.png"
                srcset="@assets/img/ModularDS-logo-light.png 2x, @assets/img/ModularDS-logo-light.png 1x"
              >
            </v-picture>

            <v-picture v-else class="modular-logo">
              <img
                v-if="!darkMode"
                alt="Logo ModularDS"
                src="@assets/img/ModularDS-logo.png"
                srcset="@assets/img/ModularDS-logo.png 2x, @assets/img/ModularDS-logo.png 1x"
              >
              <img
                v-else
                alt="Logo ModularDS"
                src="@assets/img/ModularDS-logo-light.png"
                srcset="@assets/img/ModularDS-logo-light.png 2x, @assets/img/ModularDS-logo-light.png 1x"
              >
            </v-picture>

            <VButton
              v-if="!isInCheckoutConfirmPage"
              :aria-label="$t('general.modal.close')"
              :class="{ 'text-light': isInPlanSelectionPage, 'text-primary': isInCheckoutPage }"
              :title="$t('general.modal.close')"
              class="btn-link fw-medium z-index-10 d-block"
              size="sm"
              @click="goBack()"
            >
              {{ $t('general.modal.close') }}
            </VButton>
          </div>
        </modal-title>

        <div id="checkout-modal-body" class="container-fluid w-100 px-32">
          <router-view v-slot="{ Component }">
            <transition appear mode="out-in" name="fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </modal-body>

      <modal-footer id="checkout-modal-footer" :class="isInCheckoutConfirmPage ? 'pb-xl-56' : 'p-0'" />
    </v-modal>
  </main>
</template>

<style lang="scss" scoped>
#the-billing-plans {
  .modular-logo :deep(img) {
    max-width: 9rem;
  }

  .modal-body {
    scrollbar-gutter: stable;
  }
}
</style>
