<script lang="ts" setup>
import type { SiteBackupStatusEnumKeys, SiteBackupStatusEnumType } from '@/types/enums/SiteBackupStatusEnum';
import type { Ref } from 'vue';
import useBackups from '@/composables/backups/useBackups';
import { useBackupsSocket } from '@/composables/backups/useBackupsSocket';
import useDate from '@/composables/useDate';

import useModal from '@/composables/useModal';
import usePermissions from '@/composables/usePermissions';
import useRedirectToPayment from '@/composables/useRedirectToPayment';

import useConstantsHelper from '@/resources/constants/ConstantsHelper';
import useOrganizationStore from '@/stores/organizationStore';
import { useBackupStore } from '@/stores/site/backup/backupStore';
import useSiteStore from '@/stores/site/siteStore';

import useSocketStore from '@/stores/socketStore';
import { SiteBackupStatusEnumConst } from '@/types/enums/SiteBackupStatusEnum';
import TheSiteBackupCreateModal from '@/views/site/backup/modules/TheSiteBackupCreateModal.vue';
import { watchImmediate } from '@vueuse/core';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineOptions({
  name: 'TheSiteOverviewBackups',
});

defineProps<{
  site: string
}>();

const { PENDING, IN_PROGRESS, UPLOADING } = SiteBackupStatusEnumConst;

const { redirectToPayment } = useRedirectToPayment();
const { stringConstant } = useConstantsHelper();
const { parseDateToPreferredFormat } = useDate();
const { openModal, modalToShow, closeModal } = useModal();
const { can } = usePermissions();

const userCanManualBackup = computed(() => can(PermissionEnumConst.ORGANIZATION_BACKUP_MANUAL));

const backupStore = useBackupStore();
const { backups, lastBackups, loadedBackups, loadedBackupsService } = storeToRefs(backupStore);

const siteStore = useSiteStore();
const { currentSiteId } = storeToRefs(siteStore);

const backupsIsConfigured = computed(() => backupStore.backupsIsConfigured);

const organizationStore = useOrganizationStore();
const { storagePercentage, storagePercentageLeft } = storeToRefs(organizationStore);

const socketStore = useSocketStore();
const socketSite = computed(() => socketStore.site);
const backupStatusSocket = computed(() => socketSite.value?.siteBackup());

const hasBackupLoaded = computed(() => !!backups.value && Object.keys(backups.value).length > 0);
const totalBackupsCount = computed(() => (!!backups.value ? backups.value.length : 0));
const areAllRequestsLoaded = computed(() => loadedBackups.value && loadedBackupsService.value);

const status = ref('loading');
const lastBackupDate = ref('');
const lastBackupStatus: Ref<SiteBackupStatusEnumType | null> = ref(null);
const computedStorageBackgroundColor = computed(() => ({
  'bg-danger': storagePercentage.value >= 90,
  'bg-warning': storagePercentage.value >= 70 && storagePercentage.value <= 90,
  'bg-success-dark': storagePercentage.value < 70,
}));

// TODO No debería de ser async, pero si ponemos un then en loadBackups hay que devovler un resolve()
async function mapLastBackup() {
  if (!!backups.value && backups.value.length > 0) {
    if (backups.value.length > 0) {
      lastBackupDate.value = backups.value[0].createdAt;
      lastBackupStatus.value = backups.value[0].status;
    }
  }

  status.value = 'loaded';
}

const { loadBackups, loadBackupsService } = useBackups();

async function reloadBackups() {
  if (!!currentSiteId.value) {
    await loadBackups();

    await mapLastBackup();
  }
}

function openCreateBackup() {
  if (userCanManualBackup.value) {
    openModal('the-site-backup-create-modal');
  }
}

onBeforeUnmount(() => {
  if (!!socketSite.value) {
    backupStatusSocket.value?.stop();
  }
});

const { updateBackupStatus } = useBackupsSocket()

watchImmediate(socketSite, () => {
  if (!!socketSite.value) {
    backupStatusSocket.value?.listenBackupStatus(async (data) => {
      await updateBackupStatus({
        backupId: data.id,
        status: data.status as SiteBackupStatusEnumKeys,
        isLastBackups: true,
      });
    });
  }
});

watchImmediate(
  () => backupStore.backupsIsConfigured,
  async (isConfigured, wasConfigured) => {
    if (isConfigured) {
      loadedBackupsService.value = false;

      await Promise.all([await loadBackups(), await loadBackupsService()]);
    }

    if (!isConfigured && !wasConfigured) {
      loadedBackupsService.value = true;
    }
  },
);
</script>

<template>
  <div id="site-overview-backups" class="card mb-32" data-cy="site-overview-backups">
    <div class="card-body backups-body position-relative">
      <div
        v-if="!areAllRequestsLoaded && backupsIsConfigured"
        class="backups-content placeholder-glow"
      >
        <div class="d-flex justify-content-between align-items-center mb-16">
          <span class="placeholder text-normal w-40" />
        </div>

        <div
          class="d-flex justify-content-between align-items-xxl-center flex-column flex-lg-row flex-xl-column flex-xxl-row"
        >
          <div class="d-flex align-items-center mb-lg-0 mb-xl-24 mb-xxl-0 mb-24">
            <span class="placeholder" style="width: 80px; height: 80px" />

            <div class="last-backups lh-xl fw-medium px-24 text-xs">
              <div class="placeholder col-7 mb-8" />

              <div
                v-for="n in 3"
                :key="n"
                class="backup-item position-relative d-flex justify-content-between align-items-center mb-4 pb-4 text-gray-600"
              >
                <span class="placeholder w-70" />

                <div class="d-flex align-items-center w-20">
                  <span class="placeholder w-100" />
                </div>
              </div>
            </div>
          </div>

          <div class="placeholder w-60" style="height: 50px" />
        </div>
      </div>

      <div v-else class="backups-content">
        <div class="d-flex justify-content-between align-items-center mb-16">
          <h4 class="text-normal fw-semi mb-0">
            <span class="me-8">{{ $t('site.overview.backups.title') }}</span>

            <router-link
              v-if="hasBackupLoaded"
              :to="{ name: 'sites.site.backup.index' }"
              class="btn-link text-decoration-underline fw-semi"
            >
              {{ totalBackupsCount }}
            </router-link>
          </h4>

          <div
            v-if="!!lastBackupDate && userCanManualBackup"
            class="fw-medium d-flex align-items-center text-sm text-gray-600"
          >
            <v-icon icon="calendar" space="me-8" />
            {{
              $t('site.overview.backups.lastBackupDate', {
                date: parseDateToPreferredFormat(lastBackupDate, 'd MMM yyyy, T'),
              })
            }}
          </div>
        </div>

        <div
          class="d-flex justify-content-between align-items-xxl-center flex-column flex-lg-row flex-xl-column flex-xxl-row"
        >
          <div class="d-flex align-items-center mb-lg-0 mb-xl-24 mb-xxl-0 mb-24">
            <v-picture class="md-backups-image-container" height="72" lazy width="80">
              <img
                :alt="$t('site.overview.backups.title')"
                class="backups-image"
                src="../../../../../resources/img/data_storage.png"
                srcset="../../../../../resources/img/data_storage.png 2x, @assets/img/data_storage.png 1x"
              >
            </v-picture>

            <template v-if="hasBackupLoaded && !!lastBackups && lastBackups.length > 0">
              <div v-if="userCanManualBackup" class="last-backups lh-xl fw-medium px-24 text-xs">
                <div class="mb-8 text-gray-500">
                  {{ $t('site.overview.backups.lastBackups') }}
                </div>

                <div
                  v-for="backup in lastBackups"
                  :key="backup.id"
                  :title="$t(backup.state?.name!)"
                  class="backup-item position-relative d-flex justify-content-between align-items-center mb-4 pb-4 text-gray-600"
                >
                  <span class="me-4">
                    {{
                      `${parseDateToPreferredFormat(backup.createdAt, 'd MMM yyyy, T')} ${$t('general.shared.hours')}`
                    }}
                  </span>

                  <div class="d-flex align-items-center">
                    <span>{{ $str.formatBytes(backup.size) }}</span>

                    <v-icon :class="backup.state?.color" :icon="backup.state?.icon" size="sm" space="ms-12" />
                  </div>
                </div>
              </div>

              <div v-else class="lh-xl fw-medium px-24 text-xs">
                <div class="d-flex align-items-center mb-8">
                  <span class="me-8 text-gray-500">
                    {{ $t('site.overview.backups.lastBackup') }}
                  </span>

                  <span class="text-gray-600">
                    <template
                      v-if="
                        lastBackupStatus === PENDING
                          || lastBackupStatus === IN_PROGRESS
                          || lastBackupStatus === UPLOADING
                      "
                    >
                      {{ $t('site.overview.backups.inProgress') }}
                    </template>

                    <template v-else>{{ parseDateToPreferredFormat(lastBackupDate, 'd MMM yyyy, T') }}</template>
                  </span>

                  <v-icon
                    v-if="
                      lastBackupStatus === PENDING || lastBackupStatus === IN_PROGRESS || lastBackupStatus === UPLOADING
                    "
                    class="text-success-dark rotate"
                    icon="refresh"
                    size="sm"
                    space="ms-12"
                  />
                </div>

                <div class="d-flex flex-column flex-xl-row align-items-xl-center mb-8">
                  <div class="d-flex align-items-center">
                    <span class="me-8 text-gray-500">
                      {{ $t('site.overview.backups.availableSpace') }}
                      <span class="text-gray-600">{{ storagePercentageLeft }}%</span>
                    </span>

                    <div class="progress me-8 bg-gray-300">
                      <div
                        :class="computedStorageBackgroundColor"
                        :style="{ width: `${storagePercentage}%` }"
                        aria-labelledby="usedStorage"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        aria-valuenow="5"
                        class="progress-bar rounded-xs"
                        role="progressbar"
                      />
                    </div>
                  </div>

                  <VButton
                    class="text-2xs fw-medium"
                    variant="link"
                    @click="redirectToPayment({ trackName: 'Overview increase space backups' })"
                  >
                    {{ $t('site.overview.backups.increase') }}
                  </VButton>
                </div>
              </div>
            </template>

            <div v-else class="lh-xl ms-xxl-36 me-24 ms-16 text-sm text-gray-500">
              {{ $t('site.overview.backups.noBackups') }}
            </div>
          </div>

          <template v-if="hasBackupLoaded">
            <VButton
              v-if="userCanManualBackup"
              class="btn-icon fw-semi position-relative me-lg-0 ms-auto h-fit text-nowrap text-sm"
              size="xs"
              variant="outline-gray"
              @click="openCreateBackup"
            >
              <v-icon class="text-success" icon="database" space="me-8" />
              <span class="lh-xl">{{ $t('site.backup.createBackup') }}</span>
            </VButton>

            <VButton
              v-else
              class="btn-icon fw-semi position-relative me-lg-0 ms-auto h-fit text-nowrap text-sm"
              size="xs"
              variant="outline-gray"
              @click="redirectToPayment({ trackName: 'Overview Create backup now' })"
            >
              <v-icon class="text-success" icon="database" size="lg" space="me-8" />

              <span class="lh-xl">{{ $t('site.overview.backups.createBackupNow') }}</span>

              <span
                v-if="!userCanManualBackup"
                class="position-absolute translate-middle-y badge bg-success text-uppercase fw-medium rounded-xs end-0 top-0 text-xs"
              >
                {{ $t('general.shared.proVersion') }}
                <span class="visually-hidden">{{ $t('general.shared.proVersion') }}</span>
              </span>
            </VButton>
          </template>

          <router-link
            v-else
            :to="{ name: 'sites.site.backup.update' }"
            class="btn btn-outline-gray btn-icon btn-xs fw-semi position-relative text-nowrap text-sm"
            data-cy="btn-site-overview-configure-backups"
          >
            <v-icon class="text-success" icon="database" size="lg" space="me-8" />
            <span class="lh-xl">{{ $t('site.overview.backups.configureBackup') }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <TheSiteBackupCreateModal
      :open="modalToShow === 'the-site-backup-create-modal'"
      @closed="closeModal"
      @refresh-backups="reloadBackups"
    />
  </div>
</template>

<style lang="scss" scoped>
.backups-body {
  min-height: 11.5rem;

  .md-backups-image-container,
  :deep(.md-backups-image-container .backups-image) {
    width: 5rem;
  }

  .last-backups {
    min-width: 17.5rem;
  }

  .progress {
    width: 6.5rem;
  }

  .backup-item {
    &:not(:last-child)::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: var(--md-gray-400);
    }
  }
}
</style>
