<script lang="ts" setup>
import type { SiteStatusEnumKeys } from '@/types/enums/SiteStatusEnum';
import type { LighthouseReport } from '@/types/models/site/service/performance/LighthouseReport';
import type { Ref } from 'vue';

import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import useSiteStore from '@/stores/site/siteStore';

import { SiteStatusEnumConst } from '@/types/enums/SiteStatusEnum';

import TheSiteLighthouseReportPerformance
  from '@/views/site/overview/lighthouse/parts/TheSiteLighthouseReportPerformance.vue';
import VSiteLighthouseReportPerformanceProgressCircular
  from '@/views/site/overview/lighthouse/parts/VSiteLighthouseReportPerformanceProgressCircular.vue';
import { useElementVisibility } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';

defineOptions({
  name: 'TheSiteLighthouseReport',
});

const { PENDING, PLUGIN_LOGGED_IN, PLUGIN_INSTALLED, PLUGIN_ACTIVATED, ESTABLISHED_CREDENTIALS }
  = SiteStatusEnumConst;

const siteStore = useSiteStore();

const { currentSite } = storeToRefs(siteStore);
// TODO Implementar algo así en vez de verificar el estado sitio para sólo pedirlo cuando NO lo esté haciendo
// const isConnectingSite = computed(() => siteStore.isConnectingSite);

const report: Ref<LighthouseReport | null> = ref(null);
const status = ref('loading');

const connectionProcessStates: Ref<SiteStatusEnumKeys[]> = ref([
  PENDING,
  PLUGIN_LOGGED_IN,
  PLUGIN_INSTALLED,
  PLUGIN_ACTIVATED,
  ESTABLISHED_CREDENTIALS,
]);

async function loadReport() {
  if (currentSite.value?.id) {
    report.value = await api.site.service.performance.retrieve(currentSite.value.id);

    status.value = 'loaded';
  }
}

const performanceWidget = ref<HTMLElement | null>(null);
const targetIsVisible = useElementVisibility(performanceWidget);

watch([currentSite, targetIsVisible], ([newVal, isVisible]) => {
  if (!!newVal && !report.value && isVisible && !connectionProcessStates.value.includes(newVal.connectionStatus)) {
    loadReport();
  }
});

onMounted(() => {
  loadReport();
});
</script>

<template>
  <div id="lighthouse-container" ref="performanceWidget" class="position-relative">
    <div v-if="status === 'loading'">
      <div
        class="performance-section d-flex flex-column flex-lg-row align-items-center justify-content-between w-100 border-0
          bg-transparent"
      >
        <div class="d-flex align-items-center mb-lg-0 mb-24">
          <div class="placeholder-glow d-flex flex-column align-items-start me-40">
            <span class="fw-semi placeholder mb-4" style="width: 104px" />
            <span class="placeholder text-xs text-gray-500" style="width: 60px" />
          </div>

          <div class="d-flex">
            <div class="d-flex align-items-center pe-24">
              <span class="icon-device-phone text-xxl icon me-8 text-gray-500" />
              <div
                class="circular position-relative d-flex align-items-center justify-content-center rounded-pill"
                style="background-color: rgb(229 229 229)"
              >
                <div>
                  <div
                    class="circular-content position-absolute top-50 start-50 translate-middle fw-medium text-nowrap text-sm"
                    style="color: rgb(160 163 189)"
                  >
                    --
                  </div>
                  <svg class="cvw-svg" height="48" viewBox="0 0 48 48" width="48">
                    <circle
                      class="progress"
                      cx="24"
                      cy="24"
                      fill="none"
                      r="22"
                      stroke="#a0a3bd"
                      stroke-dasharray="0"
                      stroke-dashoffset="0"
                      stroke-width="3"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <span class="icon-device-desktop text-xxl icon me-8 text-gray-500" />
              <div
                class="circular position-relative d-flex align-items-center justify-content-center rounded-pill"
                style="background-color: rgb(229 229 229)"
              >
                <div>
                  <div
                    class="circular-content position-absolute top-50 start-50 translate-middle fw-medium text-nowrap text-sm"
                    style="color: rgb(160 163 189)"
                  >
                    --
                  </div>
                  <svg class="cvw-svg" height="48" viewBox="0 0 48 48" width="48">
                    <circle
                      class="progress"
                      cx="24"
                      cy="24"
                      fill="none"
                      r="22"
                      stroke="#a0a3bd"
                      stroke-dasharray="0"
                      stroke-dashoffset="0"
                      stroke-width="3"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center placeholder-glow">
          <span class="fw-medium placeholder me-8 text-xs text-gray-500" style="width: 150px" />
        </div>
      </div>
    </div>

    <template v-if="status === 'loaded'">
      <div
        v-if="!report?.mobile"
        class="performance-section d-flex align-items-center justify-content-between w-100 border-0 bg-transparent"
      >
        <div class="d-flex align-items-center mb-lg-0 mb-24">
          <span class="d-flex flex-column align-items-start me-xl-16 me-xxl-40 me-40">
            <span class="fw-semi">{{ $t('site.overview.lighthouse.performance') }}</span>
            <span class="text-xs text-gray-500">{{ $t('site.overview.lighthouse.noInformation') }}</span>
          </span>

          <div class="d-flex">
            <div class="d-flex align-items-center pe-24">
              <VIcon class="text-gray-500" icon="device-phone" size="xxl" space="me-8" />
              <VSiteLighthouseReportPerformanceProgressCircular :valid-report="false" />
            </div>

            <div class="d-flex align-items-center">
              <VIcon class="text-gray-500" icon="device-desktop" size="xxl" space="me-8" />
              <VSiteLighthouseReportPerformanceProgressCircular :valid-report="false" />
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-column-reverse flex-xxl-row ms-16">
          <span class="fw-medium me-xl-0 me-xxl-8 me-8 text-xs text-gray-500">
            {{ $t('site.overview.lighthouse.mayLastDays') }}
          </span>

          <span class="badge text-bg-primary fw-semi rounded-xs mb-xl-8 mb-xxl-0 mb-0 px-12 py-8 text-xs">
            {{ $t('site.overview.lighthouse.calculating') }}
          </span>
        </div>
      </div>

      <TheSiteLighthouseReportPerformance v-else :report="report" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
#lighthouse-container {
  min-height: 3rem;
}
</style>
