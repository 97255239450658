export default {
  payment: {
    createPublicLink: 'Create public procurement link',
    editPublicLink: 'Edit the public procurement link',
    createLink: 'Create link',
    linkCreated: 'Link created',
    noLinks: 'No recruitment links',
    product: 'Product',
    selectProduct: 'Select a product',
    daysToExpire: 'Days to expire',
    client: 'Customer',
    chooseClient: 'Select a customer',
    chooseCountry: 'Select a country',
    hiring: 'Recruitment',
    newLink: 'New link',
    code: 'Code',
    creationDate: 'Creation date',
    expireDate: 'Expiry date',
  },
  users: {
    update: 'Update user',
    noUsers: 'No users',
    usersManagement: 'Users management',
  },
};
