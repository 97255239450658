import { createPopper } from '@popperjs/core';

export function useVueSelectPosition(dropdownList: any, component: any, { width }: any) {
  dropdownList.style.width = width;

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle('drop-up', state.placement === 'top');
        },
      },
    ],
  });

  return () => popper.destroy();
}
export default {
  calculateSelectPosition: useVueSelectPosition,
};
