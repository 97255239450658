import type { IUptimeServiceStoreParams, IUptimeStatsRetrieveParams } from '@/api/services/site/service/uptime';
import type { SiteServiceUptime } from '@/types/models/site/service/uptime/SiteServiceUptime';
import api from '@/api';
import useModalUtils from '@/composables/useModal';
import { useToast } from '@/composables/useToast';
import useSiteStore from '@/stores/site/siteStore';
import { useUptimeStore } from '@/stores/site/uptime/uptimeStore';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import { UptimeServiceKeywordStatusEnumConst } from '@/types/enums/UptimeServiceKeywordStatusEnum';
import { UptimeServiceKeywordTypeEnumConst } from '@/types/enums/UptimeServiceKeywordTypeEnum';
import { UptimeSSLStatusEnumConst } from '@/types/enums/UptimeSSLStatusEnum';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

function useUptime() {
  const { t } = useI18n();
  const { toastError } = useToast();
  const { UPTIME } = ServiceTypeEnumConst;
  const { Modal } = useModalUtils();

  // region Stores
  const siteStore = useSiteStore();
  const uptimeStore = useUptimeStore();

  const currentSiteId = computed(() => siteStore.currentSiteId);
  const currentSiteUri = computed(() => siteStore.currentSiteUri);

  const uptimeServiceId = computed(() => uptimeStore.uptimeServiceId);
  const { uptimeDateRange } = storeToRefs(uptimeStore);

  const {
    uptimeStats,
    uptimeService,
    loadedUptime,
    loadedUptimeStats,
  } = storeToRefs(uptimeStore);

  // endregion Stores

  // region Functions
  const getUptimeDates = (): IUptimeStatsRetrieveParams => {
    const [number, type] = uptimeDateRange.value.split('-');
    const duration = { [type]: Number.parseInt(number) };

    const statsType = (type === 'days' && Number.parseInt(number) > 7) || type === 'months' ? 'day' : 'hour';

    const dateNow = DateTime.now();
    const date = dateNow.minus(duration);

    const formatDate = (date: DateTime) => date.toISO();

    return {
      startedAt: formatDate(date)!,
      endedAt: formatDate(dateNow)!,
      type: statsType,
    };
  };

  const loadUptimeService = async () => {
    try {
      if (uptimeServiceId.value) {
        loadedUptime.value = false;
        uptimeService.value = await api.site.service.uptime.retrieve(uptimeServiceId.value);
      }
    } catch (e) {
      console.error('Error loading Uptime service', e);
      throw e;
    } finally {
      loadedUptime.value = true;
    }
  };

  const loadUptimeStats = async () => {
    if (uptimeServiceId.value && uptimeStore.uptimeIsConfigured) {
      try {
        loadedUptimeStats.value = false;

        const { startedAt, endedAt, type } = getUptimeDates();

        if (!!startedAt && !!endedAt && type) {
          uptimeStats.value = await api.site.service.uptime.retrieveStats(uptimeServiceId.value, {
            startedAt,
            endedAt,
            type,
          });
        } else {
          // TODO Encontrar una forma mejor de manejar esto
          throw new Error('Cannot get uptime dates');
        }

        loadedUptimeStats.value = true;
      } catch (e: any) {
        if (e?.code !== 'ERR_CANCELED') {
          console.error('Error loading uptime report', e);
        } else {
          loadedUptimeStats.value = true;
        }

        throw e;
      }
    }
  };

  const createUptimeService = async (params?: IUptimeServiceStoreParams, mode: 'update' | 'create' = 'create') => {
    if (currentSiteId.value) {
      try {
        loadedUptimeStats.value = false;

        if (mode === 'create' && !uptimeServiceId.value) {
          const defaultUptimeOptions: IUptimeServiceStoreParams = {
            interval: 1800,
            timeout: 10000,
            keyword: {
              status: UptimeServiceKeywordStatusEnumConst.INACTIVE,
              type: UptimeServiceKeywordTypeEnumConst.SOME,
              keys: [],
            },
            ssl: {
              status: UptimeSSLStatusEnumConst.INACTIVE,
              reminderFrequency: [],
            },
          };

          uptimeService.value = await api.site.service.uptime.create(currentSiteId.value, defaultUptimeOptions);

          await siteStore.reloadCurrentSiteServices();
        } else if (!!params && uptimeServiceId.value) {
          uptimeService.value = await api.site.service.uptime.update(uptimeServiceId.value, params);
        }
      } catch (e: any) {
        if (e?.code !== 'ERR_CANCELED') {
          console.error('Error loading uptime report', e);
        }
        throw e;
      } finally {
        loadedUptimeStats.value = true;
      }
    }
  };

  const toggleUptimeServiceStatus = async () => {
    try {
      if (!!currentSiteId.value) {
        uptimeService.value = await api.site.service.status.toggle(
          currentSiteId.value,
          UPTIME,
        ) as SiteServiceUptime;
      }
    } catch (e: any) {
      console.error(e);
      toastError();
    }
  };

  const enableUptimeServiceStatus = async () => {
    await toggleUptimeServiceStatus();
    await api.mixpanel.track('Reactivate Uptime', {
      'Website url': currentSiteUri.value,
    });
  };

  async function deleteUptimeService(callbackFn = () => {
  }) {
    Modal.delete({
      title: t('site.uptime.shared.deactivateUptimeMonitorTitle'),
      text: t('site.uptime.shared.deactivateUptimeMonitorText'),
      alertTitle: '',
      leftButtonText: t('general.button.disable'),
      leftButtonLoadingText: t('general.button.loading'),
      leftButtonCallback: async () => {
        if (!!currentSiteId.value) {
          await toggleUptimeServiceStatus();

          await api.mixpanel.track('Pause Uptime', {
            'Website url': currentSiteUri.value,
          });
        }
      },
      closeModalCallback: () => callbackFn(),
    });
  }

  // endregion Functions

  return {
    loadUptimeService,
    loadUptimeStats,
    createUptimeService,

    deleteUptimeService,
    toggleUptimeServiceStatus,
    enableUptimeServiceStatus,
  };
}

export { useUptime };
export default useUptime;
