<script lang="ts" setup>
import type { ProductPrice, StripeProduct } from '@/types/models/organization/billing/product/StripeProduct';
import type { InjectionKey, PropType } from 'vue';
import type { ToastInterface } from 'vue-toastification';
import api from '@/api';

import VBackupSidebar from '@/components/global/sidebar/VBackupSidebar.vue';
import VRightSidebar from '@/components/global/sidebar/VRightSidebar.vue';
import VSubscriptionSidebar from '@/components/global/sidebar/VSubscriptionSidebar.vue';
import VTrustedPerson from '@/components/global/sidebar/VTrustedPerson.vue';
import useModal from '@/composables/useModal';
import usePermissions from '@/composables/usePermissions';

import useRedirectToPayment from '@/composables/useRedirectToPayment';

import useBillingStore from '@/stores/billingStore';

import { BillingCountryEnum } from '@/types/enums/BillingCountryEnum';
import { BillingIntervalEnumConst } from '@/types/enums/BillingIntervalEnum';
import { BillingProductTypeEnumConst } from '@/types/enums/BillingProductTypeEnum';

import { ProductsEnumConst } from '@/types/ProductType';
import TheChangeBillingAccount from '@/views/billing/parts/sidebar/TheChangeBillingAccount.vue';
import { storeToRefs } from 'pinia';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineOptions({
  name: 'TheBillingSidebar',
});

defineProps({
  props: {
    type: Object as PropType<PropsType>,
    required: true,
  },
});

interface PropsType {
  status: string
}

const { t } = useI18n();
const toast: InjectionKey<ToastInterface> | any = inject('toast');

const billingStore = useBillingStore();

const { billingAccount, planSubscription, planNextInvoice, addonNextInvoice, payment, products, license }
  = storeToRefs(billingStore);

const { PLAN } = BillingProductTypeEnumConst;
const { MONTH, YEAR } = BillingIntervalEnumConst;
const { BUSINESS } = ProductsEnumConst;

const { BILLING_ACCOUNTS_STORE } = PermissionEnumConst;

const yearlyPlanDiscount = computed(() => planSubscription.value?.product.maxDiscount.percentage.amount);

const managePaymentMethodLoading = ref(false);

const { can } = usePermissions();

const { modalToShow, closeModal, openModal } = useModal();

function formatAmount(price: string): string {
  let result = price.replace(/[\d,]+/, '').trim();
  result = price.replace(result, '').trim();

  if (result.endsWith(',00')) {
    result = result.replace(',00', '');
  }

  return result;
}

// Methods
function unitFormatted() {
  if (!!planNextInvoice.value) {
    const ret = `${ planNextInvoice.value.subtotal.unit }/`;

    let interval = t('general.shared.day');

    if (planNextInvoice.value.price.interval === MONTH) {
      interval = t('general.shared.month');
    } else if (planNextInvoice.value.price.interval === YEAR) {
      interval = t('general.shared.year');
    }

    return ret + interval;
  }

  return '';
}

function addonUnitFormatted() {
  if (!!addonNextInvoice.value) {
    const ret = `${ addonNextInvoice.value.subtotal.unit }/`;

    let interval = t('general.shared.day');

    if (addonNextInvoice.value.price.interval === MONTH) {
      interval = t('general.shared.month');
    } else if (addonNextInvoice.value.price.interval === YEAR) {
      interval = t('general.shared.year');
    }

    return ret + interval;
  }

  return '';
}

async function managePaymentMethod() {
  try {
    managePaymentMethodLoading.value = true;

    const redirectUri = await api.organization.subscription.manage.paymentMethod();

    window.open(redirectUri, '_blank');
  } catch (e) {
    console.error(e);

    toast.error(t('general.shared.error'));
  } finally {
    managePaymentMethodLoading.value = false;
  }
}

const { redirectToPayment } = useRedirectToPayment();

function handleRedirectToPayment() {
  if (!!planSubscription.value && !!products.value) {
    const productId = planSubscription.value.product.id;
    const product = products.value.find((p: StripeProduct) => p.id === productId);

    if (!!product) {
      const price = product.prices.find((p: ProductPrice) => p.interval === YEAR);

      if (!!price) {
        redirectToPayment({
          trackName: 'Product',
          plan: price.product,
          price: price.id,
          routeName: 'billing.plans.checkout',
          query: {
            type: PLAN,
          },
        });
      }
    }
  }
}

onMounted(async () => {
  if (!billingAccount.value) {
    await billingStore.getBilling();
  }

  if (!payment.value) {
    await billingStore.getPayment();
  }
});
</script>

<template>
  <VRightSidebar>
    <div
      v-if="
        !!planSubscription
          && !!planSubscription.product
          && planSubscription.product.active
          && planSubscription.price.interval === MONTH
          && planSubscription.product.name !== BUSINESS
      "
      class="col col-xl-12 aside mb-16"
    >
      <div
        class="aside-content aside-subscription-yearly position-relative z-index-1 bg-warning radius-sm overflow-hidden px-20 py-16"
      >
        <h4 class="fw-semi lh-lg mb-0 text-lg">
          {{ $t('billing.plan.saveOffPercent', { percent: yearlyPlanDiscount }) }}
        </h4>

        <div class="fw-medium mb-20 text-sm">
          {{ $t('billing.plan.withAnnualPayment') }}
        </div>

        <VButton
          class="btn-icon price-plan fw-semi text-decoration-none stretched-link text-dark border-0 p-0"
          size="sm"
          variant="link"
          @click="handleRedirectToPayment"
        >
          {{ $t('billing.plan.changeHere') }}

          <v-icon class="fw-bold" icon="long-arrow" size="lg" space="ps-8" />
        </VButton>
      </div>
    </div>

    <VTrustedPerson :class="props.status === 'loaded' && !!planSubscription ? 'order-2' : 'order-1'" />

    <div v-if="props.status === 'loading'" class="aside mb-16">
      <div class="aside-content aside-billing-plan d-flex flex-column p-24">
        <span class="placeholder w-100 mb-8" style="height: 24px" />

        <div class="py-12">
          <span class="placeholder w-50 mb-8" style="height: 36px" />
        </div>

        <span class="placeholder w-100 mb-4" />
        <span class="placeholder w-100 mb-4" />
        <span class="placeholder w-100 mb-4" />
      </div>

      <div class="aside-content aside-billing-payment d-flex flex-column px-24 py-16">
        <span class="placeholder mb-8" style="height: 22px" />
        <span class="placeholder w-50" style="height: 22px" />
      </div>
    </div>

    <VSubscriptionSidebar
      v-else-if="!planSubscription && !license"
      class="order-1"
      description="billing.sidebar.getAPlanDescription"
      text-button="billing.sidebar.getAPlanButton"
      title="billing.shared.getAPlan"
    />

    <div v-else class="order-1">
      <div class="aside mb-16">
        <div v-if="!!planNextInvoice" class="aside-content aside-billing-plan h-auto bg-white p-24">
          <div class="fw-semi mb-8">
            {{ $t('billing.sidebar.totalPlan') }}
          </div>

          <div class="mb-8">
            <span class="h3">{{ formatAmount(planNextInvoice.totalExcludingTax.string) }}</span>
            <span class="fw-semi text-sm">{{ unitFormatted() }}</span>
          </div>

          <ul class="fw-medium mb-0 ps-0 text-sm text-gray-600">
            <li
              v-for="(line, index) in planNextInvoice.lines"
              :key="`plan-next-invoice-line-${index}`"
              class="d-flex justify-content-between mb-4 gap-8"
            >
              <span>
                {{ $t('billing.sidebar.planName', { plan_name: line.product.name }) }}
                <template v-if="line.price.interval === YEAR">{{ $t('general.shared.yearly') }}</template>
                <template v-else>{{ $t('general.shared.monthly') }}</template>
              </span>
              <span>{{ line.subtotal.string }}</span>
            </li>
          </ul>

          <ul
            v-if="!!planNextInvoice && !!planNextInvoice.discounts && planNextInvoice.discounts.length > 0"
            class="fw-medium mb-0 ps-0 text-sm text-gray-600"
          >
            <li
              v-for="(discount, index) in planNextInvoice.discounts"
              :key="`plan-next-invoice-line-${index}`"
              class="d-flex justify-content-between mb-4 gap-8"
            >
              <span>{{ discount.coupon }}</span>

              <span class="text-success-dark">
                {{ `-${discount.total.string}` }}
              </span>
            </li>
          </ul>

          <template v-if="!!addonNextInvoice">
            <hr class="mb-8 mt-24" />

            <p class="fw-semi mb-8">
              {{ $t('billing.sidebar.extraStorage') }}
            </p>

            <ul class="fw-medium mb-0 ps-0 text-sm text-gray-600">
              <li
                v-for="(line, index) in addonNextInvoice.lines"
                :key="`plan-next-invoice-line-${index}`"
                class="d-flex justify-content-between mb-4 gap-8"
              >
                <span>
                  {{ line.product.name }}
                </span>
                <span>{{ line.subtotal.string }}</span>
              </li>
            </ul>

            <ul
              v-if="!!addonNextInvoice && !!addonNextInvoice.discounts && addonNextInvoice.discounts.length > 0"
              class="fw-medium mb-0 ps-0 text-sm text-gray-600"
            >
              <li
                v-for="(discount, index) in addonNextInvoice.discounts"
                :key="`plan-next-invoice-line-${index}`"
                class="d-flex justify-content-between mb-4 gap-8"
              >
                <span>{{ discount.coupon }}</span>

                <span class="text-success-dark">
                  {{ `-${discount.total.string}` }}
                </span>
              </li>
            </ul>

            <div
              v-if="
                !!addonNextInvoice
                  && !!addonNextInvoice.discounts
                  && addonNextInvoice.discounts.length > 0
                  && !!addonNextInvoice.totalExcludingTax
              "
              class="d-flex justify-content-between fw-medium mt-8 gap-8 text-sm"
            >
              <span class="text-gray-600">{{ $t('billing.plan.payNow') }}</span>

              <span class="fw-semi">
                +{{ formatAmount(addonNextInvoice.totalExcludingTax.string) }}{{ addonUnitFormatted() }}
              </span>
            </div>
          </template>

          <p class="fw-medium mb-0 mt-8 text-xs text-gray-500">
            {{ $t('billing.sidebar.notIncludeVAT') }}
          </p>
        </div>

        <div class="aside-content aside-billing-payment h-auto bg-white px-24 py-16">
          <div class="d-flex align-items-center justify-content-between mb-8">
            <span class="fw-semi me-8 text-gray-600">{{ $t('billing.team.paymentMethod') }}</span>

            <VButton
              :disabled="managePaymentMethodLoading"
              class="btn-link fw-semi"
              size="sm"
              @click="managePaymentMethod"
            >
              {{ $t('general.button.edit') }}
            </VButton>
          </div>

          <div v-if="!!payment" class="d-flex align-items-center text-gray-500">
            <m-icon :icon="payment.type as any" :size="payment.type === 'paypal' ? 'md' : 'xxl'" class="me-8" />

            <div class="fw-medium text-sm">
              <template v-if="!!payment && payment.type === 'card'">
                <span class="text-uppercase">{{ payment.brand }}</span>
                <span> **** {{ payment.last4 }}</span>
              </template>

              <span v-else-if="!!payment" class="text-uppercase">{{ payment.type }}</span>
            </div>
          </div>

          <span v-else class="fw-medium text-sm text-gray-500">{{ $t('billing.team.noPaymentAdded') }}</span>
        </div>

        <div class="aside-content aside-billing-account h-auto bg-white px-24 py-16">
          <div class="d-flex align-items-center mb-8">
            <div class="fw-semi text-gray-600">
              {{ $t('billing.team.billingData') }}
            </div>

            <div class="ms-auto">
              <VButton
                v-if="can(BILLING_ACCOUNTS_STORE)"
                class="btn-link fw-semi"
                size="sm"
                @click="openModal('the-change-billing-account')"
              >
                {{ $t('general.button.edit') }}
              </VButton>
            </div>
          </div>

          <div v-if="!!billingAccount && billingAccount.isFilled" class="fw-medium text-sm text-gray-500">
            <p v-if="!!billingAccount?.fiscalName" class="mb-0">
              {{ billingAccount.fiscalName }}
            </p>

            <p v-if="!!billingAccount && !!billingAccount.idIdentity" class="mb-0">
              {{ billingAccount.idIdentity }}
            </p>

            <p v-if="!!billingAccount && !!billingAccount.line1" class="mb-0">
              {{ billingAccount.line1 }}
            </p>

            <p v-if="!!billingAccount && !!billingAccount.line2" class="mb-4">
              {{ billingAccount.line2 }}
            </p>

            <p class="mb-4">
              <span v-if="!!billingAccount && !!billingAccount.city">{{ billingAccount.city }},</span>
              <span v-if="!!billingAccount && !!billingAccount.state">{{ billingAccount.state }},</span>
              <span v-if="!!billingAccount && !!billingAccount.postalCode">{{ billingAccount.postalCode }}</span>
            </p>

            <p v-if="!!billingAccount && !!billingAccount.country" class="mb-4">
              {{ BillingCountryEnum[billingAccount.country].name }}
            </p>

            <p v-if="!!billingAccount && !!billingAccount.email" class="mb-4">
              {{ billingAccount.email }}
            </p>
          </div>

          <div v-else class="text-sm">
            <p>{{ $t('billing.team.noBillingData') }}</p>

            <VButton size="xs" variant="primary" @click="openModal('the-change-billing-account')">
              {{ $t('general.button.add') }}
            </VButton>
          </div>
        </div>

        <v-modal
          v-if="modalToShow === 'the-change-billing-account'"
          id="the-change-billing-account"
          @closed="modalToShow = ''"
        >
          <modal-header>
            <modal-title>
              {{ $t('billing.team.addBilling') }}
            </modal-title>
          </modal-header>

          <TheChangeBillingAccount
            v-if="modalToShow === 'the-change-billing-account'"
            enable-modal-edit
            @closed="
              closeModal();
              modalToShow = '';
            "
            @billing-account-updated="modalToShow = ''"
          />
        </v-modal>
      </div>
    </div>

    <VBackupSidebar class="order-3" />
  </VRightSidebar>
</template>

<style lang="scss" scoped>
.aside-billing-plan {
  border-top-left-radius: var(--md-border-radius-sm);
  border-top-right-radius: var(--md-border-radius-sm);
}

.aside-billing-plan,
.aside-billing-payment {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--md-gray-400);
  }
}

.aside-billing-account {
  border-bottom-left-radius: var(--md-border-radius-sm);
  border-bottom-right-radius: var(--md-border-radius-sm);
}

[data-bs-theme='dark'] {
  .aside-subscription-yearly {
    // Light mode warning
    background-color: #ffbd63 !important;

    > * {
      color: var(--md-light) !important;
    }

    a:hover {
      color: var(--md-primary-light) !important;
    }
  }
}
</style>
