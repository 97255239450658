import type { SiteBackupStatusEnumKeys } from '@/types/enums/SiteBackupStatusEnum';
import useBackups from '@/composables/backups/useBackups';
import useBackupStore from '@/stores/site/backup/backupStore';
import { SiteBackupStatusEnumConst } from '@/types/enums/SiteBackupStatusEnum';

export function useBackupsSocket() {
  const backupStore = useBackupStore();

  const { reloadBackup } = useBackups();
  const updateBackupStatus = async (
    {
      backupId,
      status,
      isLastBackups = false,
    }: {
      backupId: number
      status: SiteBackupStatusEnumKeys
      isLastBackups?: boolean
    }) => {
    const backupsAux = isLastBackups ? backupStore.lastBackups : backupStore.backups;

    if (!!backupsAux) {
      const foundBackup = backupsAux.find(b => b.id === backupId);
      if (!!foundBackup) {
        if (status === SiteBackupStatusEnumConst.DONE) {
          // Recall to load all data
          await reloadBackup({
            backupId,
            isLastBackups,
          });
        } else {
          foundBackup.status = status;
        }
      }
    }
  };

  return {
    updateBackupStatus,
  };
}
