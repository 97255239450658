<script lang="ts">
import { defineComponent } from 'vue';

/**
 * @deprecated since 1.6.5
 */
export default defineComponent({
  name: 'VInputSwitch',
  props: {
    id: {
      type: String,
      required: true,
      default: 'input-switch',
    },
    modelValue: {
      type: [String, Boolean],
      required: true,
    },
    trueLabel: {
      type: String,
      required: false,
      default: 'general.shared.on',
    },
    falseLabel: {
      type: String,
      required: false,
      default: 'general.shared.off',
    },
    trueColor: {
      type: String,
      required: false,
      default: 'text-success',
    },
    falseColor: {
      type: String,
      required: false,
      default: 'text-gray-500',
    },
    trueTransition: {
      type: String,
      required: false,
      default: 'splite-down',
    },
    falseTransition: {
      type: String,
      required: false,
      default: 'splite-up',
    },
    labelClasses: {
      type: [String, Object],
      required: false,
      default: '',
    },
    inputClasses: {
      type: [String, Object],
      required: false,
      default: '',
    },
    wrapperClasses: {
      type: [String, Object],
      required: false,
      default: 'p-8 me-12',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    pink: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:modelValue', 'change', 'click'],
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  computed: {
    transition() {
      return this.localValue ? this.trueTransition : this.falseTransition;
    },
    color() {
      return this.localValue ? this.trueColor : this.falseColor;
    },
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.localValue) {
        this.localValue = newVal;
      }
    },
  },
  methods: {
    updateValue() {
      this.$emit('update:modelValue', this.localValue);
    },
  },
});
</script>

<template>
  <div :class="[{ 'form-switch-pink': pink }, wrapperClasses]" class="form-switch">
    <label v-if="!!trueLabel && falseLabel" :class="[color, labelClasses]" :for="id" class="form-check-label fw-semi text-uppercase ms-8">
      <Transition :name="transition" mode="out-in">
        <span v-if="localValue && !!trueLabel" class="d-inline-block">{{ $t(trueLabel) }}</span>
        <span v-else-if="!!falseLabel" class="d-inline-block">{{ $t(falseLabel) }}</span>
      </Transition>
    </label>

    <input
      :id="id"
      ref="input"
      v-model="localValue"
      :disabled="disabled"
      class="form-check-input"
      :class="inputClasses"
      type="checkbox"
      @change="updateValue"
      @click="$emit('click', localValue)"
    >
  </div>
</template>

<style lang="scss" scoped>
.form-switch {
  display: flex;

  .form-check-input {
    margin-left: 0.25rem;

    &:focus {
      box-shadow: 0 0 0 0.15rem rgb(121 48 241 / 30%);
    }
  }
}
</style>
