<script lang="ts" setup>
import type { DeviceMetric } from '@/types/models/site/service/performance/LighthouseReport';

import { ref, toRef, watchEffect } from 'vue';

defineOptions({
  name: 'TheSiteLighthouseReportPerformanceTab',
});

const props = defineProps<{
  items: DeviceMetric[]
  id: string
  defaultActive?: boolean
  tabType: string
}>();

const localItems = toRef(props, 'items');
const filteredItems = ref<DeviceMetric[]>([]);

watchEffect(() => {
  if (!!localItems.value) {
    filteredItems.value = localItems.value.filter(item => !!item?.type && item.type !== 'server-response-time');
  }
});
</script>

<template>
  <div
    :id="id"
    :class="{ 'show active': defaultActive }"
    aria-labelledby="nav-mobile-tab"
    class="tab-pane fade"
    role="tabpanel"
  >
    <div v-if="!!filteredItems" class="row gx-0 top-section">
      <div v-for="item in filteredItems" :key="item.type" class="col-12 col-lg-6 item mb-8 pb-8">
        <div
          v-if="!!item?.name && item.name.length > 0"
          :class="!!item?.color ? item.color : 'unknown'"
          class="item-inner position-relative d-flex flex-column ps-20"
        >
          <span v-if="!!item?.name" class="title text-sm">{{ item.name }}</span>
          <span v-if="!!item?.value" class="data fw-semi text-lg">{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-section .item {
  --fail: #bf260d;
  --fail-icon: #ff2637;
  --pass-icon: #0acf83;
  --pass: #007d1d;
  --average: #ff9100;
  --average-icon: #ff9100;
  --unknown: var(--md-gray-500);

  &:not(:last-child, :nth-last-child(2)) {
    border-bottom: 0.0625rem solid var(--md-gray-400);
  }

  .item-inner {
    &::after {
      position: absolute;
      content: '';
      left: 0;
      width: 0.75rem;
      height: 0.75rem;
      top: 0.375rem;
    }

    &.fail {
      .data {
        color: var(--fail);
      }
    }

    &.average {
      .data {
        color: var(--average);
      }
    }

    &.unknown {
      .data {
        color: var(--md-gray-500);
      }
    }

    &.pass {
      .data {
        color: var(--pass);
      }

      &::after {
        background: var(--pass-icon);
        border-radius: 50%;
      }
    }

    &.fail::after {
      border-left: 0.375rem solid transparent;
      border-right: 0.375rem solid transparent;
      border-bottom: 0.75rem solid var(--fail-icon);
    }

    &.average::after {
      background: var(--average-icon);
    }
  }
}

[data-bs-theme='dark'] {
  .top-section .item {
    --fail: #e3026f;
    --fail-icon: #e3026f;
    --pass: #7df75a;
    --pass-icon: #7df75a;
    --average: #eaac30;
    --average-icon: #eaac30;
    --unknown: var(--md-gray-500);
  }
}
</style>
