<script>
import { Modal } from 'bootstrap';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VModal',
  props: {
    id: {
      type: String,
      required: false,
      default: 'modal',
    },
    backdropStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
    backdrop: {
      type: String,
      required: false,
      default: '',
    },
    backdropOverContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
    className: {
      type: String,
      required: false,
      default: null,
    },
    contentClass: {
      type: String,
      required: false,
      default: null,
    },
    modalClasses: {
      type: String,
      required: false,
      default: null,
    },
    disableClose: {
      type: Boolean,
      required: false,
      default: null,
    },
    closeSize: {
      type: String,
      required: false,
      default: null,
      validator: val => ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(val),
    },
    disableTeleport: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['closed', 'hide', 'hide-prevented', 'show', 'shown', 'clickClose'],
  data() {
    return {
      modal: {},
      doEmitOnClose: true,
    };
  },
  computed: {
    // TODO debe ser compatible con purgecss
    cSize() {
      let ret = null;

      if (!!this.size) {
        ret = `modal-${this.size}`;
      }

      return ret;
    },
  },
  mounted() {
    this.setup();
    this.open();
  },
  beforeUnmount() {
    this.modal.hide();
  },
  methods: {
    setup() {
      const MODAL = this.$refs.modal;

      const config = {};

      if (this.backdropStatic) {
        config.backdrop = 'static';
      }

      if (this.backdrop === 'false') {
        config.backdrop = false;
      }

      this.modal = Modal.getOrCreateInstance(MODAL, config);

      if (this.backdropOverContent) {
        this.modal._backdrop._config.className = 'modal-backdrop modal-backdrop--custom';
      }

      MODAL.addEventListener('hidden.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          // TODO Replace to same BT event name: hidden
          this.$emit('closed');
        }
      });

      MODAL.addEventListener('hide.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('hide');
        }
      });

      MODAL.addEventListener('hidePrevented.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('hide-prevented');
        }
      });

      MODAL.addEventListener('show.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('show');
        }
      });

      MODAL.addEventListener('shown.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('shown');
        }
      });
    },
    open() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    dispose() {
      this.modal.dispose();
    },
  },
});
</script>

<template>
  <Teleport to="body" :disabled="disableTeleport">
    <div
      :id="id"
      ref="modal"
      :data-cy="dataCy"
      :class="modalClasses"
      :data-bs-backdrop="!!backdropStatic ? 'static' : null"
      aria-hidden="true"
      class="modal fade"
      tabindex="-1"
    >
      <div :class="[className, cSize]" class="modal-dialog modal-dialog-centered">
        <div :class="contentClass" class="modal-content">
          <div v-if="!!$slots.header" class="modal-header position-relative justify-content-between border-bottom-0">
            <slot name="header" />

            <VButton
              v-if="!disableClose"
              :aria-label="$t('general.modal.close')"
              :title="$t('general.modal.close')"
              class="btn-link fw-medium p-0"
              data-bs-dismiss="modal"
              size="sm"
              @click="$emit('clickClose')"
            >
              {{ $t('general.modal.close') }}
            </VButton>
          </div>

          <div v-if="!!$slots.body" class="modal-body">
            <slot name="body" />
          </div>

          <slot />

          <div v-if="!!$slots.footer" class="modal-footer border-top-0">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
