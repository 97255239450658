import { Concept } from '@/types/models/Concept';

export type SiteServiceStatusEnumKeys = 'active' | 'inactive';

export const SiteServiceStatusEnumConst = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export const SiteServiceStatusEnum: Record<SiteServiceStatusEnumKeys, Concept> = {
  'active': {
    key: 'active',
    value: null,
    name: 'concept.site.service.status.activeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.service.status'
  },
  'inactive': {
    key: 'inactive',
    value: null,
    name: 'concept.site.service.status.inactiveName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.service.status'
  }
} as const;

export type SiteServiceStatusEnumType = (typeof SiteServiceStatusEnumConst)[keyof typeof SiteServiceStatusEnumConst];

export const SiteServiceStatusEnumArray = Object.values(SiteServiceStatusEnum);
