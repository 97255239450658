<script setup lang="ts">
import type { ButtonVariants } from '@/components/ui/button';

import type { HTMLAttributes } from 'vue';

import useRedirectToPayment from '@/composables/useRedirectToPayment';
import { cn } from '@/helpers/classNames';

import { ProductsEnumConst } from '@/types/ProductType';

defineOptions({
  name: 'MBusinessButton',
});

const props = withDefaults(defineProps<Props>(), {
  class: '',
  size: 'sm',
  text: 'general.shared.becomeBusiness',
});

const { BUSINESS } = ProductsEnumConst;

interface Props {
  class?: HTMLAttributes['class']
  trackName: string
  size?: ButtonVariants['size']
  text?: string
}

const { redirectToPayment } = useRedirectToPayment();
</script>

<template>
  <m-button
    data-cy="btn-login"
    variant="success"
    :class="cn(props.class)"
    :size="size"
    @click="
      redirectToPayment({
        trackName,
        query: {
          planName: BUSINESS,
        },
      })
    "
  >
    {{ $t(text) }}
  </m-button>
</template>
