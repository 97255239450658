export default {
  panel: {
    viewAll: 'Ver todas',
    viewUnread: 'Ver sólo no leídas',
    view: 'Ver',
    markAsRead: 'Marcar como leída',
    markAsUnread: 'Marcar como no leída',
    markAllAsRead: 'Marcar todo como leído',
    all30Days: 'No tienes más notificaciones en los últimos 30 días.',
    noPendingTitle: '¡Nada por aquí!',
    noPending: 'No tienes ninguna notificación pendiente.',
  },
  notifications: {
    uptimeDown: {
      title: '{name} parece caída',
      body: '{url} parece caída desde {date} (UTC).',
    },
    uptimeUp: {
      title: '{name} vuelve a funcionar',
      body: '{url} vuelve a funcionar desde {date} (UTC).',
    },
    uptimeSslExpiration: {
      title: '{name} SSL caducará en {days}',
      body: 'El certificado SSL de {url} caducará el {date} (UTC).',
    },
    backupFailed: {
      title: 'Error en backup en {name}',
      body: 'La copia de seguridad de {url} ha fallado.',
    },
    reportAutomaticFailed: {
      title: 'Error generando reporte en {name}',
      body: 'El reporte programado de {url} no se ha podido generar.',
    },
    siteConnectionLost: {
      title: '{name} ha perdido la conexión con Modular',
      body: 'Tu web {url} se ha desconectado de Modular.',
    },
    vulnerabilityDiscovered: {
      title: 'Vulnerabilidad detectada en {name}',
      body: 'Hemos detectado una nueva vulnerabilidad en tu web {url}',
    },
    managerUpgradedFailed: {
      title: 'Error al actualizar {name} en {site}',
      body: 'El {type} {name} no se ha podido actualizar en {url}',
    },
    managerInstallationFailed: {
      title: 'Error al instalar {name} en {site}',
      body: 'El {type} {name} no se ha podido instalar en {url}',
    },
    managerActivationFailed: {
      title: 'Error al activar {name} en {site}',
      body: 'El {type} no se ha podido activar en {url}',
    },
    managerDeactivationFailed: {
      title: 'Error al desactivar {name} en {site}',
      body: 'El {type} no se ha podido desactivar en {url}',
    },
    managerDeletionFailed: {
      title: 'Error al eliminar {name} en {site}',
      body: 'El {type} no se ha podido eliminar en {url}',
    },
  },
}
