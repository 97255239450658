import useModalUtils from '@/composables/useModal';
import useRedirectToPayment from '@/composables/useRedirectToPayment';
import { EventSymbols } from '@/resources/symbols';

import useBillingStore from '@/stores/billingStore';
import useOrganizationStore from '@/stores/organizationStore';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export function useSiteCreate() {
  const { t } = useI18n();
  const router = useRouter();

  const { Modal, modalToShow, openModal, closeModal } = useModalUtils();

  const organizationStore = useOrganizationStore();
  const billingStore = useBillingStore();

  const currentPlanName = computed(() => billingStore.currentPlanName);
  const nextPlanName = computed(() => billingStore.nextPlanName);

  const sitesLimit = computed(() => organizationStore.sitesLimit);

  const { redirectToPayment } = useRedirectToPayment();
  const sitesLimitModal = async () => {
    Modal.goPro({
      title: t('general.organizationLimit.title'),
      alertTitle: t('general.organizationLimit.alertTitle', {
        number: sitesLimit.value?.total?.format,
        plan: currentPlanName.value,
      }),
      text: t('general.organizationLimit.alertText', { nextPlan: nextPlanName.value }),
      leftButtonText: t('general.organizationLimit.cancelButton'),
      rightButtonText: t('general.organizationLimit.acceptButton', { nextPlan: nextPlanName.value }),
      rightButtonCallback: () => redirectToPayment({ trackName: 'Create site limit' }),
    });
  };

  const handleCreateSite = (location = '', clearRouter = false) => {
    if (sitesLimit.value && sitesLimit.value?.used?.value < sitesLimit.value?.total?.value) {
      const createSiteEventBus = useEventBus<string>(EventSymbols.CREATE_SITE);

      createSiteEventBus.emit(EventSymbols.CREATE_SITE.toString(), location);

      if (clearRouter) {
        router.replace({
          name: 'me.sites.index',
        });
      }
    } else {
      sitesLimitModal();
    }
  };

  return {
    handleCreateSite,
    openModal,
    modalToShow,
    closeModal,
  };
}
