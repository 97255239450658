<script setup lang="ts">
import { type TooltipRootEmits, type TooltipRootProps, useForwardPropsEmits } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

// TODO: Added sizes

const props = withDefaults(defineProps<TooltipRootProps & {
  html?: boolean
  content?: string
  contentClass?: HTMLAttributes['class']
  triggerClass?: HTMLAttributes['class']
  triggerStyle?: string
  contentStyle?: string
  contentSize?: 'basic' | 'sm'
  arrowClass?: HTMLAttributes['class']
  arrowStyle?: string
}>(), {
  content: '',
  contentClass: '',
  triggerClass: '',
  triggerStyle: '',
  contentStyle: '',
  arrowClass: '',
  arrowStyle: '',
  contentSize: 'basic',
})

const emits = defineEmits<TooltipRootEmits>();

const delegatedProps = computed(() => {
  const {
    content: _,
    contentClass,
    triggerClass,
    triggerStyle,
    html,
    contentStyle,
    contentSize,
    arrowClass,
    arrowStyle,
    ...delegated
  } = props;

  return delegated;
});

const sizeComputed = computed(() => ({
  sm: 'py-8 px-16',
  basic: '',
})[props.contentSize],
);

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <TooltipProvider :disable-hoverable-content="true">
    <TooltipRoot v-bind="forwarded">
      <TooltipTrigger as-child :class="triggerClass" :style="triggerStyle">
        <span tabindex="0">
          <slot />
        </span>
      </TooltipTrigger>

      <TooltipContent
        v-if="!!content"
        :class="[contentClass, sizeComputed]"
        :style="contentStyle"
        :arrow-class="arrowClass"
        :arrow-style="arrowStyle"
        side="top"
        align="center"
      >
        <template v-if="!html">
          {{ content }}
        </template>

        <span v-else v-dompurify-html="content" />
      </TooltipContent>

      <slot v-else name="content" />
    </TooltipRoot>
  </TooltipProvider>
</template>
