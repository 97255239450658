export default {
  mainNavigation: 'Navegación principal',
  home: 'Inicio',
  recents: 'Recientes',
  mySites: 'Mis webs',
  drafts: 'Borradores',
  design: 'Diseño de la web',
  designAndThemes: 'Diseño y Plantillas',
  logoAndColors: 'Logo, colores...',
  overview: 'Overview',
  analytics: 'Analytics',
  domain: 'Dominio',
  billingManagement: 'Gestión y Administración',
  plains: 'Plan y facturación',
  admin: 'Administrador',
  recruitmentLinks: 'Enlaces de contratación',
  usersPanel: 'Panel de usuarios',
  uptimeMonitor: 'Uptime Monitor',
  backups: 'Backups',
  reports: 'Reportes',
  updates: 'Actualizaciones',
  healthSafety: 'Seguridad & Salud',
  metrics: 'Métricas',
  searchConsole: 'Search Console',
  metricsAriaLabel: 'Alternar la navegación de métricas',
  toggleTeamAriaLabel: 'Alternar la navegación por equipos',
  settings: 'Configuración',
  changelog: 'Novedades',
  siteManagement: 'Administracion del sitio',
  siteSettings: 'Ajustes del sitio',
  alerting: 'Notificaciones',
  wpManagement: 'Gestión WP',
};
