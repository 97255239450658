<script lang="ts" setup>
import type { FontSizes, IconTypes } from '@/components/vendor/basic/icon/VIcon.vue';
import type { PropType } from 'vue';

import { Collapse } from 'bootstrap';

import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  collapseHorizontal: {
    type: Boolean,
    required: false,
    default: false,
  },
  buttonClasses: {
    type: String,
    required: false,
    default: '',
  },
  collapseClasses: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String as PropType<IconTypes>,
    default: 'arrow-caret-down',
  },
  iconSize: {
    type: String as PropType<FontSizes>,
    default: '2xs',
  },
  iconSpace: {
    type: String,
    required: false,
    default: 'ms-auto',
  },
  disableIcon: {
    type: Boolean,
    default: false,
  },
  decorated: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['click', 'toggle', 'input', 'update:modelValue']);
let collapseInstance: Collapse | null = null;
const collapse = ref(null);
const localValue = ref(false);

function getInstance() {
  if (collapse.value) {
    collapseInstance = Collapse.getOrCreateInstance(collapse.value, { toggle: props.modelValue ?? false });
  }
}

function toggle() {
  if (!!collapseInstance) {
    collapseInstance.toggle();

    emit('toggle');
  }
}

function toggleCollapse() {
  localValue.value = !localValue.value;

  toggle();

  emit('click');
  emit('toggle');
  emit('update:modelValue', localValue.value);
}

watch(localValue, (oldVal, newVal) => {
  emit('input', newVal);
  emit('toggle', newVal);

  toggle();
});

watch(
  () => props.modelValue,
  (oldVal, newVal) => {
    emit('input', newVal);
    emit('toggle', newVal);

    toggle();
  },
);

onMounted(() => {
  localValue.value = props.modelValue;

  getInstance();
});
</script>

<template>
  <div class="md-collapse">
    <VButton
      :aria-expanded="!!localValue"
      :class="[
        buttonClasses,
        {
          decorated,
          collapsed: !localValue,
        },
      ]"
      @click="toggleCollapse"
    >
      <slot name="button" />
      <v-icon v-if="!disableIcon" :class="iconSpace" :icon="icon" :size="iconSize" class="collapse-arrow iconButton" />
    </VButton>

    <div
      :id="id"
      ref="collapse"
      :class="[{ 'collapse-horizontal': collapseHorizontal }, collapseClasses]"
      class="collapse"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<style lang="scss">
.md-collapse {
  .btn {
    position: relative;
    color: var(--md-gray-600);
    border: none;

    &:focus {
      box-shadow: none;
    }

    .collapse-arrow {
      transition: transform 0.3s ease-out;
    }

    &.decorated {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 0.25rem;
        height: 100%;
        background-color: var(--vx-secondary);
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &[aria-expanded='true'] {
        &::after {
          opacity: 1;
        }
      }
    }

    &[aria-expanded='true'] {
      .collapse-arrow {
        transform: rotateX(180deg);
      }
    }
  }
}
</style>
