<script lang="ts" setup>
import { TooltipArrow, type TooltipArrowProps } from 'radix-vue';

const props = defineProps<TooltipArrowProps>();
</script>

<template>
  <TooltipArrow class="ui-tooltip-arrow" v-bind="props">
    <slot />
  </TooltipArrow>
</template>
