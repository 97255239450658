import Tracker from '@/helpers/trackerHelper';

import useUserStore from '@/stores/userStore';

import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export interface LoginInterface {
  twoFactor: boolean
}

export default class AuthService extends AbstractService {
  /**
   * Checks Sanctum Cookie on browser
   *
   * @returns {Promise<any>}
   */
  public async cookie() {
    return this.request({
      method: apiEndpoints.sanctumCsrfCookie.method,
      url: this.buildPath(apiEndpoints.sanctumCsrfCookie),
    });
  }

  /**
   * Create a new registered user.
   *
   * @param data
   * @returns {Promise<void>}
   */
  public async register(data: any) {
    await this.cookie();

    await this.request({
      method: apiEndpoints.register.method,
      url: this.buildPath(apiEndpoints.register),
      data,
      headers: Tracker.getTracker(),
    });
  }

  /**
   * Attempt to authenticate a new session.
   *
   * @param form
   * @returns {Promise<LoginInterface | null>}
   */
  public async login(form: any): Promise<LoginInterface | null> {
    await this.cookie();

    return this.request({
      method: apiEndpoints.login.method,
      url: this.buildPath(apiEndpoints.login),
      data: form,
      headers: Tracker.getTracker(),
    });
  }

  /**
   * Redirect to the provider login given by parameter
   *
   * @param {string} provider
   * @returns {Promise<{status: string, redirectUri: string}>}
   */
  public async socialLogin(provider: string): Promise<{ status: string, redirectUri: string }> {
    await this.cookie();

    return this.request({
      method: apiEndpoints.authSocialRedirect.method,
      url: this.buildPath(apiEndpoints.authSocialRedirect, { provider }),
    });
  }

  /**
   * Checks with Google if user can be logged in by social login
   *
   * @param params
   * @returns {Promise<any>}
   */
  public async confirmSocialAuth(params: any): Promise<any> {
    await this.cookie();

    return this.request({
      method: apiEndpoints.authSocialRedirect.method,
      url: this.buildPath(apiEndpoints.authSocialConfirm, { provider: 'google' }),
      params,
    });
  }

  /**
   * Send a reset link to the given user.
   *
   * @param data
   * @returns {Promise<any>}
   */
  public async forgotPassword(data: any) {
    await this.cookie();

    return this.request({
      method: apiEndpoints.passwordEmail.method,
      url: this.buildPath(apiEndpoints.passwordEmail),
      data,
    });
  }

  /**
   * Reset the user's password.
   *
   * @param data
   * @returns {Promise<any>}
   */
  public async resetPasswords(data: any) {
    await this.cookie();

    return this.request({
      method: apiEndpoints.passwordUpdate.method,
      url: this.buildPath(apiEndpoints.passwordUpdate),
      data,
    });
  }

  /**
   * Destroy an authenticated session.
   *
   * @returns {Promise<any>}
   */
  public logout() {
    return this.request({
      method: apiEndpoints.logout.method,
      url: this.buildPath(apiEndpoints.logout),
      headers: Tracker.getTracker(),
    });
  }

  /**
   * Checks if user is currently authenticated
   *
   * @param utm
   * @param {Record<string, any>} headers
   * @returns {Promise<boolean>}
   */
  public async check(utm?: any, headers?: Record<string, any>): Promise<boolean> {
    const user = useUserStore();

    if (!user.logged) {
      await this.cookie();

      user.logged = await this.request({
        method: apiEndpoints.authCheck.method,
        url: this.buildPath(apiEndpoints.authCheck),
        params: utm || '',
        headers,
      });
    }

    return user.logged;
  }

  /**
   *
   * @param values
   * @returns {Promise<any>}
   */
  public broadcast(values: any): Promise<any> {
    return this.request({
      method: 'post',
      url: this.buildPath({
        name: 'broadcasting.auth',
        path: 'api/broadcasting/auth',
        method: 'POST',
      }),
      data: values,
    });
  }

  /**
   * Logs out the user from all devices.
   *
   * @param data
   * @returns {Promise<any>}
   */
  public logoutDevices(data: any): Promise<any> {
    return this.request({
      method: apiEndpoints.devicesLogout.method,
      url: this.buildPath(apiEndpoints.devicesLogout, {
        token: data.token,
        device: data.device,
      }),
    });
  }
}
