import { Concept } from '@/types/models/Concept';

export type SiteManagerTypeEnumKeys = 'site' | 'global';

export const SiteManagerTypeEnumConst = {
  SITE: 'site',
  GLOBAL: 'global',
} as const;

export const SiteManagerTypeEnum: Record<SiteManagerTypeEnumKeys, Concept> = {
  site: {
    key: 'site',
    value: null,
    name: 'site.manager.type.site',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    status: 'active',
    permission: null,
    type: 'site.manager.type',
  },
  global: {
    key: 'global',
    value: null,
    name: 'site.manager.type.global',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    status: 'active',
    permission: null,
    type: 'site.manager.type',
  },
} as const;

export type SiteItemTypeEnumType = (typeof SiteManagerTypeEnumConst)[keyof typeof SiteManagerTypeEnumConst];

export const SiteItemTypeEnumArray = Object.values(SiteManagerTypeEnum);
