import { EventSymbols } from '@/resources/symbols';

/**
 * Composable that emits an Event Bus event to VCreateTeam component in VLayout to create a new Team.
 */
export function useTeamCreate() {
  /**
   * Emits an Event Bus event to VCreateTeam component in VLayout to create a new Team.
   */
  const createTeam = () => {
    const createSiteEventBus = useEventBus<string>(EventSymbols.CREATE_TEAM);

    createSiteEventBus.emit(EventSymbols.CREATE_TEAM.toString());
  };

  return {
    createTeam,
  };
}
