import i18n from '@/lang';
import { DateTime, Interval } from 'luxon';

export interface DateFormatOption {
  label: string
  value: string
  format: string
  altFormat: string
}

export interface DateTimeFormatOption {
  label: string
  value: string
}

class DateHelper {
  private t = i18n.global.t;

  public getMappedAvailableTimeFormats = [
    {
      label: this.t('user.dateTimeFormat.twelveHours'),
      value: 'h:mm a',
    },
    {
      label: this.t('user.dateTimeFormat.twentyFourHours'),
      value: 'HH:mm',
    },
  ];

  public getMappedAvailableDateFormats: DateFormatOption[] = [
    {
      label: this.t('user.dateFormat.ddmmyyyy'),
      value: 'dd-MM-yyyy',
      format: 'dd/MM/yyyy',
      altFormat: 'dd-MM-yyyy',
    },
    {
      label: this.t('user.dateFormat.mmddyyyy'),
      value: 'MM-dd-yyyy',
      format: 'MM-dd-yyyy',
      altFormat: 'MM/dd/yyyy',
    },
    {
      label: this.t('user.dateFormat.yyyymmdd'),
      value: 'yyyy/MM/dd',
      format: 'yyyy/MM/dd',
      altFormat: 'yyyy/MM/dd',
    },
  ];

  /**
   * Turns any provided date into a DateTime from Luxon.
   *
   * @param date - The date to be parsed. Accepts Date object, string, or number.
   * @returns A DateTime object representing the parsed date.
   * @throws Error if the date format is unknown.
   */
  parse(date: Date | DateTime | string | number): DateTime {
    if (date instanceof DateTime) {
      return date;
    }

    if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    }

    if (typeof date === 'string') {
      return DateTime.fromISO(date);
    }

    if (typeof date === 'number') {
      return DateTime.fromMillis(date);
    }

    throw new Error(`Unknown format of ${JSON.stringify(date)}`);
  }

  parseMinutesToHoursAndMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return [
      hours ? `${hours}h` : '',
      remainingMinutes ? `${remainingMinutes}m` : '',
    ].filter(Boolean).join(' ');
  }

  // TODO: REFACTOR ME
  /**
   * Get human-readable duration between start and end date in days, hours, and minutes.
   * @param {string} start - The start date string in a format that can be parsed by the `parse` method.
   * @param {DateTime} [end] - The end date as a `DateTime` object. Defaults to the current date and time.
   * @param {boolean} [disableHoursWhenDays] - Whether to disable displaying hours when days are present.
   * Defaults to `false`.
   * @param enableSeconds
   * @param disableSecondsWhenDays
   * @param disableMinutesWhenHours
   * @param short
   * @returns {string} - The human-readable duration string.
   */
  public durationForHumans(
    start: string,
    end?: DateTime | null,
    disableHoursWhenDays = false,
    enableSeconds = false,
    disableSecondsWhenDays = false,
    disableMinutesWhenHours = false,
    short = false,
    long = false,
  ): string {
    const startVal = this.parse(start);
    const endVal = end || DateTime.now();
    const diff = Interval.fromDateTimes(startVal, endVal);
    const time = diff.toDuration().shiftTo('days', 'hours', 'minutes', 'seconds');
    const { days, hours, minutes, seconds } = time;

    let result = '';

    if (days > 0) {
      result += `${days}${short ? this.t('general.shared.days') : ` ${this.t('general.shared.daysLong')}`}`;
    }

    if (hours > 0 && !(days > 0 && disableHoursWhenDays)) {
      result += `${days > 0 ? ' ' : ''}${hours}${short ? this.t('general.shared.hours') : ` ${this.t('general.shared.hoursLong')}`}`;
    }

    if (minutes > 0 && !(days > 0 && disableHoursWhenDays) && !(hours > 0 && disableMinutesWhenHours)) {
      result += `${hours > 0 || (days > 0 && !hours) ? ' ' : ''}${minutes}${short ? this.t('general.shared.minutesShort') : long ? this.t('general.shared.minutesLong') : ` ${this.t('general.shared.minutes')}`}`;
    }

    if (seconds > 0 && !(days > 0 && (disableHoursWhenDays || disableSecondsWhenDays)) && enableSeconds) {
      result += `${hours > 0 || (days > 0 && !hours) ? ' ' : ''}${seconds.toFixed(0)}${short ? this.t('general.shared.seconds') : ` ${this.t('general.shared.secondsLong')}`}`;
    }

    return result;
  }

  /**
   * Returns a date in the past.
   * @param days
   * @param format
   * @protected
   */
  public getPastDateByDays(days: number, format = 'yyyy-MM-dd'): string {
    const currentDate = new Date();
    const pastDate = new Date(currentDate);

    pastDate.setDate(currentDate.getDate() - days);

    return this.parse(pastDate).toUTC().toFormat(format);
  }

  public getTimeZones = () => Intl.supportedValuesOf('timeZone');

  public getUserBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  public getMappedTimeZones = () =>
    this.getTimeZones().map(zone => ({
      label: zone,
      value: zone,
    }));

  public getBrowserTimeFormat = () => {
    const is12HourFormat = Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).format(0).match(/AM/);

    if (is12HourFormat) {
      return '12h';
    }

    return '24h';
  };

  public getBrowserDateFormat = (): string => {
    const testDate = new Date(Date.UTC(2022, 11, 22)); // 22 de diciembre de 2022
    const userFormat = new Intl.DateTimeFormat().format(testDate);
    const luxonDate = DateTime.fromJSDate(testDate);

    const finalMatch = this.getMappedAvailableDateFormats.find((option) => {
      const luxonFormat = luxonDate.toFormat(option.format);
      const luxonAltFormat = luxonDate.toFormat(option.altFormat);

      return luxonFormat === userFormat || luxonAltFormat === userFormat;
    });

    return finalMatch?.format || 'dd/MM/yyyy';
  };
}

export default new DateHelper();
