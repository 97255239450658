import type { SimpleResponse } from '@/types/Api';
import type { SiteReportAnnotation } from '@/types/models/site/service/report/annotation/SiteReportAnnotation';
import { AbstractService } from '@modular/sdk';
import { apiEndpoints } from '@/types/routes/routes';
import type { ReportAnnotationCreateRequest } from '@/api/services/site/service/report/annotation/index';

export default class AnnotationService extends AbstractService {
  /**
   * Updates a Site Report Detail status
   *
   * @param {number} reportId
   * @returns {Promise<SiteReportAnnotation[]>} A promise that resolves to the updated annotations
   */
  public all(reportId: number): Promise<SiteReportAnnotation[]> {
    return this.request({
      method: apiEndpoints.siteReportsAnnotationsIndex.method,
      url: this.buildPath(apiEndpoints.siteReportsAnnotationsIndex, {
        site_report: reportId,
      }),
    });
  }

  /**
   * Creates a Site Report Annotation.
   *
   * @param {number} reportId
   * @param {ReportAnnotationCreateRequest} data
   * @returns {Promise<SiteReportAnnotation>} A promise that resolves to the created annotation
   */
  public create(reportId: number, data: ReportAnnotationCreateRequest): Promise<SiteReportAnnotation> {
    return this.request({
      method: apiEndpoints.siteReportsAnnotationsStore.method,
      url: this.buildPath(apiEndpoints.siteReportsAnnotationsStore, {
        site_report: reportId,
      }),
      data,
    });
  }

  /**
   * Updates a specific Site Report Annotation.
   *
   * @param {number} reportId
   * @param {number} annotationId
   * @param {ReportAnnotationCreateRequest} data
   * @returns {Promise<SiteReportAnnotation>} A promise that resolves to the updated annotation
   */
  public update(
    reportId: number,
    annotationId: number,
    data: ReportAnnotationCreateRequest,
  ): Promise<SiteReportAnnotation> {
    return this.request({
      method: apiEndpoints.siteReportsAnnotationsUpdate.method,
      url: this.buildPath(apiEndpoints.siteReportsAnnotationsUpdate, {
        site_report: reportId,
        annotation: annotationId,
      }),
      data,
    });
  }

  /**
   * Deletes a specific Site Report Annotation.
   *
   * @param {number} reportId
   * @param {number} annotationId
   * @returns {Promise<SimpleResponse>} A promise that resolves to the deleted annotation
   */
  public delete(
    reportId: string | number,
    annotationId: string | number,
  ): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.siteReportsAnnotationsDestroy.method,
      url: this.buildPath(apiEndpoints.siteReportsAnnotationsDestroy, {
        site_report: reportId,
        annotation: annotationId,
      }),
    });
  }
}
