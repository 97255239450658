<script lang="ts">
import { defineComponent } from 'vue';

/**
 * TODO DELETE ME
 */
export default defineComponent({
  name: 'ModalBody',
});
</script>

<template>
  <div class="modal-body">
    <slot />
  </div>
</template>
