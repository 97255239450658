<script lang="ts" setup>
import type { IUserConfig } from '@/helpers/storage/userConfigStorage';

import type { Tag } from '@/types/models/organization/tag/Tag';

import type { User } from '@/types/models/user/User';

import api from '@/api';

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useUserConfig } from '@/composables/useUserConfig';

import useUserStore from '@/stores/userStore';
import { reactive } from 'vue';

defineOptions({
  name: 'DashboardSitesFilters',
});

withDefaults(
  defineProps<{
    sitesHaveLoaded: boolean
    sitesAreEmpty: boolean
    location: '' | 'Dashboard' | 'My sites' | 'Team page'
  }>(),
  {
    sitesHaveLoaded: false,
    sitesAreEmpty: true,
    location: '',
  },
);

const emit = defineEmits(['updateOrder', 'updateTags', 'added', 'changedTags', 'tagRemoved', 'clearFilters']);

const orderBy = defineModel('order', {
  default: 'visited_at-desc',
  required: true,
  type: String,
});

const feedType = defineModel('feed', {
  default: 'grid',
  required: true,
  type: String,
});

const tags = defineModel('tags', {
  default: [],
  required: true,
  type: Array<Tag>,
});

const dropdownMenuIsOpen = ref(false);

const dropdownOptions = reactive([
  {
    order: 'name',
    type: 'asc',
    text: 'general.shared.name',
    extra: ' A-Z',
  },
  {
    order: 'name',
    type: 'desc',
    text: 'general.shared.name',
    extra: ' Z-A',
  },
  {
    order: 'visited_at',
    type: 'desc',
    text: 'menu.recents',
    extra: '',
  },
  {
    order: 'visited_at',
    type: 'asc',
    text: 'general.shared.older',
    extra: '',
  },
]);

const defaultConfig: Partial<IUserConfig> = {
  siteOrderColumn: 'visited_at',
  siteOrderSort: 'desc',
  siteViewMode: 'grid',
};

const userConfig = useUserConfig();

const savedConfig = ref<IUserConfig>({
  ...defaultConfig,
  ...userConfig.getConfig(),
});

const userStore = useUserStore();

async function updateUserSettings({ column, sort, type }: { column?: string, sort?: string, type?: string }) {
  try {
    const { siteOrderColumn, siteOrderSort, siteViewMode } = userConfig.getConfig();

    const savedUser: User = await api.user.general.updateSettings({
      dashboard_site_order: {
        column: column || siteOrderColumn,
        sort: sort || siteOrderSort,
      },
      dashboard_site_view_mode: type || siteViewMode,
    });

    await userStore.refreshUser(savedUser);
  } catch (e) {
    console.error(e);
  }
}

async function onChangeGroupByFilter(column: string, sort: string) {
  await userConfig.setConfig({ siteOrderColumn: column, siteOrderSort: sort });

  orderBy.value = `${column}-${sort}`;

  emit('updateOrder');

  await updateUserSettings({ column, sort });
}

async function changeFeedType(type: string) {
  await userConfig.setConfig({ siteViewMode: type });

  feedType.value = type;

  await updateUserSettings({ type });
}

function handleUpdateTagSelection(data: Tag[] | []) {
  tags.value = data || [];

  emit('changedTags');
}

onMounted(() => {
  orderBy.value = `${savedConfig.value.siteOrderColumn}-${savedConfig.value.siteOrderSort}`;
  feedType.value = savedConfig.value.siteViewMode || 'grid';
});
</script>

<template>
  <div class="mb-16">
    <div class="w-100 d-flex align-items-center justify-content-between flex-wrap mb-16">
      <slot name="options" />

      <div id="sites-filter-buttons" class="d-flex">
        <div v-show="!(sitesHaveLoaded && sitesAreEmpty)" class="feed-type d-flex ms-auto">
          <slot name="header-actions" />

          <DropdownMenu v-model:open="dropdownMenuIsOpen">
            <DropdownMenuTrigger
              class="fw-semi position-relative rounded-0 lh-1 me-24 border-0 bg-transparent text-sm outline-0"
              spacing="p-8"
              variant="underline"
            >
              <span v-if="orderBy === 'name-asc'">{{ `${$t('general.shared.name')} A-Z` }}</span>
              <span v-if="orderBy === 'name-desc'">{{ `${$t('general.shared.name')} Z-A` }}</span>
              <span v-else-if="orderBy === 'visited_at-desc'">{{ $t('menu.recents') }}</span>
              <span v-else-if="orderBy === 'visited_at-asc'">{{ $t('general.shared.older') }}</span>

              <v-icon
                :class="{ open: dropdownMenuIsOpen }"
                class="dropdown-arrow ms-8"
                icon="arrow-caret-down"
                size="2xs"
              />
            </DropdownMenuTrigger>

            <DropdownMenuContent>
              <template v-for="option in dropdownOptions" :key="`${option.order}-${option.type}`">
                <DropdownMenuItem
                  :class="{ active: orderBy === `${option.order}-${option.type}` }"
                  @click="onChangeGroupByFilter(option.order, option.type)"
                >
                  <span>{{ $t(option.text) }}{{ option.extra }}</span>
                </DropdownMenuItem>
              </template>
            </DropdownMenuContent>
          </DropdownMenu>

          <VButton
            :aria-label="$t('dashboard.listMode')"
            :class="{ 'text-primary': feedType === 'list' }"
            :title="$t('dashboard.listMode')"
            class="btn-transparent rounded-0 me-12 border-0 p-4 text-gray-500"
            data-cy="type-list"
            variant="icon"
            @click="changeFeedType('list')"
          >
            <v-icon icon="feedlist" size="xxl" />
          </VButton>

          <VButton
            :aria-label="$t('dashboard.gridMode')"
            :class="{ 'text-primary': feedType === 'grid' }"
            :title="$t('dashboard.gridMode')"
            class="btn-transparent rounded-0 border-0 p-4 text-gray-500"
            data-cy="type-grid"
            variant="icon"
            @click="changeFeedType('grid')"
          >
            <v-icon icon="feed-grid" size="xxl" />
          </VButton>
        </div>
      </div>
    </div>

    <div class="d-flex w-100">
      <span class="text-nowrap text-sm fw-semi me-12 pt-4">{{ $t('general.shared.filterBy') }}</span>

      <div class="d-flex flex-wrap gap-4 w-100">
        <VTagsCombobox
          :location="location"
          :model-value="tags"
          emit-changed-on-close
          type="filter"
          @changed-tags="handleUpdateTagSelection($event)"
          @tag-removed="$emit('tagRemoved', $event)"
        />

        <VButton
          v-show="!!tags && tags.length > 0"
          :aria-label="$t('general.button.clearFilters')"
          :title="$t('general.button.clearFilters')"
          class="fw-semi ms-auto"
          size="sm"
          variant="link"
          @click="$emit('clearFilters')"
        >
          {{ $t('general.button.clearFilters') }}
        </VButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#sites-filter-buttons {
  :deep(#sitesOrderSelector)::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0.125rem;
    left: 0.5rem;
    right: 0.75rem;
    background-color: var(--md-primary);
    border-radius: 5rem;
  }
}
</style>
