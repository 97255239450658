<script lang="ts" setup>
import type { Tag } from '@/types/models/organization/tag/Tag';
import type { AcceptableValue } from 'radix-vue/dist/shared/types';

import api from '@/api';

import { can } from '@/helpers/permissionsHelper';
import useSiteStore from '@/stores/site/siteStore';

import useTagsStore from '@/stores/tag/tagsStore';
import { PermissionEnumConst } from '@/types/PermissionEnum';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

defineOptions({
  name: 'VTagsComboboxContent',
});

const props = withDefaults(
  defineProps<{
    location: '' | 'Dashboard' | 'My sites' | 'Team page' | 'Overview' | 'Settings page' | 'Contextual menu' | 'Tags shortcut' | 'Manager' | 'Alerting' | 'Manager installation'
    syncTag?: boolean
    create?: boolean
    edit?: boolean
    type?: 'tags' | 'filter'
    disableApply?: boolean
  }>(),
  {
    syncTag: false,
    location: '',
    create: true,
    edit: true,
    type: 'tags',
    disableApply: false,
  },
);

const emit = defineEmits(['selected', 'changedTags', 'applyFilter', 'tagRemoved']);

const { TAGS_UPDATE, TAGS_STORE } = PermissionEnumConst;

const modelValue = defineModel<Tag[]>();

const openPopover = defineModel('openPopover', {
  default: false,
  required: false,
  type: Boolean,
});

const searchTerm = defineModel('searchTerm', {
  required: false,
  type: String,
});

const tagsStore = useTagsStore();

const { tags: localTags, dataRequested } = storeToRefs(tagsStore);

const siteStore = useSiteStore();

// To force do not apply con My Websites or Team at creating a tag
const applyToCurrentSite = computed(() => !!siteStore.currentSite);

const openCreate = ref(false);

async function selectNewTag(tag: Tag) {
  if (!!modelValue.value) {
    if (props.syncTag) {
      emit('selected', tag);
    } else {
      modelValue.value.push(tag);

      emit('changedTags');
    }

    if (!!props.location) {
      await api.mixpanel.track('Change tag', {
        'Tag name': tag.name,
        'Location': props.location || '',
      });
    }
  }
}

const loaded = ref(false);

/**
 * Checks if the tag is selected by checking if exists in modelValue
 * @param tag
 */
function isTagSelected(tag: Tag) {
  return !!modelValue.value && !!modelValue.value.find(item => item.id === tag.id);
}

const openEditTagCollapse = ref<boolean[]>([]);

function openEditTag(index: string | number, val: boolean) {
  const localIndex = Number(index);

  if (openEditTagCollapse.value.length > 0) {
    openEditTagCollapse.value = openEditTagCollapse.value.map(() => false);
  }

  openEditTagCollapse.value[localIndex] = val ? !val : true;
}

function closeAllOpenTagCollapse() {
  openEditTagCollapse.value = openEditTagCollapse.value.map(() => false);
}

onBeforeMount(async () => {
  if (!tagsStore.hasData) {
    await tagsStore.getTags();

    loaded.value = true;
  }
});

onUnmounted(() => {
  searchTerm.value = '';
});

const selectedItem = ref<AcceptableValue | undefined>(undefined);

function filterFunction(list: Tag[], term: string) {
  return list.filter((i: any) => i.name?.toLowerCase()?.includes(term.toLowerCase()));
}

function updateModelValue(val: AcceptableValue[]) {
  emit('changedTags', !!modelValue.value && !!val.length && val.length > modelValue.value?.length);
}
</script>

<template>
  <Combobox
    v-model="modelValue"
    v-model:open="openPopover"
    v-model:search-term="searchTerm"
    v-model:selected-value="selectedItem"
    :filter-function="filterFunction"
    multiple
    @update:model-value="updateModelValue"
    @update:open="!tagsStore.hasData ? tagsStore.getTags() : () => {}"
    @update:search-term="closeAllOpenTagCollapse()"
  >
    <ComboboxInput
      :placeholder="$t('tags.searchTag')"
      class="m-0 px-16"
      size="sm"
      type="transparent"
      :auto-focus="false"
      @keydown.enter.prevent="!selectedItem && create && can(TAGS_STORE) ? (openCreate = true) : null"
    >
      <template #cancel>
        <ComboboxCancel v-if="!!searchTerm && searchTerm.length > 0" />
      </template>
    </ComboboxInput>

    <ComboboxContent :class="{ 'pb-16': !create, 'pb-0': create }" position="inline" style="min-width: 370px">
      <ComboboxEmpty v-if="dataRequested">
        {{ $t('tags.notFound') }}
      </ComboboxEmpty>

      <ScrollArea :max-height="openCreate ? '10rem' : '19rem'">
        <ComboboxGroup v-if="!!localTags && localTags.length > 0" class="d-flex flex-column" spacing="pt-0">
          <template v-if="!dataRequested">
            <div v-for="n in 6" :key="n" class="px-16 py-4">
              <span class="placeholder-glow w-100">
                <span class="placeholder" style="width: 100%; height: 20px" />
              </span>
            </div>
          </template>

          <template v-else>
            <template v-for="(localTag, index) in localTags" :key="index">
              <ComboboxItem :value="localTag" class="my-2" @select="$emit('selected', localTag)">
                <VTagsComboboxContentItem
                  v-model="localTags[index]"
                  :color="localTags[index].color"
                  :is-selected="isTagSelected(localTags[index])"
                  :location="location"
                  :show-count="type === 'filter'"
                  :tag-id="localTags[index].id"
                  show-remove
                  @tag-removed="$emit('tagRemoved', $event)"
                >
                  <template #trigger>
                    <m-button
                      v-if="edit"
                      :permission="TAGS_UPDATE"
                      class="fw-medium p-0"
                      size="xs"
                      variant="link"
                      @click.stop="openEditTag(index, openEditTagCollapse[index])"
                    >
                      <m-icon class="text-base" icon="edit" />
                    </m-button>
                  </template>
                </VTagsComboboxContentItem>
              </ComboboxItem>

              <div v-can="TAGS_UPDATE" class="px-12">
                <VTagsComboboxContentEdit
                  v-if="edit"
                  v-model="localTags[index]"
                  :color="localTags[index].color"
                  :is-last="localTags.length - 1 === index"
                  :is-selected="isTagSelected(localTags[index])"
                  :location="location"
                  :open="openEditTagCollapse[index]"
                  :search="localTags[index].name"
                  :tag-id="Number(localTags[index].id)"
                  show-remove
                  @tag-removed="$emit('tagRemoved', $event)"
                  @update:open="closeAllOpenTagCollapse()"
                />
              </div>
            </template>
          </template>
        </ComboboxGroup>
      </ScrollArea>

      <ComboboxSeparator v-if="create || type === 'filter'" />

      <VTagsComboboxContentEdit
        v-if="create || type === 'filter'"
        v-model:open="openCreate"
        :allow-apply="type === 'filter'"
        :create="create"
        :disable-apply="disableApply"
        :location="location"
        :search="searchTerm"
        class="px-4"
        @save="(e) => (applyToCurrentSite ? selectNewTag(e) : null)"
        @tag-removed="$emit('tagRemoved', $event)"
        @update:open="closeAllOpenTagCollapse()"
        @apply-filters="$emit('applyFilter')"
      />
    </ComboboxContent>
  </Combobox>
</template>
