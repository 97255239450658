export default {
  fileTypeNotAllowed: 'Debe cargar un archivo de tipo',
  tooLarge: 'Archivo demasiado grande',
  tooSmall: 'Archivo demasiado pequeño',
  tryAgain: 'intenta subir este archivo de nuevo',
  somethingWentWrong: 'Algo salió mal al cargar este archivo',
  selectOrDragMax: 'Seleccione o arrastre máximo 1 archivo | Seleccione o arrastre máximo {max} archivos',
  selectOrDrag: 'Seleccionar o arrastrar archivos',
  file: {
    singular: 'archivo',
    plural: 'archivos',
  },
  anyImage: 'cualquier imagen',
  anyVideo: 'cualquier video',
  goBack: 'Volver',
  dropFile: 'Soltar archivo para cargar',
  dragHere: 'Arrastra el archivo aquí',
  remove: 'Eliminar',
  name: 'nombre',
  download: 'Descargar',
};
