<script lang="ts" setup>
import { useRouter } from 'vue-router';

defineOptions({
  name: 'VModalLayout',
});

withDefaults(
  defineProps<{
    title: string
    titleContentClass: string
  }>(),
  {
    title: '',
    titleContentClass: 'container-fluid',
  },
);

defineSlots<{
  default: () => any
  footer: () => any
}>();

const router = useRouter();

function goBack() {
  router.back();
  // router.push(String(route.query.origin));
}
</script>

<template>
  <main class="position-relative">
    <v-modal
      id="modal-layout"
      ref="modal"
      backdrop="false"
      :backdrop-static="false"
      size="fullscreen"
      content-class="p-0"
      disable-teleport
      modal-classes="modal-fullscreen-index"
    >
      <modal-body class="p-0" style="overflow-x: hidden">
        <modal-title
          v-if="!!title"
          class="text-xxl fw-bold px-40 py-32"
        >
          <div class="d-flex justify-content-between px-16" :class="titleContentClass">
            {{ $t(title) }}

            <VButton
              :aria-label="$t('general.modal.close')"
              :title="$t('general.modal.close')"
              class="btn-link fw-medium z-index-10 d-block"
              size="sm"
              @click="goBack()"
            >
              {{ $t('general.modal.close') }}
            </VButton>
          </div>
        </modal-title>

        <div id="modal-layout-body" class="container-fluid w-100 px-32">
          <router-view v-slot="{ Component }">
            <transition appear mode="out-in" name="fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </modal-body>

      <modal-footer v-if="!!$slots.footer" id="modal-layout-footer">
        <router-view v-slot="{ Component }">
          <component :is="Component">
            <template #footer="props">
              <component :is="Component" :props="props" />
            </template>
          </component>
        </router-view>
      </modal-footer>
    </v-modal>
  </main>
</template>

<style lang="scss" scoped>
#the-billing-plans {
  .modular-logo :deep(img) {
    max-width: 9rem;
  }

  .modal-body {
    scrollbar-gutter: stable;
  }
}
</style>
