export const ProductsEnumConst = {
  FREE: 'FREE',
  STARTER: 'Starter',
  BUSINESS: 'Business',
} as const;

export type ProductsEnumType = (typeof ProductsEnumConst)[keyof typeof ProductsEnumConst];

export const AllProductsEnumConst = {
  PRO: 'pro',
  STARTER: 'starter',
  EARLY_ADOPTER: 'earlyAdopter',
  BUSINESS: 'business',
} as const;

export type AllProductsEnumType = (typeof AllProductsEnumConst)[keyof typeof AllProductsEnumConst];
