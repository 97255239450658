<script lang="ts" setup>
import type { StringSchema } from 'yup';
import api from '@/api';
import useValidation from '@/composables/useValidation';

import { useVueSelectPosition } from '@/composables/useVueSelectPosition';
import useOrganizationStore from '@/stores/organizationStore';

import useUserStore from '@/stores/userStore';
import TheSiteConnectLoad from '@/views/site/connect/parts/TheSiteConnectLoad.vue';

import { useField } from 'vee-validate';

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useRoute, useRouter } from 'vue-router';
import { object, string } from 'yup';

defineOptions({
  name: 'TheSiteCreateConnection',
});

defineProps({
  legend: {
    type: String,
    required: false,
    default: 'site.create.title',
  },
});

const emits = defineEmits(['closeModal']);

const userStore = useUserStore();
const organizationStore = useOrganizationStore();

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const teams = computed(() => userStore.teams);
const computedTeams = computed(() =>
  !!teams.value
    ? teams.value.map((team: any) => ({
      name: team.name,
      slug: team.slug,
      id: team.id,
    }))
    : [],
);

const connectionState = ref('credentials');
const protocol = ref('https://');
const selectedTeam = ref(0);
const site = ref('');

const animation = computed(() =>
  connectionState.value === 'credentials' ? 'page-transit-move-reverse' : 'page-transit-move',
);

const rules = object({
  currentTab: string().required(),
  name: string()
    .required()
    .max(60, t('validation.string.max', { max: 60 })),
  uri: string().required(),
  teamName: string()
    .max(60, t('validation.string.max', { max: 60 }))
    .when('currentTab', (currentTab: string | any, schema: StringSchema) => {
      const [tab] = currentTab;

      return tab === 'site-create-team' ? schema.required() : schema.nullable();
    }),
});

const initialValues = {
  currentTab: 'site-select-team',
};

const { errors, accepted, toggleAccepted, setErrors, setFieldError, resetField, validate } = useValidation(
  rules,
  initialValues,
);

const { value: name } = useField<string>('name');
const { value: uri } = useField<string>('uri');
const { value: teamName } = useField<string>('teamName');
const { value: currentTab } = useField<string>('currentTab');

function validateHttp(uriAux: string, protocolAux: string) {
  let validatedUri: string = uriAux;

  validatedUri = window.decodeURIComponent(validatedUri);
  validatedUri = validatedUri.trim().replace(/\s/g, '');

  const pattern = /^((http|https|ftp):\/\/)/;

  if (!pattern.test(validatedUri)) {
    validatedUri = `${protocolAux}${validatedUri}`;
  }

  const regExp = /\/(wp-admin|wp-login)\/?$/;

  if (regExp.test(validatedUri)) {
    validatedUri = validatedUri.replace(regExp, '');
  }

  return validatedUri;
}

async function redirectToConnect() {
  await organizationStore.manager();

  await router.push({
    name: 'sites.site.connect',
    params: {
      team: selectedTeam.value,
      site: site.value,
    },
    query: {
      from: 'new-site',
    },
  });

  emits('closeModal');
}

async function onSubmit() {
  try {
    toggleAccepted();

    const response = await validate();

    if (!response.valid)
      return;

    connectionState.value = 'loading';

    const validatedUri = validateHttp(uri.value, protocol.value);

    let teamId = selectedTeam.value;

    if (currentTab.value === 'site-create-team') {
      const team = await api.team.create(teamName.value);
      teamId = team.id;

      selectedTeam.value = teamId;
    }

    site.value = (
      await api.site.general.create({
        name: name.value,
        provider: 'wp',
        uri: validatedUri,
        team: teamId,
      })
    ).slug;

    await redirectToConnect();

    await Promise.all([userStore.refreshTeams()]);
  } catch (e: any) {
    connectionState.value = 'credentials';

    if (e.response?.data?.errors) {
      if (e.response?.data?.errors.uri) {
        setFieldError('uri', t('site.create.notValidUrl'));
      } else {
        setErrors(e.response.data.errors);
      }
    }

    if (currentTab.value === 'site-create-team') {
      await userStore.refreshTeams();
    }
  } finally {
    toggleAccepted();
  }
}

function setInitialTeam() {
  if (!!route.params.team) {
    const team = teams.value.find((item: any) => route.params.team === item.slug);

    if (!!team) {
      selectedTeam.value = team.id;
    }
  } else if (teams.value.length > 0) {
    selectedTeam.value = teams.value[0].id;
  }
}

watch(() => computedTeams.value, (newVal) => {
  if (!!newVal && newVal.length > 0) {
    setInitialTeam();
  }
}, { immediate: true });
</script>

<template>
  <div class="connect-container position-relative d-flex align-items-center my-auto">
    <transition-group :name="animation" class="connect-inner-container position-relative" mode="out-in" tag="div">
      <div
        v-if="connectionState === 'credentials'"
        key="credentials"
        class="row gx-12 gy-12 align-content-start form-group animated mx-0"
      >
        <h3 class="fw-medium mt-0 text-sm">
          {{ t(legend) }}
        </h3>

        <div class="col-3">
          <select v-model="protocol" aria-label="HTTPS" class="form-select col-4" data-cy="create-site-protocol">
            <option selected value="https://">
              https://
            </option>
            <option value="http://">
              http://
            </option>
          </select>
        </div>

        <div class="col-9">
          <VInputTextFloating
            id="site-uri"
            v-model="uri"
            :label="t('site.create.uri')"
            :yup-errors-variable="errors.uri"
            autocomplete="off"
            data-cy="create-site-uri"
            placeholder="localhost/wordpress/"
            @keyup.enter="onSubmit"
          />
        </div>

        <div class="col-12">
          <VInputTextFloating
            id="site-name"
            v-model="name"
            :label="t('site.create.name')"
            :placeholder="t('site.create.name')"
            :yup-errors-variable="errors.name"
            autocomplete="off"
            data-cy="create-site-name"
            @keyup.enter="onSubmit"
          />
        </div>

        <div>
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <VButton
                id="nav-site-create-team"
                aria-controls="site-create-team"
                aria-selected="true"
                class="nav-link active text-sm"
                data-bs-target="#site-create-team"
                data-bs-toggle="tab"
                data-cy="nav-site-select-team"
                role="tab"
                @click="
                  currentTab = 'site-select-team';
                  resetField('teamName');
                "
              >
                {{ t('site.create.addToExistingTeam') }}
              </VButton>
            </li>

            <li class="nav-item">
              <VButton
                id="nav-site-select-team"
                aria-controls="site-select-team"
                aria-selected="false"
                class="nav-link text-sm"
                data-bs-target="#site-select-team"
                data-bs-toggle="tab"
                data-cy="nav-site-create-team"
                role="tab"
                @click="currentTab = 'site-create-team'"
              >
                {{ t('site.create.addToNewTeam') }}
              </VButton>
            </li>
          </ul>

          <div id="site-team-tabs-content" class="tab-content position-relative">
            <div
              id="site-create-team"
              aria-labelledby="nav-site-create-team"
              class="tab-pane fade active show"
              data-cy="site-create-team"
              role="tabpanel"
            >
              <VSelect
                key="id"
                v-model="selectedTeam"
                :calculate-position="useVueSelectPosition"
                :clearable="false"
                :loading="!teams || Object.keys(teams).length === 0"
                :options="computedTeams"
                :placeholder="t('site.create.team')"
                :reduce="(team: Record<string, any>) => team.id"
                append-to-body
                data-cy="site-create-select-team"
                label="name"
              >
                <template #no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ t('report.analytics.noResults', { search }) }}
                  </template>
                </template>

                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <v-icon icon="chevron-down" size="sm" />
                  </span>
                </template>
              </VSelect>
            </div>

            <div id="site-select-team" aria-labelledby="nav-site-select-team" class="tab-pane fade" role="tabpanel">
              <VInputTextFloating
                id="site-create-team-name"
                v-model="teamName"
                :label="t('team.shared.name')"
                :placeholder="t('team.shared.name')"
                :yup-errors-variable="errors.teamName"
                autocomplete="off"
                data-cy="site-create-team-name"
                @keyup.enter="onSubmit"
              />
            </div>
          </div>
        </div>
      </div>

      <TheSiteConnectLoad
        v-else-if="connectionState === 'loading'"
        key="loading"
        form-state="creating_site"
        :title="name"
        class="align-content-start"
        mode="validating"
      />
    </transition-group>

    <Teleport to="#the-site-create-footer">
      <VButton
        v-if="connectionState === 'credentials'"
        id="create-site-button"
        ref="createButton"
        :is-loading="accepted"
        data-cy="btn-site-create-continue"
        variant="primary"
        @click="onSubmit()"
      >
        {{ t('general.button.continue') }}
      </VButton>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.connect-container {
  width: 37.5rem;

  &.loading {
    width: 37.5rem;
  }

  .connect-inner-container {
    width: 37.5rem;
    height: 280px;

    > div {
      height: 280px;
    }
  }
}
</style>
