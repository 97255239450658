import type { ComponentSocketEventMapped, ManagerActionKeys } from '@/stores/manager/index';
import type { SiteItemTypeEnumKeys } from '@/types/enums/SiteItemTypeEnum';
import type { SiteManagerActionEnumType } from '@/types/enums/SiteManagerActionEnum';
import type { IComponent, IComponentSelection } from '@/types/models/organization/manager/Component';
import type { SiteItem } from '@/types/models/site/updater/SiteItem';
import type { Ref } from 'vue';
import api from '@/api';
import { SiteItemStatusEnumConst } from '@/types/enums/SiteItemStatusEnum';

export function createComponentProcessor(
  componentsMapRef: Ref<Map<number, IComponent>>,
  selection: IComponentSelection[] | ComponentSocketEventMapped,
  action: ManagerActionKeys | SiteManagerActionEnumType,
  socket = false,
) {
  const componentsMap = componentsMapRef.value;
  const selectedComponentsMap = new Map<number, IComponent>();

  if (socket) {
    const socketObject: ComponentSocketEventMapped = selection as ComponentSocketEventMapped;
    const componentFound = componentsMap.get(socketObject.componentId);
    const componentFoundClone: IComponent = { ...componentFound } as IComponent;

    if (!!componentFoundClone && !!componentFound) {
      if (!!componentFoundClone.siteItemsMap && componentFoundClone.siteItemsMap.size > 0 && !!componentFound.siteItemsMap && componentFound.siteItemsMap.size > 0) {
        const siteItemKeys: number[] = [];

        socketObject.siteItemsMap.forEach((siteItemSocket) => {
          siteItemKeys.push(siteItemSocket.id);
        });

        componentFoundClone.siteItemsMap = new Map(
          Array.from(componentFoundClone.siteItemsMap)
            .filter(([siteItemId]) => siteItemKeys.includes(Number(siteItemId))),
        );
      }

      selectedComponentsMap.set(Number(componentFoundClone.id), componentFoundClone);
    }
  } else {
    (selection as IComponentSelection[]).forEach((componentSelected) => {
      const componentFound: IComponent = { ...componentsMap.get(componentSelected.id) } as IComponent;
      if (!componentFound)
        return;

      const siteItemIds = componentSelected.all ? componentSelected.excluded : componentSelected.included;

      if (!!componentFound.siteItemsMap && componentFound.siteItemsMap.size > 0) {
        componentFound.siteItemsMap = new Map(
          Array.from(componentFound.siteItemsMap).filter(([siteItemId]) => componentSelected.all
            ? !siteItemIds.includes(Number(siteItemId))
            : siteItemIds.includes(Number(siteItemId))),
        );
      }

      selectedComponentsMap.set(Number(componentFound.id), componentFound);
    });
  }

  const processItems = (processFn: ({ component, siteItem }: {
    component?: IComponent
    siteItem?: SiteItem
  }) => void) => {
    selectedComponentsMap.forEach((componentSelected) => {
      const componentFound = componentsMapRef.value.get(Number(componentSelected.id));
      if (!componentFound)
        return;

      if (!!componentSelected.siteItemsMap && componentSelected.siteItemsMap.size > 0) {
        componentSelected.siteItemsMap.forEach((siteItem) => {
          const siteItemFound = componentFound.siteItemsMap?.get(Number(siteItem.id));

          if (!siteItemFound)
            return;

          processFn({ component: componentFound, siteItem: siteItemFound });
        });
      }

      processFn({ component: componentFound });
    });
  };

  return {
    componentsMapRef,

    setToProcessing() {
      processItems(({ component, siteItem }: { component?: IComponent, siteItem?: SiteItem }) => {
        if (!component)
          return this;

        if (siteItem) {
          siteItem.processing = true;
        } else {
          const componentSelection = (selection as IComponentSelection[])
            .find(componentSelected => component.id === componentSelected.id);

          if (componentSelection && component.processing !== undefined) {
            const siteItemIds = componentSelection.all ? componentSelection.excluded : componentSelection.included;
            const adjustment = componentSelection.all ? !!component.siteItemsCount ? component.siteItemsCount : 0 - siteItemIds.length : siteItemIds.length;
            component.processing += adjustment;
          }
        }
      });

      return this;
    },

    setToHiddenVisible() {
      const isHidden = action === 'hide';

      processItems(({ component, siteItem }: { component?: IComponent, siteItem?: SiteItem }) => {
        if (!!component && !siteItem)
          component.hasHidden = isHidden;
        if (!!siteItem)
          siteItem.isHidden = isHidden;
      });
      return this;
    },

    setToActiveInactive() {
      const active = action === 'activate' ? 'active' : 'inactive';

      processItems(({ siteItem }: { siteItem?: SiteItem }) => {
        if (!!siteItem)
          siteItem.status = active;
      });
      return this;
    },

    hideShowRemove() {
      processItems(({ component, siteItem }: { component?: IComponent, siteItem?: SiteItem }) => {
        if (!component)
          return this;

        if (siteItem && !!component.siteItemsCount) {
          component.siteItemsCount -= 1;
          component.siteItemsMap?.delete(Number(siteItem.id));
        } else {
          const componentSelection = (selection as IComponentSelection[])
            .find(componentSelected => component.id === componentSelected.id);

          if (componentSelection) {
            const siteItemIds = componentSelection.all ? componentSelection.excluded : componentSelection.included;

            if (componentSelection.all && siteItemIds.length === 0) {
              component.siteItemsCount = 0;
            }
          }
        }

        if (component.siteItemsCount === 0)
          componentsMapRef.value.delete(Number(component.id));
      });
      return this;
    },

    removeProcessing() {
      processItems(({ siteItem }: { siteItem?: SiteItem }) => {
        if (!!siteItem)
          siteItem.processing = false;
      });

      return this;
    },

    setNewValues() {
      processItems(({ component, siteItem }: { component?: IComponent, siteItem?: SiteItem }) => {
        if (!!component && !!siteItem) {
          const siteItemFound = (selection as ComponentSocketEventMapped).siteItemsMap.get(Number(siteItem.siteId));

          if (!!siteItemFound) {
            siteItem.version = siteItemFound.version;
            siteItem.newVersion = siteItemFound.newVersion;
            siteItem.status = siteItemFound.status;
          }
        }
      });

      return this;
    },

    delete() {
      processItems(({ component, siteItem }: { component?: IComponent, siteItem?: SiteItem }) => {
        if (!component || !siteItem)
          return this;

        if (siteItem.status === SiteItemStatusEnumConst.UNINSTALLED || siteItem.newVersion === null) {
          component.siteItemsMap?.delete(Number(siteItem.id));
        }
      });
      return this;
    },

  };
}

export function trackManagerAction(
  componentsSlugsArray: Array<string>,
  type: SiteItemTypeEnumKeys,
  action: SiteManagerActionEnumType,
  count: number,
  isGlobal: boolean,
) {
  const adjustedAction = action === 'upgrade' ? 'update' : action;

  api.mixpanel.track(`${adjustedAction.charAt(0).toUpperCase() + adjustedAction.slice(1)
  } ${type}`, {
    Names: componentsSlugsArray,
    Location: isGlobal ? 'Updater' : 'Website',
    Number: count,
  });
}
