export default {
  loadingTitle: 'Buscando errores críticos...',
  loadingText: 'Estamos analizando tu web y buscando potenciales errores. Este proceso puede tardar varios minutos.',
  numCriticalErrors: '{num} error crítico | {num} errores críticos',
  title: 'Estado de salud del sitio',
  description:
    'La comprobación del estado de salud del sitio muestra una información crítica acerca de la configuración de tu WordPress y los elementos que podrían requerir tu atención.',
  wpVersion: 'WordPress',
  phpVersion: 'PHP',
  modularVersion: 'Modular',
  versionShort: 'V { version }',
  lastCheck: 'Última comprobación',
  checking: 'Estamos comprobando el estado del sitio',
  now: 'Ahora...',
  checkNow: 'Comprobar ahora',
  nextCheck: 'Próxima comprobación en {date}',
  noData: 'Estamos comprobando el estado del sitio. Esto puede tardar unos minutos.',
  generalStatus: {
    title: {
      plugin_not_updated: 'Actualizaciones de plugins',
      plugin_inactive: 'Plugins inactivos',
      theme_not_updated: 'Actualizaciones de temas',
      has_ssl: 'Certificado SSL',
      is_in_debug_mode: 'WP Debug',
      is_public: 'Indexación',
    },
    good: {
      plugin_not_updated: 'Todos los plugins están actualizados',
      plugin_inactive: 'No tienes plugins desactivados',
      theme_not_updated: 'Todos los temas están actualizados',
      has_ssl: 'El certificado SSL es válido y está activo',
      is_in_debug_mode: 'El WP Debug está en false',
      is_public: 'La web es indexable por los motores de búsqueda',
    },
    recommended: {
      plugin_not_updated: 'Tienes plugins sin actualizar',
      plugin_inactive: 'Tienes plugins desactivados',
      theme_not_updated: 'Tienes temas sin actualizar',
      is_in_debug_mode: 'El {variable} está en {value}',
    },
    critical: {
      plugin_not_updated: 'Tienes plugins sin actualizar',
      plugin_inactive: 'Tienes plugins desactivados',
      theme_not_updated: 'Tienes temas sin actualizar',
      has_ssl: 'El certificado SSL no es válido y no está activo',
      is_in_debug_mode: 'El WP Debug está en true',
      is_public: 'La web no es indexable por los motores de búsqueda',
    },
  },
  stateSite: 'Estado del sitio',
  siteInformation: 'Información del sitio',
  vulnerabilities: 'Vulnerabilidades',
  criticalErrors: {
    title: 'Errores críticos',
    description:
      'Los problemas críticos son elementos que podrían tener un gran impacto en el rendimiento o seguridad del sitio y debería darse prioridad a solucionar estos problemas.',
    eachYearPayment: 'Al mes, pagando anualmente',
    everythingWorking: 'Todo está funcionando correctamente',
    noErrors: 'No hay errores críticos',
    empty: {
      wantSeeCriticalErrors: '¿Quieres ver los errores críticos de tu web?',
      becomePro:
        'Hazte<span class=\'badge bg-success text-light rounded-xs text-xs fw-medium p-4 mx-4\'>PRO</span>para ver esta información y acceder al resto de funcionalidades.',
    },
  },
  freeState: {
    defaultTimezone: 'La zona horaria por defecto de PHP es válida',
    phpSessions: 'Se han detectado sesiones PHP',
    scheduledEvents: 'Los eventos programados se están ejecutando',
    restAvailability: 'La API REST ha encontrado un resultado inesperado',
    wantSeeErrors: '¿Quieres ver el estado de salud completo de tu web?',
    wantSeeVulnerabilities: '¿Quieres ver todas las vulnerabilidades de tu web?',
  },
  security: 'Seguridad',
  performance: 'Rendimiento',
  core: 'WordPress',
  php: 'PHP',
  database: 'Base de datos',
  site: 'Sitio',
  directories: 'Directorios',
  constants: 'Constantes',
  muPlugins: 'MU Plugins',
  current: 'Versión actual',
  memoryLimit: 'Límite de memoria',
  safeMode: 'Modo seguro',
  shell: 'Shell',
  basename: 'Basename',
  name: 'Nombre',
  locale: 'Idioma',
  version: 'Versión actual',
  newVersion: 'Última versión',
  requiresPhp: 'Versión mínima de PHP',
  mysqlVersion: 'Versión mínima de MySQL',
  status: 'Estado',
  extension: 'Extensión',
  server: 'Versión del servidor',
  media: 'Media',
  engine: 'Servidor de la base de datos',
  clientVersion: 'Versión del cliente',
  uname: 'Arquitectura del servidor',
  hostname: 'Hostname',
  diskFreeSpace: 'Espacio de disco disponible',
  isUnix: 'Unix',
  pseudoCronDisable: '¿Está desactivado el Pseudo-CRON?',
  contentDirWritable: '¿El directorio "wp-content" es editable?',
  isSsl: '¿Está utilizando HTTPS este sitio?',
  isMultisite: '¿Es un multisitio?',
  baseUrl: 'URL de WordPress',
  restUrl: 'REST URL',
  homeUrl: 'URL de inicio',
  pluginsUrl: 'URL del directorio de plugins',
  timezone: 'Zona horaria',
  isPublic: '¿Está el sitio disuadiendo a los motores de búsqueda?',
  abspath: 'ABSPATH',
  debug: 'WP_DEBUG',
  debugLog: 'WP_DEBUG_LOG',
  debugDisplay: 'WP_DEBUG_DISPLAY',
  exist: '¿Existe alguno?',
  writable: '¿Se puede editar el directorio?',
  existHandler: '¿Existe el Handler?',
  yes: 'Sí',
  no: 'No',
  activate: 'Activado',
  disabled: 'Desactivado',
  disabledFunctions: 'Funciones desactivadas',
  extensions: 'Extensiones',
  toastMessage: 'Estamos comprobando el estado del sitio. Esto puede tardar unos minutos.',
  errorModal: {
    title: 'Es necesario actualizar el plugin de Modular',
    contentTitle: 'Para ver este contenido es necesario actualizar el plugin de Modular en tu web.',
    content:
      'La versión del plugin de Modular que tienes instalada en esta web no es compatible con la visualización del contenido de Seguridad y Salud.',
    updatePlugin: 'Actualizar plugin',
  },
  errorDisconnectedModal: {
    title: 'Es necesario conectar tu web',
    contentTitle: 'Para ver este contenido es necesario que tengas conectada tu web.',
    content:
      'No tienes tu web conectada con el plugin de Modular, conéctala para ver el contenido de Seguridad y Salud.',
    connectWeb: 'Conectar web',
  },
};
