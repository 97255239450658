import { Concept } from '@/types/models/Concept';

export type BillingIntervalEnumKeys = 'month' | 'year';

export const BillingIntervalEnumConst = {
  MONTH: 'month',
  YEAR: 'year',
} as const;

export const BillingIntervalEnum: Record<BillingIntervalEnumKeys, Concept> = {
  'month': {
    key: 'month',
    value: null,
    name: 'concept.billing.interval.monthName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.interval'
  },
  'year': {
    key: 'year',
    value: null,
    name: 'concept.billing.interval.yearName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.interval'
  }
} as const;

export type BillingIntervalEnumType = (typeof BillingIntervalEnumConst)[keyof typeof BillingIntervalEnumConst];

export const BillingIntervalEnumArray = Object.values(BillingIntervalEnum);
