export default {
  mixed: {
    default: 'Not valid',
    required: ({ label } : MessageParams) => `${!!label ? label : 'The field'} is required`,
    oneOf: 'Passwords must match',
    notType: 'You must enter a valid data',
  },
  number: {
    min: 'Value must be greater than {min}',
    max: 'The value must be less than {max}',
  },
  string: {
    min: 'You must enter a minimum of {min} characters',
    max: 'You must enter a maximum of {max} characters',
    email: 'You must enter a valid email address',
    password: 'The provided password was incorrect',
    url: 'You must add a valid URL, for example, https://www.mywebsite.com',
  },
};
