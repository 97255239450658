import type { SiteBackupExtendedType } from '@/stores/site/backup';
import type { SiteBackupItemTypeEnumType } from '@/types/enums/SiteBackupItemTypeEnum';
import { SiteBackupItemTypeEnumConst } from '@/types/enums/SiteBackupItemTypeEnum';
import type { BackupItem, BackupItemStatus, MappedItemsType } from '@/types/models/site/service/backup/SiteBackup';
import { useBackupStatus } from '@/composables/backups/useBackupStatus';
import useSiteStore from '@/stores/site/siteStore';
import { SiteBackupStatusEnumConst } from '@/types/enums/SiteBackupStatusEnum';

const { ALL, DATABASE, CORE, PLUGINS, THEMES, UPLOADS } = SiteBackupItemTypeEnumConst;
const { PENDING } = SiteBackupStatusEnumConst;

export function useBackupsMap() {
  const siteStore = useSiteStore();

  const mapBackups = ({ backups }: { backups: SiteBackupExtendedType[] }) => {
    const siteBackupItemTypes: SiteBackupItemTypeEnumType[] = [DATABASE, CORE, PLUGINS, THEMES, UPLOADS];

    const currentVersion = siteStore?.currentSiteConnectorVersion || '0.0.0';
    const isNewBackupStructure = currentVersion.localeCompare('0.49.0', undefined, { numeric: true }) === 1;

    return backups.map((backup) => {
      const itemsByType = backup.items.reduce(
        (acc, item) => {
          const type = item.type;

          if (!acc[type]) {
            acc[type] = {
              items: [],
              type,
              status: '' as BackupItemStatus,
            };
          }
          acc[type].items.push(item);

          return acc;
        },
        {} as Record<string, BackupItem>,
      );

      Object.values(itemsByType).forEach((item) => {
        item.items.sort((a, b) => a.batch - b.batch);
        const lastItem = item.items[item.items.length - 1];
        item.status = lastItem?.status || PENDING;
      });

      if (
        (isNewBackupStructure && backup.items.length === 0)
        || (!!backup.items[0]?.type && backup.items[0]?.type !== ALL)
      ) {
        backup.mappedItems = siteBackupItemTypes.reduce(
          (acc, type) => ({
            ...acc,
            [type]: itemsByType[type] || {
              items: [],
              type,
              status: 'pending',
            },
          }),
          {} as MappedItemsType,
        );
      } else if (!isNewBackupStructure || (backup.items.length === 1 && backup.items[0]?.type === ALL)) {
        backup.mappedItemsLegacy = {
          all: itemsByType.all || {
            items: [],
            type: ALL,
            status: backup.status,
          },
        };
      }

      return backup;
    });
  };

  const mapLastBackups = ({ backups }: { backups: SiteBackupExtendedType[] }) => {
    const { getBackupStatus } = useBackupStatus();

    let backupsAux = backups.sort((a: any, b: any) => +new Date(b.createdAt) - +new Date(a.createdAt));
    backupsAux = backups.slice(0, 2);

    if (!!backupsAux && backupsAux.length > 0) {
      backupsAux.map((backup) => {
        backup.state = getBackupStatus(backup.status);

        return backup;
      });
    }

    return backupsAux;
  };

  return {
    mapBackups,
    mapLastBackups,
  };
}
