<script lang="ts" setup>
import { SelectIcon, SelectTrigger, type SelectTriggerProps, useForwardProps } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

const props = withDefaults(
  defineProps<SelectTriggerProps & { class?: HTMLAttributes['class'], variant?: 'default' | 'underline' | 'flat' }>(),
  {
    class: '',
    variant: 'default',
  },
);

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <SelectTrigger
    class="ui-select-menu-trigger d-flex w-100 align-items-center justify-content-between text-nowrap text-dark"
    :class="[
      {
        'ui-select-trigger-default rounded border px-24 py-16': props.variant === 'default',
        'ui-select-trigger-flat bg-transparent border-0 px-0 py-8': props.variant === 'flat',
        'ui-select-trigger-underline position-relative bg-transparent border-0 shadow-none px-12 py-2': props.variant === 'underline',
      },
      props.class,
    ]"
    v-bind="forwardedProps"
  >
    <slot />
    <SelectIcon as-child>
      <m-icon
        class="ui-select-menu-trigger-icon d-flex align-items-center justify-content-center ms-8"
        :icon="['underline', 'flat'].includes(props.variant) ? 'arrow-caret-down' : 'arrow-chevron-down'"
        :size="['underline', 'flat'].includes(props.variant) ? 'xs' : 'normal'"
      />
    </SelectIcon>
  </SelectTrigger>
</template>
