<script lang="ts" setup>
import type { IconTypes } from '@/components/vendor/basic/icon/VIcon.vue';
import type { Tag } from '@/types/models/organization/tag/Tag';

import type { Site } from '@/types/models/site/Site';
import { usePicture } from '@/composables/usePicture';

import useSiteFunctions from '@/composables/useSiteFunctions';
import { PermissionEnumConst } from '@/types/PermissionEnum';
import { toRefs } from 'vue';
import { can } from '@/helpers/permissionsHelper';

defineOptions({
  name: 'DashboardSitesItemCard',
});

const props = defineProps<{
  site: Site
  lastTimeVisited: string
  isConnectionEstablished: boolean
  isConnectionLost: boolean
  isConnectionOnError: boolean
  isConnectionOnErrorOrLost: boolean
  state: {
    reconnectionTestIsLoading: boolean
    reconnectionResult: string
  }
  changeStatusData: {
    icon: IconTypes
    color: string
    background: string
  }
  loadingLoginButton: boolean
  lastBackupHasError: boolean
}>();

defineEmits<{
  (e: 'launchTracking'): void
  (e: 'testConnection'): void
  (e: 'loginWordpress', site: string, team: string): void
  (e: 'syncTag', tag: Tag): void
}>();

const {
  SITES_MANAGER_LOGIN,
  SITE_BACKUPS_RETRY,
  SITES_OAUTH_CLIENT_CONFIRM,
  SITES_SHOW,
  SITE_ITEMS_INDEX,
  UPTIME_SERVICES_SHOW,
  HEALTH_SERVICES_REPORT,
  VULNERABILITY_SERVICES_SHOW,
} = PermissionEnumConst;

const { site: localSite } = toRefs(props);

const tagsModel = defineModel<Tag[]>('tags', {
  required: true,
});

const healthCriticalErrors = computed(() => localSite.value?.healthCriticalErrors ?? 0);
const updatableItemsCount = computed(() => localSite.value?.updatableItemsCount ?? 0);

const { parseUri } = useSiteFunctions();

const imageUrl = new URL('@assets/img/ModularDS-Placeholder.jpg', import.meta.url).href;

const screenshot = usePicture(localSite.value.screenshot, 'siteCard', {
  src: imageUrl,
  srcset: `${imageUrl} 2x, ${imageUrl} 1x`,
});
</script>

<template>
  <article>
    <div :class="{ 'connection-lost': isConnectionOnErrorOrLost }" class="card-image position-relative w-100">
      <div
        v-if="lastBackupHasError"
        v-can="SITE_BACKUPS_RETRY"
        class="site-backup-error position-absolute bg-danger d-flex align-items-center fw-medium z-index-2 end-0 start-0 top-0 px-8
          text-xs text-white"
      >
        <span> {{ $t('dashboard.backupError') }}</span>

        <router-link
          :to="{
            name: 'sites.site.backup.index',
            params: {
              site: localSite.slug,
            },
          }"
          class="btn btn-link text-light text-hover text-hover-opacity fw-medium text-decoration-underline ms-auto text-xs"
          size="sm"
          variant="link"
          @click="$emit('launchTracking')"
        >
          {{ $t('general.button.retry') }}
        </router-link>
      </div>

      <div
        v-if="!isConnectionOnErrorOrLost"
        :class="{ 'site-options-error': lastBackupHasError, 'top-0': !lastBackupHasError }"
        class="site-options d-flex justify-content-between position-absolute w-100 start-0"
      >
        <span
          v-can="UPTIME_SERVICES_SHOW"
          :class="changeStatusData?.background"
          class="status-connection d-flex flex-column align-items-center justify-content-center"
        >
          <v-icon :class="changeStatusData?.color" :icon="changeStatusData?.icon" space="text-xs" />
        </span>

        <div class="d-flex">
          <m-button
            v-can="SITES_MANAGER_LOGIN"
            v-tooltip="$t('general.button.toWordPress')"
            rel="noreferrer"
            :aria-label="$t('general.button.toWordPress')"
            :data-cy="`site-card-go-to-wp-${localSite.slug}`"
            :disabled="!isConnectionEstablished || loadingLoginButton"
            variant="transparent"
            size="icon"
            class="btn-wordpress position-relative z-index-2 text-md border-0 shadow-none"
            @click="$emit('loginWordpress', localSite.team?.slug ?? '', localSite?.slug ?? '')"
          >
            <v-icon icon="wordpress" />
            <span class="visually-hidden">{{ $t('general.button.toWordPress') }}</span>
          </m-button>

          <slot />
        </div>
      </div>

      <v-picture :lazy="true" class="site-image d-block overflow-hidden" height="148" width="260">
        <img
          :alt="$t('site.shared.siteImage', { site: localSite.name })"
          :src="screenshot.src"
          :srcset="screenshot.srcset"
          class="card-img-top"
        >
      </v-picture>

      <div
        v-if="isConnectionLost || isConnectionOnError"
        class="site-connection-overlay d-flex justify-content-center position-absolute h-100 w-100 start-0 top-0"
      >
        <transition mode="out-in" name="fade">
          <div
            v-if="!state.reconnectionTestIsLoading"
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <v-icon class="mb-8" icon="connection" />

            <MButton
              v-if="isConnectionLost"
              v-can="SITES_OAUTH_CLIENT_CONFIRM"
              data-cy="btn-site-list-reconnect"
              class="btn-reconnect"
              size="xs"
              variant="primaryDark"
              @click="$emit('testConnection')"
            >
              {{ $t('general.button.reconnect') }}
            </MButton>

            <router-link
              v-else
              v-can="SITES_OAUTH_CLIENT_CONFIRM"
              class="btn btn-primary btn-primary-dark btn-xs btn-reconnect text-xs"
              :to="{ name: 'sites.site.connect', params: { site: localSite.slug } }"
              data-cy="btn-site-list-connect"
            >
              <span class="site-name">{{ $t('general.button.connect') }}</span>
            </router-link>
          </div>

          <div v-else-if="state.reconnectionResult === 'loading'" class="d-flex align-items-center text-xs text-white">
            <span class="md-small-load-bar">
              <span class="line" />
              <span class="inside-line increase" />
            </span>

            <span class="d-block mt-36">{{ $t('dashboard.connectingLoad') }}</span>
          </div>

          <div v-else-if="state.reconnectionResult === 'valid'" class="d-flex align-items-center text-xs text-white">
            <span>{{ $t('dashboard.connectionSuccess') }}</span>
          </div>

          <div
            v-else-if="state.reconnectionResult === 'invalid'"
            class="d-flex flex-column justify-content-center align-items-center text-center text-xs text-white"
          >
            <span>{{ $t('dashboard.connectionFailed') }}</span>
            <span>{{ $t('dashboard.connectionFailedDesc') }}</span>
          </div>
        </transition>
      </div>
    </div>

    <div :class="{ 'position-relative z-index-1': isConnectionOnErrorOrLost }" class="d-flex flex-column h-100 p-16">
      <div class="d-flex align-items-center mb-4">
        <VTagsCombobox
          v-model="tagsModel"
          location="Tags shortcut"
          sync-tag
          type="dots"
          @selected="$emit('syncTag', $event)"
        />

        <m-permission-provider :permission="SITES_SHOW">
          <router-link
            v-if="!!localSite?.slug"
            :data-cy="`link-grid-site-${localSite.slug}`"
            :to="{ name: 'sites.site.show', params: { site: localSite.slug } }"
            class="card-title text-decoration-none d-flex align-items-center text-dark text-hover-primary stretched-link fw-semi text-xs"
            @click="$emit('launchTracking')"
          >
            <span class="site-name" data-cy="grid-site-name">{{ localSite.name }}</span>
          </router-link>

          <template #fallback>
            <span class="site-name d-flex flex-column text-dark fw-semi text-xs" data-cy="list-site-name">
              {{ site.name }}
            </span>
          </template>
        </m-permission-provider>
      </div>

      <a
        v-if="!!localSite?.uri"
        :href="localSite.uri"
        class="site-uri position-relative text-decoration-none fw-medium text-2xs z-index-2 text-hover-primary mb-2 text-gray-600"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ parseUri(localSite.host, localSite.path) }}
      </a>

      <div
        v-if="!!lastTimeVisited || isConnectionEstablished"
        class="position-relative z-index-2 d-flex justify-content-between align-items-center text-2xs"
      >
        <p v-if="!!lastTimeVisited" class="fw-medium mb-0 me-4 text-gray-600">
          {{ lastTimeVisited }}
        </p>

        <div v-if="isConnectionEstablished" class="d-flex align-items-center gap-2 ms-auto">
          <template v-if="updatableItemsCount > 0 || healthCriticalErrors > 0">
            <span
              v-if="updatableItemsCount > 0"
              v-can="SITE_ITEMS_INDEX"
              v-tooltip.bottom="{
                content: $t('site.shared.numUpdates', { num: updatableItemsCount }, updatableItemsCount),
                html: true,
              }"
              class="badge md-badge-updates d-flex align-items-center rounded-xs lh-md fw-medium bg-primary-light text-primary-dark text-2xs
              p-4"
            >
              {{ site.updatableItemsCount }}
              <m-icon icon="exchange-transfer" space="ms-2" />
            </span>

            <span
              v-if="healthCriticalErrors > 0 && (can(HEALTH_SERVICES_REPORT) || can(VULNERABILITY_SERVICES_SHOW))"
              v-tooltip.bottom="{
                content: $t('health.numCriticalErrors', { num: healthCriticalErrors }, healthCriticalErrors),
                html: true,
              }"
              class="badge md-badge-errors d-flex align-items-center rounded-xs lh-md fw-medium bg-danger-light text-danger text-2xs p-4"
            >
              {{ healthCriticalErrors }}
              <v-icon icon="sad-emoji" space="ms-2" />
            </span>
          </template>

          <div v-else>
            <v-icon class="text-success" icon="check" />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.site-card {
  .site-name,
  .site-uri {
    max-width: 100%;
  }

  .site-connection-overlay .icon {
    font-size: 2.75rem;
    color: var(--md-primary-light);
  }

  .status-connection {
    position: relative;
    left: 0;
    top: 0;
    border-radius: 0.5rem 0;
    z-index: 2;
    max-height: 1.5rem;
    padding-inline: 0.625rem;

    .icon-arrow-up {
      transform: rotateY(0deg) rotate(45deg);
    }
  }

  .site-options-error {
    top: 1.5rem;

    .status-connection {
      border-radius: 0 0 0.5rem;
    }
  }

  .site-backup-error {
    height: 1.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  :deep(.btn-options),
  .btn-wordpress {
    transition: color 0.2s ease-out;
    color: var(--md-light);

    &:hover,
    &:focus {
      color: var(--md-gray-300);
    }

    &:active {
      color: var(--md-gray-900);
    }
  }

  .site-connection-overlay,
  .site-connection-overlay::after,
  .site-image,
  .card-image::after {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .card-image {
    max-height: 9.25rem;
    min-height: 9.25rem;

    img,
    :deep(picture) {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: inherit;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      background: linear-gradient(180deg, rgb(41 13 86 / 32%) 0%, rgb(41 13 86 / 0%) 100%);
    }

    &.connection-lost {
      z-index: 1;

      .site-options {
        z-index: 2;
      }

      .site-connection-overlay {
        z-index: 3;
      }

      &::after {
        content: none;
      }
    }
  }

  &,
  .card-image :deep(.card-img-top),
  .card-title {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    box-shadow: 0.25rem 0.25rem 0.5rem 0 rgb(14 14 14 / 10%);

    .card-title {
      color: var(--md-primary);
    }
  }
}

/* stylelint-disable */
[data-bs-theme='dark'] {
  .site-card {
    :deep(.btn-options),
    .btn-wordpress {
      transition: color 0.2s ease-out;
      color: var(--md-dark);

      &:hover,
      &:focus {
        color: var(--md-gray-600);
      }
    }
  }
}

/* stylelint-enable */
</style>
