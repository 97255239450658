import { getLocale } from '@/helpers/langHelper';

import ApiClient from './ApiClient';
import type { AxiosError } from 'axios';

const locale = getLocale();

const client = new ApiClient({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Accept-Language': locale,
  },
  debug: false,
  caseTransform: 'snake',
  customInterceptor: (error: AxiosError) => {
    if (error.response && [401, 419].includes(error.response.status)) {
      console.warn('Unauthorized request, redirecting to login...');

      window.location.href = '/login';
    }

    return Promise.reject(error);
  },
});

export default client as ApiClient;
