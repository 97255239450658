<script lang="ts" setup>
import type { Tag } from '@/types/models/organization/tag/Tag';

import type { Site } from '@/types/models/site/Site';
import api from '@/api';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { PermissionEnumConst } from '@/types/PermissionEnum';

import { ref } from 'vue';
import { can } from '@/helpers/permissionsHelper';

defineOptions({
  name: 'DashboardSitesItemMenuDropdown',
});

const props = defineProps<{
  site: Site
  mode: string
  disabled?: boolean
}>();

const emits = defineEmits(['loginWordpress', 'launchTracking', 'createTask', 'editSite', 'syncTag']);

const {
  SITES_SHOW,
  ANALYTICS_SERVICES_SHOW,
  SEARCH_CONSOLE_SERVICES_SHOW,
  UPTIME_SERVICES_SHOW,
  SITE_BACKUPS_INDEX,
  SITE_REPORTS_INDEX,
  REPORT_SERVICES_INDEX,
  ORGANIZATION_SITE_REPORT_SCHEDULE_ALLOW,
} = PermissionEnumConst;

const tagsModel = defineModel<Tag[]>('tags', {
  required: true,
})

const router = useRouter();

function openSiteRoute(route = 'sites.site.show', newTab = false) {
  if (newTab) {
    window.open(
      router.resolve({
        name: route,
        params: {
          site: props.site.slug,
        },
      }).href,
      '_blank',
    );
  } else {
    router.push({
      name: route,
      params: {
        site: props.site.slug,
      },
    });
  }

  emits('launchTracking');
}

function openSite() {
  window.open(props.site.uri, '_blank');
}

const openPopover = ref(true);
const searchTerm = ref('');

async function trackTagSelection(hasAddedTag = false) {
  await api.mixpanel.track('Change tag', {
    Location: 'Contextual menu',
    Type: hasAddedTag ? 'Add' : 'Remove',
  });
}
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger class="btn btn-transparent" :class="[$attrs.class]" :disabled="disabled" spacing="p-8">
      <slot />
    </DropdownMenuTrigger>

    <DropdownMenuContent>
      <DropdownMenuItem
        v-can="SITES_SHOW"
        @click.exact="openSiteRoute('sites.site.show', true)"
        @click.ctrl="openSiteRoute('sites.site.show', true)"
      >
        {{ $t('menu.overview') }}
        <DropdownMenuShortcut>
          <v-icon class="position-relative transform-rotate--45 me-0" icon="arrow-right" />
        </DropdownMenuShortcut>
      </DropdownMenuItem>

      <DropdownMenuItem @click="$emit('loginWordpress')">
        {{ $t('general.button.toWordPress') }}
        <DropdownMenuShortcut>
          <m-icon icon="wordpress-go" class="me-0" />
        </DropdownMenuShortcut>
      </DropdownMenuItem>

      <DropdownMenuSeparator />

      <DropdownMenuItem @click="openSite">
        {{ $t('general.button.viewWebsite') }}
      </DropdownMenuItem>

      <DropdownMenuSeparator />

      <DropdownMenuSub>
        <DropdownMenuSubTrigger v-if="can(ANALYTICS_SERVICES_SHOW) || can(SEARCH_CONSOLE_SERVICES_SHOW)">
          {{ $t('menu.metrics') }}
        </DropdownMenuSubTrigger>

        <DropdownMenuSubContent>
          <DropdownMenuItem
            v-can="ANALYTICS_SERVICES_SHOW"
            @click.exact="openSiteRoute('sites.site.analytics.show')"
            @click.ctrl="openSiteRoute('sites.site.analytics.show', true)"
          >
            {{ $t('menu.analytics') }}
          </DropdownMenuItem>
          <DropdownMenuItem
            v-can="SEARCH_CONSOLE_SERVICES_SHOW"
            @click.exact="openSiteRoute('sites.site.service.search-console.show')"
            @click.ctrl="openSiteRoute('sites.site.service.search-console.show', true)"
          >
            {{ $t('menu.searchConsole') }}
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuSub>

      <DropdownMenuItem
        v-can="UPTIME_SERVICES_SHOW"
        @click.exact="openSiteRoute('sites.site.uptime.check.stats.show')"
        @click.ctrl="openSiteRoute('sites.site.uptime.check.stats.show', true)"
      >
        {{ $t('menu.uptimeMonitor') }}
      </DropdownMenuItem>

      <DropdownMenuItem
        v-can="SITE_BACKUPS_INDEX"
        @click.exact="openSiteRoute('sites.site.backup.index')"
        @click.ctrl="openSiteRoute('sites.site.backup.index', true)"
      >
        {{ $t('menu.backups') }}
      </DropdownMenuItem>

      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          {{ $t('menu.reports') }}
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent>
          <DropdownMenuItem
            v-if="can(SITE_REPORTS_INDEX) || (can(REPORT_SERVICES_INDEX) && can(ORGANIZATION_SITE_REPORT_SCHEDULE_ALLOW))"
            @click.exact="openSiteRoute('sites.site.report.index')"
            @click.ctrl="openSiteRoute('sites.site.report.index', true)"
          >
            {{ $t('general.button.viewReports') }}
          </DropdownMenuItem>
          <DropdownMenuItem
            @click.exact="openSiteRoute('sites.site.report.type')"
            @click.ctrl="openSiteRoute('sites.site.report.type', true)"
          >
            {{ $t('general.button.createReport') }}
          </DropdownMenuItem>
          <DropdownMenuItem @click="$emit('createTask', site)">
            {{ $t('general.button.createTask') }}
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuSub>

      <DropdownMenuItem
        @click.exact="openSiteRoute('sites.site.health.show')"
        @click.ctrl="openSiteRoute('sites.site.health.show', true)"
      >
        {{ $t('menu.healthSafety') }}
      </DropdownMenuItem>

      <DropdownMenuSeparator />

      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          {{ $t('tags.tags') }}
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent>
          <VTagsComboboxContent
            v-model="tagsModel"
            v-model:open="openPopover"
            v-model:search-term="searchTerm"
            :sync-tag="true"
            location="Contextual menu"
            @selected="$emit('syncTag', $event)"
            @changed-tags="trackTagSelection($event)"
          />
        </DropdownMenuSubContent>
      </DropdownMenuSub>

      <DropdownMenuItem
        :data-cy="mode === 'card' ? `site-card-edit-${site.slug}` : `site-list-edit-${site.slug}`"
        @click="$emit('editSite')"
      >
        {{ $t('general.button.edit') }}
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
