<script lang="ts" setup>
import type {
  ExtendedSiteStatusEnumKeys,
} from '@/types/SiteStatusOwnEnum';
import { MProgress } from '@/components/ui/progress';

import {
  SiteStatusEnum,
  SiteStatusEnumConst,
} from '@/types/enums/SiteStatusEnum';
import {
  SiteStatusOwnEnumConst,
  SiteStatusProcessEnumArray,
} from '@/types/SiteStatusOwnEnum';
import { computed } from 'vue';

import { useI18n } from 'vue-i18n';

defineOptions({
  name: 'TheSiteConnectFailure',
});

const props = withDefaults(
  defineProps<{
    title?: string
    formState: ExtendedSiteStatusEnumKeys
    mode: 'validating' | 'auto' | 'manual'
  }>(),
  {
    title: '',
  },
);

const { t } = useI18n();

const {
  PENDING,
  REACHED_LOGIN_PAGE,
  LOGIN_IN,
  PLUGIN_LOGGED_IN,
  REACHED_SECONDARY_ACTION_REQUIRED,
  REACHED_PLUGIN_INSTALLATION_PAGE,
  INSTALLING_PLUGIN,
  PLUGIN_INSTALLED,
  SEARCHING_PLUGIN_UPGRADE,
  PLUGIN_UPGRADING,
  PLUGIN_UPGRADED,
  REACHED_ACTIVATION_PAGE,
  PLUGIN_ACTIVATING,
  PLUGIN_ACTIVATED,
  REACHED_PLUGIN_CREDENTIALS_PAGE,
  ESTABLISHED_CREDENTIALS,
} = SiteStatusEnumConst;

const { CREATING_SITE } = SiteStatusOwnEnumConst;

const loadingPercentage = computed(() => {
  if (props.mode !== 'auto')
    return 0;

  const totalStates = SiteStatusProcessEnumArray.length;
  const currentIndex = SiteStatusProcessEnumArray.indexOf(props.formState);

  if (currentIndex === 0)
    return 2;
  if (currentIndex === -1)
    return 0;

  return (currentIndex / (totalStates - 1)) * 100;
});

const lastValidMessage = ref('');

watchEffect(() => {
  let tmp = '';

  switch (props.formState) {
    case PENDING:
    case REACHED_LOGIN_PAGE:
    case LOGIN_IN:
    case PLUGIN_LOGGED_IN:
    case REACHED_SECONDARY_ACTION_REQUIRED:
    case REACHED_PLUGIN_INSTALLATION_PAGE:
    case INSTALLING_PLUGIN:
    case PLUGIN_INSTALLED:
    case SEARCHING_PLUGIN_UPGRADE:
    case PLUGIN_UPGRADING:
    case PLUGIN_UPGRADED:
    case REACHED_ACTIVATION_PAGE:
    case PLUGIN_ACTIVATING:
    case PLUGIN_ACTIVATED:
    case REACHED_PLUGIN_CREDENTIALS_PAGE:
    case ESTABLISHED_CREDENTIALS:
      tmp = t(SiteStatusEnum[props.formState].name ?? '');
      break;
    default:
      break;
  }

  if (tmp) {
    lastValidMessage.value = tmp;
  }
});
</script>

<template>
  <div class="connection-load w-100 pt-16">
    <Transition mode="out-in" name="fade">
      <h1 v-if="formState === CREATING_SITE" class="h5 fw-semi mb-24">
        {{ title }}
      </h1>
    </Transition>

    <v-load-bar v-if="mode === 'manual' || mode === 'validating'" class="mb-16" />

    <MProgress
      v-else
      :model-value="loadingPercentage"
      class="mb-16"
      lightining
      variant="primary"
    />

    <Transition mode="out-in" name="fade">
      <p v-if="mode === 'validating'" class="fw-medium text-sm">
        {{ $t('site.create.validatingUrl') }}
      </p>
      <p v-else-if="mode === 'manual'" class="fw-medium text-sm">
        {{ $t('concept.site.status.loginInName') }}
      </p>
      <p v-else-if="lastValidMessage" :key="lastValidMessage" class="fw-medium text-sm">
        {{ lastValidMessage }}
      </p>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.connection-load {
  height: 150px;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
