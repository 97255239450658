<script lang="ts" setup>
import VModal from '@/components/vendor/modal/VModal.vue';

import useRedirectToPayment from '@/composables/useRedirectToPayment';
import DateHelper from '@/helpers/dateHelper';

import { useClipboard } from '@vueuse/core';
import { DateTime } from 'luxon';

import { onMounted, onUnmounted, ref } from 'vue';

defineOptions({
  name: 'TheDiscountPopup',
});

const props = defineProps<{
  enabled: boolean
  specialDates: DatesPropType
}>();

interface DatesPropType {
  start: DateTime
  end: DateTime
}

const showBanner = defineModel('showBanner', {
  default: false,
  type: Boolean,
});

const currentTimeUnmodified = DateTime.now();
let currentTime = DateTime.now();

const bfModal = ref<InstanceType<typeof VModal> | null>(null);

const { redirectToPayment } = useRedirectToPayment();

const timeCounter = ref<string | number>('');

let interval: number | undefined;

const bronce50 = ref('BRONCE50');

const {
  copy: copy50,
  copied: copied50,
  isSupported: isSupported50,
} = useClipboard({ source: bronce50 });

const bronce25 = ref('BRONCE25');

const {
  copy: copy25,
  copied: copied25,
  isSupported: isSupported25,
} = useClipboard({ source: bronce25 });

function updateTime() {
  currentTime = DateTime.now();
  const endTime = props.specialDates.end;

  timeCounter.value = DateHelper.durationForHumans(currentTime.toString(), endTime, false, true, true);
}

const { locale } = useI18n();

function checkAndUpdateLastTimeVisited() {
  const lastTimeVisited = localStorage.getItem('lastTimeVisited');

  if (locale.value !== 'es') {
    return;
  }

  if (!lastTimeVisited) {
    // Si no existe lastTimeVisited, lo setteamos y mostramos el banner
    localStorage.setItem('lastTimeVisited', String(currentTimeUnmodified.toISO()));
    showBanner.value = true;
  } else {
    const lastTime = DateTime.fromISO(lastTimeVisited);
    const hoursDiff = currentTime.diff(lastTime, 'hours').hours;

    if (hoursDiff > 6) {
      // Si han pasado más de 6 horas, actualizamos y mostramos el banner
      localStorage.setItem('lastTimeVisited', String(currentTimeUnmodified.toISO()));
      showBanner.value = true;
    }
  }
}

function handleOfferClick() {
  redirectToPayment({ trackName: 'Semana bronceadora Countdown' });

  bfModal.value?.hide();
}

onMounted(() => {
  checkAndUpdateLastTimeVisited();

  updateTime();
  interval = window.setInterval(updateTime, 1000);
});

onUnmounted(() => {
  if (interval)
    clearInterval(interval);
});
</script>

<template>
  <VModal
    v-if="showBanner"
    id="bf-discount-modal"
    ref="bfModal"
    content-class="rounded overflow-hidden"
    size="lg"
    @closed="showBanner = false"
  >
    <modal-header class="bg-primary pt-32  pb-24">
      <v-picture id="modal-logo">
        <img
          alt="Logo ModularDS"
          src="@assets/img/ModularDS-logo-light.png"
          srcset="@assets/img/ModularDS-logo-light.png 2x, @assets/img/ModularDS-logo-light.png 1x"
        >
      </v-picture>
    </modal-header>

    <modal-body class="text-light bg-primary">
      <div class="position-relative z-index-2 px-16">
        <div class="d-flex">
          <h1 class="h3 d-flex flex-column mb-24">
            <span>Semana</span>
            <span class="text-warning">bronceadora</span>
          </h1>
          <p class="text-xxl fw-bold mt-32">
            del 24/06 al 28/06
          </p>
        </div>
        <p v-dompurify-html="$t('discount.planazoAnnouncement')" class="h6 fw-normal w-md-70 mb-24" />
        <div class="d-flex flex-column flex-sm-nowrap mb-16 flex-wrap">
          <div class="h5 mb-12">
            Código 50%:
            <span class="text-warning">BRONCE50</span>

            <VButton
              id="copy-button"
              v-tooltip.top="{
                content: $t('discount.copiedConfirmation'),
                arrowPadding: 24,
                triggers: [],
                shown: copied50,
                disabled: !isSupported50 || !copied50,
              }"
              class="btn-transparent text-light fw-bold ms-8 p-4 text-xl"
              @click="copy50(bronce50)"
            >
              <span class="mi mi-copy" />
            </VButton>
          </div>
          <div class="h5 mb-0">
            Código 25%:
            <span class="text-warning">BRONCE25</span>

            <VButton
              id="copy-button"
              v-tooltip.top="{
                content: $t('discount.copiedConfirmation'),
                arrowPadding: 24,
                triggers: [],
                shown: copied25,
                disabled: !isSupported25 || !copied25,
              }"
              class="btn-transparent text-light fw-bold ms-8 p-4 text-xl"
              @click="copy25(bronce25)"
            >
              <span class="mi mi-copy" />
            </VButton>
          </div>

          <span id="copy-button-tooltip" class="text-light" role="tooltip">
            {{ $t('discount.copiedConfirmation') }}
            <span class="arrow" data-popper-arrow />
          </span>
        </div>
      </div>

      <div class="row position-relative z-index-2">
        <div class="col-12 col-sm-6 col-md-7 d-flex align-items-end mb-md-0 mb-48">
          <div class="image h-auto overflow-hidden">
            <v-picture>
              <img
                id="modular-dashboard"
                alt="ModularDS Dashboard"
                src="@assets/img/semana-bronceadora.png"
                srcset="@assets/img/semana-bronceadora.png 2x, @assets/img/semana-bronceadora.png 1x"
              >
            </v-picture>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-5 text-primary mb-24 mt-auto">
          <p class="fw-bold text-md lh-lg mb-16">
            Descuentos en planes PRO
          </p>
          <p class="display-5 fw-bold lh-md mb-8">
            50%
          </p>
          <p class="lh-lg mb-8">
            En los planes
            <span class="fw-bold">mensuales</span>
            durante 3 meses.
          </p>
          <p class="display-5 fw-bold lh-md mb-8">
            25%
          </p>
          <p class="lh-lg mb-8">
            En planes
            <span class="fw-bold">anuales</span>
            durante
            <span class="fw-bold">1 año.</span>
          </p>

          <VButton class="btn-pink btn-sm mt-8" @click="handleOfferClick">
            <span>¡Hazte PRO!</span>
          </VButton>
        </div>
      </div>

      <svg
        class="modal-decoration modal-decoration-blob px-0"
        fill="none"
        height="655"
        viewBox="0 0 889 655"
        width="889"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M532.442 0.611697C677.521 3.29888 806.022 75.9395 898.902 180.092C994.713 287.532 1054.62 421.306 1030.7 559.349C1004.82 708.766 924.491 867.161 771.321 916.508C628.092 962.652 503.05 836.301 367.539 773.001C236.542 711.81 62.3536 692.947 15.3247 563.369C-34.8079 425.24 45.6023 276.839 147.296 166.171C244.142 60.7773 383.788 -2.1417 532.442 0.611697Z"
          fill="#F7F7FC"
          fill-rule="evenodd"
        />
      </svg>
    </modal-body>

    <svg
      class="modal-decoration modal-decoration-square"
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#FFBD63"
        height="16"
        rx="4"
        transform="rotate(30 7 -1)"
        width="16"
        x="7"
        y="-1"
      />
    </svg>

    <svg
      class="modal-decoration modal-decoration-ellipse"
      fill="none"
      height="39"
      viewBox="0 0 70 39"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M8.37329 24.5939C28.0568 32.6359 50.5327 23.1986 58.5748 3.51516C59.7236 0.703232 62.9345 -0.644947 65.7464 0.503912C68.5583 1.65277 69.9065 4.86362 68.7576 7.67555C58.4179 32.9829 29.5202 45.1165 4.2129 34.7768C1.40097 33.6279 0.0527952 30.4171 1.20165 27.6051C2.35051 24.7932 5.56136 23.445 8.37329 24.5939Z"
        fill="#4CCFFF"
        fill-rule="evenodd"
      />
    </svg>

    <svg
      class="modal-decoration modal-decoration-triangle"
      fill="none"
      height="38"
      viewBox="0 0 36 38"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.79858 0.505132C2.06866 0.0123755 0.476691 1.73758 0.933031 3.61049L8.73645 35.6374C9.19279 37.5103 11.3552 38.1262 12.6288 36.7461L34.4069 13.1453C35.6805 11.7652 35.11 9.42404 33.3801 8.93128L3.79858 0.505132Z"
        fill="#FF84E5"
      />
    </svg>

    <svg
      class="modal-decoration modal-decoration-circle"
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" fill="#00E5E5" r="6" />
    </svg>
  </VModal>
</template>

<style lang="scss">
#bf-discount-modal {
  --md-modal-zindex: 1051;

  .modal-dialog {
    position: relative;
  }

  #modal-logo {
    max-width: 10rem;
  }

  .btn-close-modal {
    top: 1rem;
    right: 1rem;

    &:hover .icon {
      color: var(--uq-primary) !important;
    }
  }

  .modal-decoration {
    position: absolute;
    z-index: 1;

    &.modal-decoration-square {
      top: 2rem;
      left: 40%;

      @media (width <= 575px) {
        top: 9rem;
        left: 0.5rem;
      }
    }

    &.modal-decoration-ellipse {
      top: 2rem;
      right: 20%;

      @media (width <= 575px) {
        top: 5rem;
        right: -1rem;
      }
    }

    &.modal-decoration-triangle {
      top: 30%;
      right: 22%;

      @media (width <= 575px) {
        top: 50%;
        right: 5%;
      }
    }

    &.modal-decoration-circle {
      top: 40%;
      right: 45%;

      @media (width <= 575px) {
        top: 70%;
      }
    }

    &.modal-decoration-blob {
      bottom: 0;
      right: 0;
      width: 65%;
      height: 65%;
      z-index: 1;
    }
  }

  #copy-button-tooltip {
    display: none;
    background: #4e4b66;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    z-index: 9999;

    .arrow,
    .arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
    }

    .arrow {
      visibility: hidden;
    }

    .arrow::before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }

    &[data-show] {
      display: block;
    }
  }

  #copy-button-tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
    left: 50%;
  }
}
</style>
