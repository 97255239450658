<script lang="ts" setup>
import type { IModalOptions, IModalState, TextLayout } from '@/plugins/ModalPlugin';
import type { Ref } from 'vue';
import { computed, inject, onMounted, reactive, ref, shallowRef, watch } from 'vue';
import AlertDivLayout from '@/components/vendor/basic/AlertDivLayout.vue';

import { useI18n } from 'vue-i18n';

// Error de TS, no real

enum ModalType {
  DELETE = 'delete',
  GO_PRO = 'goPro',
  CONFIRM = 'confirm',
}

const { t } = useI18n();
const modalState: IModalState = inject('modalState')!;

const state = reactive({
  show: false,
  disableLeftButton: false,
  disableRightButton: false,
  closingDueToButtonAction: false,
});

const modalInjectedOptions: Ref<IModalOptions> = ref({});

const modalSizeClasses = computed(() => {
  const size = modalInjectedOptions.value.modalSize;
  return size ? `modal-${size}` : '';
});

function buildModalOptions() {
  const modalDefaultValues = {
    title: '',
    text: '',
    subtitle: '',
    alertTitle: '',
    textLayout: 'alert' as TextLayout,
    textLayoutClasses: '',
    showCloseX: true,
    showAlertTitle: true,
    backdrop: false,
    backdropStatic: false,
    showBackdrop: true,
    textClasses: '',
    modalClasses: '',
    modalSize: '',
    subtitleClasses: '',
    rightButtonText: t('general.button.confirm'),
    rightButtonClasses: 'btn-primary',
    rightButtonLoadingText: t('general.button.processing'),
    rightButtonDisabled: false,
    rightButtonCallback: async () => {
      state.show = false;
    },
    leftButtonText: t('general.button.close'),
    leftButtonClasses: 'btn-secondary',
    leftButtonLoadingText: t('general.button.processing'),
    leftButtonCallback: async () => {
      state.show = false;
    },
    leftButtonDisabled: false,
    closeModalCallback: () => ({}),
  };

  const modalOptions = (modalProps: Partial<IModalOptions> = {}) => ({
    ...modalDefaultValues,
    ...modalProps,
    ...modalState.options.value,
  });

  switch (modalState.type) {
    case ModalType.DELETE:
      modalInjectedOptions.value = modalOptions({
        title: t('general.button.delete'),
        text: t('general.button.delete'),
        modalSize: 'lg',
        alertTitle: t('general.shared.cannotUndo'),
        textClasses: 'mb-0',
        subtitleClasses: 'fw-semi text-dark mb-0 mt-24',
        leftButtonText: t('general.button.delete'),
        leftButtonLoadingText: t('general.button.deleting'),
        textLayout: shallowRef(AlertDivLayout),
        showCloseX: false,
        leftButtonClasses: 'btn-outline-danger',
        rightButtonText: t('general.button.cancel'),
        rightButtonClasses: 'btn-primary',
        rightButtonLoadingText: t('general.button.cancel'),
        closeOnActionCallback: true,
      });
      break;
    case ModalType.GO_PRO:
      modalInjectedOptions.value = modalOptions({
        title: t('general.organizationLimit.title'),
        text: t('general.organizationLimit.alertText', { nextPlan: 'PRO' }),
        modalSize: 'lg',
        alertTitle: t('general.shared.cannotUndo'),
        textClasses: 'mb-0',
        subtitleClasses: 'fw-semi text-dark mb-0 mt-24',
        leftButtonText: t('general.button.delete'),
        leftButtonLoadingText: t('general.organizationLimit.acceptButton', { nextPlan: 'PRO' }),
        showBackdrop: true,
        textLayout: shallowRef(AlertDivLayout),
        showCloseX: false,
        leftButtonClasses: 'btn-outline-danger',
        rightButtonText: t('general.button.cancel'),
        rightButtonClasses: 'btn-primary',
        rightButtonLoadingText: t('general.button.cancel'),
        closeOnActionCallback: true,
      });
      break;
    case ModalType.CONFIRM:
      modalInjectedOptions.value = modalOptions({
        textLayout: shallowRef(AlertDivLayout),
        title: t('general.button.confirm'),
        text: t('general.button.confirm'),
        modalClasses: '',
        modalSize: 'lg',
        textClasses: 'text-dark',
        subtitleClasses: 'text-dark',
        leftButtonText: t('general.button.cancel'),
        alertVariant: 'tiger',
        leftButtonClasses: 'btn-outline-secondary',
        leftButtonLoadingText: t('general.button.cancel'),
        rightButtonText: t('general.button.confirm'),
        rightButtonClasses: 'btn-primary',
        rightButtonLoadingText: t('general.button.processing'),
        closeOnActionCallback: true,
      });
      break;
    default:
      modalInjectedOptions.value = modalOptions();
      break;
  }
}

function handleShow() {
  state.show = true
}

function getButtonCallback(type: 'left' | 'right' | 'modal') {
  if (type === 'left' && !!modalInjectedOptions.value.leftButtonCallback) {
    return modalInjectedOptions.value.leftButtonCallback;
  }

  if (type === 'right' && !!modalInjectedOptions.value.rightButtonCallback) {
    return modalInjectedOptions.value.rightButtonCallback;
  }

  if (type === 'modal' && !!modalInjectedOptions.value.closeModalCallback) {
    return modalInjectedOptions.value.closeModalCallback;
  }

  return undefined;
}

let handleClose: () => void;

async function handleCallback(type: 'left' | 'right' | 'modal' = 'left') {
  try {
    const callback = getButtonCallback(type);

    if (callback) {
      state.closingDueToButtonAction = modalInjectedOptions.value.closeOnActionCallback === false && type !== 'modal';

      if (type === 'left') {
        state.disableLeftButton = true;
      } else if (type === 'right') {
        state.disableRightButton = true;
      }

      await callback();
    }

    handleClose();
  } catch (e) {
    console.error(e);
  } finally {
    state.disableLeftButton = false;
    state.disableRightButton = false;
  }
}

function resetModal() {
  modalInjectedOptions.value = {};
  state.show = false;
  state.disableLeftButton = false;
  state.disableRightButton = false;
  state.closingDueToButtonAction = false;
  modalState.isVisible = false;
}

function getModalInstance() {
  handleClose = () => {
    const callback = getButtonCallback('modal');

    if (!!callback && !state.closingDueToButtonAction) {
      callback();
    } else {
      state.closingDueToButtonAction = false;
    }

    resetModal();
  };
}

function processModalState() {
  buildModalOptions();

  getModalInstance();

  handleShow();
}

watch(
  () => modalState.isVisible,
  (newValue) => {
    if (newValue) {
      processModalState();
    } else {
      buildModalOptions();
    }
  },
);

onMounted(() => {
  state.closingDueToButtonAction = false;
});
</script>

<template>
  <Dialog :open="state.show" @update:open="handleClose">
    <DialogContent
      id="md-auxiliar-modal"
      :class="modalSizeClasses"
      elevated
      size="lg"
    >
      <DialogHeader>
        <DialogTitle>
          {{ modalInjectedOptions.title }}
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <component
          :is="modalInjectedOptions.textLayout"
          v-if="!!modalInjectedOptions.textLayout"
          :alert-title="modalInjectedOptions.alertTitle"
          :show-alert-title="modalInjectedOptions.showAlertTitle"
          :title-classes="modalInjectedOptions.textClasses"
          :variant="modalInjectedOptions.alertVariant"
        >
          <template v-if="!!modalInjectedOptions.alertDescriptionElement">
            <component :is="modalInjectedOptions.alertDescriptionElement()" />
          </template>
          <div :class="modalInjectedOptions.textLayoutClasses">
            <p :class="modalInjectedOptions.textClasses">
              {{ modalInjectedOptions.text }}
            </p>
            <p v-if="!!modalInjectedOptions.subtitle" :class="modalInjectedOptions.subtitleClasses">
              {{ modalInjectedOptions.subtitle }}
            </p>
          </div>
        </component>

        <template v-else>
          {{ modalInjectedOptions.text }}
        </template>
      </DialogBody>

      <DialogFooter>
        <VButton
          v-if="modalInjectedOptions.leftButtonText"
          :class="modalInjectedOptions.leftButtonClasses"
          :disabled="state.disableLeftButton || modalInjectedOptions.leftButtonDisabled"
          data-cy="btn-modal-left"
          type="button"
          @click="handleCallback"
        >
          <template v-if="modalInjectedOptions.leftButtonLoadingText && state.disableLeftButton">
            <span>{{ modalInjectedOptions.leftButtonLoadingText }}</span>
          </template>

          <template v-else>
            {{ modalInjectedOptions.leftButtonText }}
          </template>
        </VButton>

        <VButton
          :class="modalInjectedOptions.rightButtonClasses"
          :disabled="state.disableRightButton"
          data-cy="btn-modal-right"
          type="button"
          @click="handleCallback('right')"
        >
          <template v-if="modalInjectedOptions.rightButtonLoadingText && state.disableRightButton">
            <span>{{ modalInjectedOptions.rightButtonLoadingText }}</span>
          </template>

          <template v-else>
            {{ modalInjectedOptions.rightButtonText }}
          </template>
        </VButton>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss">
#md-auxiliar-modal {
  z-index: 1100;
}

.modal-backdrop.modal-backdrop--custom {
  z-index: 1060;
}
</style>
