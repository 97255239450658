export default {
  goToAnalytics: 'Ir a Analytics',
  noProperties: 'Parece que tu cuenta no tiene ninguna propiedad de {property} asociada.',
  noAccount: 'Parece que tu cuenta de Google no tiene esta cuenta de {property} asociada.',
  selectProperty: 'Selecciona una propiedad de Analytics',
  selectPropertyText:
    'Debes seleccionar una cuenta y una propiedad de Google Analytics para poder sincronizar tus datos. Solo compatible con propiedades de GA4. ',
  changeProperty: 'Cambia la propiedad de Analytics',
  changePropertyText:
    'Puedes cambiar la cuenta o la propiedad de Google Analytics que has elegido entre las cuentas y propiedades a las que tiene acceso tu cuenta de Google.',
  selectAccount: 'Selecciona una cuenta',
  property: 'Propiedad',
  view: 'Vista',
  noResults: 'Sin resultados para {search}',
  connectModular: 'Conecta Modular con Google Analytics para comenzar a ver tus estadísticas en la aplicación.',
  loginGoogle: 'Iniciar sesión en Google',
  confirmingConnectionTitle: 'Confirmando con Google',
  lastDays: '{days} días',
  unlinkAccount: 'Desvincular esta cuenta de Analytics',
  unlinkAccountShort: 'Desvincular cuenta',
  unlinkAccountExplain:
    'Vas a desvincular tu cuenta de Analytics para esta web. No se podrás ver tus estadísticas en Modular hasta que vuelvas a reconectar la cuenta de Analytics. ¿Estás seguro?',
  ga4Selector: '{name} (Google Analytics V4)',
  mayLateMinutes: 'Puede tardar 5-10 minutos',
  calculating: 'Calculando reporte...',
  accountInformation: 'Información de la cuenta',
  googleAccount: 'Cuenta de Google',
  analyticsAccount: 'Cuenta de Analytics',
  changeGoogleAccount: 'Cambiar cuenta de Google',
  changeAnalyticsPropertyt: 'Cambiar propiedad de Analytics',
  reportError:
    'Wops! Parece que hay algún problema al obtener el reporte de Analytics. Revisa la configuración de tu cuenta de Google y vuelve a intentarlo.',
  empty: {
    emptyYTLink: 'https://www.youtube.com/embed/dQl0U0TyobY',
    title: 'Google Analytics',
    emptyText:
      'Conecta tu cuenta de Google Analytics para mostrar en Modular los datos principales de la web de tu cliente y así poder añadirlos a los reportes. Tanto con propiedades de Google Analytics Universal como Google Analytics 4.',
    statisticsTitle1: 'Estadísticas básicas para tu cliente',
    statisticsReason1:
      'Haz que tu cliente entienda cómo está funcionando su web. Cuánta gente la visita, desde dónde lo hacen y qué páginas son las que tienen más éxito.',
    statisticsTitle2: 'Conectadas a los reportes',
    statisticsReason2:
      'Una vez que conectes tu cuenta de Google Analytics podrás sacar las estadísticas también en los reportes de Modular.',
  },
};
