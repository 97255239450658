export default {
  fileTypeNotAllowed: 'You must load a file of type',
  tooLarge: 'File too large',
  tooSmall: 'File too small',
  tryAgain: 'try to upload this file again',
  somethingWentWrong: 'Something went wrong loading this file',
  selectOrDragMax: 'Select or drag maximum 1 file | Select or drag maximum {max} files',
  selectOrDrag: 'Select or drag files',
  file: {
    singular: 'file',
    plural: 'files',
  },
  anyImage: 'any image',
  anyVideo: 'any video',
  goBack: 'Go Back',
  dropFile: 'Drop file to upload',
  dragHere: 'Drag the file here',
  remove: 'Remove',
  name: 'name',
  download: 'Download',
};
