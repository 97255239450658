<script lang="ts" setup>
import type { Ref } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import api from '@/api';

import router from '@/router';
import { useUserStore } from '@/stores/userStore';

import { useWindowSize, watchDebounced } from '@vueuse/core';
import useAppStore from '@/stores/appStore';

defineOptions({
  name: 'TheScreenSizeVerify',
});

const isMobile = ref(false);
const isEventLaunched = ref(false);

const userStore = useUserStore();
const isLogged = computed(() => userStore.isLogged);

const appStore = useAppStore();
const darkMode = computed(() => appStore.darkMode);

function checkScreenWidth(widthToCheck: number) {
  if (widthToCheck < 768) {
    isMobile.value = true;

    if (!isEventLaunched.value) {
      try {
        isEventLaunched.value = true;
        api.mixpanel.trackGuest('Try from Mobile');
      } catch (e: any) {
        console.error('Error tracking event', e);
      }
    }
  } else {
    isMobile.value = false;
  }
}

const { width } = useWindowSize();

watchDebounced(
  width,
  (newWidth) => {
    checkScreenWidth(newWidth);
  },
  {
    debounce: 250,
    maxWait: 1000,
  },
);

function supportLaunch() {
  api.mixpanel.track('Ask for support', {
    location: 'Responsive Modal',
  });

  const { Assistant } = window;

  if (!!Assistant) {
    Assistant('open');
  } else {
    window.open('mailto:hdeprada@modulards.com');
  }
}

const isPublic: Ref<boolean> = ref(true);

watch(
  () => router.currentRoute.value,
  (value, previousValue) => {
    if (value !== previousValue) {
      isPublic.value = !!value.meta.isPublic;
    }
  },
);

onMounted(() => {
  checkScreenWidth(width.value);
});
</script>

<template>
  <Dialog :open="isMobile && isLogged && !isPublic">
    <DialogContent id="site-updates-item-changelog" persistent size="full">
      <DialogHeader :disable-close="true">
        <v-picture class="logo d-flex justify-content-center">
          <img
            v-if="darkMode"
            alt="Logo ModularDS"
            src="@assets/img/ModularDS-logo-light.png"
            srcset="@assets/img/ModularDS-logo-light.png 2x, @assets/img/ModularDS-logo-light.png 1x"
          >
          <img
            v-else
            :alt="$t('general.shared.appLogoAlt')"
            src="@assets/img/modular-purple-logo.png"
            srcset="@assets/img/modular-purple-logo.png 2x, @assets/img/modular-purple-logo.png 1x"
          >
        </v-picture>
      </DialogHeader>

      <DialogBody class="mt-32 pb-32">
        <h1 class="text-xxl lh-lg mb-12">
          {{ $t('general.screenVerify.title') }}
        </h1>

        <p class="mb-32 text-sm text-gray-600">
          {{ $t('general.screenVerify.workingOn') }}
        </p>

        <p class="mb-32 text-sm text-gray-600">
          {{ $t('general.screenVerify.openOnDesktop') }}
        </p>

        <p class="fw-semi mb-8">
          {{ $t('general.screenVerify.needHelp') }}
        </p>

        <div class="d-flex position-relative mb-24">
          <div class="image md-image-rounded radius-lg me-8 overflow-hidden">
            <v-picture height="32" width="32">
              <img
                :alt="$t('general.shared.trustPersonImageAlt')"
                src="@assets/img/hector-de-prada-ceo-cofounder-uniqoders-96x96.jpg"
                srcset="
                @assets/img/hector-de-prada-ceo-cofounder-uniqoders-96x96.jpg 2x,
                @assets/img/hector-de-prada-ceo-cofounder-uniqoders-96x96.jpg 1x
              "
              >
            </v-picture>
          </div>

          <div class="d-flex justify-content-center">
            <VButton
              class="align-items-center stretched-link btn-link-primary-dark"
              size="sm"
              variant="link"
              @click="supportLaunch"
            >
              <v-icon icon="comment" size="sm" space="fw-bold" />

              <span class="fw-semi ms-8 text-sm">{{ $t('general.sidebar.trustedPersonLink') }}</span>
            </VButton>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <v-picture class="md-resp-image" height="150" width="113">
            <img
              :alt="$t('general.screenVerify.title')"
              src="@assets/img/responsive-image.png"
              srcset="@assets/img/responsive-image.png 2x, @assets/img/responsive-image.png 1x"
            >
          </v-picture>
        </div>
      </DialogBody>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped>
.logo {
  max-width: 175px;
}

:deep(.md-resp-image) img {
  width: 150px;
}

:deep(.md-image-rounded) img {
  height: 2rem;
  width: 2rem;
}
</style>
