<script lang="ts">
import api from '@/api';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VTrustedPerson',
  methods: {
    supportLaunch() {
      api.mixpanel.track('Ask for support', {
        location: 'Sidebar',
      });

      const { Assistant } = window;

      if (!!Assistant) {
        Assistant('open');
      } else {
        window.open('mailto:hdeprada@modulards.com');
      }
    },
  },
});
</script>

<template>
  <div id="overview-aside-trusted" class="col-12 aside mb-16">
    <div class="aside-content aside-trusted-person radius-sm position-relative bg-white px-16 py-24">
      <div class="d-flex">
        <div class="image image-rounded radius-lg me-16">
          <v-picture height="48" width="48">
            <img
              :alt="$t('general.shared.trustPersonImageAlt')"
              src="@assets/img/hector-de-prada-ceo-cofounder-uniqoders-96x96.jpg"
              srcset="
                @assets/img/hector-de-prada-ceo-cofounder-uniqoders-96x96.jpg 2x,
                @assets/img/hector-de-prada-ceo-cofounder-uniqoders-96x96.jpg 1x
              "
            >
          </v-picture>
        </div>

        <div class="d-flex flex-column justify-content-center">
          <h3 class="fw-medium mb-0 text-xs text-gray-600">
            {{ $t('general.sidebar.trustedPerson') }}
          </h3>

          <VButton class="align-items-center stretched-link" size="sm" variant="link" @click="supportLaunch">
            <v-icon icon="comment" size="sm" space="fw-bold" />

            <span class="fw-semi ms-8 text-sm">{{ $t('general.sidebar.trustedPersonLink') }}</span>
          </VButton>
        </div>
      </div>
    </div>
  </div>
</template>
