<script lang="ts" setup>
import VSitesCreateButtons from '@/components/dashboard/sites/widgets/DashboardWidgetActionButtons.vue';

import useTeamStore from '@/stores/teamStore';

import { computed } from 'vue';

defineOptions({
  name: 'TheTeam',
});

defineProps({
  team: {
    type: String,
    required: true,
  },
});

const teamStore = useTeamStore();

const currentTeam = computed(() => teamStore.currentTeam);
</script>

<template>
  <div id="the-team" data-cy="team-page">
    <dashboard-sites
      :current-team="currentTeam"
      :current-team-slug="team"
      :is-team-view="true"
      location="Team page"
      show-aside
    >
      <template #options>
        <VSitesCreateButtons enable-create-site>
          <template #title>
            <h2 v-if="!!currentTeam?.name" class="h5 mb-0 me-32" data-cy="team-header-name">
              {{ currentTeam.name }}
            </h2>
            <span v-else class="placeholder-glow"><span class="placeholder me-32" style="width: 140px" /></span>
          </template>
        </VSitesCreateButtons>
      </template>

      <template #sidebar>
        <slot name="sidebar" />
      </template>
    </dashboard-sites>

    <router-view />
  </div>
</template>
