<script lang="ts" setup>
import type { StrInterface } from '@/helpers/stringHelper';
import type { DatePickerModel } from 'v-calendar/dist/types/src/use/datePicker';

import useAppStore from '@/stores/appStore';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';

import { DatePicker } from 'v-calendar';
import { inject, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

interface ModelModifiersType {
  number?: boolean
  string?: boolean
  range?: boolean
}

interface MasksType {
  title?: string
  weekdays?: string
  navMonths?: string
  dayPopover?: string
  data?: string | string[]
  modelValue?: string
  input?: string | string[]
}

const props = withDefaults(
  defineProps<{
    masks?: MasksType
    modifiers: ModelModifiersType
    id: string
    modelValue?: DatePickerModel
    label?: string
    classes?: string
    labelClass?: string
    disabled?: boolean
    yupErrorsVariable?: string
    minDate?: string | null
    maxDate?: string | null
    range?: boolean
    string?: boolean
    number?: boolean
    emptyStartLabel?: string
    emptyEndLabel?: string
    borderless?: boolean
    color: string
    mode: string
    trimWeeks?: boolean
    disabledDates?: any[]
  }>(),
  {
    classes: '',
    label: '',
    modelValue: '',
    minDate: null,
    maxDate: null,
    masks: () => ({
      title: '',
      weekdays: '',
      navMonths: '',
      dayPopover: '',
      data: '',
      modelValue: '',
      input: '',
    }),
    labelClass: '',
    disabled: false,
    yupErrorsVariable: '',
    emptyStartLabel: '',
    emptyEndLabel: '',
    modifiers: () => ({
      number: false,
      string: false,
      range: false,
    }),
    range: false,
    string: false,
    number: false,
    borderless: true,
    color: '',
    mode: 'date',
    trimWeeks: false,
    disabledDates: () => [],
  },
);

const emit = defineEmits(['update:modelValue']);

interface ModelValueDateType {
  start: number | string | Date | null
  end: number | string | Date | null
}

const $str: StrInterface = inject('str')!;

const { modelValue } = toRefs(props);
const localValueTemp = ref<DatePickerModel>(null);
const selecting = ref('');

const { locale } = useI18n();

const appStore = useAppStore();

const { darkMode } = storeToRefs(appStore);
function updateValue(value: DatePickerModel) {
  selecting.value = '';

  emit('update:modelValue', value);
}

function drag(value: any) {
  if (!localValueTemp.value) {
    selecting.value = 'start';
    localValueTemp.value = value;
  } else {
    const startOldValue = $str.formatDate((localValueTemp.value as ModelValueDateType).start);
    const startNewValue = $str.formatDate(value.start);

    if (startOldValue !== startNewValue) {
      selecting.value = 'start';
      localValueTemp.value = value;
    } else {
      selecting.value = 'end';
      localValueTemp.value = value;
    }
  }
}
</script>

<template>
  <div class="date-picker h-100">
    <DatePicker
      :model-value="modelValue"
      :borderless="borderless"
      :color="color"
      :disabled-dates="disabledDates"
      :input-debounce="100"
      :locale="locale"
      :masks="masks"
      :max-date="maxDate"
      :min-date="minDate"
      :mode="mode"
      :model-modifiers="modifiers"
      :trim-weeks="trimWeeks"
      :is-dark="darkMode"
      @drag="drag"
      @update:model-value="updateValue"
      @dayclick="
        (_, event) => {
          // Avoids one VCalendar error, DO NOT REMOVE
          event.target.blur();
        }
      "
    >
      <template #default="{ inputValue, togglePopover }">
        <slot name="content" v-bind="{ inputValue, togglePopover }" />

        <template v-if="!$slots.content">
          <VButton
            class="btn-date btn-transparent text-dark fw-semi shadow-none"
            variant="link"
            @click="togglePopover"
          >
            <span
              :class="{ 'text-primary': selecting === 'start' }"
              class="date-title text-decoration-underline text-nowrap text-end"
            >
              <template v-if="!inputValue.start">
                <template v-if="!!emptyStartLabel">
                  {{ emptyStartLabel }}
                </template>
                <template v-else>
                  {{ $t('general.shared.from') }}
                </template>
              </template>
              <template v-else>
                {{ DateTime.fromISO(inputValue.start).setLocale(locale).toFormat('DD') }}
              </template>
            </span>

            <slot name="separator" />

            <template v-if="!$slots.separator">
              <span class="fw-semi px-4">-</span>
            </template>

            <span
              :class="{ 'text-primary': selecting === 'end' }"
              class="date-title text-decoration-underline text-nowrap"
            >
              <template v-if="!inputValue.end">
                <template v-if="!!emptyEndLabel">
                  {{ emptyEndLabel }}
                </template>
                <template v-else>
                  {{ $t('general.shared.to') }}
                </template>
              </template>
              <template v-else>
                {{ DateTime.fromISO(inputValue.end).setLocale(locale).toFormat('DD') }}
              </template>
            </span>
          </VButton>
        </template>
      </template>
    </DatePicker>

    <span v-if="!!yupErrorsVariable" class="invalid-feedback mt-8">
      {{ yupErrorsVariable }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.date-title {
  width: 6.375rem;

  &:hover {
    color: var(--md-primary);
  }
}
</style>
