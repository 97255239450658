<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalHeader',
  props: {
    disableClose: {
      type: Boolean,
      required: false,
      default: null,
    },
    closeSize: {
      type: String,
      required: false,
      default: null,
      validator: val => ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(val),
    },
    closeClasses: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['clickClose'],
});
</script>

<template>
  <div class="modal-header position-relative justify-content-between border-bottom-0">
    <slot />

    <VButton
      v-if="!disableClose"
      :aria-label="$t('general.modal.close')"
      :class="closeClasses"
      :title="$t('general.modal.close')"
      class="btn-link fw-medium"
      data-bs-dismiss="modal"
      size="sm"
      data-cy="close-modal"
      @click="$emit('clickClose')"
    >
      {{ $t('general.modal.close') }}
    </VButton>
  </div>
</template>
