export default {
  countdown: {
    countdownMode:
      '{number}% discount with the code <strong>{code}</strong> until Sunday. <span class="time">{time}</span> left.',
    moreInfo: 'More info',
  },
  serverDown: 'We’re experiencing technical difficulties. Some features may not be available.',
  maintenanceMode: 'Scheduled maintenance on November 13 from 4:30 PM to 5:00 PM (UTC).',
  disconnectedSocket: 'Due to inactivity, real-time data updates have stopped. The information may not be up to date.',
  shared: {
    // Needed for report
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    days: 'd',
    daysLong: 'days',
    hours: 'h',
    hoursLong: 'hours',
    minutes: 'min',
    minutesShort: 'm',
    minutesLong: 'minutes',
    seconds: 's',
    secondsLong: 'seconds',
    year: 'Year',
    yearly: 'Yearly',
    month: 'Month',
    monthly: 'Monthly',
    since: 'Since',
    date: 'Date',
    state: 'Status',
    to: 'Until',
    duration: 'Duration',
    now: 'Now',

    title: 'Title',
    text: 'Text',
    advancedSettings: 'Advanced Settings',
    options: 'Options',
    config: 'Configuration',
    general: 'General',
    actions: 'Actions',
    contact: 'Contact',
    cannotUndo: 'This operation cannot be undone',
    settings: 'Settings',
    name: 'Name',
    older: 'Older',
    lastName: 'Last name',
    lastNames: 'Last name',
    Slug: 'Slug',
    primary: 'Primary',
    error: 'An error has occurred',
    frequenlyAsked: 'Frequenly asked',
    see: 'See',
    up: 'Up',
    paid: 'Paid',
    pending: 'Pending',
    day: 'Day | Days',
    country: 'Country',
    selectCountry: 'Select a Country',
    updatedData: 'Data updated',
    savedData: 'Data saved',
    savedChanges: 'Changes saved',
    updatedEmail: 'Update Email',
    updatedPassword: 'Update Password',
    price: 'Price',
    ohNo: 'Oh no!',
    appLogoAlt: 'Modular Logo',
    teamImageAlt: 'Team work',
    userImageAlt: 'User image',
    trustPersonImageAlt: 'Trusted Person Image',
    tableShowNumber: 'Show: {number}',
    pdf: 'PDF',
    pagination: 'Pagination',
    new: 'New',
    last: 'Last',
    next: 'Next',
    retry: 'Retry',
    on: 'on',
    off: 'off',
    down: 'Down',
    paused: 'Paused',
    unknown: 'Unknown',
    for: 'for',
    comingSoon: 'Coming soon',
    alreadyAvailable: 'Already available',
    proTag: 'PRO',
    proVersion: 'Pro',
    hour: 'Hour',
    size: 'Size',
    stateAndActions: 'State and actions',
    from: 'Since',
    search: 'Search',
    soon: 'Soon',
    and: 'and',
    frequencyError: 'Unsupported frequency',
    filterBy: 'Filter by:',
    searchFor: 'Search...',
    uploadImageText: 'Select or drag 1 file',
    becomePro: 'Become PRO',
    becomeBusiness: 'Get Business',
  },
  webs: {
    websCount: '{num} webs',
  },
  header: {
    search: 'Find websites, teams...',
    support: 'Support',
    supportLink: 'https://support.modulards.com/en?utm_source=app&utm_medium=support&utm_campaign=menu',
    myProfile: 'My profile',
    logout: 'Logout',
    helpUs: 'Can you help us?',
    reviewUs: 'Leave a review',
  },
  download: {
    downloadingFiles: 'Downloading {num} files',
    compressing: 'Compressing...',
  },
  form: {
    user: 'User',
    password: 'Password',
  },
  button: {
    add: 'Add',
    adding: 'Adding...',
    agree: 'Agree',
    update: 'Update',
    delete: 'Delete',
    deleteAlt: 'Erase',
    deleting: 'Deleting...',
    remove: 'Remove',
    removing: 'Removing...',
    apply: 'Apply',
    deleteSite: 'Delete website',
    enable: 'Enable',
    disable: 'Disable',
    disabling: 'Disabling...',
    confirm: 'Confirm',
    continue: 'Continue',
    cancel: 'Cancel',
    dashboard: 'Go to dashboard',
    noCancel: 'No cancel',
    loading: 'Loading...',
    processing: 'Processing...',
    checking: 'Checking...',
    connect: 'Connect',
    reconnectShort: 'Reconnect',
    retry: 'Retry',
    close: 'Close',
    save: 'Save',
    saveAndSchedule: 'Save and schedule',
    saveChanges: 'Save changes',
    saving: 'Saving...',
    edit: 'Edit',
    back: 'Back',
    backToTeam: 'Back to team',
    viewAll: 'View all',
    toWordPress: 'Go to WordPress',
    updateNow: 'Update now',
    select: 'Select',
    selected: 'Selected',
    unlink: 'Unlink',
    unlinking: 'Unlinking...',
    goToModular: 'Go to ModularDS',
    reconnect: 'Reconnect',
    configure: 'Configure',
    learnMore: 'Learn More',
    checkConnection: 'Test connection',
    exclude: 'Exclude',
    getMoreSpace: 'Get more space',
    recover: 'Recover',
    seeDetails: 'See details',
    hire: 'Upgrade',
    next: 'Next',
    saveAndSend: 'Save and send',
    checkState: 'Check state',
    include: 'Include',
    addAnotherWebsite: 'Add another website',
    disconnectOtherSessions: 'Log out other browser sessions',
    disconnectSessions: 'Close sessions',
    disconnect: 'Disconnect',
    disconnecting: 'Disconnecting',
    saveDraft: 'Save draft',
    generateReport: 'Generate report',
    generateOtherReport: 'Generate another report',
    finish: 'Finish',
    saveReport: 'Save report',
    share: 'Share',
    saveAsDraft: 'Save as draft',
    restoreDefaults: 'Restore defaults',
    download: 'Download',
    downloadAll: 'Download all',
    deactivated: 'Deactivated',
    updatePlugins: 'Update plugins',
    discard: 'Discard',
    preview: 'Preview',
    view: 'View',
    addExtraSpacing: 'Add GB to my plan',
    verify: 'Verify',
    verifying: 'Verifying...',
    change: 'Change',
    changing: 'Changing...',
    viewWebsite: 'Visit website',
    viewReports: 'View reports',
    createReport: 'Create report',
    createTask: 'Create task',
    new: 'New',
    removeTag: 'Delete',
    clearFilters: 'Clear filters',
    search: 'Search',
    searching: 'Searching...',
  },
  modal: {
    close: 'Close',
    delete: 'Delete {name}',
    deletePaymentLink: 'Delete payment link',
    deletePaymentExplain: 'Deleting the link will make it disappear forever..',
    deletePaymentMethod: 'Remove payment link',
    deletePaymentMethodExplain: 'Deleting the link will make it disappear forever.',
    confirmDeleteSite:
      'If you disconnect this website from Modular, you will not be able to consult it from the application and you will have to start the connection process again.',
    confirmDeleteTeam: 'The team will be permanently deleted.',
    successDeleteSite: 'Website successfully removed.',
    successDeleteLink: 'Link deleted',
    successDeletePaymentMethod: 'Payment method removed',
    deleteUser: 'Delete user {email}',
    deleteUserExplain: 'By deleting the user all data will be lost.',
    successDeleteUser: 'User deleted',
  },
  sidebar: {
    offerTitle: 'Vote for upcoming features',
    offerText: 'You decide with Modular',
    offerLink: 'View public roadmap',
    offerLinkUrl: 'https://trello.com/b/Tl5aWQOo',
    trustedPerson: 'Your trusted person',
    trustedPersonLink: 'Talk to Hector',
    activateUptimeMonitor: 'Activate Uptime Monitor',
    activating: 'Activating...',
  },
  verifyEmail: {
    title: 'Remember to verify your email address in the email we have sent you.',
    resend: 'Resend email',
    confirmEmailSent: 'Confirmation email sent',
    error: 'There was an error in your request.',
    tryLater: 'Please try again later or contact us.',
    successVerified: 'Your email has been successfully verified.',
    goBack: 'You can click on the following link to go back to Modular.',
  },
  screenVerify: {
    title: 'Modular is better on a big screen!',
    workingOn: 'We are working on bringing you a quality mobile version. For now we can help you from the big screen',
    openOnDesktop: 'Switch to your laptop or tablet for access!',
    needHelp: 'Need some help?',
  },
  error: {
    lostConnection: '403, we have lost contact with the server...',
    lostConnectionImgAlt: '403 error',
    lostPage: '404, we missed something...',
    lostPageImgAlt: '404 error',
  },
  toast: {
    successSaving: 'Successfully saved',
    successUpdated: 'Successfully updated',
    successDeleting: 'Successfully deleted',
  },
  organizationLimit: {
    title: 'You have reached the limit',
    alertTitle: 'You have already connected {number} websites on your {plan} plan',
    alertText: 'Subscribe to a {nextPlan} plan to connect unlimited websites',
    cancelButton: 'Not now',
    acceptButton: 'Upgrade to {nextPlan}',
  },
  text: {
    maxLimit: '{characters} of {max_characters} characters used',
  },
  placeholder: {
    noDataToShow: 'No data to show',
  },
  dayweek: {
    monday: 'monday | monday',
    tuesday: 'tuesday | tuesday',
    wednesday: 'wednesday | wednesday',
    thursday: 'thursday | thursday',
    friday: 'friday | friday',
    saturday: 'saturday | saturdays',
    sunday: 'sunday | sundays',
  },
  downloader: {
    complete: 'Download complete',
  },
};
