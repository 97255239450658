export default {
  label: 'Vulnerabilities',
  tabTitle:
    'Your page has <span class="text-dark">{num} vulnerability</span> that may compromise the site\'s security. | Your page has <span class="text-dark">{num} vulnerabilities</span> that may compromise the site\'s security.',
  noData: 'We are checking the site\'s vulnerabilities. This may take a few minutes.',
  severityShort: {
    high: 'High',
    medium: 'Medium',
    low: 'Low',
    unknown: 'Unknown',
  },
  severity: {
    high: 'High vulnerability',
  },
  tooltip: {
    view: 'View vulnerabilities',
  },
  fields: {
    severity: 'Severity',
    affectedVersion: 'Affected version',
    fixedVersion: 'Fixed in version',
    globalScore: 'Global score',
    exploitable: 'Exploitability',
    impact: 'Impact',
    moreInformation: 'More information',
    moreInformationAlt: 'View more information about the vulnerability',
  },
  health: {
    unFixed: 'no fix available',
    fixedIn: 'fixed in {version}',
  },
  tabs: {
    plugin: 'Plugins',
    core: 'WordPress',
    theme: 'Themes',
    php: 'PHP Version',
    nginx: 'Nginx Version',
    apache: 'Apache Version',
  },
  criticalTabs: {
    plugin: 'You have {num} vulnerability in Plugins | You have {num} vulnerabilities in Plugins',
    core: 'You have {num} vulnerability in WordPress | You have {num} vulnerabilities in WordPress',
    theme: 'You have {num} vulnerability in Themes | You have {num} vulnerabilities in Themes',
    php: 'You have {num} PHP vulnerability | You have {num} PHP vulnerabilities',
    nginx: 'You have {num} Nginx vulnerability | You have {num} Nginx vulnerabilities',
    apache: 'You have {num} Apache vulnerability | You have {num} Apache vulnerabilities',
  },
};
