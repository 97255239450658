import type { SiteBackupExtendedType } from '@/stores/site/backup';
import api from '@/api';
import { useBackupsMap } from '@/composables/backups/useBackupsMap';
import { useBackupStore } from '@/stores/site/backup/backupStore';
import useSiteStore from '@/stores/site/siteStore';
import { computed } from 'vue';

function useBackups() {
  // region Stores
  const siteStore = useSiteStore();
  const backupStore = useBackupStore();

  const currentSiteId = computed(() => siteStore.currentSiteId);

  const backupsServiceId = computed(() => backupStore.backupsServiceId);

  const {
    currentBackup,
    backups,
    lastBackups,
    backupsService,
    loadedBackupsService,
    loadedBackups,
  } = storeToRefs(backupStore);

  // endregion Stores

  // region Functions

  const loadBackupsService = async () => {
    try {
      if (backupsServiceId.value) {
        loadedBackupsService.value = false;

        backupsService.value = await api.site.service.backup.retrieveService(backupsServiceId.value);
      }
    } catch (e) {
      console.error('Error loading Backup service', e);
      throw e;
    } finally {
      loadedBackupsService.value = true;
    }
  };

  const loadBackups = async () => {
    if (backupsServiceId.value && currentSiteId.value && backupStore.backupsIsConfigured) {
      try {
        const requestedBackups = (await api.site.service.backup.index(currentSiteId.value));

        const { mapBackups, mapLastBackups } = useBackupsMap();

        backups.value = mapBackups({ backups: requestedBackups });
        lastBackups.value = mapLastBackups({ backups: requestedBackups });
      } catch (e) {
        console.error(e);
      } finally {
        loadedBackups.value = true;
      }
    }
  };

  const reloadBackup = async ({ backupId, isLastBackups = false }: {
    backupId: number
    isLastBackups?: boolean
  }) => {
    try {
      const { mapBackups, mapLastBackups } = useBackupsMap();

      const backupsAux = isLastBackups ? lastBackups.value : backups.value;

      if (!!backupsAux) {
        // Get requested backup
        let requestedBackup = (await api.site.service.backup.show(
          backupId,
        )) as SiteBackupExtendedType;

        if (!!requestedBackup) {
          // Map backup properties
          if (isLastBackups) {
            [requestedBackup] = mapLastBackups({ backups: [requestedBackup] });
          } else {
            [requestedBackup] = mapBackups({ backups: [requestedBackup] });
          }
        }

        // If backup do not exist, adds it at the beginning of the array
        if (!backupsAux.find(b => b.id === backupId)) {
          backupsAux.unshift(requestedBackup);
        } else {
          // If exists, we delete it and replace it with the new one
          backupsAux.splice(
            backupsAux.findIndex(b => b.id === backupId),
            1,
            requestedBackup,
          );
        }

        if (!!currentBackup.value && currentBackup.value.id === backupId) {
          currentBackup.value = requestedBackup;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      loadedBackups.value = true;
    }
  }

  return {
    loadBackupsService,
    loadBackups,

    reloadBackup,
  };
}

export { useBackups };
export default useBackups;
