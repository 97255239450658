export default {
  toolbar: {
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    strike: 'Strike through',
    blockquote: 'Blockquote',
    code: 'Code',
    codeBlock: 'Code block',
    link: 'Insert/edit a link',
    orderedList: 'Numbered list',
    bulletList: 'Bulleted list',
    alignLeft: 'Align left',
    alignCenter: 'Align center',
    alignRight: 'Align right',
    alignJustify: 'Justify',
    setUrl: 'Enter URL',
    clean: 'Clear format',
  },
};
