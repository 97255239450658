import { Concept } from '@/types/models/Concept';

export type ChangeStatusEnumKeys = 'up' | 'down' | 'unknown';

export const ChangeStatusEnumConst = {
  UP: 'up',
  DOWN: 'down',
  UNKNOWN: 'unknown',
} as const;

export const ChangeStatusEnum: Record<ChangeStatusEnumKeys, Concept> = {
  'up': {
    key: 'up',
    value: null,
    name: 'concept.change.status.upName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'change.status'
  },
  'down': {
    key: 'down',
    value: null,
    name: 'concept.change.status.downName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'change.status'
  },
  'unknown': {
    key: 'unknown',
    value: null,
    name: 'concept.change.status.unknownName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'change.status'
  }
} as const;

export type ChangeStatusEnumType = (typeof ChangeStatusEnumConst)[keyof typeof ChangeStatusEnumConst];

export const ChangeStatusEnumArray = Object.values(ChangeStatusEnum);
