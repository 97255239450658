import type { IconTypes } from '@/components/vendor/basic/icon/VIcon.vue';
import type {
  SiteBackupStatusEnumType,
} from '@/types/enums/SiteBackupStatusEnum';

import {
  SiteBackupStatusEnum,
  SiteBackupStatusEnumConst,
} from '@/types/enums/SiteBackupStatusEnum';

export function useBackupStatus() {
  const {
    DONE,
    FAILED_IN_CREATION,
    FAILED_EXPORT_DATABASE,
    FAILED_EXPORT_FILES,
    FAILED_IN_PROCESSING,
    FAILED_UPLOADED,
    FAILED_PARTIAL,
    FAILED,
    FAILED_FILE_NOT_FOUND,
    STORAGE_EXCEEDED,
    PENDING,
    IN_PROGRESS,
    UPLOADING,
    READY,
  } = SiteBackupStatusEnumConst;

  const errorStates = new Set<SiteBackupStatusEnumType>([
    FAILED_IN_CREATION,
    FAILED_EXPORT_DATABASE,
    FAILED_EXPORT_FILES,
    FAILED_IN_PROCESSING,
    FAILED_UPLOADED,
    FAILED_PARTIAL,
    FAILED,
    FAILED_FILE_NOT_FOUND,
    STORAGE_EXCEEDED,
  ]);

  const loadingStates = new Set<SiteBackupStatusEnumType>([PENDING, IN_PROGRESS, UPLOADING, READY]);

  const getBackupStatus = (status: SiteBackupStatusEnumType): { name: string, color: string, icon: IconTypes } => {
    const conceptName = SiteBackupStatusEnum[status].name || '';

    if (errorStates.has(status)) {
      return {
        name: conceptName,
        color: 'text-danger',
        icon: 'close-x' as IconTypes,
      };
    }

    if (loadingStates.has(status)) {
      return {
        name: conceptName,
        color: 'text-success-dark rotate',
        icon: 'refresh' as IconTypes,
      };
    }

    if (status === DONE) {
      return {
        name: conceptName,
        color: 'text-success-dark',
        icon: 'check' as IconTypes,
      };
    }

    return {
      name: conceptName,
      color: 'text-success-dark',
      icon: 'check' as IconTypes,
    };
  };

  return {
    getBackupStatus,
  };
}
