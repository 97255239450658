<script setup lang="ts">
import type { TabsIndicatorProps } from 'radix-vue';
import { TabsIndicator, useForwardProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<TabsIndicatorProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <TabsIndicator
    v-bind="forwardedProps"
    class="ui-tabs-indicator position-absolute rounded-pill transition-width-transform start-0 px-0"
    :class="[props.class]"
    :style="{
      width: 'var(--radix-tabs-indicator-size)',
      transform: `translateX(var(--radix-tabs-indicator-position))`,
    }"
  >
    <div class="bg-primary w-100 h-100" />
  </TabsIndicator>
</template>

<style scoped lang="scss">
.ui-tabs-indicator {
  height: 2px;
  width: var(--radix-tabs-indicator-size);
  transform: translateX(var(--radix-tabs-indicator-position));
  bottom: 0;

  // TODO REMOVEME WHEN MERGED WITH PRO
  // CREATE TRANSITION: transition-width-transform
  transition-property: width, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}
</style>
