import { Concept } from '@/types/models/Concept';

export type UptimeServiceKeywordStatusEnumKeys = 'active' | 'inactive';

export const UptimeServiceKeywordStatusEnumConst = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export const UptimeServiceKeywordStatusEnum: Record<UptimeServiceKeywordStatusEnumKeys, Concept> = {
  'active': {
    key: 'active',
    value: null,
    name: 'concept.uptime.service.keyword.status.activeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'uptime.service.keyword.status'
  },
  'inactive': {
    key: 'inactive',
    value: null,
    name: 'concept.uptime.service.keyword.status.inactiveName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'uptime.service.keyword.status'
  }
} as const;

export type UptimeServiceKeywordStatusEnumType = (typeof UptimeServiceKeywordStatusEnumConst)[keyof typeof UptimeServiceKeywordStatusEnumConst];

export const UptimeServiceKeywordStatusEnumArray = Object.values(UptimeServiceKeywordStatusEnum);
