<script lang="ts" setup>
import type { Tag } from '@/types/models/organization/tag/Tag';

defineOptions({
  name: 'VTagsComboboxContentItem',
});

withDefaults(
  defineProps<{
    isSelected?: boolean
    showCount?: boolean
  }>(),
  {
    isSelected: false,
    showCount: false,
  },
);

const tag = defineModel<Tag>('modelValue', {
  required: true,
});

function isDarkColor(hex: string) {
  const r = Number.parseInt(hex.substring(1, 3), 16);
  const g = Number.parseInt(hex.substring(3, 5), 16);
  const b = Number.parseInt(hex.substring(5, 7), 16);

  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return brightness > 128;
}
</script>

<template>
  <div v-if="!!tag" class="d-flex align-items-center justify-content-between w-100">
    <span
      :style="{ 'background-color': !!tag && tag?.color, 'color': !!tag && isDarkColor(tag.color) ? 'black' : 'white' }"
      class="badge rounded-xs fw-medium text-xs py-4 px-8"
    >
      <span class="me-2">{{ tag.name }}</span>
      <span v-if="showCount" class="text-2xs">
        <template v-if="!!tag.sitesCount">{{ `(${tag.sitesCount})` }}</template>
        <template v-else>(0)</template>
      </span>
    </span>

    <div class="d-flex align-items-center ms-8">
      <slot name="trigger" />

      <checkbox
        :id="`tags-selector-tag-${tag.id}`"
        :checked="isSelected"
        class="ms-8"
        size="sm"
        square
      />
    </div>
  </div>
</template>
