<script lang="ts" setup>
import type { Tag } from '@/types/models/organization/tag/Tag';

import type { Site } from '@/types/models/site/Site';
import api from '@/api';
import { ContextMenu, ContextMenuContent, ContextMenuTrigger } from '@/components/ui/context-menu';
import { ref } from 'vue';
import { can } from '@/helpers/permissionsHelper';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineOptions({
  name: 'DashboardSitesItemMenuContext',
});

const props = defineProps<{
  site: Site
  disabled?: boolean
}>();

const emits = defineEmits(['loginWordpress', 'launchTracking', 'createTask', 'editSite', 'syncTag']);

const {
  SITES_SHOW,
  ANALYTICS_SERVICES_SHOW,
  SEARCH_CONSOLE_SERVICES_SHOW,
  UPTIME_SERVICES_SHOW,
  SITE_BACKUPS_INDEX,
  SITE_REPORTS_INDEX,
  REPORT_SERVICES_INDEX,
  ORGANIZATION_SITE_REPORT_SCHEDULE_ALLOW,
} = PermissionEnumConst;

const tagsModel = defineModel<Tag[]>('tags', {
  required: true,
});

const router = useRouter();

function openSiteRoute(route = 'sites.site.show', newTab = false) {
  if (newTab) {
    window.open(
      router.resolve({
        name: route,
        params: {
          site: props.site.slug,
        },
      }).href,
      '_blank',
    );
  } else {
    router.push({
      name: route,
      params: {
        site: props.site.slug,
      },
    });
  }

  emits('launchTracking');
}

function openSite() {
  window.open(props.site.uri, '_blank');
}

const openPopover = ref(true);
const searchTerm = ref('');

async function trackTagSelection(hasAddedTag = false) {
  await api.mixpanel.track('Change tag', {
    Location: 'Contextual menu',
    Type: hasAddedTag ? 'Add' : 'Remove',
  });
}
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger :class="$attrs.class" :disabled="disabled" as-child>
      <slot />
    </ContextMenuTrigger>

    <ContextMenuContent class="w-64">
      <ContextMenuItem
        v-can="SITES_SHOW"
        @click.exact="openSiteRoute('sites.site.show', true)"
        @click.ctrl="openSiteRoute('sites.site.show', true)"
      >
        {{ $t('menu.overview') }}
        <ContextMenuShortcut>
          <v-icon class="position-relative transform-rotate--45 me-0" icon="arrow-right" />
        </ContextMenuShortcut>
      </ContextMenuItem>

      <ContextMenuItem @click="$emit('loginWordpress')">
        {{ $t('general.button.toWordPress') }}

        <ContextMenuShortcut>
          <m-icon class="me-0" icon="wordpress-go" />
        </ContextMenuShortcut>
      </ContextMenuItem>

      <ContextMenuSeparator />

      <ContextMenuItem @click="openSite">
        {{ $t('general.button.viewWebsite') }}
      </ContextMenuItem>

      <ContextMenuSeparator />

      <ContextMenuSub>
        <ContextMenuSubTrigger v-if="can(ANALYTICS_SERVICES_SHOW) || can(SEARCH_CONSOLE_SERVICES_SHOW)">
          {{ $t('menu.metrics') }}
        </ContextMenuSubTrigger>
        <ContextMenuSubContent>
          <ContextMenuItem
            v-can="ANALYTICS_SERVICES_SHOW"
            @click.exact="openSiteRoute('sites.site.analytics.show')"
            @click.ctrl="openSiteRoute('sites.site.analytics.show', true)"
          >
            {{ $t('menu.analytics') }}
          </ContextMenuItem>
          <ContextMenuItem
            v-can="SEARCH_CONSOLE_SERVICES_SHOW"
            @click.exact="openSiteRoute('sites.site.service.search-console.show')"
            @click.ctrl="openSiteRoute('sites.site.service.search-console.show', true)"
          >
            {{ $t('menu.searchConsole') }}
          </ContextMenuItem>
        </ContextMenuSubContent>
      </ContextMenuSub>
      <ContextMenuItem
        v-can="UPTIME_SERVICES_SHOW"
        @click.exact="openSiteRoute('sites.site.uptime.check.stats.show')"
        @click.ctrl="openSiteRoute('sites.site.uptime.check.stats.show', true)"
      >
        {{ $t('menu.uptimeMonitor') }}
      </ContextMenuItem>
      <ContextMenuItem
        v-can="SITE_BACKUPS_INDEX"
        @click.exact="openSiteRoute('sites.site.backup.index')"
        @click.ctrl="openSiteRoute('sites.site.backup.index', true)"
      >
        {{ $t('menu.backups') }}
      </ContextMenuItem>
      <ContextMenuSub>
        <ContextMenuSubTrigger>
          {{ $t('menu.reports') }}
        </ContextMenuSubTrigger>
        <ContextMenuSubContent>
          <ContextMenuItem
            v-if="can(SITE_REPORTS_INDEX) || (can(REPORT_SERVICES_INDEX) && can(ORGANIZATION_SITE_REPORT_SCHEDULE_ALLOW))"
            @click.exact="openSiteRoute('sites.site.report.index')"
            @click.ctrl="openSiteRoute('sites.site.report.index', true)"
          >
            {{ $t('general.button.viewReports') }}
          </ContextMenuItem>
          <ContextMenuItem
            @click.exact="openSiteRoute('sites.site.report.type')"
            @click.ctrl="openSiteRoute('sites.site.report.type', true)"
          >
            {{ $t('general.button.createReport') }}
          </ContextMenuItem>
          <ContextMenuItem @click="$emit('createTask', site)">
            {{ $t('general.button.createTask') }}
          </ContextMenuItem>
        </ContextMenuSubContent>
      </ContextMenuSub>

      <ContextMenuItem
        @click.exact="openSiteRoute('sites.site.health.show')"
        @click.ctrl="openSiteRoute('sites.site.health.show', true)"
      >
        {{ $t('menu.healthSafety') }}
      </ContextMenuItem>

      <ContextMenuSeparator />

      <ContextMenuSub>
        <ContextMenuSubTrigger>
          {{ $t('tags.tags') }}
        </ContextMenuSubTrigger>
        <ContextMenuSubContent>
          <VTagsComboboxContent
            v-model="tagsModel"
            v-model:open="openPopover"
            v-model:search-term="searchTerm"
            :sync-tag="true"
            location="Contextual menu"
            @selected="$emit('syncTag', $event);"
            @changed-tags="trackTagSelection($event)"
          />
        </ContextMenuSubContent>
      </ContextMenuSub>

      <ContextMenuItem @click="$emit('editSite')">
        {{ $t('general.button.edit') }}
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
</template>
