import { strings as allStrings, config, faqs, featureKeys, metrics, opinions } from './StringsConstants';

function useConstantsHelper() {
  const strings: any = { ...allStrings };

  const stringConstant = (constant: string) => strings[constant] ?? '';

  return {
    stringConstant,
    faqs,
    opinions,
    featureKeys,
    config,
    metrics,
  };
}

export default useConstantsHelper;
