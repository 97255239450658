<script lang="ts" setup>
import { cn } from '@/helpers/classNames';
import { DialogTitle, type DialogTitleProps, useForwardProps } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

defineOptions({
  name: 'DialogTitle',
});

const props = defineProps<DialogTitleProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <DialogTitle
    :class="cn('ui-dialog-title modal-title mb-0 lh-xl', props.class)"
    v-bind="forwardedProps"
  >
    <slot />
  </DialogTitle>
</template>
