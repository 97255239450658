<script lang="ts" setup>
import { ContextMenuSubTrigger, type ContextMenuSubTriggerProps, useForwardProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<ContextMenuSubTriggerProps & { class?: HTMLAttributes['class'], inset?: boolean }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <ContextMenuSubTrigger
    class="ui-menu-sub-trigger d-flex align-items-center rounded-xs fw-medium px-16 text-sm"
    :class="[
      props.class,
      {
        'ps-8': inset,
      },
    ]"
    v-bind="forwardedProps"
  >
    <slot />
    <v-icon class="ui-menu-sub-trigger-icon ms-auto" icon="chevron-right" size="2xs" />
  </ContextMenuSubTrigger>
</template>
