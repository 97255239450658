import NotificationComponent from '@/helpers/notifications/component/NotificationComponent.vue';
import { NotificationBase } from '@/helpers/notifications/NotificationBase';
import { h } from 'vue';

export class ManagerActivationFailedNotification extends NotificationBase {
  data: {
    site: {
      name: string
      slug: string
      team: string
      url: string
    }
    siteItem: {
      item: string
      type: string
    }
  };

  constructor(
    createdAt: string,
    readAt: string | null,
    updatedAt: string | null,
    data: {
      site: { team: string, slug: string, name: string, url: string }
      siteItem: { item: string, type: string }
    },
  ) {
    super('manager.activation.failed', createdAt, readAt, updatedAt);
    this.data = data;
  }

  render() {
    const route = {
      name: 'sites.site.show',
      params: { site: this.data.site.slug },
    };

    const { t } = useI18n();

    const title = t('notifications.notifications.managerActivationFailed.title', {
      name: this.data.siteItem.item,
      site: this.data.site.name,
    });
    const bodyText = t('notifications.notifications.managerActivationFailed.body', {
      type: t(`manager.${this.data.siteItem.type}`).toLocaleLowerCase(),
      url: this.data.site.url,
    });

    return h(NotificationComponent, {
      route,
      title,
      icon: 'exchange-transfer',
      bodyText,
      createdAt: this.createdAt,
    });
  }
}
