import type { ImageSize, Media } from '@/types/models/Media';
import type { Ref } from 'vue';

import { ref } from 'vue';

export interface PictureData extends Partial<Media> {
  src: string
  srcset: string
  readableSize?: string
  name?: string
}

type SizeName = 'siteCard' | 'reportLogo' | 'reportBranding' | 'customWork' | 'preview';

const sizeNameToThumbnail: Record<SizeName, string> = {
  siteCard: 'thumb-site-card',
  reportLogo: 'thumb-report-logo',
  reportBranding: 'thumb-report-branding',
  customWork: 'thumb-custom-work',
  preview: 'thumb-preview',
};

export function mapResponsive(imageSize: ImageSize | undefined): string {
  if (!imageSize)
    return '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (
    Object.entries(imageSize.urls)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .flatMap(([format, resolutions]) =>
        Object.entries(resolutions as unknown as Record<string, string>).map(([res, url]) => `${url} ${res}`),
      )
      .join(', ')
  );
}

export function usePicture(
  media: Media | null | undefined,
  sizeName: SizeName,
  placeholder?: Pick<PictureData, 'src' | 'srcset' | 'name'>,
): Ref<PictureData> {
  const pictureData = ref<PictureData>({
    src: '',
    srcset: '',
    name: '',
    readableSize: '',
  });

  const generatePictureData = () => {
    if (!media?.responsive && placeholder) {
      pictureData.value = {
        src: placeholder.src,
        srcset: placeholder.srcset,
        name: placeholder.name ?? '',
      };
    } else if (!!media) {
      const thumbnailName = sizeNameToThumbnail[sizeName];
      const srcsetString = thumbnailName ? mapResponsive(media.responsive[thumbnailName]) : '';

      pictureData.value = {
        src: media.conversions.webp || media.url,
        srcset: srcsetString,
        readableSize: media.humanReadableSize,
        ...media,
      };
    }
  };

  generatePictureData();

  return pictureData;
}
