<script setup lang="ts">
import type { ComboboxEmptyProps } from 'radix-vue'
import { ComboboxEmpty } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<ComboboxEmptyProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <ComboboxEmpty v-bind="delegatedProps" class="ui-combobox-empty pt-16 pb-4 text-center text-sm lh-base fw-medium text-primary" :class="props.class">
    <slot />
  </ComboboxEmpty>
</template>
