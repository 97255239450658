export default {
  faq1Title: 'How many websites can I have with my plan?',
  faq1Content:
    'Depending on your plan, you will be able to connect a number of websites to Modular. Our Starter plan allows you to connect up to 50 websites and our Business plan allows you to connect unlimited websites.',
  faq2Title: 'Can I cancel my subscription?',
  faq2Content:
    'Yes, you can cancel your subscription at any time and you will continue to enjoy the functionality and features of your subscription until your next renewal date.',
  faq3Title: 'When am I charged?',
  faq3Content:
    'You will be charged once a month or once a year, depending on the subscription cycle you choose. Starting on the day you sign up for the service.',
  faq4Title: 'What payment methods are allowed?',
  faq4Content:
    'Currently, you can pay for your Modular subscription with a debit or credit card, Google Pay or PayPal.',
  faq5Title: 'What happens if the payment plan does not suit me?',
  faq5Content:
    'You have 15 days to contact us and tell us that you don\'t want to continue with your payment plan and we will refund your money.',
  faq6Title: 'Is Modular safe?',
  faq6Content:
    'Yes, we use the OAuth2 protocol for the authentication of the connection between your website and our servers. Thanks to this protocol, we generate tokens with expiration that can be revoked at any time. Additionally, we do not store any WordPress user passwords.',
  faq7Title: 'Is Modular GPRD compliant?',
  faq7Content:
    'Yes, Modular is GPRD compliant and all our servers and databases are hosted in the European Union. We do not sell your data to third parties and never will.',
};
