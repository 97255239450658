<script lang="ts" setup>
import type { Media } from '@/types/models/Media';

import { ref } from 'vue';
import VMediaUploadComponent from '@/components/vendor/upload/VMediaUploadComponent.vue';
import useValidation from '@/composables/useValidation';
import { DateTime } from 'luxon';

import { Form, useField } from 'vee-validate';

import { useI18n } from 'vue-i18n';

import { object, string } from 'yup';
import useSiteStore from '@/stores/site/siteStore';
import useTask from '@/composables/task/useTask';
import type { TaskStoreRequest, TaskUpdateRequest } from '@/api/services/site/service/report/task';
import type { SiteTaskMapped } from '@/types/models/site/task/SiteTask';

defineOptions({
  name: 'TheSiteReportCreateTask',
});

const props = defineProps<{
  siteId?: number
}>()

const emit = defineEmits(['closed', 'reloadTasks', 'taskUpdated']);

const modelValue = defineModel<SiteTaskMapped | null>('modelValue', {
  default: null,
});

const { t } = useI18n();

const { createTask, updateTask } = useTask();

const siteStore = useSiteStore();

const customWorkImages = ref({ files: [] as Media[] });

const rules = object({
  title: string()
    .required()
    .max(100, t('validation.string.max', { max: 100 })),
  content: string()
    .required(t('validation.mixed.required')),
  date: string().required().nullable(),
  timeSpent: string().nullable().notRequired(),
});

interface InitialValuesType {
  title?: string
  content: string
  date: string
  timeSpent?: number | null
}

let initialValues: InitialValuesType = {
  title: '',
  content: '',
  date: DateTime.now().toISODate(),
  timeSpent: null,
};

const form = useValidation(rules, initialValues);

const { errors, accepted, toggleAccepted, validate, resetForm } = form;

function reloadValues() {
  if (!modelValue.value)
    return;

  initialValues = {
    title: modelValue.value.title,
    content: modelValue.value.content,
    date: !!modelValue.value.date ? modelValue.value.date.split('T')[0] : '',
    timeSpent: modelValue.value.timeSpent || null,
  };

  form.setValues(initialValues);

  if (!!modelValue.value.media) {
    modelValue.value.media.forEach((media: Media) => customWorkImages.value.files.push(media));
  }
}

reloadValues();

const { value: title } = useField<string>('title');
const { value: content } = useField<string>('content');
const { value: date } = useField<string>('date');
const { value: timeSpent } = useField<number | undefined>('timeSpent');

const open = ref(true);

function handleClose() {
  open.value = false;

  setTimeout(() => {
    emit('closed');

    resetForm();
  }, 200);
}

async function saveTask() {
  const valid = await validate();
  toggleAccepted();

  if (!valid.valid)
    return;

  if (!modelValue.value) {
    await handleCreateTask();
  } else {
    await handleUpdateTask();
  }

  toggleAccepted();
}

async function handleCreateTask() {
  if (!siteStore.currentSite && !props.siteId) {
    return;
  }

  const siteId = siteStore?.currentSite?.id || props.siteId;

  const data: TaskStoreRequest = {
    title: title.value,
    content: content.value,
    site: siteId!,
    date: date.value.split('T')[0],
    ...(!!timeSpent.value && { timeSpent: timeSpent.value }),
  };

  if (!!customWorkImages.value.files && customWorkImages.value.files.length > 0) {
    data.files = customWorkImages.value.files.map((media: Media) => ({
      uuid: media.uuid || media.attributes.uuid,
    }));
  }

  await createTask(data).then(() => {
    emit('reloadTasks');

    handleClose();
  });
}

async function handleUpdateTask() {
  if (!modelValue.value)
    return;

  const data: TaskUpdateRequest = {
    title: title.value,
    content: content.value,
    date: date.value.split('T')[0],
    ...(!!timeSpent.value && { timeSpent: timeSpent.value }),
  };

  if (!!customWorkImages.value.files && customWorkImages.value.files.length > 0) {
    data.files = customWorkImages.value.files.map((media: Media) => ({
      uuid: media.uuid || media.attributes.uuid,
    }));
  }

  await updateTask(modelValue.value.id, data).then((task) => {
    if (!task)
      return;

    modelValue.value = {
      ...modelValue.value,
      ...task,
    };

    emit('taskUpdated', modelValue.value);

    handleClose();
  });
}

function saveMedia(media: Media[]) {
  customWorkImages.value.files = media;
}
</script>

<template>
  <Dialog v-model:open="open" :default-open="true" @update:open="handleClose">
    <DialogContent id="site-report-create-task-dialog" overlay-scrollable persistent size="lg">
      <DialogHeader>
        <DialogTitle>
          <template v-if="!modelValue">
            {{ $t('siteReports.tasks.createAdditionalTask') }}
          </template>
          <template v-else>
            {{ $t('siteReports.tasks.editAdditionalTask') }}
          </template>
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <Form id="report-create-task-form" class="mb-8">
          <div class="d-flex mb-16">
            <v-input-date
              id="create-report-task-date"
              v-model="date"
              :class="{ 'is-invalid': !!errors.date }"
              :masks="{ weekdays: 'WWW', input: 'YYYY-MM-DD', modelValue: 'YYYY-MM-DD' }"
              :modifiers="{ string: true }"
              class="me-8"
              color="primary"
              mode="text"
              string
            >
              <template #content="{ inputValue, togglePopover }">
                <v-input-text
                  id="create-report-task-date"
                  :label="`${$t('general.shared.date')}*`"
                  :model-value="inputValue"
                  :placeholder="$t('general.shared.date')"
                  :readonly="true"
                  :yup-errors-variable="errors.date"
                  autocomplete="off"
                  data-cy="create-additional-task-date"
                  label-class="text-sm text-dark fw-medium"
                  label-on-top
                  type="text"
                  @click="togglePopover"
                />
              </template>
            </v-input-date>

            <v-input-time
              id="create-report-task-time"
              v-model="timeSpent"
              :label="$t('siteReports.customWork.hoursSpent')"
              :yup-errors-variable="errors.timeSpent"
              autocomplete="off"
              data-cy="create-additional-task-time"
              label-class="text-sm text-dark fw-medium"
              label-on-top
              placeholder="00:00"
              show-preview
              type="text"
            />
          </div>

          <p class="fw-medium mb-8 text-sm">
            {{ `${$t('general.shared.title')}*` }}
          </p>

          <div class="form-group mb-16">
            <v-input-text
              id="create-report-task-title"
              v-model="title"
              :placeholder="$t('general.shared.title')"
              :yup-errors-variable="errors.title"
              autocomplete="off"
              data-cy="create-additional-task-title"
            />
          </div>

          <div class="form-group mb-16">
            <p class="fw-medium mb-8 text-sm">
              {{ $t('siteReports.messageContent') }}*
            </p>

            <div class="mb-16">
              <m-editor
                id="create-report-task-content"
                v-model="content"
                :editor-class="{ 'is-invalid': !!errors.content }"
                :placeholder="$t('siteReports.tasks.introductionReportContent')"
                class="mb-8"
                data-cy="create-additional-task-content"
                style="max-width: 704px;"
              />

              <span v-if="!!errors.content" class="d-block invalid-feedback">{{ errors.content }}</span>
            </div>
          </div>

          <p class="fw-medium mb-16 text-sm">
            {{ $t('siteReports.customWork.addImages') }}
          </p>

          <VMediaUploadComponent
            v-if="!!customWorkImages.files"
            :files="customWorkImages.files"
            :max-items="4"
            :multiple="true"
            backdrop-over-content
            class="mb-16"
            data-cy="btn-upload-media-annotation"
            @after-upload="saveMedia"
          />
        </Form>
      </DialogBody>

      <DialogFooter>
        <VButton
          :disabled="accepted"
          :is-loading="accepted"
          data-cy="btn-additional-task-create"
          size="sm"
          variant="primary"
          @click="saveTask"
        >
          <template v-if="!modelValue">
            {{ $t('siteReports.tasks.createTask') }}
          </template>
          <template v-else>
            {{ $t('general.button.saveChanges') }}
          </template>
        </VButton>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
