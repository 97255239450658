<script lang="ts" setup>
import type { HTMLAttributes } from 'vue';
import { cn } from '@/helpers/classNames';

defineOptions({
  name: 'DialogBody',
});

const props = withDefaults(defineProps<{
  class?: HTMLAttributes['class']
  scroll?: boolean
}>(), {
  class: '',
  scroll: false,
});

const isBodyScrollable = ref(false);

onMounted(() => {
  const currentInstance = getCurrentInstance();

  // Gets DialogContent prop bodyScrollable and forces DialogBody scroll
  isBodyScrollable.value = !!currentInstance?.parent?.attrs?.bodyScrollable || false;
});
</script>

<template>
  <div
    :class="cn(
      'ui-dialog-body',
      { 'ui-dialog-body-scroll': scroll || isBodyScrollable },
      props.class)"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.ui-dialog-body-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
