import type { App } from 'vue';

import { Menu, Tooltip, vClosePopper, vTooltip } from 'floating-vue';

export default {
  install: (Vue: App) => {
    Vue.directive('tooltip', vTooltip);
    Vue.directive('close-popper', vClosePopper);

    Vue.component('VTooltip', Tooltip);
    Vue.component('VMenu', Menu);
  },
};
