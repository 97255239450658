export default {
  label: 'Vulnerabilidades',
  tabTitle:
    'Tu página tiene <span class="text-dark">{num} vulnerabilidad</span> que pueden poner en riesgo la seguridad del sitio. | Tu página tiene <span class="text-dark">{num} vulnerabilidades</span> que pueden poner en riesgo la seguridad del sitio.',
  noData: 'Estamos comprobando las vulnerabilidades del sitio. Esto puede tardar unos minutos.',
  severityShort: {
    high: 'Alta',
    medium: 'Media',
    low: 'Baja',
    unknown: 'Desconocida',
  },
  severity: {
    high: 'Vulnerabilidad Alta',
  },
  tooltip: {
    view: 'Ver vulnerabilidades',
  },
  fields: {
    severity: 'Gravedad',
    affectedVersion: 'Versión afectada',
    fixedVersion: 'Versión en que se soluciona',
    globalScore: 'Puntuación global',
    exploitable: 'Explotabilidad',
    impact: 'Impacto',
    moreInformation: 'Más información',
    moreInformationAlt: 'Ver más información sobre la vulnerabilidad',
  },
  health: {
    unFixed: 'solución no disponible',
    fixedIn: 'solucionado en {version}',
  },
  tabs: {
    plugin: 'Plugins',
    core: 'WordPress',
    theme: 'Temas',
    php: 'Versión de PHP',
    nginx: 'Versión de Nginx',
    apache: 'Versión de Apache',
  },
  criticalTabs: {
    plugin: 'Tienes {num} vulnerabilidad en los Plugins | Tienes {num} vulnerabilidades en los Plugins',
    core: 'Tienes {num} vulnerabilidad en WordPress | Tienes {num} vulnerabilidades en WordPress',
    theme: 'Tienes {num} vulnerabilidad en los Temas | Tienes {num} vulnerabilidades en los Temas',
    php: 'Tienes {num} vulnerabilidad de PHP | Tienes {num} vulnerabilidades de PHP',
    nginx: 'Tienes {num} vulnerabilidad en Nginx | Tienes {num} vulnerabilidades en Nginx',
    apache: 'Tienes {num} vulnerabilidad en Apache | Tienes {num} vulnerabilidades en Apache',
  },
};
