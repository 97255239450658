import useAppStore from '@/stores/appStore';

import { storeToRefs } from 'pinia';
import useUserStore from '@/stores/userStore';

/**
 * Composable function for determining user capabilities based on permissions.
 *
 * This function utilizes the appStore to fetch the logged-in user permissions.
 *
 * @returns An object containing two properties:
 * - can: A function that takes a permission string and returns a boolean indicating if the user has that permission.
 *
 * @example
 * const { can } = usePermissions();
 * if (can('edit')) {
 *   // Do something that requires edit permission
 * }
 */
function usePermissions() {
  const userStore = useUserStore();
  const permissions = computed(() => userStore.permissions);

  const can = (permission: string) =>
    permissions.value.includes(permission);

  return {
    can,
  };
}

export default usePermissions;
