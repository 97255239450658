import type { RouteRecordRaw } from 'vue-router';

import VAuthLayout from '@/layouts/VAuthLayout.vue';
import TheRegisterAppSumo from '@/views/auth/TheRegisterAppSumo.vue';

const TheRegister = () => import('@/views/auth/TheRegister.vue');
const TheLogin = () => import('@/views/auth/TheLogin.vue');
const TheResetPassword = () => import('@/views/auth/TheResetPassword.vue');
const TheForgotPassword = () => import('@/views/auth/TheForgotPassword.vue');

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login',
    component: VAuthLayout,
    meta: {
      requiresAuth: false,
      transition: 'fade-in',
      theme: 'light',
    },
    children: [
      {
        path: '/register-appsumo',
        name: 'register-appsumo',
        component: TheRegisterAppSumo,
        meta: {
          requiresAuth: false,
          typeLayout: 'container-split',
        },
      },
      {
        path: '/login',
        name: 'login',
        component: TheLogin,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'register',
        name: 'register',
        component: TheRegister,
        meta: {
          requiresAuth: false,
          typeLayout: 'container-split',
        },
      },
      {
        path: 'forgot-password',
        name: 'forgot.password',
        component: TheForgotPassword,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'reset-password/:token',
        name: 'reset-password',
        component: TheResetPassword,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];

export default authRoutes;

export { authRoutes };
