<script lang="ts" setup>
import { ContextMenuItem, type ContextMenuItemEmits, type ContextMenuItemProps, useForwardPropsEmits } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<
  ContextMenuItemProps & { class?: HTMLAttributes['class'], inset?: boolean, variant?: '' | 'danger' }
>();
const emits = defineEmits<ContextMenuItemEmits>();

const delegatedProps = computed(() => {
  const { class: _, variant, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <ContextMenuItem
    class="ui-menu-item position-relative d-flex align-items-center rounded-xs fw-medium px-16 text-sm"
    :class="[
      {
        'ps-36': inset,
        'ui-menu-item--danger text-danger-dark': props.variant === 'danger',
      },
      props.class,
    ]"
    v-bind="forwarded"
  >
    <slot />
  </ContextMenuItem>
</template>
