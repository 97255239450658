<script lang="ts" setup>
import DateHelper from '@/helpers/dateHelper';

import { DateTime } from 'luxon';
import { onMounted, onUnmounted, ref } from 'vue';

defineOptions({
  name: 'TheCountdown',
});

const props = defineProps<{
  enabled: boolean
  specialDates: DatesPropType
}>();

defineEmits(['openModal']);

interface DatesPropType {
  start: DateTime
  end: DateTime
}

let currentTime = DateTime.now();
const timeCounter = ref<string | number>('');

let interval: number | undefined;

function updateTime() {
  currentTime = DateTime.now();
  const endTime = props.specialDates.end;

  timeCounter.value = DateHelper.durationForHumans(currentTime.toString(), endTime, false, true, true);
}

onMounted(() => {
  updateTime();
  interval = window.setInterval(updateTime, 1000);
});

onUnmounted(() => {
  if (interval)
    clearInterval(interval);
});
</script>

<template>
  <div
    v-if="enabled"
    id="layout-alert"
    class="alert alert-warning d-flex justify-content-center align-items-center rounded-0 p-8"
  >
    <span
      class="fw-bold text-nowrap text-sm"
      v-html="
        $t('general.countdown.countdownMode', {
          time: timeCounter,
        })
      "
    />

    <VButton
      class="ms-24"
      size="2xs"
      variant="pink"
      @click="$emit('openModal')"
    >
      {{ $t('general.countdown.moreInfo') }}
    </VButton>
  </div>
</template>

<style lang="scss">
#layout-alert .time {
  display: inline-block;
}
</style>
