export default {
  shared: {
    email: 'Email',
    emailLong: 'Correo electrónico',
    password: 'Contraseña',
    confirmPassword: 'Confirma la contraseña',
    name: 'Nombre',
    lastName: 'Apellidos',
  },
  forgot: {
    receiveEmail: 'Recibirás un email para recuperar tu contraseña en esta dirección.',
    receiveEmailSpam: 'Si no lo recibes, puede que esté en la carpeta de spam.',
    email: 'Introduce tu correo',
    backLogin: 'Volver a iniciar sesión',
    recover: 'Recuperar',
    resend: 'Volver a enviar',
    emailSent: 'Te acabamos de enviar el correo de recuperación. Revisa tu carpeta de spam.',
    emailReSent: 'Te acabamos de reenviar el correo de recuperación. Revisa tu carpeta de spam.',
    emailSentToast: 'Correo enviado correctamente.',
  },
  login: {
    googleLogin: 'Continuar con Google',
    googleSignUp: 'Continuar con Google',
    registerEmail: 'Regístrate con tu email',
    haveAccount: 'Ya tengo una cuenta',
    loggingIn: 'Iniciando sesión...',
    redirect: 'Redirigiendo...',
    userAlreadyExists:
      'Por favor, inicia sesión con tus credenciales registradas o configura el inicio de sesión social en tu cuenta.',
    confirm2faTitle: 'Confirmar el acceso a tu cuenta',
    confirm2faText: 'Confirma el acceso a tu cuenta con el código de 6 dígitos de su aplicación de autenticación',
    confirm2faDevice: '¿No tienes a mano tu móvil?',
    confirm2faDeviceId: 'ID de Modular',
    confirm2faDeviceWhatIsRecoveryCode: '¿Qué es el código de recuperación?',
    confirm2faDeviceWhatIsRecoveryCodeLink: 'https://soporte.modulards.com/es/codigo-recuperacion-2fa',
    unexpectedError: 'Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo más tarde.',
  },
  loginEmail: {
    logIn: 'Iniciar sesión',
    logInWithEmail: 'o inicia sesión con tu email',
    remember: 'Recuérdame',
    forgot: 'He olvidado mi contraseña',
    register: 'Regístrate gratis',
  },
  register: {
    signUp: 'Darse de alta',
    createAccount: 'Crear cuenta',
    alreadyAccount: '¿Ya tienes cuenta? Inicia sesión',
    passwordValidation:
      'Debe ser de al menos 8 carácteres, uno en mayúsculas, uno en minúsculas, un número y un carácter especial',
    wpRepository: 'En el repositorio de WordPress',
    manageAllWebsites: 'Gestiona todas tus webs de WordPress desde un único lugar',
    subtitle: '+2.000 agencias y profesionales ya confían en Modular',
    automateTasks: 'Automatiza las tareas de mantenimiento de tus webs',
    loyalty: 'Fideliza a tus clientes',
    moreContracts: 'Consigue más contratos de mantenimiento',
    termsAndConditions: 'Términos y condiciones',
    passwordDescription:
      'La contraseña debe ser de al menos 8 carácteres, uno en mayúsculas, uno en minúsculas, un número y un carácter especial.',
    termsAndConditionsText:
      '<span>He leído y acepto los</span> <a class="text-decoration-underline text-xs fw-medium text-gray-500" href="{termsAndConditionsLink}" target="_blank" aria-label="Términos y condiciones">términos y condiciones</a>, el contrato de <a class="text-decoration-underline text-xs fw-medium text-gray-500" href="{dataProcessingLink}" target="_blank" aria-label="Tratamiento de datos">tratamiento de datos</a> y la <a class="text-decoration-underline text-xs fw-medium text-gray-500" href="{privacyPolicyLink}" target="_blank" aria-label="Política de privacidad">política de privacidad</a>.',
    termsConditionsValidation: 'Debes aceptar los términos y condiciones para continuar',
  },
  reset: {
    insertNewPassword: 'Introduce la nueva contraseña',
    createNewPassword: 'Crear nueva contraseña',
    newPassword: 'Nueva contraseña',
    confirmNewPassword: 'Confirma la nueva contraseña',
    congratulations: '¡Felicidades!',
    successfulRecover:
      'Tu contraseña ha sido restablecida de forma satisfactoria. Ya puedes volver a iniciar sesión e introducir tus nuevos credenciales.',
  },
  logout: {
    title: 'Cerrar todas las sesiones',
    text: 'Al confirmar, se cerrará sesión de todos tus dispositivos y como medida de seguridad desactivaremos tu contraseña. Recibirás un correo para poder restablecerla.',
    errorMessageTitle: 'El enlace ya no es válido',
    errorMessageText: 'Puede que haya caducado o que ya se haya utilizado una vez.',
  },
};
