import type { LighthouseReport } from '@/types/models/site/service/performance/LighthouseReport';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class PerformanceService extends AbstractService {
  /**
   * Retrieves the site CVW Report from current site
   * @param {number} site
   * @returns {Promise<LighthouseReport>}
   */
  public retrieve(site: number): Promise<LighthouseReport> {
    return this.request({
      method: apiEndpoints.performanceServicesShow.method,
      url: this.buildPath(apiEndpoints.performanceServicesShow, {
        site,
      }),
      options: {
        cancellable: true,
        cancelKey: `performanceServiceSite-${site}`,
      },
    });
  }
}
