class TrackerHelper {
  private tracker: any;

  constructor() {
    this.tracker = {
      $screen_width: this.getBrowserSize().width,
      $screen_height: this.getBrowserSize().height,
    };
  }

  /**
   * Returns user browser dimensions
   */
  getBrowserSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    return {
      width,
      height,
    };
  }

  /**
   * Returns string with the attributes of this class used in the headers
   */
  getTracker() {
    return { 'User-Agent-Tracker': JSON.stringify(this.tracker) };
  }
}

export default new TrackerHelper();
