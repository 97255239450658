<script setup lang="ts">
import { PopoverTrigger, type PopoverTriggerProps } from 'radix-vue';

const props = defineProps<PopoverTriggerProps & { disabled?: boolean }>();
</script>

<template>
  <PopoverTrigger v-bind="props" class="ui-popover-trigger" :disabled="disabled">
    <slot />
  </PopoverTrigger>
</template>

<style lang="scss" scoped>
.ui-popover-trigger {
  :deep(.icon) {
    transition: transform 0.2s ease-out;

    &.open {
      transform: rotateX(180deg);
    }
  }

  &[disabled="true"] {
    cursor: default;
  }
}
</style>
