<script lang="ts" setup>
import type { Tag } from '@/types/models/organization/tag/Tag';
import api from '@/api';
import { TagsInputItem } from '@/components/ui/tags-input';
import { PermissionEnumConst } from '@/types/PermissionEnum';
import { computed, ref } from 'vue';

defineOptions({
  name: 'VTagsCombobox',
});

const props = withDefaults(
  defineProps<{
    location: '' | 'Dashboard' | 'My sites' | 'Team page' | 'Overview' | 'Settings page' | 'Contextual menu' | 'Tags shortcut' | 'Manager' | 'Alerting' | 'Manager installation'
    type?: 'tags' | 'filter' | 'dots'
    syncTag?: boolean
    class?: any
    emitChangedOnClose?: boolean
    create?: boolean
    edit?: boolean
    disabled?: boolean
  }>(),
  {
    type: 'tags',
    syncTag: false,
    class: '',
    emitChangedOnClose: false,
    create: true,
    edit: true,
    disabled: false,
  },
);

const emit = defineEmits(['selected', 'changedTags', 'tagRemoved']);

const { TAGS_INDEX } = PermissionEnumConst;

const modelValue = defineModel<Tag[]>();
const openPopover = defineModel<boolean>('open', {
  default: false,
});

const searchTerm = ref('');

const someTagSelected = computed(() => {
  return !!modelValue.value && modelValue.value?.length > 0;
});

const anyTagHasChanged = ref(false);

function handleEmitChangedTagsOnClose(forceClose = false) {
  if (anyTagHasChanged.value) {
    emit('changedTags', modelValue.value);

    anyTagHasChanged.value = false;

    if (props.type === 'filter') {
      api.mixpanel.track('Filter by tag', {
        Location: props.location || '',
      });
    }
  }

  if (forceClose) {
    openPopover.value = false;
  }
}

function handleEmitChangedTags(track = true, hasAddedTag = false) {
  if (props.emitChangedOnClose) {
    anyTagHasChanged.value = true;

    return;
  }

  emit('changedTags', modelValue.value);

  if (track) {
    api.mixpanel.track('Change tag', {
      Location: props.location || '',
      Type: hasAddedTag ? 'Add' : 'Remove',
    });
  }
}

function handleClose(open: boolean) {
  if (!open) {
    handleEmitChangedTagsOnClose();
  }
}

function handleTagRemove(tag: Tag) {
  emit('tagRemoved', tag);

  handleEmitChangedTags(false);
}

/**
 * Avoid duplicates on site selector
 *
 * @param value
 */
function updateModel(value: Tag[]) {
  if (!value)
    return;

  const ids = new Set();

  const isDuplicated = value.some(tag => ids.size === ids.add(tag.id).size);

  if (isDuplicated) {
    return;
  }

  modelValue.value = value;
}

function limitTagName(name: string) {
  return name.length > 20 ? `${name.slice(0, 20)}...` : name;
}

function isDarkColor(hex: string) {
  const r = Number.parseInt(hex.substring(1, 3), 16);
  const g = Number.parseInt(hex.substring(3, 5), 16);
  const b = Number.parseInt(hex.substring(5, 7), 16);

  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return brightness > 128;
}

const firstDot = computed(() => Array.isArray(modelValue.value) ? modelValue.value[0] : modelValue.value);

const secondDot = computed(() => {
  if (Array.isArray(modelValue.value)) {
    if (modelValue.value.length > 2) {
      return { color: 'var(--md-primary)', name: modelValue.value.length - 1, id: null };
    } else {
      return modelValue.value[1];
    }
  } else {
    return null;
  }
});

function handleUpdateOpen(open: boolean) {
  if (!props.disabled) {
    openPopover.value = open;
    handleClose(open)
  } else {
    openPopover.value = false;
  }
}
</script>

<template>
  <Popover :open="openPopover" @update:open="handleUpdateOpen">
    <PopoverTrigger
      id="md-tag-combobox-trigger"
      v-can="TAGS_INDEX"
      as-child
      :disabled="disabled"
      :class="{ disabled }"
    >
      <div v-if="type === 'tags' || type === 'filter'" :class="props.class">
        <TagsInput
          v-if="type === 'tags'"
          :display-value="(value: Tag) => limitTagName(value.name)"
        >
          <div v-auto-animate="{ duration: 100 }" class="d-flex flex-wrap gap-8 position-relative">
            <div
              :aria-expanded="openPopover"
              class="btn btn-outline-gray btn-2xs text-nowrap me-16 rounded-xs btn-tag d-flex align-items-center mb-4"
              style="height: 24px"
            >
              <m-icon class="btn-tag me-4" icon="actions-plus" />

              {{ $t('tags.tag') }}
            </div>

            <TagsInputItem
              v-for="item in modelValue"
              :key="item.id"
              v-tooltip.bottom="{
                content: item?.name,
                html: true,
                disabled: item?.name.length <= 20,
              }"
              :value="item"
              as-child
              class="mb-4"
              style="height: 24px"
            >
              <span
                :style="{ 'background-color': !!item && item?.color, 'color': !!item && isDarkColor(item.color) ? 'black' : 'white' }"
                class="btn-tag"
              >
                <TagsInputItemText class="text-nowrap" />
              </span>
            </TagsInputItem>
          </div>
        </TagsInput>

        <VButton
          v-else-if="type === 'filter'"
          :class="{ active: someTagSelected, disabled }"
          class="text-nowrap rounded-xs"
          size="2xs"
          :disabled="disabled"
          variant="combobox-select"
        >
          <span class="d-flex align-items-center gap-16">
            {{ $t('tags.tags') }}

            <v-icon :class="{ open: openPopover }" icon="arrow-caret-down" />
          </span>
        </VButton>
      </div>

      <VButton
        v-else-if="type === 'dots'"
        :class="{ 'md-multiple-dots': !!secondDot, 'position-absolute': !!modelValue && modelValue.length === 0 }"
        class="btn btn-transparent p-0 me-4 z-2"
        size="2xs"
      >
        <span v-if="!!modelValue && modelValue.length > 0" class="d-flex align-items-center">
          <span
            :class="{ 'md-one-dot': !secondDot }"
            :style="`background-color: ${firstDot?.color}`"
            class="md-dot d-inline-block"
          />
          <span
            v-if="!!secondDot"
            :style="`background-color: ${secondDot?.color}`"
            class="md-dot md-second-dot d-flex align-items-center justify-content-center text-light position-relative"
          >
            <span v-if="!secondDot.id" class="position-absolute md-second-dot-text fw-bold">{{ secondDot.name }}
            </span>
          </span>
        </span>
      </VButton>
    </PopoverTrigger>

    <PopoverContent
      :prioritize-position="type === 'dots'"
      :side="type === 'dots' ? 'bottom' : undefined"
      align="start"
      class="shadow-sm px-8 pt-24 pb-12"
      update-position-strategy="optimized"
    >
      <VTagsComboboxContent
        v-model:open="openPopover"
        v-model:search-term="searchTerm"
        :create="create"
        :disable-apply="!anyTagHasChanged"
        :edit="edit"
        :location="location"
        :model-value="modelValue"
        :sync-tag="syncTag"
        :type="['tags', 'dots'].includes(type) ? 'tags' : 'filter'"
        @selected="$emit('selected', $event)"
        @apply-filter="handleEmitChangedTagsOnClose(true)"
        @tag-removed="handleTagRemove($event)"
        @changed-tags="handleEmitChangedTags(true, $event)"
        @update:model-value="updateModel"
      />
    </PopoverContent>
  </Popover>
</template>

<style lang="scss">
.btn-tag {
  line-height: 16px !important;
}

#md-tag-combobox-trigger.md-multiple-dots {
  transition: transform .2s;

  &:hover {
    box-shadow: 0 0 0 0.125rem var(--md-gray-300);
    transform: scale(1.15);
  }
}

.md-dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.2s;

  &.md-one-dot:hover {
    box-shadow: 0 0 0 0.125rem var(--md-gray-300);
    transform: scale(1.15);
  }

  &.md-second-dot {
    margin-left: -0.25rem;
    outline: 0.125rem solid var(--md-light);

    .md-second-dot-text {
      font-size: 0.5625rem;
    }
  }
}
</style>
