<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
  name: 'VIcon',
});

const props = withDefaults(
  defineProps<{
    icon?: IconTypes | null
    size?: FontSizes
    space?: string
  }>(),
  {
    icon: 'modular-icon',
    size: '',
    space: '',
  },
);

export type IconTypes =
  | 'arrow-caret-down'
  | 'arrow-going-up'
  | 'bullets'
  | 'copy'
  | 'database'
  | 'chart-bar'
  | 'cup'
  | 'chart-pie'
  | 'fast-gage'
  | 'hashtag'
  | 'card'
  | 'builder'
  | 'feedlist'
  | 'neutral-emoji'
  | 'smile-emoji'
  | 'bell'
  | 'clock'
  | 'image'
  | 'logout'
  | 'help'
  | 'long-arrow'
  | 'pencil'
  | 'heart'
  | 'play'
  | 'at-sign'
  | 'attach'
  | 'calendar'
  | 'call'
  | 'check'
  | 'chevron-left'
  | 'chevron-down'
  | 'chevron-right'
  | 'chevron-up'
  | 'circle-actions-alert-info'
  | 'circle-actions-alert-question'
  | 'circle-actions-close'
  | 'circle-actions-success'
  | 'close-x'
  | 'comment'
  | 'disabled'
  | 'facebook'
  | 'file'
  | 'flag-bannor'
  | 'feed-grid'
  | 'hamburger'
  | 'home'
  | 'instagram'
  | 'link'
  | 'linkedin'
  | 'location'
  | 'mail'
  | 'pinterest'
  | 'plus'
  | 'profile'
  | 'search'
  | 'shield'
  | 'star'
  | 'trash'
  | 'twitter'
  | 'arrow-back'
  | 'arrow-down'
  | 'arrow-reply'
  | 'arrow-right'
  | 'arrow-select'
  | 'arrow-up'
  | 'quotes'
  | 'blob-mask'
  | 'download'
  | 'eye'
  | 'device-desktop'
  | 'device-phone'
  | 'dumbbell'
  | 'feed-list'
  | 'blob-3'
  | 'blob-2'
  | 'union'
  | 'settings'
  | 'updates'
  | 'chip'
  | 'connect'
  | 'box'
  | 'bold'
  | 'footprint'
  | 'configuration'
  | 'pointer'
  | 'pause'
  | 'refresh'
  | 'plus-slim'
  | 'modular-icon'
  | 'connection'
  | 'whatsapp'
  | 'question'
  | 'log-in-circle'
  | 'wordpress'
  | 'world-net'
  | 'youtube'
  | 'star-fill'
  | 'pdf'
  | 'minus'
  | 'filled-alert-info'
  | 'sad-emoji'
  | 'email-closed'
  | 'charging-station'
  | 'notifications'
  | 'email-send-status'
  | 'bullhorn'
  | 'weather-stars'
  | 'power-on'
  | 'power-off'
  | 'php'
  | 'upload'
  | 'lightbulb';

export type FontSizes = '' | '4xs' | '3xs' | '2xs' | 'xxs' | 'xs' | 'sm' | 'md' | 'normal' | 'lg' | 'xl' | 'xxl';

const classes = computed(() => {
  let oClass = '';
  oClass += !!props.icon ? `icon-${props.icon}` : '';
  oClass += !!props.size ? ` text-${props.size}` : '';
  oClass += !!props.space ? ` ${props.space}` : '';
  return oClass;
});
</script>

<template>
  <span :class="classes" class="icon" />
</template>
