<script lang="ts" setup>
import type { SiteManagerActionEnumType } from '@/types/enums/SiteManagerActionEnum';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';

import useAppStore from '@/stores/appStore';
import useManagerStore from '@/stores/manager/managerStore';

import { SiteItemTypeEnumConst } from '@/types/enums/SiteItemTypeEnum';
import { SiteManagerActionEnumConst } from '@/types/enums/SiteManagerActionEnum';
import { storeToRefs } from 'pinia';

import { computed, ref } from 'vue';

defineOptions({
  name: 'TheManagerActions',
});

defineEmits(['selectAll']);

const { ACTIVATE, DEACTIVATE, DELETE, UPGRADE, HIDE, SHOW } = SiteManagerActionEnumConst;
const { PLUGIN, THEME } = SiteItemTypeEnumConst;

const managerStore = useManagerStore();
const {
  type,
  selectedCount,
  visibilityStatus,
  loadedComponents,
  syncSitesRequested,
  isGlobal,
  allowedActions,
} = storeToRefs(managerStore);

const openActiveThemeDialog = ref(false);
const openDeleteItemDialog = ref(false);

async function manageAction(action: SiteManagerActionEnumType) {
  try {
    await managerStore.manageAction(action);
  } catch (e: any) {
    console.error(e);
  }
}

const appStore = useAppStore();
const darkMode = computed(() => appStore.darkMode);
</script>

<template>
  <div
    class="manager-actions d-flex justify-content-between align-items-center px-lg-32 ps-xxl-48 pe-xxl-72 h-100 border px-24 py-8"
    :class="{ 'text-bg-dark': !darkMode, 'border-gray-100 bg-gray-300': darkMode }"
  >
    <div
      :class="{
        'flex-column flex-xl-row align-items-start align-items-xl-center gap-16 py-16': isGlobal,
        'flex-column py-4': !isGlobal,
      }"
      class="d-flex h-100"
    >
      <span class="fw-medium text-xs" :class="!isGlobal ? 'mb-2' : ''">
        {{ $t('manager.selectedItems', { count: selectedCount }, selectedCount) }}
      </span>

      <div
        class="d-flex flex-column"
        :class="{
          'flex-xxl-row align-items-start align-items-xxl-stretch': selectedCount > 0 && !isGlobal,
          'flex-lg-row align-items-start align-items-lg-stretch': selectedCount > 0 && isGlobal,
          'flex-md-row': selectedCount === 0,
        }"
      >
        <VButton
          :aria-label="$t('manager.selectAll')"
          :disabled="!loadedComponents || syncSitesRequested"
          class="border-0 px-0 py-2"
          :class="{ 'mb-4 mb-xxl-0': selectedCount > 0 && !isGlobal }"
          size="sm"
          variant="link-primary-200"
          @click="$emit('selectAll', true)"
        >
          {{ $t('manager.selectAll') }}
        </VButton>

        <v-separator
          class="h-100 mx-8"
          :class="{ 'd-none d-lg-block': selectedCount > 0 }"
          background="bg-gray-600"
          decorative
          orientation="vertical"
        />

        <VButton
          :aria-label="$t('manager.deselect')"
          :disabled="selectedCount === 0 || syncSitesRequested"
          class="border-0 px-0 py-2"
          size="sm"
          variant="link-primary-200"
          @click="$emit('selectAll', false)"
        >
          {{ $t('manager.deselect') }}
        </VButton>
      </div>
    </div>

    <div v-if="selectedCount > 0" class="d-flex align-items-center flex-row gap-8">
      <Tooltip
        v-if="type === PLUGIN || type === THEME"
        :content="
          !allowedActions.connectorNotUpdated
            ? $t('general.button.delete')
            : isGlobal
              ? $t('manager.connectorNotUpdatedShort')
              : $t('manager.connectorNotUpdatedSiteShort')
        "
      >
        <VButton
          :disabled="!allowedActions.delete || allowedActions.connectorNotUpdated"
          :aria-label="$t('general.button.delete')"
          class="btn-icon btn-outline-danger border-1 py-12"
          size="xs"
          variant="outline-danger-medium"
          @click="openDeleteItemDialog = true"
        >
          <m-icon icon="delete" size="lg" />
        </VButton>
      </Tooltip>

      <Tooltip
        v-if="type === PLUGIN"
        :content="
          !allowedActions.connectorNotUpdated
            ? $t('manager.deactivateUpdates')
            : isGlobal
              ? $t('manager.connectorNotUpdatedShort')
              : $t('manager.connectorNotUpdatedSiteShort')
        "
      >
        <VButton
          :disabled="!allowedActions.deactivate || allowedActions.connectorNotUpdated"
          :aria-label="$t('manager.deactivateUpdates')"
          class="btn-icon btn-outline-primary border-1 py-12"
          size="xs"
          variant="outline-primary-200"
          @click="manageAction(DEACTIVATE)"
        >
          <m-icon icon="power-off" size="lg" />
        </VButton>
      </Tooltip>

      <Tooltip
        v-if="type === PLUGIN || type === THEME"
        :content="
          !allowedActions.connectorNotUpdated
            ? $t('manager.activateUpdates')
            : isGlobal
              ? $t('manager.connectorNotUpdatedShort')
              : $t('manager.connectorNotUpdatedSiteShort')
        "
      >
        <VButton
          :disabled="!allowedActions.activate || allowedActions.connectorNotUpdated"
          :aria-label="$t('manager.activateUpdates')"
          class="btn-icon btn-outline-primary border-1 py-12"
          size="xs"
          variant="outline-primary-200"
          @click="type === THEME ? (openActiveThemeDialog = true) : manageAction(ACTIVATE)"
        >
          <m-icon icon="power-on" size="lg" />
        </VButton>
      </Tooltip>

      <Tooltip v-if="visibilityStatus !== 'hidden'" :content="$t('manager.hideUpdates')">
        <VButton
          :disabled="!allowedActions.hide"
          :aria-label="$t('manager.hideUpdates')"
          class="btn-icon btn-outline-primary border-1 py-12"
          size="xs"
          variant="outline-primary-200"
          @click="manageAction(HIDE)"
        >
          <m-icon icon="hide" size="lg" />
        </VButton>
      </Tooltip>

      <Tooltip v-if="visibilityStatus !== 'visible'" :content="$t('manager.showUpdates')">
        <VButton
          :disabled="!allowedActions.show"
          :aria-label="$t('manager.showUpdates')"
          class="btn-icon btn-outline-primary border-1 py-12"
          size="xs"
          variant="outline-primary-200"
          @click="manageAction(SHOW)"
        >
          <m-icon icon="view" size="lg" />
        </VButton>
      </Tooltip>

      <Tooltip :content="$t('manager.updateType', { type: $t(`manager.${type}`, 2) })">
        <VButton
          :disabled="!allowedActions.upgrade"
          :aria-label="$t('manager.updateType', { type: $t(`manager.${type}`, 2) })"
          class="btn-icon h-100 border-1 py-12"
          size="xs"
          variant="primary"
          @click="manageAction(UPGRADE)"
        >
          <m-icon icon="refresh" size="md" class="fw-bold" />
        </VButton>
      </Tooltip>
    </div>

    <Dialog v-model:open="openActiveThemeDialog">
      <DialogContent id="manager-activate-theme" size="lg">
        <DialogHeader>
          <DialogTitle>
            <div class="d-flex align-items-center">
              {{ $t('manager.activateTheme', selectedCount) }}
            </div>
          </DialogTitle>
        </DialogHeader>

        <DialogBody>
          <VAlert variant="tiger">
            <p class="fw-semi mb-0">
              {{ $t('manager.activateThemeModalTitle') }}
            </p>

            <p class="mb-4">
              <template v-if="selectedCount === 1">
                {{ $t('manager.activateThemeModalTextOneSite') }}
              </template>

              <template v-else>
                {{ $t('manager.activateThemeModalText') }}
              </template>
            </p>
          </VAlert>
        </DialogBody>

        <DialogFooter>
          <VButton variant="outline-danger" @click="openActiveThemeDialog = false">
            {{ $t('general.button.cancel') }}
          </VButton>

          <VButton
            variant="primary"
            @click="
              openActiveThemeDialog = false;
              manageAction(ACTIVATE);
            "
          >
            {{ $t('manager.activateTheme', selectedCount) }}
          </VButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>

    <Dialog v-model:open="openDeleteItemDialog">
      <DialogContent id="manager-delete-theme" size="lg">
        <DialogHeader>
          <DialogTitle>
            <div class="d-flex align-items-center">
              <template v-if="type === PLUGIN">
                {{ $t('manager.deletePlugin', selectedCount) }}
              </template>
              <template v-else>
                {{ $t('manager.deleteTheme', selectedCount) }}
              </template>
            </div>
          </DialogTitle>
        </DialogHeader>

        <DialogBody>
          <VAlert variant="tiger">
            <p class="fw-semi mb-0">
              {{ $t('manager.activateThemeModalTitle') }}
            </p>

            <p class="mb-4">
              <template v-if="type === PLUGIN">
                {{ $t('manager.deletePluginModalText', selectedCount) }}
              </template>
              <template v-else>
                {{ $t('manager.deleteThemeModalText', selectedCount) }}
              </template>
            </p>
          </VAlert>
        </DialogBody>

        <DialogFooter>
          <VButton variant="outline-danger" @click="openDeleteItemDialog = false">
            {{ $t('general.button.cancel') }}
          </VButton>

          <VButton
            variant="primary"
            @click="
              openDeleteItemDialog = false;
              manageAction(DELETE);
            "
          >
            {{ type === PLUGIN ? $t('manager.deletePlugin', selectedCount) : $t('manager.deleteTheme', selectedCount) }}
          </VButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>

<style lang="scss" scoped>
.manager-actions {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 10;
  bottom: 1.5rem;
  box-shadow: -26.2px -37.8px 88px 0px rgba(146, 119, 191, 0.16),
  -37.8px 37.8px 37.8px 0px rgba(255, 255, 255, 0.16);

  .h-initial {
    height: initial;
  }
}

[data-bs-theme='dark'] {
  .manager-actions {
    box-shadow: -26.2px -37.8px 88px 0px rgba(0, 0, 0, 0.16),
    -37.8px 37.8px 37.8px 0px rgba(0, 0, 0, 0.16);
  }
}
</style>
