<script lang="ts">
import useAppStore from '@/stores/appStore';

/**
 * @deprecated use CircularProgress component from @components/ui
 */
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'VSiteLighthouseReportPerformanceProgressCircular',
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: 0,
    },
    validReport: {
      type: Boolean,
      required: true,
    },
    withoutColor: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPercentage: {
      type: Boolean,
      required: false,
      default: false,
    },
    transitionDuration: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  setup() {
    const appStore = useAppStore();

    const isDark = computed(() => appStore.darkMode);

    return {
      isDark,
    };
  },
  data() {
    return {
      progressColor: '',
      backgroundColor: '',
      number: 0,
      interval: 0,
      radiusCircle: 22.5,
      strokeCircle: 0,
      strokeCircleOffset: 0,
    };
  },
  computed: {
    computedValue() {
      if (typeof this.value === 'string' && Number.isNaN(Number(this.value))) {
        return Number(this.value);
      }

      return this.value;
    },
  },
  watch: {
    value() {
      if (this.computedValue === this.number) {
        return;
      }

      this.animateProgress();
    },
  },
  mounted() {
    this.computedColor();
    this.animateProgress();
  },
  methods: {
    computedColor() {
      if (this.withoutColor) {
        this.backgroundColor = '#F7F7FC';
        this.progressColor = '#6E7191';
      } else if (this.number >= 90) {
        this.backgroundColor = '#e2faef';
        this.progressColor = '#0acf83';
      } else if (this.number >= 50 && this.number < 90) {
        this.backgroundColor = '#fff6ec';
        this.progressColor = '#ff9100';
      } else if (this.number < 50 && this.number !== 0) {
        this.backgroundColor = '#ffe2e3';
        this.progressColor = '#ff2637';
      } else {
        this.backgroundColor = '#e5e5e5';
        this.progressColor = '#a0a3bd';
      }
    },
    animateProgress() {
      if (this.computedValue !== 0) {
        clearInterval(this.interval);

        if (this.transitionDuration !== 0) {
          this.interval = window.setInterval(() => {
            if (this.number !== this.computedValue) {
              let change = (Number(this.computedValue) - this.number) / 10;

              change = change >= 0 ? Math.ceil(change) : Math.floor(change);
              this.number += change;

              this.strokeCircle = 2 * Math.PI * this.radiusCircle;
              this.strokeCircleOffset = this.strokeCircle * ((100 - this.number) / 100);

              this.computedColor();
            }
          }, this.transitionDuration);
        } else {
          this.number = Number(this.computedValue);

          this.strokeCircle = 2 * Math.PI * this.radiusCircle;
          this.strokeCircleOffset = this.strokeCircle * ((100 - this.number) / 100);

          this.computedColor();
        }
      }
    },
  },
});
</script>

<template>
  <div
    :style="{ 'background-color': backgroundColor }"
    class="circular position-relative d-flex align-items-center justify-content-center rounded-pill"
  >
    <div>
      <div
        v-if="validReport"
        :style="{ color: progressColor }"
        class="circular-content position-absolute top-50 start-50 translate-middle fw-medium text-nowrap text-sm"
      >
        <template v-if="value === false">
          -
        </template>

        <template v-else>
          {{ number }}
        </template>

        <template v-if="isPercentage">
          %
        </template>
      </div>

      <div
        v-else
        :style="{ color: progressColor }"
        class="circular-content position-absolute top-50 start-50 translate-middle fw-medium text-nowrap text-sm"
      >
        --
      </div>

      <svg class="cvw-svg" height="48" viewBox="0 0 48 48" width="48">
        <circle
          v-if="isDark"
          :r="22.5"
          stroke="#4E4B66"
          stroke-dasharray="360"
          stroke-dashoffset="91.89158511750145"
          cx="24"
          cy="24"
          fill="none"
          stroke-width="3"
        />
        <circle
          :r="radiusCircle"
          :stroke="progressColor"
          :stroke-dasharray="strokeCircle"
          :stroke-dashoffset="strokeCircleOffset"
          cx="24"
          cy="24"
          fill="none"
          stroke-width="3"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.circular {
  min-width: 48px;
  height: 48px;
  transition:
    background-color 0.3s ease-out,
    color 0.3s ease-out;

  .cvw-svg {
    transform: rotate(270deg);
  }
}

[data-bs-theme='dark'] {
  .circular {
    background-color: transparent !important;
  }
}
</style>
