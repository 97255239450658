<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VInputText',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    labelOnTop: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelClass: {
      type: [String, Object, Array],
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    yupErrorsVariable: {
      type: [String, undefined],
      required: false,
      default: null,
    },
    max: {
      type: [String, Number],
      required: false,
      default: null,
    },
    min: {
      type: [String, Number],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'blur', 'input'],
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  computed: {
    computedClasses() {
      return {
        'form-control': !['checkbox', 'radio'].includes(this.type),
        'form-control-sm': this.variant === 'sm',
        'is-invalid': !!this.yupErrorsVariable,
      };
    },
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal;
      }
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
      this.$emit('input', event.target.value);
    },
  },
});
</script>

<template>
  <div class="form-group text-sm">
    <label v-if="labelOnTop && !!label" class="form-label" :class="[labelClass]" :for="id">
      {{ label }}
    </label>

    <input
      :id="id"
      v-model="localValue"
      :autocomplete="autocomplete"
      :class="[classes, computedClasses]"
      :disabled="disabled"
      :max="max"
      :readonly="readonly"
      :min="min"
      :placeholder="placeholder"
      :type="type"
      @blur="$emit('blur')"
      @input="updateValue"
    >

    <label v-if="!labelOnTop && !!label" class="form-label" :class="[labelClass]" :for="id">
      {{ label }}
    </label>
    <span v-if="!!yupErrorsVariable" class="invalid-feedback">
      {{ yupErrorsVariable }}
    </span>
  </div>
</template>
