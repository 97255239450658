<script setup lang="ts">
import { cn } from '@/helpers/classNames';
import { TagsInputItem, type TagsInputItemProps, useForwardProps } from 'radix-vue'

import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<TagsInputItemProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TagsInputItem
    v-bind="forwardedProps"
    :class="cn('ui-tag-input-item d-flex align-items-center rounded-xs py-4 px-8 fw-medium', props.class)"
  >
    <slot />
  </TagsInputItem>
</template>

<style lang="scss">
.ui-tag-input-item {
  white-space: nowrap;
  overflow: hidden;
  min-width: min-content;
}
</style>
