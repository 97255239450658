export default {
  mixed: {
    default: 'No es válido',
    required: ({ label }: MessageParams) => `${!!label ? label : 'El campo'} es obligatorio`,
    oneOf: 'Las contraseñas deben coincidir',
    notType: 'Debes introducir un dato válido',
  },
  number: {
    min: 'El valor debe ser mayor que {min}',
    max: 'El valor debe ser menor que {max}',
  },
  string: {
    min: 'Debes introducir un mínimo de {min} caracteres',
    max: 'Debes introducir un máximo de {max} caracteres',
    email: 'Debes introducir un correo válido',
    password: 'La contraseña introducida es incorrecta',
    url: 'Tienes que añadir una URL válida, por ejemplo, https://www.miweb.com',
  },
};
