export default {
  connectionLost:
    'Se ha perdido la conexión con WP en una de tus webs. | Se ha perdido la conexión con WP en varias de tus webs.',
  goToUpdates: 'Ir al actualizador',
  connectingLoad: 'conectando...',
  connectionSuccess: '¡Conectado!',
  connectionFailed: '¡Oh no! Algo ha fallado',
  connectionFailedDesc: 'Abriendo gestor de conexión...',
  search: {
    noResults: 'Sin resultados, prueba con otra búsqueda',
    typeLeastCharacters: 'Escribe un correo electrónico para continuar',
  },
  gridMode: 'Modo cuadrícula',
  listMode: 'Modo lista',
  siteOptions: 'Opciones de la web',
  backupError: 'Backup incompleto',
  backupErrorText: 'Tu último backup ha fallado',
  reviewBanner: {
    title: '¿Te gusta nuestro trabajo?',
    text: 'Ya llevas un tiempo utilizando Modular... parece que es buena señal, pero ¿nos dejarías una review en el repositorio de WordPress?',
    link: 'https://es.wordpress.org/plugins/modular-connector/#reviews',
  },
  emptyState: {
    default: {
      firstSite: '¡Conecta tu primera web!',
      firstLoginSite: 'Conecta tu primera web de WordPress',
      firstSiteDescription:
        'Sólo te llevará un par de minutos y podrás empezar a aprovechar todas las funcionalidades de Modular para gestionar tu web.',
      firstLoginSiteTitle:
        'Introduce los datos de la web para empezar el proceso de conexión. Puedes conectar cualquier web de WordPress que tengas.',
      connectSite: 'Conectar web',
      howToConnect:
        'Descubre cómo añadir webs a Modular en <a href="https://soporte.modulards.com/es/conectar-web" target="_blank" aria-label="Cómo añadir webs a Modular">este artículo de ayuda</a>.',
    },
    tags: {
      noResultTitle: '¡Ups! No encontramos nada',
      noResultText: 'Parece que no hay ninguna web en tu cuenta de Modular que coincida con los filtros que has aplicado. Intenta con otra combinación.',
    },
  },
};
