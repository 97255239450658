<script setup lang="ts">
import { CollapsibleTrigger, type CollapsibleTriggerProps } from 'radix-vue';

const props = defineProps<CollapsibleTriggerProps>();
</script>

<template>
  <CollapsibleTrigger v-bind="props" class="ui-collapse-trigger">
    <slot />
  </CollapsibleTrigger>
</template>

<style lang="scss" scoped>
.ui-collapse-trigger {
  :deep(.icon),
  :deep(.mi) {
    transition: transform 0.2s ease-out;

    &.open {
      transform: rotateX(180deg);
    }
  }
}
</style>
