<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VRightSidebar',
});
</script>

<template>
  <aside id="right-sidebar" class="row flex-xl-column gx-16 gx-xl-0 flex-row">
    <slot />
  </aside>
</template>
