import { getModalInjectionkey } from '@/plugins/ModalPlugin';

import { ref } from 'vue';

// TODO CHANGE NAME TO useModal
/**
 * Composable function for handling modal-related functionalities.
 *
 * This function leverages a custom Vue plugin, `ModalPlugin`, to manage modal state. It provides utilities
 * for opening and closing modals, as well as for keeping track of the modal currently being displayed.
 *
 * @returns {object} An object containing four properties:
 * - Modal: An object returned by the custom `useModal` function, containing modal control methods.
 * - modalToShow: A ref that stores the name of the modal to be displayed.
 * - closeModal: A function that sets `modalToShow` to an empty string, effectively closing the modal.
 * - openModal: A function that takes a modal name as an argument and sets `modalToShow` to that name,
 * effectively opening the modal.
 *
 * @example
 * const { Modal, modalToShow, closeModal, openModal } = useModalUtils();
 * openModal('myModal');
 * closeModal();
 */
function useModalUtils() {
  const modalToShow = ref('');

  const Modal = getModalInjectionkey();

  const closeModal = () => {
    modalToShow.value = '';
  };

  const openModal = (modal: string) => {
    modalToShow.value = modal;
  };

  return {
    Modal,
    modalToShow,
    closeModal,
    openModal,
  };
}

export default useModalUtils;
