import type { LanguagesType } from '@/helpers/langHelper';

import type { LocaleObject } from 'yup';
import userConfigStorage from '@/helpers/storage/userConfigStorage';
import en from '@/lang/english';
import validationEn from '@/lang/locales/en/validation';
import validationEs from '@/lang/locales/es/validation';
import es from '@/lang/spanish';
import { createI18n } from 'vue-i18n';
import { setLocale } from 'yup';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  allowComposition: true,
  inheritLocale: true,
  locale: import.meta.env.VITE_I18N_LOCALE,
  fallbackLocale: 'en',
  messages: {
    es,
    en,
  },
});

const validationLocale: Record<string, LocaleObject> = {
  es: validationEs,
  en: validationEn,
};

export function getNavigatorLanguage(): string {
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language;
  return !!navigatorLocale ? navigatorLocale.trim().split(/-|_/)[0] : '';
}

function getLanguageFromParams(): string | null {
  const params = new URLSearchParams(window.location.search);

  return params.get('language') || '';
}

export function getAnyValidLanguage(): string {
  const navigatorLocale = getNavigatorLanguage();

  const languageFromParams = getLanguageFromParams();

  const languageFromLocalStorage = userConfigStorage.getConfig()?.language;

  return languageFromParams || languageFromLocalStorage || navigatorLocale || 'en';
}

const firstLang = getAnyValidLanguage();

if (Object.keys(validationLocale).includes(firstLang)) {
  setLocale(validationLocale[firstLang as LanguagesType]);
} else {
  setLocale(validationLocale.en)
}

export default i18n;
