import type { RouteRecordRaw } from 'vue-router';

import TheSiteHeaderContent from '@/components/site/header/TheSiteHeaderContent.vue';
import TheSiteMenu from '@/components/site/menu/TheSiteMenu.vue';
import TheSiteOverviewSidebar from '@/views/site/overview/sidebar/TheSiteOverviewSidebar.vue';

import TheSite from '@/views/site/TheSite.vue';
import TheSiteEdit from '@/views/site/TheSiteEdit.vue';

const TheInstallationManager = () => import('@/views/manager/installation/TheInstallationManager.vue');

const siteRoutes: Array<RouteRecordRaw> = [
  {
    path: 'sites/:site',
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'connect',
        name: 'sites.site.connect',
        props: true,
        components: {
          menu: TheSiteMenu,
          default: () => import('@/views/site/connect/TheSiteConnect.vue'),
          sidebar: TheSiteOverviewSidebar,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '',
        name: 'sites.site.show',
        components: {
          default: TheSite,
          menu: TheSiteMenu,
          sidebar: TheSiteOverviewSidebar,
        },

        meta: {
          requiresAuth: true,
          events: {
            organization: ['.site.items.updated', '.site.items.managed', '.site.items.installed'],
          },
        },
        props: true,
        children: [
          {
            path: 'edit',
            name: 'sites.site.update',
            components: {
              default: TheSiteEdit,
              sidebar: TheSiteOverviewSidebar,
            },
            props: {
              redirectOnClose: true,
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'manager/:type/install',
        name: 'sites.site.manager.component.install',
        components: {
          menu: TheSiteMenu,
          default: TheInstallationManager,
          sidebar: TheSiteOverviewSidebar,
        },
        props: true,
        meta: {
          requiresAuth: true,
          events: {
            organization: ['.site.items.installed'],
          },
        },
      },
      {
        path: '',
        name: 'sites.site.metrics.show',
        redirect: { name: 'sites.site.analytics.show' },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'analytics',
            alias: ['statistics'],
            name: 'sites.site.analytics.show',
            components: {
              default: () => import('@/views/site/metrics/analytics/TheSiteAnalytics.vue'),
              menu: TheSiteMenu,
            },
            props: {
              menu: true,
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'search-console',
            name: 'sites.site.service.search-console.show',
            components: {
              default: () => import('@/views/site/metrics/search-console/TheSiteSearchConsole.vue'),
              menu: TheSiteMenu,
            },
            props: {
              menu: true,
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'uptime',
        name: 'sites.site.uptime.check.show',
        components: {
          default: () => import('@/views/site/uptime/TheSiteUptime.vue'),
          menu: TheSiteMenu,
        },
        props: {
          menu: true,
        },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'sites.site.uptime.check.stats.show',
            props: true,
            components: {
              default: () => import('@/views/site/uptime/parts/TheSiteUptimeActivity.vue'),
              menu: TheSiteMenu,
            },
          },
          {
            path: 'configuration',
            name: 'sites.site.uptime.check.store',
            props: true,
            components: {
              default: () => import('@/views/site/uptime/parts/TheSiteUptimeConfiguration.vue'),
              menu: TheSiteMenu,
            },
          },
        ],
      },
      {
        path: 'backups',
        name: 'sites.site.backup.show',
        components: {
          default: () => import('@/views/site/backup/TheSiteBackups.vue'),
          menu: TheSiteMenu,
        },
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'sites.site.backup.index',
            props: true,
            components: {
              default: () => import('@/views/site/backup/TheSiteBackupsOverview.vue'),
              menu: TheSiteMenu,
            },
            children: [
              {
                path: ':backupId',
                name: 'sites.site.backup.index.show',
                props: true,
                meta: {
                  doNotScroll: true,
                  isModalView: true,
                },
                components: {
                  default: () => import('@/views/site/backup/TheSiteBackupsDetailsModal.vue'),
                  menu: TheSiteMenu,
                },
              },
            ],
          },
          {
            path: 'configuration',
            name: 'sites.site.backup.update',
            props: true,
            components: {
              default: () => import('@/views/site/backup/TheSiteBackupsConfiguration.vue'),
              menu: TheSiteMenu,
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'reports',
        name: 'sites.site.reports.show',
        components: {
          default: () => import('@/views/site/reports/TheSiteReports.vue'),
          menu: TheSiteMenu,
        },
        props: true,
        meta: {
          requiresAuth: true,
        },
        redirect: { name: 'sites.site.report.index' },
        children: [
          {
            path: '',
            name: 'sites.site.report.index',
            props: true,
            components: {
              default: () => import('@/views/site/reports/TheSiteReportOverview.vue'),
              menu: TheSiteMenu,
            },
            children: [
              {
                path: 'report',
                name: 'sites.site.report.show',
                component: () => import('@/views/site/reports/TheSiteReportCreateModal.vue'),
                redirect: { name: 'sites.site.report.update-initial' },
                props: true,
                meta: {
                  doNotScroll: true,
                },
                children: [
                  {
                    path: 'type',
                    name: 'sites.site.report.type',
                    component: () => import('@/views/site/reports/create/TheSiteReportCreateTypeStep.vue'),
                    props: true,
                    meta: {
                      isModalView: true,
                    },
                  },
                  {
                    path: ':reportType/:reportId/initial',
                    name: 'sites.site.report.update-initial',
                    component: () => import('@/views/site/reports/create/TheSiteReportCreateInitialStep.vue'),
                    props: true,
                    meta: {
                      isModalView: true,
                    },
                  },
                  {
                    path: ':reportType/:reportId/content',
                    name: 'sites.site.report.update-content',
                    component: () => import('@/views/site/reports/create/TheSiteReportCreateContentStep.vue'),
                    props: true,
                    meta: {
                      isModalView: true,
                    },
                  },
                  {
                    path: ':reportType/:reportId/send',
                    name: 'sites.site.report.update-send',
                    component: () => import('@/views/site/reports/create/TheSiteReportCreateSendStep.vue'),
                    props: true,
                    meta: {
                      isModalView: true,
                    },
                  },
                  {
                    path: ':reportType/:reportId/generate',
                    name: 'sites.site.report.create.generate',
                    component: () => import('@/views/site/reports/create/TheSiteReportCreateGeneratingStep.vue'),
                    props: true,
                    meta: {
                      isModalView: true,
                    },
                  },
                  {
                    path: ':reportType/:reportId/details',
                    name: 'sites.site.report.details',
                    component: () => import('@/views/site/reports/create/TheSiteReportDetailsStep.vue'),
                    props: true,
                    meta: {
                      isModalView: true,
                    },
                  },
                ],
              },
              {
                path: 'report/:reportType/:reportId/preview',
                name: 'sites.site.report.preview',
                component: () => import('@/views/site/reports/TheSiteReportPreview.vue'),
                props: true,
              },
            ],
          },
          {
            path: 'tasks',
            name: 'sites.site.task.index',
            props: true,
            components: {
              default: () => import('@/views/site/reports/tasks/TheSiteReportTasks.vue'),
              menu: TheSiteMenu,
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'health',
        name: 'sites.site.health.show',
        components: {
          default: () => import('@/views/site/health/TheSiteHealth.vue'),
          menu: TheSiteMenu,
          headerContent: TheSiteHeaderContent,
        },
        props: true,
        meta: {
          requiresAuth: true,
          events: {
            site: ['.site.health.updated'],
          },
          // events: {
          //   site: ['.site.health.updated'],
          //   organization: ['.site.items.installed'],
          // },
        },
      },
      {
        path: 'settings',
        name: 'sites.site.settings.show',
        components: {
          default: () => import('@/views/site/management/TheSiteSettings.vue'),
          menu: TheSiteMenu,
        },
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default siteRoutes;

export { siteRoutes };
