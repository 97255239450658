import { nextTick } from 'vue';

/**
 * Composable function for scrolling to a specific HTML element on the page.
 *
 * @returns {object} An object containing the `scrollToElement` function.
 *
 * @example
 * const { scrollToElement } = useScrollToElement();
 * scrollToElement("someElementId");
 */
export default function useScrollToElement() {
  /**
   * Scrolls the page to bring the specified element into the center of the viewport.
   *
   * @param {string | any} elementIdOrRef - Either the HTML element's ID as a string or a ref to the HTML element.
   * @param block
   * @param timeout
   * @returns {void} Returns nothing. Scrolls to the element if found, logs a warning otherwise.
   *
   * @example
   * scrollToElement("elementId"); // Scrolls to the element with the specified ID
   * scrollToElement(someRef); // Scrolls to the element referenced by `someRef`
   */
  const scrollToElement = (
    elementIdOrRef: string | any,
    block: ScrollLogicalPosition = 'center',
    timeout = 0,
  ): void => {
    let element: HTMLElement | null;

    if (elementIdOrRef.value) {
      element = elementIdOrRef.value;
    } else if (!!document.getElementById(elementIdOrRef)) {
      element = document.getElementById(elementIdOrRef);
    } else {
      element = elementIdOrRef;
    }

    if (!!element) {
      nextTick(() => {
        setTimeout(() => {
          element?.scrollIntoView({
            block,
            behavior: 'smooth',
          });
        }, timeout);
      });
    } else {
      console.warn('Element was not found.');
    }
  };

  return { scrollToElement };
}
