import type { SiteReport } from '@/types/models/site/service/report/SiteReport';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class PublicReportService extends AbstractService {
  /**
   * /report/{format}/print
   *
   * Retrieves the site report specified by the slug.
   *
   * @param {string} slug
   * @param {string} format
   * @returns {Promise<SiteReport>} Returns the site report.
   */
  public retrieveNew(slug: string, format: string): Promise<SiteReport> {
    return this.request({
      method: apiEndpoints.publicSiteReportView.method,
      url: this.buildPath(apiEndpoints.publicSiteReportView, {
        slug,
        format,
      }),
    });
  }
}
