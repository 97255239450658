import { cva, type VariantProps } from 'class-variance-authority'

export { default as Badge } from './Badge.vue'

export const badgeVariants = cva(
  'd-inline-flex align-items-center transition-colors fw-medium',
  {
    variants: {
      variant: {
        default: '',
        primary: 'bg-primary-light text-primary-dark',
        secondary: 'bg-secondary text-light',
        success: 'bg-success text-light',
        danger: 'bg-danger-light text-danger-dark',
        outline: 'text-dark border border-1 border-gray-400',
        gray: 'text-dark bg-gray-400',
      },
      size: {
        xs: 'text-xs rounded-xs p-4 lh-md',
        sm: 'text-sm rounded-sm px-8 py-4 lh-xl',
        pill: 'rounded-pill text-normal px-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'sm',
    },
  },
)

export type BadgeVariants = VariantProps<typeof badgeVariants>
