export default {
  shared: {
    connected: 'Connection established',
    lost: 'Connection lost',
    siteImage: 'Image of {site}',
    site: 'Website',
    createdSites: 'Created',
    showRecents: 'View all',
    viewedAt: '{time} ago',
    numUpdates: '{num} update | {num} updates',
    numAvailable: '{num} available | {num} available',
    seeDetail: 'See detail',
    web: 'Web',
    performance: 'Performance',
    updates: 'Updates',
    updatesErrors: 'Updates/Errors',
    backup: 'Backups',
    wordpress: 'WordPress',
    connectionStatus: 'Connection status',
  },
  edit: {
    settings: 'Website settings',
    delete: 'Delete website',
    reconnectSite: 'Reconnect website',
    refreshThumbnail: 'Regenerate thumbnail',
    successRefreshThumbnail: 'We are regenerating the thumbnail. It may take a few minutes.',
  },
  settings: {
    config: 'Website Settings',
    title: '{name} settings',
    nameConnection: 'Name and connexion',
    nameConnectionText:
      'Customize the name of your website, the team it belongs to or reconnect it if necessary.',
    websiteName: 'Name',
    tags: 'Tags',
    tagsText:
      'Modify the tags of your website to take advantage of the filters in the updater or your sites page.',
  },
  connect: {
    title: 'Connect your WordPress website',
    uri: 'Public website url: myweb.com',
    accessUri: 'Access url',
    cancelConnection: 'Cancel connection',
    unableToConnect:
      '<p class="mb-32">Discover the most common reasons and how to fix them at <a id="help" target="_blank" rel="noreferrer noopener" href="https://support.modulards.com/en/conection-web-error">{helpText}</a>.</p> <p class="mb-0">If the problem persists, we would be very grateful if you could write to us at <a id=\'email\' href=\'mailto:{email}\'>{email}</a> so that we can help you to solve it.</p>',
    helpText: 'this article',
    failedConnection: 'Connection failed',
    connectError: 'Connection error: {error}',
    auto: 'Automatic',
    manual: 'Manual',
    invalidCredentialsTitle: 'The credentials are not correct',
    invalidCredentialsText: 'Enter a valid username and password.',
    invalidLoginUrlTitle: 'The URL is not correct',
    invalidLoginUrlText:
      'If you use a different access URL (e.g. .../my-panel) make sure you enter the website information correctly.',
    credentials: 'Credentials',
    loginDataMessage: 'IMPORTANT: Modular will not store your credentials.',
    loginFormCustomuriPlaceholder: 'wp-admin',
    customUri: 'custom URL',
    manualConnectionTitle: 'To connect your web manually, follow these steps:',
    manualFirstStep: '<a href="{link}" target="_blank" rel="noreferrer noopener">Download our official plugin</a>.',
    manualSecondStep: 'On the "Plugins" page in WordPress, click "Add new" and upload it.',
    manualThirdStep: 'In the WordPress menu, go to "Tools > Modular - Connection management".',
    manualFourthStep: 'Paste in the corresponding place the public and private keys that you will find below.',
    manualFifthStep: 'Save the changes to your website.',
    manualSixthStep: 'Click on the "Connect" button below.',
    publicKey: 'Public Key',
    copyCode: 'Copy code',
    privateKey: 'Private Key',
    showPrivateKey: 'Generate private key',
    testConnection: 'Test connection',
    connecting: 'Let\'s go there, establishing connection...',
    loggedIn: 'Connection established! Let\'s install Modular Connector...',
    pluginInstalled: 'We have successfully installed the Modular Connector, let\'s continue...',
    pluginReplaced: 'We have updated an old version of Modular Connector, let\'s continue...',
    pluginActivated: 'Plugin activated! We are checking the connection...',
    establishedCredentials: 'We are checking that everything went correctly, it will be ready in a moment!',
    siteConnected: 'Your website has been connected!',
    successConnection: 'Connection complete',
    captchaDetected: 'We have detected a possible captcha or a security plugin has blocked us.',
    maintenanceMode: 'Maintenance mode is activated on your website, please try again later.',
    cannotConfirm: 'The connection could not be validated.',
    sitesLimitReached: 'You have exceeded the maximum number of websites connected with your Modular plan.',
    cannotPluginInstalled: 'Modular Connector could not be installed. Please try the manual method.',
    siteConnectedFailure: 'We could not connect your website.',
    genericError: 'The connection could not be established.',
    urlInfo:
      'If your access url is the standard WordPress one, don\'t put anything here.<br/>Change it only if you use a custom url.',
    uriErrorMessage: 'Enter a correct url',
  },
  create: {
    title: 'Enter website data',
    uri: 'Public website url: myweb.com',
    name: 'Website name',
    team: 'Select a team',
    urlFormat: 'Must have the correct URL format',
    created: 'Website created',
    nextDesign: 'Coming soon',
    connectSite: 'Connect your WordPress website',
    new: 'Connect your WordPress website',
    notValidUrl: 'The URL is invalid or a site with this URL already exists.',
    notValidUrlFormat: 'The URL is not in a valid format.',
    validatingUrl: 'We are verifying that the URL is valid...',
    addToExistingTeam: 'Add to an existing team',
    addToNewTeam: 'Add to new team',
  },
  overview: {
    metrics: {
      title: 'Analytics',
      since: 'Last {date_range} days',
      googleAnalytics: 'Google Analytics',
      googleAnalyticsDescription: 'Connect a Google Analytics account to start seeing statistics of your website.',
      googleAnalyticsConnect: 'Connect Analytics',
    },
    updates: {
      updates: 'Updates',
      reload: 'Reload',
      updateAll: 'Update all',
      plugin: 'plugin',
      plugins: 'plugins',
      theme: 'theme',
      themes: 'themes',
      translation: 'translation',
      translations: 'translations',
      wordpress: 'WordPress',
      totalItems: '{name} ({total})',
      selectedUpdates: '1 Selected | {total} Selected',
      noUpdates: 'Nothing to update!',
      update: 'update',
      itemsUpdated: '{name} updated',
      wordPressUpdated: 'Updated version',
      showNumberMore: 'See {num} more',
      showLess: 'See less',
      showMore: 'See more',
      noPendingUpdates: 'No pending updates',
      noPendingUpdatesText: 'So far everything is running smoothly.',
      noHiddenUpdates: 'You have no hidden updates',
      noGlobalHiddenUpdatesText: 'Learn about hidden updates <a href="https://support.modulards.com/en/updater-global#:~:text=others%20without%20updating.-,Hide%20updates,-If%20you%20prefer " target="_blank" rel="noreferrer noopener" aria-label="Support">here</a>.',
      noHiddenUpdatesText: 'Learn about hidden updates <a href="https://support.modulards.com/en/web-updater#:~:text=refresh%20the%20information.-,Hide%20updates,-If%20you%20prefer" target="_blank" rel="noreferrer noopener" aria-label="Support">here</a>.',
      cannotConnectSite:
        'Your website connection to Modular has been lost. Please reconnect to access all features.',
      cannotUpdateData: 'The connection to your website has been lost. Please reconnect to view this information.',
      cannotUpdateDataText:
        'Discover the most common reasons and how to fix it in <a href="https://support.modulards.com/en/conection-web-error" target="_blank" rel="noreferrer noopener" aria-label="Support">this article</a>. If the problem persists, we would really appreciate it if you write to us at <a href="mailto:{email}" aria-label="Contact by email">{email}</a> so we can help you solve it.',
      updateSelection: 'Update selection',
      viewChangelog: 'View changelog',
      actualVersion: 'actual version: V{ version }',
      notChangelogFound:
        'We could not find the changelog for this plugin. It is possible that it is a plugin that is not in the WordPress repository.',
      notVulnerabilitiesFound:
        'We have been unable to find the vulnerability information. Please try again later or contact us.',
    },
    lighthouse: {
      mobile: 'Mobile',
      desktop: 'Desktop',
      estimateReport:
        'Values are estimates and may vary. The test is performed automatically every 10-15 days, but you can do it now at this link.',
      testNow: 'Do the test now',
      performance: 'Performance',
      updatedAt: 'Updated on {date}',
      noInformation: 'No information',
      viewDetails: 'view detail',
      mayLastDays: 'May take 5-10 minutes',
      calculating: 'Calculating report...',
      tooltipInfo: 'The test is performed automatically every 10-15 days.',
    },
    backups: {
      title: 'Backups',
      lastBackup: 'Last Backup',
      lastBackupDate: 'Last Backup {date}',
      lastBackups: 'Last backups made',
      createBackupNow: 'Backup Now',
      nextBackup: 'Next backup on {date}',
      activateBackup: 'Activate Backups',
      configureBackup: 'Configure Backups',
      configureBackupLong: 'Configure Backups',
      noBackups: 'Configure backups for your website, schedule them and recover them when you need them.',
      availableSpace: 'Available space',
      increase: 'Increase',
      inProgress: 'In progress',
    },
    note: {
      createNote: 'Add note',
      noteOfSite: 'Notes for {name}',
      noteOfSitePlaceholder: 'Write a note to remember something important about this website, precautions, etc.',
      deleteSiteNote: 'Delete the content of the note',
      deleteSiteNoteText: 'Are you sure you want to delete the content of the note from this website?',
      deleteSiteNoteSuccess: 'Note deleted',
      saveSiteNoteSuccess: 'Note saved',
    },
    health: {
      criticalErrors: 'Critical errors',
      noCriticalErrors: 'No critical errors',
      checkSite: 'Checking the site...',
      notAvailable: 'Not available',
    },
    searchConsole: {
      title: 'Search Console',
      description:
        'Connect a Search Console property to start seeing how your website is performing in search results.',
      connect: 'Connect Search Console',
    },
  },
  sidebar: {
    addCategory: 'To add or edit categories you can do it',
    here: 'here',
    featuredImage: 'Featured Image',
    featuredImageText: 'Main image of the post. It is the one that appears as a thumbnail.',
    setFeaturedImage: 'Set featured image',
    deleteFeaturedImage: 'Delete Featured Image',
    status: 'Status',
    schedule: 'Schedule',
    visibility: 'Visibility',
    public: 'Public',
    protected: 'Protected',
    private: 'Private',
    straightway: 'Immediately',
    deletePost: 'Delete post',
    saveAs: 'Save As: {status}',
    successPublish: 'Your new post has been successfully saved',
    successDeletePost: 'Post has been deleted successfully',
    uptimeMonitor: 'UptimeMonitor',
    uptimeMonitorDescription: 'Receive notifications by email or SMS when your website is down.',
    activateFree: 'Activate free',
    uptimeStatus: 'Uptime is {percentage}%',
    latestEvents: 'Latest events',
    maintenanceReports: 'Maintenance reports',
    generateReport: 'Generate report',
    createReport: 'Create report',
    next: 'next',
    lastReports: 'Latest reports',
  },
  uptime: {
    shared: {
      hours: '1 hour | { hours } hours',
      minutes: '1 minute | { minutes } minutes',
      seconds: '1 second | { seconds } seconds',
      days: '1 day | { days } days',
      byDate: 'by date',
      shortHours: '{ hours }h',
      shortMinutes: '{ minutes }m',
      shortSeconds: '{ seconds }s',
      shortDays: '{ days }d',
      months: '1 month | { number } months',
      lastDays: 'Last Day | Last { number } days',
      noActivity: 'No activity',
      uptimeEnabled: 'Uptime enabled',
      checkDateDuration: '{ start } for { duration }',
      checkStatusDurationDaysHours: '{ status } since { days }d { hours }h { minutes }m',
      checkStatusWithoutStatus: 'Up since { time }',
      checkStatusSSLValid: 'in { time } days',
      checkStatusSSLInvalid: '{ time } days ago',
      checkNoChanges: 'The uptime has not yet generated changes',
      action: 'Action',
      deactivateUptimeMonitorTitle: 'Deactivate Uptime Monitor',
      deactivateUptimeMonitorText:
        'If you click on confirm, the status monitoring of your website will be deactivated and the status will be set to paused. In case you want to enable it again, you can always do it from the Uptime Monitor configuration',
    },
    activity: {
      emptyYTLink: 'https://www.youtube.com/embed/nlXSJXGjO-g',
      uptimeDescription:
        'Uptime monitoring helps you find out quickly when your website has a problem and users can\'t access it by sending you a notification via email or SMS. In addition, you will be able to see the activity of the web during the last days or weeks to know when there were down times.',
      uptimeTitle1: 'Forget awkward situations with your client',
      uptimeReason1:
        'Have you experienced that moment when a client tells you that their website is not working? Thanks to Uptime monitoring you will find out when there is a problem to solve it before it gets worse.',
      uptimeTitle2: 'Know when it fails and when it recovers',
      uptimeReason2:
        'When your website breaks, you\'ll immediately receive an alert with error messages, HTTP status, content changes and much more. And when the website is back up and running, you\'ll get another notification.',
      uptimeTitle3: 'Know the status at all times',
      uptimeReason3:
        'Modular gives you the infrastructure to monitor the availability of your website every minute, every day of the year. In addition, in the incorporated graphs you will be able to see the availability of your website during the last month.',
      uptimeTitle4: 'Learn the cause of the problem',
      uptimeReason4:
        'Whenever we detect an error we perform additional tests to help you identify the cause of the outage so you can prevent it from happening again.',
    },
    configuration: {
      title: 'Settings',
      interval: 'Interval',
      intervalText: 'Determine how often we will check the availability of your website.',
      minutesInterval: '{ number } minute | { number } minutes',
      keywordMonitoring: 'Keywords',
      keywordMonitoringText:
        'Your website will be considered down when a check does not show all the defined words. They can be words or phrases. It is case insensitive.',
      enterKeywords: 'Enter keywords',
      keywordsExample: 'EJ: lorem, ipsum, pain, sit ...',
      keywordsRule: 'separate keywords by commas',
      keywordDuplicatedError: 'The word is already in the list',
      uptimeNotification: 'Notifications',
      uptimeNotificationText:
        'Receive notifications when your website is down.',
      uptimeNotificationDelayText:
        'You can set the time until we send a notification when we detect your website is down.',
      emailsEntered: 'Emails entered',
      emailsExample: 'EX: lucas{\'@\'}modular.es, pablo{\'@\'}modular.es ...',
      emailDuplicatedError: 'Email is already on the list',
      emailsRule: 'separate emails by commas',
      emailNotificationTimeout: 'Notify after',
      emailNotificationTimeoutText:
        'If the website responds again before we send you the notification,<br/> it will be added to the log but we won\'t send you a notification.<br/> This is useful to prevent us from sending you notifications, for example,<br/> if your website is temporarily down due to a WordPress update.',
      phoneNotificationText:
        'With the PRO plans you can add a phone number so that we notify you via SMS in case there is a problem with your website.',
      enterPrefix: 'Prefix',
      enterPhone: 'Enter phone',
      disableUptime: 'Disable Uptime',
      removeUptimeAdvice: 'Are you sure you want to disable uptime for website { site }?',
      timeoutSettingsTitle: 'Consider website is down after...',
      timeoutSettingsText:
        'By lowering this value, set a threshold for the response time. Your website is considered down when it exceeds the selected value without responding.',
      sslTitle: 'SSL monitoring',
      sslText: 'Receive alerts when your website\'s SSL certificate is about to expire.',
      sslWhenReceiveAlerts: 'When do you want to receive notifications?',
      sslActivateNotifications: 'Notifications must be activated',
      alerting: {
        alertText: 'This setting is no longer valid, please <a href="{link}" class="text-tiger-900">click here</a> to access the notification panel to change or delete it.',
        empty: {
          title: 'You do not have a notification settings assigned',
          description: 'Create a new configuration or assign an existing one to this site.',
          configureNotifications: 'Set up notifications',
        },
      },
      alertingAlert: {
        //  FIXME: This is temporal alert, only when is configured uptime notifications and not alerting
        title: 'By clicking confirm, the notification settings for this website will be removed.',
        text: 'To receive notifications from Uptime Monitor, assign a notification setting to this website from the notifications panel.',
      },
    },
    ssl: {
      sslKeyName: 'SSL',
      sslProperty: 'Property',
      sslValue: 'Value',
      cerfiticateDetails: 'Certificate details',
      sslValidFrom: 'Valid from',
      sslValidTo: 'Valid until',
      sslError: 'Error',
      sslSubject: 'Subject',
      sslSubjectCN: 'Common Name (CN)',
      sslSubjectO: 'Organisation (O)',
      sslSubjectC: 'Organisational Unit (OU)',
      sslIssuer: 'Sender',
      sslIssuerCN: 'Common Name (CN)',
      sslIssuerO: 'Organisation (O)',
      sslIssuerC: 'Organisational Unit (OU)',
      sslFingerprintSha1: 'Fingerprint SHA1',
      sslFingerprintSha256: 'Fingerprint SHA256',
      sslFingerprintSha512: 'Fingerprint SHA512',
      sslSerialNumber: 'Serial Number',
    },
  },
  backup: {
    title: 'Backups',
    createBackup: 'Create Backup',
    createNewBackup: 'Create Backup Now',
    createNewBackupTitle: 'Do you want to make a backup right now?',
    createNewBackupDescription:
      'The backup will take the configuration you have saved for this website in the configuration tab. You can check it beforehand just in case.',
    totalBackups: '1 backup done | { number } backups made',
    backupCreatedMessage: 'Your backup is being processed...',
    deactivateBackupRecurrenceTitle: 'Deactivate automatic backups',
    deactivateBackupRecurrenceText:
      'If you click confirm, the recurring backups for this website will stop. If you want to activate them again, you can always do so from the website\'s backup settings.',
    deleteBackupTitle: 'Do you want to delete this backup?',
    deleteBackupText:
      'If you delete this Modular backup, the data will be lost and you will not be able to recover it from our tool. You will be able to keep it if you have previously downloaded it to your computer',
    deleteMasterBackupTitle: 'Do you want to remove this backup as master?',
    deleteMasterBackupText:
      'If you do, this backup will be deleted {days} days after its creation date. If more than {days} days have passed, it will be deleted in a few minutes. From that moment it will not be possible to recover. We recommend downloading it first.',
    backupDeleteCompleted: 'The backup has been successfully deleted | Backups have been successfully deleted',
    cannotDeleteMasterBackup: 'You cannot delete a master backup',
    reachedMasterBackupLimit: 'You have reached the master backup limit for this website',
    includedCannotBeEmpty: 'You must select something to continue.',
    markAsMasterBackup: 'Make Master Backup',
    unmarkAsMasterBackup: 'Disable master backup',
    maxMasterBackupsExceeded: 'You have reached the limit of master backups for this website.',
    all: 'All',
    none: 'None',
    downloadZip: 'Download ZIP',
    status: {
      automatic: 'Automatic',
      force_first: 'Automatic',
      manual: 'Manual',
    },
    tooltip: {
      status: {
        excluded: '{type}: excluded',
        pending: '{type}: pending',
        in_progress: '{type}: in progress',
        ready: '{type}: ready',
        upload_pending: '{type}: upload pending',
        uploading: '{type}: uploading',
        done: '{type}: completed',
        failed_in_creation: '{type}: failure in creation',
        failed_export_database: '{type}: failure in database export',
        failed_export_files: '{type}: failure in files export',
        failed_in_processing: '{type}: failure in processing',
        failed_uploaded: '{type}: failure in upload',
        failed_partial: '{type}: partial failure',
        failed: '{type}: failed',
        failed_file_not_found: '{type}: file not found',
        storage_exceeded: '{type}: storage limit exceeded',
        partial_error: '{type}: with partial errors',
        error: '{type}: error',
        disabled: '{type}: disabled',
      },
      type: {
        database: 'Database',
        databaseInitial: 'D',
        themes: 'Themes',
        themesInitial: 'T',
        plugins: 'Plugins',
        pluginsInitial: 'P',
        core: 'WordPress',
        coreInitial: 'W',
        uploads: 'Media',
        uploadsInitial: 'M',
        all: 'Full backup',
        ready: 'Ready',
      },
    },
    noBackups: {
      title: 'We are preparing your first backup',
      description:
        'This process may take a few minutes. As soon as the backup creation process starts, it will appear below in the backup list.',
      button: 'Review settings',
    },
    aside: {
      scheduleBackups: 'Schedule backups',
      usedStorage: 'Used storage',
      currentStorage: '{ current } of { total } used',
      noBackups:
        'You don\'t have any backups yet. Learn how to activate them on your websites <a href="https://support.modulards.com/en/schedule-recurring-backups" class="d-inline lh-xl text-sm btn btn-link btn-link-primary-dark text-decoration-underline shadow-none border-0 text-sm p-0 m-0 fw-medium" target="_blank" aria-label="How to activate Backups">here</a>.',
      excludedFiles: 'Excluded files',
      spaceFilled: 'No new backups will be made until you have space or purchase more with a PRO plan.',
    },
    configuration: {
      backupName: 'Backup name',
      elementsToInclude: 'Elements to include',
      elementsToIncludeText:
        'Choose the elements you want to include in your backups.',
      core: 'WordPress and files',
      database: 'Database',
      files: 'Files',
      backupModeTitle: 'Backup mode',
      backupModeDescription:
        'Choose the copy mode you want for your backups. If you choose the normal backup option, a normal backup will be performed. If you have a failure, you can choose the deferred (failsafe) mode, which will allow you to make sure that the backup will be carried out correctly.',
      elementsRecurrenceTitle: 'Recurrence',
      elementsRecurrenceText: 'Choose how often you want backups to be made.',
      elementsRecurrenceExtraText:
        'Regardless of your selection the first backup will be done instantly. The next one, on the date you choose. And from there on the selected date with a minimum of the days corresponding to your recurrence in between.',
      scheduleRecurrence: 'Schedule recurrence',
      scheduleRecurrenceWeekly: 'Every week on',
      scheduleRecurrenceMonthly: 'Every month on day',
      hour: 'Hour',
      hourTimezone: 'By default we use your browser\'s time zone: {timezone}',
      backupAdvice:
        'If you have several websites on the same server, we recommend scheduling backups at least half an hour apart to avoid errors.',
      filesExcluded: '({ count } Excluded item) | ({ count } Excluded items)',
      tablesExcluded: '({ count } Table excluded) | ({ count } Tables excluded)',
      excludeFiles: 'Exclude files',
      selectFiles: 'Select files',
      excludeTables: 'Exclude tables',
      selectTables: 'Select tables',
      selectFilesExclude: 'Select files to exclude',
      selectTablesExclude: 'Select tables to exclude',
      includeAll: 'Include all',
      allFiles: 'All files',
      allTables: 'All tables',
      tables: 'Tables',
      excludedFiles: 'Excluded files ({ count })',
      excludedTables: 'Excluded tables ({ count })',
      elementsRetentionTitle: 'Backup retention',
      elementsRetentionText:
        'Choose how many days you want the backups of this website to be kept in Modular before being deleted.',
      elementsRetentionMonthly: 'Monthly backups are stored for 45 days.',
      expertiseConfig: {
        title: 'Expert configuration',
        expertiseAlertText:
          'Settings for experts only. We recommend that you do not make changes unless you have extensive knowledge about the capacities of the servers where the website is hosted and the operation of the backups. Incorrect configuration can prevent the generation of backups.',
        maxFiles: {
          title: 'Max number of files',
          description:
            'Sets the maximum number of files to process per cycle (default 5000). Lowering this value is ideal for servers with older, slower hard drives.',
        },
        maxZipSize: {
          title: 'Max ZIP size',
          description: 'Defines the maximum size for each ZIP segment (default 150MB).',
        },
        maxWait: {
          title: 'Max wait time',
          description:
            'Maximum time to wait before canceling the backup if the server encounters issues (default 30 minutes).',
        },
      },
    },
    detail: {
      restoreBackup: 'Download or restore this backup',
      backupDetails: 'Backup information',
      restoreBackupDescription:
        'Here you can download the .zip file with your backup or restore it from Modular if you need it (coming soon).',
      restoreToVersion: 'Restore to this version',
      downloadZip: 'Download ZIP',
      downloadCompleteZip: 'Complete ZIP',
      downloadParts: 'By parts',
      wpVersion: 'WP version',
      numPlugins: 'Plugins',
      activeTheme: 'Active theme',
      postPublish: 'Posts published',
      content: 'Content',
      contentDescription:
        'Here you can view the different sections of your backup. Depending on your configuration and the size of the website, these sections may be subdivided into smaller fragments.',
      couldntSave: 'Could not save',
    },
    slider: {
      titleSlide0: 'Exclude files and database tables from your backups',
      textSlide0:
        'Optimize your backups by selecting which specific files, folders or database tables you want to exclude to save only essential information.',
      titleSlide1: 'Save 3 master backups forever',
      textSlide1:
        'Backups are deleted after 45 days. Protect up to 3 backups so that they are always saved (until you decide to delete them).',
      titleSlide2: 'Make instant backups whenever you need them',
      textSlide2:
        'If you need to make a backup before making changes to your website and you don\'s want to wait for the scheduled copy, make instant backups with 1 click from Modular.',
      titleSlide3: 'Choose the recurrence that best suits your website',
      textSlide3:
        'Is one month too long for you? With our PRO plans you can make weekly and even daily backups. So you don\'s have a problem if you have a very dynamic website.',
    },
    empty: {
      emptyYTLink: 'https://www.youtube.com/embed/ZElwrL2FQpo',
      emptyText:
        'Backups are one of the most important tasks that must be done on a website for proper maintenance. With Modular you can activate automatic backups by choosing the recurrence you prefer. Make instant backups before making a change. And, of course, recover them when you have detected a problem on your website or want to go back.',
      backupTitle1: 'Create backups of what you need',
      backupReason1:
        'You can choose if you want to make full backups of your website. Files only. Database only. Or even select if you want to exclude specific files from the backup.',
      backupTitle2: 'Recover them yourself or do it automatically',
      backupReason2:
        'You will be able to download the backups in a .zip file to restore them yourself or, very soon, you will be able to restore the backups from Modular with a single click.',
      backupTitle3: 'Backups outside your hosting',
      backupReason3:
        'We create your backup "live", so it won\'s be stored on your server and won\'s take up space on it. So that if it fails, the backup is 100% safe in a different location.',
      backupTitle4: 'GDPR Compliant',
      backupReason4:
        'All our backups are done on AWS cloud servers hosted in Europe, so you and your clients stay GDPR compliant.',
    },
    warnings: {
      title: 'You have health alerts that can affect your backups',
      description: 'You can check the health status of your site in the Health & Safety section.',
    },
  },
};
