<script lang="ts" setup>
import {
  DropdownMenuContent,
  type DropdownMenuContentEmits,
  type DropdownMenuContentProps,
  DropdownMenuPortal,
  useForwardPropsEmits,
} from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = withDefaults(
  defineProps<
    DropdownMenuContentProps & {
      class?: HTMLAttributes['class']
      variant?: 'light' | 'dark'
      size?: '' | 'sm' | 'lg'
      maxHeight?: string | number | null
      spacing?: string
    }
  >(),
  {
    sideOffset: 4,
    variant: 'light',
    size: '',
    maxHeight: '',
    spacing: '',
  },
);
const emits = defineEmits<DropdownMenuContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, variant, size, maxHeight, spacing, ...delegated } = props;

  return delegated;
});

const computedMaxHeight = computed(() => {
  if (typeof props.maxHeight === 'number') {
    return `${props.maxHeight}px`;
  }

  if (typeof props.maxHeight === 'string' && (props.maxHeight.includes('px') || props.maxHeight.includes('rem'))) {
    return props.maxHeight;
  }

  return props.maxHeight;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <DropdownMenuPortal>
    <DropdownMenuContent
      class="ui-menu-content ui-animate ui-animate-out fw-medium rounded-sm border text-sm shadow"
      :class="[
        {
          'ui-menu-content-dark': variant === 'dark',
          'ui-menu-content-light': variant === 'light',
          'ui-menu-content-sm': size === 'sm',
          'ui-menu-content-lg': size === 'lg',
          'overflow-y-auto overflow-x-hidden': !!maxHeight,
          'px-8 py-12': !spacing,
        },
        spacing,
      ]"
      :style="{ maxHeight: computedMaxHeight }"
      v-bind="forwarded"
    >
      <slot />
    </DropdownMenuContent>
  </DropdownMenuPortal>
</template>
