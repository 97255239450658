import type {
  SitesAppendFilter,
  SitesIncludeFilter,
  SitesRequestOptions,
} from '@/api/services/organization/OrganizationService';
import type { Tag } from '@/types/models/organization/tag/Tag';

import type { Site } from '@/types/models/site/Site';
import type { HighlightedSite, PaginatedSites, SitesMeta } from '@/types/models/site/Sites';
import type { Ref } from 'vue';
import { computed, reactive, ref, toValue } from 'vue';
import api from '@/api';
import { SiteStatusEnumConst } from '@/types/enums/SiteStatusEnum';

import { useInfiniteScroll } from '@vueuse/core';
import { go as fuzzysortGo } from 'fuzzysort';

export function useSitesInfiniteScroll(options: {
  orderBy?: Ref<string>
  filters?: {
    tagsFilter?: Ref<Tag[]>
    teamsFilter?: Ref<number[]>
    include?: Ref<SitesIncludeFilter[]>
    append?: Ref<SitesAppendFilter[]>
    search?: Ref<string>
    all?: boolean | undefined
    included?: number[] | undefined
    excluded?: number[] | undefined
  }
  params?: Record<string, any>
  isTeamView?: boolean
  limit?: boolean
  onlyEstablished?: boolean
  connectorMinVersion?: string
  highlight?: boolean
  itemFilter?: (item: Site) => Site
}) {
  const {
    orderBy,
    filters,
    limit,
    isTeamView,
    itemFilter,
    params: optionsParams,
    onlyEstablished,
    connectorMinVersion,
    highlight,
  } = options;
  const { tagsFilter, teamsFilter, include, append, search, all, included, excluded } = filters || {};

  const loadMoreTrigger = ref<HTMLDivElement | null>(null);

  const isRequestingData = ref(false);
  const dataRequested = ref<boolean>(false);
  const currentPage = ref(1);

  const dataResponse = reactive<PaginatedSites>({
    data: [],
    links: [],
    included: [],
    meta: {
      currentPage: 1,
      lastPage: 0,
    } as SitesMeta,
  });

  const reset = () => {
    isRequestingData.value = false;
    dataRequested.value = false;
    currentPage.value = 1;
    dataResponse.data = [];
    dataResponse.meta = {
      currentPage: 1,
      lastPage: 0,
      from: null,
      path: null,
      perPage: null,
      to: null,
      total: null,
      totalDisconnectedSites: 0,
      totalUpdatableItems: 0,
    };
  };

  const requestData = async (forceLoader = false, teamIds?: Array<number>) => {
    if (
      isRequestingData.value
      || (limit && !!dataResponse.data && dataResponse.data.length > 0)
      || (isTeamView && teamIds?.length === 0)
    ) {
      return;
    }

    if (forceLoader) {
      dataRequested.value = false;
    }

    try {
      isRequestingData.value = true;

      const [filterName, filterType] = orderBy?.value.split('-') || ['visited_at', 'desc'];
      const tagsIdSelected = tagsFilter?.value.map(tag => Number(tag.id)) || [];

      const params: SitesRequestOptions = {
        order: { [filterName]: filterType },
        page: currentPage.value,
        per_page: 12,
        filters: {
          include: toValue(include) || [],
          append: toValue(append) || [],
        },
        ...optionsParams,
      };

      if (!!toValue(search)) {
        params.s = toValue(search);
      }

      if (tagsIdSelected.length > 0) {
        params.tags = tagsIdSelected;
      }

      if (teamIds && teamIds.length > 0) {
        params.team = teamIds;
      } else if (!!teamsFilter?.value && teamsFilter.value.length > 0) {
        params.team = teamsFilter.value;
      }

      if (!!included || !!excluded) {
        if (!params.filters)
          params.filters = {};

        params.filters.all = all ? 1 : 0;
        params.filters.included = included;
        params.filters.excluded = excluded;
      }

      if (onlyEstablished) {
        params.connection_status = [SiteStatusEnumConst.SUCCESS];
      }

      if (!!connectorMinVersion) {
        params.connector_min_version = connectorMinVersion;
      }

      const response = await api.organization.general.sites(params);

      currentPage.value = currentPage.value + 1;

      const filteredData = itemFilter ? response.data.map(itemFilter) : response.data;

      if (!dataResponse.data) {
        dataResponse.data = filteredData;
      } else {
        dataResponse.data.push(...filteredData);
        dataResponse.meta = response.meta;
      }

      if (highlight) {
        const searchValue = toValue(search) || '';

        dataResponse.data = fuzzysortGo(searchValue, dataResponse.data, {
          key: 'name',
          all: true,
        }).map(result => ({
          ...result.obj,
          highlightedName: result.highlight() || result.obj.name,
        })) as HighlightedSite[];
      }

      dataRequested.value = true;
    } catch (error) {
      console.error('Error fetching sites:', error);
      return undefined;
    } finally {
      isRequestingData.value = false;
    }
  };

  const resetAndFetch = async (teamIds?: Array<number>) => {
    reset();
    await requestData(false, teamIds);
  };

  /**
   * Clear filters
   * Team ids are not cleaned due to Team view
   * @param reFetch
   */
  const clearFilters = async (reFetch = false) => {
    if (!!tagsFilter?.value) {
      tagsFilter.value = [];
    }

    if (!!teamsFilter?.value) {
      teamsFilter.value = [];
    }

    currentPage.value = 1;
    dataResponse.data = [];
    dataRequested.value = false;

    if (reFetch) {
      await requestData(true);
    }
  };

  const applyFilters = async () => {
    currentPage.value = 1;
    dataResponse.data = [];
    dataRequested.value = false;
    await requestData(true);
  };

  const hasData = computed(() => !!dataResponse.data && dataResponse.data.length > 0);
  const disconnectedSitesCount = computed(() => dataResponse?.meta?.totalDisconnectedSites);

  const canLoadMore = computed(() => {
    if (!!dataResponse?.meta && !!dataResponse?.meta?.currentPage && !!dataResponse?.meta?.lastPage) {
      return dataResponse?.meta && dataResponse?.meta?.currentPage < dataResponse?.meta?.lastPage;
    }

    return false;
  });

  const numberToPlacehold = computed(() => {
    if (!!dataResponse.meta?.total && !!dataResponse.meta?.to && !!dataResponse.meta?.perPage) {
      const remainingItems = dataResponse.meta?.total - dataResponse.meta?.to;
      return Math.min(dataResponse.meta.perPage, remainingItems > 0 ? remainingItems : 0) || 0;
    }

    return 0;
  });

  const { isLoading: isLoadingMore } = useInfiniteScroll(
    loadMoreTrigger,
    async () => {
      if (!isLoadingMore.value && canLoadMore.value) {
        await requestData(false);
      }
    },
    {
      distance: 0,
      interval: 1000,
    },
  );

  return {
    loadMoreTrigger,
    dataRequested,
    currentPage,
    dataResponse,
    hasData,
    canLoadMore,
    numberToPlacehold,
    requestData,
    isRequestingData,
    isLoadingMore,
    disconnectedSitesCount,
    clearFilters,
    reset,
    resetAndFetch,
    applyFilters,
  };
}
