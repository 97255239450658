import type { BillingAccount } from '@/types/models/organization/billing/BillingAccount';

import type { Feature, ProductPrice, StripeProduct } from '@/types/models/organization/billing/product/StripeProduct';
import type { License } from '@/types/models/organization/license/License';
import type { PaymentMethod } from '@/types/models/organization/payment/PaymentMethod';
import type { Subscription } from '@/types/models/organization/subscription/Subscription';
import api from '@/api';
import useUserStore from '@/stores/userStore';
import { BillingProductTypeEnumConst } from '@/types/enums/BillingProductTypeEnum';

import { defineStore } from 'pinia';

interface BillingState {
  billingAccount: BillingAccount | null
  paymentMethod: any | null

  subscriptions: {
    plan: Subscription | null
    addon: Subscription | null
  }

  license: License | null

  payment: PaymentMethod | null

  stripeProducts: {
    plans: StripeProduct[] | null
    addons: StripeProduct[] | null
  }

  selectedPlan: StripeProduct | null
  selectedPrice: ProductPrice | null

  loadedSubscriptions: boolean
}

const { PLAN, ADDON } = BillingProductTypeEnumConst;

const useBillingStore = defineStore({
  id: 'billing',
  state: (): BillingState => ({
    billingAccount: null,
    paymentMethod: null,

    subscriptions: {
      plan: null,
      addon: null,
    },

    license: null,

    payment: null,

    stripeProducts: {
      plans: null,
      addons: null,
    },

    selectedPlan: null,
    selectedPrice: null,

    loadedSubscriptions: false,
  }),
  actions: {
    async getBilling() {
      this.billingAccount = await api.organization.billing.retrieve();
    },
    async getProducts(products?: StripeProduct[]) {
      const localProducts = products || (await api.organization.product.all([PLAN, ADDON]));

      this.stripeProducts.plans = localProducts.filter(product => product.type === PLAN) || null;
      this.stripeProducts.addons = localProducts.filter(product => product.type === ADDON) || null;
    },
    async getPayment() {
      this.payment = await api.organization.payment.retrieve();
    },
    async getSubscription(subscriptions?: Subscription[]) {
      const userStore = useUserStore();

      const localSubscriptions = subscriptions || (await api.organization.subscription.general.all());

      this.subscriptions.plan = localSubscriptions.find(subscription => subscription.type === PLAN) || null;
      if (userStore.isAppSumoUser) {
        this.license = await api.organization.subscription.licenses.all();

        this.loadedSubscriptions = true;
      }

      this.subscriptions.plan = localSubscriptions.find(subscription => subscription.type === PLAN) || null;

      this.subscriptions.addon = localSubscriptions.find(subscription => subscription.type === ADDON) || null;

      this.loadedSubscriptions = true;
    },
    setSelectedPlan(plan: StripeProduct) {
      this.selectedPlan = plan;
    },
    setSelectedPrice(price: ProductPrice) {
      this.selectedPrice = price;
    },
  },
  getters: {
    planSubscription: state => state.subscriptions.plan,
    addonSubscription: state => state.subscriptions.addon,
    products: state => state.stripeProducts.plans,
    addons: state => state.stripeProducts.addons,
    planNextInvoice: state => state.subscriptions.plan?.nextInvoice,
    addonNextInvoice: state => state.subscriptions.addon?.nextInvoice,
    currentPlanName: state => state.subscriptions.plan?.product?.name || 'FREE',
    nextPlanName: (state) => {
      switch (state.subscriptions.plan?.product?.name) {
        case 'FREE':
          return 'PRO';
        case 'Early Adopter':
          return 'PRO';
        case 'Starter':
          return 'Business';
        case 'PRO':
          return 'Business';
        default:
          return 'PRO';
      }
    },
    planSubscriptionStorage: (state) => {
      if (
        !!state.subscriptions
        && !!state.subscriptions.plan
        && !!state.subscriptions.plan.product
        && !!state.subscriptions.plan.product.features
        && state.subscriptions.plan.product.features.length > 0
      ) {
        const backupsStorage = state.subscriptions.plan.product.features.find(
          (feature: Feature) => feature.key === 'backups_storage',
        );

        if (!!backupsStorage) {
          return backupsStorage;
        }
      }

      return null;
    },
  },
});

export { useBillingStore };
export default useBillingStore;
