<script lang="ts" setup>
import api from '@/api';
import { useTeamSitesInfiniteScroll } from '@/composables/team/useTeamSitesInfiniteScroll';
import useSiteStore from '@/stores/site/siteStore';

import useTeamStore from '@/stores/teamStore';

import useUserStore from '@/stores/userStore';

defineOptions({
  name: 'TheSiteTeamMenu',
});

const dropdownMenuIsOpen = ref(false);

const teamStore = useTeamStore();
const siteStore = useSiteStore();
const userStore = useUserStore();

const currentSite = computed(() => siteStore.currentSite);
const currentSiteTeam = computed(() => siteStore.currentSite?.team);

const teams = computed(() => userStore.teams);

const router = useRouter();

function goToTeam() {
  if (router.options.history.state?.back === '/') {
    router.push({ name: 'me.sites.index' });
  } else if (router.options.history.state?.back === '/sites') {
    router.push({ name: 'sites' });
  } else if (!!currentSite.value?.team) {
    router.push({
      name: 'team.show',
      params: {
        team: currentSite.value?.team?.slug,
      },
    });
  } else {
    router.back();
  }
}

const loadMoreTrigger = ref<HTMLElement | null>(null);

const { dataRequested, dataResponse, hasData, canLoadMore, requestData, isLoadingMore }
  = useTeamSitesInfiniteScroll(loadMoreTrigger, currentSiteTeam);

async function trackNextAndCleanCurrentSite(siteName: string, siteUri: string) {
  await api.mixpanel.track('View Website', {
    'Website name': siteName,
    'Website url': siteUri,
    'Location': 'Teams Menu',
  });
}

watch(teams, (newVal, oldVal) => {
  if (!!newVal && !!oldVal && oldVal.length === 0) {
    teamStore.setCurrentTeamSites();
  }
});
</script>

<template>
  <div class="sidebar-nav">
    <v-nav>
      <li class="nav-item">
        <VButton
          :aria-label="$t('general.button.backToTeam')"
          class="btn-back position-absolute top-50 translate-middle-y z-index-3 shadow-0 rounded-0 start-0 border-0 bg-transparent px-12
            py-16"
          @click="goToTeam"
        >
          <v-icon class="me-0" icon="arrow-back" />
        </VButton>

        <DropdownMenu v-model:open="dropdownMenuIsOpen" @update:open="!hasData ? requestData() : () => {}">
          <DropdownMenuTrigger
            class="btn-sidebar btn btn-team nav-link w-100 ps-48"
            data-cy="btn-profile"
            spacing="px-24 py-8"
            variant="transparent"
            @mouseenter="!hasData ? requestData() : () => {}"
            @focusin="!hasData ? requestData() : () => {}"
          >
            <span class="d-flex flex-column text-start">
              <span v-if="currentSite?.name" class="site-name text-dark fw-semi lh-lg mb-4">
                {{ currentSite.name }}
              </span>

              <span v-else class="placeholder-glow d-block lh-lg mb-4">
                <span class="placeholder" style="width: 100px" />
              </span>

              <span v-if="currentSite?.team?.name" class="site-team fw-medium text-xs lh-lg" data-cy="sidebar-site-team-name">
                {{ currentSite.team.name }}
              </span>

              <span v-else class="placeholder-glow" style="height: 12px">
                <span class="placeholder" style="width: 60px" />
              </span>
            </span>

            <!--              v-if="!!currentTeamSitesLength && currentTeamSitesLength > 1" -->
            <m-icon
              :class="{ open: dropdownMenuIsOpen }"
              class="dropdown-arrow ms-auto"
              icon="arrow-caret-down"
              size="2xs"
            />
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end" spacing="px-0 py-12" variant="dark">
            <template v-if="hasData && dataRequested">
              <ScrollArea class="teams-dropdown-scroll pe-12 ps-8">
                <DropdownMenuItem
                  v-for="siteItem in dataResponse.data"
                  :key="siteItem.slug"
                  class="dropdown-item mb-4"
                  data-cy="btn-profile"
                  @click="$router.push({ name: 'sites.site.show', params: { site: siteItem.slug } })"
                  @click.exact="trackNextAndCleanCurrentSite(siteItem.name, siteItem.uri)"
                >
                  <span class="text-xs">{{ siteItem.name }}</span>
                </DropdownMenuItem>

                <template v-if="isLoadingMore && canLoadMore">
                  <DropdownMenuItem v-for="n in 3" :key="n">
                    <span class="placeholder-glow fw-normal h-100 lh-md">
                      <span
                        :style="{ width: `${Math.floor(Math.random() * (140 - 80 + 1)) + 80}px` }"
                        class="placeholder"
                      />
                    </span>
                  </DropdownMenuItem>
                </template>

                <span v-if="canLoadMore" ref="loadMoreTrigger" class="d-block" />
              </ScrollArea>
            </template>

            <template v-else>
              <DropdownMenuItem v-for="n in 3" :key="n">
                <span class="placeholder-glow nav-link fw-normal h-100 lh-md pe-8 ps-20">
                  <span class="placeholder" style="width: 120px" />
                </span>
              </DropdownMenuItem>
            </template>
          </DropdownMenuContent>
        </DropdownMenu>
      </li>
    </v-nav>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-nav {
  .site-name,
  .site-team {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 11.5rem;
  }

  .btn-back {
    .mi {
      transition: all 0.3s ease-out;
      color: var(--md-gray-900) !important;
    }

    &:hover .mi {
      color: var(--md-primary) !important;
    }
  }
}

.teams-dropdown-scroll {
  max-height: 15rem;
  :deep(.ui-scroll-area) {
    max-height: 15rem;
  }
}
</style>
