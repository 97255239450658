export default {
  fromPriceInterval: 'Starting at <span class="d-flex align-items-center h4 lh-lg mb-0">{price}€</span>/<span class="text-lowercase">{interval}</span>',
  shared: {
    getAPlan: 'Get your plan',
    getAPlanPrice: 'Get it for 29 €/month',
    includes: 'Includes',
  },
  plan: {
    choosePlanText:
      'Our PRO plans grow with you, to help you take your web maintenance services to the next level. Without the hassle.',
    continueBilling: 'Continue with the payment',
    confirmText:
      'Your subscription will automatically renew as set and you will be charged the amount of the plan you have selected. You can cancel at any time before the next billing period.',
    hasEverything: 'It has it all',
    saveAnnualPayment: 'Save {percentage}% with an annual payment',
    yourPlanName: 'Your Plan: {plan_name}',
    selectTheBestPlan: 'Choose the plan that best suits your business needs.',
    eachYearPayment: 'Per month, paying annually',
    eachMonthPayment: 'Per month, paying monthly',
    contractedPlan: 'Contracted plan',
    currentPlan: 'Current plan',
    selectedPlan: 'Selected plan',
    startingAt: 'Starting at',
    noPlan: 'You don´t have a contracted plan',
    nonePlan: 'No contracted plan',
    myPlan: 'Plan and billing',
    myPlanAppSumo: 'Plan',
    saveUpTo: 'Save up {percent}%',
    saveUp: 'Save {percent}%',
    yearlyPayment: 'Annual Payment',
    yearly: 'Payment is made once a year',
    monthlyPayment: 'Monthly',
    monthly: 'Payment is made on a monthly basis',
    paymentEvery: 'Payment every {interval}',
    paymentOnlyOnce: 'You only pay the first time',
    initialFee: 'Initial Fee',
    initialFeePrice: 'Initial Fee: {price}',
    byYearlyDiscount: 'Anual payment discount',
    payNow: 'Total',
    choosePlan: 'Choose the plan that best suits your business',
    completeBuy: 'Complete your purchase',
    billingCycle: 'Select a billing cycle',
    paymentInfo: 'Billing information',
    servicesIncluded: 'Included services',
    addBillingInfo: 'You must add your billing details',
    editingBillingInfo: 'You must save your billing information',
    summary: 'Summary',
    confirmingPurchase: 'Confirming payment',
    mostPopular: 'Most popular',
    discountQuestion: 'Do you have a discount code?',
    discountAnswer: 'You can apply it on the next page',
    features: {
      upTo5Websites: 'Up to 5 websites',
      upTo7Websites: 'Up to 7 websites',
      upTo15Websites: 'Up to 15 websites',
      upTo30Websites: 'Up to 30 websites',
      upTo50Websites: 'Up to 50 websites',
      upTo90Websites: 'Up to 90 websites',
      unlimitedWebsites: 'Unlimited websites',
      uptimeMonitorEvery2Minutes: 'Uptime monitor every 2 minutes',
      uptimeMonitorEvery5Minutes: 'Uptime monitor every 5 minutes',
      uptimeMonitorEvery15Minutes: 'Uptime monitor every 15 minutes',
      emailNotifications: 'Email notifications',
      emailAndSmsNotifications: 'Email and SMS notifications',
      whiteLabelReports: 'White Label reports',
      scheduledReports: 'Scheduled reports',
      reportsClients: 'Reports for clients',
      proSecurityAnalysis: 'PRO security analysis',
      basicSecurityAnalysis: 'Basic security analysis',
      backupsEvery24h: 'Backups every 24h',
      backupsEveryMonth: 'Backups every month',
      instantBackups: 'Instant backups',
      storage10GB: '10GB of storage',
      storage35GB: '35GB of storage',
      storage70GB: '70GB of storage',
      storage100GB: '100GB of storage',
      storage110GB: '110GB of storage',
      storage175GB: '175GB of storage',
      storage250GB: '250GB of storage',
      twoFactorAuthentication: '2FA authentication',
      prioritySupportViaChatAndEmail: 'Priority support via chat and email',
      whiteLabelPlugin: 'White Label plugin',
      user1: '1 user',
      user2: '2 users',
      user3: '3 users',
      user5: '5 users',
    },
    seePlan: 'See my plan',
    actual: 'Actual',
    saveOffPercent: 'Save {percent}%',
    withAnnualPayment: 'With the annual payment',
    changeHere: 'Change it here',
    concept: 'Change it here',
    renewalDate: 'Renewal date',
    amount: 'Amount',
    planProratedCredit: 'Prorated credit of {plan_name}',
    expirationDate: 'Expires on {date}',
    cancelSubscription: 'Unsubscribe',
    cancelPlan: 'Cancel Plan {plane_name}',
    subscriptionOptions: 'Subscription options',
    cancelAlertTitle: 'Your websites will no longer be available',
    cancelAddonAlertTitle: 'You will lose your extra GB package',
    cancelAlertText: 'When you cancel your plan, some of your sites may no longer be available as of {date}.',
    cancelAddonAlertText:
      'When you cancel, you will return to the original GB limit of your Modular plan and, if you exceed it, no new backups will be made until you have available space.',
    reactivate: 'Reactivate',
    toastResumeText: 'Your subscription has been reactivated',
    cancelPlanHasAddonsModalTitle: 'Necessary action',
    cancelPlanHasAddonsModalAlertTitle: 'Cancel your extra GB package to delete your plan',
    cancelPlanHasAddonsModalAlertText:
      'You must first cancel your extra GB package. Once you do so, you will be able to cancel your PRO plan subscription.',
    reactiveAddonNotPlanModalTitle: 'Necessary action',
    reactiveAddonNotPlanModalAlertTitle: 'Activate your plan to recover your extra GB package',
    reactiveAddonNotPlanModalAlertText:
      'You must first activate your PRO plan. Once you do so, you will be able to reactivate your extra GB package.',
    storage: {
      title: 'Storage. Make sure you don\'t miss it!',
      subtitle:
        'All payment plans come with a number of Gigabytes that you can add according to your storage needs.',
      adjustYourPlan: 'Adjust your plan according to your needs!',
    },
    included: 'Included',
    whatPlanInclude: 'What does your {plan} plan include?',
    appsumo_tier_1: 'AppSumo Tier 1',
    appsumo_tier_2: 'AppSumo Tier 2',
    appsumo_tier_3: 'AppSumo Tier 3',
    appsumo_tier_4: 'AppSumo Tier 4',
    appsumo_tier_5: 'AppSumo Tier 5',
  },
  discount: {
    forever: 'forever',
    months: 'for {months} months',
    percent: 'Discount of {percent}%',
    amount: 'Discount of {amount}{unit}',
  },
  stripeStatus: {
    draft: 'Draft',
    open: 'Awaiting Payment',
    paid: 'Paid',
    void: 'Canceled',
    uncollectible: 'Bad Debt',
  },
  sidebar: {
    becomeAPro: 'Become PRO',
    sidebartext: 'Find out how to take your web maintenance to the next level',
    totalPlan: 'Total plan',
    creditCard: 'Credit card',
    getAPlanDescription: 'Sign up for a plan to publish unlimited websites with ModularDS',
    getAPlanButton: 'Become PRO for 29 €/month',
    planName: 'Plan {plan_name}',
    extraStorage: 'Extra storage',
    notIncludeVAT: '*Prices do not include VAT',
  },
  team: {
    billingData: 'Invoicing data',
    addBilling: 'Add billing data',
    addPayment: 'Add payment method',
    paymentAdded: 'Payment method added',
    noPaymentAdded: 'No payment method added',
    cardNumber: 'Card number',
    cardDate: 'Expiry date',
    cardCode: 'CVC',
    city: 'City',
    postalCode: 'Zip/Postal code',
    province: 'State/Province/Region',
    vat: 'VAT',
    direction1: 'Billing address 1',
    direction2: 'Billing address 2',
    bussinessName: 'Name or Company name',
    nif: 'NIF / VAT / IVA Number',
    managePlan: 'Manage plan',
    noBillingData: 'No billing data added',
    paymentMethod: 'Payment method',
    editBillingData: 'Edit billing info',
    saveBillingData: 'Save billing info',
  },
  invoices: {
    lastInvoices: 'Last invoices',
    number: 'Nº',
    concept: 'Concept',
    amount: 'Amount',
    downloadInvoice: 'Download invoice',
    status: 'Status',
  },
  freeState: {
    free: 'Free',
    viewPlan: 'View plan',
    yourProgress: 'Your progress',
    newbie: 'Newbie',
    planFreeProgress:
      'You have only 1 web left to exhaust your FREE plan. | You have {number} websites left to exhaust your FREE plan',
    proReviews: 'What the <span class="fw-semi">PROs</span> say',
    qaProTitle: 'Why get <span class="fw-semi">PRO</span>?',
    cofounderText:
      '"We want to help WordPress professionals and agencies save time in managing their websites and get more recurring contracts. With a tool where they can centralize all their projects and maintenance tasks and demonstrate the value of their daily work to their clients. So that they can understand it and are willing to pay for it."',
    hectorDePradaCofounder: 'Héctor de Prada, Co-Founder of Modular DS',
    planAppSumoProgress: 'You have only 1 website left to reach your connected sites limit. | You have {number} websites left to reach your connected sites limit.',
  },
  checkout: {
    paymentInProgressTitle: 'We are processing payment',
    paymentInProgressText: 'Payment is being processed',
    successPayment: 'The payment was successful',
    addExtraSpacing: 'Storage packages',
    customPricing: 'Custom',
    customPricingPrice: 'from 49,90 €/month',
    modal: {
      planTitle: 'Are you sure you don\'t want to level up?',
      planText:
        'If you complete the contract you will be able to connect more websites to Modular, enjoy more storage and extra functionalities to help you with maintenance.',
      addonTitle: 'Are you sure you don\'t want those extra GB?',
      addonText: 'Think about how useful it would be for you to be able to host all your web site backups.',
      forTimeNot: 'Not now',
      continuePurchase: 'Continue my purchase',
    },
  },
};
