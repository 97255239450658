<script setup lang="ts">
import api from '@/api';
import { useToast } from '@/composables/useToast';
import useSiteStore from '@/stores/site/siteStore';

defineOptions({
  name: 'TheSiteBackupCreateModal',
});

const emit = defineEmits(['closed', 'refreshBackups']);

const siteStore = useSiteStore();

const backupsServiceId = computed(() => siteStore.backupsServiceId);

const disableButton = ref(false);
const status = ref('loading');

const open = defineModel('open', { type: Boolean, required: true, default: false });

const { toastSuccess, toastError } = useToast();

async function createBackup() {
  try {
    if (!backupsServiceId.value) {
      throw new Error('Backup service ID is missing');
    }

    disableButton.value = true;

    await api.site.service.backup.create(backupsServiceId.value);

    toastSuccess('site.backup.backupCreatedMessage');

    emit('refreshBackups');

    status.value = 'loaded';
    disableButton.value = false;
  } catch (e: any) {
    console.error(e);

    if (!!e?.response?.data?.message) {
      toastError(e.response.data.message, { noTranslate: true });
    } else {
      toastError();
    }
  } finally {
    status.value = 'loaded';
    disableButton.value = false;

    emit('closed');
  }
}

function handleClose() {
  emit('closed');

  open.value = false;
}
</script>

<template>
  <Dialog
    v-model:open="open"
    @update:open="handleClose"
  >
    <DialogContent size="lg">
      <DialogHeader>
        <DialogTitle>
          {{ $t('site.backup.createNewBackup') }}
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <v-alert
          icon-variant="circle-actions-alert-info"
          icon-class="text-xxl"
          variant="secondary"
        >
          <p class="fw-semi mb-0">
            {{ $t('site.backup.createNewBackupTitle') }}
          </p>

          <p class="mb-0">
            {{ $t('site.backup.createNewBackupDescription') }}
          </p>
        </v-alert>
      </DialogBody>

      <DialogFooter>
        <VButton
          :disabled="disableButton"
          :is-loading="disableButton"
          size="sm"
          data-cy="btn-create-backup-confirm"
          variant="primary"
          @click="createBackup"
        >
          {{ $t('site.backup.createBackup') }}
        </VButton>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
