<script lang="ts" setup>
import { onBeforeMount, watch } from 'vue';

import router from '@/router';

import { useAppStore } from '@/stores/appStore';
import { useSiteStore } from '@/stores/site/siteStore';
import { useTeamStore } from '@/stores/teamStore';
import { useUserStore } from '@/stores/userStore';

import TheScreenSizeVerify from '@/components/global/TheScreenSizeVerify.vue';
import VLoader from '@/components/global/VLoader.vue';
import ModalComponent from '@/components/vendor/ModalComponent.vue';
import { useSocketTimeout } from '@/composables/sockets/useSocketTimeout';

defineOptions({
  name: 'App',
});

const appStore = useAppStore();
const user = useUserStore();
const teamStore = useTeamStore();
const siteStore = useSiteStore();

const { status } = storeToRefs(appStore);

const loader = document.querySelector('#md-main-loader') as HTMLElement;

watch(status, (value) => {
  if (!!loader) {
    if (value === 'loaded') {
      loader.style.opacity = '0';

      setTimeout(() => {
        loader.style.display = 'none';
      }, 100);
    } else if (value === 'loading') {
      loader.style.display = '';
      loader.style.opacity = '1';
    }
  }
});

watch(
  () => user.logged,
  (value, oldValue) => {
    if (!oldValue && value) {
      appStore.init();

      useSocketTimeout();
    }
  },
  {
    immediate: true,
  },
);

router.beforeEach((to, from, next) => {
  if (to.params.team && to.params.team !== from.params.team) {
    teamStore.getCurrentTeam(to.params.team as string);
  }

  if (from.params.team && !to.params.team) {
    teamStore.cleanCurrentTeam();
  }

  if (to.params.site && to.params.site !== from.params.site) {
    siteStore.cleanCurrentSite();

    siteStore.getCurrentSite({
      site: to.params.site as string,
      withTeam: true,
    });
  }

  if (from.params.site && !to.params.site) {
    siteStore.cleanCurrentSite();
  }

  next();
});

onBeforeMount(() => {
  document.title = import.meta.env.VITE_APP_NAME;

  if (import.meta.env.VITE_DEVELOPMENT_MODE === 'false' && import.meta.env.VITE_SHOW_HINT === 'true') {
    console.log(`

  ███╗   ███╗ ██████╗ ██████╗ ██╗   ██╗██╗      █████╗ ██████╗
  ████╗ ████║██╔═══██╗██╔══██╗██║   ██║██║     ██╔══██╗██╔══██╗
  ██╔████╔██║██║   ██║██║  ██║██║   ██║██║     ███████║██████╔╝
  ██║╚██╔╝██║██║   ██║██║  ██║██║   ██║██║     ██╔══██║██╔══██╗
  ██║ ╚═╝ ██║╚██████╔╝██████╔╝╚██████╔╝███████╗██║  ██║██║  ██║
  ╚═╝     ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝

  ⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐

      Welcome to ModularDS ✨

  🛑 WARNING: DO NOT TOUCH ANYTHING HERE 🛑

   If you encounter any issues, please contact us at "soporte@modulards.com".

  ⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐
  `);
  }
});
</script>

<template>
  <VLoader type="progress" class="main-loader" />

  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

  <ModalComponent />

  <TheScreenSizeVerify />
</template>
