import type { OrganizationSocketService } from '@/socket/services/OrganizationSocketService';
import type { SiteSocketService } from '@/socket/services/SiteSocketService';

import type { Ref } from 'vue';
import { ref } from 'vue';
import socket from '@/socket';
import SocketClientInstance from '@/socket/SocketClient';

interface ISocketStore {
  site: Ref<SiteSocketService | null>
  organization: Ref<OrganizationSocketService | null>
  forcedDisconnection: Ref<boolean>

  reset: () => void
  unsubscribeOrganization: (clearOrganizationSocket?: boolean) => void
  subscribeOrganization: (payload: string | number) => void
  unsubscribeSite: (clearSiteSocket?: boolean) => void
  subscribeSite: (payload: string | number) => void
  disconnectSockets: () => void
  reconnectSockets: () => void
  reconnectToChannels: () => void
}

const useSocketStore = defineStore('sockets', (): ISocketStore => {
  const site: Ref<SiteSocketService | null> = ref(null);
  const organization: Ref<OrganizationSocketService | null> = ref(null);

  const forcedDisconnection = ref(false);

  const reset = () => {
    site.value = null;
    organization.value = null;
    forcedDisconnection.value = false;
  };

  const router = useRouter();

  const listenRouteEvents = (stop = false) => {
    if (router.currentRoute.value.meta.events) {
      const events = router.currentRoute.value.meta.events as any;

      if (organization.value && !!events.organization) {
        organization.value.listenEvents(events.organization, stop);
      }

      if (site.value && !!events.site) {
        site.value.listenEvents(events.site, stop);
      }
    }
  };

  const unsubscribeOrganization = (clearOrganizationSocket = true) => {
    if (!!organization.value) {
      organization.value.unsubscribe();

      if (clearOrganizationSocket) {
        organization.value = null;
      }
    }
  };

  const subscribeOrganization = (payload: string | number) => {
    if (!!payload) {
      organization.value = socket.organization(payload);

      listenRouteEvents();
    }
  };

  const unsubscribeSite = (clearSiteSocket = true) => {
    if (!!site.value) {
      site.value.unsubscribe();

      if (clearSiteSocket) {
        site.value = null;
      }
    }
  };

  const subscribeSite = (payload: string | number) => {
    if (!!payload) {
      site.value = socket.site(payload);

      listenRouteEvents();
    }
  };

  const reconnectToChannels = () => {
    if (!!organization.value) {
      organization.value.subscribe();
    }

    if (!!site.value) {
      site.value.subscribe();
    }
  }

  const disconnectSockets = () => {
    SocketClientInstance.echo.disconnect();
  };

  const reconnectSockets = () => {
    SocketClientInstance.echo.connect();
  };

  router.afterEach(() => {
    listenRouteEvents();
  });

  router.beforeEach(() => {
    listenRouteEvents(true);
  });

  return {
    site,
    organization,

    forcedDisconnection,

    reset,
    unsubscribeOrganization,
    subscribeOrganization,
    unsubscribeSite,
    subscribeSite,
    disconnectSockets,
    reconnectSockets,
    reconnectToChannels,
  };
});

export { useSocketStore };
export default useSocketStore;
