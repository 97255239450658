// TODO This should not be a composable, has 0 vue-reactivity

export default function useSiteFunctions() {
  const parseUri = (host?: string, path?: string, truncate = false, maxLength = 24) => {
    if (!host || !path) {
      return '';
    }

    const url = `${host}${path !== '/' ? path : ''}`;

    if (truncate) {
      if (url.length > maxLength) {
        const startUrl = url.substring(0, maxLength / 2);
        const endUrl = url.substring(url.length - maxLength / 2);
        return `${startUrl}...${endUrl}`;
      }
    }
    return url;
  };

  return {
    parseUri,
  };
}
