<script setup lang="ts">
import { SelectGroup, type SelectGroupProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<SelectGroupProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <SelectGroup class="w-100 p-4" :class="[props.class]" v-bind="delegatedProps">
    <slot />
  </SelectGroup>
</template>
