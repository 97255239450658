<script lang="ts" setup>
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

import { useVModel } from '@vueuse/core';

import { computed, ref } from 'vue';

defineOptions({
  name: 'VInputSearch',
});

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
    default: 'general.shared.search',
  },
  id: {
    type: String,
    required: true,
  },
  inputClass: {
    type: String,
    required: false,
    default: 'py-8',
  },
  compact: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(['update:modelValue', 'blur', 'focus', 'input', 'clear']);

const modelValue = useVModel(props, 'modelValue', emits);

const hasFocus = ref(false);
const inputRef = ref<null | HTMLElement>(null);

const inputBox = computed(() => inputRef.value as HTMLInputElement);

const hasSearchValue = computed(() => modelValue.value.length > 0);

function clear() {
  modelValue.value = '';

  emits('clear');
}

function setFocus() {
  hasFocus.value = true;
  inputBox.value.focus();
}

function onClickOnClear() {
  clear();
  setFocus();
}
</script>

<template>
  <div :class="{ 'md-search-compact': compact }" class="md-search position-relative">
    <VIcon class="search-icon search position-absolute d-block text-gray-500" icon="search" />

    <input
      :id="id"
      ref="inputRef"
      v-model="modelValue"
      :class="inputClass"
      :disabled="disabled"
      :placeholder="placeholder"
      autocomplete="off"
      class="form-control search-input text-normal fw-semi rounded-sm pe-16 ps-48 text-gray-900 shadow-none"
      data-search-input="true"
      type="text"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      @input="$emit('input')"
      @update:model-value="$emit('update:modelValue', $event)"
    >

    <button
      v-if="hasSearchValue"
      aria-label="Clear"
      class="btn btn-icon search-icon clear position-absolute rounded-xs p-4 text-gray-500"
      type="button"
      @click="onClickOnClear"
      @keydown.space.enter="onClickOnClear"
    >
      <VIcon icon="close-x" size="xs" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.md-search {
  .search-input {
    border: 0.0625rem solid var(--md-gray-400);
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;

    &::placeholder {
      color: var(--md-gray-500);
      font-size: 0.875rem;
      font-weight: normal;
    }

    &:focus {
      background-color: var(--md-light);
    }
  }

  &:not(.md-search-compact) .search-input {
    height: 2.5rem;
  }

  &.md-search-compact {
    height: 3rem;
    min-width: 20rem;
  }

  .search-icon {
    top: 50%;
    transform: translateY(-50%);

    &.search {
      left: 1rem;
      width: 1rem;
      height: 1rem;
    }

    &.clear {
      right: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.125rem solid transparent;
      background: none;
      z-index: 10;
    }
  }

  .md-search-box {
    max-height: 30rem;
    overflow-y: auto;
    z-index: 1025;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    opacity: 0;
    transition: all 1s ease-out;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgb(0 0 0 / 4%);
    border: 0.0625rem solid var(--md-gray-400);

    &.show {
      display: block;
      opacity: 1;
    }

    .md-search-link {
      :deep(mark) {
        background-color: transparent;
        color: inherit;
        padding: 0 !important;
        font-weight: 600;
      }

      &:focus {
        box-shadow: inset 0 0 0 0.15rem rgba(var(--md-primary-rgb), 0.25) !important;
      }

      .team-name {
        line-height: 1.375rem;
      }
    }

    .md-search-item {
      &:hover {
        .md-search-link {
          background-color: var(--md-gray-200);
        }
      }

      &.item-selected .md-search-link {
        background-color: var(--md-gray-300);
      }

      &.md-site-placeholder {
        height: 2.375rem;
      }

      &.md-team-placeholder {
        height: 2.75rem;
      }
    }

    .list-group:not(:last-child) {
      position: relative;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 0.0625rem;
        background: var(--md-gray-400);
      }
    }
  }
}
</style>
