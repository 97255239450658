import type { SocketClient } from '@/socket/SocketClient';

import type { Channel } from 'laravel-echo';
import type { Router as RouterType } from 'vue-router';
import Router from '@/router';
import SocketClientInstance from '@/socket/SocketClient';

export default class AbstractService {
  protected socket: SocketClient;

  protected channelName: string;

  protected channel: Channel;

  protected router: RouterType;

  constructor(name: string | number) {
    this.socket = SocketClientInstance;

    this.channelName = `${name}`;

    this.channel = this.subscribe();

    this.router = Router;
  }

  /**
   * Subscribes to private auth user channel
   */
  public subscribe(): any {
    return this.socket.echo.private(this.channelName);
  }

  /**
   * Unsubscribes to private auth user channel
   */
  public unsubscribe(): void {
    this.socket.echo.leave(this.channelName);
  }
}
