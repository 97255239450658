export default {
  generation: {
    createdBy: 'Reporte creado con',
    download: 'Descargar PDF',
  },
  overview: {
    globalState: 'Visión global',
    updates: 'Actualizaciones',
    plugins: 'Plugins',
    themes: 'Temas',
    wordpress: 'WordPress',
    translations: 'Traducciones',
    uptimeMonitor: 'Uptime monitor',
    state: 'Estado actual',
    percentUptime: '% Uptime',
    backups: 'Copias de seguridad',
    activated: 'Activadas',
    periodicity: 'Periodicidad',
    backupsDone: 'Copias realizadas',
    metrics: 'Métricas',
    performance: 'Rendimiento',
    deviceTrackingTraffic: 'Tráfico',
    byDevice: 'Por dispositivo',
    customWorks: 'Otras tareas de mantenimiento',
    annotations: 'Anotaciones',
  },
  updates: {
    title: 'Actualizaciones',
    updatesDone: 'Se ha actualizado:',
    name: 'Nombre',
    date: 'Fecha',
    version: 'Versión',
    updatedItems: 'Actualizado { number } veces',
  },
  uptime: {
    title: 'Monitorización de Uptime',
    averageUptime: 'Uptime medio',
    upSince: 'Up desde',
    checkDurationDaysHours: '{ days }d { hours }h',
    lastDown: 'Última caída',
    lastDays: 'Últimos {days} días',
    lastHours: 'Últimas {hours}h',
    activity: {
      uptime: 'Tiempo de funcionamiento',
      downtime: 'Tiempo de inactividad',
      title: 'Actividad',
      lastHours: 'Últimas { number }h',
      statusFor: '{ status } desde',
      upFor: 'Funcionando desde',
      downFor: 'Inactivo desde',
      unknownFor: 'Desconocido desde',
      pausedFor: 'Pausado desde',
      activityChanges: 'Cambios de actividad',
      log: 'Log',
      responseTime: 'Tiempo de respuesta { unit }',
      uptimePercentage: 'Uptime {min}-{max}%',
      uptimeUnknown: 'Desconocido',
      up: 'Funcionando',
      down: 'Inactivo',
      unknown: 'Desconocido',
      paused: 'Pausado',
      upShort: 'Up',
      downShort: 'Down',
      uptimeStatus: 'Estado del Uptime',
    },
  },
  backup: {
    title: 'Copias de seguridad',
    lastBackup: 'Última copia de seguridad',
    wpVersion: 'Versión de WP',
    numPlugins: 'Plugins',
    size: 'Tamaño',
    activeTheme: 'Tema activo',
    postPublish: 'Post publicados',
  },
  analytics: {
    title: 'Analytics',
    visits: 'Páginas vistas',
    users: 'Usuarios',
    page: 'Página',
    numberOfVisits: 'Nº visitas',
    newUsers: 'Nuevos usuarios',
    numberOfUsers: 'Nº usuarios',
    recurrent: 'Recurrentes',
    bounceRate: 'Porcentaje de rebote',
    bounceRateAlt: 'Tasa de rebote',
    sessionLength: 'Duración de la sesión',
    popularContent: 'Contenido más popular',
    originChannel: 'Canal de origen',
    channel: 'Canal',
    citiesOfOrigin: 'Ciudades de procedencia',
    city: 'Ciudad',
    countriesOfOrigin: 'Países de procedencia',
    country: 'País',
    distributionDevice: 'Distribución por dispositivo',
    smartphone: 'Smartphone',
    desktop: 'Desktop',
  },
  performance: {
    title: 'Rendimiento',
    updatedAt: 'Actualizado { date }',
  },
  customWorks: {
    title: 'Otros trabajos',
  },
  searchConsole: {
    title: 'Resultados de búsqueda en Google',
    topQueries: 'Top 5 consultas principales',
    topPages: 'Top 5 páginas principales',
    topCountries: 'Top 5 países principales',
    topDevices: 'Top dispositivos',
    topDates: 'Top 5 fechas principales',
    totalClicks: 'Clicks totales',
    impressions: 'Impresiones',
    averageCtr: 'CTR medio',
    averagePosition: 'Posición media',
    query: 'Consulta',
    queries: 'Consultas',
    pages: 'Páginas',
    page: 'Página',
    countries: 'Países',
    country: 'País',
    appearanceSearches: 'Aparición en Búsquedas',
    dates: 'Fechas',
    devices: 'Dispositivos',
    device: 'Dispositivo',
    date: 'Fecha',
    clicks: 'Clicks',
    ctr: 'CTR',
    position: 'Posición',
    desktop: 'Ordenador',
    mobile: 'Móvil',
    tablet: 'Tablet',
    clicksTooltip:
      'En <span class="fw-bold">Clics totales</span> se indica el número de veces que los<br/>usuarios han hecho clic para llegar a tu sitio web.<br/>La forma de calcular este valor depende del tipo de<br/>resultado de búsqueda que sea.',
    impressionsTooltip:
      'En <span class="fw-bold">Impresiones totales</span> se indica el número de veces<br/>que se ha visto un enlace a tu sitio web en los resultados<br/>de búsqueda. El modo de calcular este valor varía en<br/>función de si los resultados son imágenes o de otro tipo,<br/>ya que en algunos casos se tiene en cuenta si los usuarios<br/>se tienen que desplazar por la página de resultados para<br/>llegar a verlos.',
    ctrTooltip:
      'El <span class="fw-bold">CTR medio</span> es el porcentaje de impresiones que<br/>han generado clics.',
    positionTooltip:
      'En <span class="fw-bold">Posición media</span> se indica en qué posición de los resultados<br/>de búsqueda aparece tu sitio web de media. Para calcularse,<br/>se tiene en cuenta la posición más alta que tiene el sitio web<br/>cada vez que se muestra en los resultados de búsqueda.<br/>Las posiciones de páginas concretas figuran en la tabla que<br/>está debajo del gráfico. El uso de algunas funciones de<br/>búsqueda, como los carruseles o los paneles de información,<br/>complican la tarea de determinar la posición.',
  },
};
