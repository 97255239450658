<script lang="ts" setup>
import api from '@/api';

defineOptions({
  name: 'VOffer',
});

const props = withDefaults(
  defineProps<{
    title?: string
    text?: string
    size?: 'sm' | 'lg'
    spacing?: string
    location?: string
  }>(),
  {
    title: 'general.sidebar.offerTitle',
    text: 'general.sidebar.offerText',
    size: 'sm',
    spacing: 'px-12 py-8',
    location: 'Sidebar',
  },
);

function fillSurvey() {
  api.mixpanel.track('Go to roadmap', {
    Location: props.location,
  });
}
</script>

<template>
  <div class="col col-xl-12 aside mb-16">
    <div class="aside-content aside-offer position-relative z-index-1 bg-warning radius-sm overflow-hidden px-32 py-36">
      <h4 :class="{ 'mb-24': size === 'sm', 'mb-16': size === 'lg' }" class="fw-semi text-lg">
        {{ $t(title) }}
      </h4>

      <div :class="{ 'mb-4': size === 'sm', 'mb-16': size === 'lg' }" class="fw-medium text-sm">
        {{ $t(text) }}
      </div>

      <a
        :aria-label="$t('general.sidebar.offerLink')"
        class="price-plan fw-semi text-decoration-none stretched-link text-dark"
        :href="$t('general.sidebar.offerLinkUrl')"
        rel="noreferrer noopener"
        target="_blank"
        @click="fillSurvey()"
      >
        <span class="text-sm">{{ $t('general.sidebar.offerLink') }}</span>
        <v-icon class="fw-bold" icon="long-arrow" size="lg" space="ps-16" />
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aside-offer {
  &:hover {
    .price-plan {
      color: var(--md-primary) !important;
    }
  }

  .icon {
    vertical-align: middle;
  }
}

[data-bs-theme='dark'] {
  .aside-offer {
    > * {
      color: var(--md-light) !important;
    }

    a:hover {
      color: var(--md-primary-light) !important;
    }
  }
}
</style>
