<script lang="ts" setup>
import type { IComponent, IComponentSelection } from '@/types/models/organization/manager/Component';
import type { PaginatedSiteItems, SiteItem, SiteItemMeta } from '@/types/models/site/updater/SiteItem';
import type { ComponentRequestType } from '@/views/manager';

import type { ComputedRef } from 'vue';
import api from '@/api';
import { CollapsibleContent, CollapsibleTrigger } from '@/components/ui/Collapsible';
import MCollapsible from '@/components/ui/Collapsible/MCollapsible.vue';
import useManagerStore from '@/stores/manager/managerStore';

import { ComponentRequestIncludeEnumConst } from '@/types/enums/ComponentRequestIncludeEnum';

import { SiteItemTypeEnumConst } from '@/types/enums/SiteItemTypeEnum';

import TheManagerUpdateChangelogButton from '@/views/manager/parts/TheManagerUpdateChangelogButton.vue';
import TheManagerUpdateComponentVulnerabilities
  from '@/views/manager/parts/TheManagerUpdateComponentVulnerabilities.vue';

import TheManagerUpdateSiteItem from '@/views/manager/parts/TheManagerUpdateSiteItem.vue';
import { useInfiniteScroll } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';

defineOptions({
  name: 'TheManagerUpdateComponent',
});

defineEmits(['openVulnerability', 'openChangelog']);
const { PLUGIN, CORE, THEME } = SiteItemTypeEnumConst;
const { SITE, VULNERABILITIES } = ComponentRequestIncludeEnumConst;

const component = defineModel<IComponent>({
  required: true,
});

const managerStore = useManagerStore();
const { managerRequest, componentSelection, componentsMap, isGlobal, type, visibilityStatus }
  = storeToRefs(managerStore);

// region Lazy Loading
const isRequestingData = ref(false);
const dataRequested = ref<boolean>(false);
const currentPage = ref(1);
const loadMoreTrigger = ref(null);

const dataResponse = reactive<PaginatedSiteItems>({
  data: [] as SiteItem[],
  links: [],
  included: [],
  meta: {
    currentPage: 1,
    lastPage: 0,
  } as SiteItemMeta,
});

const hasData = computed(() => !!dataResponse.data && dataResponse.data.length > 0);

const canLoadMore = computed(() => {
  if (!!dataResponse?.meta && !!dataResponse?.meta?.currentPage && !!dataResponse?.meta?.lastPage) {
    return dataResponse?.meta && dataResponse?.meta?.currentPage < dataResponse?.meta?.lastPage;
  }

  return false;
});

const numberToPlacehold = computed(() => {
  if (!!dataResponse.meta?.total && !!dataResponse.meta?.to && !!dataResponse.meta?.perPage) {
    const remainingItems = dataResponse.meta?.total - dataResponse.meta?.to;
    return Math.min(dataResponse.meta.perPage, remainingItems > 0 ? remainingItems : 0) || 0;
  }

  return (
    (!!component.value?.siteItemsCount && component.value?.siteItemsCount < 12 ? component.value.siteItemsCount : 12)
    ?? 0
  );
});

async function loadSiteItems() {
  if (
    isRequestingData.value
    || (!open.value && (!component.value?.siteItemsMap || component.value?.siteItemsMap?.size === 0))
  ) {
    return;
  }

  try {
    isRequestingData.value = true;

    const localParams: ComponentRequestType = {
      ...managerRequest.value,
      components: [Number(component.value.id)],
      include: [SITE, VULNERABILITIES],
    };

    const response = await api.organization.managerUpdate.retrieveSiteItems(localParams, {
      page: currentPage.value,
      per_page: 50,
    });

    currentPage.value = currentPage.value + 1;

    dataResponse.data!.push(...response.data);

    const found = componentsMap.value.get(Number(component.value.id));

    if (!!found) {
      if (!found.siteItemsMap || (!!found.siteItemsMap && found.siteItemsMap.size === 0)) {
        // found.siteItems = [...response.data];
        found.siteItemsMap = new Map(response.data.map(item => [Number(item.id), item]));
      } else {
        // found.siteItems.push(...response.data);
        response.data.forEach((item) => {
          found.siteItemsMap?.set(Number(item.id), item);
        });
      }
    }

    dataResponse.meta = response.meta;
  } catch (e: any) {
    console.error(e);
    return undefined;
  } finally {
    isRequestingData.value = false;
    dataRequested.value = true;
  }
}

const { isLoading: isLoadingMore } = useInfiniteScroll(
  loadMoreTrigger,
  async () => {
    if (!isLoadingMore.value && canLoadMore.value) {
      await loadSiteItems();
    }
  },
  { distance: 0, interval: 200 },
);

watch(
  () => managerRequest.value,
  () => {
    loadSiteItems();
  },
);

watch(
  () => component.value.siteItemsMap,
  (value, oldValue) => {
    if (!value && !!oldValue && oldValue?.size > 0) {
      dataResponse.data = [];
      open.value = false;
      currentPage.value = 1;
    }
  },
);
// endregion Lazy Loading

// region Vulnerabilities and Changelog
const vulnerabilitiesLoaded = computed(() => {
  return component.value.vulnerabilitiesCExists || component.value.vulnerabilitiesExists;
});

const vulnerabilities = computed(() => ({
  vulnerabilitiesCExists: component.value.vulnerabilitiesCExists,
  vulnerabilitiesExists: component.value.vulnerabilitiesExists,
}));

const componentChangelog = computed(() => ({
  type: component.value.type,
  name: component.value.name,
  slug: component.value.slug,
  version: !!component.value.newVersion ? component.value.newVersion : component.value.minVersion,
}));

const componentVulnerability = computed(() => ({
  type: component.value.type,
  name: component.value.name,
  slug: component.value.slug,
  version: component.value.minVersion,
}));
// endregion Vulnerabilities and Changelog

const open = ref(false);

const inputId = computed(() => `manager-update-component-${component.value.id}-checkbox`);

const currentComponentSelection: ComputedRef<IComponentSelection> = computed(() => {
  const found = componentSelection.value.find((c: IComponentSelection) => c.id === component.value.id);
  return found!;
});

const selectedSites = computed(() => {
  const siteItems = new Set<number>();
  const selection = currentComponentSelection.value;

  if (selection) {
    const sites = selection.all ? selection.excluded : selection.included;
    sites?.forEach(siteId => siteItems.add(siteId));
  }

  return siteItems;
});

const componentCheck = computed(() => {
  if (!currentComponentSelection.value)
    return false;

  if (currentComponentSelection.value.check === 'all') {
    return true;
  } else if (currentComponentSelection.value.check === 'empty') {
    return false;
  }

  return currentComponentSelection.value.check;
});

function handleSetAllSites(value: boolean) {
  // Si habia algo seleccionado y se hace click en el checkbox del componente, se ponen todos a false, y si no, a true
  currentComponentSelection.value.all = !((componentCheck.value === 'indeterminate' && value) || !value);
  currentComponentSelection.value.check = currentComponentSelection.value.all ? 'all' : 'empty';
  currentComponentSelection.value.excluded = [];
  currentComponentSelection.value.included = [];
}

function isSiteItemChecked(siteItemId: number) {
  return currentComponentSelection.value.all
    ? !selectedSites.value.has(Number(siteItemId))
    : selectedSites.value.has(Number(siteItemId))
}

function handleUpdateSelectedSites(siteId: number, isSelected: boolean) {
  const allSelected = currentComponentSelection.value.all;
  const totalItemsToCount = !component.value.siteItemsCount
    ? 0
    : component.value.siteItemsCount - (component.value.processing ?? 0);

  // Actualizar los sitios seleccionados
  (allSelected && !isSelected) || (!allSelected && isSelected)
    ? selectedSites.value.add(siteId)
    : selectedSites.value.delete(siteId);

  // Si no hay sitios seleccionados, ajustar los valores por defecto
  if (selectedSites.value.size === 0) {
    currentComponentSelection.value.included = [];
    currentComponentSelection.value.excluded = [];
    currentComponentSelection.value.check = allSelected ? 'all' : 'empty';
    return;
  }

  // Determinar el estado de selección del componente
  const isAllSelected = selectedSites.value.size === totalItemsToCount;
  if (allSelected) {
    currentComponentSelection.value.excluded = Array.from(selectedSites.value);
    currentComponentSelection.value.check = isAllSelected ? 'empty' : 'indeterminate';
  } else {
    currentComponentSelection.value.included = Array.from(selectedSites.value);
    currentComponentSelection.value.check = isAllSelected ? 'all' : 'indeterminate';
  }
}
</script>

<template>
  <Component
    :is="isGlobal ? MCollapsible : 'div'"
    v-model:open="open"
    class="manager-component w-100 border-bottom border-gray-400"
    @update:open="(val: boolean) => (val && !hasData ? loadSiteItems() : () => {})"
  >
    <div class="d-flex justify-content-between align-items-center position-relative flex-row px-0 py-0 text-gray-600">
      <Component
        :is="isGlobal ? CollapsibleTrigger : 'label'"
        :class="[{ active: !!componentCheck && component.siteItemsCount !== component.processing, processing: component.processing }, isGlobal ? 'btn px-8' : 'pe-24 ps-8']"
        :for="inputId"
        class="component-item w-100 rounded-0 border-0 py-8 shadow-none"
        role="button"
        @click.stop
      >
        <div class="d-flex justify-content-between align-items-center w-100">
          <div
            class="component-name-container fw-semi d-flex flex-grow-1 flex-shrink-1 justify-content-start align-items-center w-100 text-sm
              text-gray-600"
          >
            <span class="d-flex align-items-center plugin-name-container py-4 text-sm">
              <label
                :class="{
                  'text-gray-500': visibilityStatus === 'all' && component.hasHidden,
                  'disabled':
                    component.processing === undefined
                    || (!!component.siteItemsCount && component.processing >= component.siteItemsCount),
                }"
                :for="inputId"
                class="component-name d-flex align-items-center text-start fw-semi w-100 text-sm"
                role="button"
                :disabled="
                  component.processing === undefined
                    || (!!component.siteItemsCount && component.processing >= component.siteItemsCount)
                "
                @click.stop
              >
                <Checkbox
                  :id="inputId"
                  :checked="componentCheck && component.siteItemsCount !== component.processing"
                  :data-cy="inputId"
                  :disabled="
                    component.processing === undefined
                      || (!!component.siteItemsCount && component.processing >= component.siteItemsCount)
                  "
                  class="me-8"
                  square
                  @click.stop
                  @update:checked="handleSetAllSites"
                />
                <span v-dompurify-html="component.name" class="text-truncate-one-line" />
                <span v-if="isGlobal && !!component.siteItemsCount" class="ms-2">({{ component.siteItemsCount }})</span>
              </label>
            </span>

            <span class="statuses-container d-flex align-items-center px-8">
              <Tooltip
                v-if="visibilityStatus === 'all' && component.hasUpdatables"
                :content="$t('manager.updateAvailable')"
                html
                trigger-class="status-item-container"
              >
                <span
                  class="status-item d-flex align-items-center justify-content-center text-bg-primary rounded-pill text-4xs border
                    border-gray-100"
                >
                  <m-icon class="lh-sm" icon="exchange-transfer" />
                </span>
              </Tooltip>

              <Tooltip
                v-if="visibilityStatus === 'all' && component.hasHidden"
                :content="$t('manager.updateHidden')"
                trigger-class="status-item-container"
              >
                <span
                  class="status-item d-flex align-items-center justify-content-center border-light text-light rounded-pill text-2xs border
                    bg-gray-500"
                >
                  <m-icon class="lh-sm" icon="disabled" />
                </span>
              </Tooltip>

              <TheManagerUpdateComponentVulnerabilities
                v-if="vulnerabilitiesLoaded"
                :component-vulnerabilities="vulnerabilities"
                @open-vulnerability="$emit('openVulnerability', componentVulnerability)"
              />
            </span>
          </div>

          <span class="d-flex align-items-center">
            <Tooltip
              v-if="!isGlobal && !!component.siteItemsCount && component.hasInactive && component.type !== THEME"
              :content="
                isGlobal
                  ? $t('manager.componentDisabled', { type: $t(`manager.${type}`, 2) })
                  : $t('manager.componentItemDisabled', { type: $t(`manager.${type}`, 2) })
              "
              trigger-class="d-flex align-items-center me-16 me-xl-24"
            >
              <m-icon size="md" class="text-danger fw-medium" icon="power-on-off" />
            </Tooltip>

            <Tooltip
              v-if="!!component.siteItemsCount && !isGlobal && !component.hasInactive && component.type === THEME"
              :content="$t('manager.themeActive')"
              trigger-class="d-flex align-items-center me-16 me-xl-24"
            >
              <m-icon class="text-secondary" icon="lightbulb" size="md" />
            </Tooltip>

            <v-load-bar
              v-if="!!component.processing && component.processing > 0"
              :decrease="false"
              class="position-relative me-16"
              type="small"
            />

            <span class="d-flex align-items-center update-actions fw-medium text-nowrap text-xs text-gray-600">
              <span v-if="!!component.newVersion" class="actual-version lh-xl">{{ component.minVersion }}</span>
              <v-icon v-if="!!component.newVersion" class="px-8" icon="arrow-right" />

              <Tooltip
                :content="$t('site.overview.updates.viewChangelog')"
                :disabled="component.type !== PLUGIN && component.type !== CORE"
              >
                <TheManagerUpdateChangelogButton
                  v-if="component.type === PLUGIN"
                  v-model="componentChangelog"
                  :classes="!!component.newVersion ? 'text-success-dark' : 'text-gray-600'"
                  @open-changelog="$emit('openChangelog', componentChangelog)"
                />

                <a
                  v-else-if="component.type === CORE"
                  class="d-flex btn btn-link btn-2xs fw-medium new-version text-success-dark text-decoration-underline lh-xl text-nowrap border-0 p-0"
                  :class="[
                    !!component.newVersion ? 'text-success-dark' : 'text-gray-600',
                  ]"
                  href="https://wordpress.org/news/category/releases/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {{ componentChangelog.version }}
                </a>

                <span
                  v-else
                  :class="[!!component.newVersion ? 'text-success-dark' : 'text-gray-600']"
                  class="new-version"
                >
                  {{ componentChangelog.version }}
                </span>
              </Tooltip>
            </span>

            <v-icon
              v-if="isGlobal"
              :class="{ open }"
              class="ms-24 p-6 text-gray-500"
              icon="arrow-caret-down"
              size="xs"
            />
          </span>
        </div>
      </Component>
    </div>

    <Component :is="isGlobal ? CollapsibleContent : 'div'">
      <ul v-if="hasData && dataRequested" v-auto-animate class="p-0">
        <li v-for="siteItem in component.siteItemsMap?.values()" :key="siteItem.id">
          <TheManagerUpdateSiteItem
            :model-value="siteItem"
            :selected="isSiteItemChecked(Number(siteItem.id)) && !siteItem.processing"
            @update:selected="handleUpdateSelectedSites(Number(siteItem.id), $event)"
            @open-changelog="$emit('openChangelog', $event)"
            @open-vulnerability="$emit('openVulnerability', $event)"
          />
        </li>

        <template v-if="isLoadingMore && canLoadMore">
          <li
            v-for="n in 3"
            :key="n"
            class="d-flex component-site-item pe-xxl-48 ps-xxl-40 py-16 pe-24 ps-16 text-gray-600"
          >
            <span class="placeholder-glow w-100">
              <span class="placeholder w-100" style="height: 20px" />
            </span>
          </li>
        </template>

        <li v-if="canLoadMore" ref="loadMoreTrigger" />
      </ul>

      <ul v-else-if="isRequestingData" class="position-relative list-group">
        <li
          v-for="n in numberToPlacehold"
          :key="n"
          class="d-flex component-site-item pe-xxl-48 ps-xxl-40 placeholder-glow w-100 py-8 pe-24 ps-16 text-gray-600"
        >
          <span class="placeholder w-100" style="height: 32px" />
        </li>
      </ul>
    </Component>
  </Component>
</template>

<style lang="scss" scoped>
.manager-component {
  .component-item,
  .component-site-item {
    height: 3rem;
  }

  .component-item {
    .component-name.disabled {
      cursor: not-allowed;
    }

    &.active {
      background-color: var(--md-primary-light);
    }

    &:not(.active):not(.processing):hover {
      background-color: var(--md-gray-200);
    }

    &.active:not(.processing):hover {
      background-color: var(--md-primary-100);
    }
  }

  :deep(.new-version.text-decoration-underline) {
    text-underline-offset: 2px;
  }
}

.component-name-container {
  min-width: 0;

  &.disabled {
    .tri-checkbox,
    label {
      cursor: auto;
    }
  }

  :deep(.component-name) {
    b {
      color: var(--md-dark);
      font-weight: 700;
    }
  }
}

:deep(.statuses-container) {
  .status-item-container {
    transition: all 0.2s ease-out;
  }

  &:hover .status-item-container:not(:first-child) {
    margin-left: 0.25rem;
  }

  .status-item-container:not(:first-child) {
    margin-left: -0.25rem;
  }

  .status-item {
    width: 1rem;
    height: 1rem;

    &.border {
      width: 17px;
      height: 17px;
    }
  }

  .status-item-container:nth-child(1) {
    z-index: 3;
  }

  .status-item-container:nth-child(2) {
    z-index: 2;
  }

  .status-item-container:nth-child(3) {
    z-index: 1;
  }
}

[data-bs-theme='dark'] {
  .manager-component {
    .component-item {
      &.active {
        background-color: var(--md-gray-100);
      }

      &:not(.active):not(.processing):hover,
      &.active:not(.processing):hover {
        background-color: var(--md-gray-300);
      }
    }
  }
}
</style>
