<script setup lang="ts">
import { TooltipPortal, type TooltipPortalProps, useForwardPropsEmits } from 'radix-vue';

const props = defineProps<TooltipPortalProps>();

const forwarded = useForwardPropsEmits(props);
</script>

<template>
  <TooltipPortal v-bind="forwarded" class="ui-tooltip-portal">
    <slot />
  </TooltipPortal>
</template>
