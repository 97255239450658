<script lang="ts" setup>
import { cn } from '@/helpers/classNames';
import {
  TooltipContent,
  type TooltipContentEmits,
  type TooltipContentProps,
  useForwardPropsEmits,
} from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<TooltipContentProps & { class?: HTMLAttributes['class'], arrowClass?: HTMLAttributes['class'], arrowStyle?: string }>(), {
  sideOffset: 4,
})

const emits = defineEmits<TooltipContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, arrowClass: __, ...delegated } = props;

  return delegated;
})

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <TooltipPortal>
    <TooltipContent
      :class="cn('ui-tooltip-content overflow-hidden rounded bg-gray-700 text-sm fw-medium ui-animate-delayed ui-animate-out', props.class)"
      v-bind="{ ...forwarded, ...$attrs }"
    >
      <slot />

      <TooltipArrow
        :class="arrowClass"
        :style="arrowStyle"
        :width="16"
        :height="8"
      />
    </TooltipContent>
  </TooltipPortal>
</template>
