export default {
  totalClicks: 'Total clicks',
  impressions: 'Impressions',
  averageCtr: 'Average CTR',
  averagePosition: 'Average position',
  query: 'Query',
  queries: 'Queries',
  pages: 'Pages',
  page: 'Page',
  countries: 'Countries',
  country: 'Country',
  appearanceSearches: 'Appearance in Searches',
  dates: 'Dates',
  devices: 'Devices',
  device: 'Device',
  date: 'Date',
  clicks: 'Clicks',
  ctr: 'CTR',
  position: 'Position',
  noData: 'No data available',
  selectPropertyDomain: 'Change the Search Console property',
  selectPropertyDomainText:
    'You can change the Search Console property you have chosen from the properties your Google account has access to.',
  changePropertyDomain: 'Change the Search Console property',
  changePropertyDomainText:
    'You can change the Search Console property you have chosen from the properties your Google account has access to.',
  selectProperty: 'Select a property',
  noResults: 'No results for {search}',
  unlinkAccount: 'Unlink Google account',
  unlinkAccountExplain:
    'You are going to unlink your Google account for this website. You will not be able to see your performance in the search results in Modular until you reconnect the account and select a Search Console property. Do you want to continue?',
  frequentlyQuestionsResults: 'Frequently asked questions about enriched results',
  translatedResults: 'Translated results',
  desktop: 'Desktop',
  mobile: 'Phone',
  tablet: 'Tablet',
  changeSearchConsoleProperty: 'Change Search Console property',
  goToSearchConsole: 'Go to Search Console',
  reportError:
    'Wops! There seems to be a problem getting the report from Search Console. Please check your Google account settings and try again.',
  empty: {
    emptyYTLink: 'https://www.youtube.com/embed/V8bZL3W5kvQ',
    title: 'Search Console',
    emptyText:
      'Connect a Google Search Console property to consult from Modular the performance of your website in Google searches. ',
    statisticsTitle1: 'Analyze your web performance on Google',
    statisticsReason1:
      'Basic Google Search Console statistics, including queries, landing pages, countries and devices with the best metrics. To better understand your website\'s organic traffic.',
    statisticsTitle2: 'Include them in your reports',
    statisticsReason2:
      'Once you connect your Google Search Console property, you will be able to show the performance stats in your client reports as well.',
  },
};
