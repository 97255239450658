export default {
  title: 'Novedades',
  offer: {
    title: 'Vota por las próximas funcionalidades',
    text: 'Por si no lo sabías... ¡tú formas parte de Modular! Así que, ¿nos ayudas a construir nuestro futuro?',
  },
  noMorePosts: 'Esto es todo por ahora. ',
  noMorePostsText: '¡Gracias por acompañarnos en esta aventura!',
  new: 'Novedad',
};
