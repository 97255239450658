<script lang="ts" setup>
import type { ManualReportIndexRequest } from '@/api/services/site/service/report/manual';
import type { SiteReport } from '@/types/models/site/service/report/SiteReport';
import type { SiteScheduledReport } from '@/types/models/site/service/report/SiteScheduledReport';

import type { Ref } from 'vue';
import api from '@/api';
import VCollapse from '@/components/vendor/basic/collapse/VCollapse.vue';
import useDate from '@/composables/useDate';
import usePermissions from '@/composables/usePermissions';
import useConstantsHelper from '@/resources/constants/ConstantsHelper';

import useSiteStore from '@/stores/site/siteStore';

import { SiteServiceStatusEnumConst } from '@/types/enums/SiteServiceStatusEnum';
import { getReportStatus } from '@/views/site/reports/utils/reportFunctions';
import { watchImmediate } from '@vueuse/core';

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineOptions({
  name: 'TheSiteOverviewReport',
});

const { parseDateToPreferredFormat } = useDate();
const { can } = usePermissions();

const userCanUseScheduledReports = computed(() => can(PermissionEnumConst.ORGANIZATION_SITE_REPORT_SCHEDULE_ALLOW));

const siteStore = useSiteStore();
const { currentSiteSlug, currentSiteId } = storeToRefs(siteStore);

const loaded = ref(false);
const reports: Ref<SiteReport[]> = ref([]);
const lastReport: Ref<null | SiteReport> = ref(null);
const nextScheduledReport: Ref<null | SiteScheduledReport> = ref(null);

async function loadReports() {
  if (!currentSiteId.value)
    return;

  try {
    const params: ManualReportIndexRequest = {
      page: 1,
      perPage: 10,
      sites: [currentSiteId.value],
    };

    const { data } = await api.report.all(params) || {};
    if (!data)
      return;

    reports.value = data.slice(0, 3);

    if (!data.length)
      return;

    lastReport.value = data[data.length - 1];

    if (!nextScheduledReport.value) {
      reports.value = reports.value.filter((report: SiteReport) => report.id !== lastReport.value?.id);
    }
  } catch (e: any) {
    console.error('Error loading manual reports', e);
  }
}

async function getScheduledReports() {
  if (!currentSiteId.value)
    return;

  try {
    const response = await api.site.service.report.schedule.all({ sites: [currentSiteId.value] });

    // FIXME Temp solution. We need to get site service report ordered by next request and limit
    //  the total results to one
    const scheduledReports = response.filter(
      (report: SiteScheduledReport) => report.status === SiteServiceStatusEnumConst.ACTIVE,
    );

    const actualDate = DateTime.now();
    let minDifference = Infinity;

    scheduledReports.forEach((report: SiteScheduledReport) => {
      if (!report.nextRequest)
        return;

      const reportDate = DateTime.fromISO(report.nextRequest.toString());
      const difference = Math.abs(reportDate.diff(actualDate).milliseconds);

      if (difference < minDifference) {
        minDifference = difference;
        nextScheduledReport.value = report;
      }
    });
  } catch (e: any) {
    console.error('Error loading schedule reports', e);
  }
}

async function loadAllReports() {
  if (userCanUseScheduledReports.value) {
    await getScheduledReports();
  }

  await loadReports();

  loaded.value = true;
}

watchImmediate(
  () => currentSiteSlug.value,
  async (value, oldValue) => {
    if (!!value && value !== oldValue) {
      loaded.value = false;
      reports.value = [];
      lastReport.value = null;
      nextScheduledReport.value = null;

      await loadAllReports();
    }
  },
);
</script>

<template>
  <div id="overview-aside-report" data-cy="overview-aside-report" class="col-12 col-lg-6 col-xl-12 aside mb-16">
    <div class="aside-content aside-report radius-sm position-relative bg-white p-16 pt-24">
      <div class="d-flex justify-content-between align-items-center mb-8">
        <h5 class="text-normal fw-semi lh-xl mb-0 text-gray-600">
          {{
            (!lastReport && !nextScheduledReport) || !loaded
              ? $t('site.sidebar.maintenanceReports')
              : $t('siteReports.reports')
          }}
        </h5>

        <router-link
          v-if="loaded && (!!lastReport || !!nextScheduledReport)"
          :to="{ name: 'sites.site.report.type' }"
          class="btn btn-sm btn-icon btn-link btn-link-primary-dark fw-semi lh-xl text-lowercase p-0 shadow-none"
          data-cy="btn-overview-create-report"
        >
          <m-icon class="fw-semi" icon="actions-plus" size="xs" space="me-2" />
          {{ $t('general.shared.new') }}
        </router-link>
      </div>

      <span v-if="!loaded" class="placeholder-glow">
        <span class="placeholder" style="width: 150px" />
      </span>

      <template v-else>
        <router-link
          v-if="!lastReport && !nextScheduledReport"
          :to="{ name: 'sites.site.report.type' }"
          class="btn btn-sm btn-icon btn-outline-success w-100 justify-content-center"
          data-cy="btn-overview-create-report"
        >
          <m-icon icon="bullets" space="me-12" />
          {{ $t('site.sidebar.createReport') }}
        </router-link>

        <template v-else>
          <div class="d-flex align-items-center">
            <v-icon class="report-icon text-warning h-fit" icon="bullets" space="me-8" />

            <div class="w-100">
              <div class="fw-semi text-sm">
                <template v-if="!!nextScheduledReport">
                  {{ nextScheduledReport.title }}
                </template>
                <template v-else-if="!!lastReport">
                  {{ lastReport.title }}
                </template>
              </div>

              <div class="d-flex align-items-center justify-content-between gap-8">
                <div class="fw-medium text-xs text-gray-500">
                  <template v-if="!!nextScheduledReport && nextScheduledReport.nextRequest">
                    {{ $str.formatDate(nextScheduledReport.nextRequest, 'dd/MM/yy') }}
                  </template>

                  <template v-else-if="!!lastReport && !!lastReport.startedAt && !!lastReport.endedAt">
                    {{
                      `${parseDateToPreferredFormat(lastReport.startedAt, 'dd/MM/yy')} - ${parseDateToPreferredFormat(
                        lastReport.endedAt,
                        'dd/MM/yy',
                      )}`
                    }}
                  </template>

                  <template v-else>
                    ? - ?
                  </template>
                </div>

                <div v-if="!!nextScheduledReport" class="fw-semi align-self-end text-sm text-gray-500">
                  {{ $t('site.sidebar.next') }}
                </div>

                <div
                  v-else-if="!!lastReport"
                  :class="`text-${getReportStatus(lastReport.status).background}`"
                  class="text-success fw-semi align-self-end text-sm"
                >
                  {{ $t(getReportStatus(lastReport.status).label) }}
                </div>
              </div>
            </div>
          </div>

          <template v-if="!!reports && reports.length > 0">
            <hr class="my-8">

            <VCollapse
              id="site-overview-last-reports"
              button-classes="btn-link d-flex align-items-center fw-medium shadow-none w-100 pb-8 pt-0 px-0"
              icon="chevron-down"
              icon-size="sm"
              icon-space="ms-auto text-gray-500"
            >
              <template #button>
                <span class="lh-xl text-sm text-gray-500">
                  {{ $t('site.sidebar.lastReports') }}
                </span>
              </template>

              <template #content>
                <div class="d-flex flex-column">
                  <ul class="list-group pt-8">
                    <li
                      v-for="report in reports"
                      :key="report.id"
                      class="d-flex justify-content-between align-items-center mb-4 gap-8"
                    >
                      <span class="report-title fw-semi text-sm">{{ report.title }}</span>
                      <span v-if="!!report.startedAt && !!report.endedAt" class="fw-medium text-xs text-gray-500">
                        {{
                          `${parseDateToPreferredFormat(report.startedAt, 'dd/MM/yy')} - ${parseDateToPreferredFormat(
                            report.endedAt,
                            'dd/MM/yy',
                          )}`
                        }}
                      </span>
                    </li>
                  </ul>

                  <router-link
                    :aria-label="$t('menu.reports')"
                    :to="{ name: 'sites.site.reports.show' }"
                    class="btn btn-sm btn-link btn-link-primary-dark fw-semi align-self-end lh-xl text-lowercase mt-8 p-0 shadow-none"
                  >
                    {{ $t('general.button.viewAll') }}
                  </router-link>
                </div>
              </template>
            </VCollapse>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.mi-actions-plus::before) {
  font-weight: 600 !important;
}

.report-icon {
  &.icon-bullets {
    padding: 0.75rem;
    border: 0.125rem solid;
    border-radius: 50rem;
  }
}

.report-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 13ch;
  overflow: hidden;
}
</style>
