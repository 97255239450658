<script lang="ts" setup>
import { cn } from '@/helpers/classNames';
import { CollapsibleContent, type CollapsibleContentProps } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<CollapsibleContentProps & { class?: HTMLAttributes['class'], disableAnimation?: boolean }>();

const delegatedProps = computed(() => {
  const { class: _, disableAnimation, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <CollapsibleContent
    :class="cn('md-collapsible', { 'md-collapsible-deanimated': disableAnimation }, props.class)"
    v-bind="props"
  >
    <slot />
  </CollapsibleContent>
</template>

<style lang="scss" scoped>
.md-collapsible {
  overflow: hidden;

  &:not(.md-collapsible-deanimated) {
    &[data-state='closed'] {
      animation: collapsible-up 0.35s ease;
    }

    &[data-state='open'] {
      animation: collapsible-down 0.35s ease;
    }
  }
}

@keyframes collapsible-down {
  from {
    height: 0;
  }

  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes collapsible-up {
  from {
    height: var(--radix-collapsible-content-height);
  }

  to {
    height: 0;
  }
}
</style>
