<script lang="ts" setup>
import type { SiteItemChangelog } from '@/views/manager';
import { computed, inject, InjectionKey, onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import type { ToastInterface } from 'vue-toastification';

import { useI18n } from 'vue-i18n';

defineOptions({
  name: 'TheManagerChangelogModal',
});

const props = defineProps<{
  item: SiteItemChangelog
}>();

const emits = defineEmits(['closed']);

const toast: InjectionKey<ToastInterface> | any = inject('toast');

const { t } = useI18n();

interface PluginInformationType {
  sections?: {
    changelog?: string
  }
  error?: string
}

const WP_PLUGIN_URL = 'https://api.wordpress.org/plugins/info/1.2/?action=plugin_information&request[slug]=';

const slug = computed(() => props.item.slug);
const itemChangelog = ref<string>('');
const loaded = ref(false);

const open = ref(false);

function handleClose() {
  open.value = false;

  setTimeout(() => {
    emits('closed');
  }, 200);
}

onBeforeMount(async () => {
  try {
    const url = `${WP_PLUGIN_URL}${slug.value}`;

    const response = await fetch(url);

    const itemInformation: PluginInformationType = await response.json();

    if (!!itemInformation && !!itemInformation.sections && !!itemInformation.sections.changelog) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(itemInformation.sections.changelog, 'text/html');

      const hElements = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');

      // hr parser
      hElements.forEach((hElement: any, index: number) => {
        hElement.classList.add('title');

        if (index !== 0) {
          const hrElement = doc.createElement('hr');
          hElement.parentNode.insertBefore(hrElement, hElement);
        }
      });

      // Link parser
      const aElements = doc.querySelectorAll('a');
      aElements.forEach((aElement: any) => {
        if (!aElement.hasAttribute('target')) {
          aElement.setAttribute('target', '_blank');
        }
      });

      itemChangelog.value = doc.documentElement.outerHTML;
    } else {
      itemChangelog.value = t('site.overview.updates.notChangelogFound');
    }

    loaded.value = true;
  } catch (e) {
    loaded.value = true;
    console.error(e);

    itemChangelog.value = t('site.overview.updates.notChangelogFound');

    toast.error(t('general.shared.error'));
  }
});

onMounted(async () => {
  open.value = true;
});

onUnmounted(async () => {
  open.value = false;
});
</script>

<template>
  <Dialog v-model:open="open" @update:open="handleClose">
    <DialogContent id="site-updates-item-changelog" body-scrollable size="lg">
      <DialogHeader>
        <DialogTitle class="d-flex flex-column">
          <span>{{ item.name }}</span>
          <span class="text-success-dark fw-medium text-sm">
            {{ $t('site.overview.updates.actualVersion', { version: item.version }) }}
          </span>
        </DialogTitle>
      </DialogHeader>

      <DialogBody class="pb-32">
        <Transition appear mode="out-in" name="fade-in-fast">
          <div v-if="!loaded">
            <template v-for="n in 2" :key="n">
              <hr v-if="n !== 1" class="my-32">

              <div class="placeholder-glow">
                <span class="placeholder" style="width: 100px; height: 19px" />
              </div>

              <div class="placeholder-glow mb-8">
                <span class="placeholder" style="width: 200px; height: 19px" />
              </div>

              <ul>
                <li v-for="m in 4" :key="m">
                  <div class="placeholder-glow">
                    <span class="placeholder w-100" style="height: 19px" />
                  </div>
                </li>
              </ul>
            </template>
          </div>

          <span v-else class="changelog-text" v-html="itemChangelog" />
        </Transition>
      </DialogBody>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped>
.loader-container {
  height: 6.25rem;
}

:deep(.changelog-text) {
  font-weight: 500;
  font-size: 0.875rem;

  .title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;

    & ~ p {
      font-style: normal;
      margin-bottom: 0.25rem;

      em {
        font-style: normal !important;
      }
    }
  }

  hr {
    margin-block: 2rem;
  }

  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
  }
}
</style>
