import { Concept } from '@/types/models/Concept';

export type SiteBackupStatusEnumKeys = 'excluded' | 'pending' | 'in_progress' | 'ready' | 'upload_pending' | 'uploading' | 'done' | 'failed_in_creation' | 'failed_export_database' | 'failed_export_files' | 'failed_in_processing' | 'failed_uploaded' | 'failed_partial' | 'failed' | 'failed_file_not_found' | 'storage_exceeded';

export const SiteBackupStatusEnumConst = {
  EXCLUDED: 'excluded',
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  READY: 'ready',
  UPLOAD_PENDING: 'upload_pending',
  UPLOADING: 'uploading',
  DONE: 'done',
  FAILED_IN_CREATION: 'failed_in_creation',
  FAILED_EXPORT_DATABASE: 'failed_export_database',
  FAILED_EXPORT_FILES: 'failed_export_files',
  FAILED_IN_PROCESSING: 'failed_in_processing',
  FAILED_UPLOADED: 'failed_uploaded',
  FAILED_PARTIAL: 'failed_partial',
  FAILED: 'failed',
  FAILED_FILE_NOT_FOUND: 'failed_file_not_found',
  STORAGE_EXCEEDED: 'storage_exceeded',
} as const;

export const SiteBackupStatusEnum: Record<SiteBackupStatusEnumKeys, Concept> = {
  excluded: {
    key: 'excluded',
    value: null,
    name: 'concept.site.backup.status.excludedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  pending: {
    key: 'pending',
    value: null,
    name: 'concept.site.backup.status.pendingName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  in_progress: {
    key: 'in_progress',
    value: null,
    name: 'concept.site.backup.status.inProgressName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  ready: {
    key: 'ready',
    value: null,
    name: 'concept.site.backup.status.readyName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  upload_pending: {
    key: 'upload_pending',
    value: null,
    name: 'concept.site.backup.status.uploadPendingName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  uploading: {
    key: 'uploading',
    value: null,
    name: 'concept.site.backup.status.uploadingName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  done: {
    key: 'done',
    value: null,
    name: 'concept.site.backup.status.doneName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed_in_creation: {
    key: 'failed_in_creation',
    value: null,
    name: 'concept.site.backup.status.failedInCreationName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed_export_database: {
    key: 'failed_export_database',
    value: null,
    name: 'concept.site.backup.status.failedExportDatabaseName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed_export_files: {
    key: 'failed_export_files',
    value: null,
    name: 'concept.site.backup.status.failedExportFilesName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed_in_processing: {
    key: 'failed_in_processing',
    value: null,
    name: 'concept.site.backup.status.failedInProcessingName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed_uploaded: {
    key: 'failed_uploaded',
    value: null,
    name: 'concept.site.backup.status.failedUploadedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed_partial: {
    key: 'failed_partial',
    value: null,
    name: 'concept.site.backup.status.failedPartialName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed: {
    key: 'failed',
    value: null,
    name: 'concept.site.backup.status.failedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  failed_file_not_found: {
    key: 'failed_file_not_found',
    value: null,
    name: 'concept.site.backup.status.failedFileNotFoundName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  },
  storage_exceeded: {
    key: 'storage_exceeded',
    value: null,
    name: 'concept.site.backup.status.storageExceededName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.status'
  }
} as const;

export type SiteBackupStatusEnumType = (typeof SiteBackupStatusEnumConst)[keyof typeof SiteBackupStatusEnumConst];

export const SiteBackupStatusEnumArray = Object.values(SiteBackupStatusEnum);
