import { Concept } from '@/types/models/Concept';

export type SiteReportStatusEnumKeys = 'draft' | 'in_progress' | 'done' | 'error' | 'partial_error';

export const SiteReportStatusEnumConst = {
  DRAFT: 'draft',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
  ERROR: 'error',
  PARTIAL_ERROR: 'partial_error',
} as const;

export const SiteReportStatusEnum: Record<SiteReportStatusEnumKeys, Concept> = {
  'draft': {
    key: 'draft',
    value: null,
    name: 'concept.site.report.status.draftName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.report.status'
  },
  'in_progress': {
    key: 'in_progress',
    value: null,
    name: 'concept.site.report.status.inProgressName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.report.status'
  },
  'done': {
    key: 'done',
    value: null,
    name: 'concept.site.report.status.doneName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.report.status'
  },
  'error': {
    key: 'error',
    value: null,
    name: 'concept.site.report.status.errorName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.report.status'
  },
  'partial_error': {
    key: 'partial_error',
    value: null,
    name: 'concept.site.report.status.partialErrorName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.report.status'
  }
} as const;

export type SiteReportStatusEnumType = (typeof SiteReportStatusEnumConst)[keyof typeof SiteReportStatusEnumConst];

export const SiteReportStatusEnumArray = Object.values(SiteReportStatusEnum);
