<script lang="ts" setup>
import {
  SelectItem,
  SelectItemIndicator,
  type SelectItemProps,
  SelectItemText,
  useForwardProps,
} from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

const props = withDefaults(
  defineProps<SelectItemProps & { class?: HTMLAttributes['class'], indicator?: boolean }>(),
  {
    indicator: false,
  },
);

const delegatedProps = computed(() => {
  const { class: _, indicator, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <SelectItem
    class="ui-select-menu-item position-relative d-flex align-items-center rounded-xs px-16 my-2"
    :class="
      [
        props.class,
      ]
    "
    v-bind="forwardedProps"
  >
    <span
      v-if="indicator"
      class="ui-select-item-indicator-wrapper position-absolute right-2 d-flex align-items-center justify-content-center"
    >
      <SelectItemIndicator>
        <m-icon class="ui-select-item-indicator-check" icon="check" />
      </SelectItemIndicator>
    </span>

    <SelectItemText>
      <slot />
    </SelectItemText>
  </SelectItem>
</template>
