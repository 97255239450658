export default {
  whiteLabel: {
    title: 'White Label',
    activeWhiteLabel: 'Activar White Label en el Plugin de Modular DS en tus webs',
    pluginConfiguration: 'Configuración del Plugin',
    pluginName: 'Nombre del plugin',
    pluginDescription: 'Descripción del Plugin',
    authorName: 'Nombre del autor',
    authorUrl: 'URL del autor',
    authorUrlInvalid: 'Tienes que añadir una URL válida, por ejemplo, https://www.miweb.com',
    wpPluginListPreview: 'Vista previa de la lista de plugins de WordPress',
    disableWhiteLabelTitle: 'Desactivar White Label',
    disableWhiteLabelText: 'Esta acción afectará a todas tus webs.',
    disableWhiteLabelDescription: 'Al desactivar el White Label, se aplicará a todas tus webs de manera simultánea.',
    disableWhiteLabelMessage: 'White Label desactivado. Puede tardar unos minutos en verse reflejado en todas tus webs',
    pluginExampleNameOne: 'Joinchat',
    pluginExampleNameTwo: 'Editor clásico',
    activate: 'Activar',
    deactivate: 'Desactivar',
    delete: 'Borrar',
    connectionManager: 'Gestor de conexión',
    pluginExampleDescription: 'Este es un ejemplo que te permitirá previsualizar tus cambios.',
    pluginExampleVersion: 'Versión 1.0.0',
    pluginExampleAuthor: 'Por WordPress',
    pluginExampleViewDetails: 'Ver detalles',
    pluginAuthor:
      'Por <a class="{ class }" href="{ href }" rel="noreferrer noopener" target="{ target }">{ author }</a>',
    pluginDefaultDescription:
      'Conecta y gestiona tus webs de WordPress de una manera más fácil y eficiente. Actualizaciones de plugins y temas. Conexión con Google Analytics para generar reportes. Y una aplicación para que tu cliente entienda y valore su web. Nosotros lo llamamos #LaRevolucionDeLasWebs',
    modularConnector: 'Modular Connector',
    modularDS: 'Modular DS',
    toastMessage: 'Configuración de White Label actualizada para tu cuenta',
    alertInfoText:
      'El White Label solo funcionará en las webs que tengan el plugin Modular Connector actualizado a la versión 1.0.0 o superior',
    empty: {
      emptyYTLink: 'https://www.youtube.com/embed/Sui2eE02NOY',
      title: 'White Label del plugin de Modular DS',
      description:
        'Activa la opción de White Label y personaliza el plugin Modular Connector, esencial para enlazar tu sitio de WordPress con Modular, con la información que prefieras. Esta característica es ideal si quieres mantener en privado el uso de Modular frente a clientes o colaboradores, o bien para reforzar tu imagen de marca al personalizar la interfaz del plugin con tus datos corporativos. Incluido en el plan Business.',
      becomeBusiness: 'Hazte Business',
      activate: 'Activar White Label',
    },
  },
  emailDomains: {
    link: 'DOMINIOS & EMAILS',
    title: 'Dominios personalizados | 1 dominio personalizado | { count } dominios personalizados',
    addDomain: 'Añadir dominio personalizado',
    domain: 'Dominio',
    dateInclusion: 'Fecha inclusión',
    configureDomain: 'Mostrar configuración de DNS',
    removeDomain: 'Quitar dominio',
    selectThisDomain: 'Seleccionar como “ACTIVO”',
    inUse: 'En uso',
    setDefaultToast: 'Ahora los correos se enviarán desde el dominio {domain}',
    deleteModalTitle: '¿Quieres eliminar este dominio?',
    deleteModalText: 'Si eliminas este dominio de Modular, no podrás usarlo como dominio de envío en los reportes que programados de mantenimiento.',
    deleteDomainCompleted: 'El dominio se ha borrado correctamente',
    active: 'activo',
    emailAddress: 'Dirección de email',
    sendingAddress: 'Dirección desde la que se envía',
    sendingAddressDescription:
      'La dirección de correo que se mostrará como remitente en los reportes enviados. Añade un dominio personalizado para editar esta dirección. Solo el dominio es modificable.',
    freeSetDefaultToast: 'Tienes que tener un plan PRO para seleccionar un dominio personalizado',
    create: {
      title: 'Añade tu dominio',
      domainName: 'Tu dominio',
      domainNameText: 'Tienes que añadir un dominio, por ejemplo, miweb.com o subdominio.miweb.com',
      domainNameInvalid: 'Tienes que añadir un dominio válido, por ejemplo, miweb.com o subdominio.miweb.com',
      domainExistsError: 'Ya tienes configurado este dominio. Prueba con otro distinto.',
      toastMessage: 'Dominio añadido correctamente',
    },
    show: {
      title: 'Configuración de DNS',
      subtitle:
        'Añade estos registros DNS a tu proveedor de dominio o modifica los registros DNS existentes para verificar que seas el titular de este dominio.',
      verificationText: 'La verificación del dominio puede tardar hasta 72 horas mientras se propagan los cambios en tus registros.',
      type: 'Tipo',
      name: 'Nombre',
      value: 'Valor',
      copy: 'Copiar',
      pendingToastMessage:
        'No hemos podido verificar el dominio. Los cambios en las DNS pueden tardar en aplicarse. Inténtalo de nuevo en unas horas',
      successToastMessage: 'Dominio verificado correctamente',
    },
    noResults: {
      title: 'No has añadido ningún dominio personalizado',
      subtitle: 'Añade tus dominios para poder cambiar el email de envío de tus reportes.',
      button: 'Añadir dominio',
    },
  },
  accountsIntegrations: {
    title: 'Cuentas de google',
    connectedCount: 'Cuentas de google | 1 cuenta conectada | {count} cuentas conectadas',
    addAccount: 'Añadir cuenta de Google',
    addNewAccount: 'Añadir nueva cuenta',
    table: {
      account: 'Cuenta',
      associationDate: 'Fecha asociación',
      activeServices: 'Servicios activos',
      reconnect: 'Reconectar',
      deleteAccount: 'Borrar cuenta',
      noAccess: 'Sin permisos',
      noAccessText:
        'Para poder utilizar esta cuenta,<br> es necesario reconectarla seleccionando<br> los permisos necesarios.',
    },
    services: {
      search_console: 'Search Console',
      analytics: 'Analytics',
    },
    disconnectModal: {
      title: 'Borrar cuenta de Google',
      description:
        'Si desconectas tu cuenta de Google, perderás la conexión de Analytics y Search Console en todas las webs en las que hayas vinculado una propiedad o dominio de esta cuenta.',
    },
    emptyState: {
      title: 'No has conectado ninguna cuenta a nivel global',
      subtitle:
        'Añade una para poder compartirla en diferentes webs y facilitar el proceso de conexión de Analytics y Search Console en esos sitios.',
      button: 'Añadir cuenta',
    },
    associationModal: {
      selectAccount: 'Seleccionar cuenta existente',
      editAccounts: 'Editar cuentas',
      permissionAdvice:
        'Sólo puedes utilizar cuentas que tienen asociado el servicio de Google que intentas conectar. Si no aparece una cuenta que has conectado, vuelve a añadirla concediendo todos los permisos.',
    },
  },
};
