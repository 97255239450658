<script lang="ts" setup>
import usePermissions from '@/composables/usePermissions';

import useRedirectToPayment from '@/composables/useRedirectToPayment';

import router from '@/router';
import useBillingStore from '@/stores/billingStore';

import useOrganizationStore from '@/stores/organizationStore';
import { computed } from 'vue';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineProps({
  enableConfigLink: {
    type: Boolean,
    default: false,
  },
  siteSidebar: {
    type: Boolean,
    default: false,
  },
});

const { can } = usePermissions();
const { redirectToPayment } = useRedirectToPayment();

const { ORGANIZATION_STORAGE_SIZE_100GB } = PermissionEnumConst;

const organizationStore = useOrganizationStore();
const storage = computed(() => organizationStore.storage);
const storagePercentage = computed(() => organizationStore.storagePercentage);

const billingStore = useBillingStore();

const subscription: any = computed(() => billingStore.subscriptions.plan);
const hasSubscription = computed(() => !!subscription.value);

const computedStorageColor = computed(() => ({
  'text-danger-dark': storagePercentage.value >= 90,
  'text-warning-medium': storagePercentage.value >= 70 && storagePercentage.value < 90,
  'text-success-dark': storagePercentage.value < 70,
}));

const computedStorageBackgroundColor = computed(() => ({
  'bg-danger': storagePercentage.value >= 90,
  'bg-warning': storagePercentage.value >= 70 && storagePercentage.value < 90,
  'bg-success-dark': storagePercentage.value < 70,
}));

async function handleRedirectToPayment() {
  if (hasSubscription.value) {
    await router.push({ name: 'billing.show' });
  } else {
    await redirectToPayment({ trackName: 'Get more space backups' });
  }
}
</script>

<template>
  <div :class="{ 'col-12 col-lg-6 col-xl-12 mb-16': !siteSidebar }" class="aside">
    <div id="backup-right-sidebar" class="aside-content d-flex flex-column radius-sm px-xl-16 bg-white p-32">
      <div v-if="enableConfigLink" class="aside-backup-configure position-relative mb-16 pb-16">
        <div class="d-flex justify-content-between align-items-center mb-8">
          <div class="fw-semi">
            {{ $t('general.shared.config') }}
          </div>

          <router-link :to="{ name: 'sites.site.backup.update' }" class="btn btn-link btn-sm fw-semi">
            <span>{{ $t('general.button.edit') }}</span>
          </router-link>
        </div>
      </div>

      <span v-if="!storage" class="placeholder-glow" style="min-height: 8rem">
        <span class="placeholder" style="width: 100%" />
        <span class="placeholder" style="width: 100%" />
        <span class="placeholder" style="width: 40%" />
      </span>

      <template v-else>
        <div class="d-flex justify-content-between align-items-center fw-semi mb-16">
          <h4 id="usedStorage" class="text-normal fw-semi mb-0">
            {{ $t('site.backup.aside.usedStorage') }}
          </h4>

          <span :class="computedStorageColor" class="fw-semi text-sm">
            {{ `${storagePercentage}%` }}
          </span>
        </div>

        <div class="progress mb-4 bg-gray-300">
          <div
            :class="computedStorageBackgroundColor"
            :style="{ width: `${storagePercentage}%` }"
            aria-labelledby="usedStorage"
            aria-valuemax="100"
            aria-valuemin="0"
            aria-valuenow="5"
            class="progress-bar rounded-xs"
            role="progressbar"
          />
        </div>

        <div class="fw-medium text-sm text-gray-600">
          {{
            $t('site.backup.aside.currentStorage', {
              current: storage?.used?.format,
              total: storage?.total?.format,
            })
          }}
        </div>

        <div
          v-if="storagePercentage === 0"
          class="fw-medium mt-16 text-sm text-gray-600"
          v-html="
            $t('site.backup.aside.noBackups')
          "
        />

        <div v-if="storagePercentage >= 100" class="fw-medium text-danger-dark mt-16 text-sm">
          {{ $t('site.backup.aside.spaceFilled') }}
        </div>

        <v-tooltip :disabled="!can(ORGANIZATION_STORAGE_SIZE_100GB)" class="w-lg-100 mt-auto" placement="bottom">
          <span v-if="can(ORGANIZATION_STORAGE_SIZE_100GB)">
            <VButton
              class="btn-icon justify-content-center fw-semi w-lg-100 mt-16"
              disabled
              size="sm"
              variant="outline-success"
            >
              <v-icon class="fw-semi" icon="database" space="me-12" />
              {{ $t('general.button.getMoreSpace') }}
            </VButton>
          </span>

          <span v-else>
            <VButton
              v-if="storagePercentage >= 70"
              class="btn-icon justify-content-center fw-semi w-xl-100 mt-16"
              size="sm"
              variant="outline-success"
              @click="handleRedirectToPayment"
            >
              <v-icon class="fw-semi" icon="database" space="me-12" />
              {{ $t('general.button.getMoreSpace') }}
            </VButton>
          </span>

          <template #popper>
            {{ $t('general.shared.comingSoon') }}
          </template>
        </v-tooltip>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aside-backup-configure::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--md-gray-400);
}
</style>
