export default {
  loadingTitle: 'Looking for critical errors...',
  loadingText: 'We are analyzing your website and looking for potential errors. This process may take several minutes.',
  numCriticalErrors: '{num} critical error | {num} critical errors',
  title: 'Health status of the site',
  description:
    'The site health check shows critical information about the configuration of your WordPress and the elements that might require your attention.',
  wpVersion: 'WordPress',
  phpVersion: 'PHP',
  modularVersion: 'Modular',
  versionShort: 'V { version }',
  lastCheck: 'Last check',
  checking: 'We are checking the status of the site',
  now: 'now...',
  checkNow: 'Check now',
  nextCheck: 'Next check in {date}',
  noData: 'We are checking the site\'s status. This may take a few minutes.',
  generalStatus: {
    title: {
      plugin_not_updated: 'Plugin updates',
      plugin_inactive: 'Inactive plugins',
      theme_not_updated: 'Theme updates',
      has_ssl: 'SSL Certificate',
      is_in_debug_mode: 'WP Debug',
      is_public: 'Indexation',
    },
    good: {
      plugin_not_updated: 'All plugins are up to date',
      plugin_inactive: 'You have no plugins deactivated',
      theme_not_updated: 'All themes are up to date',
      has_ssl: 'SSL certificate is valid and active',
      is_in_debug_mode: 'WP Debug is false',
      is_public: 'The website can be indexed by search engines.',
    },
    recommended: {
      plugin_not_updated: 'You have plugins that have not been updated',
      plugin_inactive: 'You have deactivated plugins',
      theme_not_updated: 'You have unupdated themes',
      is_in_debug_mode: 'The {variable} is {value}',
    },
    critical: {
      plugin_not_updated: 'You have plugins that have not been updated',
      plugin_inactive: 'You have deactivated plugins',
      theme_not_updated: 'You have unupdated themes',
      has_ssl: 'The SSL certificate is invalid and not active.',
      is_in_debug_mode: 'WP Debug is set to true',
      is_public: 'The website is not indexable by search engines.',
    },
  },
  stateSite: 'Site status',
  siteInformation: 'Site information',
  vulnerabilities: 'Vulnerabilities',
  criticalErrors: {
    title: 'Critical errors',
    description:
      'Critical issues are items that could have a major impact on the performance or security of the site and priority should be given to fixing these issues.',
    eachYearPayment: 'Per month, paying annually',
    everythingWorking: 'Everything is working properly',
    noErrors: 'No critical errors',
    empty: {
      wantSeeCriticalErrors: 'Do you want to see the critical errors on your website?',
      becomePro:
        'Become a<span class=\'badge bg-success text-light rounded-xs text-xs fw-medium p-4 mx-4\'>PRO</span>to see this information and access the rest of the functionalities.',
    },
  },
  freeState: {
    defaultTimezone: 'PHP\'s default time zone is valid.',
    phpSessions: 'PHP sessions have been detected',
    scheduledEvents: 'Scheduled events are running',
    restAvailability: 'REST API has encountered an unexpected result',
    wantSeeErrors: 'Do you want to see the complete health status of your website?',
    wantSeeVulnerabilities: 'Do you want to see all your website\'s vulnerabilities?',
  },
  security: 'Security',
  performance: 'Performance',
  core: 'WordPress',
  php: 'PHP',
  database: 'Database',
  site: 'Website',
  directories: 'Directories',
  constants: 'Constants',
  muPlugins: 'MU Plugins',
  current: 'Current version',
  memoryLimit: 'Memory limit',
  safeMode: 'Safe Mode',
  shell: 'Shell',
  basename: 'Basename',
  name: 'Name',
  locale: 'Locale',
  version: 'Current version',
  newVersion: 'Latest version',
  requiresPhp: 'Minimum PHP version',
  mysqlVersion: 'Minimum MySQL version',
  status: 'Status',
  extension: 'Extension',
  server: 'Server version',
  media: 'Media',
  engine: 'Database server',
  clientVersion: 'Client version',
  uname: 'Server architecture',
  hostname: 'Hostname',
  diskFreeSpace: 'Disk space available',
  isUnix: 'Unix',
  pseudoCronDisable: 'Is Pseudo-CRON deactivated?',
  contentDirWritable: 'Is the "wp-content" directory editable?',
  isSsl: 'Is this site using HTTPS?',
  isMultisite: 'Is it a multisite?',
  baseUrl: 'WordPress URL',
  restUrl: 'REST URL',
  homeUrl: 'Home URL',
  pluginsUrl: 'Plugin directory URL',
  timezone: 'Timezone',
  isPublic: 'Is the site deterring search engines?',
  abspath: 'ABSPATH',
  debug: 'WP_DEBUG',
  debugLog: 'WP_DEBUG_LOG',
  debugDisplay: 'WP_DEBUG_DISPLAY',
  exist: 'Are there any?',
  writable: 'Can the directory be edited?',
  existHandler: 'Is there a Handler?',
  yes: 'Yes',
  no: 'No',
  activate: 'Activate',
  disabled: 'Disabled',
  disabledFunctions: 'Disabled functions',
  extensions: 'Extensions',
  toastMessage: 'We are checking the status of the site. This may take a few minutes.',
  errorModal: {
    title: 'You need to update the Modular plugin',
    contentTitle: 'To view this content you need to update the Modular plugin on your website.',
    content:
      'The version of the Modular plugin you have installed on this website is not compatible with the display of the Health and Safety content.',
    updatePlugin: 'Update plugin',
  },
  errorDisconnectedModal: {
    title: 'It is necessary to connect your website',
    contentTitle: 'To see this content you need to have your website connected.',
    content:
      'You don\'t have your website connected with the Modular plugin, connect it to see the Health and Safety content.',
    connectWeb: 'Connect web',
  },
};
