import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

interface StorageData {
  value: number
  format: string
}

interface Storage {
  used: StorageData
  total: StorageData
}

export interface ManagerSitesCount extends Storage {
  existing: StorageData
}

interface Manager {
  siteItems: any
  sites: ManagerSitesCount
  storage: Storage
}

export default class ManagerStatusService extends AbstractService {
  /**
   * Retrieves the total number of sites, site items, and storage used by the organization.
   *
   * @returns {Promise<Manager>}
   */
  public retrieve(): Promise<Manager> {
    return this.request({
      method: apiEndpoints.organizationsStatus.method,
      url: this.buildPath(apiEndpoints.organizationsStatus),
      options: {
        cancellable: true,
        cancelKey: 'managerStatus',
      },
    });
  }
}
