import type { IUserConfig, IUserConfigDashboard } from '@/composables/useUserConfig';
import { useUserConfig } from '@/composables/useUserConfig';

import type { Team } from '@/types/models/team/Team';
import type { User } from '@/types/models/user/User';
import api from '@/api';
import { changeLanguage } from '@/helpers/langHelper';
import useAppStore from '@/stores/appStore';
import useBillingStore from '@/stores/billingStore';
import useManagerStore from '@/stores/manager/managerStore';
import useOrganizationStore from '@/stores/organizationStore';
import useBackupStore from '@/stores/site/backup/backupStore';
import useReportsStore from '@/stores/site/report/reportsStore';
import useSiteStore from '@/stores/site/siteStore';
import useSocketStore from '@/stores/socketStore';

import useTagsStore from '@/stores/tag/tagsStore';
import useTeamStore from '@/stores/teamStore';
import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { datadogRum } from '@datadog/browser-rum';

interface IUserStore {
  userLogged: Ref<User | null>
  logged: Ref<boolean>
  refreshUser: (user?: User | null) => Promise<void>
  refreshUserConfig: (user: User) => Promise<void>

  teams: Ref<Team[]>
  refreshTeams: () => Promise<void>

  settings: Ref<IUserConfig | null>
  notificationsCount: Ref<number | null>
  getNotificationsCount: () => Promise<void>

  permissions: Ref<string[]>
  permissionsLoaded: ComputedRef<boolean>

  login: () => Promise<void>
  logout: () => Promise<void>

  setFirstLogin: (isFirst: boolean) => void
  isFirstLogin: Ref<boolean>
  isLogged: ComputedRef<boolean | undefined>
  isAppSumoUser: ComputedRef<boolean>
}

const useUserStore = defineStore('userStore', (): IUserStore => {
  const userLogged: Ref<User | null> = ref(null);
  const logged: Ref<boolean> = ref(false);
  const teams: Ref<Team[]> = ref([]);
  const settings: Ref<IUserConfig | null> = ref({});
  const notificationsCount: Ref<number | null> = ref(null);
  const isFirstLogin = ref(false);
  const permissions: Ref<string[]> = ref([]);

  async function login() {
    const [requestedUser, requestedPermissions, requestedTeams] = await Promise.all([
      api.user.general.retrieve(),
      api.user.general.allPermissions(),
      api.organization.general.teams(),
    ]);

    userLogged.value = requestedUser;
    logged.value = true;
    teams.value = requestedTeams;

    permissions.value = requestedPermissions;

    const userConfigStorage = useUserConfig();

    await userConfigStorage.setConfig({
      language: requestedUser.language,
      siteOrderColumn: requestedUser.dashboardSiteOrder?.column,
      siteOrderSort: requestedUser.dashboardSiteOrder?.sort,
      siteViewMode: requestedUser.dashboardSiteViewMode,
    });

    changeLanguage(requestedUser.language);

    const organizationStore = useOrganizationStore();
    const billingStore = useBillingStore();

    const subscription = billingStore.planSubscription;
    const currentOrganization = organizationStore.organization;

    let subscriptionData: { name: string, productId?: string | null, priceId?: string | null, interval?: string | null } = {
      name: 'Free',
    };

    if (!!subscription) {
      subscriptionData = {
        name: subscription?.product?.name,
        productId: subscription?.product?.id,
        priceId: subscription?.price?.id,
        interval: subscription?.price?.interval,
      }
    }

    datadogRum.setUser({
      id: String(userLogged.value.id),
      name: userLogged.value.fullName,
      email: userLogged.value.email,
      metas: {
        changelog_read_at: userLogged.value.changelogReadAt || '',
        dashboard_site_order: !!userLogged.value.dashboardSiteOrder ? `${userLogged.value.dashboardSiteOrder.column}-${userLogged.value.dashboardSiteOrder.sort}` : '',
        dashboard_site_view_mode: userLogged.value.dashboardSiteViewMode || '',
        language: userLogged.value.language || '',
      },
      organization: {
        id: currentOrganization?.id,
        name: currentOrganization?.name,
      },
      plan: subscriptionData,
    });

    const { Assistant } = window;

    if (!!Assistant) {
      Assistant('identify', {
        name: requestedUser.fullName,
        email: requestedUser.email,
      });

      // TODO CREATE A FUNCTION AND ADD A REMOVELISTENER ON LOGOUT
      const iframe: HTMLIFrameElement | null = document.querySelector('.td-assistant iframe');

      if (!!iframe && !!iframe?.contentDocument) {
        const iframeDocument = iframe.contentDocument;

        iframeDocument.body.addEventListener('click', () => {
          const isThriveDeskOpen = iframeDocument.querySelector('.go3015032717');

          if (!isThriveDeskOpen) {
            api.mixpanel.track('Ask for support', {
              location: 'Thrivedesk Assistant',
            });
          }
        });
      }
    }

    await getNotificationsCount();
  }

  async function refreshTeams() {
    try {
      teams.value = await api.organization.general.teams();
    } catch (e: any) {
      console.error('Error refreshing teams', e);
    }
  }

  async function refreshUser(user?: User | null) {
    if (!!user) {
      userLogged.value = user;

      await refreshUserConfig(user);
    } else {
      const retrievedUser = await api.user.general.retrieve();

      userLogged.value = retrievedUser;

      await refreshUserConfig(retrievedUser);
    }
  }

  async function refreshUserConfig(user: User) {
    const userConfig = useUserConfig();
    const currentConfig: IUserConfigDashboard = userConfig.getConfig() || {};

    const newConfig: IUserConfigDashboard = {
      siteOrderColumn: user.dashboardSiteOrder?.column || currentConfig.siteOrderColumn,
      siteOrderSort: user.dashboardSiteOrder?.sort || currentConfig.siteOrderSort,
      siteViewMode: user.dashboardSiteViewMode || currentConfig.siteViewMode,
    };

    const isDifferentConfig = Object.entries(newConfig).some(
      ([key, value]) => currentConfig[key as keyof IUserConfigDashboard] !== value,
    );

    if (isDifferentConfig) {
      await userConfig.setConfig(newConfig);
    }

    changeLanguage(user.language);
  }

  async function getNotificationsCount() {
    try {
      notificationsCount.value = await api.user.notifications.retrieveCount();
    } catch (e: any) {
      console.error('Error retrieving notifications count', e);
    }
  }

  function reset() {
    userLogged.value = null;
    logged.value = false;
    teams.value = [];
    settings.value = {};
    notificationsCount.value = null;
    isFirstLogin.value = false;
    permissions.value = [];
  }

  async function logout() {
    const appStore = useAppStore();
    const backupStore = useBackupStore();
    const billing = useBillingStore();
    const organizationStore = useOrganizationStore();
    const reportsStore = useReportsStore();
    const siteStore = useSiteStore();
    const socketStore = useSocketStore();
    const tagsStore = useTagsStore();
    const teamStore = useTeamStore();
    const managerStore = useManagerStore();

    await api.auth.logout();

    appStore.setStatus('loading');

    userLogged.value = null;
    logged.value = false;

    socketStore.unsubscribeOrganization();
    socketStore.unsubscribeSite();

    appStore.setDark(false);

    appStore.reset();
    backupStore.reset();
    billing.$reset();
    organizationStore.reset();
    reportsStore.reset();
    siteStore.reset();
    socketStore.reset();
    tagsStore.reset();
    teamStore.$reset();
    managerStore.reset();
    reset();

    localStorage.clear();
  }

  function setFirstLogin(isFirst: boolean) {
    isFirstLogin.value = isFirst;
  }

  const isLogged = computed(() => logged.value);
  const isAppSumoUser = computed(() => userLogged.value?.source === 'appsumo');
  const permissionsLoaded = computed(() => permissions.value?.length > 0);

  return {
    userLogged,
    logged,
    teams,
    settings,
    notificationsCount,
    isFirstLogin,
    isLogged,
    permissions,
    permissionsLoaded,

    isAppSumoUser,

    login,
    refreshTeams,
    refreshUser,
    refreshUserConfig,
    getNotificationsCount,
    logout,
    setFirstLogin,
  };
});

export { useUserStore };
export default useUserStore;
