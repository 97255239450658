export interface DebouncedFunction {
  (...args: any): void
  cancel: () => void
}

/**
 * @deprecated Use vue-use useDebounce instead
 * @param func
 * @param wait
 */
function debounce(func: (...args: any) => void, wait: number): DebouncedFunction {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const debouncedFunction = (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, wait);
  };

  debouncedFunction.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return debouncedFunction;
}

export default { debounce };
export { debounce };
