import type { SiteReportStatusEnumType } from '@/types/enums/SiteReportStatusEnum';
import type { Notification } from '@/types/models/site/service/report/notification/Notification';
import type { ReportSendStatusType, ReportStatusType } from '@/views/site/reports';
import router from '@/router';
import { SiteReportStatusEnum, SiteReportStatusEnumConst } from '@/types/enums/SiteReportStatusEnum';

function getReportStatus(status: SiteReportStatusEnumType): ReportStatusType {
  const statusConcept = SiteReportStatusEnum[status];
  const statusLabel = statusConcept?.name ? statusConcept.name : 'concept.site.report.status.errorName';

  switch (status) {
    case SiteReportStatusEnumConst.DONE:
      return { label: statusLabel, background: 'success' };
    case SiteReportStatusEnumConst.DRAFT:
    case SiteReportStatusEnumConst.IN_PROGRESS:
      return { label: statusLabel, background: 'warning' };
    default:
      return { label: 'concept.site.report.status.errorName', background: 'danger' };
  }
}

function getReportSendStatusMapped(opened: boolean): ReportSendStatusType {
  return {
    label: opened ? 'siteReports.details.opened' : 'siteReports.details.sent',
    color: opened ? 'success' : 'secondary-dark',
    icon: opened ? 'email-send-status' : 'email-closed',
  }
}

function getReportSendStatus(notifications: Notification[]): ReportSendStatusType {
  const allNotificationsRead = notifications.some((notification: Notification) => !!notification.readAt);

  return getReportSendStatusMapped(allNotificationsRead);
}

function viewPdf(reportUri?: string) {
  try {
    if (!!reportUri) {
      window.open(reportUri);
    }
  } catch (e) {
    console.error(e);
  }
}

function downloadPDF(reportUri?: string) {
  try {
    if (!!reportUri) {
      window.open(reportUri, '_blank');
    }
  } catch (e) {
    console.error(e);
  }
}

async function goToReports() {
  await router.push({
    name: 'sites.site.reports.show',
  });
}

export { downloadPDF, getReportSendStatus, getReportSendStatusMapped, getReportStatus, goToReports, viewPdf };
export default {
  downloadPDF,
  getReportSendStatus,
  getReportSendStatusMapped,
  getReportStatus,
  goToReports,
  viewPdf,
};
