interface IStorage {
  getItem: (key: string) => string | null
  setItem: (key: string, value: string) => void
  removeItem: (key: string) => void
  clear: () => void
}

type StorageKey = string;

abstract class StorageHelper<T extends StorageKey> {
  private readonly storage: IStorage;

  public constructor(getStorage = (): IStorage => window.localStorage) {
    this.storage = getStorage();
  }

  protected get(key: T): string | null {
    return this.storage.getItem(key);
  }

  protected set(key: T, value: string): void {
    this.storage.setItem(key, value);
  }

  protected remove(key: T): void {
    this.storage.removeItem(key);
  }

  protected clear(): void {
    this.storage.clear();
  }
}

export default StorageHelper;
