<script lang="ts" setup>
import type { SiteItem } from '@/types/models/site/updater/SiteItem';
import type { SiteItemChangelog } from '@/views/manager';
import type { ComputedRef } from 'vue';

import useManagerStore from '@/stores/manager/managerStore';
import { SiteItemTypeEnumConst } from '@/types/enums/SiteItemTypeEnum';

import TheManagerUpdateChangelogButton from '@/views/manager/parts/TheManagerUpdateChangelogButton.vue';

import TheManagerUpdateComponentVulnerabilities
  from '@/views/manager/parts/TheManagerUpdateComponentVulnerabilities.vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

defineOptions({
  name: 'TheManagerUpdateSiteItem',
});

defineEmits(['openChangelog', 'openVulnerability', 'update:selected']);

const { PLUGIN, CORE, THEME } = SiteItemTypeEnumConst;

const managerStore = useManagerStore();
const { visibilityStatus } = storeToRefs(managerStore);

const siteItem = defineModel<SiteItem>({
  default: {},
  required: true,
});

const selected = defineModel('selected', {
  type: Boolean,
});

const inputId = computed(() => `manager-item-site-${siteItem.value.id}`);
const isProcessing = computed(() => siteItem.value.processing);
const siteUri = computed(() => siteItem.value.site?.uri.replace(/^https?:\/\//, '').replace(/\/$/, ''));

const vulnerabilities = computed(() => ({
  vulnerabilitiesExists: siteItem.value.vulnerabilitiesExists,
  vulnerabilitiesCExists: siteItem.value.vulnerabilitiesCExists,
}));

const siteItemChangelog: ComputedRef<SiteItemChangelog> = computed(() => ({
  type: siteItem.value.type,
  name: siteItem.value.name,
  slug: siteItem.value.slug,
  version: !!siteItem.value.newVersion ? siteItem.value.newVersion : siteItem.value.version,
}));

const componentVulnerability: ComputedRef<SiteItemChangelog> = computed(() => ({
  type: siteItem.value.type,
  name: siteItem.value.name,
  slug: siteItem.value.slug,
  version: siteItem.value.version,
}));
</script>

<template>
  <label
    :for="inputId"
    :class="[
      { processing: siteItem.processing, active: selected },
      visibilityStatus === 'all' && siteItem.isHidden ? 'text-gray-500' : 'text-gray-600',
    ]"
    class="d-flex component-site-item justify-content-between pe-xl-56 ps-xxl-40 py-8 pe-24 ps-16"
    role="button"
  >
    <span class="d-flex align-items-center">
      <span :class="{ disabled: isProcessing }" class="d-flex align-items-center plugin-name-container py-4 text-sm">
        <Checkbox
          :id="inputId"
          v-model:checked="selected"
          :data-cy="inputId"
          :disabled="siteItem.processing"
          class="me-8"
          square
          @click.exact.prevent
          @update:checked="$emit('update:selected', $event)"
        />

        <span class="d-flex align-items-center fw-semi w-100 text-sm">
          <span class="fw-medium site-name overflow-hidden text-ellipsis text-nowrap">
            {{ siteItem.site?.name }}
          </span>

          <span
            v-if="!!siteUri"
            :class="{ short: isProcessing }"
            class="site-uri text-2xs fw-medium align-self-end ms-8 overflow-hidden text-ellipsis text-nowrap"
          >
            {{ siteUri }}
          </span>
        </span>
      </span>

      <span class="statuses-container d-flex align-items-center px-8">
        <Tooltip
          v-if="visibilityStatus === 'all' && !!siteItem.newVersion"
          :content="$t('manager.updateAvailable')"
          trigger-class="status-item-container"
        >
          <span
            class="status-item d-flex align-items-center justify-content-center text-bg-primary rounded-pill text-4xs border
              border-gray-100"
          >
            <m-icon class="lh-sm" icon="exchange-transfer" />
          </span>
        </Tooltip>

        <Tooltip
          v-if="visibilityStatus === 'all' && siteItem.isHidden"
          :content="$t('manager.updateHidden')"
          trigger-class="status-item-container"
        >
          <span
            class="status-item d-flex align-items-center justify-content-center border-light text-light rounded-pill text-2xs border
              bg-gray-500"
          >
            <m-icon class="lh-sm" icon="disabled" />
          </span>
        </Tooltip>

        <TheManagerUpdateComponentVulnerabilities
          :component-vulnerabilities="vulnerabilities"
          @open-vulnerability="$emit('openVulnerability', componentVulnerability)"
        />
      </span>
    </span>

    <span class="d-flex align-items-center">
      <Tooltip
        v-if="siteItem.status === 'inactive' && siteItem.type !== THEME"
        :content="$t('manager.updateDisabled', { type: siteItem.type })"
        trigger-class="d-flex align-items-center me-24"
        html
      >
        <m-icon size="md" class="text-danger fw-medium" icon="power-on-off" />
      </Tooltip>

      <Tooltip
        v-if="siteItem.status === 'active' && siteItem.type === THEME"
        :content="$t('manager.themeActive')"
        trigger-class="d-flex align-items-center me-24"
      >
        <m-icon class="text-secondary" size="md" icon="lightbulb" />
      </Tooltip>

      <v-load-bar v-if="isProcessing" :decrease="false" class="position-relative me-16 me-4" type="small" />

      <span class="d-flex align-items-center update-actions fw-medium text-nowrap text-xs">
        <span v-if="!!siteItem.newVersion" class="actual-version lh-xl">{{ siteItem.version }}</span>
        <v-icon v-if="!!siteItem.newVersion" class="px-8" icon="arrow-right" />

        <Tooltip :content="$t('site.overview.updates.viewChangelog')">
          <TheManagerUpdateChangelogButton
            v-if="siteItem.type === PLUGIN"
            v-model="siteItemChangelog"
            :classes="siteItem.isHidden ? 'text-gray-700' : siteItem.newVersion ? 'text-success-dark' : 'text-gray-600'"
            @open-changelog="$emit('openChangelog', siteItemChangelog)"
          />

          <a
            v-else-if="siteItem.type === CORE"
            class="d-flex btn btn-link btn-2xs fw-medium new-version text-decoration-underline lh-xl text-nowrap border-0 p-0"
            :class="[
              siteItem.isHidden ? 'text-gray-700' : !!siteItem.newVersion ? 'text-success-dark' : 'text-gray-600',
            ]"
            href="https://wordpress.org/news/category/releases/"
            rel="noreferrer noopener"
            target="_blank"
          >
            {{ !!siteItem.newVersion ? siteItem.newVersion : siteItem.version }}
          </a>

          <span
            v-else
            :class="[
              siteItem.isHidden ? 'text-gray-700' : !!siteItem.newVersion ? 'text-success-dark' : 'text-gray-600',
            ]"
            class="new-version"
          >
            {{ !!siteItem.newVersion ? siteItem.newVersion : siteItem.version }}
          </span>
        </Tooltip>
      </span>
    </span>
  </label>
</template>

<style lang="scss" scoped>
.site-name,
.site-uri {
  text-overflow: ellipsis;
  line-height: 1.3125rem;
}

.component-site-item {
  height: 3rem;
}

.component-site-item {
  &.processing {
    cursor: default;
  }

  &.active {
    background-color: var(--md-primary-100);
  }

  &:not(.active):not(.processing):hover {
    background-color: var(--md-primary-100);
  }

  &.active:not(.processing):hover {
    background-color: var(--md-gray-200);
  }
}

[data-bs-theme='dark'] {
  .component-site-item {
    &.active {
      background-color: var(--md-gray-100);
    }

    &:not(.active):not(.processing):hover,
    &.active:not(.processing):hover {
      background-color: var(--md-gray-300);
    }
  }
}

.site-name {
  max-width: 4rem;

  @media (width >= 992px) {
    max-width: 8rem;
  }

  @media (width >= 1200px) {
    max-width: 6rem;
  }

  @media (width >= 1400px) {
    max-width: 10rem;
  }
}

.site-uri {
  max-width: 3rem;

  @media (width >= 992px) {
    max-width: 8rem;
  }

  @media (width >= 1200px) {
    max-width: 4rem;
  }

  @media (width >= 1400px) {
    max-width: 8rem;
  }
}
</style>
