export default {
  tags: 'Etiquetas',
  tag: 'Etiqueta',
  assignColor: 'Asignar color',
  tagName: 'Nombre de la etiqueta',
  searchTag: 'Buscar etiqueta',
  notFound: 'No se han encontrado resultados',
  nameErrorText: 'Ya existe una etiqueta con este nombre',
  maxCharError: 'Máximo {max} caracteres',
  create: 'Crear etiqueta',
};
