import type { SimpleResponse } from '@/types/Api';
import type { Team } from '@/types/models/team/Team';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class TeamService extends AbstractService {
  /**
   * Retrieves the current Team
   *
   * @param {string} team
   * @returns {Promise<Team>}
   */
  public retrieve(team: string): Promise<Team> {
    return this.request({
      method: apiEndpoints.teamsShow.method,
      url: this.buildPath(apiEndpoints.teamsShow, {
        team,
      }),
      params: {
        append: ['sites_count'],
      },
    });
  }

  /**
   * Creates a new Team
   *
   * @param {string} teamName
   * @returns {Promise<Team>}
   */
  public create(teamName: string): Promise<Team> {
    return this.request({
      method: apiEndpoints.teamsStore.method,
      url: this.buildPath(apiEndpoints.teamsStore),
      data: {
        name: teamName,
      },
    });
  }

  /**
   * Updates the current Team
   *
   * @param {number} teamId
   * @param {{name: string}} data
   * @returns {Promise<Team>}
   */
  public update(teamId: number, data: { name: string }): Promise<Team> {
    return this.request({
      method: apiEndpoints.teamsUpdate.method,
      url: this.buildPath(apiEndpoints.teamsUpdate, { team: teamId }),
      data,
    });
  }

  /**
   * Deletes the current Team
   *
   * @param {number} teamId
   * @returns {Promise<SimpleResponse>}
   */
  public delete(teamId: number): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.teamsDestroy.method,
      url: this.buildPath(apiEndpoints.teamsDestroy, { team: teamId }),
    });
  }
}
