export default {
  panel: {
    viewAll: 'View all',
    viewUnread: 'View only unread',
    view: 'View',
    markAsRead: 'Mark as read',
    markAsUnread: 'Mark as unread',
    markAllAsRead: 'Mark all as read',
    all30Days: 'That\'s all your notifications from the last 30 days.',
    noPendingTitle: 'Nothing here!',
    noPending: 'You have no pending notifications.',
  },
  notifications: {
    uptimeDown: {
      title: '{name} seems down',
      body: '{url} appears to be down since {date} (UTC).',
    },
    uptimeUp: {
      title: '{name} is up again',
      body: '{url} is working again since {date} (UTC).',
    },
    uptimeSslExpiration: {
      title: '{name} SSL will expire in {days}',
      body: '{url} SSL certificate will expire on {date} (UTC).',
    },
    backupFailed: {
      title: 'Backup failed on {name}',
      body: 'The backup of {url} has failed.',
    },
    reportAutomaticFailed: {
      title: 'Error generating report on {name}',
      body: 'The scheduled report of {url} could not be generated.',
    },
    siteConnectionLost: {
      title: '{name} has lost connection with Modular',
      body: '{url} has lost connection with Modular.',
    },
    vulnerabilityDiscovered: {
      title: 'Vulnerability detected on {name}',
      body: 'We have detected a new vulnerability on your website {url}',
    },
    managerUpgradedFailed: {
      title: 'Error updating {name} on {site}',
      body: '{name} {type} could not be updated on {url}',
    },
    managerInstallationFailed: {
      title: 'Error installing {name} on {site}',
      body: 'The {type} {name} could not be installed on {url}',
    },
    managerActivationFailed: {
      title: 'Error activating {name} on {site}',
      body: 'The {type} could not be activated on {url}',
    },
    managerDeactivationFailed: {
      title: 'Error deactivating {name} on {site}',
      body: 'The {type} could not be deactivated on {url}',
    },
    managerDeletionFailed: {
      title: 'Error deleting {name} on {site}',
      body: 'The {type} could not be deleted on {url}',
    },
  },
}
