import { cva, type VariantProps } from 'class-variance-authority';

export { default as MButton } from './MButton.vue';

export const buttonVariants = cva(
  'btn ui-button d-flex align-items-center justify-content-center rounded-sm',
  {
    variants: {
      variant: {
        default: 'btn-primary',
        primaryDark: 'btn-primary btn-primary-dark',
        secondary: 'btn-secondary',
        success: 'btn-success',
        successDark: 'btn-success btn-success-dark',
        danger: 'btn-danger',
        pink: 'btn-pink',
        warning: 'btn-warning',
        // info: 'btn-info',
        light: 'btn-light',
        dark: 'btn-dark',
        // black: 'btn-black',
        // gray: 'btn-gray',
        // gray200: 'btn-gray-200',
        outlinePrimary: 'btn-outline-primary',
        // outlinePrimaryDark: 'btn-outline-primary btn-outline-primary-dark',
        outlinePrimary200: 'btn-outline-primary btn-outline-primary-200',
        outlineSecondary: 'btn-outline-secondary',
        outlineSuccess: 'btn-outline-success',
        outlineDanger: 'btn-outline-danger',
        outlineDangerMedium: 'btn-outline-danger btn-outline-danger-medium',
        outlineLight: 'btn-outline-light',
        // outlineSuccessDark: 'btn-outline-success-dark',
        outlineGray: 'btn-outline-gray',
        outlineGray600: 'btn-outline-gray-600',
        link: 'btn-link',
        linkPrimary200: 'btn-link-primary-200',
        linkPrimaryDark: 'btn-link btn-link-primary-dark',
        linkLightDark: 'btn-link btn-link-light-dark',
        linkDanger: 'btn-link btn-link-danger',
        linkDangerDark: 'btn-link-danger-dark',
        linkDark: 'btn-link btn-link-dark',
        linkDarkPrimary: 'btn-link btn-link-dark-primary',
        icon: 'btn-icon',
        transparent: 'btn-transparent',
        // filter: 'btn-filter',
        comboboxSelect: 'btn-combobox-select',
      },
      size: {
        // default: 'px-24 py-16',
        // xs: 'px-16 py-8',
        // sm: 'px-22 py-12',
        // lg: 'px-32 py-20',
        'default': '',
        '2xs': 'btn-2xs',
        'xs': 'btn-xs',
        'sm': 'btn-sm',
        'lg': 'btn-lg',
        'icon': 'p-4',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
