import type { HealthItem } from '@/types/models/site/service/health/SiteHealth';
import type { ExtendedHealthItem, HealthStatusItemsType } from '@/views/site/health/types';
import { statusConfig } from '@/views/site/health/composables/useSiteInformation';

function linkParser(content: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  Array.from(doc.querySelectorAll('a')).forEach((aElement) => {
    if (!aElement.hasAttribute('target'))
      aElement.setAttribute('target', '_blank');
  });

  return doc.documentElement.outerHTML;
}

function parseHealthItems(items: HealthItem[], type: string): ExtendedHealthItem[] {
  return items
    .filter(item => item.type === type)
    .map(item => ({
      ...item,
      description: item.description ? linkParser(item.description) : undefined,
      actions: item.actions ? linkParser(item.actions) : undefined,
      ...statusConfig[item.status],
    })) as ExtendedHealthItem[];
}

function createHealthStatusItem(type: string, healthItems: HealthItem[]): HealthStatusItemsType {
  const items = parseHealthItems(healthItems, type);
  const criticalErrors = items.reduce((count, item) => count + (item.status === 'critical' ? 1 : 0), 0);

  return { type, criticalErrors, items };
}

export { createHealthStatusItem };
export default {
  createHealthStatusItem,
};
