import type { SitesRequestOptions } from '@/api/services/organization/OrganizationService';

import type { Site } from '@/types/models/site/Site';
import type { Sites } from '@/types/models/site/Sites';

import type { Team } from '@/types/models/team/Team';
import api from '@/api';

import useOrganizationStore from '@/stores/organizationStore';
import useUserStore from '@/stores/userStore';
import { defineStore } from 'pinia';

interface TeamState {
  currentTeam: Team | null
  sites: Sites | null
  sitesCount: number | null
}

const useTeamStore = defineStore({
  id: 'team',
  state: (): TeamState => ({
    currentTeam: null,
    sites: null,
    sitesCount: 0,
  }),
  actions: {
    cleanCurrentTeam() {
      this.currentTeam = null;
      this.sites = null;
    },
    async getCurrentTeam(team: string) {
      if (!team) {
        this.cleanCurrentTeam();
      } else if (!this.currentTeam || this.currentTeam.slug !== team || this.currentTeam.slug === team) {
        try {
          this.currentTeam = await api.team.retrieve(team);

          this.setCurrentTeamSites();
        } catch (e: any) {
          if (e.status === 404) {
            // await router.replace({ name: 'me.sites.index' });
          } else {
            console.error('Error loading Team', e);
          }
        }
      }
    },
    async refreshSites(team?: string | number) {
      if (!team)
        return;

      const params: SitesRequestOptions = {
        page: 1,
        per_page: 12,
        team: [Number(team)],
      };

      const response = await api.organization.general.sites(params);

      this.sites = response.data;
    },
    setCurrentTeamSites() {
      const userStore = useUserStore();

      if (!!userStore?.teams && !!this.currentTeam) {
        const currentUserTeam = userStore.teams.find(userTeam => userTeam.slug === this.currentTeam?.slug);

        this.sites = currentUserTeam?.sites ?? null;

        const teamStore = useTeamStore();

        teamStore.sitesCount = this.sites?.length ?? 0;
      }
    },
    async deleteSite(site: Site) {
      try {
        if (!this.sites)
          return;

        this.sites = this.sites.filter(s => s.id !== site.id);

        await api.site.general.delete(site.id);

        const userStore = useUserStore();
        const teamStore = useTeamStore();
        const organizationStore = useOrganizationStore();

        await Promise.all([
          userStore.refreshTeams(),
          teamStore.refreshSites(site?.team?.id),
          organizationStore.manager(),
        ]);
      } catch (e: any) {
        console.error(e);
      }
    },
  },
  getters: {
    currentTeamSlug: state => state.currentTeam?.slug,
    currentTeamSitesLength: state => state.currentTeam?.sitesCount,
  },
});

export { useTeamStore };
export default useTeamStore;
