import type { PaginationResponse } from '@/types/Api';
import type { INotification } from '@/types/models/user/notifications/Notification';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class NotificationService extends AbstractService {
  /**
   * Returns user notifications count
   *
   * @returns {Promise<number | null>}
   */
  public retrieveCount(): Promise<number | null> {
    return this.request({
      method: apiEndpoints.notificationsCount.method,
      url: this.buildPath(apiEndpoints.notificationsCount),
      params: {
        read: 'unread',
      },
    });
  }

  /**
   * Returns user notifications
   *
   * @returns {Promise<INotification[]>}
   */
  public retrieve(params: {
    read: 'all' | 'unread' | 'read'
    per_page: number
    page: number
  }): Promise<PaginationResponse<INotification>> {
    return this.request({
      method: apiEndpoints.notificationsIndex.method,
      url: this.buildPath(apiEndpoints.notificationsIndex),
      params,
      options: {
        paginate: true,
      },
    });
  }

  /**
   * Marks a notification as read
   *
   * @returns {Promise<void>}
   * @param id
   */
  public markAsRead(id: number | string): Promise<void> {
    return this.request({
      method: apiEndpoints.notificationsRead.method,
      url: this.buildPath(apiEndpoints.notificationsRead, { notification: id }),
    });
  }

  /**
   * Marks all notifications as read
   *
   * @returns {Promise<void>}
   */
  public markAllAsRead(): Promise<void> {
    return this.request({
      method: apiEndpoints.notificationsReadAll.method,
      url: this.buildPath(apiEndpoints.notificationsReadAll),
    });
  }
}
