import type { BillingAccount } from '@/types/models/organization/billing/BillingAccount';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class BillingService extends AbstractService {
  /**
   * Retrieves the billing account of the organization.
   *
   * @returns {Promise<BillingAccount>}
   */
  public retrieve(): Promise<BillingAccount> {
    return this.request({
      method: apiEndpoints.billingAccountsShow.method,
      url: this.buildPath(apiEndpoints.billingAccountsShow),
    });
  }

  /**
   * Creates or updates the billing account for the organization
   *
   * @param billing
   * @returns {Promise<BillingAccount>}
   */
  public create(billing: any): Promise<BillingAccount> {
    return this.request({
      method: apiEndpoints.billingAccountsStore.method,
      url: this.buildPath(apiEndpoints.billingAccountsStore),
      data: billing,
    });
  }
}
