import type { Directive, DirectiveBinding } from 'vue';
import { can } from '@/helpers/permissionsHelper';

export const vCan: Directive<HTMLElement, string> = {
  mounted(el: HTMLElement, binding: DirectiveBinding<string>) {
    const evaluate = () => {
      let hasPermission = false;

      if (typeof binding.value === 'string') {
        hasPermission = can(binding.value);
      } else {
        console.error(
          'vCan directive expects a string value. Received:',
          typeof binding.value,
        );
        el.parentNode?.removeChild(el);
        return;
      }

      if (!hasPermission) {
        el.parentNode?.removeChild(el);
      }
    };

    evaluate();
  },
};
