import { Concept } from '@/types/models/Concept';

export type SiteStatusEnumKeys = 'pending' | 'established' | 'lost' | 'unknown' | 'generic_error' | 'invalid_credentials' | 'invalid_login_uri' | 'captcha_detected' | 'maintenance_mode' | 'cannot_confirm' | 'cannot_plugin_installed' | 'client_error' | 'server_error' | 'plugin_not_found' | 'sites_limit_reached' | 'redirect_detected' | 'unauthorized' | 'reached_login_page' | 'login_in' | 'logged_in' | 'reached_secondary_action_required' | 'reached_installation_page' | 'installing_plugin' | 'plugin_installed' | 'searching_plugin_upgrade' | 'plugin_upgrading' | 'plugin_upgraded' | 'reached_activation_page' | 'activating_plugin' | 'plugin_activated' | 'reached_credentials_page' | 'established_credentials';

export const SiteStatusEnumConst = {
  PENDING: 'pending',
  SUCCESS: 'established',
  LOST: 'lost',
  UNKNOWN_ERROR: 'unknown',
  GENERIC_ERROR: 'generic_error',
  INVALID_CREDENTIALS: 'invalid_credentials',
  INVALID_LOGIN_URI: 'invalid_login_uri',
  CAPTCHA_DETECTED: 'captcha_detected',
  MAINTENANCE_MODE: 'maintenance_mode',
  CANNOT_CONFIRM: 'cannot_confirm',
  CANNOT_PLUGIN_INSTALLED: 'cannot_plugin_installed',
  CLIENT_ERROR: 'client_error',
  SERVER_ERROR: 'server_error',
  PLUGIN_NOT_FOUND: 'plugin_not_found',
  SITES_LIMIT_REACHED: 'sites_limit_reached',
  REDIRECT_DETECTED: 'redirect_detected',
  UNAUTHORIZED: 'unauthorized',
  REACHED_LOGIN_PAGE: 'reached_login_page',
  LOGIN_IN: 'login_in',
  PLUGIN_LOGGED_IN: 'logged_in',
  REACHED_SECONDARY_ACTION_REQUIRED: 'reached_secondary_action_required',
  REACHED_PLUGIN_INSTALLATION_PAGE: 'reached_installation_page',
  INSTALLING_PLUGIN: 'installing_plugin',
  PLUGIN_INSTALLED: 'plugin_installed',
  SEARCHING_PLUGIN_UPGRADE: 'searching_plugin_upgrade',
  PLUGIN_UPGRADING: 'plugin_upgrading',
  PLUGIN_UPGRADED: 'plugin_upgraded',
  REACHED_ACTIVATION_PAGE: 'reached_activation_page',
  PLUGIN_ACTIVATING: 'activating_plugin',
  PLUGIN_ACTIVATED: 'plugin_activated',
  REACHED_PLUGIN_CREDENTIALS_PAGE: 'reached_credentials_page',
  ESTABLISHED_CREDENTIALS: 'established_credentials',
} as const;

export const SiteStatusEnum: Record<SiteStatusEnumKeys, Concept> = {
  'pending': {
    key: 'pending',
    value: null,
    name: 'concept.site.status.pendingName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'established': {
    key: 'established',
    value: null,
    name: 'concept.site.status.establishedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'lost': {
    key: 'lost',
    value: null,
    name: 'concept.site.status.lostName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'unknown': {
    key: 'unknown',
    value: null,
    name: 'concept.site.status.unknownName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'generic_error': {
    key: 'generic_error',
    value: null,
    name: 'concept.site.status.genericErrorName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'invalid_credentials': {
    key: 'invalid_credentials',
    value: null,
    name: 'concept.site.status.invalidCredentialsName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'invalid_login_uri': {
    key: 'invalid_login_uri',
    value: null,
    name: 'concept.site.status.invalidLoginUriName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'captcha_detected': {
    key: 'captcha_detected',
    value: null,
    name: 'concept.site.status.captchaDetectedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'maintenance_mode': {
    key: 'maintenance_mode',
    value: null,
    name: 'concept.site.status.maintenanceModeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'cannot_confirm': {
    key: 'cannot_confirm',
    value: null,
    name: 'concept.site.status.cannotConfirmName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'cannot_plugin_installed': {
    key: 'cannot_plugin_installed',
    value: null,
    name: 'concept.site.status.cannotPluginInstalledName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'client_error': {
    key: 'client_error',
    value: null,
    name: 'concept.site.status.clientErrorName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'server_error': {
    key: 'server_error',
    value: null,
    name: 'concept.site.status.serverErrorName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'plugin_not_found': {
    key: 'plugin_not_found',
    value: null,
    name: 'concept.site.status.pluginNotFoundName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'sites_limit_reached': {
    key: 'sites_limit_reached',
    value: null,
    name: 'concept.site.status.sitesLimitReachedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'redirect_detected': {
    key: 'redirect_detected',
    value: null,
    name: 'concept.site.status.redirectDetectedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'unauthorized': {
    key: 'unauthorized',
    value: null,
    name: 'concept.site.status.unauthorizedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'reached_login_page': {
    key: 'reached_login_page',
    value: null,
    name: 'concept.site.status.reachedLoginPageName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'login_in': {
    key: 'login_in',
    value: null,
    name: 'concept.site.status.loginInName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'logged_in': {
    key: 'logged_in',
    value: null,
    name: 'concept.site.status.loggedInName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'reached_secondary_action_required': {
    key: 'reached_secondary_action_required',
    value: null,
    name: 'concept.site.status.reachedSecondaryActionRequiredName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'reached_installation_page': {
    key: 'reached_installation_page',
    value: null,
    name: 'concept.site.status.reachedInstallationPageName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'installing_plugin': {
    key: 'installing_plugin',
    value: null,
    name: 'concept.site.status.installingPluginName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'plugin_installed': {
    key: 'plugin_installed',
    value: null,
    name: 'concept.site.status.pluginInstalledName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'searching_plugin_upgrade': {
    key: 'searching_plugin_upgrade',
    value: null,
    name: 'concept.site.status.searchingPluginUpgradeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'plugin_upgrading': {
    key: 'plugin_upgrading',
    value: null,
    name: 'concept.site.status.pluginUpgradingName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'plugin_upgraded': {
    key: 'plugin_upgraded',
    value: null,
    name: 'concept.site.status.pluginUpgradedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'reached_activation_page': {
    key: 'reached_activation_page',
    value: null,
    name: 'concept.site.status.reachedActivationPageName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'activating_plugin': {
    key: 'activating_plugin',
    value: null,
    name: 'concept.site.status.activatingPluginName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'plugin_activated': {
    key: 'plugin_activated',
    value: null,
    name: 'concept.site.status.pluginActivatedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'reached_credentials_page': {
    key: 'reached_credentials_page',
    value: null,
    name: 'concept.site.status.reachedCredentialsPageName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  },
  'established_credentials': {
    key: 'established_credentials',
    value: null,
    name: 'concept.site.status.establishedCredentialsName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.status',
    status: 'active'
  }
} as const;

export type SiteStatusEnumType = (typeof SiteStatusEnumConst)[keyof typeof SiteStatusEnumConst];

export const SiteStatusEnumArray = Object.values(SiteStatusEnum);
