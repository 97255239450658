<script lang="ts" setup>
import type { DebouncedFunction } from '@/helpers/formHelper';
import { debounce } from '@/helpers/formHelper';
import type { Tag } from '@/types/models/organization/tag/Tag';

import type { Site } from '@/types/models/site/Site';
import type { Team } from '@/types/models/team/Team';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';

import useLoginWordpress from '@/composables/useLoginToWordpress';

import useSiteFunctions from '@/composables/useSiteFunctions';

import fuzzysort from 'fuzzysort';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

// TODO Remove this library when API sorts results by score

type TeamWithType = Team & { type: string, score: number };
type SiteWithType = Site & { type: string, score: number };
type TagWithType = Tag & { type: string, score: number };

defineOptions({
  name: 'TheHeaderSearch',
});

const router = useRouter();
const { parseUri } = useSiteFunctions();

const teams = ref<TeamWithType[]>([]);
const sites = ref<SiteWithType[]>([]);
const tags = ref<TagWithType[]>([]);
const selectedElement = ref<TeamWithType | SiteWithType | TagWithType | null>(null);
const hasFocus = ref(false);
const searching = ref(false);
const searchValue = ref<string>('');
const inputRef = ref<null | HTMLElement>(null);
const searchBox = ref<null | HTMLElement>(null);

const inputBox = computed(() => inputRef.value as HTMLInputElement);

const hasSearchValue = computed(() => searchValue.value.length > 0);

async function doSearch() {
  try {
    const filters = {
      s: searchValue.value,
      include: ['team', 'tag'],
    };

    const response = await api.organization.general.search(filters);

    if (filters.s === searchValue.value) {
      const scoredTeams = response.teams.map((team: TeamWithType) => {
        const tmp = team;

        tmp.type = 'team';
        tmp.score = tmp.name.toLowerCase() === searchValue.value.toLowerCase() ? -100 : 0;

        const score = fuzzysort.single(searchValue.value, tmp.name);
        if (score) {
          tmp.score += score.score;
        }

        return tmp;
      }) as TeamWithType[];

      const scoredSites = response.sites.map((site: SiteWithType) => {
        const tmp = site;

        tmp.type = 'site';
        tmp.score = tmp.name.toLowerCase() === searchValue.value.toLowerCase() ? -100 : 0;

        let score = fuzzysort.single(searchValue.value, tmp.name);

        if (score !== null) {
          tmp.score += score.score;
        }

        score = fuzzysort.single(searchValue.value, tmp.uri);
        if (score !== null) {
          tmp.score += score.score / 2;
        }

        // FIXME Algo pacha aquí
        score = fuzzysort.single(searchValue.value, tmp?.team?.name || '');
        if (score !== null) {
          tmp.score += score.score / 3;
        }

        return tmp;
      }) as SiteWithType[];

      const scoredTags = response.tags.map((tag: TagWithType) => {
        const tmp = tag;

        tmp.type = 'tag';
        tmp.score = tmp.name.toLowerCase() === searchValue.value.toLowerCase() ? -100 : 0;

        const score = fuzzysort.single(searchValue.value, tmp.name);
        if (score) {
          tmp.score += score.score;
        }

        return tmp;
      }) as TagWithType[];

      teams.value = scoredTeams.sort((a, b) => a.score - b.score);
      sites.value = scoredSites.sort((a, b) => a.score - b.score);
      tags.value = scoredTags.sort((a, b) => a.score - b.score);

      // Preselects the result with the highest score
      if (teams.value.length > 0 && sites.value.length > 0 && tags.value.length > 0) {
        if (teams.value[0].score < sites.value[0].score && teams.value[0].score < tags.value[0].score) {
          selectedElement.value = teams.value[0];
        } else if (sites.value[0].score < tags.value[0].score) {
          selectedElement.value = sites.value[0];
        } else {
          selectedElement.value = tags.value[0];
        }
      } else if (sites.value.length > 0) {
        [selectedElement.value] = sites.value;
      } else if (teams.value.length > 0) {
        [selectedElement.value] = teams.value;
      }
    }
  } catch (e: any) {
    console.error(e);
  } finally {
    searching.value = false;
  }
}

const debouncedSearch: DebouncedFunction = debounce(doSearch, 500);

function clear() {
  searchValue.value = '';
  teams.value = [];
  sites.value = [];
  selectedElement.value = null;
}

function setFocus() {
  hasFocus.value = true;
  inputBox.value.focus();
}

function onClickOnClear() {
  clear();
  setFocus();
}

function onFocus() {
  if (!!searchValue.value) {
    hasFocus.value = true;
  }
}

function onInput(e: Event) {
  searchValue.value = (e.target as HTMLInputElement).value;

  if (!searchValue.value || (!!searchValue.value && searchValue.value.length < 3)) {
    hasFocus.value = true;
    searching.value = false;

    teams.value = [];
    sites.value = [];
    tags.value = [];

    if (debouncedSearch) {
      debouncedSearch.cancel();
    }
  } else {
    setFocus();
    searching.value = true;

    debouncedSearch();
  }
}

function launchTracking(site: Site) {
  api.mixpanel.track('View Website', {
    'Website name': site.name,
    'Website url': site.uri,
    'Location': 'Search',
  });
}

async function onKeydown(e: KeyboardEvent) {
  const allResults = [...teams.value, ...sites.value, ...tags.value];
  let selectedIndex = -1;

  if (selectedElement.value) {
    selectedIndex = allResults.indexOf(selectedElement.value);
  }

  if (e.key === 'ArrowDown') {
    if (selectedIndex < allResults.length - 1) {
      selectedElement.value = allResults[selectedIndex + 1];
    }
  } else if (e.key === 'ArrowUp') {
    if (selectedIndex > 0) {
      selectedElement.value = allResults[selectedIndex - 1];
    }
  } else if (e.key === 'Enter') {
    if (!!selectedElement.value) {
      if (selectedElement.value.type === 'team') {
        const team: Team = selectedElement.value as TeamWithType;

        await router.push({
          name: 'team.show',
          params: {
            team: team.slug,
          },
        });
      } else if (selectedElement.value.type === 'site') {
        const site: Site = selectedElement.value as SiteWithType;

        await router.push({
          name: 'sites.site.show',
          params: {
            site: site.slug,
          },
        });

        launchTracking(site);
      } else if (selectedElement.value.type === 'tag') {
        const tag: Tag = selectedElement.value as TagWithType;

        await router.push({
          name: 'sites',
          params: {
            tag: tag.name,
          },
        });
      }

      hasFocus.value = false;
      clear();

      inputBox.value.blur();
    }
  }

  if ((e.key === 'ArrowDown' && selectedIndex < allResults.length - 1) || (e.key === 'ArrowUp' && selectedIndex > 0)) {
    await nextTick();

    const searchContainer = searchBox.value as HTMLElement;

    const selectedItemElement = searchContainer.querySelector('.item-selected') as HTMLDivElement;

    selectedItemElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }

  if (e.key === 'Escape') {
    clear();
    inputBox.value.blur();
    hasFocus.value = false;
  }
}

function highlightMatchesSearch(value: string) {
  const removePunctuation = (s: string) =>
    s
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
      .replace(/\s{2,}/g, ' ');

  let normalizedSearchValue = removePunctuation(searchValue.value);
  const normalizedValue = removePunctuation(value);

  normalizedSearchValue = normalizedSearchValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(`(${normalizedSearchValue})`, 'gi');

  const matches = [...normalizedValue.matchAll(regex)];
  let highlightedValue = '';
  let currentIndex = 0;

  matches.forEach((match) => {
    const start = match.index as number;
    const end = start + match[0].length;

    highlightedValue += value.substring(currentIndex, start);
    highlightedValue += `<mark>${value.substring(start, end)}</mark>`;

    currentIndex = end;
  });

  highlightedValue += value.substring(currentIndex);

  return highlightedValue;
}

function handleClickOutside(event: MouseEvent) {
  const searchContainer = searchBox.value as HTMLElement;

  if (!!inputRef.value && !!searchBox.value) {
    if (!inputBox.value.contains(event.target as Node) && !searchContainer.contains(event.target as Node)) {
      hasFocus.value = false;
    }
  }
}

function handleGlobalKeydown(e: KeyboardEvent) {
  if (e.ctrlKey && e.key === 'k') {
    e.preventDefault();

    setFocus();
  }
}

const { loginWordpress, isLoading: loadingLoginButton } = useLoginWordpress();

function handleGoToWordPress(siteId: number) {
  if (!!siteId) {
    loginWordpress(siteId);
  }
}

watch(
  () => router.currentRoute.value,
  (value, previousValue) => {
    if (value !== previousValue) {
      searchValue.value = '';
    }
  },
);

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  document.addEventListener('keydown', handleGlobalKeydown);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
  document.removeEventListener('keydown', handleGlobalKeydown);
});
</script>

<template>
  <div :class="{ 'search-box-open': hasFocus }" class="md-search position-relative w-100 me-xl-48 me-16">
    <VIcon class="search-icon search position-absolute d-block text-gray-500" icon="search" />

    <input
      id="search-input"
      ref="inputRef"
      :placeholder="$t('general.header.search')"
      :value="searchValue"
      autocomplete="off"
      class="form-control text-normal w-100 fw-semi rounded-sm py-8 pe-16 ps-48 text-gray-900 shadow-none"
      data-search-input="true"
      type="text"
      @focus="onFocus"
      @input="onInput"
      @keydown="onKeydown"
    >

    <button
      v-if="hasSearchValue"
      aria-label="Clear"
      class="btn btn-icon search-icon clear position-absolute rounded-xs p-4 text-gray-500"
      type="button"
      @click="onClickOnClear"
      @keydown.space.enter="onClickOnClear"
    >
      <VIcon icon="close-x" size="xs" />
    </button>

    <div ref="searchBox" :class="{ show: hasFocus }" class="md-search-box position-absolute bg-light">
      <div class="position-relative mh-100 h-100 py-8">
        <div v-if="searching && teams.length === 0 && sites.length === 0 && tags.length === 0">
          <ul class="list-group">
            <li v-for="n in 2" :key="n" class="md-search-item md-site-placeholder d-flex">
              <div class="d-flex w-100 align-items-center px-16 py-8">
                <m-icon class="fw-bold me-16 text-gray-500" icon="three-people" />

                <div class="placeholder-glow lh-1 w-100">
                  <span class="placeholder" style="height: 16px; width: 200px" />
                </div>
              </div>
            </li>
          </ul>

          <ul class="list-group">
            <li v-for="n in 3" :key="n" class="md-search-item md-team-placeholder d-flex">
              <div class="d-flex w-100 align-items-center px-16 py-4">
                <m-icon class="text-info fw-bold me-16" icon="link" />

                <div class="placeholder-glow lh-1 w-100">
                  <span class="placeholder" style="height: 22px; width: 200px" />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <ul v-if="searchValue && !!teams && teams.length > 0" :class="{ 'opacity-50': searching }" class="list-group">
          <li
            v-for="team in teams"
            :key="team.id"
            :class="{ 'item-selected': team === selectedElement }"
            class="md-search-item d-flex"
          >
            <router-link
              :to="{ name: 'team.show', params: { team: team.slug } }"
              class="md-search-link btn btn-link btn-link-dark-primary d-flex w-100 align-items-center rounded-0 position-relative lh-xl
                border-0 px-16 py-8"
              @click.exact="clear()"
              @click.ctrl="clear()"
              @focus="selectedElement = team"
              @mouseover="selectedElement = team"
            >
              <m-icon class="fw-bold me-16 text-gray-500" icon="three-people" />
              <span v-dompurify-html="highlightMatchesSearch(team.name)" class="team-name text-sm" />
            </router-link>
          </li>
        </ul>

        <ul v-if="searchValue && !!tags && tags.length > 0" :class="{ 'opacity-50': searching }" class="list-group">
          <li
            v-for="tag in tags"
            :key="tag.id"
            :class="{ 'item-selected': tag === selectedElement }"
            class="md-search-item d-flex"
          >
            <router-link
              :to="{ name: 'sites', query: { tags: [tag.id] } }"
              class="md-search-link btn btn-link btn-link-dark-primary d-flex w-100 align-items-center rounded-0 position-relative lh-xl
                border-0 px-16 py-8"
              @click.exact="clear()"
              @click.ctrl="clear()"
              @focus="selectedElement = tag"
              @mouseover="selectedElement = tag"
            >
              <m-icon class="fw-bold me-16 text-gray-500" icon="tag" />
              <span v-dompurify-html="highlightMatchesSearch(tag.name)" class="team-name text-sm" />
            </router-link>
          </li>
        </ul>

        <ul v-if="searchValue && !!sites && sites.length > 0" :class="{ 'opacity-50': searching }" class="list-group">
          <li
            v-for="site in sites"
            :key="site.id"
            :class="{ 'item-selected': site === selectedElement }"
            class="md-search-item d-flex"
          >
            <div class="md-search-link d-flex w-100 align-items-center justify-content-between">
              <router-link
                :to="{ name: 'sites.site.show', params: { site: site.slug } }"
                class="btn btn-link btn-link-dark-primary d-flex w-100 align-items-center justify-content-between rounded-0 position-relative lh-xl
                border-0 ps-16 py-4 pe-0"
                @click.exact="clear(); launchTracking(site)"
                @click.ctrl="clear(); launchTracking(site)"
                @focus="selectedElement = site"
                @mouseover="selectedElement = site"
              >
                <span class="d-flex align-items-center">
                  <m-icon class="text-info fw-bold me-16" icon="link" />

                  <span class="d-flex flex-column">
                    <span v-dompurify-html="highlightMatchesSearch(site.name)" class="lh-xl text-sm" />

                    <span class="d-flex lh-lg gap-16 text-xs text-gray-500">
                      <span>{{ site?.team?.name }}</span>
                      <span v-dompurify-html="highlightMatchesSearch(parseUri(site.host, site.path))" />
                    </span>
                  </span>
                </span>
              </router-link>

              <VButton
                v-if="site.connectionStatus === 'established'"
                :disabled="loadingLoginButton"
                class="md-search-wordpress d-flex align-items-center text-nowrap text-primary-dark text-xs rounded-0 py-8 px-12 h-100 me-4"
                emit-event-data
                variant="transparent"
                @click.prevent="handleGoToWordPress(site.id)"
              >
                {{ $t('general.button.toWordPress') }}

                <m-icon class="text-primary-dark ms-8" icon="wordpress-go" size="normal" />
              </VButton>
            </div>
          </li>
        </ul>

        <p
          v-if="!searching && teams.length === 0 && sites.length === 0 && tags.length === 0 && !!searchValue && searchValue.length >= 3"
          class="fw-normal mb-0 p-16 text-sm"
        >
          {{ $t('dashboard.search.noResults') }}
        </p>

        <p
          v-if="!searching && ((!!searchValue && searchValue.length < 3) || !searchValue)"
          class="fw-normal mb-0 p-16 text-sm"
        >
          {{ $t('dashboard.search.noResults') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.md-search {
  #search-input {
    height: 2.5rem;
    border: 0.0625rem solid var(--md-gray-400);
    transition: background-color 0.2s ease-in-out;

    &::placeholder {
      color: var(--md-gray-500);
      font-size: 0.875rem;
      font-weight: normal;
    }

    &:focus {
      background-color: var(--md-light);
    }
  }

  &.search-box-open {
    #search-input {
      background-color: var(--md-light);
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &:not(.search-box-open) {
    #search-input {
      background-color: transparent;
    }
  }

  .search-icon {
    top: 50%;
    transform: translateY(-50%);

    &.search {
      left: 1rem;
      width: 1rem;
      height: 1rem;
    }

    &.clear {
      right: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.125rem solid transparent;
      background: none;
      z-index: 10;
    }
  }

  .md-search-box {
    max-height: 30rem;
    overflow-y: auto;
    z-index: 1025;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    opacity: 0;
    transition: all 1s ease-out;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgb(0 0 0 / 4%);
    border: 0.0625rem solid var(--md-gray-400);

    &.show {
      display: block;
      opacity: 1;
    }

    .md-search-link {
      :deep(mark) {
        background-color: transparent;
        color: inherit;
        padding: 0 !important;
        font-weight: 600;
      }

      &:focus {
        box-shadow: inset 0 0 0 0.15rem rgba(var(--md-primary-rgb), 0.25) !important;
      }

      .team-name {
        line-height: 1.375rem;
      }
    }

    .md-search-item {
      &:hover {
        .md-search-link {
          background-color: var(--md-gray-200);
        }
      }

      &.item-selected .md-search-link {
        background-color: var(--md-gray-300);
      }

      &.md-site-placeholder {
        height: 2.375rem;
      }

      &.md-team-placeholder {
        height: 2.75rem;
      }
    }

    .list-group:not(:last-child) {
      position: relative;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 0.0625rem;
        background: var(--md-gray-400);
      }
    }
  }

  .md-search-wordpress {
    background-color: transparent;

    &:hover {
      background-color: var(--md-gray-400);
    }
  }
}
</style>
