import type {
  GetCurrentSiteParams,
  GetCurrentSiteParamsConnection,
  ISiteStoreState,
  SiteActiveServices,
  SiteFiltersType,
} from '@/stores/site/index';

import type { Site } from '@/types/models/site/Site';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import api from '@/api';
import { useManagerStore } from '@/stores/manager/managerStore';
import useBackupStore from '@/stores/site/backup/backupStore';

import useReportsStore from '@/stores/site/report/reportsStore';
import useSocketStore from '@/stores/socketStore';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import { SiteStatusEnumConst } from '@/types/enums/SiteStatusEnum';
import { defineStore } from 'pinia';
import { useUptimeStore } from '@/stores/site/uptime/uptimeStore';
import useMetricsStore from '@/stores/site/metrics/metricsStore';

const useSiteStore = defineStore('site', (): ISiteStoreState => {
  const currentSite: Ref<Site | null> = ref(null);
  const siteServices: Ref<SiteActiveServices[]> = ref([]);

  const reportsStore = useReportsStore();
  const backupStore = useBackupStore();
  const uptimeStore = useUptimeStore();
  const metricsStore = useMetricsStore();
  const socketStore = useSocketStore();
  const managerStore = useManagerStore();

  const router = useRouter();

  const reset = () => {
    currentSite.value = null;
    siteServices.value = [];
  };

  const cleanCurrentSite = async () => {
    reset();

    reportsStore.reset();
    backupStore.reset();
    metricsStore.reset();
    managerStore.reset();
    uptimeStore.reset();
    socketStore.unsubscribeSite();
  };

  const reloadCurrentSite = async ({ site, withTeam = false }: GetCurrentSiteParams) => {
    try {
      const params: SiteFiltersType = {
        include: ['tags', 'team'],
        appends: ['performance', 'health_critical_errors'],
      };

      if (withTeam) {
        params?.include?.push('team');
      }

      const [requestedSite, requestedSiteServices] = await Promise.all([
        api.site.general.retrieve(site, params),
        await api.site.general.retrieveServices(site),
      ]);

      currentSite.value = requestedSite;
      siteServices.value = requestedSiteServices;

      socketStore.subscribeSite(currentSite.value.id);
    } catch (e: any) {
      if (e?.response?.status === 404) {
        await router.replace({ name: 'me.sites.index' });
      } else {
        console.error('Error loading Site', e);
      }
    }
  };

  const getCurrentSite = async (
    {
      site: siteSlug,
      isFirstConnection = false,
      withTeam = false,
    }: GetCurrentSiteParamsConnection) => {
    await reloadCurrentSite({ site: siteSlug, withTeam });
    if (!!currentSite.value) {
      if (isFirstConnection) {
        currentSite.value.recentlyConnected = true;
      }
    }
  };

  const reloadCurrentSiteServices = async () => {
    if (!!currentSite.value) {
      siteServices.value = await api.site.general.retrieveServices(currentSite.value.slug);
    }
  };

  function removeTagFromCurrentSite(tagId: string | number) {
    if (!!currentSite.value && !!currentSite.value.tags && currentSite.value.tags.length > 0) {
      currentSite.value.tags = currentSite.value.tags.filter(tag => tag.id !== tagId);
    }
  }

  const currentSiteSlug = computed(() => currentSite.value?.slug);
  const currentSiteTags = computed(() => currentSite.value?.tags);
  const currentSiteTeam = computed(() => currentSite.value?.team);
  const currentSiteTeamSlug = computed(() => currentSite.value?.team?.slug);
  const currentSiteName = computed(() => currentSite.value?.name);
  const currentSiteUri = computed(() => currentSite.value?.uri);
  const currentSiteId = computed(() => currentSite.value?.id);
  const currentSiteConnectionStatus = computed(() => currentSite.value?.connectionStatus);
  const currentSiteIsConnected = computed(() => currentSite.value?.connectionStatus === SiteStatusEnumConst.SUCCESS);
  const currentSiteConnectorVersion = computed(() => currentSite.value?.connectorVersion);

  const hasCurrentSiteLoaded = computed(() => !!currentSite.value);

  const reportServiceId = computed(() => (siteServices.value ?? []).find(service => service.type === ServiceTypeEnumConst.REPORT)?.id);
  const analyticsServiceId = computed(() => (siteServices.value ?? []).find(service => service.type === ServiceTypeEnumConst.ANALYTICS)?.id);
  const searchConsoleServiceId = computed(() => (siteServices.value ?? []).find(service => service.type === ServiceTypeEnumConst.SEARCH_CONSOLE)?.id);
  const uptimeServiceId = computed(() => (siteServices.value ?? []).find(service => service.type === ServiceTypeEnumConst.UPTIME)?.id);
  const performanceServiceId = computed(() => (siteServices.value ?? []).find(service => service.type === ServiceTypeEnumConst.PERFORMANCE)?.id);
  const backupsServiceId = computed(() => (siteServices.value ?? []).find(service => service.type === ServiceTypeEnumConst.BACKUP)?.id);
  const healthServiceId = computed(() => (siteServices.value ?? []).find(service => service.type === ServiceTypeEnumConst.HEALTH)?.id);

  return {
    currentSite,
    siteServices,

    currentSiteSlug,
    currentSiteTags,
    currentSiteTeam,
    currentSiteTeamSlug,
    currentSiteName,
    currentSiteUri,
    currentSiteId,
    currentSiteConnectionStatus,
    currentSiteIsConnected,
    currentSiteConnectorVersion,

    hasCurrentSiteLoaded,

    reportServiceId,
    analyticsServiceId,
    searchConsoleServiceId,
    uptimeServiceId,
    performanceServiceId,
    backupsServiceId,
    healthServiceId,

    reset,
    cleanCurrentSite,
    getCurrentSite,
    reloadCurrentSite,
    reloadCurrentSiteServices,
    removeTagFromCurrentSite,
  };
});

export { useSiteStore };

export default useSiteStore;
