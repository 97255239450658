import type { AnalyticsReportRequest, AnalyticsServiceStoreRequest } from '@/api/services/site/service/analytics/index';
import type { SimpleResponse } from '@/types/Api';
import type { SiteAnalyticsAccount } from '@/types/models/site/service/analytics/SiteAnalyticsAccount';
import type { SiteAnalyticsReport } from '@/types/models/site/service/analytics/SiteAnalyticsReport';
import type { SiteServiceAnalytics } from '@/types/models/site/service/analytics/SiteServiceAnalytics';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class AnalyticsService extends AbstractService {
  /**
   * Retrieves the analytics service for the current site.
   *
   * @returns {Promise<SiteServiceAnalytics>}
   * @param serviceId
   */
  public retrieve(serviceId: number): Promise<SiteServiceAnalytics> {
    return this.request({
      method: apiEndpoints.analyticsServicesShow.method,
      url: this.buildPath(apiEndpoints.analyticsServicesShow, {
        analytics_service: serviceId,
      }),
      options: {
        cancellable: true,
        cancelKey: `retrieveAnalyticsService-${serviceId}`,
      },
    });
  }

  /**
   * Retrieves an analytics report for the current site based on the specified date range.
   *
   * @param service
   * @param start
   * @param end
   * @param reportName
   * @returns {Promise<SiteAnalyticsReport>}
   */
  public retrieveReport({ service, start, end, reportName = 'all' }: AnalyticsReportRequest,
  ): Promise<SiteAnalyticsReport> {
    return this.request({
      method: apiEndpoints.analyticsServicesReport.method,
      url: this.buildPath(apiEndpoints.analyticsServicesReport, {
        analytics_service: service,
      }),
      params: {
        started_at: start,
        ended_at: end,
        report_name: reportName,
      },
      options: {
        cancellable: true,
        cancelKey: 'analyticsRetrieve',
      },
    });
  }

  /**
   * Updates the analytics service for the current site.
   *
   * @param service
   * @param data
   * @returns {Promise<SiteServiceAnalytics>}
   */
  public update(service: number, data: AnalyticsServiceStoreRequest): Promise<SiteServiceAnalytics> {
    return this.request({
      method: apiEndpoints.analyticsServicesUpdate.method,
      url: this.buildPath(apiEndpoints.analyticsServicesUpdate, {
        analytics_service: service,
      }),
      data,
    });
  }

  /**
   * Disconnects the analytics service from the current site.
   *
   * @returns {Promise<SimpleResponse>}
   * @param serviceId
   */
  public delete(serviceId: number): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.analyticsServicesDestroy.method,
      url: this.buildPath(apiEndpoints.analyticsServicesDestroy, {
        analytics_service: serviceId,
      }),
    });
  }

  /**
   * Retrieves the GA4 accounts for the current site.
   *
   * @returns {Promise<SiteAnalyticsAccount[]>}
   * @param serviceId
   */
  public retrieveAccounts(serviceId: number): Promise<SiteAnalyticsAccount[]> {
    return this.request({
      method: apiEndpoints.analyticsServicesAccountsIndex.method,
      url: this.buildPath(apiEndpoints.analyticsServicesAccountsIndex, {
        analytics_service: serviceId,
      }),
    });
  }

  /**
   * Get GA properties
   *
   * @param serviceId
   * @param {string | number} account
   * @returns {Promise<SiteAnalyticsAccount[]>}
   */
  public retrieveProperties(serviceId: number, account: string | number): Promise<SiteAnalyticsAccount[]> {
    return this.request({
      method: apiEndpoints.analyticsServicesPropertiesIndex.method,
      url: this.buildPath(apiEndpoints.analyticsServicesPropertiesIndex, {
        analytics_service: serviceId,
        account,
      }),
    });
  }
}
