<script setup lang="ts">
import type { PermissionEnumType } from '@/types/PermissionEnum';
import type { ProductsEnumType } from '@/types/ProductType';

import { can } from '@/helpers/permissionsHelper';
import { ProductsEnumConst } from '@/types/ProductType';

defineOptions({
  name: 'MPermissionProvider', // m-permission-provider
});

const props = withDefaults(defineProps<Props>(), {
  planName: ProductsEnumConst.STARTER,
});

const { STARTER, BUSINESS } = ProductsEnumConst;

// TODO: A futuro, cuando en el ENUM de permisos tenga el mínimo rol necesario para tener dicho permiso, se mostrará
//  el ProButton o el BusinessButton según este rol, no por la prop 'planName'.
interface Props {
  permission: PermissionEnumType
  planName?: ProductsEnumType
}
</script>

<template>
  <transition-group appear mode="out-in" name="fade-in-fast" tag="div" class="m-permission-provider">
    <slot v-if="can(props.permission)" />

    <slot v-else-if="!!$slots.fallback" name="fallback" />

    <m-pro-button v-else-if="planName === STARTER" track-name="text-name" />

    <m-business-button v-else-if="planName === BUSINESS" track-name="text-name" />
  </transition-group>
</template>
