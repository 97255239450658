<script lang="ts" setup>
import { cn } from '@/helpers/classNames';
import { Separator, type SeparatorProps } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

defineOptions({
  name: 'VSeparator',
});

const props = withDefaults(defineProps<SeparatorProps & {
  class?: HTMLAttributes['class']
  label?: string
  background?: string
}>(), {
  class: '',
  label: '',
  background: 'bg-gray-400',
});

const delegatedProps = computed(() => {
  const { class: _, background: __, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <Separator
    :class="
      cn(
        'ui-separator flex-shrink-0 position-relative',
        props.orientation === 'vertical' ? 'ui-separator-vertical h-auto' : 'ui-separator-horizontal w-100',
        props.background,
        props.class,
      )
    "
    v-bind="delegatedProps"
  >
    <span
      v-if="props.label"
      :class="
        cn(
          'ui-separator-label text-xs position-absolute d-flex justify-content-center align-items-center',
          props.orientation === 'vertical' ? 'ui-separator-label--vertical px-1 py-2' : 'ui-separator-label--horizontal py-1 px-2',
        )
      "
    >{{ props.label }}
    </span>
  </Separator>
</template>

<style lang="scss" scoped>
.ui-separator {
  height: 1px;

  &.ui-separator-vertical {
    width: 1px;
    height: auto;
  }

  &.ui-separator-horizontal {
    height: 1px;
  }
}

.ui-separator-label {
  top: 50%;
  left: -50%;
  transform: translate(-50%, -50%);

  &.ui-separator-label--vertical {
    width: 1px;
    padding: 2px 1px;
  }

  &.ui-separator-label--horizontal {
    height: 1px;
    padding: 1px 2px;
  }
}
</style>
