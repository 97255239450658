export abstract class NotificationBase {
  type: string;
  createdAt: string;
  readAt: string | null;
  updatedAt: string | null;

  protected constructor(type: string, createdAt: string, readAt: string | null, updatedAt: string | null) {
    this.type = type;
    this.createdAt = createdAt;
    this.readAt = readAt;
    this.updatedAt = updatedAt;
  }

  abstract render(): ReturnType<typeof h>;
}
