import { Concept } from '@/types/models/Concept';

export type AlertItemTypeKeys = 'uptime.down' | 'uptime.up' | 'uptime.ssl.expiration' | 'backup.failed' | 'site.connection-lost' | 'manager.upgraded.failed' | 'manager.activation.failed' | 'manager.deactivation.failed' | 'manager.deletion.failed' | 'manager.installation.failed' | 'siteReport.generated.contact' | 'report.automatic.failed' | 'vulnerability.discovered' | 'invoice.generated';

export const AlertItemTypeConst = {
  UPTIME_DOWN: 'uptime.down',
  UPTIME_UP: 'uptime.up',
  UPTIME_SSL_EXPIRATION: 'uptime.ssl.expiration',
  BACKUP_FAILED: 'backup.failed',
  SITE_CONNECTION_LOST: 'site.connection-lost',
  MANAGER_UPGRADED_FAILED: 'manager.upgraded.failed',
  MANAGER_ACTIVATION_FAILED: 'manager.activation.failed',
  MANAGER_DEACTIVATION_FAILED: 'manager.deactivation.failed',
  MANAGER_DELETION_FAILED: 'manager.deletion.failed',
  MANAGER_INSTALLATION_FAILED: 'manager.installation.failed',
  REPORT_SEND_TO_CONTACT: 'siteReport.generated.contact',
  REPORT_AUTOMATIC_FAILED: 'report.automatic.failed',
  VULNERABILITY_DISCOVERED: 'vulnerability.discovered',
  INVOICE_GENERATED: 'invoice.generated',
} as const;

export const AlertItemType: Record<AlertItemTypeKeys, Concept> = {
  'uptime.down': {
    key: 'uptime.down',
    value: null,
    name: 'concept.alert.item.type.uptimeDownName',
    shortName: null,
    description: 'concept.alert.item.type.uptimeDownDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'uptime'
  },
  'uptime.up': {
    key: 'uptime.up',
    value: null,
    name: 'concept.alert.item.type.uptimeUpName',
    shortName: null,
    description: 'concept.alert.item.type.uptimeUpDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'uptime'
  },
  'uptime.ssl.expiration': {
    key: 'uptime.ssl.expiration',
    value: null,
    name: 'concept.alert.item.type.uptimeSslExpirationName',
    shortName: null,
    description: 'concept.alert.item.type.uptimeSslExpirationDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'uptime'
  },
  'backup.failed': {
    key: 'backup.failed',
    value: null,
    name: 'concept.alert.item.type.backupFailedName',
    shortName: null,
    description: 'concept.alert.item.type.backupFailedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'backup'
  },
  'site.connection-lost': {
    key: 'site.connection-lost',
    value: null,
    name: 'concept.alert.item.type.siteConnectionLostName',
    shortName: null,
    description: 'concept.alert.item.type.siteConnectionLostDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'connector'
  },
  'manager.upgraded.failed': {
    key: 'manager.upgraded.failed',
    value: null,
    name: 'concept.alert.item.type.managerUpgradedFailedName',
    shortName: null,
    description: 'concept.alert.item.type.managerUpgradedFailedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'connector'
  },
  'manager.activation.failed': {
    key: 'manager.activation.failed',
    value: null,
    name: 'concept.alert.item.type.managerActivationFailedName',
    shortName: null,
    description: 'concept.alert.item.type.managerActivationFailedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'connector'
  },
  'manager.deactivation.failed': {
    key: 'manager.deactivation.failed',
    value: null,
    name: 'concept.alert.item.type.managerDeactivationFailedName',
    shortName: null,
    description: 'concept.alert.item.type.managerDeactivationFailedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'connector'
  },
  'manager.deletion.failed': {
    key: 'manager.deletion.failed',
    value: null,
    name: 'concept.alert.item.type.managerDeletionFailedName',
    shortName: null,
    description: 'concept.alert.item.type.managerDeletionFailedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'connector'
  },
  'manager.installation.failed': {
    key: 'manager.installation.failed',
    value: null,
    name: 'concept.alert.item.type.managerInstallationFailedName',
    shortName: null,
    description: 'concept.alert.item.type.managerInstallationFailedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'connector'
  },
  'siteReport.generated.contact': {
    key: 'siteReport.generated.contact',
    value: null,
    name: 'concept.alert.item.type.siteReportGeneratedContactName',
    shortName: null,
    description: 'concept.alert.item.type.siteReportGeneratedContactDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type'
  },
  'report.automatic.failed': {
    key: 'report.automatic.failed',
    value: null,
    name: 'concept.alert.item.type.reportAutomaticFailedName',
    shortName: null,
    description: 'concept.alert.item.type.reportAutomaticFailedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'report'
  },
  'vulnerability.discovered': {
    key: 'vulnerability.discovered',
    value: null,
    name: 'concept.alert.item.type.vulnerabilityDiscoveredName',
    shortName: null,
    description: 'concept.alert.item.type.vulnerabilityDiscoveredDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type',
    service: 'vulnerability'
  },
  'invoice.generated': {
    key: 'invoice.generated',
    value: null,
    name: 'concept.alert.item.type.invoiceGeneratedName',
    shortName: null,
    description: 'concept.alert.item.type.invoiceGeneratedDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'alert.item.type'
  }
} as const;

export type AlertItemTypeType = (typeof AlertItemTypeConst)[keyof typeof AlertItemTypeConst];

export const AlertItemTypeArray = Object.values(AlertItemType);
