export default {
  jaimeGarmar:
    'Gestionamos +100 Webs de clientes y con Modular todo es fácil, no solo por lo que hace, sino por el soporte humano que hay detrás.',
  martaTorre:
    'Probé Modular porque es un proyecto español y porque vi el diseño y me gustó bastante. Luego me quedé por todo lo que tienen y que escuchan bastante a los usuarios.',
  pabloMoratinos:
    'Con Modular he descubierto uno de los SaaS que más tiene en cuenta el feedback de sus clientes para añadir nuevas funcionalidades.',
  sebastianEchevarri:
    'Llevo el mantenimiento web de mis clientes y nada mejor que poder controlar que todo esté funcionando correctamente desde un solo sitio.',
};
