<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import useRedirectToPayment from '@/composables/useRedirectToPayment';
import { cn } from '@/helpers/classNames';

defineOptions({
  name: 'MProButton',
});

const props = withDefaults(defineProps<Props>(), {
  class: '',
  text: 'general.shared.becomePro',
  withTag: true,
});

interface Props {
  class?: HTMLAttributes['class']
  trackName: string
  text?: string
  withTag?: boolean
}

const { redirectToPayment } = useRedirectToPayment();
</script>

<template>
  <m-button
    data-cy="btn-login"
    variant="outlineGray"
    :class="cn(props.class)"
    class="position-relative h-fit"
    size="sm"
    @click="redirectToPayment({ trackName })"
  >
    {{ $t(text) }}

    <m-pro-badge v-if="withTag" class="position-absolute translate-middle-y end-0 top-0" />
  </m-button>
</template>
