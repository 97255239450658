<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
  name: 'VLoadBar',
});

const props = withDefaults(defineProps<Props>(), {
  type: 'large',
  background: 'primary',
  color: 'primary',
  height: 'normal',
  decrease: true,
});

interface Props {
  type?: 'large' | 'small'
  background?: 'primary' | 'gray-400'
  color?: 'primary' | 'success'
  height?: 'normal' | 'small'
  decrease?: boolean
}

const elementType = computed(
  () =>
    ({
      small: 'md-small-load-bar',
      large: 'md-load-bar',
    })[props.type],
);

const containerType = computed(
  () =>
    ({
      small: 'md-small-load-bar-container',
      large: 'md-load-bar-container',
    })[props.type],
);
</script>

<template>
  <div :class="[containerType, `md-load-bar-container-height-${height}`]">
    <div :class="elementType">
      <div class="md-line" :class="`bg-${background}`" />
      <div class="md-inside-line md-increase" :class="`bg-${color}`" />
      <div v-if="decrease" class="md-inside-line md-decrease" :class="`bg-${color}`" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.md-load-bar-container-height-normal {
  height: 0.5rem;

  .md-load-bar,
  .md-line,
  .md-inside-line {
    height: 0.5rem;
  }
}

.md-load-bar-container-height-small {
  height: 0.25rem;

  .md-load-bar,
  .md-line,
  .md-inside-line {
    height: 0.25rem;
  }
}

.md-small-load-bar {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 2rem;

  &,
  .md-line,
  .md-inside-line {
    height: 0.5rem;
  }

  .md-line {
    position: absolute;
    left: 0;
    opacity: 0.4;
    background: var(--md-primary);
    width: 150%;
  }

  .md-inside-line {
    display: block;
    width: 100%;
    height: 12px;
    position: absolute;
    background: var(--md-primary);

    &.md-increase {
      animation: increase 5s infinite ease-in-out;
    }
  }
}

.md-load-bar-container {
  .md-load-bar {
    position: absolute;
    width: 100%;
    overflow-x: hidden;
    border-radius: 2rem;

    .md-line {
      position: absolute;
      opacity: 0.4;
      width: 150%;
    }

    .md-inside-line {
      position: absolute;

      &.md-increase {
        animation: increase 4s infinite ease-in-out;
      }

      &.md-decrease {
        animation: decrease 4s 2s infinite ease-in-out;
      }
    }
  }
}
</style>
