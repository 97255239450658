import type { AnalyticsReportRequest } from '@/api/services/site/service/analytics';
import type { SearchConsoleServiceRetrieveReportRequest } from '@/api/services/site/service/search-console';
import type { ServiceTypeEnumKeys } from '@/types/enums/ServiceTypeEnum';
import type { Ref } from 'vue';
import api from '@/api';
import { useToast } from '@/composables/useToast';
import DateHelper from '@/helpers/dateHelper';
import useConstantsHelper from '@/resources/constants/ConstantsHelper';
import useMetricsStore from '@/stores/site/metrics/metricsStore';
import useSiteStore from '@/stores/site/siteStore';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

type ALLOWED_SERVICES = Extract<ServiceTypeEnumKeys, 'analytics' | 'search_console'>;
function useMetrics(service: ALLOWED_SERVICES | 'none') {
  const { t } = useI18n();
  const siteStore = useSiteStore();
  const metricsStore = useMetricsStore();

  // region Refs
  const maxEndDate = computed(() => DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'));

  const disableSignInButton = ref(false);
  const selectedDateRange: Ref<number | string> = ref(7);

  const metricsDatesFields = ref({
    start: DateHelper.getPastDateByDays(Number(selectedDateRange.value)),
    end: DateHelper.getPastDateByDays(1),
  });

  const resetMetricsDatesFields = () => {
    metricsDatesFields.value = {
      start: DateHelper.getPastDateByDays(Number(selectedDateRange.value)),
      end: DateHelper.getPastDateByDays(1),
    };
  };

  const metricsDatesSelectorFields = computed(() => [
    {
      key: 'selector',
      label: t('site.uptime.shared.byDate'),
    },
    {
      key: 7,
      label: t('statistics.lastDays', { days: 7 }),
    },
    {
      key: 14,
      label: t('statistics.lastDays', { days: 14 }),
    },
    {
      key: 28,
      label: t('statistics.lastDays', { days: 28 }),
    },
  ]);
  // endregion Refs

  // region Tracker
  const { SEARCH_CONSOLE, ANALYTICS } = ServiceTypeEnumConst;

  const currentSiteUri = computed(() => siteStore.currentSiteUri);
  const currentSiteName = computed(() => siteStore.currentSiteName);
  const trackChangeStatisticsProperty = async (service: typeof SEARCH_CONSOLE | typeof ANALYTICS, isChangingAccount = false) => {
    if (isChangingAccount) {
      await api.mixpanel.track(
        service === SEARCH_CONSOLE ? 'Change Search Console property' : 'Change Analytics property',
        {
          'Website name': currentSiteName.value,
          'Website url': currentSiteUri.value,
        },
      );
    } else if (service === SEARCH_CONSOLE) {
      await api.mixpanel.track('Connect Search Console', {
        'Website name': currentSiteName.value,
        'Website url': currentSiteUri.value,
      });
    } else {
      await api.mixpanel.track('Connect Analytics', {
        'Website name': currentSiteName.value,
        'Website url': currentSiteUri.value,
        'Analytics version': 'GA4',
      });
    }
  };
  // endregion Tracker

  // region Analytics & Search Console
  const analyticsServiceId = computed(() => metricsStore.analyticsServiceId);
  const searchConsoleServiceId = computed(() => metricsStore.searchConsoleServiceId);

  const {
    analyticsReport,
    analyticsService,
    loadedAnalytics,
    loadedAnalyticsReport,
    searchConsoleReport,
    searchConsoleService,
    loadedSearchConsole,
    loadedSearchConsoleReport,
  } = storeToRefs(metricsStore);

  const loadMetricService = async () => {
    try {
      if (analyticsServiceId.value && service === ServiceTypeEnumConst.ANALYTICS) {
        loadedAnalytics.value = false;
        analyticsService.value = await api.site.service.analytics.retrieve(analyticsServiceId.value);
      } else if (searchConsoleServiceId.value && service === ServiceTypeEnumConst.SEARCH_CONSOLE) {
        loadedSearchConsole.value = false;
        searchConsoleService.value = await api.site.service.searchConsole.retrieve(searchConsoleServiceId.value);
      }
    } catch (e) {
      console.error(`Error loading ${service} service`, e);
      throw e;
    } finally {
      if (service === ServiceTypeEnumConst.ANALYTICS)
        loadedAnalytics.value = true;
      if (service === ServiceTypeEnumConst.SEARCH_CONSOLE)
        loadedSearchConsole.value = true;
    }
  };

  const deleteMetricsService = async () => {
    try {
      if (analyticsServiceId.value && service === ServiceTypeEnumConst.ANALYTICS) {
        await api.site.service.analytics.delete(analyticsServiceId.value);

        analyticsService.value = null;
        analyticsReport.value = null;
      } else if (searchConsoleServiceId.value && service === ServiceTypeEnumConst.SEARCH_CONSOLE) {
        await api.site.service.searchConsole.delete(searchConsoleServiceId.value);

        searchConsoleService.value = null;
        searchConsoleReport.value = null;
      }

      await siteStore.reloadCurrentSiteServices();
    } catch (e) {
      console.error('Error deleting analytics service', e);
      throw e;
    }
  };

  // endregion Analytics & Search Console

  // region Analytics
  const loadAnalyticsReport = async (request?: Omit<AnalyticsReportRequest, 'service' | 'reportName'>) => {
    if (request && analyticsServiceId.value && metricsStore.analyticsIsEnabled) {
      try {
        loadedAnalyticsReport.value = false;

        analyticsReport.value = await api.site.service.analytics.retrieveReport({
          service: analyticsServiceId.value,
          ...request,
          reportName: 'all',
        });
      } catch (e: any) {
        if (e?.code !== 'ERR_CANCELED') {
          console.error('Error loading analytics report', e);

          throw e;
        }
      } finally {
        loadedAnalyticsReport.value = true;
      }
    }
  };

  const { metrics } = useConstantsHelper();
  const router = useRouter();
  const route = useRoute();

  const { toastError } = useToast();
  const connectNewGoogleAccount = async (sendCallbackKey = true): Promise<void> => {
    try {
      const name = route.name;

      const redirection = router.resolve({
        name,
        query: {
          ...(sendCallbackKey && { open: metrics.metricsRouteCallbackKey }),
          noEvent: 'true',
        },
      });

      const response = await api.organization.accounts.connect(redirection.fullPath);

      if (response.status === 'success') {
        window.location.href = response.redirectUri;
      } else {
        toastError();
      }
    } catch (e) {
      console.error('Error connecting new Google account', e);

      toastError();
      throw e;
    }
  };
  // endregion Analytics

  // region Search Console
  const loadSearchConsoleReport = async (request?: Omit<SearchConsoleServiceRetrieveReportRequest, 'service'>) => {
    if (request && searchConsoleServiceId.value) {
      try {
        loadedSearchConsoleReport.value = false;

        searchConsoleReport.value = await api.site.service.searchConsole.retrieveReport({
          service: searchConsoleServiceId.value,
          ...request,
        });

        loadedSearchConsoleReport.value = true;
      } catch (e: any) {
        if (e?.code !== 'ERR_CANCELED') {
          console.error('Error loading search console report', e);
        }
        throw e;
      }
    }
  };
  // endregion Search Console

  return {
    metricsDatesFields,
    resetMetricsDatesFields,
    metricsDatesSelectorFields,
    disableSignInButton,
    selectedDateRange,
    maxEndDate,
    trackChangeStatisticsProperty,

    loadMetricService,
    deleteMetricsService,

    loadAnalyticsReport,

    loadSearchConsoleReport,

    connectNewGoogleAccount,
  };
}

export { useMetrics };
export default useMetrics;
