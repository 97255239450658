export default {
  personalData: 'Personal data',
  addImage: 'Add image',
  optional: '(optional)',
  actualPassword: 'Actual password',
  actualPasswordToChangeEmail: 'Enter your current password to change your email.',
  myProfile: 'My profile',
  earlyAdopter: 'Early Adopter',
  confirmPassword: 'Confirm your password',
  confirmToChangeProfile: 'In order to edit your profile, we need you to confirm your password.',
  social: {
    socialLogin: 'Social Login',
    googleLogo: 'Google Logo',
    googleAccount: 'Google account',
    enableSocial: 'Connect a Google account so that you can sign in directly with it in Modular.',
    disableSocial:
      'If you disconnect your Google Account, you will need to sign in with your username and password and set a password if you have not already created one.',
    socialNetworkAlreadyExistsError: 'The specified social network already exists in our database.',
    disableSocialAccountModalTitle: 'Deactivate Google Account',
    disableSocialTextWithPasswordText:
      'Once deactivated you will not be able to access your Modular account through your Google account. You will have to use your account email ({email}) and password.',
    disableSocialTextWithPasswordSubtitle: 'We will send you an email to set your password',
  },
  doubleFA: {
    title: '2-Factor Authentication (2FA)',
    preTitle: '2-Factor Authentication',
    text: 'Increases the security of access to your account',
    descriptionLabel: 'Prevent other people from accessing your account.',
    descriptionText:
      'Turn on 2-step authentication to protect yourself even if they somehow managed to figure out the password you\'re using. This way, even if your credentials are stolen, there will always be that second step where you have to verify the login.',
    recoveryTitle: 'Access Recovery',
    recoveryDescription: 'Confirm access to your account with your 2-step authentication recovery code.',
    recoveryFieldTag: 'Recovery code',
    faSecret: 'Secret: {secret}',
    faType: 'Type: TOTP (time-based one-time password)',
    faTDigits: 'Digits: 6',
    faTAlgorithm: 'Algorithm: SHA1',
    faTInterval: 'Interval: 30',
    modalConfigTitle: 'Configure 2-Factor Authentication (2FA)',
    modalConfigDescription: 'Follow these steps to start receiving 6-digit codes on your mobile.',
    modalConfigPhase1:
      'Install an authenticator application on your device (for example FreeOTP, Google Authenticator...)',
    modalConfigPhase2: 'Open the authenticator app on your mobile device',
    modalConfigPhase3: 'Scan the QR code shown below with the application',
    endConfigText:
      'To finish the configuration, enter the 6-digit code that the authenticator application shows you on the mobile device. In the case of FreeOTP, the code is displayed when you type in the MODULAR input of the authenticator app.',
    modalDisableTitle: 'Disable 2-Factor Authentication (2FA)',
    modalDisableText:
      'If you disable 2-factor authentication, you won\'t be able to protect your account with this option. Are you sure you want to disable it?',
    recoveryKeys: 'Recovery Keys',
    modalSaveRecoveryKeys:
      'Save your account\'s 2FA recovery keys in a safe place. These keys are very important in case you lose your device or can\'t access your authenticator app.',
    copyRecoveryCodes: 'Copy keys',
  },
  languages: {
    selectLanguage: 'Select a language',
    spanish: 'Spanish',
    english: 'English',
  },
  connectionDevices: 'Connection & Devices',
  currentSession: 'Current session',
  otherSessions: 'Other open sessions',
  disconnectAlertMessage: 'Here you can view and disconnect from the sessions you have open on other devices.',
  noOtherSessionTitle: 'You have no other sessions open at this time',
  noOtherSessionText: 'If you log in on another device, it will appear here',
  thisBrowser: 'This browser',
  lastUsed: 'Last used on { date } (UTC)',
  ip: 'IP: { value }',
  disconnectAllSessionsTitle: 'Close all open sessions',
  disconnectAllSessionsText:
    'If you close your other open sessions you will have to manually log in again to be able to use Modular on the listed devices.',
  passwordMessage:
    'Changing your password will log you out of all your active Modular sessions except the one you’re using right now.',
  dateAndTimezoneFormats: 'Date and timezone formats',
  selectTimezone: 'Select a timezone',
  timezone: 'Timezone',
  dateTimeFormat: {
    label: 'Time format',
    twelveHours: '12h (6:30pm) format',
    twentyFourHours: '24h (18:30) format',
  },
  dateFormat: {
    label: 'DateFormat',
    ddmmyyyy: 'dd/mm/yyyy',
    mmddyyyy: 'mm/dd/yyyyy',
    yyyymmdd: 'yyyy-mm-dd',
  },
  timeFormat: 'TimeFormat',
};
