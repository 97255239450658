import type { SimpleResponse } from '@/types/Api';
import type { Organization } from '@/types/models/organization/Organization';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class WhiteLabelService extends AbstractService {
  /**
   * Retrieves the white label information for an organization.
   *
   * @returns {Promise<Organization>}
   */
  public retrieve(): Promise<Organization> {
    return this.request({
      method: apiEndpoints.whiteLabelShow.method,
      url: this.buildPath(apiEndpoints.whiteLabelShow),
    });
  }

  /**
   * Updates the white label information for an organization.
   *
   * @param data
   * @returns {Promise<SimpleResponse>}
   */
  public update(data: any): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.whiteLabelUpdate.method,
      url: this.buildPath(apiEndpoints.whiteLabelUpdate),
      data,
      options: {
        cancellable: true,
        cancelKey: 'updateWhiteLabel',
      },
    });
  }
}
