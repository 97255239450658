export default {
  all: 'Todo',
  upgradesAvailable: 'Actualizaciones disponibles',
  availableUpdates: '1 Actualización disponible | {total} Actualización disponible | {total} Actualizaciones disponibles',
  successReloadItemsText: 'Recargando datos. Pueden pasar unos minutos hasta que aparezcan todas las actualizaciones pendientes.',
  hiddenUpdates: 'Actualizaciones ocultas',
  plugin: 'plugin | plugins',
  theme: 'tema | temas',
  core: 'WordPress',
  withVulnerabilities: 'Con vulnerabilidades',
  selectStatus: 'Selecciona estado de los items',
  synchronized: 'Versiones sincronizadas',
  reloadData: 'Recargar',
  installPlugin: 'Instalar plugin',
  installTheme: 'Instalar tema',
  searchType: 'Buscar { type }',
  selectAll: 'Seleccionar todos',
  deselect: 'Deseleccionar',
  hideUpdates: 'Ocultar',
  showUpdates: 'Mostrar',
  activateUpdates: 'Activar',
  deactivateUpdates: 'Desactivar',
  connectorNotUpdated: 'Alguna de tus webs están utilizando una versión antigua del plugin Modular Connector. Actualiza a la versión más reciente para aprovechar todas las funcionalidades disponibles, como activar, desactivar e instalar plugins/temas.',
  connectorNotUpdatedShort: 'Primero actualiza Modular Connector en tus webs',
  connectorNotUpdatedSiteShort: 'Primero actualiza Modular Connector en esta web',
  updateType: 'Actualizar { type }',
  refreshItems: 'Hay datos nuevos disponibles',
  selectedItems: '{ count } items selecccionados | { count } item seleccionado | { count } item seleccionados',
  cannotReloadText: 'Sólo se puede recargar la lista de actualizaciones cada 5 minutos.',
  updateAvailable: 'Actualización disponible',
  updateHidden: 'Actualización oculta',
  updateDisabled: '<span class="text-capitalize">{ type }</span> desactivado',
  componentItemDisabled: 'Este { type } está desactivado',
  themeActive: 'Tema activo',
  noFilterMatch: '¡Ups! No encontramos nada',
  noFilterMatchText: 'Parece que no hay ninguna web en tu cuenta de Modular que coincida con los filtros que has aplicado. Intenta con otra combinación.',
  lostConnection: 'Se ha perdido la conexión con tu web. Vuelve a conectar para ver esta información.',
  lostConnectionText: 'Descubre las razones más comunes y cómo solucionarlo en <a href="https://soporte.modulards.com/es/error-conexion-web" target="_blank" rel="noreferrer noopener" aria-label="Soporte">este artículo</a>. Si el problema persiste te agradeceríamos mucho que nos escribas a <a href="mailto:{email}" aria-label="Contacta por email">{email}</a> para que te ayudemos a solucionarlo.',
  noSitesConnection: 'Todavía no has conectado ninguna web',
  noSitesConnectionText: '¿Por qué no conectas tu primera web?',
  noHiddenUpdates: 'No tienes actualizaciones ocultas',
  noHiddenUpdatesText: 'Aprende sobre las actualizaciones ocultas <a href="https://soporte.modulards.com/es/actualizador-web#:~:text=refrescar%20la%20información.-,Ocultar%20actualizaciones,-Si%20prefieres%20no" target="_blank" rel="noreferrer noopener" aria-label="Soporte">aquí</a>.',
  noPendingUpdates: 'No hay actualizaciones pendientes',
  noPendingUpdatesText: 'De momento todo corriendo sin novedad.',
  connectWeb: 'Conectar web',
  noUpdates: '¡Nada que actualizar!',
  deleteTheme: 'Eliminar tema | Eliminar temas',
  deletePlugin: 'Eliminar plugin | Eliminar plugins',
  deletePluginModalText: 'Se eliminará el plugin en esta web | Se eliminarán los plugins en las webs seleccionadas',
  deleteThemeModalText: 'Se eliminará el tema en esta web | Se eliminarán los temas en las webs seleccionadas',
  activateTheme: 'Activar tema | Activar temas',
  activateThemeModalText: 'Al activar los nuevos temas, se desactivará automáticamente el tema activo actual de las webs selecionadas.',
  activateThemeModalTitle: 'Aviso importante',
  activateThemeModalTextOneSite: 'Al activar el nuevo tema, se desactivará automáticamente el tema activo actualmente en esta web.',
  freeBanner: {
    title: 'Aprovecha todas las funcionalidades de Modular',
    description: 'Hazte<span class=\'badge bg-success text-light rounded-xs text-xs fw-medium p-4 mx-4\'>PRO</span>para instalar plugins en bulk, hacer backups diarios, etc...',
  },
  installation: {
    zipWarningTitle: 'Verifica tus archivos ZIP antes de subirlos',
    zipWarningDescription: 'Modular no comprueba los archivos ZIP que subes a tus webs, por lo que deberías asegurarte de que estos son los archivos correctos. Te recomendamos descargar los plugins/temas del repositorio oficial de WordPress o de su web oficial',
    installUrl: 'Instalar desde URL',
    installAI: 'Utiliza la IA',
    installZip: 'Instalar desde un archivo ZIP',
    searchPlaceholder: 'Buscar plugin en WordPress.org',
    name: 'Nombre',
    rating: 'Rating',
    requirements: 'Requisitos',
    lastUpdate: 'Última actualización',
    selectSites: 'Seleccionar webs',
    activeInstallationsCount: '+{ count } instalaciones activas',
    atLeast: 'Al menos',
    noData: 'Sin datos',
    searchWeb: 'Buscar web',
    button: 'Instalar',
    progress: {
      title: '¡Hemos empezado con la instalación!',
      subtitle: 'Puedes ver el progreso aquí abajo',
      installationError: 'Error en la instalación',
      installationSuccess: 'Instalación completada',
    },
    plugin: {
      title: 'Instalar plugin',
      installUrlDescription: 'Ingresa la URL del plugin que deseas instalar. Añade plugins rápidamente desde cualquier web.',
      installAIDescription: 'Describe la funcionalidad que necesitas y nuestra IA se encarga de todo.',
      installZipDescription: 'Selecciona el archivo ZIP del plugin desde tu dispositivo. Vuelve a instalar ese plugin que ya tenías descargado.',
      modalConfirm: {
        title: 'Instalación de plugin',
        alertTitle: '¿Seguro que quieres instalar este plugin en las webs seleccionadas?',
        alertTitleWeb: '¿Seguro que quieres instalar este plugin en esta web?',
        alertTitleWebName: '¿Seguro que quieres instalar este plugin en {site}?',
        activate: 'Activar plugin después de la instalación',
        overwrite: 'Sobreescribir plugin si ya lo tienes instalado',
      },
      steps: {
        step1: {
          url: {
            title: 'Búsqueda de URL',
            description: '¿Dónde está alojado tu archivo?',
            content: {
              title: 'URL del paquete del plugin',
              description: 'Utiliza un enlace de descarga de acceso público, un enlace de Dropbox o un enlace de Google Drive de tu paquete de plugin (.zip) para instalarlo en tus webs.',
            },
          },
          ai: {
            title: 'Utiliza la IA',
            description: 'Cuéntanos que estás buscando',
            content: {
              title: 'Escribe tu prompt',
              description: 'Escribe una descripción de la funcionalidad que necesitas para tu web de WordPress. Nuestra herramienta de inteligencia artificial buscará el plugin perfecto para ti. Como si fuera magia.',
              placeholder: 'Describe la funcionalidad que necesitas...',
              by: 'by',
            },
          },
          zip: {
            title: 'Instalar desde un archivo ZIP',
            description: 'Sube el archivo desde tu ordenador.',
            content: {
              title: 'Sube tu propio archivo',
              description: 'Selecciona el archivo directamente desde tu dispositivo para poder instalarlo en tus webs.',
            },
          },
        },
        step2: {
          title: 'Selección de webs',
          description: 'Selecciona dónde quieres instalarlo',
          content: {
            title: 'Tus webs',
            noResults: 'Parece que no hay ningún plugin que se llame “<span class=\'text-dark fw-semi\'>{search}</span>” o parecido. Intenta con otra combinación.',
          },
        },
      },
    },
    theme: {
      title: 'Instalar tema',
      installUrlDescription: 'Ingresa la URL del tema que deseas instalar. Añade temas rápidamente desde cualquier web.',
      installZipDescription: 'Selecciona el archivo ZIP del tema desde tu dispositivo. Vuelve a activar ese tema que ya tenías descargado.',
      modalConfirm: {
        title: 'Instalación de tema',
        alertTitle: '¿Seguro que quieres instalar este tema en las webs seleccionadas?',
        alertTitleWeb: '¿Seguro que quieres instalar este tema en esta web?',
        alertTitleWebName: '¿Seguro que quieres instalar este tema en {site}?',
        activate: 'Activar tema después de la instalación (desactivará los temas que tengas activos actualmente)',
        overwrite: 'Sobreescribir tema si ya lo tienes instalado',
      },
      steps: {
        step1: {
          url: {
            title: 'Búsqueda de URL',
            description: '¿Dónde está alojado tu archivo?',
            content: {
              title: 'URL del paquete del tema',
              description: 'Utiliza un enlace de descarga de acceso público, un enlace de Dropbox o un enlace de Google Drive de tu paquete de tema (.zip) para instalarlo en tus webs.',
            },
          },
          zip: {
            title: 'Instalar desde un archivo ZIP',
            description: 'Sube el archivo desde tu ordenador',
            content: {
              title: 'Sube tu propio archivo',
              description: 'Selecciona el archivo directamente desde tu dispositivo para poder instalarlo en tus webs.',
            },
          },
        },
        step2: {
          title: 'Selección de webs',
          description: 'Selecciona dónde quieres instalarlo',
          content: {
            title: 'Tus webs',
            noResults: 'Parece que no hay ningún tema que se llame “<span class=\'text-dark fw-semi\'>{search}</span>” o parecido. Intenta con otra combinación.',
          },
        },
      },
    },
  },
}
