export default {
  shared: {
    addTeam: 'Add team',
    team: 'Team',
    teams: 'Teams',
    create: 'Create new team',
    name: 'Team name',
    createTeam: 'Create a team',
    successCreate: 'Team set up',
    edit: 'Edit team',
    delete: 'Delete team',
    deletedTeam: 'Deleted team',
  },
  delete: {
    alertTitle: 'You can\'t delete a team that has websites.',
    alertDescription: 'Delete or move to other teams the websites inside this team in order to delete it.',
  },
};
