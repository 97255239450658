import api from '@/api';
import { BillingProductTypeEnumConst } from '@/types/enums/BillingProductTypeEnum';

import { computed } from 'vue';

import { useRoute, useRouter } from 'vue-router';

/**
 * A composable function for handling redirects to the payment page.
 *
 * `useRedirectToPayment` provides a method for redirecting the user to a specific billing route, while
 * tracking the action with Mixpanel. It also exposes the current route path for other uses.
 *
 * @returns An object containing:
 *   - `currentRoutePath` {ComputedRef<string>}: The full path of the current route.
 *   - `redirectToPayment` {Function}: A function that redirects the user to a billing page and tracks the action.
 *
 * @example
 * const { currentRoutePath, redirectToPayment } = useRedirectToPayment();
 * redirectToPayment("button_click", "billing.plans.select"); // Redirects and tracks the action.
 */
export function useRedirectToPayment() {
  const route = useRoute();
  const router = useRouter();

  const currentRoutePath = computed(() => route.fullPath);

  const trackRedirectToPayment = async (trackName: string) => {
    await api.mixpanel.track('Try to add PRO plan', {
      'PRO CTA Location': trackName,
    });
  };

  /**
   * Asynchronously redirects the user to the specified billing page and tracks the action using Mixpanel.
   *
   * @param {string} trackName - The name used to identify the tracking event in Mixpanel.
   * @param product
   * @param {string} [routeName] - The name of the Vue Router route to redirect to.
   * Defaults to 'billing.plans.select'.
   * @param query
   * @param originFromParent
   * @returns {Promise<void>} A Promise that resolves when the redirection and tracking are complete.
   *
   * @example
   * await redirectToPayment("Clicked from Dashboard", "billing.plans.select");
   */
  const redirectToPayment = async (
    { trackName = '', plan = '', price = '', routeName = 'billing.plans.select', query = {} },
    originFromParent: null | string = null,
  ) => {
    const { PLAN } = BillingProductTypeEnumConst;

    await router
      .push({
        name: routeName,
        query: {
          origin: !!originFromParent ? originFromParent : currentRoutePath.value,
          plan,
          price,
          type: PLAN,
          ...query,
        },
      })
      .then(() => {
        trackRedirectToPayment(trackName);
      });
  };

  return {
    currentRoutePath,
    redirectToPayment,
    trackRedirectToPayment,
  };
}

export default useRedirectToPayment;
