import type { InjectionKey } from 'vue';
import type { ToastInterface } from 'vue-toastification';

export function useToast() {
  const toast: InjectionKey<ToastInterface> | any = inject('toast');

  const { t } = useI18n();

  /**
   * Toast error message with generic message
   * @param message
   * @param options
   */
  const toastError = (message?: string, options = {
    noTranslate: false,
  }) => toast.error(!!message
    ? (!options?.noTranslate ? message : t(message))
    : t('general.shared.error'));

  /**
   * Toast error message with generic message
   * @param message
   */
  const toastSuccess = (message?: string) => toast.success(!!message ? t(message) : t('general.shared.success'));

  /**
   * Toast error message with generic message
   * @param component
   */
  const toastCustomSuccess = (component: Component) => toast.success(component);

  return { toast, toastError, toastSuccess, toastCustomSuccess };
}
