import { Concept } from '@/types/models/Concept';

export type UptimeServiceKeywordTypeEnumKeys = 'some' | 'every';

export const UptimeServiceKeywordTypeEnumConst = {
  SOME: 'some',
  EVERY: 'every',
} as const;

export const UptimeServiceKeywordTypeEnum: Record<UptimeServiceKeywordTypeEnumKeys, Concept> = {
  'some': {
    key: 'some',
    value: null,
    name: 'concept.uptime.service.keyword.type.someName',
    shortName: null,
    description: 'concept.uptime.service.keyword.type.someDescription',
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'uptime.service.keyword.type'
  },
  'every': {
    key: 'every',
    value: null,
    name: 'concept.uptime.service.keyword.type.everyName',
    shortName: null,
    description: 'concept.uptime.service.keyword.type.everyDescription',
    class: null,
    color: null,
    icon: null,
    permission: 'check.keyword.type.every',
    type: 'uptime.service.keyword.type'
  }
} as const;

export type UptimeServiceKeywordTypeEnumType = (typeof UptimeServiceKeywordTypeEnumConst)[keyof typeof UptimeServiceKeywordTypeEnumConst];

export const UptimeServiceKeywordTypeEnumArray = Object.values(UptimeServiceKeywordTypeEnum);
