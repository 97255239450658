<script lang="ts" setup>
import type { Tag, TagPayload } from '@/types/models/organization/tag/Tag';
import type { InjectionKey } from 'vue';
import type { ToastInterface } from 'vue-toastification';

import api from '@/api';
import useValidation from '@/composables/useValidation';
import { can } from '@/helpers/permissionsHelper';
import useSiteStore from '@/stores/site/siteStore';
import useTagsStore from '@/stores/tag/tagsStore';

import { PermissionEnumConst } from '@/types/PermissionEnum';
import { useField } from 'vee-validate';

import { inject, ref } from 'vue';

import { useI18n } from 'vue-i18n';
import * as yup from 'yup';

defineOptions({
  name: 'VTagsComboboxContentEdit',
});

const props = withDefaults(
  defineProps<{
    location: '' | 'Dashboard' | 'My sites' | 'Team page' | 'Overview' | 'Settings page' | 'Contextual menu' | 'Tags shortcut' | 'Manager' | 'Alerting' | 'Manager installation'
    isSelected?: boolean
    tagId?: number
    showRemove?: boolean
    create?: boolean
    allowApply?: boolean
    disableApply?: boolean
    isLast?: boolean
  }>(),
  {
    isSelected: false,
    tagId: undefined,
    showRemove: false,
    create: false,
    isLast: false,
    allowApply: false,
    disableApply: false,
  },
);

const emit = defineEmits(['save', 'tagRemoved', 'selected', 'applyFilters']);

const { TAGS_STORE, TAGS_UPDATE, TAGS_DESTROY } = PermissionEnumConst;

const { t } = useI18n();
const toast: InjectionKey<ToastInterface> | any = inject('toast');

const tag = defineModel<Tag>('modelValue', {
  required: false,
});

const search = defineModel('search', {
  required: false,
  type: String,
});

const color = defineModel('color', {
  required: false,
  type: String,
});

const open = defineModel('open', {
  default: false,
  required: false,
  type: Boolean,
});

const tagsStore = useTagsStore();
const siteStore = useSiteStore();

const nameErrorText = ref<string>('');
const colorPickerEl = ref<HTMLInputElement | null>(null);
const colorPicker = ref<string>('');

const basicColors = ref([
  '#FF9999',
  '#FFC899',
  '#9BB2FD',
  '#CDCBCB',
  '#F3F3B2',
  '#99FFCC',
  '#99FFE9',
  '#99E3FF',
  '#C19CFC',
  '#FF99E9',
]);
const defaultColor = computed(() => (!!color.value ? color.value : ''));

const isCustomColor = computed(() => {
  return !!color.value && !basicColors.value.includes(color.value);
});

function openColorPicker() {
  colorPickerEl.value?.click();
}

const rules = yup.object({
  tagName: yup
    .string()
    .required()
    .nullable()
    .max(30, t('tags.maxCharError', { max: 30 })),
});

const form = useValidation(rules, search.value);
const { value: tagName } = useField<string | null>('tagName');

const { validate, accepted, toggleAccepted, setFieldValue, errors } = form;

async function saveTag() {
  if (can(TAGS_UPDATE) || can(TAGS_STORE)) {
    try {
      toggleAccepted();

      let tag: Tag | null = null;

      const valid = await validate();

      if (!valid.valid)
        return;

      if (!props.create && can(TAGS_UPDATE)) {
        const data: TagPayload = {
          name: tagName.value!,
          color: color.value!,
        };

        tag = await api.organization.tags.update(Number(props.tagId), data);

        if (!!tag && !!tag.id) {
          const currentTag = tagsStore.tags.find(tagAux => tagAux.id === tag!.id);

          if (!!currentTag) {
            currentTag.name = tag.name;
            currentTag.color = tag.color;
          }

          if (!!siteStore.currentSite && !!siteStore.currentSite.tags && siteStore.currentSite.tags.length > 0) {
            const currentSiteTag = siteStore.currentSite.tags.find(tagAux => tagAux.id === tag!.id);

            if (!!currentSiteTag) {
              currentSiteTag.name = tag.name;
              currentSiteTag.color = tag.color;
            }
          }
        }
      } else if (can(TAGS_STORE)) {
        const data: TagPayload = {
          name: tagName.value!,
          color: color.value!,
        };

        tag = await api.organization.tags.create(data);

        if (!!tag) {
          tagsStore.addTagToTags(tag);
        }

        await api.mixpanel.track('Create tag', {
          'Tag name': tag.name,
          'Location': props.location || '',
        });
      }

      emit('save', tag);

      open.value = false;

      if (props.create) {
        setFieldValue('tagName', null);
      }
    } catch (e: any) {
      console.error(e);

      if (!!e.response?.data?.errors.name) {
        nameErrorText.value = t('tags.nameErrorText');
      } else {
        toast.error(t('general.shared.error'));
      }
    } finally {
      toggleAccepted();
    }
  }
}

async function removeTag() {
  try {
    const tagName = toValue(tag.value)?.name;

    if (!!props.tagId) {
      await api.organization.tags.delete(props.tagId!);

      tagsStore.removeTagFromTags(props.tagId!);

      if (!!siteStore.currentSite) {
        siteStore.removeTagFromCurrentSite(props.tagId!);
      }

      emit('tagRemoved', props.tagId!);

      await api.mixpanel.track('Delete tag', {
        'Tag name': tagName,
        'Location': props.location || '',
      });

      open.value = false;
    }
  } catch (e: any) {
    console.error(e);

    toast.error(t('general.shared.error'));
  }
}

function clearColor() {
  color.value = '';
}

function cancelTagCreation() {
  open.value = !open.value;
  search.value = '';
}

function handleSetColor(value: string) {
  if (value) {
    color.value = value;
  }
}

watch(
  () => open.value,
  () => {
    if (!defaultColor.value) {
      const randomIndex = Math.floor(Math.random() * basicColors.value.length);
      color.value = basicColors.value[randomIndex];
    } else if (isCustomColor.value && !!tag.value?.color) {
      color.value = tag.value?.color;
      colorPicker.value = tag.value?.color;
    } else {
      color.value = defaultColor.value;
    }
  },
);

watch(
  () => search.value,
  () => {
    nameErrorText.value = '';

    setFieldValue('tagName', !!search.value ? search.value : null);
  },
  { immediate: true },
);
</script>

<template>
  <MCollapsible v-model:open="open" class="w-100" @update:open="clearColor">
    <div
      :class="{ 'justify-content-between': create, 'justify-content-end': !create && allowApply }"
      class="d-flex align-items-center"
    >
      <CollapsibleTrigger
        v-if="create && !open"
        :class="{ 'w-100': !allowApply }"
        as-child
        class="btn btn-transparent add-tag-item rounded-xs d-flex align-items-center mb-4 px-16 py-4"
      >
        <button>
          <span v-if="!allowApply" class="badge rounded-xs fw-medium my-2 bg-gray-300 px-8 py-4 text-xs text-gray-700">
            {{ search }}
          </span>

          <span v-if="create" v-can="TAGS_STORE" class="d-flex align-items-center ms-auto">
            <m-button
              class="fw-semi py-4"
              size="xs"
              variant="link"
            >
              <m-icon class="me-2" icon="actions-plus" />
              {{ $t('tags.tag') }}
            </m-button>
          </span>
        </button>
      </CollapsibleTrigger>

      <m-button
        v-if="allowApply && !open"
        :disabled="disableApply"
        class="align-self-end fw-medium mb-4"
        size="xs"
        @click="$emit('applyFilters')"
      >
        {{ $t('general.button.apply') }}
      </m-button>
    </div>

    <CollapsibleContent :disable-animation="create">
      <ComboboxSeparator v-show="!create" />

      <div class="d-flex flex-column px-4 pt-8">
        <v-input-text
          id="create-tag-name"
          v-model="tagName"
          :placeholder="$t('tags.tagName')"
          :yup-errors-variable="errors?.tagName || nameErrorText"
          autocomplete="off"
          class="mb-16"
          label-on-top
          variant="sm"
          @keydown.enter="saveTag"
        />

        <p class="fw-semi mb-8 text-sm text-gray-700">
          {{ $t('tags.assignColor') }}
        </p>

        <div class="d-flex align-items-center mb-8">
          <ToggleGroup
            :model-value="color"
            type="single"
            @update:model-value="handleSetColor($event as string)"
          >
            <ToggleGroupItem
              v-for="(basicColor, index) in basicColors"
              :key="index"
              :aria-label="basicColor"
              :style="{ 'background-color': basicColor }"
              :value="basicColor"
              variant="dot"
            />

            <template v-if="!!colorPicker || isCustomColor">
              <hr class="vr my-0">
              <ToggleGroupItem
                :aria-label="colorPicker"
                :style="{ 'background-color': colorPicker }"
                :value="colorPicker"
                variant="dot"
              />
            </template>
          </ToggleGroup>

          <m-button
            variant="linkDarkPrimary"
            class="color-picker-container d-flex position-relative shadow-none ms-auto p-8"
            @click="openColorPicker"
          >
            <m-icon class="color-display mx-4" icon="edit" />
            <input
              ref="colorPickerEl"
              v-model.lazy="colorPicker"
              class="position-absolute h-0 w-0 opacity-0"
              style="pointer-events: none; top: 1rem; left: 1rem"
              type="color"
              @update:model-value="color = colorPicker"
            >
          </m-button>
        </div>

        <div
          :class="showRemove || create ? 'justify-content-between' : 'justify-content-end'"
          class="d-flex mb-6 gap-8"
        >
          <m-button
            v-if="showRemove"
            :permission="TAGS_DESTROY"
            class="btn-link-danger align-self-end fw-medium"
            size="sm"
            variant="link"
            @click="removeTag"
          >
            {{ $t('general.button.removeTag') }}
          </m-button>

          <m-button
            v-else-if="create"
            class="align-self-end fw-medium"
            size="sm"
            variant="linkDanger"
            @click="cancelTagCreation"
          >
            {{ $t('general.button.cancel') }}
          </m-button>

          <m-button
            :disabled="accepted"
            class="align-self-end fw-medium border-0"
            size="sm"
            variant="linkPrimaryDark"
            @click="saveTag"
          >
            {{ $t('general.button.save') }}
          </m-button>
        </div>
      </div>

      <ComboboxSeparator v-show="!create && !isLast" />
    </CollapsibleContent>
  </MCollapsible>
</template>

<style lang="scss">
.color-display {
  cursor: pointer !important;
}

.add-tag-item {
  --md-btn-hover-bg: var(--md-gray-300);
}
</style>
