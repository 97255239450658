import { Concept } from '@/types/models/Concept';

export type SiteManagerActionEnumKeys = 'activate' | 'deactivate' | 'delete' | 'upgrade' | 'hide' | 'show';

export const SiteManagerActionEnumConst = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  DELETE: 'delete',
  UPGRADE: 'upgrade',
  HIDE: 'hide',
  SHOW: 'show',
} as const;

export const SiteManagerActionEnum: Record<SiteManagerActionEnumKeys, Concept> = {
  'activate': {
    key: 'activate',
    value: null,
    name: 'concept.site.manager.action.activateName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.manager.action',
    status: 'active'
  },
  'deactivate': {
    key: 'deactivate',
    value: null,
    name: 'concept.site.manager.action.deactivateName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.manager.action',
    status: 'active'
  },
  'delete': {
    key: 'delete',
    value: null,
    name: 'concept.site.manager.action.deleteName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.manager.action',
    status: 'active'
  },
  'upgrade': {
    key: 'upgrade',
    value: null,
    name: 'concept.site.manager.action.upgradeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.manager.action',
    status: 'active'
  },
  'hide': {
    key: 'hide',
    value: null,
    name: 'concept.site.manager.action.hideName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.manager.action',
    status: 'active'
  },
  'show': {
    key: 'show',
    value: null,
    name: 'concept.site.manager.action.showName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.manager.action',
    status: 'active'
  }
} as const;

export type SiteManagerActionEnumType = (typeof SiteManagerActionEnumConst)[keyof typeof SiteManagerActionEnumConst];

export const SiteManagerActionEnumArray = Object.values(SiteManagerActionEnum);
