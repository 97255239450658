import type { IconTypes } from '@/components/vendor/basic/icon/VIcon.vue';
import type { ChangeStatusEnumType } from '@/types/enums/ChangeStatusEnum';
import type { SiteServiceUptime } from '@/types/models/site/service/uptime/SiteServiceUptime';

import { ChangeStatusEnumConst } from '@/types/enums/ChangeStatusEnum';
import { SiteServiceStatusEnumConst } from '@/types/enums/SiteServiceStatusEnum';
import { UptimeSSLStatusEnumConst } from '@/types/enums/UptimeSSLStatusEnum';
import { DateTime } from 'luxon';
import { computed } from 'vue';

import { useI18n } from 'vue-i18n';

export interface CurrentChangeStatusType {
  icon?: IconTypes
  color?: string
  class?: string
  text?: string
}

interface UptimeSslStatus {
  icon?: IconTypes
  color?: string
  class?: string
  validCertificateDate: string
  isExpired: boolean
}

function mapUptimeSslStatus(isUptimeEnabled: boolean, uptimeService?: SiteServiceUptime | null): UptimeSslStatus {
  let currentChange: CurrentChangeStatusType = {};
  let validCertificateDate = '';
  let isExpired = false;

  const { currentChangeSsl, status } = uptimeService || {};

  if (!!currentChangeSsl && !!status && isUptimeEnabled) {
    switch (status) {
      case SiteServiceStatusEnumConst.ACTIVE: {
        const tmpCertificateDate = DateTime.fromISO(currentChangeSsl?.certificate?.valid?.to).toUTC();
        const currentDate = DateTime.now().toUTC();

        const { status: sslStatus } = currentChangeSsl;

        if (tmpCertificateDate >= currentDate) {
          validCertificateDate = tmpCertificateDate.diff(currentDate, 'days').days.toFixed(0);
        } else {
          const lastSslCheck = DateTime.fromISO(currentChangeSsl.startedAt).toUTC();

          isExpired = true;
          validCertificateDate = currentDate.diff(lastSslCheck, 'days').days.toFixed(0);
        }

        if (sslStatus === ChangeStatusEnumConst.UP) {
          currentChange = {
            icon: 'arrow-up',
            color: 'text-success',
            class: 'text-xs',
          };
        } else if (sslStatus === ChangeStatusEnumConst.DOWN) {
          currentChange = {
            icon: 'arrow-down',
            color: 'text-danger',
            class: 'text-xs',
          };
        } else {
          currentChange = {};
        }

        break;
      }

      default:
        currentChange = {
          icon: 'log-in-circle',
          color: 'text-danger',
          class: 'text-xs',
        };
        break;
    }
  }

  return {
    ...currentChange,
    validCertificateDate,
    isExpired,
  };
}

export function useUptimeFunctions(uptimeService?: SiteServiceUptime | null) {
  const { t } = useI18n();

  const isUptimeEnabled = computed(
    () => !!uptimeService && !!uptimeService?.status && uptimeService.status === SiteServiceStatusEnumConst.ACTIVE,
  );

  const isUptimeSslEnabled = computed(
    () => !!uptimeService && !!uptimeService?.ssl?.status && uptimeService.ssl.status === UptimeSSLStatusEnumConst.ACTIVE,
  );

  const uptimeSslChangeStatus = computed(() => ({
    ...mapUptimeSslStatus(isUptimeEnabled.value, uptimeService),
  }));

  const mapGivenUptimeChangeStatus = (
    status?: ChangeStatusEnumType,
    returnShortStrings = false,
  ): CurrentChangeStatusType => {
    let currentChange: CurrentChangeStatusType = {};

    switch (status) {
      case ChangeStatusEnumConst.UP:
        currentChange = {
          icon: 'arrow-up',
          color: 'text-success',
          text: returnShortStrings ? 'report.uptime.activity.up' : 'report.uptime.activity.upFor',
        };
        break;
      case ChangeStatusEnumConst.DOWN:
        currentChange = {
          icon: 'arrow-down',
          color: 'text-danger',
          text: returnShortStrings ? 'report.uptime.activity.down' : 'report.uptime.activity.downFor',
        };
        break;
      case ChangeStatusEnumConst.UNKNOWN:
        currentChange = {
          icon: 'pause',
          color: 'text-warning',
          text: returnShortStrings ? 'report.uptime.activity.paused' : 'report.uptime.activity.pausedFor',
        };
        break;
      default:
        currentChange = {
          icon: 'question',
          color: 'text-gray-600',
          text: returnShortStrings ? 'report.uptime.activity.unknown' : 'report.uptime.activity.unknownFor',
        };
        break;
    }

    return currentChange;
  };

  const uptimeChangeStatus = computed(() => {
    let currentChange: CurrentChangeStatusType = {};

    if (!!uptimeService && !!uptimeService?.currentChange?.status) {
      currentChange = mapGivenUptimeChangeStatus(uptimeService.currentChange.status);
    } else {
      currentChange = {
        icon: 'question',
        color: 'text-gray-600',
        text: 'report.uptime.activity.unknownFor',
      };
    }

    return currentChange;
  });

  const parseDuration = (startDate: string, endDate: string): string => {
    const start = DateTime.fromISO(startDate);
    let duration: any = null;
    let formatedTime = '';
    let end: any = '';

    if (!!endDate) {
      end = DateTime.fromISO(endDate);
    } else {
      end = DateTime.now();
    }

    duration = end.diff(start, ['day', 'hour', 'minute', 'second', 'millisecond']);

    const { days, hours, minutes, seconds }: any = duration;

    if (days > 0) {
      formatedTime = `${t('site.uptime.shared.shortDays', { days }, days)} ${t('site.uptime.shared.shortHours', { hours }, hours)} ${t('site.uptime.shared.shortMinutes', { minutes }, minutes)}`;
    } else if (hours > 0) {
      formatedTime = `${t('site.uptime.shared.shortHours', { hours }, hours)}
              ${t('site.uptime.shared.shortMinutes', { minutes }, minutes)}`;
    } else if (minutes > 0) {
      formatedTime = `${t('site.uptime.shared.shortMinutes', { minutes }, minutes)}`;
    } else {
      formatedTime = `${t('site.uptime.shared.shortSeconds', { seconds }, seconds)}`;
    }

    return formatedTime;
  };

  return {
    uptimeChangeStatus,
    uptimeSslChangeStatus,
    isUptimeEnabled,
    isUptimeSslEnabled,
    mapGivenUptimeChangeStatus,
    parseDuration,
  };
}

export default useUptimeFunctions;

// Exported for tests
export { mapUptimeSslStatus };
