<script lang="ts" setup>
import { TooltipTrigger, type TooltipTriggerProps } from 'radix-vue';

const props = defineProps<TooltipTriggerProps>();
</script>

<template>
  <TooltipTrigger class="ui-tooltip-trigger position-relative" v-bind="props">
    <slot />
  </TooltipTrigger>
</template>
