export const apiEndpoints = {
  login: {
    methods: [
      'POST'
    ],
    name: 'login',
    path: 'api/login',
    method: 'POST'
  },
  logout: {
    methods: [
      'POST'
    ],
    name: 'logout',
    path: 'api/logout',
    method: 'POST'
  },
  passwordEmail: {
    methods: [
      'POST'
    ],
    name: 'password.email',
    path: 'api/forgot-password',
    method: 'POST'
  },
  passwordUpdate: {
    methods: [
      'POST'
    ],
    name: 'password.update',
    path: 'api/reset-password',
    method: 'POST'
  },
  register: {
    methods: [
      'POST'
    ],
    name: 'register',
    path: 'api/register',
    method: 'POST'
  },
  verificationVerify: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'verification.verify',
    path: 'api/email/verify/{id}/{hash}',
    method: 'GET',
    parameters: [
      'id',
      'hash'
    ]
  },
  verificationSend: {
    methods: [
      'POST'
    ],
    name: 'verification.send',
    path: 'api/email/verification-notification',
    method: 'POST'
  },
  userProfileInformationUpdate: {
    methods: [
      'PUT'
    ],
    name: 'user-profile-information.update',
    path: 'api/user/profile-information',
    method: 'PUT'
  },
  userPasswordUpdate: {
    methods: [
      'PUT'
    ],
    name: 'user-password.update',
    path: 'api/user/password',
    method: 'PUT'
  },
  passwordConfirmation: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'password.confirmation',
    path: 'api/user/confirmed-password-status',
    method: 'GET'
  },
  passwordConfirm: {
    methods: [
      'POST'
    ],
    name: 'password.confirm',
    path: 'api/user/confirm-password',
    method: 'POST'
  },
  twoFactorChallenge: {
    methods: [
      'POST'
    ],
    name: 'two-factor.challenge',
    path: 'api/two-factor-challenge',
    method: 'POST'
  },
  twoFactorEnable: {
    methods: [
      'POST'
    ],
    name: 'two-factor.enable',
    path: 'api/user/two-factor-authentication',
    method: 'POST'
  },
  twoFactorConfirm: {
    methods: [
      'POST'
    ],
    name: 'two-factor.confirm',
    path: 'api/user/confirmed-two-factor-authentication',
    method: 'POST'
  },
  twoFactorDisable: {
    methods: [
      'DELETE'
    ],
    name: 'two-factor.disable',
    path: 'api/user/two-factor-authentication',
    method: 'DELETE'
  },
  twoFactorQrCode: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'two-factor.qr-code',
    path: 'api/user/two-factor-qr-code',
    method: 'GET'
  },
  twoFactorSecretKey: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'two-factor.secret-key',
    path: 'api/user/two-factor-secret-key',
    method: 'GET'
  },
  twoFactorRecoveryCodes: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'two-factor.recovery-codes',
    path: 'api/user/two-factor-recovery-codes',
    method: 'GET'
  },
  twoFactorRecoveryCodesStore: {
    methods: [
      'POST'
    ],
    name: 'two-factor.recovery-codes.store',
    path: 'api/user/two-factor-recovery-codes',
    method: 'POST'
  },
  sanctumCsrfCookie: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sanctum.csrf-cookie',
    path: 'sanctum/csrf-cookie',
    method: 'GET'
  },
  appsumoWebhooks: {
    methods: [
      'POST'
    ],
    name: 'appsumo.webhooks',
    path: 'api/appsumo/webhooks',
    method: 'POST'
  },
  publicSiteReportView: {
    methods: [
      'GET',
      'HEAD'
    ],
    wheres: {
      format: 'json|pdf'
    },
    name: 'public.site.report.view',
    path: 'api/report/{slug}/{format}',
    method: 'GET',
    parameters: [
      'slug',
      'format'
    ]
  },
  authCheck: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'auth.check',
    path: 'api/auth/check',
    method: 'GET'
  },
  trackerStoreGuest: {
    methods: [
      'POST'
    ],
    name: 'tracker.store.guest',
    path: 'api/mp/track-guest',
    method: 'POST'
  },
  trackerStore: {
    methods: [
      'POST'
    ],
    name: 'tracker.store',
    path: 'api/mp/track',
    method: 'POST'
  },
  authSocialRedirect: {
    methods: [
      'GET',
      'HEAD'
    ],
    wheres: {
      provider: 'google|appsumo'
    },
    name: 'auth.social.redirect',
    path: 'api/auth/social/{provider}/redirect',
    method: 'GET',
    parameters: [
      'provider'
    ]
  },
  authSocialConfirm: {
    methods: [
      'GET',
      'HEAD'
    ],
    wheres: {
      provider: 'google|appsumo'
    },
    name: 'auth.social.confirm',
    path: 'api/auth/social/{provider}/confirm',
    method: 'GET',
    parameters: [
      'provider'
    ]
  },
  meShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'me.show',
    path: 'api/me',
    method: 'GET'
  },
  organizationsShowCurrent: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'organizations.show.current',
    path: 'api/me/organization',
    method: 'GET'
  },
  meAuthSocialIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'me.auth.social.index',
    path: 'api/me/auth/social',
    method: 'GET'
  },
  authSocialDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'auth.social.destroy',
    path: 'api/me/auth/social/{provider}',
    method: 'DELETE',
    parameters: [
      'provider'
    ]
  },
  mePermissionsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'me.permissions.index',
    path: 'api/me/permissions',
    method: 'GET'
  },
  meSettingUpdate: {
    methods: [
      'PATCH'
    ],
    name: 'me.setting.update',
    path: 'api/me/settings',
    method: 'PATCH'
  },
  browserSessionsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'browser-sessions.index',
    path: 'api/me/browser-sessions',
    method: 'GET'
  },
  browserSessionsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'browser-sessions.destroy',
    path: 'api/me/other-browser-sessions',
    method: 'DELETE'
  },
  devicesLogout: {
    methods: [
      'POST'
    ],
    name: 'devices.logout',
    path: 'api/logout-devices/{token}',
    method: 'POST',
    parameters: [
      'token'
    ]
  },
  notificationsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'notifications.index',
    path: 'api/notifications',
    method: 'GET'
  },
  notificationsCount: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'notifications.count',
    path: 'api/notifications/count',
    method: 'GET'
  },
  notificationsRead: {
    methods: [
      'PATCH'
    ],
    name: 'notifications.read',
    path: 'api/notifications/{notification}/read',
    method: 'PATCH',
    parameters: [
      'notification'
    ]
  },
  notificationsReadAll: {
    methods: [
      'PATCH'
    ],
    name: 'notifications.read-all',
    path: 'api/notifications/read',
    method: 'PATCH'
  },
  vulnerabilitiesIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'vulnerabilities.index',
    path: 'api/vulnerabilities',
    method: 'GET'
  },
  mediaLibraryUploads: {
    methods: [
      'POST'
    ],
    name: 'media-library-uploads',
    path: 'api/files/temporary/uploads',
    method: 'POST'
  },
  organizationsSearch: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'organizations.search',
    path: 'api/search',
    method: 'GET'
  },
  organizationsStatus: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'organizations.status',
    path: 'api/manager/status',
    method: 'GET'
  },
  teamsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'teams.index',
    path: 'api/teams',
    method: 'GET'
  },
  teamsStore: {
    methods: [
      'POST'
    ],
    name: 'teams.store',
    path: 'api/teams',
    method: 'POST'
  },
  teamsUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'teams.update',
    path: 'api/teams/{team}',
    method: 'PUT',
    parameters: [
      'team'
    ],
    bindings: {
      team: 'id'
    }
  },
  teamsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'teams.destroy',
    path: 'api/teams/{team}',
    method: 'DELETE',
    parameters: [
      'team'
    ],
    bindings: {
      team: 'id'
    }
  },
  teamsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'teams.show',
    path: 'api/teams/{team}',
    method: 'GET',
    parameters: [
      'team'
    ],
    bindings: {
      team: 'slug'
    }
  },
  sitesIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.index',
    path: 'api/sites',
    method: 'GET'
  },
  sitesUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'sites.update',
    path: 'api/sites/{site}',
    method: 'PUT',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'sites.destroy',
    path: 'api/sites/{site}',
    method: 'DELETE',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesStore: {
    methods: [
      'POST'
    ],
    name: 'sites.store',
    path: 'api/sites',
    method: 'POST'
  },
  sitesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.show',
    path: 'api/sites/{site}',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'slug'
    }
  },
  sitesServicesIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.services.index',
    path: 'api/sites/{site}/services',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'slug'
    }
  },
  siteNotesUpdate: {
    methods: [
      'PATCH'
    ],
    name: 'site-notes.update',
    path: 'api/sites/{site}/notes',
    method: 'PATCH',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  siteTagsToggle: {
    methods: [
      'PATCH'
    ],
    name: 'site-tags.toggle',
    path: 'api/sites/{site}/tags',
    method: 'PATCH',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesSelectionCount: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.selection.count',
    path: 'api/sites/selection/count',
    method: 'GET'
  },
  sitesOauthClientShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.oauth.client.show',
    path: 'api/sites/{site}/oauth/client',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesOauthClientConfirm: {
    methods: [
      'POST'
    ],
    name: 'sites.oauth.client.confirm',
    path: 'api/sites/{site}/oauth/confirm',
    method: 'POST',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesConnect: {
    methods: [
      'POST'
    ],
    name: 'sites.connect',
    path: 'api/sites/{site}/connect',
    method: 'POST',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  screenshotServicesRenew: {
    methods: [
      'PATCH'
    ],
    name: 'screenshot-services.renew',
    path: 'api/sites/{site}/renew',
    method: 'PATCH',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  performanceServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'performance-services.show',
    path: 'api/sites/{site}/performance',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  vulnerabilityServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'vulnerability-services.show',
    path: 'api/sites/{site}/vulnerabilities',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesManagerLogin: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.manager.login',
    path: 'api/sites/{site}/manager/login',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesDatabaseTreeShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.database.tree.show',
    path: 'api/sites/{site}/manager/tree/database',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  sitesDirectoryTreeShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'sites.directory.tree.show',
    path: 'api/sites/{site}/manager/tree/directory',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  servicesAssignSocial: {
    methods: [
      'PUT'
    ],
    wheres: {
      type: 'analytics|search_console'
    },
    name: 'services.assign.social',
    path: 'api/site/{site}/services/{type}/connect',
    method: 'PUT',
    parameters: [
      'site',
      'type'
    ],
    bindings: {
      site: 'id'
    }
  },
  siteBackupsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-backups.index',
    path: 'api/site-backups',
    method: 'GET'
  },
  siteBackupsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-backups.show',
    path: 'api/site-backups/{site_backup}',
    method: 'GET',
    parameters: [
      'site_backup'
    ]
  },
  siteBackupsStore: {
    methods: [
      'POST'
    ],
    name: 'site-backups.store',
    path: 'api/site-backups',
    method: 'POST'
  },
  siteBackupsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'site-backups.destroy',
    path: 'api/site-backups',
    method: 'DELETE'
  },
  siteBackupsRetry: {
    methods: [
      'PATCH'
    ],
    name: 'site-backups.retry',
    path: 'api/backups/{site_backup}/retry',
    method: 'PATCH',
    parameters: [
      'site_backup'
    ]
  },
  siteBackupsDownload: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-backups.download',
    path: 'api/backups/{site_backup}/download',
    method: 'GET',
    parameters: [
      'site_backup'
    ]
  },
  siteBackupsFavoriteToggle: {
    methods: [
      'PATCH'
    ],
    name: 'site-backups.favorite.toggle',
    path: 'api/backups/{site_backup}/favorite',
    method: 'PATCH',
    parameters: [
      'site_backup'
    ]
  },
  siteBackupsItemDownload: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-backups-item.download',
    path: 'api/backup-items/{item}/download',
    method: 'GET',
    parameters: [
      'item'
    ],
    bindings: {
      item: 'id'
    }
  },
  siteReportsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-reports.index',
    path: 'api/site-reports',
    method: 'GET'
  },
  siteReportsStore: {
    methods: [
      'POST'
    ],
    name: 'site-reports.store',
    path: 'api/site-reports',
    method: 'POST'
  },
  siteReportsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-reports.show',
    path: 'api/site-reports/{site_report}',
    method: 'GET',
    parameters: [
      'site_report'
    ]
  },
  siteReportsUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'site-reports.update',
    path: 'api/site-reports/{site_report}',
    method: 'PUT',
    parameters: [
      'site_report'
    ]
  },
  siteReportsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'site-reports.destroy',
    path: 'api/site-reports',
    method: 'DELETE'
  },
  siteReportsReplicateService: {
    methods: [
      'POST'
    ],
    name: 'site-reports.replicate.service',
    path: 'api/site-reports/{site_report}/replicate/service',
    method: 'POST',
    parameters: [
      'site_report'
    ]
  },
  siteReportsGenerate: {
    methods: [
      'PATCH'
    ],
    name: 'site-reports.generate',
    path: 'api/site-reports/{site_report}/generate',
    method: 'PATCH',
    parameters: [
      'site_report'
    ]
  },
  siteReportsAnnotationsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-reports.annotations.index',
    path: 'api/site-reports/{site_report}/annotations',
    method: 'GET',
    parameters: [
      'site_report'
    ]
  },
  siteReportsAnnotationsStore: {
    methods: [
      'POST'
    ],
    name: 'site-reports.annotations.store',
    path: 'api/site-reports/{site_report}/annotations',
    method: 'POST',
    parameters: [
      'site_report'
    ]
  },
  siteReportsAnnotationsUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'site-reports.annotations.update',
    path: 'api/site-reports/{site_report}/annotations/{annotation}',
    method: 'PUT',
    parameters: [
      'site_report',
      'annotation'
    ]
  },
  siteReportsAnnotationsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'site-reports.annotations.destroy',
    path: 'api/site-reports/{site_report}/annotations/{annotation}',
    method: 'DELETE',
    parameters: [
      'site_report',
      'annotation'
    ]
  },
  siteReportsItemsUpdate: {
    methods: [
      'PUT'
    ],
    wheres: {
      type: 'welcome_message|overview|uptime|analytics|performance|upgrade|backup|task|search_console|closing_message'
    },
    name: 'site-reports.items.update',
    path: 'api/reports/{report}/items/{type}',
    method: 'PUT',
    parameters: [
      'report',
      'type'
    ],
    bindings: {
      report: 'id'
    }
  },
  siteReportsItemsStatusToggle: {
    methods: [
      'PATCH'
    ],
    wheres: {
      type: 'welcome_message|overview|uptime|analytics|performance|upgrade|backup|task|search_console|closing_message'
    },
    name: 'site-reports.items.status.toggle',
    path: 'api/reports/{report}/items/{type}/status',
    method: 'PATCH',
    parameters: [
      'report',
      'type'
    ],
    bindings: {
      report: 'id'
    }
  },
  tasksIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'tasks.index',
    path: 'api/tasks',
    method: 'GET'
  },
  tasksStore: {
    methods: [
      'POST'
    ],
    name: 'tasks.store',
    path: 'api/tasks',
    method: 'POST'
  },
  tasksShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'tasks.show',
    path: 'api/tasks/{task}',
    method: 'GET',
    parameters: [
      'task'
    ],
    bindings: {
      task: 'id'
    }
  },
  tasksUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'tasks.update',
    path: 'api/tasks/{task}',
    method: 'PUT',
    parameters: [
      'task'
    ],
    bindings: {
      task: 'id'
    }
  },
  tasksDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'tasks.destroy',
    path: 'api/tasks',
    method: 'DELETE'
  },
  servicesStatusToggle: {
    methods: [
      'PATCH'
    ],
    wheres: {
      type: 'uptime|backup|report'
    },
    name: 'services.status.toggle',
    path: 'api/sites/{site}/services/{type}/status',
    method: 'PATCH',
    parameters: [
      'site',
      'type'
    ],
    bindings: {
      site: 'id'
    }
  },
  uptimeServicesStore: {
    methods: [
      'POST'
    ],
    name: 'uptime-services.store',
    path: 'api/uptime-services',
    method: 'POST'
  },
  uptimeServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'uptime-services.show',
    path: 'api/uptime-services/{uptime_service}',
    method: 'GET',
    parameters: [
      'uptime_service'
    ]
  },
  uptimeServicesUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'uptime-services.update',
    path: 'api/uptime-services/{uptime_service}',
    method: 'PUT',
    parameters: [
      'uptime_service'
    ]
  },
  uptimeServicesStatsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'uptime-services.stats.show',
    path: 'api/uptime-services/{uptime_service}/stats',
    method: 'GET',
    parameters: [
      'uptime_service'
    ]
  },
  backupServicesStore: {
    methods: [
      'POST'
    ],
    name: 'backup-services.store',
    path: 'api/backup-services',
    method: 'POST'
  },
  backupServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'backup-services.show',
    path: 'api/backup-services/{backup_service}',
    method: 'GET',
    parameters: [
      'backup_service'
    ]
  },
  backupServicesUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'backup-services.update',
    path: 'api/backup-services/{backup_service}',
    method: 'PUT',
    parameters: [
      'backup_service'
    ]
  },
  analyticsServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'analytics-services.show',
    path: 'api/analytics-services/{analytics_service}',
    method: 'GET',
    parameters: [
      'analytics_service'
    ]
  },
  analyticsServicesUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'analytics-services.update',
    path: 'api/analytics-services/{analytics_service}',
    method: 'PUT',
    parameters: [
      'analytics_service'
    ]
  },
  analyticsServicesDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'analytics-services.destroy',
    path: 'api/analytics-services/{analytics_service}',
    method: 'DELETE',
    parameters: [
      'analytics_service'
    ]
  },
  analyticsServicesReport: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'analytics-services.report',
    path: 'api/analytics-services/{analytics_service}/report',
    method: 'GET',
    parameters: [
      'analytics_service'
    ]
  },
  analyticsServicesAccountsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'analytics-services.accounts.index',
    path: 'api/analytics-services/{analytics_service}/accounts',
    method: 'GET',
    parameters: [
      'analytics_service'
    ]
  },
  analyticsServicesPropertiesIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'analytics-services.properties.index',
    path: 'api/analytics-services/{analytics_service}/accounts/{account}/properties',
    method: 'GET',
    parameters: [
      'analytics_service',
      'account'
    ]
  },
  searchConsoleServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'search-console-services.show',
    path: 'api/search-console-services/{search_console_service}',
    method: 'GET',
    parameters: [
      'search_console_service'
    ]
  },
  searchConsoleServicesUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'search-console-services.update',
    path: 'api/search-console-services/{search_console_service}',
    method: 'PUT',
    parameters: [
      'search_console_service'
    ]
  },
  searchConsoleServicesDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'search-console-services.destroy',
    path: 'api/search-console-services/{search_console_service}',
    method: 'DELETE',
    parameters: [
      'search_console_service'
    ]
  },
  searchConsoleServicesReport: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'search-console-services.report',
    path: 'api/search-console-services/{search_console_service}/report',
    method: 'GET',
    parameters: [
      'search_console_service'
    ]
  },
  searchConsoleServicesAccounts: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'search-console-services.accounts',
    path: 'api/search-console-services/{search_console_service}/accounts',
    method: 'GET',
    parameters: [
      'search_console_service'
    ]
  },
  healthServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'health-services.show',
    path: 'api/health-services/{health_service}',
    method: 'GET',
    parameters: [
      'health_service'
    ]
  },
  healthServicesRefresh: {
    methods: [
      'POST'
    ],
    name: 'health-services.refresh',
    path: 'api/health-services/{health_service}/refresh',
    method: 'POST',
    parameters: [
      'health_service'
    ]
  },
  healthServicesInformation: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'health-services.information',
    path: 'api/site/{site}/server',
    method: 'GET',
    parameters: [
      'site'
    ],
    bindings: {
      site: 'id'
    }
  },
  reportServicesCalculateNextRequests: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'report-services.calculate-next-requests',
    path: 'api/report-services/next-requests',
    method: 'GET'
  },
  reportServicesIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'report-services.index',
    path: 'api/report-services',
    method: 'GET'
  },
  reportServicesStore: {
    methods: [
      'POST'
    ],
    name: 'report-services.store',
    path: 'api/report-services',
    method: 'POST'
  },
  reportServicesShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'report-services.show',
    path: 'api/report-services/{report_service}',
    method: 'GET',
    parameters: [
      'report_service'
    ]
  },
  reportServicesUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'report-services.update',
    path: 'api/report-services/{report_service}',
    method: 'PUT',
    parameters: [
      'report_service'
    ]
  },
  reportServicesDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'report-services.destroy',
    path: 'api/report-services',
    method: 'DELETE'
  },
  reportServicesUpdateEmail: {
    methods: [
      'PUT'
    ],
    name: 'report-services.update.email',
    path: 'api/report-services/{report_service}/email',
    method: 'PUT',
    parameters: [
      'report_service'
    ]
  },
  reportServicesStatusToggle: {
    methods: [
      'PATCH'
    ],
    name: 'report-services.status.toggle',
    path: 'api/report-services/{report_service}/status',
    method: 'PATCH',
    parameters: [
      'report_service'
    ]
  },
  reportServicesItemUpdate: {
    methods: [
      'PUT'
    ],
    wheres: {
      type: 'welcome_message|overview|uptime|analytics|performance|upgrade|backup|task|search_console|closing_message'
    },
    name: 'report-services.item.update',
    path: 'api/report-services/{report_service}/items/{type}',
    method: 'PUT',
    parameters: [
      'report_service',
      'type'
    ]
  },
  reportServicesItemsStatusToggle: {
    methods: [
      'PATCH'
    ],
    wheres: {
      type: 'welcome_message|overview|uptime|analytics|performance|upgrade|backup|task|search_console|closing_message'
    },
    name: 'report-services.items.status.toggle',
    path: 'api/report-services/{report_service}/items/{type}/status',
    method: 'PATCH',
    parameters: [
      'report_service',
      'type'
    ]
  },
  wordpressPluginIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    wheres: {
      type: 'plugins|themes'
    },
    name: 'wordpress.plugin.index',
    path: 'api/manager/wordpress/{type}/list',
    method: 'GET',
    parameters: [
      'type'
    ]
  },
  aiPluginIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'ai.plugin.index',
    path: 'api/manager/ai/plugins/list',
    method: 'GET'
  },
  managerItemsSelectionStatus: {
    methods: [
      'POST'
    ],
    name: 'manager.items.selection.status',
    path: 'api/manager/items/selection-status',
    method: 'POST'
  },
  managerItemsManage: {
    methods: [
      'PATCH'
    ],
    name: 'manager.items.manage',
    path: 'api/manager/items/manage',
    method: 'PATCH'
  },
  componentsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'components.index',
    path: 'api/components',
    method: 'GET'
  },
  componentsCheckSelection: {
    methods: [
      'PATCH'
    ],
    name: 'components.check-selection',
    path: 'api/components/check-selection',
    method: 'PATCH'
  },
  siteItemsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-items.index',
    path: 'api/site-items',
    method: 'GET'
  },
  siteItemsUpdateReferences: {
    methods: [
      'POST'
    ],
    name: 'site-items.update-references',
    path: 'api/site-items',
    method: 'POST'
  },
  siteItemsMetas: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'site-items.metas',
    path: 'api/site-items/metas',
    method: 'GET'
  },
  siteItemsInstall: {
    methods: [
      'POST'
    ],
    name: 'site-items.install',
    path: 'api/site-items/install',
    method: 'POST'
  },
  siteItemsUpload: {
    methods: [
      'POST'
    ],
    name: 'site-items.upload',
    path: 'api/site-items/uploads',
    method: 'POST'
  },
  whiteLabelShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'white-label.show',
    path: 'api/manager/white-label',
    method: 'GET'
  },
  whiteLabelUpdate: {
    methods: [
      'PUT'
    ],
    name: 'white-label.update',
    path: 'api/manager/white-label',
    method: 'PUT'
  },
  domainsDefault: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'domains.default',
    path: 'api/domains/default',
    method: 'GET'
  },
  domainsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'domains.index',
    path: 'api/domains',
    method: 'GET'
  },
  domainsStore: {
    methods: [
      'POST'
    ],
    name: 'domains.store',
    path: 'api/domains',
    method: 'POST'
  },
  domainsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'domains.show',
    path: 'api/domains/{domain}',
    method: 'GET',
    parameters: [
      'domain'
    ],
    bindings: {
      domain: 'id'
    }
  },
  domainsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'domains.destroy',
    path: 'api/domains/{domain}',
    method: 'DELETE',
    parameters: [
      'domain'
    ],
    bindings: {
      domain: 'id'
    }
  },
  domainsToggleDefault: {
    methods: [
      'PUT'
    ],
    name: 'domains.toggle-default',
    path: 'api/domains/{domain}',
    method: 'PUT',
    parameters: [
      'domain'
    ],
    bindings: {
      domain: 'id'
    }
  },
  domainsVerify: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'domains.verify',
    path: 'api/domains/{domain}/verify',
    method: 'GET',
    parameters: [
      'domain'
    ],
    bindings: {
      domain: 'id'
    }
  },
  billingProductsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'billing-products.index',
    path: 'api/billing/products',
    method: 'GET'
  },
  billingAccountsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'billing-accounts.show',
    path: 'api/billing-accounts',
    method: 'GET'
  },
  billingAccountsStore: {
    methods: [
      'POST'
    ],
    name: 'billing-accounts.store',
    path: 'api/billing-accounts',
    method: 'POST'
  },
  paymentMethodsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'payment-methods.show',
    path: 'api/payment-methods',
    method: 'GET'
  },
  checkoutsPriceTotalCalculate: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'checkouts.price-total.calculate',
    path: 'api/checkouts/calculate-price',
    method: 'GET'
  },
  checkoutsStore: {
    methods: [
      'POST'
    ],
    name: 'checkouts.store',
    path: 'api/checkouts',
    method: 'POST'
  },
  checkoutsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'checkouts.show',
    path: 'api/checkouts/{checkout}',
    method: 'GET',
    parameters: [
      'checkout'
    ],
    bindings: {
      checkout: 'id'
    }
  },
  invoicesIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'invoices.index',
    path: 'api/invoices',
    method: 'GET'
  },
  invoicesDownload: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'invoices.download',
    path: 'api/invoices/{invoice}/download',
    method: 'GET',
    parameters: [
      'invoice'
    ],
    bindings: {
      invoice: 'id'
    }
  },
  licensesIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'licenses.index',
    path: 'api/licenses',
    method: 'GET'
  },
  subscriptionsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'subscriptions.index',
    path: 'api/subscriptions',
    method: 'GET'
  },
  subscriptionsManagePaymentMethod: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'subscriptions.manage.payment-method',
    path: 'api/subscriptions/manage/payment-method',
    method: 'GET'
  },
  subscriptionsManageUpdate: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'subscriptions.manage.update',
    path: 'api/subscriptions/manage/update',
    method: 'GET'
  },
  subscriptionsManageResume: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'subscriptions.manage.resume',
    path: 'api/subscriptions/manage/resume',
    method: 'GET'
  },
  subscriptionsManageCancel: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'subscriptions.manage.cancel',
    path: 'api/subscriptions/manage/cancel',
    method: 'GET'
  },
  contactsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'contacts.index',
    path: 'api/contacts',
    method: 'GET'
  },
  contactsStore: {
    methods: [
      'POST'
    ],
    name: 'contacts.store',
    path: 'api/contacts',
    method: 'POST'
  },
  organizationSocialIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'organization.social.index',
    path: 'api/social/networks',
    method: 'GET'
  },
  organizationSocialConnectConfirm: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'organization.social.connect.confirm',
    path: 'api/social/connect/confirm',
    method: 'GET'
  },
  organizationSocialConnectRedirect: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'organization.social.connect.redirect',
    path: 'api/social/connect/{provider}',
    method: 'GET',
    parameters: [
      'provider'
    ]
  },
  organizationSocialDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'organization.social.destroy',
    path: 'api/social/network/{socialNetwork}',
    method: 'DELETE',
    parameters: [
      'socialNetwork'
    ],
    bindings: {
      socialNetwork: 'id'
    }
  },
  alertsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'alerts.index',
    path: 'api/alerts',
    method: 'GET'
  },
  alertsStore: {
    methods: [
      'POST'
    ],
    name: 'alerts.store',
    path: 'api/alerts',
    method: 'POST'
  },
  alertsShow: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'alerts.show',
    path: 'api/alerts/{alert}',
    method: 'GET',
    parameters: [
      'alert'
    ],
    bindings: {
      alert: 'id'
    }
  },
  alertsUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'alerts.update',
    path: 'api/alerts/{alert}',
    method: 'PUT',
    parameters: [
      'alert'
    ],
    bindings: {
      alert: 'id'
    }
  },
  alertsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'alerts.destroy',
    path: 'api/alerts/{alert}',
    method: 'DELETE',
    parameters: [
      'alert'
    ],
    bindings: {
      alert: 'id'
    }
  },
  alertsContactsSync: {
    methods: [
      'PATCH'
    ],
    name: 'alerts.contacts.sync',
    path: 'api/alerts/{alert}/contacts',
    method: 'PATCH',
    parameters: [
      'alert'
    ],
    bindings: {
      alert: 'id'
    }
  },
  alertsSitesSync: {
    methods: [
      'PATCH'
    ],
    name: 'alerts.sites.sync',
    path: 'api/alerts/{alert}/sites',
    method: 'PATCH',
    parameters: [
      'alert'
    ],
    bindings: {
      alert: 'id'
    }
  },
  tagsIndex: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'tags.index',
    path: 'api/tags',
    method: 'GET'
  },
  tagsStore: {
    methods: [
      'POST'
    ],
    name: 'tags.store',
    path: 'api/tags',
    method: 'POST'
  },
  tagsUpdate: {
    methods: [
      'PUT',
      'PATCH'
    ],
    name: 'tags.update',
    path: 'api/tags/{tag}',
    method: 'PUT',
    parameters: [
      'tag'
    ],
    bindings: {
      tag: 'id'
    }
  },
  tagsDestroy: {
    methods: [
      'DELETE'
    ],
    name: 'tags.destroy',
    path: 'api/tags/{tag}',
    method: 'DELETE',
    parameters: [
      'tag'
    ],
    bindings: {
      tag: 'id'
    }
  },
  authSocialRedirectCallback: {
    methods: [
      'GET',
      'HEAD'
    ],
    name: 'auth.social.redirect.callback',
    path: 'auth/social/{provider}/callback',
    method: 'GET',
    parameters: [
      'provider'
    ]
  }
} as const;
