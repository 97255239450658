<script setup lang="ts">
import { DropdownMenuItem, type DropdownMenuItemProps, useForwardProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = withDefaults(
  defineProps<DropdownMenuItemProps & {
    class?: HTMLAttributes['class']
    inset?: boolean
    variant?: '' | 'danger'
    align?: 'center' | 'start' | 'end'
  }>(),
  {
    class: '',
    inset: false,
    variant: '',
    align: 'center',
  },
);

const delegatedProps = computed(() => {
  const { class: _, variant, align, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <DropdownMenuItem
    v-bind="forwardedProps"
    class="ui-menu-item position-relative d-flex rounded-xs fw-medium px-16 text-sm"
    :class="[
      {
        'ps-36': inset,
        'ui-menu-item--danger text-danger-dark': props.variant === 'danger',
        'align-items-center': align === 'center',
        'align-items-start': align === 'start',
        'align-items-end': align === 'end',
      },
      props.class,
    ]"
  >
    <slot />
  </DropdownMenuItem>
</template>
