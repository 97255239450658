<script setup lang="ts">
import { ComboboxCancel, type ComboboxCancelProps } from 'radix-vue';

const props = defineProps<ComboboxCancelProps>();
</script>

<template>
  <ComboboxCancel v-bind="props" class="ui-combobox-cancel btn btn-transparent p-4 position-absolute top-50 end-0 translate-middle-y text-gray-600 pe-32">
    <slot>
      <m-icon icon="close-x" />
    </slot>
  </ComboboxCancel>
</template>
