import type { RouteRecordRaw } from 'vue-router';
import api from '@/api';

import VAuthLayout from '@/layouts/VAuthLayout.vue';
import authRoutes from '@/router/modules/authRoutes';
import homeRoutes from '@/router/modules/homeRoutes';
import socialRoutes from '@/router/modules/socialAuthRoutes';

import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

const TheEmailVerify = () => import('@/views/email/TheEmailVerify.vue');

const TheReportGenerate = () => import('@/views/report/TheReportGenerate.vue');
const TheLogoutDevices = () => import('@/views/auth/TheLogoutDevices.vue');

const routes: Array<RouteRecordRaw> = [
  ...authRoutes,
  ...homeRoutes,
  ...socialRoutes,
  {
    path: '/report/print',
    name: 'report.print',
    component: TheReportGenerate,
    props: route => ({
      token: route.query.token,
    }),
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
  },
  {
    path: '/report/:slug/:format',
    name: 'report.print-new',
    component: TheReportGenerate,
    props: route => ({
      slug: route.params.slug,
      format: route.params.format,
    }),
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
  },
  {
    path: '/email/verify/:id/:token',
    name: 'verification.verify',
    component: VAuthLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'verification.verify.show',
        component: TheEmailVerify,
      },
    ],
  },
  {
    path: '/auth/social/google/confirm',
    name: 'auth.social.confirm',
    redirect: '/login',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/logout-devices/:token',
    component: VAuthLayout,
    name: 'logout.devices',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    children: [
      {
        path: '',
        name: 'logout.devices.show',
        component: TheLogoutDevices,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(async (to, from) => {
  const doNotScroll = to.matched.some(record => record.meta.doNotScroll);
  const isModalView = from.matched.some(record => record.meta.isModalView);

  if (to.name !== from.name && !doNotScroll && !isModalView) {
    const container: HTMLElement | null = document.getElementById('main-container');

    if (!!container) {
      await nextTick(() => {
        container.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        });
      });
    }
  }
});

router.beforeEach(async (to, from, next) => {
  // TODO apply permissions for route
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);
  const isPublic = to.matched.some(record => record.meta.isPublic);

  let isLogged;

  try {
    let utmQueryParams = {};

    if (to.query) {
      utmQueryParams = Object.keys(to.query)
        .filter(param => param.startsWith('utm_'))
        .reduce((accumulator: Record<string, any>, key) => {
          accumulator[key] = to.query[key];
          return accumulator;
        }, {});
    }

    const { referrer } = document;
    const headers: Record<string, any> = {};

    if (!!referrer) {
      headers['X-Referer'] = referrer;
    }

    if (!isPublic) {
      isLogged = await api.auth.check(utmQueryParams, headers);
    }
  } catch (error) {
    isLogged = false;
  }

  if (reqAuth) {
    if (!isLogged) {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (isLogged && !isPublic) {
    next({ name: 'me.sites.index' });
  } else {
    next();
  }
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module')
    || error.message.includes('Importing a module script failed')
  ) {
    window.location.href = to.fullPath;
  }
});

export default router;
