<script lang="ts" setup>
import type { MenuItem } from '@/components/site/menu/TheSiteMainMenu.vue';
import NewBadge from '@/components/global/menu/parts/NewBadge.vue';
import { ref } from 'vue';

defineOptions({
  name: 'TheMenuManagement',
});

const menuItems = ref<MenuItem[]>([
  {
    id: 'menu-billing',
    icon: 'card',
    label: 'menu.plains',
    ariaLabel: 'menu.plains',
    route: { name: 'billing.show' },
    isNewFeature: false,
  },
  {
    id: 'menu-alerting',
    icon: 'bullhorn',
    label: 'menu.alerting',
    ariaLabel: 'menu.alerting',
    route: { name: 'alerting.show' },
    isNewFeature: false,
  },
  {
    id: 'menu-settings',
    icon: 'settings',
    label: 'menu.settings',
    ariaLabel: 'menu.settings',
    class: 'router-link-parent-active',
    route: { name: 'settings.index' },
    isNewFeature: false,
  },
]);
</script>

<template>
  <div class="sidebar-nav pt-8">
    <p class="sidebar-heading text-uppercase fw-semi mb-8 ps-16 text-gray-500">
      {{ $t('menu.billingManagement') }}
    </p>

    <ul id="menu-management" class="nav billing-nav">
      <li v-for="item in menuItems" :id="item.id" :key="item.id" class="nav-item" role="presentation">
        <router-link
          :aria-label="$t(item.ariaLabel)"
          :class="item.class"
          :to="item.route"
          class="nav-link py-12"
        >
          <m-icon :icon="item.icon" aria-hidden="true" />
          <span class="text-sm">{{ $t(item.label) }}</span>

          <NewBadge v-if="item?.isNewFeature" />
        </router-link>
      </li>
    </ul>
  </div>
</template>
