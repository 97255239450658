import type { Permission } from '@/types/models/Permission';
import type { User } from '@/types/models/user/User';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class UserService extends AbstractService {
  /**
   * Retrieves the authenticated user and their associated organization.
   *
   * @returns Returns user {Promise<User>}
   */
  public async retrieve(): Promise<User> {
    return this.request({
      method: 'get',
      url: this.buildPath(apiEndpoints.meShow),
    });
  }

  /**
   * Update the user's profile information.
   *
   * @param {User} data
   * @returns {Promise<User>}
   */
  public update(data: User): Promise<User> {
    return this.request({
      method: apiEndpoints.userProfileInformationUpdate.method,
      url: this.buildPath(apiEndpoints.userProfileInformationUpdate),
      data,
    });
  }

  /**
   * Update the user's password.
   *
   * @param data
   * @returns {Promise<any>}
   */
  public updatePassword(data: any): Promise<any> {
    return this.request({
      method: apiEndpoints.userPasswordUpdate.method,
      url: this.buildPath(apiEndpoints.userPasswordUpdate),
      data,
    });
  }

  /**
   * Get the password confirmation status.
   *
   * @returns {Promise<any>}
   */
  public passwordStatus(): Promise<any> {
    return this.request({
      method: apiEndpoints.passwordConfirmation.method,
      url: this.buildPath(apiEndpoints.passwordConfirmation),
    });
  }

  /**
   * Confirm the user's password.
   *
   * @param data
   * @returns {Promise<any>}
   */
  public confirmPassword(data: any): Promise<any> {
    return this.request({
      method: apiEndpoints.passwordConfirm.method,
      url: this.buildPath(apiEndpoints.passwordConfirm),
      data,
    });
  }

  /**
   * Retrieves all permissions for the logged in user and organization user.
   *
   * @returns {Promise<Permission[]>}
   */
  public async allPermissions(): Promise<string[]> {
    const response = await this.request({
      method: apiEndpoints.mePermissionsIndex.method,
      url: this.buildPath(apiEndpoints.mePermissionsIndex),
    });

    return response.map((permission: Permission) => permission.name);
  }

  /**
   * Send a new email verification notification.
   *
   * @returns {Promise<any>}
   */
  public sendEmailVerificationNotification(): Promise<any> {
    return this.request({
      method: apiEndpoints.verificationSend.method,
      url: this.buildPath(apiEndpoints.verificationSend),
    });
  }

  // TODO Revisar esta ruta para apiEndpoints
  /**
   * Mark the authenticated user's email address as verified.
   *
   * @param data
   * @returns {Promise<any>}
   */
  public verifyEmail(data: any): Promise<any> {
    const route = { ...apiEndpoints.verificationVerify };
    route.path = `${route.path}?expires={expires}&signature={signature}` as any;

    return this.request({
      method: apiEndpoints.verificationVerify.method,
      url: this.buildPath(route, {
        id: data.id,
        hash: data.token,
        expires: data.expires,
        signature: data.signature,
      }),
    });
  }

  /**
   * Updates the user's settings
   *
   * @param settings
   * @returns {Promise<User>}
   */
  public updateSettings(settings: any): Promise<User> {
    return this.request({
      method: 'patch',
      url: this.buildPath(apiEndpoints.meSettingUpdate),
      data: settings,
    });
  }
}
