import useDate from '@/composables/useDate';
import NotificationComponent from '@/helpers/notifications/component/NotificationComponent.vue';
import { NotificationBase } from '@/helpers/notifications/NotificationBase';
import { h } from 'vue';

export class UptimeDownNotification extends NotificationBase {
  data: {
    site: {
      team: string
      slug: string
      url: string
      name: string
    }
    change: number
  };

  constructor(
    createdAt: string,
    readAt: string | null,
    updatedAt: string | null,
    data: {
      site: { team: string, slug: string, url: string, name: string }
      change: number
    },
  ) {
    super('uptime.down', createdAt, readAt, updatedAt);
    this.data = data;
  }

  render() {
    const route = {
      name: 'sites.site.show',
      params: { site: this.data.site.slug },
    };

    const { t } = useI18n();
    const { parseDateToPreferredFormat, parseDateToTimeFormat } = useDate();

    const title = t('notifications.notifications.uptimeDown.title', { name: this.data.site.name });
    const bodyText = t('notifications.notifications.uptimeDown.body', { url: this.data.site.url, date: `${parseDateToPreferredFormat(this.createdAt)} ${parseDateToTimeFormat(this.createdAt)}` });

    return h(NotificationComponent, {
      route,
      title,
      icon: 'fast-gage',
      bodyText,
      createdAt: this.createdAt,
    });
  }
}
