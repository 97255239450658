export default {
  title: 'Notificaciones',
  titleCount: '{num} configuración | {num} configuraciones',
  empty: {
    create: 'Crear configuración',
    title: 'Notificaciones personalizadas',
    description: 'Crea configuraciones personalizadas con diferentes tipos de notificaciones a través del medio que prefieras y asígnalas a tus webs para estar siempre al día de lo que pasa en ellas.',
    alertingReason1: 'Lo importante para ti',
    alertingDescription1: 'Elige qué notificaciones quieres recibir, en qué momento y en qué dispositivos. Pudiendo crear tantas configuraciones diferentes como quieras.',
    alertingReason2: 'Estate al tanto de todo',
    alertingDescription2: 'Añade a tus configuraciones de notificaciones el fallo de un backup, las caídas de la web o las nuevas vulnerabilidades críticas detectadas.',
    emptyYTLink: 'https://www.youtube.com/embed/SjeML97fUfQ?si=r_AcD4HHuEQQPQXY',
    emptyYTLinkHref: 'https://youtu.be/SjeML97fUfQ?si=r_AcD4HHuEQQPQXY',
  },
  delete: {
    modal: {
      title: '¿Quieres eliminar la configuración {name}?',
      text:
        'Si eliminas esta configuración, no recibirás más notificaciones sobre los eventos que tengas configurados. ¿Seguro que quieres eliminarla?',
    },
    toast: {
      success: 'La configuración ha sido eliminada correctamente',
      error: 'Ha ocurrido un error al eliminar la configuración',
    },
  },
  table: {
    stateAndName: 'Estado y nombre',
    name: 'Nombre',
    contact: 'Medio de contacto',
    type: 'Tipo',
    medium: 'Medio',
    sites: 'Webs',
    phone: 'Teléfono',
    emailAndPhone: 'Email, teléfono',
    email: 'Email',
    app: 'Sólo App',
    disableAlert: 'Deshabilitar alerta',
    enableAlert: 'Habilitar alerta',
    default: 'Predeterminada',
    removeAsDefault: 'Quitar como predeterminada',
    setAsDefault: 'Establecer como predeterminada',
    defaultTooltip: 'Esta alerta se asociará a todas las webs que conectes a Modular a partir de ahora',
    showConfiguration: 'Ver configuración',
  },
  featureHelp: {
    title: 'Aprende todo sobre las configuraciones de notificaciones',
    description: 'Te contamos qué son y para qué sirven las configuraciones de notificaciones de Modular y cómo puedes crearlas para tus webs.',
    watchVideo: 'Ver vídeo',
  },
  create: {
    availableSMS: 'Disponible por SMS',
    siteSelector: {
      applyToNewDescription: 'Aplica esta configuración a todas los webs nuevas que añadas.',
      applyToNewLabel: 'Aplicar a nuevas webs',
      name: 'Nombre',
      team: 'Equipo',
      noSites: 'No hemos encontrado ninguna web',
      sitesSelected: 'Sitios seleccionados',
    },
    steps: {
      step1: {
        title: 'Configuración general',
        description: 'Nombre, notificaciones activas...',
        subtitle: 'Notificaciones',
        subtitleDescription: 'Todas las comunicaciones se notificarán mediante la aplicación y por email (en caso de configurar una dirección de envío), salvo que se indique lo contrario a continuación. ',
        hasAnyAlertEnabledAlert: 'Debes seleccionar al menos una notificación',
        form: {
          alertName: 'Nombre de la configuración',
        },
      },
      step2: {
        title: 'Webs',
        description: 'A qué webs aplica',
        subtitle: 'Selección de webs',
      },
      step3: {
        title: 'Envío',
        description: 'Cómo quieres recibir las notificaciones',
        subtitle: 'Métodos de envío',
        smsValidationError: 'Introduce un número de teléfono válido',
        smsRequiredValidationError: 'Si quieres recibir notificaciones por SMS, debes introducir un número de teléfono',
        prefixRequiredValidationError: 'Debes introducir un prefijo',
        emailRequiredValidationError: 'Si quieres recibir notificaciones por correo, debes introducir al menos un email',
      },
    },
    toast: {
      success: 'La configuración ha sido guardada correctamente',
    },
  },
  toast: {
    status: {
      success: 'Estado de configuración cambiado correctamente.',
      error: 'Ha ocurrido un error al editar la configuración.',
    },
  },
  description: 'How you want to receive alerts',
  statusItems: {
    connector: 'Conexión',
    backupFailed: 'Copia de seguridad fallida',
    backupFailedDescription: 'Te avisaremos cuando una copia de seguridad no se pueda completar con éxito.',
    connectorSiteDisconnected: 'Web desconectada',
    connectorSiteDisconnectedDescription: 'Te avisaremos cuando una de tus webs pierda la conexión con Modular',
    reportAutomaticGenerated: 'Error en la generación de un reporte programado',
    reportAutomaticGeneratedDescription: 'Te avisaremos cuando haya un error en la generación de un reporte programado.',
    sslCertExpirationWarning: 'Advertencia de expiración del certificado SSL',
    sslCertExpirationWarningDescription: 'Te avisaremos cuando el certificado SSL esté próximo a caducar. Tienes que tenerlo activo en la configuración de Uptime de tu sitio.',
    uptimeDowntime: 'Web caída',
    uptimeDowntimeDescription: 'Te avisaremos cuando detectemos que tu web está caída en base a tu configuración de Uptime.',
    uptimeUptime: 'Web funcionando de nuevo',
    uptimeUptimeDescription: 'Te avisaremos cuando la web vuelva a funcionar.',
    vulnerabilityDiscoveryDate: 'Nueva vulnerabilidad detectada',
    vulnerabilityDiscoveryDateDescription: 'Te avisaremos cuando encontremos una nueva vulnerabilidad en tu web.',
  },
  sending: {
    email: 'Email',
    emailText: 'Elige tantas direcciones de email como quieras para recibir las notificaciones.',
    sms: 'SMS',
    smsText: 'Elige un número de teléfono para recibir tus notificaciones.',
    smsTextExtra: '<span class="fw-semi">Importante</span>: Solo llegarán por SMS las notificaciones críticas de Uptime. Web caída y web funcionando de nuevo.',
  },
};
