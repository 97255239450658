import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { ITaskStoreState } from '@/stores/task/index';
import type { SiteTask, SiteTaskMapped } from '@/types/models/site/task/SiteTask';
import type { Pagination } from '@/types/models/Pagination';
import type { PaginationResponse } from '@/types/Api';

const useTaskStore = defineStore('task', (): ITaskStoreState => {
  const loadedTasks = ref(false);
  const tasksMap = ref<Map<number, SiteTask | SiteTaskMapped>>(new Map());
  const tasksPaginated = ref<PaginationResponse<SiteTask | SiteTaskMapped> | null>(null);

  const tasksPagination: ComputedRef<Pagination | null> = computed(() => {
    if (!!tasksPaginated.value) {
      return {
        meta: tasksPaginated.value.meta,
        links: tasksPaginated.value.links,
      }
    }

    return null;
  });

  const reset = () => {
    loadedTasks.value = false;
    tasksMap.value = new Map();
    tasksPaginated.value = null;
  };

  return {
    loadedTasks,
    tasksMap,
    tasksPaginated,

    tasksPagination,

    reset,
  };
});

export { useTaskStore };

export default useTaskStore;
