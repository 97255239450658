<script setup lang="ts">
import { useAppStore } from '@/stores/appStore';
import { useUserStore } from '@/stores/userStore';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

defineOptions({
  name: 'TheMenu',
});

const appStore = useAppStore();
const userStore = useUserStore();

const { userLogged: user } = storeToRefs(userStore);
const darkMode = computed(() => appStore.darkMode);

const isChristmas = computed(() => import.meta.env.VITE_CHRISTMAS_MODE === 'true');
const isHalloween = computed(() => import.meta.env.VITE_HALLOWEEN_MODE === 'true');

const isEarlyAdopter = computed(() => !!user.value?.isEarlyAdopter);

const transitionName = ref('slide-right');

const route = useRoute();

watch(
  () => route,
  (to, from) => {
    // Force right transition when going from site to team
    if (to.name === 'team.show' && from.name === 'sites.site.show') {
      transitionName.value = 'slide-right';
    } else {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;

      transitionName.value = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }
  },
);
</script>

<template>
  <div id="sidebar-menu" class="bg-light">
    <div id="site-logo">
      <router-link
        :to="{ name: 'me.sites.index' }"
        class="logo-link d-block h-100 position-relative text-decoration-none px-16"
        data-cy="logo-link"
      >
        <v-picture
          :class="{
            'early-adopter-image': isEarlyAdopter,
            'ms-8': !isChristmas,
          }"
          height="32"
          width="192"
        >
          <img
            v-if="isChristmas"
            alt="Logo ModularDS"
            src="@assets/img/logo-modular-christmas.png"
            srcset="@assets/img/logo-modular-christmas.png 2x, @assets/img/logo-modular-christmas.png 1x"
          />
          <img
            v-else-if="isHalloween && !darkMode"
            alt="Logo ModularDS"
            src="@assets/img/logo-modular-halloween.png"
            srcset="@assets/img/logo-modular-halloween.png 2x, @assets/img/logo-modular-halloween.png 1x"
          />
          <img
            v-else-if="isHalloween && darkMode"
            alt="Logo ModularDS"
            src="@assets/img/logo-modular-halloween-dark.png"
            srcset="@assets/img/logo-modular-halloween-dark.png 2x, @assets/img/logo-modular-halloween-dark.png 1x"
          />
          <img
            v-else-if="darkMode"
            alt="Logo ModularDS"
            src="@assets/img/ModularDS-logo-light.png"
            srcset="@assets/img/ModularDS-logo-light.png 2x, @assets/img/ModularDS-logo-light.png 1x"
          >

          <img
            v-else
            alt="Logo ModularDS"
            src="@assets/img/ModularDS-logo.png"
            srcset="@assets/img/ModularDS-logo.png 2x, @assets/img/ModularDS-logo.png 1x"
          >
        </v-picture>

        <transition name="fade-in">
          <span
            v-if="isEarlyAdopter"
            :class="{ 'early-christmas': isChristmas }"
            class="d-block early-adopter fw-medium text-xs"
          >
            {{ $t('user.earlyAdopter') }}
          </span>
        </transition>
      </router-link>
    </div>

    <hr class="mb-24 mt-0 text-gray-600">

    <div id="site-sidebar" class="bg-light overflow-hidden">
      <nav
        id="site-sidebar-nav"
        :aria-labelledby="$t('general.shared.primary')"
        class="d-flex flex-column position-relative h-100 m-scroll-sm"
      >
        <router-view v-slot="{ Component }" name="menu">
          <Transition :name="transitionName">
            <component :is="Component" :key="$route.matched[0]" class="w-100" />
          </Transition>
        </router-view>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#site-logo {
  picture {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: max-width 0.3s ease-out;

    &.early-adopter-image {
      max-width: 7.5rem;
    }

    &:not(.early-adopter-image) {
      max-width: 9rem;
    }
  }

  .early-adopter {
    margin-top: -1.875rem;
    margin-left: 2.25rem;
    color: #cc5e00;

    &.early-christmas {
      margin-left: 2.75rem;
    }
  }
}

#site-sidebar {
  width: 17.5rem;

  #site-sidebar-nav {
    overflow: hidden auto;
  }

  .slide-right-move,
  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-move,
  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .slide-right-enter-from,
  .slide-left-leave-to {
    opacity: 0;
    transform: translateX(-3.125rem);
  }

  .slide-right-leave-to,
  .slide-left-enter-from {
    opacity: 0;
    transform: translateX(3.125rem);
  }

  .slide-right-leave-active,
  .slide-right-enter-active,
  .slide-left-leave-active,
  .slide-left-enter-active {
    position: absolute;
  }

  .menu-no-transition-move,
  .menu-no-transition-enter-active,
  .menu-no-transition-leave-active {
    transition: unset;
  }

  .menu-no-transition-enter-from {
    position: absolute;
    opacity: 0;
  }
}
</style>
