<script lang="ts" setup>
import useMetrics from '@/composables/metrics/useMetrics';
import useMetricsStore from '@/stores/site/metrics/metricsStore';

import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';

import LogoGoogle from '@assets/img/google/LogoGoogle.svg';
import { watchImmediate } from '@vueuse/core';
import { ref } from 'vue';

defineOptions({
  name: 'TheSiteOverviewMetrics',
});

defineProps<{
  dateRange: number
}
>();

const metricsStore = useMetricsStore();

const { loadedAnalytics } = storeToRefs(metricsStore);

const analyticsIsEnabled = computed(() => metricsStore.analyticsIsEnabled);

const loadedAnalyticsReport = computed(() => metricsStore.loadedAnalyticsReport);
const analyticsReport = computed(() => metricsStore.analyticsReport);

const { ANALYTICS } = ServiceTypeEnumConst;

const {
  metricsDatesFields,
  loadMetricService,
  loadAnalyticsReport,
} = useMetrics(ANALYTICS);

// Only load Widget when visible
const analyticsWidget = ref<HTMLElement | null>(null);
const targetIsVisible = useElementVisibility(analyticsWidget);

watchImmediate([targetIsVisible, analyticsIsEnabled], async ([targetValue, isEnabled]) => {
  if (targetValue && !analyticsReport.value && isEnabled) {
    if (!metricsStore.analyticsService) {
      await loadMetricService();
    }

    await loadAnalyticsReport({
      start: metricsDatesFields.value.start,
      end: metricsDatesFields.value.end,
    });
  }

  // If search is not enabled, we can consider it loaded
  if (!isEnabled) {
    loadedAnalytics.value = true;
  }
});
</script>

<template>
  <div id="site-overview-metrics" ref="analyticsWidget" class="metrics position-relative w-100 mb-28">
    <Transition mode="out-in" name="fade-in">
      <div v-if="!loadedAnalytics || (analyticsIsEnabled && !loadedAnalyticsReport)">
        <div class="placeholder-glow d-flex justify-content-between align-items-center mb-16">
          <span class="placeholder" style="width: 70px" />
          <span class="placeholder text-xs text-gray-500" style="width: 100px" />
        </div>

        <div class="placeholder-glow">
          <div class="row row-cols-3 gx-0">
            <div
              class="col border-1 d-flex flex-column align-items-center justify-content-center border py-8 text-center"
            >
              <span class="placeholder mb-8 text-xs text-gray-500" style="width: 100px" />

              <span class="placeholder h4 mb-0" style="width: 70px" />
            </div>

            <div
              class="col border-1 d-flex flex-column align-items-center justify-content-center border py-8 text-center"
            >
              <span class="placeholder mb-8 text-xs text-gray-500" style="width: 100px" />

              <span class="placeholder h4 mb-0" style="width: 70px" />
            </div>

            <div
              class="col border-1 d-flex flex-column align-items-center justify-content-center border py-8 text-center"
            >
              <span class="placeholder mb-8 text-xs text-gray-500" style="width: 100px" />

              <span class="placeholder h4 mb-0" style="width: 70px" />
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="!analyticsIsEnabled">
        <div class="metrics-header d-flex justify-content-between align-items-center mb-16">
          <div class="metrics-title fw-semi">
            {{ $t('site.overview.metrics.title') }}
          </div>
        </div>

        <div class="metrics-body">
          <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between w-100">
            <div class="col-12 col-lg-6 image position-relative">
              <v-picture height="153" lazy width="370">
                <img
                  :alt="$t('statistics.connectModular')"
                  class="md-image-empty-chart w-100 w-lg-auto mw-100"
                  src="@assets/img/empty-metrics-chart.png"
                  srcset="@assets/img/empty-metrics-chart.png 2x, @assets/img/empty-metrics-chart.png 1x"
                >
              </v-picture>
              <span class="md-image-gradient position-absolute w-100 h-100 start-0 top-0" />
            </div>

            <div class="col-12 col-lg-6 d-flex flex-column align-items-end text-end">
              <p class="fw-semi text-primary mb-4">
                {{ $t('site.overview.metrics.googleAnalytics') }}
              </p>

              <p class="fw-medium mb-8 text-xs text-gray-500">
                {{ $t('site.overview.metrics.googleAnalyticsDescription') }}
              </p>

              <router-link
                :to="{ name: 'sites.site.analytics.show' }"
                class="btn btn-outline-gray btn-sm btn-icon fw-semi"
              >
                <div class="me-8">
                  <LogoGoogle height="16" viewBox="0 0 32 32" width="16" />
                </div>

                <span class="text-sm">{{ $t('site.overview.metrics.googleAnalyticsConnect') }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="!!analyticsReport && Object.keys(analyticsReport).length > 0">
        <div class="metrics-header d-flex justify-content-between align-items-center mb-16">
          <div class="metrics-title fw-semi">
            {{ $t('site.overview.metrics.title') }}
          </div>

          <div class="metrics-date fw-medium text-sm text-gray-500">
            {{ $t('site.overview.metrics.since', { date_range: dateRange }) }}
          </div>
        </div>

        <div class="metrics-body">
          <div class="row row-cols-3 gx-0">
            <div
              class="metric-content col d-flex flex-column align-items-center justify-content-center py-8 text-center"
            >
              <span class="fw-medium text-sm text-gray-500">{{ $t('report.analytics.visits') }}</span>

              <span class="h4 mb-0">
                <template v-if="!!analyticsReport.pageViewsPerDay.amounts.total">
                  {{ $str.formatNumberToThousand(analyticsReport.pageViewsPerDay.amounts.total) }}
                </template>

                <template v-else>0</template>
              </span>
            </div>

            <div
              class="metric-content col d-flex flex-column align-items-center justify-content-center py-8 text-center"
            >
              <span class="fw-medium text-sm text-gray-500">{{ $t('report.analytics.users') }}</span>

              <span class="h4 mb-0">
                <template v-if="!!analyticsReport && !!analyticsReport.usersPerDay.amounts.total">
                  {{ $str.formatNumberToThousand(analyticsReport.usersPerDay.amounts.total) }}
                </template>

                <template v-else>0</template>
              </span>
            </div>

            <div
              class="metric-content col d-flex flex-column align-items-center justify-content-center py-8 text-center"
            >
              <span class="fw-medium text-sm text-gray-500">{{ $t('report.analytics.bounceRate') }}</span>

              <span class="h4 mb-0">
                <template v-if="!!analyticsReport && !!analyticsReport.bounceRatePerDay.amounts.average">
                  {{
                    $str.formatNumber(analyticsReport.bounceRatePerDay.amounts.average, $str.numberFormats.PERCENTAGE)
                  }}
                </template>

                <template v-else>{{ $str.formatNumber(parseFloat('0'), $str.numberFormats.PERCENTAGE) }}</template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
#site-overview-metrics {
  min-height: 7.5rem;

  .metrics-body {
    .metric-content {
      border: 0.0625rem solid var(--md-gray-400);
    }
  }
}
</style>
