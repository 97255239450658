export default {
  title: 'Goodbye <span class="text-warning">Beta</span>',
  planazoAnnouncement: '<strong>Goodbyes</strong> are not so hard when it\'s for the better.',
  codeLabel: 'Code:',
  copy: 'Copy',
  copiedConfirmation: 'Copied!',
  discountOnProPlan: 'Discount on Modular <br/> PRO plans',
  offerUntilWednesday: 'Until Sunday the 3rd at 23:59',
  offerThursdayToMonday: 'From Thursday 23rd to Monday 27th at 23:59',
  offerUntilMonday: 'Until Monday the 27th at 23:59',
  signInLabel: 'Sign in',
  takeAdvantageOfTheOffer: 'Get it now',
};
