<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

export type ButtonVariantType =
  | ''
  | 'primary'
  | 'primary-dark'
  | 'secondary'
  | 'success'
  | 'success-dark'
  | 'danger'
  | 'pink'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'black'
  | 'gray'
  | 'outline-primary'
  | 'outline-primary-200'
  | 'outline-primary-dark'
  | 'outline-secondary'
  | 'outline-danger'
  | 'outline-danger-medium'
  | 'outline-success'
  | 'outline-success-dark'
  | 'outline-light'
  | 'outline-gray'
  | 'outline-gray-600'
  | 'link'
  | 'link-primary-200'
  | 'link-primary-dark'
  | 'link-dark-primary'
  | 'link-light-dark'
  | 'link-danger-dark'
  | 'link-dark'
  | 'icon'
  | 'transparent'
  | 'filter'
  | 'combobox-select';

export default defineComponent({
  name: 'VButton',
  props: {
    type: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      required: false,
      default: 'button',
    },
    variant: {
      type: String as PropType<ButtonVariantType>,
      required: false,
      default: '',
    },
    size: {
      type: String as PropType<'2xs' | 'xs' | 'sm' | 'md' | 'lg'>,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    dataBsDismiss: {
      type: String,
      required: false,
      default: null,
    },
    dataBsToggle: {
      type: String,
      required: false,
      default: null,
    },
    dataBsTarget: {
      type: String,
      required: false,
      default: null,
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
    ariaLabelledby: {
      type: String,
      required: false,
      default: null,
    },
    ariaExpanded: {
      type: [Boolean, () => undefined],
      required: false,
      default: undefined,
    },
    ariaControls: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: null,
    },
    // This is for compatibility with @click handler methods that do not expect an event object
    emitEventData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    cSize() {
      let ret = null;

      if (!!this.size) {
        ret = `btn-${this.size}`;
      }

      return ret;
    },
    cVariant() {
      let ret = null;

      if (!!this.variant) {
        ret = `btn-${this.variant}`;
      }

      return ret;
    },
  },
  methods: {
    handleClick(e: any) {
      if (this.emitEventData) {
        this.$emit('click', e);
      } else {
        this.$emit('click');
      }
    },
  },
});
</script>

<template>
  <button
    :id="id"
    :aria-controls="ariaControls"
    :aria-expanded="ariaExpanded"
    :aria-label="ariaLabel"
    :aria-labelledby="ariaLabelledby"
    :class="[cSize, cVariant]"
    :data-bs-dismiss="dataBsDismiss"
    :data-bs-target="dataBsTarget"
    :data-bs-toggle="dataBsToggle"
    :disabled="disabled"
    :type="type"
    class="btn"
    @click="handleClick"
  >
    <slot v-if="!isLoading" />
    <template v-else>
      {{ $t('general.button.loading') }}
    </template>
  </button>
</template>
