<script lang="ts" setup>
import type { HTMLAttributes } from 'vue';
import { cn } from '@/helpers/classNames';
import { computed } from 'vue';

defineOptions({
  name: 'DialogHeader',
});

const props = withDefaults(defineProps<{
  class?: HTMLAttributes['class']
  disableClose?: boolean
}>(), {
  class: '',
  disableClose: false,
});

const delegatedProps = computed(() => {
  const { class: _, disableClose: __, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <div
    :class="cn('ui-dialog-header d-flex align-items-center justify-content-between', props.class, delegatedProps)"
  >
    <slot />

    <DialogClose v-if="!disableClose">
      {{ $t('general.modal.close') }}
    </DialogClose>
  </div>
</template>
