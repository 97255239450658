import type {
  SiteServiceVulnerability,
  VulnerabilityServiceParams,
} from '@/types/models/site/service/vulnerabilities/Vulnerability';
import { AbstractService } from '@modular/sdk';
import { apiEndpoints } from '@/types/routes/routes';

export default class VulnerabilitiesService extends AbstractService {
  /**
   * Retrieves the Vulnerabilities Service for the current site.
   *
   * @param {number} siteId
   * @param {VulnerabilityServiceParams} params
   * @returns {Promise<SiteServiceVulnerability>} A promise that resolves to the vulnerabilities service for the site.
   */
  public async retrieve(siteId: number, params?: VulnerabilityServiceParams): Promise<SiteServiceVulnerability> {
    return this.request({
      method: apiEndpoints.vulnerabilityServicesShow.method,
      url: this.buildPath(apiEndpoints.vulnerabilityServicesShow, {
        site: siteId,
      }),
      params,
    });
  }
}
