<script lang="ts" setup>
import VNav from '@/components/vendor/basic/nav/VNav.vue';

import { useTeamCreate } from '@/composables/team/useTeamCreate';

import useUserStore from '@/stores/userStore';

import { computed } from 'vue';

defineOptions({
  name: 'TheMenuTeams',
});

const userStore = useUserStore();
const teams = computed(() => userStore.teams);

const { createTeam } = useTeamCreate();

const sidebarConfig = useStorage('sidebar-config', {
  teamsCollapsible: true,
});

const collapsibleOpen = computed({
  get: () => sidebarConfig.value.teamsCollapsible,
  set: (value: boolean) => {
    sidebarConfig.value.teamsCollapsible = value;
  },
});
</script>

<template>
  <div v-if="teams" class="sidebar-nav" data-cy="sidebar-teams">
    <MCollapsible
      v-if="teams.length > 0"
      v-model:open="collapsibleOpen"
      as="div"
      class="nav-item position-relative mb-8"
    >
      <CollapsibleTrigger
        :class="{ open: collapsibleOpen }"
        class="btn btn-transparent btn-sidebar z-3 rounded-0 d-flex align-items-center end-0 border-0 px-16 py-12 mb-4 outline-none w-100 d-flex align-items-center justify-content-between"
      >
        <span class="sidebar-heading text-uppercase fw-semi mb-0 text-gray-500">
          {{ $t('team.shared.teams') }}
        </span>

        <m-icon
          :class="{ open: collapsibleOpen }"
          class="ms-auto transition-transform me-0"
          icon="arrow-caret-down"
          size="2xs"
        />
      </CollapsibleTrigger>

      <CollapsibleContent class="nav-teams-collapsible">
        <ul class="nav teams-nav">
          <the-menu-teams-collapsible v-for="team in teams" :key="team.slug" :team="team" />
        </ul>

        <VButton
          :aria-label="$t('team.shared.createTeam')"
          :title="$t('team.shared.createTeam')"
          class="btn-link btn-sidebar text-decoration-none fw-semi text-sm text-end d-flex justify-content-end px-16 py-12 w-100"
          data-cy="btn-sidebar-create-team"
          variant="transparent"
          @click="createTeam()"
        >
          <v-icon class="fw-semi me-4" icon="plus-slim" size="4xs" />
          <span>{{ $t('team.shared.addTeam') }}</span>
        </VButton>
      </CollapsibleContent>
    </MCollapsible>

    <VNav v-else class="teams-nav">
      <li v-for="n in 3" :key="n" class="nav-item">
        <div class="nav-group btn-group d-flex placeholder-glow position-relative">
          <span class="nav-link pe-none py-12">
            <span class="placeholder" style="width: 120px; height: 1rem" />
          </span>
        </div>
      </li>
    </VNav>
  </div>
</template>

<style lang="scss" scoped>
.teams-nav {
  > .nav-item {
    > .nav-group {
      padding-left: 1.25rem;
    }

    .nav-link {
      padding-right: 3.25rem;
    }

    .team-name,
    .site-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .team-name {
      max-width: 210px;
    }

    .site-name {
      max-width: 240px;
    }
  }

  .submenu {
    max-height: 12rem;
  }
}
</style>
