export default {
  backup: {
    schedule: {
      frequency: {
        dailyName: 'Daily',
        weeklyName: 'Weekly',
        monthlyName: 'Monthly',
        weekly: {
          day: {
            weeks: {
              mondayName: 'Monday',
              tuesdayName: 'Tuesday',
              wednesdayName: 'Wednesday',
              thursdayName: 'Thursday',
              fridayName: 'Friday',
              saturdayName: 'Saturday',
              sundayName: 'Sunday',
            },
          },
        },
      },
    },
    retention: {
      period: {
        monthly: {
          '45Name': '45 days',
        },
        weekly: {
          '14Name': '2 weeks',
          '30Name': '30 days',
          '45Name': '45 days',
        },
        daily: {
          '3Name': '3 days',
          '7Name': '1 week',
          '14Name': '2 weeks',
          '30Name': '30 days',
          '45Name': '45 days',
        },
      },
    },
  },
  user: {
    language: {
      esName: 'Spanish',
      enName: 'English',
    },
  },
  site: {
    report: {
      status: {
        draftName: 'Draft',
        inProgressName: 'Generating',
        doneName: 'Generated',
        errorName: 'Error',
        partialErrorName: 'Partial Generated',
      },
      language: {
        esName: 'Spanish',
        enName: 'English',
        caName: 'Catalan',
        frName: 'French',
        ptName: 'Portuguese',
        fiName: 'Finnish',
        deName: 'German',
      },
    },
    service: {
      status: {
        activeName: 'Active',
        inactiveName: 'Inactive',
      },
      report: {
        schedule: {
          frequency: {
            weeklyName: 'Weekly',
            monthlyName: 'Monthly',
            quarterlyName: 'Quarterly',
          },
        },
      },
    },
    status: {
      pendingName: 'We are preparing the connection process...',
      reachedLoginPageName: 'We are preparing the connection process...',

      loginInName: 'Here we go, establishing connection with your website...',

      loggedInName: 'Connection established! Let’s install the Modular Connector plugin...',
      reachedSecondaryActionRequiredName: 'Connection established! Let’s install the Modular Connector plugin...',
      reachedInstallationPageName: 'Connection established! Let’s install the Modular Connector plugin...',
      installingPluginName: 'Connection established! Let’s install the Modular Connector plugin...',

      pluginInstalledName: 'Modular Connector installed successfully, almost done...',
      pluginReplacedName: 'Modular Connector installed successfully, almost done...',
      searchingPluginUpgradeName: 'Modular Connector installed successfully, almost done...',
      pluginUpgradingName: 'Modular Connector installed successfully, almost done...',
      pluginUpgradedName: 'Modular Connector installed successfully, almost done...',
      reachedActivationPageName: 'Modular Connector installed successfully, almost done...',
      activatingPluginName: 'Modular Connector installed successfully, almost done...',

      pluginActivatedName: 'Plugin activated! Checking the connection...',
      reachedCredentialsPageName: 'Plugin activated! Checking the connection...',
      establishedCredentialsName: 'Plugin activated! Checking the connection...',

    },
    backup: {
      status: {
        excludedName: 'Excluded',
        pendingName: 'Queued',
        inProgressName: 'Creating',
        readyName: 'Processing',
        uploadPendingName: 'Pending upload',
        uploadingName: 'Uploading...',
        doneName: 'Done',
        failedInCreationName: 'Creation failed',
        failedExportDatabaseName: 'Failed to export the database',
        failedExportFilesName: 'Failure to export files',
        failedInProcessingName: 'Processing failed',
        failedUploadedName: 'Upload failed',
        failedPartialName: 'Partial failure',
        failedName: 'Failure',
        failedFileNotFoundName: 'File not found',
        storageExceededName: 'Storage exceeded',
      },
    },
  },
  uptime: {
    service: {
      interval: {
        '120Name': '2 minutes',
        '300Name': '5 minutes',
        '900Name': '15 minutes',
        '1800Name': '30 minutes',
      },
      timeout: {
        '250Name': '250ms',
        '500Name': '500ms',
        '750Name': '750ms',
        '1000Name': '1s',
        '2000Name': '2s',
        '5000Name': '5s',
        '10000Name': '10s',
        '20000Name': '20s',
        '30000Name': '30s',
        '45000Name': '45s',
      },
      keyword: {
        type: {
          someName: 'Non strict mode',
          someDescription:
            'At least one of the words must be present in the page content',
          everyName: 'Strict mode',
          everyDescription: 'All words must be present in the page content',
        },
      },
    },
  },
  check: {
    ssl: {
      frequency: {
        notification: {
          '1Name': 'One day before',
          '7Name': '7 days before',
          '14Name': '14 days before',
          '30Name': '30 days before',
        },
      },
    },
    notification: {
      interval: {
        '60Name': '1 Minute',
        '120Name': '2 Minutes',
        '300Name': '5 Minutes',
        '900Name': '15 Minutes',
        '1800Name': '30 Minutes',
      },
    },
  },
  schedule: {
    frequency: {
      weekly: {
        days: {
          mondayName: 'Monday',
          tuesdayName: 'Tuesday',
          wednesdayName: 'Wednesday',
          thursdayName: 'Thursday',
          fridayName: 'Friday',
          saturdayName: 'Saturday',
          sundayName: 'Sunday',
        },
      },
    },
  },
  organization: {
    domain: {
      status: {
        pendingName: 'Not verified',
        successName: 'Verified',
        failedName: 'Failed',
        temporaryFailureName: 'Temporary failure',
        notStartedName: 'Not verified',
      },
      error: {
        type: {
          serviceErrorName: 'Service error',
          dnsServerErrorName: 'DNS server error',
          hostNotFoundName: 'DNS records not found',
          typeNotFoundName: 'Type not found',
          invalidValueName: 'Some DNS record has invalid value',
        },
      },
    },
  },
};
