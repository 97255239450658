export default {
  payment: {
    createPublicLink: 'Crear enlace de contratación público',
    editPublicLink: 'Editar enlace de contratación público',
    createLink: 'Crear enlace',
    linkCreated: 'Enlace creado',
    noLinks: 'No hay links de contratación',
    product: 'Producto',
    selectProduct: 'Seleccione un producto',
    daysToExpire: 'Días para caducar',
    client: 'Cliente',
    chooseClient: 'Seleccione un cliente',
    chooseCountry: 'Seleccione un país',
    hiring: 'Contratación',
    newLink: 'Nuevo enlace',
    code: 'Code',
    creationDate: 'Fecha de creación',
    expireDate: 'Fecha de expiración',
  },
  users: {
    update: 'Actualizar usuario',
    noUsers: 'No hay usuarios',
    usersManagement: 'Gestión de usuarios',
  },
};
