<script lang="ts" setup>
import type { ComponentVulnerabilityExists } from '@/types/models/organization/vulnerability/ComponentVulnerabilityExists';
import { computed } from 'vue';

defineOptions({
  name: 'TheManagerUpdateComponentVulnerabilities',
});

const props = defineProps<{
  componentVulnerabilities: ComponentVulnerabilityExists
}>();

defineEmits(['openVulnerability']);

const hasVulnerabilies = computed(() => props.componentVulnerabilities.vulnerabilitiesExists);
</script>

<template>
  <Tooltip
    v-if="hasVulnerabilies"
    :content="$t('vulnerability.tooltip.view')"
    trigger-class="status-item-container"
  >
    <transition appear name="fade-in-fast">
      <VButton
        :class="{
          'text-danger-dark': componentVulnerabilities.vulnerabilitiesCExists && componentVulnerabilities.vulnerabilitiesExists,
          'text-tiger-500': !componentVulnerabilities.vulnerabilitiesCExists && componentVulnerabilities.vulnerabilitiesExists,
        }"
        class="status-item border-1 p-0"
        emit-event-data
        variant="icon"
        @click.stop="$emit('openVulnerability')"
      >
        <v-icon class="status-item-icon" icon="filled-alert-info" />
      </VButton>
    </transition>
  </Tooltip>
</template>

<style lang="scss" scoped>
.status-item-icon {
  font-size: 15px;
}
</style>
