import type { Channel } from 'pusher-js';
import api from '@/api';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export class SocketClient {
  public echo: Echo;

  constructor() {
    this.echo = this.generateEcho();
  }

  private generateEcho(): Echo {
    const options = {
      key: import.meta.env.VITE_ECHO_KEY,
      cluster: import.meta.env.VITE_CLUSTER,
      wsHost: import.meta.env.VITE_PUSHER_HOST,
      wsPort: import.meta.env.VITE_PUSHER_PORT,
      wssPort: import.meta.env.VITE_PUSHER_PORT,
    };

    return new Echo({
      broadcaster: import.meta.env.VITE_BROADCASTER,
      ...options,
      forceTLS: false,
      encrypted: true,
      enableStats: true,
      enabledTransports: ['ws', 'wss'],
      logToConsole: true,
      Pusher,
      authEndpoint: '/broadcasting/auth',
      authorizer: (channel: Channel) => ({
        authorize: async (socketId: any, callback: any) => {
          try {
            const response = await api.auth.broadcast({
              socket_id: socketId,
              channel_name: channel.name,
            });

            callback(false, response);
          } catch (e) {
            callback(true, e);
          }
        },
      }),
    });
  }
}

export default new SocketClient();
