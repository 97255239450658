import { Concept } from '@/types/models/Concept';

export type UptimeSSLStatusEnumKeys = 'active' | 'inactive';

export const UptimeSSLStatusEnumConst = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export const UptimeSSLStatusEnum: Record<UptimeSSLStatusEnumKeys, Concept> = {
  'active': {
    key: 'active',
    value: null,
    name: 'concept.uptime.s.s.l.status.activeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: 'check.ssl.enabled',
    type: 'uptime.s.s.l.status'
  },
  'inactive': {
    key: 'inactive',
    value: null,
    name: 'concept.uptime.s.s.l.status.inactiveName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'uptime.s.s.l.status'
  }
} as const;

export type UptimeSSLStatusEnumType = (typeof UptimeSSLStatusEnumConst)[keyof typeof UptimeSSLStatusEnumConst];

export const UptimeSSLStatusEnumArray = Object.values(UptimeSSLStatusEnum);
