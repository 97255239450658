<script lang="ts" setup>
import api from '@/api';

import VRightSidebar from '@/components/global/sidebar/VRightSidebar.vue';

import VTrustedPerson from '@/components/global/sidebar/VTrustedPerson.vue';

import useModalUtils from '@/composables/useModal';
import usePermissions from '@/composables/usePermissions';

import useSiteStore from '@/stores/site/siteStore';
import TheSiteOverviewSidebarNote from '@/views/site/overview/sidebar/TheSiteOverviewSidebarNote.vue';
import TheSiteOverviewSidebarReport from '@/views/site/overview/sidebar/TheSiteOverviewSidebarReport.vue';
import TheSiteOverviewSidebarUptime from '@/views/site/overview/sidebar/TheSiteOverviewSidebarUptime.vue';
import TheSiteEdit from '@/views/site/TheSiteEdit.vue';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { PermissionEnumConst } from '@/types/PermissionEnum';

const siteStore = useSiteStore();
const { can } = usePermissions();

const toast: any = inject('toast');

const { openModal, modalToShow, closeModal } = useModalUtils();

const disableButtonLoading = ref(false);

const currentSite = computed(() => siteStore.currentSite);

async function loginWordpress() {
  try {
    if (!currentSite.value?.id)
      return;

    if (currentSite.value?.connectionStatus === 'established') {
      disableButtonLoading.value = true;

      const response = await api.site.manager.loginInWordpress(currentSite.value.id);

      window.open(response.redirectUri, '_blank');
    }
  } catch (e: any) {
    console.error(e);

    toast.error(e.response.data.message);
  } finally {
    disableButtonLoading.value = false;
  }
}

const localNote = ref<string | null>('');
const shortLocalNote = ref<string | null>('');

function setNoteData(note?: string | null) {
  const tmpNote = note || currentSite?.value?.note;

  if (!!tmpNote && tmpNote.length > 200) {
    shortLocalNote.value = `${tmpNote.substring(0, 200)}...`;
  } else {
    localNote.value = tmpNote || null;
    shortLocalNote.value = tmpNote || null;
  }

  localNote.value = tmpNote || null;
}

watch(
  () => currentSite?.value?.note,
  (newVal) => {
    setNoteData(newVal);
  },
);

onMounted(() => {
  setNoteData();
});
</script>

<template>
  <VRightSidebar>
    <div id="site-sidebar-note" class="position-relative mb-16">
      <VButton
        class="d-flex align-items-start fw-medium w-100 p-16 text-start"
        variant="transparent"
        @click="openModal('site-note')"
      >
        <span v-if="!currentSite" class="placeholder-glow" style="height: 12px">
          <span class="placeholder" style="width: 120px; min-height: 12px" />
        </span>

        <template v-else-if="!!currentSite && shortLocalNote && shortLocalNote.length > 0">
          <p v-dompurify-html="shortLocalNote" class="ui-editor-content site-note-text text-break mb-0 pb-16 text-xs" />
        </template>

        <div v-else class="d-flex align-items-center">
          <v-icon class="note-add-icon" icon="plus" />
          <span class="fw-semi ms-4 text-xs">{{ $t('site.overview.note.createNote') }}</span>
        </div>
      </VButton>

      <TheSiteOverviewSidebarNote
        v-if="modalToShow === 'site-note' && !!currentSite"
        :site-id="currentSite.id"
        :site-name="currentSite.name"
        :site-note="localNote"
        @closed="closeModal"
        @saved="setNoteData"
      />
    </div>

    <div id="site-sidebar-actions" class="d-flex justify-content-end align-items-lg-center w-100 mb-24 pt-4">
      <VButton
        v-if="can(PermissionEnumConst.SITES_UPDATE)"
        class="btn-settings d-inline-flex flex-grow-1 btn-xs fw-semi me-8"
        data-cy="site-settings"
        size="xs"
        variant="outline-gray-600"
        @click="openModal('the-site-edit')"
      >
        <v-icon icon="settings" size="sm" space="me-8" />
        <span class="fw-semi mx-auto text-sm">{{ $t('general.shared.settings') }}</span>
      </VButton>

      <VButton
        v-else
        class="d-inline-flex btn-settings flex-grow-1 fw-semi m e-8"
        data-cy="site-settings"
        disabled
        size="xs"
        variant="outline-gray-600"
      >
        <v-icon icon="settings" size="sm" space="me-8" />
        <span class="fw-semi mx-auto text-sm">{{ $t('general.shared.settings') }}</span>
      </VButton>

      <VButton
        v-if="!currentSite || siteStore.currentSiteIsConnected"
        :disabled="!currentSite?.connectionStatus || disableButtonLoading"
        class="d-inline-flex fw-semi text-nowrap"
        data-cy="site-go-to-wp"
        size="xs"
        variant="outline-gray-600"
        @click="loginWordpress"
      >
        <span class="fw-semi text-sm">{{ $t('general.button.toWordPress') }}</span>
        <v-icon icon="arrow-right" size="sm" space="ms-8" />
      </VButton>

      <router-link
        v-else
        :to="{
          name: 'sites.site.connect',
          params: { site: currentSite.slug },
        }"
        class="btn btn-primary btn-xs fw-semi text-nowrap"
        data-cy="site-reconnect"
      >
        <span class="fw-semi text-sm">{{ $t('general.button.reconnect') }}</span>
      </router-link>
    </div>

    <TheSiteOverviewSidebarReport />

    <TheSiteOverviewSidebarUptime />

    <VTrustedPerson />

    <TheSiteEdit v-if="modalToShow === 'the-site-edit'" @closed="closeModal" />
  </VRightSidebar>
</template>

<style lang="scss" scoped>
#site-sidebar-note {
  background-color: #ffe0a5;

  > .btn {
    min-height: 4.5rem;
  }

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
  }

  &::before {
    border-style: solid;
    border-width: 1.5rem 1.5rem 0 0;
    border-color: #ffbd63 transparent;
  }

  &::after {
    border-style: solid;
    border-width: 0 0 1.5rem 1.5rem;
    border-color: #f6f7ff transparent;
  }

  .note-add-icon {
    font-size: 0.375rem;
  }

  .site-note-text {
    line-height: 1.75em;
    white-space: break-spaces;
  }
}

[data-bs-theme='dark'] {
  #site-sidebar-note {
    background-color: var(--md-gray-200);

    &::after {
      border-style: solid;
      border-width: 0 0 1.5rem 1.5rem;
      border-color: var(--md-gray-100) transparent;
    }
  }
}
</style>
