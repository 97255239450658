import type { InjectionKey } from 'vue';
import type { ToastInterface } from 'vue-toastification';
import api from '@/api';

import { inject, ref } from 'vue';

import { useI18n } from 'vue-i18n';

function useLoginWordpress() {
  const isLoading = ref(false);
  const toast: InjectionKey<ToastInterface> | any = inject('toast');
  const { t } = useI18n();

  const loginWordpress = async (siteId: number) => {
    isLoading.value = true;
    try {
      const response = await api.site.manager.loginInWordpress(siteId);
      window.open(response.redirectUri, '_blank');
    } catch (e: any) {
      console.error(e);
      toast.error(t('general.shared.error'));
    } finally {
      isLoading.value = false;
    }
  };

  return { loginWordpress, isLoading };
}

export { useLoginWordpress };
export default useLoginWordpress;
