<script lang="ts" setup>
import CharacterCount from '@tiptap/extension-character-count';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/vue-3';
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Bold,
  Code as CodeIcon,
  Eraser,
  Heading1,
  Heading2,
  Heading3,
  Italic,
  Link as LinkIcon,
  List,
  ListOrdered,
  Quote,
  SquareDashedBottomCode,
  Strikethrough,
  Underline as UnderlineIcon,
} from 'lucide-vue-next';

defineOptions({
  name: 'MEditor',
});

const props = defineProps({
  modelValue: String,
  characterLimit: {
    type: Number,
    default: 2500,
  },
  placeholder: {
    type: String,
    default: '',
  },
  editorClass: {
    type: [String, Object, Array],
    default: () => ({}),
  },
});

const emit = defineEmits(['update:modelValue']);

const editor = useEditor({
  content: props.modelValue,
  onUpdate: ({ editor }) => {
    let content = editor.getHTML()
    const json = editor.getJSON().content;

    // Cleans empty <p></p> tags to show the placeholder
    if (Array.isArray(json) && json.length === 1 && !Object.prototype.hasOwnProperty.call(json[0], 'content')) {
      content = '';
    }

    emit('update:modelValue', content);
  },
  extensions: [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3],
      },
    }),
    Underline,
    Link.configure({
      openOnClick: false,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
      alignments: ['left', 'center', 'right', 'justify'],
    }),
    CharacterCount.configure({
      limit: props.characterLimit,
    }),
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
  ],
  editorProps: {
    attributes: {
      class: 'ui-editor-content px-12 py-8 overflow-y-auto border-0 outline-none outline-0',
    },
  },
});

const openSetLink = ref(false);
const urlInput = ref('');

function getLink() {
  if (!editor.value)
    return;

  urlInput.value = editor?.value.getAttributes('link').href;
}

function setLink() {
  if (!editor.value)
    return;

  const url = urlInput.value;

  // cancelled
  if (url === null) {
    return;
  }

  // empty
  if (url === '') {
    editor.value.chain().focus().extendMarkRange('link').unsetLink().run();

    return;
  }

  // update link
  editor.value.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
}

function setContent(content: string) {
  if (!editor.value)
    return;

  editor.value.commands.setContent(content);
}

watchOnce(
  () => props.modelValue,
  (value) => {
    if (!!editor.value && !!value) {
      setContent(value);
    }
  },
);

onBeforeUnmount(() => {
  if (!!editor.value) {
    editor.value.destroy();
  }
});

defineExpose({
  setContent,
});
</script>

<template>
  <div class="ui-editor-container">
    <div
      :class="[editorClass]"
      class="ui-editor rounded"
    >
      <section
        v-if="editor"
        class="ui-editor-buttons d-flex flex flex-wrap items-center gap-4 p-8"
      >
        <Tooltip
          content="H1 <span class='text-2xs'>(CTRL + ALT + 1)</span>"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('heading', { level: 1 }) }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            <Heading1 class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          content="H2 <span class='text-2xs'>(CTRL + ALT + 2)</span>"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('heading', { level: 2 }) }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            <Heading2 class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          content="H3 <span class='text-2xs'>(CTRL + ALT + 3)</span>"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('heading', { level: 3 }) }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            <Heading3 class="ui-editor-icon" />
          </button>
        </Tooltip>

        <v-separator class="mx-8 my-4" orientation="vertical" />

        <Tooltip
          :content="`${$t('editor.toolbar.bold')} <span class='text-2xs'>(CTRL + B)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('bold') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <Bold class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          :content="`${$t('editor.toolbar.italic')} <span class='text-2xs'>(CTRL + I)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('italic') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <Italic class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          :content="`${$t('editor.toolbar.underline')} <span class='text-2xs'>(CTRL + U)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('underline') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleUnderline().run()"
          >
            <UnderlineIcon class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          :content="`${$t('editor.toolbar.strike')} <span class='text-2xs'>(CTRL + SHIFT + S)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('strike') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <Strikethrough class="ui-editor-icon" />
          </button>
        </Tooltip>

        <v-separator class="mx-8 my-4" orientation="vertical" />

        <Tooltip
          :content="`${$t('editor.toolbar.orderedList')} <span class='text-2xs'>(CTRL + SHIFT + 7)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('orderedList') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <ListOrdered class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          :content="`${$t('editor.toolbar.bulletList')} <span class='text-2xs'>(CTRL + SHIFT + 8)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('bulletList') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <List class="ui-editor-icon" />
          </button>
        </Tooltip>

        <v-separator class="mx-8 my-4" orientation="vertical" />

        <Tooltip
          :content="`${$t('editor.toolbar.blockquote')} <span class='text-2xs'>(CTRL + SHIFT + B)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('blockquote') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleBlockquote().run()"
          >
            <Quote class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          :content="`${$t('editor.toolbar.code')} <span class='text-2xs'>(CTRL + E)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('code') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleCode().run()"
          >
            <CodeIcon class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip
          :content="`${$t('editor.toolbar.codeBlock')} <span class='text-2xs'>(CTRL + ALT + C)</span>`"
          content-class="rounded-sm px-12 py-4"
          html
        >
          <button
            :class="{ active: editor.isActive('codeBlock') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleCodeBlock().run()"
          >
            <SquareDashedBottomCode class="ui-editor-icon" />
          </button>
        </Tooltip>

        <v-separator class="mx-8 my-4" orientation="vertical" />

        <Tooltip :content="$t('editor.toolbar.alignLeft')" content-class="rounded-sm px-12 py-4">
          <button
            :class="{ active: editor.isActive({ textAlign: 'left' }) }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().setTextAlign('left').run()"
          >
            <AlignLeft class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip :content="$t('editor.toolbar.alignCenter')" content-class="rounded-sm px-12 py-4">
          <button
            :class="{ active: editor.isActive({ textAlign: 'center' }) }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().setTextAlign('center').run()"
          >
            <AlignCenter class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip :content="$t('editor.toolbar.alignRight')" content-class="rounded-sm px-12 py-4">
          <button
            :class="{ active: editor.isActive({ textAlign: 'right' }) }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().setTextAlign('right').run()"
          >
            <AlignRight class="ui-editor-icon" />
          </button>
        </Tooltip>

        <Tooltip :content="$t('editor.toolbar.alignJustify')" content-class="rounded-sm px-12 py-4">
          <button
            :class="{ active: editor.isActive({ textAlign: 'justify' }) }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().setTextAlign('justify').run()"
          >
            <AlignJustify class="ui-editor-icon" />
          </button>
        </Tooltip>

        <v-separator class="mx-8 my-4" orientation="vertical" />

        <Popover v-model:open="openSetLink" @update:open="!$event ? setLink() : getLink()">
          <PopoverTrigger as-child>
            <button
              :class="{ active: editor.isActive('link') }"
              class="btn ui-editor-button rounded-xs border-0 p-4"
              type="button"
            >
              <Tooltip :content="$t('editor.toolbar.link')" content-class="rounded-sm px-12 py-4">
                <LinkIcon class="ui-editor-icon" />
              </Tooltip>
            </button>
          </PopoverTrigger>
          <PopoverContent>
            <div class="d-flex items-center gap-4">
              <v-input-text
                id="editor-link"
                v-model="urlInput"
                :placeholder="$t('editor.toolbar.setUrl')"
                variant="sm"
                @keyup.enter="setLink()"
              />
            </div>
          </PopoverContent>
        </Popover>

        <v-separator class="mx-8 my-4" orientation="vertical" />

        <Tooltip :content="$t('editor.toolbar.clean')" content-class="rounded-sm px-12 py-4">
          <button
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().unsetAllMarks().clearNodes().run()"
          >
            <Eraser class="ui-editor-icon" />
          </button>
        </Tooltip>
      </section>

      <BubbleMenu v-if="editor" :editor="editor" :tippy-options="{ duration: 100 }">
        <div class="bubble-menu bg-light d-flex gap-2 rounded-sm p-4 shadow">
          <button
            :class="{ active: editor.isActive('bold') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <Bold class="ui-editor-icon" />
          </button>

          <button
            :class="{ active: editor.isActive('italic') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <Italic class="ui-editor-icon" />
          </button>

          <button
            :class="{ active: editor.isActive('underline') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleUnderline().run()"
          >
            <UnderlineIcon class="ui-editor-icon" />
          </button>

          <button
            :class="{ active: editor.isActive('strike') }"
            class="btn ui-editor-button rounded-xs border-0 p-4"
            type="button"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <Strikethrough class="ui-editor-icon" />
          </button>
        </div>
      </BubbleMenu>

      <EditorContent :editor="editor" />
    </div>

    <span
      v-if="!!editor?.storage?.characterCount"
      :class="editor?.storage?.characterCount?.characters() === characterLimit ? 'text-danger' : 'text-gray-500'"
      class="d-block ui-editor-chars-limit fw-medium text-sm mt-8"
    >
      {{
        $t('general.text.maxLimit', {
          characters: editor.storage.characterCount.characters(),
          max_characters: characterLimit,
        })
      }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.ui-editor {
  --ui-editor-bg: var(--md-gray-300);

  --ui-editor-icon-color: var(--md-gray-600);

  --ui-editor-button-color: var(--md-gray-700);

  --ui-editor-button-hover-bg: var(--md-gray-400);
  --ui-editor-button-hover-color: var(--md-gray-700);
  --ui-editor-button-active-bg: var(--ui-editor-button-hover-bg);
  --ui-editor-button-active-color: var(--md-gray-900);

  --ui-editor-placeholder-color: var(--md-gray-500);

  background-color: var(--ui-editor-bg);

  &.is-invalid {
    border-color: var(--md-form-invalid-border-color) !important;
    box-shadow: 0 0 0 0.125rem rgba(var(--md-danger-rgb), 0.25);
    background-color: var(--md-danger-extra-light) !important;
  }

  .ui-editor-buttons {
    color: var(--ui-editor-button-color);
  }

  .ui-editor-button {
    transition: background-color 0.15s ease-out, color 0.15s ease-out;

    .ui-editor-icon {
      transition: color 0.15s ease-out;
      width: 1rem;
      height: 1rem;
      color: var(--ui-editor-icon-color);
    }

    &:hover {
      color: var(--ui-editor-button-hover-color);
      background-color: var(--ui-editor-button-hover-bg);

      .ui-editor-icon {
        color: var(--ui-editor-button-hover-color);
      }
    }

    &.active {
      background-color: var(--ui-editor-button-active-bg);

      .ui-editor-icon {
        color: var(--ui-editor-button-active-color);
      }
    }
  }

  :deep(p).is-editor-empty:first-child::before {
    color: var(--ui-editor-placeholder-color);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  :deep(.ui-editor-content) {
    outline: none;
    min-height: 200px;
  }

  :deep(.bubble-menu) {
    border: 1px solid var(--md-gray-400);
  }
}
</style>
