import { SiteStatusEnumConst, SiteStatusEnumKeys } from '@/types/enums/SiteStatusEnum';

export const SiteStatusOwnEnumConst = {
  CONNECTION: 'connection',
  CREATING_SITE: 'creating_site',
} as const;

export type ExtendedSiteStatusEnumKeys = SiteStatusEnumKeys | 'connection' | 'creating_site' | '';

export const SiteStatusProcessEnumArray: ExtendedSiteStatusEnumKeys[] = [
  SiteStatusEnumConst.PENDING,
  SiteStatusEnumConst.REACHED_LOGIN_PAGE,
  SiteStatusEnumConst.LOGIN_IN,
  SiteStatusEnumConst.PLUGIN_LOGGED_IN,
  SiteStatusEnumConst.REACHED_SECONDARY_ACTION_REQUIRED,
  SiteStatusEnumConst.REACHED_PLUGIN_INSTALLATION_PAGE,
  SiteStatusEnumConst.INSTALLING_PLUGIN,
  SiteStatusEnumConst.PLUGIN_INSTALLED,
  SiteStatusEnumConst.SEARCHING_PLUGIN_UPGRADE,
  SiteStatusEnumConst.PLUGIN_UPGRADING,
  SiteStatusEnumConst.PLUGIN_UPGRADED,
  SiteStatusEnumConst.REACHED_ACTIVATION_PAGE,
  SiteStatusEnumConst.PLUGIN_ACTIVATING,
  SiteStatusEnumConst.PLUGIN_ACTIVATED,
  SiteStatusEnumConst.REACHED_PLUGIN_CREDENTIALS_PAGE,
  SiteStatusEnumConst.ESTABLISHED_CREDENTIALS,
];

export const SiteStatusErrorEnumArray: ExtendedSiteStatusEnumKeys[] = [
  SiteStatusEnumConst.LOST,
  SiteStatusEnumConst.UNKNOWN_ERROR,
  SiteStatusEnumConst.GENERIC_ERROR,
  SiteStatusEnumConst.CAPTCHA_DETECTED,
  SiteStatusEnumConst.MAINTENANCE_MODE,
  SiteStatusEnumConst.CANNOT_CONFIRM,
  SiteStatusEnumConst.CANNOT_PLUGIN_INSTALLED,
  SiteStatusEnumConst.CLIENT_ERROR,
  SiteStatusEnumConst.SERVER_ERROR,
  SiteStatusEnumConst.PLUGIN_NOT_FOUND,
  SiteStatusEnumConst.SITES_LIMIT_REACHED,
  SiteStatusEnumConst.REDIRECT_DETECTED,
  SiteStatusEnumConst.UNAUTHORIZED,
];

export const SiteStatusConnectionError: ExtendedSiteStatusEnumKeys[] = [
  SiteStatusEnumConst.PLUGIN_NOT_FOUND,
  SiteStatusEnumConst.MAINTENANCE_MODE,
  SiteStatusEnumConst.INVALID_CREDENTIALS,
  SiteStatusEnumConst.REDIRECT_DETECTED,
  SiteStatusEnumConst.UNAUTHORIZED,
  SiteStatusEnumConst.CLIENT_ERROR,
  SiteStatusEnumConst.SERVER_ERROR,
  SiteStatusEnumConst.SITES_LIMIT_REACHED,
  SiteStatusEnumConst.CANNOT_CONFIRM,
  SiteStatusEnumConst.UNKNOWN_ERROR,
  SiteStatusEnumConst.GENERIC_ERROR,
  SiteStatusEnumConst.INVALID_LOGIN_URI,
  SiteStatusEnumConst.CAPTCHA_DETECTED,
  SiteStatusEnumConst.CANNOT_PLUGIN_INSTALLED,
];
