<script setup lang="ts">
import type { PermissionEnumKeys } from '@/types/PermissionEnum';

import type { HTMLAttributes } from 'vue';
import { cn } from '@/helpers/classNames';

import { can } from '@/helpers/permissionsHelper';
import { Primitive, type PrimitiveProps } from 'radix-vue';

import { buttonVariants, type ButtonVariants } from '.';

defineOptions({
  name: 'MButton',
});

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
  variant: 'default',
  size: 'default',
  class: '',
  type: 'button',
  loading: false,
  disabled: false,
  permission: undefined,
});

const emit = defineEmits(['click']);

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  disabled?: boolean
  permission?: PermissionEnumKeys
}

function eventClick(event: any) {
  if (!props.permission)
    return emit('click', event);

  if (can(props.permission)) {
    emit('click', event);
  }
}
</script>

<template>
  <Primitive
    v-if="!!permission ? can(permission) : true"
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class)"
    :type="type"
    :loading="loading"
    :disabled="disabled"
    @click="eventClick"
  >
    <slot v-if="!loading" />
    <slot v-else-if="$slots.loading" name="loading" />

    <template v-else>
      {{ $t('general.button.loading') }}
    </template>
  </Primitive>
</template>

<style lang="scss" scoped>
.ui-button {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>
