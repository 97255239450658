<script lang="ts">
import usePermissions from '@/composables/usePermissions';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VCheckbox',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    checked: {
      type: String,
      required: false,
      default: null,
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    labelContainerClass: {
      type: String,
      required: false,
      default: '',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: [String, () => null, () => undefined],
      required: false,
      default: '',
    },
    descriptionClass: {
      type: String,
      default: 'text-gray-500 text-sm',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    left: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'checkbox',
      validation: (val: string) => ['checkbox', 'radio', ''].includes(val),
    },
    size: {
      type: String,
      default: '',
      required: false,
      validation: (val: string) => ['sm', 'lg', ''].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    permissionToCheck: {
      type: String,
      required: false,
      default: '',
    },
    callbackWithoutPermission: {
      type: Function,
      required: false,
      default: () => {},
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue', 'blur'],
  setup() {
    const { can } = usePermissions();

    return {
      can,
    };
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  computed: {
    classes() {
      return {
        'md-checkbox-left': this.left && !this.right,
        'md-checkbox-right': this.right,
        'md-checkbox-sm': this.size === 'sm',
        'md-checkbox-lg': this.size === 'lg',
        'md-checkbox-description': !!this.description,
      };
    },
    computedLabelClasses() {
      return {
        'me-8': !!this.description,
      };
    },
    computedName() {
      if (this.name) {
        return this.name;
      }

      return !!this.id ? `check-${this.id}` : '';
    },
    isCheckbox() {
      return this.type === 'checkbox';
    },
    isDisabledByCapabilities() {
      if (this.permissionToCheck) {
        return !this.can(this.permissionToCheck);
      }

      return false;
    },
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal;
      }
    },
  },
  methods: {
    updateValue() {
      if (this.isDisabledByCapabilities && this.callbackWithoutPermission) {
        this.localValue = true;

        this.callbackWithoutPermission();
      } else {
        this.$emit('update:modelValue', this.localValue);
      }
    },
  },
});
</script>

<template>
  <div :class="[classes]" class="md-checkbox">
    <label :class="[computedLabelClasses, labelContainerClass]">
      <input
        v-if="isCheckbox"
        :id="id"
        v-model="localValue"
        :aria-checked="Boolean(localValue)"
        :checked="Boolean(localValue)"
        :disabled="disabled || (isDisabledByCapabilities && !callbackWithoutPermission)"
        :false-value="false"
        :name="computedName"
        :true-value="true"
        type="checkbox"
        @blur="$emit('blur')"
        @change="updateValue"
      >
      <input
        v-else
        :id="id"
        v-model="localValue"
        :class="{ checked: localValue === value }"
        :disabled="disabled || (isDisabledByCapabilities && !callbackWithoutPermission)"
        :value="value"
        type="radio"
        @blur="$emit('blur')"
        @change="updateValue"
      >
      <span :class="[{ 'text-gray-500': disabled }, labelClass]" class="md-checkbox-label">
        <span v-if="!!label">{{ label }}</span>
        <slot v-else name="label" />

        <span v-if="!!permissionToCheck && isDisabledByCapabilities" class="text-success-dark fw-medium ms-8 text-xs">{{
          $t('general.shared.proTag')
        }}</span>
      </span>
    </label>

    <div v-auto-animate>
      <span v-if="!!yupErrorsVariable" class="d-block text-danger text-xs">
        {{ yupErrorsVariable }}
      </span>
    </div>

    <div v-if="!!description" :class="descriptionClass">
      {{ description }}
    </div>
  </div>
</template>
