<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'
import { cn } from '@/helpers/classNames';
import { type BadgeVariants, badgeVariants } from '.'

const props = withDefaults(defineProps<{
  variant?: BadgeVariants['variant']
  class?: HTMLAttributes['class']
  size?: BadgeVariants['size']
  style?: any
}>(), {
  size: 'sm',
  variant: 'default',
  class: '',
  style: '',
});
</script>

<template>
  <div :class="cn(badgeVariants({ variant, size }), props.class)" :style="props.style" class="ui-badge">
    <slot />
  </div>
</template>

<style lang="scss">
.ui-badge {
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  &.disabled {
    color: var(--md-gray-500) !important;
  }
}
</style>
