<script setup lang="ts">
import type { ComboboxGroupProps } from 'radix-vue';
import { cn } from '@/helpers/classNames';
import { ComboboxGroup, ComboboxLabel } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

const props = withDefaults(
  defineProps<
    ComboboxGroupProps & {
      class?: HTMLAttributes['class']
      heading?: string
      spacing?: string
    }
  >(),
  {
    class: '',
    heading: '',
    spacing: 'pt-16',
  },
);

const delegatedProps = computed(() => {
  const { class: _, spacing, ...delegated } = props

  return delegated
})
</script>

<template>
  <ComboboxGroup
    v-bind="delegatedProps"
    :class="cn('ui-combobox-group overflow-hidden', spacing, props.class)"
  >
    <ComboboxLabel v-if="heading" class="px-8 py-2 text-xs font-medium">
      {{ heading }}
    </ComboboxLabel>
    <slot />
  </ComboboxGroup>
</template>
