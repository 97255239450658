import type { RouteRecordRaw } from 'vue-router';

import TheMenuDashboard from '@/components/global/menu/TheMenuDashboard.vue';

const alertingRoutes: Array<RouteRecordRaw> = [
  {
    path: 'alerting',
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'alerting.show',
        components: {
          default: () => import('@/views/alerting/TheAlerting.vue'),
          menu: TheMenuDashboard,
        },
        meta: {
          requiresAuth: true,
        },
        props: {
          default: true,
        },
        children: [
          {
            path: 'create/:alertId?',
            name: 'alerting.create',
            component: () => import('@/views/alerting/TheAlertingCreate.vue'),
            meta: {
              requiresAuth: true,
              isModalView: true,
            },
            props: true,
          },
          {
            path: ':alertId/edit',
            name: 'alerting.edit',
            component: () => import('@/views/alerting/TheAlertingCreate.vue'),
            meta: {
              requiresAuth: true,
              isModalView: true,
            },
            props: route => ({ alertId: route.params.alertId }),
          },
        ],
      },
    ],
  },
];

export default alertingRoutes;

export { alertingRoutes };
