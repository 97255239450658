<script lang="ts" setup>
import type { Vulnerability, VulnerabilityOperator } from '@/types/models/site/service/vulnerabilities/Vulnerability';
import api from '@/api';

import { computed } from 'vue';

defineOptions({
  name: 'TheManagerVulnerabilityItem',
});

const props = defineProps<{
  vulnerability: Vulnerability
  itemName?: string | null
  itemType?: string
  itemSlug?: string
}>();

const vulnerabilitySeverity = computed(() => {
  if (!props.vulnerability) {
    return {
      value: 'unknown',
      text: 'vulnerability.severityShort.unknown',
    };
  }

  const score = props.vulnerability.score;

  if (score !== null) {
    if (score >= 7.0) {
      return {
        value: 'high',
        text: 'vulnerability.severityShort.high',
      };
    } else if (score >= 4.0) {
      return {
        value: 'medium',
        text: 'vulnerability.severityShort.medium',
      };
    } else if (score >= 0.1) {
      return {
        value: 'low',
        text: 'vulnerability.severityShort.low',
      };
    }
  }

  return {
    value: 'unknown',
    text: 'vulnerability.severityShort.unknown',
  };
});

const formattedMaxOperator = computed(() => {
  const operatorMap: { [key in VulnerabilityOperator]: string } = {
    lt: '&lt;',
    le: '&le;',
    gt: '&gt;',
    ge: '&ge;',
    eq: '&eq;',
  };

  if (!props.vulnerability?.maxOperator)
    return '';

  const operator = operatorMap[props.vulnerability.maxOperator] || null;

  if (!!props.vulnerability?.maxVersion && !!operator) {
    return `${operator}${props.vulnerability?.maxVersion}`;
  }

  return '';
});

const formattedMinOperator = computed(() => {
  const operatorMap: { [key in VulnerabilityOperator]: string } = {
    lt: '&lt;',
    le: '&le;',
    gt: '&gt;',
    ge: '&ge;',
    eq: '&eq;',
  };

  if (!props.vulnerability?.minOperator)
    return '';

  const operator = operatorMap[props.vulnerability.minOperator] || null;

  if (!!props.vulnerability?.minVersion && !!operator) {
    return `${operator}${props.vulnerability?.minVersion}`;
  }

  return '';
});

function trackVulnerabilitySource({ sourceName, sourceType }: { sourceName: string, sourceType: string }) {
  let data: { name: string, source: string, type?: string, slug?: string } = {
    name: props.itemName || sourceName,
    source: sourceType,
  };

  if (props.itemType) {
    data = {
      ...data,
      type: props.itemType,
    };
  }

  if (props.itemSlug) {
    data = {
      ...data,
      slug: props.itemSlug,
    };
  }

  api.mixpanel.track('Check vulnerability source', data);
}
</script>

<template>
  <div>
    <div class="fw-medium mb-24 text-sm">
      <p v-if="vulnerability?.sourceName" v-dompurify-html="vulnerability?.sourceName" class="mb-8" />
      <p
        v-if="vulnerability?.sourceDescription"
        v-dompurify-html="vulnerability?.sourceDescription"
        class="text-gray-500"
      />
    </div>

    <ul class="fw-medium list-unstyled mb-24 text-sm">
      <li class="d-flex align-content-center mb-4">
        <span>{{ $t('vulnerability.fields.severity') }}</span>

        <span
          :class="{
            'text-bg-danger-dark': vulnerabilitySeverity.value === 'high',
            'text-bg-tiger': vulnerabilitySeverity.value === 'medium',
            'text-bg-warning-dark': vulnerabilitySeverity.value === 'low',
            'text-bg-gray': vulnerabilitySeverity.value === 'unknown',
          }"
          class="badge d-flex align-items-center text-uppercase lh-1 fw-medium rounded-xs ms-8 text-xs"
        >{{ $t(vulnerabilitySeverity.text) }}
        </span>
      </li>

      <li v-if="!!formattedMinOperator || !!vulnerability?.minVersion || !!formattedMaxOperator" class="mb-4">
        <span>{{ `${$t('vulnerability.fields.affectedVersion')}:` }}</span>
        <span class="ms-8 text-gray-600">
          <span v-if="!!formattedMinOperator" v-html="formattedMinOperator" />
          <span v-else-if="!!vulnerability?.minVersion">
            {{ vulnerability.minVersion }}
          </span>
          <span v-if="(!!formattedMinOperator || !!vulnerability?.minVersion) && !!formattedMaxOperator" class="mx-4">
            {{ $t('general.shared.and') }}
          </span>
          <span v-if="!!formattedMaxOperator" v-html="formattedMaxOperator" />
          <span v-else>-</span>
        </span>
      </li>

      <li v-if="vulnerability?.maxVersion || vulnerability?.unfixed" class="mb-4">
        <span>{{ `${$t('vulnerability.fields.fixedVersion')}:` }}</span>
        <span
          v-if="vulnerability?.unfixed"
          class="text-danger-dark fw-medium ms-8"
        >{{ $t('vulnerability.health.unFixed') }}
        </span>
        <span v-else-if="!!vulnerability?.maxVersion" class="ms-8 text-gray-600">{{ vulnerability.maxVersion }}</span>
        <span v-else-if="vulnerability?.maxVersion" class="ms-8 text-gray-600">{{ vulnerability.maxVersion }}</span>
      </li>

      <li v-if="vulnerability?.score" class="mb-4">
        <span>{{ `${$t('vulnerability.fields.globalScore')}:` }}</span>
        <span class="ms-8 text-gray-600">{{ `${vulnerability.score * 10}/100` }}</span>
      </li>

      <li v-if="false" class="mb-4">
        <span>{{ `${$t('vulnerability.fields.exploitable')}:` }}</span>
        <!-- <span class="text-gray-600 ms-8">{{ `${vulnerability.impactCvss.exploitable * 10}/100` }}</span> -->
      </li>

      <li v-if="false" class="mb-4">
        <span>{{ `${$t('vulnerability.fields.impact')}:` }}</span>
        <!-- <span class="text-gray-600 ms-8">{{ `${vulnerability.impactCvss.impact * 10}/100` }}</span> -->
      </li>
    </ul>

    <p class="mb-0 text-gray-600">
      {{ $t('vulnerability.fields.moreInformation') }}
    </p>

    <ul v-if="vulnerability?.sourceUrl && vulnerability?.sourceType" class="list-unstyled text-sm">
      <li>
        <a
          :aria-label="$t('vulnerability.fields.moreInformationAlt')"
          :href="vulnerability.sourceUrl"
          class="btn btn-link btn-link-primary-dark text-capitalize fw-semi border-0 text-sm"
          rel="noreferrer noopener"
          target="_blank"
          @click="
            trackVulnerabilitySource({
              sourceName: vulnerability.sourceName,
              sourceType: vulnerability.sourceType,
            })
          "
        >{{ vulnerability.sourceType }}</a>
      </li>

      <template v-if="!!vulnerability.relatedVulnerabilities && vulnerability.relatedVulnerabilities.length > 0">
        <li v-for="related in vulnerability.relatedVulnerabilities" :key="related.id">
          <a
            v-if="!!related?.sourceUrl && !!related?.sourceType"
            :aria-label="$t('vulnerability.fields.moreInformationAlt')"
            :href="related.sourceUrl"
            class="btn btn-link btn-link-primary-dark text-capitalize fw-semi border-0 text-sm"
            rel="noreferrer noopener"
            target="_blank"
            @click="
              trackVulnerabilitySource({
                sourceName: related.sourceName,
                sourceType: related.sourceType,
              })
            "
          >{{ related.sourceType }}</a>
        </li>
      </template>
    </ul>
  </div>
</template>
