import { Concept } from '@/types/models/Concept';

export type ServiceTypeEnumKeys = 'uptime' | 'analytics' | 'backup' | 'performance' | 'report' | 'health' | 'screenshot' | 'vulnerability' | 'search_console' | 'connection';

export const ServiceTypeEnumConst = {
  UPTIME: 'uptime',
  ANALYTICS: 'analytics',
  BACKUP: 'backup',
  PERFORMANCE: 'performance',
  REPORT: 'report',
  HEALTH: 'health',
  SCREENSHOT: 'screenshot',
  VULNERABILITY: 'vulnerability',
  SEARCH_CONSOLE: 'search_console',
  CONNECTION: 'connection',
} as const;

export const ServiceTypeEnum: Record<ServiceTypeEnumKeys, Concept> = {
  'uptime': {
    key: 'uptime',
    value: 'uptimeService',
    name: 'concept.service.type.uptimeName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceUptime',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'analytics': {
    key: 'analytics',
    value: 'analyticsService',
    name: 'concept.service.type.analyticsName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceAnalytics',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'backup': {
    key: 'backup',
    value: 'backupService',
    name: 'concept.service.type.backupName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceBackup',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'performance': {
    key: 'performance',
    value: 'performanceService',
    name: 'concept.service.type.performanceName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServicePerformance',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'report': {
    key: 'report',
    value: 'reportServices',
    name: 'concept.service.type.reportName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceReport',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'health': {
    key: 'health',
    value: 'healthService',
    name: 'concept.service.type.healthName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceHealth',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'screenshot': {
    key: 'screenshot',
    value: 'screenshotService',
    name: 'concept.service.type.screenshotName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceScreenshot',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'vulnerability': {
    key: 'vulnerability',
    value: 'vulnerabilityService',
    name: 'concept.service.type.vulnerabilityName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceVulnerability',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'search_console': {
    key: 'search_console',
    value: 'searchConsoleService',
    name: 'concept.service.type.searchConsoleName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceSearchConsole',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  },
  'connection': {
    key: 'connection',
    value: 'connectionService',
    name: 'concept.service.type.connectionName',
    shortName: null,
    description: null,
    class: 'App\Models\SiteServiceConnection',
    color: null,
    icon: null,
    permission: null,
    type: 'service.type'
  }
} as const;

export type ServiceTypeEnumType = (typeof ServiceTypeEnumConst)[keyof typeof ServiceTypeEnumConst];

export const ServiceTypeEnumArray = Object.values(ServiceTypeEnum);
