<script lang="ts" setup>
import type { MIconTypes } from '@/components/vendor/basic/icon/MIcon.vue';
import DateHelper from '@/helpers/dateHelper';

import { computed } from 'vue';
import { RouterLink } from 'vue-router';

defineOptions({
  name: 'NotificationComponent',
});

const props = defineProps<{
  route: object
  title: string
  icon: MIconTypes
  bodyText: string
  createdAt: string
}>();

const { t } = useI18n();

const formattedDate = computed(() => {
  const difference = DateHelper.durationForHumans(props.createdAt, null, true, false, true, true, true);

  return !!difference
    ? t('site.shared.viewedAt', { time: difference })
    : t('site.shared.viewedAt', { time: `1${t('general.shared.minutesShort')}` });
});
</script>

<template>
  <RouterLink :to="route" class="notification-content text-decoration-none">
    <!-- Header -->
    <div class="d-flex mb-4">
      <m-icon :icon="icon" size="xl" class="p-4 me-8 text-gray-500" />

      <span class="notification-header align-self-center d-block text-dark text-decoration-none fw-semi pe-24 text-sm">
        {{ title }}
      </span>
    </div>
    <!-- Body -->
    <span
      v-dompurify-html="bodyText"
      class="notification-body d-block text-dark text-decoration-none fw-medium mb-8 text-xs"
    />
    <!-- Footer -->
    <span class="notification-footer d-flex align-items-center justify-content-between">
      <span class="notification-date fw-medium text-xs text-gray-500">{{ formattedDate }}</span>
      <span class="notification-actions d-flex align-items-center">
        <slot />
      </span>
    </span>
  </RouterLink>
</template>
