import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class TwoFactorService extends AbstractService {
  /**
   * Retrieves the SVG element for the user's two factor authentication QR code.
   * @returns {Promise<any>}
   */
  public retrieveTwoFactorQrCode(): Promise<any> {
    return this.request({
      method: apiEndpoints.twoFactorQrCode.method,
      url: this.buildPath(apiEndpoints.twoFactorQrCode),
    });
  }

  /**
   * Confirm user Two Factor Authentication
   *
   * @param {string} code
   * @returns {Promise<any>}
   */
  public confirmTwoFactorQrCode(code: string): Promise<any> {
    return this.request({
      method: apiEndpoints.twoFactorConfirm.method,
      url: this.buildPath(apiEndpoints.twoFactorConfirm),
      params: { code },
    });
  }

  // TODO ESTE NO APARECE EN ROUTES.TS
  /**
   * Retrieves user Two Factor Authentication recovery codes
   *
   * @returns {Promise<any>}
   */
  public retrieveRecoveryCodes(): Promise<any> {
    console.error('No está!!!!');
    return this.request({
      method: apiEndpoints.twoFactorRecoveryCodes.method,
      url: this.buildPath(apiEndpoints.twoFactorRecoveryCodes),
    });
  }

  /**
   * Creates user Two Factor Authentication recovery codes
   *
   * @returns {Promise<any>}
   */
  public createRecoveryCodes(): Promise<any> {
    return this.request({
      method: apiEndpoints.twoFactorRecoveryCodesStore.method,
      url: this.buildPath(apiEndpoints.twoFactorRecoveryCodesStore),
    });
  }

  /**
   * Enable user Two Factor Authentication
   * @protected
   */
  public enableTwoFactor(): Promise<any> {
    return this.request({
      method: apiEndpoints.twoFactorEnable.method,
      url: this.buildPath(apiEndpoints.twoFactorEnable),
    });
  }

  /**
   * Enable user Two Factor Authentication
   * @param code
   * @protected
   */
  public twoFactorChallenge(code: Record<string, string>): Promise<any> {
    return this.request({
      method: apiEndpoints.twoFactorChallenge.method,
      url: this.buildPath(apiEndpoints.twoFactorChallenge),
      params: code,
    });
  }

  /**
   * Delete user Two Factor Authentication
   * @protected
   */
  public disableTwoFactor(): Promise<any> {
    return this.request({
      method: apiEndpoints.twoFactorDisable.method,
      url: this.buildPath(apiEndpoints.twoFactorDisable),
    });
  }
}
