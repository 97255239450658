import type { ITagsStore } from '@/stores/tag/index';
import type { Tag, TagPayload, TagSyncPayload } from '@/types/models/organization/tag/Tag';
import type { Site } from '@/types/models/site/Site';
import type { Ref } from 'vue';
import api from '@/api';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const useTagsStore = defineStore('tags', (): ITagsStore => {
  const isRequestingData: Ref<boolean> = ref(false);
  const dataRequested: Ref<boolean> = ref(false);
  const loaded: Ref<boolean> = ref(false);
  const tags: Ref<Tag[]> = ref([]);

  const reset = () => {
    isRequestingData.value = false;
    dataRequested.value = false;
    loaded.value = false;
    tags.value = [];
  };

  const addTagToTags = async (tag: Tag) => {
    tags.value.push(tag);
  };

  const createTag = async (data: TagPayload): Promise<Tag> => {
    const response = await api.organization.tags.create(data);

    tags.value.push(response);

    return response;
  };

  const getTags = async () => {
    if (isRequestingData.value) {
      return;
    }

    try {
      isRequestingData.value = true;

      tags.value = await api.organization.tags.all({
        append: ['sites_count'],
        order: { sites_count: 'desc' },
      }) as Tag[];
    } catch (error) {
      console.error('Error fetching sites:', error);
      return undefined;
    } finally {
      isRequestingData.value = false;
      dataRequested.value = true;
    }
  };

  const syncTag = async (tagSynced: Tag, target: Site) => {
    // Save state for Optimistic Update
    const previousTags = [...(target.tags || [])];

    try {
      const syncData: TagSyncPayload = {
        tags: [Number(tagSynced.id)],
      };

      if (!!target.tags) {
        const tagFoundIndex = target.tags?.findIndex((targetTag: Tag) => targetTag.id === tagSynced.id);

        if (tagFoundIndex !== -1) {
          tagSynced.sitesCount -= 1;

          target.tags?.splice(tagFoundIndex, 1);
        } else {
          tagSynced.sitesCount += 1;

          target.tags?.push(tagSynced);
        }
      } else {
        target.tags = [tagSynced];
      }

      await api.site.general.syncTags(target.id, syncData);
    } catch (e: any) {
      console.error(e);

      // Rollback changes if error
      target.tags = previousTags;
    }
  };

  function removeTagFromTags(tagId: string | number) {
    tags.value = tags.value.filter(tag => tag.id !== tagId);
  }

  const hasData = computed(() => !!tags.value && tags.value.length > 0);

  return {
    loaded,
    dataRequested,
    tags,

    hasData,

    reset,
    getTags,
    syncTag,
    createTag,
    addTagToTags,
    removeTagFromTags,
  };
});

export { useTagsStore };

export default useTagsStore;
