export default {
  connectionLost:
    'The connection to WP has been lost on one of your websites. | The connection with WP has been lost on several of your websites.',
  goToUpdates: 'Go to updater',
  connectingLoad: 'connecting...',
  connectionSuccess: '¡Connected!',
  connectionFailed: 'Oh no! Something has gone wrong',
  connectionFailedDesc: 'Opening connection manager...',
  search: {
    noResults: 'No results, try another search',
    typeLeastCharacters: 'Type an email address to continue',
  },
  gridMode: 'Grid mode',
  listMode: 'List mode',
  siteOptions: 'Website options',
  backupError: 'Backup incomplete',
  backupErrorText: 'Your last backup failed',
  reviewBanner: {
    title: 'Do you like our work?',
    text: 'You\'ve been using Modular for a while now... it seems like a good sign, but would you leave us a review in the WordPress repository?',
    link: 'https://wordpress.org/plugins/modular-connector/#reviews',
  },
  emptyState: {
    default: {
      firstSite: 'Connect your first website!',
      firstLoginSite: 'Connect your first WordPress website',
      firstSiteDescription:
        'It will only take a couple of minutes and you can start taking advantage of all Modular features to manage your website.',
      firstLoginSiteTitle:
        'Enter the website data to start the connection process. You can connect any WordPress website you have.',
      connectSite: 'Connect website',
      howToConnect:
        'Find out how to add websites to Modular in <a href="https://support.modulards.com/en/add-website" target="_blank" aria-label="How to add websites to Modular">this help article</a>.',
    },
    tags: {
      noResultTitle: 'Oops! We didn\'t find anything',
      noResultText: 'We couldn\'t find any sites on your Modular that match the filters you applied. Try a different combination.',
    },
  },
};
