import type { Ref } from 'vue';
import { ref } from 'vue';
import useBillingStore from '@/stores/billingStore';
import useOrganizationStore from '@/stores/organizationStore';

import useTagsStore from '@/stores/tag/tagsStore';

import useUserStore from '@/stores/userStore';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

type AppStatusType = 'loading' | 'loaded';

interface IAppStore {
  status: Ref<AppStatusType>
  setStatus: (newStatus: AppStatusType) => void
  init: () => Promise<void>
  reset: () => void
  darkMode: Ref<boolean>
  setDark: (isDark: boolean) => void
}

const useAppStore = defineStore('app', (): IAppStore => {
  const status: Ref<AppStatusType> = ref('loading');

  const router = useRouter();

  const userStore = useUserStore();
  const organizationStore = useOrganizationStore();
  const billingStore = useBillingStore();
  const tagsStore = useTagsStore();

  const darkMode = useStorage('dark-mode', false);

  const reset = () => {
    status.value = 'loading';
  };

  const setStatus = (newStatus: AppStatusType) => {
    status.value = newStatus;
  };

  const setDark = (isDark = false) => {
    const html = document.getElementsByTagName('html')[0];

    darkMode.value = isDark && router.currentRoute.value.meta.theme !== 'light';

    if (darkMode.value) {
      html.dataset.bsTheme = 'dark';
    } else {
      html.dataset.bsTheme = 'light';
    }
  };

  watch(darkMode, (isDark) => {
    setDark(isDark);
  });

  const init = async () => {
    setDark(darkMode.value);

    await Promise.all([
      userStore.login(),
      organizationStore.manager(),
      organizationStore.init(),
      billingStore.getSubscription(),
      tagsStore.getTags(),
    ]);

    setStatus('loaded');
  };

  return {
    reset,
    status,
    setStatus,
    init,
    darkMode,
    setDark,
  };
});

export { useAppStore };

export default useAppStore;
