<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

defineOptions({
  name: 'MProBadge',
});

const props = withDefaults(defineProps<Props>(), {
  class: '',
});

interface Props {
  class?: HTMLAttributes['class']
}
</script>

<template>
  <badge variant="success" size="xs" class="px-8" :class="props.class">
    {{ $t('general.shared.proTag') }}
    <span class="visually-hidden">{{ $t('general.shared.proTag') }}</span>
  </badge>
</template>
