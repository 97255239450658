import type { Organization } from '@/types/models/organization/Organization';
import type { Search } from '@/types/models/organization/search/Search';
import type { PaginatedSites } from '@/types/models/site/Sites';
import type { Team, TeamParams } from '@/types/models/team/Team';
import type { SiteStatusErrorEnumArray } from '@/types/SiteStatusOwnEnum';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export type SitesIncludeFilter =
  'team'
  | 'screenshot'
  | 'backup_service'
  | 'analytics_service'
  | 'uptime_service'
  | 'tags';
export type SitesAppendFilter = 'updatable_items_count' | 'visited_at';

export interface SitesRequestsFilters {
  include?: SitesIncludeFilter[]
  append?: SitesAppendFilter[]
  team?: string[] | number[]
  only?: Array<string> | null
  s?: string
  limit?: number
  order?: Record<string, any> | null
  tags?: number[]
  connection_status?: typeof SiteStatusErrorEnumArray
  connector_min_version?: string
  page?: number
  per_page?: number
  all?: 0 | 1
  included?: number[]
  excluded?: number[]
}

export interface SitesRequestOptions {
  s?: string
  order?: Record<string, any> | null
  filters?: SitesRequestsFilters | null
  only?: Array<string> | null
  tags?: number[]
  page?: number
  per_page?: number
  connection_status?: typeof SiteStatusErrorEnumArray
  connector_min_version?: string
  team?: string[] | number[]
  options?: {
    cancellable?: boolean
    cancelKey?: string
  }
}

export default class OrganizationService extends AbstractService {
  /**
   * Retrieves user organizations
   *
   * @returns {Promise<Team>}
   */
  public retrieve(): Promise<Organization> {
    return this.request({
      method: apiEndpoints.organizationsShowCurrent.method,
      url: this.buildPath(apiEndpoints.organizationsShowCurrent),
    });
  }

  /**
   * Returns all the existing sites where the logged user is member.
   *
   * @returns {Promise<PaginatedSites>}
   * @param params
   */
  public sites(params: SitesRequestOptions): Promise<PaginatedSites> {
    const filters: SitesRequestsFilters = {
      append: ['visited_at'],
    };

    if (params.filters?.include) {
      filters.include = params.filters.include;
    }

    if (params.filters?.append) {
      filters.append = [...(filters.append || []), ...params.filters.append];
    }

    if (params.s)
      filters.s = params.s;
    if (params.order)
      filters.order = params.order;
    if (params.team)
      filters.team = params.team;
    if (params.connection_status)
      filters.connection_status = params.connection_status;
    if (params.connector_min_version)
      filters.connector_min_version = params.connector_min_version;
    if (params.page)
      filters.page = params.page;
    if (params.per_page)
      filters.per_page = params.per_page;
    if (params.tags)
      filters.tags = params.tags;
    if (params.only)
      filters.only = params.only;

    if (!!params.filters?.included && !!params.filters?.excluded) {
      filters.all = params.filters.all;

      if (params.filters.included.length > 0)
        filters.included = params.filters.included;
      if (params.filters.excluded.length > 0)
        filters.excluded = params.filters.excluded;
    }

    const options = {
      cancellable: params.options?.cancelKey || false,
      cancelKey: params.options?.cancelKey || 'organizationSitesKey',
      paginate: true,
    };

    return this.request({
      method: apiEndpoints.sitesIndex.method,
      url: this.buildPath(apiEndpoints.sitesIndex),
      params: filters,
      options: options as any,
    });
  }

  /**
   * Returns the count of all the existing sites where the logged user is member.
   *
   * @returns {Promise<number>}
   */
  public sitesSelectionCount(params: {
    all?: boolean
    included?: number[]
    excluded?: number[]
    only?: string[]
    connection_status?: string[]
    connector_min_version?: string
  }): Promise<number> {
    const processedParams: Record<string, any> = {};

    if (params.all !== undefined)
      processedParams.all = params.all ? 1 : 0;
    if (params.included && params.included.length > 0)
      processedParams.included = params.included;
    if (params.excluded && params.excluded.length > 0)
      processedParams.excluded = params.excluded;
    if (params.only && params.only.length > 0)
      processedParams.only = params.only;
    if (params.connection_status && Object.keys(params.connection_status).length > 0) {
      processedParams.connection_status = params.connection_status;
    }
    if (params.connector_min_version)
      processedParams.connector_min_version = params.connector_min_version;

    const requestParams: Record<string, any> = {
      ...(Object.keys(processedParams).length > 0 && { params: processedParams }),
    };

    return this.request({
      method: apiEndpoints.sitesSelectionCount.method,
      url: this.buildPath(apiEndpoints.sitesSelectionCount),
      ...requestParams,
      options: {
        cancellable: true,
        cancelKey: 'sites-selector-count',
      },
    });
  }

  /**
   * Returns all the existing teams where the logged user is member.
   *
   * @returns {Promise<Team[]>}
   */
  public async teams(params?: TeamParams): Promise<Team[]> {
    let localParams: TeamParams = { ...params };

    if (!params) {
      localParams = {
        append: ['sites_count'],
        order: {
          name: 'asc',
        },
      };
    }

    return await this.request({
      method: apiEndpoints.teamsIndex.method,
      url: this.buildPath(apiEndpoints.teamsIndex),
      params: localParams,
    });
  }

  /**
   * Returns the results of a global search.
   *
   * @param params
   * @returns {Promise<Search>}
   */
  public search(params: any): Promise<Search> {
    return this.request({
      method: apiEndpoints.organizationsSearch.method,
      url: this.buildPath(apiEndpoints.organizationsSearch),
      params,
      options: {
        cancellable: true,
        cancelKey: 'searchBox',
      },
    });
  }
}
