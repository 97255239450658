<script lang="ts" setup>
import { useSiteCreate } from '@/composables/site/useSiteCreate';

import { useTeamCreate } from '@/composables/team/useTeamCreate';
import { PermissionEnumConst } from '@/types/PermissionEnum';

import { useRouteQuery } from '@vueuse/router';
import { onMounted } from 'vue';

defineOptions({
  name: 'DashboardWidgetActionButtons',
});

defineProps<Props>();

interface Props {
  enableCreateTeam?: boolean
  enableCreateSite?: boolean
}

const { TEAMS_STORE, SITES_STORE } = PermissionEnumConst;

const modalName = useRouteQuery('modal');

const { handleCreateSite } = useSiteCreate();

const { createTeam } = useTeamCreate();

onMounted(() => {
  if (modalName.value === 'site-create') {
    handleCreateSite('Dashboard header', true);
  }
});
</script>

<template>
  <div id="action-buttons" class="d-flex align-items-center me-16">
    <slot name="title" />

    <m-button
      v-if="enableCreateTeam"
      id="header-create-team"
      :permission="TEAMS_STORE"
      data-cy="btn-header-create-team"
      size="2xs"
      class="me-8"
      @click="createTeam"
    >
      <v-icon class="me-4" icon="plus" size="4xs" />
      <span>{{ $t('team.shared.team') }}</span>
    </m-button>

    <m-button
      v-if="enableCreateSite"
      id="header-create-site"
      :permission="SITES_STORE"
      data-cy="btn-header-create-site"
      size="2xs"
      class="me-8"
      @click="handleCreateSite('Dashboard header')"
    >
      <v-icon class="me-4" icon="plus" size="4xs" />
      <span>{{ $t('site.shared.site') }}</span>
    </m-button>
  </div>
</template>
