import type { ReportItemUpdateRequest } from '@/api/services/site/service/report/manual';
import type {
  ScheduledReportIndexRequest,
  ScheduledReportNextRequestParams,
  ScheduledReportUpdateEmailRequest,
  ScheduledReportUpdateRequest,
} from '@/api/services/site/service/report/schedule/index';
import type { SimpleResponse } from '@/types/Api';
import type { SiteReportItemTypeEnumKeys } from '@/types/enums/SiteReportItemTypeEnum';
import type { SiteScheduledReport } from '@/types/models/site/service/report/SiteScheduledReport';

import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class scheduledReportService extends AbstractService {
  /**
   * Retrieves all Schedule Reports for the current site.
   *
   * @param {ScheduledReportIndexRequest} params
   * @returns {Promise<SiteScheduledReport[]>} A promise that resolves to an array of scheduled reports.
   */
  public all(params: ScheduledReportIndexRequest): Promise<SiteScheduledReport[]> {
    return this.request({
      method: apiEndpoints.reportServicesIndex.method,
      url: this.buildPath(apiEndpoints.reportServicesIndex),
      params,
    });
  }

  /**
   * Retrieves a specific Schedule Report.
   *
   * @param {number} reportId
   * @returns {Promise<SiteScheduledReport>} A promise that resolves to the retrieved scheduled report.
   */
  public retrieve(reportId: number): Promise<SiteScheduledReport> {
    return this.request({
      method: apiEndpoints.reportServicesShow.method,
      url: this.buildPath(apiEndpoints.reportServicesShow, {
        report_service: reportId,
      }),
    });
  }

  /**
   * Calculates the next scheduled requests based on the provided schedule and limit.
   *
   * @param {Record<string, any>} data
   * @returns {Promise<string[]>} A promise that resolves to an array of next scheduled request dates.
   */
  public calculateNextRequest(data: ScheduledReportNextRequestParams): Promise<string[]> {
    return this.request({
      method: apiEndpoints.reportServicesCalculateNextRequests.method,
      url: this.buildPath(apiEndpoints.reportServicesCalculateNextRequests),
      params: data,
    });
  }

  /**
   * Creates a new Schedule Report
   *
   * @param {number} siteId
   * @returns {Promise<SiteScheduledReport>} A promise that resolves to the created scheduled report.
   */
  public create(siteId: number): Promise<SiteScheduledReport> {
    return this.request({
      method: apiEndpoints.reportServicesStore.method,
      url: this.buildPath(apiEndpoints.reportServicesStore),
      data: {
        site: siteId,
      },
    });
  }

  /**
   * Updates a specific Schedule Report.
   *
   * @param reportId
   * @param {Record<string, any>} data
   * @returns {Promise<SiteScheduledReport>} A promise that resolves to the updated scheduled report.
   */
  public update(reportId: number, data: ScheduledReportUpdateRequest): Promise<SiteScheduledReport> {
    return this.request({
      method: apiEndpoints.reportServicesUpdate.method,
      url: this.buildPath(apiEndpoints.reportServicesUpdate, {
        report_service: reportId,
      }),
      data,
    });
  }

  /**
   * Updates the email data of a Schedule Report
   *
   * @param {number} reportId
   * @param {ScheduledReportUpdateEmailRequest} data
   * @returns {Promise<SiteScheduledReport>} A promise that resolves to the updated scheduled report with email data.
   */
  public updateEmail(reportId: number, data: ScheduledReportUpdateEmailRequest): Promise<SiteScheduledReport> {
    return this.request({
      method: apiEndpoints.reportServicesUpdateEmail.method,
      url: this.buildPath(apiEndpoints.reportServicesUpdateEmail, {
        report_service: reportId,
      }),
      data,
    });
  }

  /**
   * Toggles the status of a specific item in a Schedule Report.
   *
   * @param {number} reportId
   * @param {string} type
   * @returns {Promise<SiteScheduledReport>} A promise that resolves to the updated scheduled report with toggled item status.
   */
  public toggleItemStatus(reportId: number, type: string): Promise<SiteScheduledReport> {
    return this.request({
      method: apiEndpoints.reportServicesItemsStatusToggle.method,
      url: this.buildPath(apiEndpoints.reportServicesItemsStatusToggle, {
        report_service: reportId,
        type,
      }),
    });
  }

  /**
   * Toggles the status of a Schedule Report.
   *
   * @param {number} reportId
   * @returns {Promise<any>} A promise that resolves to the result of the status toggle operation.
   */
  public toggleStatus(reportId: number): Promise<any> {
    return this.request({
      method: apiEndpoints.reportServicesStatusToggle.method,
      url: this.buildPath(apiEndpoints.reportServicesStatusToggle, {
        report_service: reportId,
      }),
    });
  }

  /**
   * Updates a specific item in a Schedule Report.
   *
   * @param {number} reportId
   * @param {SiteReportItemTypeEnumKeys} type
   * @param {ReportItemUpdateRequest} data
   * @returns {Promise<SiteScheduledReport>} A promise that resolves to the updated scheduled report item.
   */
  public updateItem(
    reportId: number,
    type: SiteReportItemTypeEnumKeys,
    data: ReportItemUpdateRequest,
  ): Promise<SiteScheduledReport> {
    return this.request({
      method: apiEndpoints.reportServicesItemUpdate.method,
      url: this.buildPath(apiEndpoints.reportServicesItemUpdate, {
        report_service: reportId,
        type,
      }),
      data,
    });
  }

  /**
   * Deletes specific Manual Reports.
   *
   * @param {number[]} reportIds
   * @returns {Promise<SimpleResponse>} A promise that resolves to a simple response indicating the result of the deletion.
   */
  public delete(reportIds: number[]): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.reportServicesDestroy.method,
      url: this.buildPath(apiEndpoints.reportServicesDestroy),
      data: {
        services: reportIds,
      },
    });
  }
}
