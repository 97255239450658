export default {
  tags: 'Tags',
  tag: 'Tag',
  assignColor: 'Assign color',
  tagName: 'Tag name',
  searchTag: 'Search tag',
  notFound: 'No results found',
  nameErrorText: 'A tag with this name already exists',
  maxCharError: 'Maximum {max} characters',
  create: 'Create a tag',
};
