export default {
  shared: {
    connected: 'Conexión establecida',
    lost: 'Conexión perdida',
    siteImage: 'Imagen de {site}',
    site: 'Web',
    createdSites: 'Creados',
    showRecents: 'Ver todos',
    viewedAt: 'Hace {time}',
    numUpdates: '{num} actualización | {num} actualizaciones',
    numAvailable: '{num} disponible | {num} disponibles',
    seeDetail: 'Ver detalle',
    web: 'Web',
    performance: 'Rendimiento',
    updates: 'Actualizaciones',
    updatesErrors: 'Actualizaciones/Errores',
    backup: 'Backups',
    wordpress: 'WordPress',
    connectionStatus: 'Estado de conexión',
  },
  edit: {
    settings: 'Ajustes de la web',
    delete: 'Eliminar web',
    reconnectSite: 'Reconectar web',
    refreshThumbnail: 'Regenerar miniatura',
    successRefreshThumbnail: 'Estamos regenerando la miniatura. Puede tardar unos minutos.',
  },
  settings: {
    config: 'Configuración de la web',
    title: 'Ajustes de {name}',
    nameConnection: 'Nombre y conexión',
    nameConnectionText:
      'Personaliza el nombre de tu web, el equipo al que pertenece o reconéctala en caso de ser necesario.',
    websiteName: 'Nombre',
    tags: 'Etiquetas',
    tagsText:
      'Modifica las etiquetas de tu web para aprovechar los filtros en el actualizador o la página de todos tus sitios.',
  },
  connect: {
    title: 'Conecta tu web de WordPress',
    uri: 'Url pública de la web: miweb.com',
    accessUri: 'Url de acceso',
    cancelConnection: 'Cancelar conexión',
    unableToConnect:
      '<p class="mb-32">Descubre las razones más comunes y cómo solucionarlas en <a id="help" target="_blank" rel="noreferrer noopener" href="https://soporte.modulards.com/es/error-conexion-web">{helpText}</a>.</p> <p class="mb-0">Si el problema persiste te agradeceríamos mucho que nos escribas a <a id="email" href="mailto:{email}">{email}</a> para que te ayudemos a solucionarlo.</p>',
    helpText: 'este artículo',
    failedConnection: 'Fallo en conexión',
    connectError: 'Error de conexión: {error}',
    auto: 'Automático',
    manual: 'Manual',
    invalidCredentialsTitle: 'Las credenciales no son correctas',
    invalidCredentialsText: 'Introduce un usuario y contraseña válidos.',
    invalidLoginUrlTitle: 'La URL no es correcta',
    invalidLoginUrlText:
      'Si utilizas una URL de acceso distinta (ej: .../mi-panel) asegúrate de introducir correctamente la información de la web.',
    credentials: 'Credenciales',
    loginDataMessage: 'IMPORTANTE: Modular no almacenará tus credenciales.',
    loginFormCustomuriPlaceholder: 'wp-admin',
    customUri: 'URL personalizada',
    manualConnectionTitle: 'Para conectar tu web de forma manual, sigue estos pasos:',
    manualFirstStep: '<a href="{link}" target="_blank" rel="noreferrer noopener">Descarga nuestro plugin oficial</a>.',
    manualSecondStep: 'En la página "Plugins" en WordPress, haz clic en "Añadir nuevo" y súbelo.',
    manualThirdStep: 'Accede en el menú de WordPress a "Herramientas > Modular - Gestión de conexión".',
    manualFourthStep: 'Pega en el lugar correspondiente las claves pública y privada que encontrarás aquí abajo.',
    manualFifthStep: 'Guarda los cambios en tu web.',
    manualSixthStep: 'Haz clic en el botón de "Conectar" aquí abajo.',
    publicKey: 'Clave pública',
    copyCode: 'Copiar código',
    privateKey: 'Clave privada',
    showPrivateKey: 'Generar clave privada',
    testConnection: 'Comprobar conexión',
    connecting: 'Vamos allá, estableciendo conexión...',
    loggedIn: '¡Conexión establecida! Vamos a instalar Modular Connector...',
    pluginInstalled: 'Hemos instalado correctamente Modular Connector, continuemos...',
    pluginReplaced: 'Hemos actualizado una versión antigua de Modular Connector, continuemos...',
    pluginActivated: '¡Plugin activado! Estamos comprobando la conexión...',
    establishedCredentials: 'Estamos comprobando que todo ha ido correctamente, ¡Estará listo en un momento!',
    siteConnected: '¡Tu web ha sido conectada!',
    successConnection: 'Conexión completa',
    captchaDetected: 'Hemos detectado un posible catpcha o un plugin de seguridad nos ha bloqueado.',
    maintenanceMode: 'Modo mantenimiento activado en tu web, vuelve a intentarlo más tarde.',
    cannotConfirm: 'No se ha podido validar la conexión.',
    sitesLimitReached: 'Has superado el número máximo de webs conectadas con tu plan de Modular.',
    cannotPluginInstalled: 'No se ha podido instalar Modular Connector. Por favor, prueba con el método manual.',
    siteConnectedFailure: 'No hemos podido conectar tu web.',
    genericError: 'No se ha podido establecer la conexión.',
    urlInfo:
      'Si tu url de acceso es la estándar de WordPress no pongas nada aquí.<br/>Cámbiala solo si usas una url personalizada.',
    uriErrorMessage: 'Introduce una url correcta',
  },
  create: {
    title: 'Introduce los datos de la web',
    uri: 'Url pública de la web: miweb.com',
    name: 'Nombre de la web',
    team: 'Selecciona un equipo',
    urlFormat: 'Debe tener el formato correcto de URL',
    created: 'Web creada',
    nextDesign: 'Próximamente',
    connectSite: 'Conecta tu web de WordPress',
    new: 'Conecta tu web de WordPress',
    notValidUrl: 'La URL no es válida o ya existe un sitio con esta URL.',
    notValidUrlFormat: 'La URL no tiene un formato válido.',
    validatingUrl: 'Estamos verificando que la URL es válida...',
    addToExistingTeam: 'Añadir a un equipo existente',
    addToNewTeam: 'Añadir a nuevo equipo',
  },
  overview: {
    metrics: {
      title: 'Analytics',
      since: 'Últimos {date_range} días',
      googleAnalytics: 'Google Analytics',
      googleAnalyticsDescription: 'Conecta la cuenta de Google Analytics para empezar a ver estadísticas de tu web.',
      googleAnalyticsConnect: 'Conectar Analytics',
    },
    updates: {
      updates: 'Actualizaciones',
      reload: 'Recargar',
      updateAll: 'Actualizar todo',
      plugin: 'Plugin',
      plugins: 'Plugins',
      theme: 'Tema',
      themes: 'Temas',
      translation: 'Traducción',
      translations: 'Traducciones',
      wordpress: 'WordPress',
      totalItems: '{name} ({total})',
      selectedUpdates: '1 Seleccionada | {total} Seleccionada | {total} Seleccionadas',
      noUpdates: '¡Nada que actualizar!',
      update: 'actualizar',
      itemsUpdated: '{name} actualizados',
      wordPressUpdated: 'Versión actualizada',
      showNumberMore: 'Ver {num} más',
      showLess: 'Ver menos',
      showMore: 'Ver más',
      noPendingUpdates: 'No hay actualizaciones pendientes',
      noPendingUpdatesText: 'De momento todo corriendo sin novedad.',
      noHiddenUpdates: 'No tienes actualizaciones ocultas',
      noGlobalHiddenUpdatesText:
        'Aprende sobre las actualizaciones ocultas <a href="https://soporte.modulards.com/es/actualizador-global?=1#:~:text=otras%20sin%20actualizar.-,Ocultar%20actualizaciones,-Si%20prefieres%20no" target="_blank" rel="noreferrer noopener" aria-label="Soporte">aquí</a>.',
      noHiddenUpdatesText:
        'Aprende sobre las actualizaciones ocultas <a href="https://soporte.modulards.com/es/actualizador-web#:~:text=refrescar%20la%20información.-,Ocultar%20actualizaciones,-Si%20prefieres%20no" target="_blank" rel="noreferrer noopener" aria-label="Soporte">aquí</a>.',
      cannotConnectSite:
        'Se ha perdido la conexión de tu web con Modular. Vuelve a conectar para acceder a todas las funcionalidades.',
      cannotUpdateData: 'Se ha perdido la conexión con tu web. Vuelve a conectar para ver esta información.',
      cannotUpdateDataText:
        'Descubre las razones más comunes y cómo solucionarlo en <a href="https://soporte.modulards.com/es/error-conexion-web" target="_blank" rel="noreferrer noopener" aria-label="Soporte">este artículo</a>. Si el problema persiste te agradeceríamos mucho que nos escribas a <a href="mailto:{email}" aria-label="Contacta por email">{email}</a> para que te ayudemos a solucionarlo.',
      updateSelection: 'Actualizar selección',
      viewChangelog: 'Ver changelog',
      actualVersion: 'versión actual: V{ version }',
      notChangelogFound:
        'No hemos podido encontrar el changelog de este plugin. Es posible que se trate de un plugin que no está en el repositorio de WordPress.',
      notVulnerabilitiesFound:
        'No hemos podido encontrar la información de las vulnerabilidades. Inténtalo de nuevo más tarde o ponte en contacto con nosotros.',
    },
    lighthouse: {
      mobile: 'Móvil',
      desktop: 'Ordenador',
      estimateReport:
        'Los valores son estimaciones y pueden variar. El test se realiza de forma automática cada 10-15 días, pero puedes hacerlo ahora en este link.',
      testNow: 'Haz el test ahora',
      performance: 'Rendimiento',
      updatedAt: 'Actualizado el {date}',
      noInformation: 'Sin información',
      viewDetails: 'ver detalle',
      mayLastDays: 'Puede tardar 5-10 minutos',
      calculating: 'Calculando reporte...',
      tooltipInfo: 'El test se realiza de forma automática cada 10-15 días.',
    },
    backups: {
      title: 'Copias de seguridad',
      lastBackup: 'Última copia',
      lastBackupDate: 'Última copia {date}',
      lastBackups: 'Últimas copias realizadas',
      createBackupNow: 'Hacer Backup ahora',
      nextBackup: 'Próximo backup en {date}',
      activateBackup: 'Activar Backups',
      configureBackup: 'Configurar Backups',
      configureBackupLong: 'Configurar Copias de Seguridad',
      noBackups: 'Configura las copias de seguridad para tu web, prográmalas y recupéralas cuando las necesites.',
      availableSpace: 'Espacio disponible',
      increase: 'Aumentar',
      inProgress: 'En progreso',
    },
    note: {
      createNote: 'Añade una nota',
      noteOfSite: 'Notas de {name}',
      noteOfSitePlaceholder: 'Escribe una nota para recordar algo importante sobre esta web, precauciones, etc.',
      deleteSiteNote: 'Borrar el contenido de la nota',
      deleteSiteNoteText: '¿Estás seguro que quieres borrar el contenido de la nota de esta web?',
      deleteSiteNoteSuccess: 'Nota borrada',
      saveSiteNoteSuccess: 'Nota guardada',
    },
    health: {
      criticalErrors: 'Errores críticos',
      noCriticalErrors: 'Sin errores críticos',
      checkSite: 'Comprobando el sitio...',
      notAvailable: 'No disponible',
    },
    searchConsole: {
      title: 'Search Console',
      description:
        'Conecta una propiedad de Search Console para empezar a ver el rendimiento de tu web en los resultados de búsqueda.',
      connect: 'Conectar Search Console',
    },
  },
  sidebar: {
    addCategory: 'Para agregar o editar categorías puedes hacerlo',
    here: 'aquí',
    featuredImage: 'Imagen destacada',
    featuredImageText: 'Imagen principal de la entrada. Es la que aparece como miniatura.',
    setFeaturedImage: 'Establecer la imagen destacada',
    deleteFeaturedImage: 'Eliminar imagen destacada',
    status: 'Estado',
    schedule: 'Programar',
    visibility: 'Visibilidad',
    public: 'Público',
    protected: 'Protegido',
    private: 'Privado',
    straightway: 'Inmediatamente',
    deletePost: 'Borrar entrada',
    saveAs: 'Guardar como: {status}',
    successPublish: 'Tu nuevo post se ha guardado con éxito',
    successDeletePost: 'La publicación se ha borrado con éxito',
    uptimeMonitor: 'Uptime Monitor',
    uptimeMonitorDescription: 'Recibe notificaciones por email o SMS cuando tu web esté caída.',
    activateFree: 'Actívalo gratis',
    uptimeStatus: 'Uptime is {percentage}%',
    latestEvents: 'Últimos eventos',
    maintenanceReports: 'Reportes de mantenimiento',
    generateReport: 'Generar reporte',
    createReport: 'Crear reporte',
    next: 'próximo',
    lastReports: 'Últimos reportes',
  },
  uptime: {
    shared: {
      hours: '1 hora | { hours } horas',
      minutes: '1 minuto | { minutes } minutos',
      seconds: '1 segundo | { seconds } segundos',
      days: '1 día | { days } días',
      byDate: 'por fecha',
      shortHours: '{ hours }h',
      shortMinutes: '{ minutes }m',
      shortSeconds: '{ seconds }s',
      shortDays: '{ days }d',
      months: '1 mes | { number } meses',
      lastDays: 'Último día | Últimos { number } días',
      noActivity: 'No hay actividad',
      uptimeEnabled: 'Uptime activado',
      checkDateDuration: '{ start } durante { duration }',
      checkStatusDurationDaysHours: '{ status } desde { days }d { hours }h { minutes }m',
      checkStatusWithoutStatus: 'Up desde { time }',
      checkStatusSSLValid: 'en { time } días',
      checkStatusSSLInvalid: 'hace { time } días',
      checkNoChanges: 'El uptime aún no ha generado cambios',
      action: 'Action',
      deactivateUptimeMonitorTitle: 'Desactivar Uptime Monitor',
      deactivateUptimeMonitorText:
        'Si haces clic en confirmar se desactivará la monitorización de estado de tu web y el estado pasará a pausado. En caso de querer activarla de nuevo, siempre podrás hacerlo desde la configuración de Uptime Monitor.',
    },
    activity: {
      emptyYTLink: 'https://www.youtube.com/embed/daurPYTPiXQ',
      uptimeDescription:
        'La monitorización de Uptime te ayuda a enterarte rápidamente cuando tu web sufre un problema y los usuarios no pueden acceder a alle enviándote una notificación vía email o SMS.  Además, podrás ver la actividad de la web durante los últimos días o semanas para saber en qué momentos se produjeron caídas.',
      uptimeTitle1: 'Olvídate de situaciones incómodas con tu cliente',
      uptimeReason1:
        '¿Has sufrido ese momento en el que un cliente te avisa de que su web no funciona? Gracias a la monitorización de Uptime te enterarás en el momento que haya un problema para solucionarlo antes de que se agrave.',
      uptimeTitle2: 'Entérate cuando falle y cuando se recupere',
      uptimeReason2:
        'Cuando su web se rompa, recibirá inmediatamente una alerta con mensajes de error, estado HTTP, cambios de contenido y mucho más. Y cuando la web vuelva a estar funcionando, recibirás otra notificación.',
      uptimeTitle3: 'Conoce el estado en todo momento',
      uptimeReason3:
        'Modular te ofrece la infraestructura para monitorizar la disponibilidad de su web cada minuto durante todos los días del año. Además, en las gráficas incorporadas podrás ver la disponibilidad de tu web durante el último mes.',
      uptimeTitle4: 'Conoce la causa del problema',
      uptimeReason4:
        'Siempre que detectamos un error realizamos pruebas adicionales para ayudarte a identificar la causa de la interrupción, de modo que pueda evitar que se repita.',
    },
    configuration: {
      title: 'Configuración',
      interval: 'Intervalo',
      intervalText: 'Determina cada cuando comprobaremos la disponibilidad de tu web.',
      minutesInterval: '{ number } minuto | { number } minutos',
      keywordMonitoring: 'Palabras clave',
      keywordMonitoringText:
        'Tu web se considerará caída cuando en una comprobación no aparezcan todas las palabras definidas. Pueden ser palabras o frases. Es insensible a mayúsculas y minúsculas.',
      enterKeywords: 'Introducir palabras',
      keywordsExample: 'EJ: lorem, ipsum, dolor, sit ...',
      keywordsRule: 'separa por comas las palabras clave',
      keywordDuplicatedError: 'La palabra ya está en la lista',
      uptimeNotification: 'Notificaciones',
      uptimeNotificationText: 'Recibe notificaciones cuando tu web está caída.',
      uptimeNotificationDelayText:
        'Puedes determinar el tiempo hasta que enviamos una notificación cuando detectamos que tu web está caída.',
      emailsEntered: 'Emails introducidos',
      emailsExample: 'EJ: lucas{\'@\'}modular.es, pablo{\'@\'}modular.es ...',
      emailDuplicatedError: 'El correo ya está en la lista',
      emailsRule: 'separa por comas los emails',
      emailNotificationTimeout: 'Notificar después de',
      emailNotificationTimeoutText:
        'Si la web vuelve a responder antes de que te enviemos la notificación,<br/> se añadirá en el registro pero no te enviaremos una notificación.<br/> Esto es útil para evitar que te enviemos notificaciones, por ejemplo,<br/> si tu web está caída temporalmente por una actualización de WordPress.',
      phoneNotificationText:
        'Con los planes PRO podrás añadir un número de teléfono para que te notifiquemos vía SMS en caso de que haya algún problema con tu web.',
      enterPrefix: 'Prefijo',
      phones: 'Teléfonos',
      enterPhone: 'Introducir teléfono',
      disableUptime: 'Desactivar Uptime',
      removeUptimeAdvice: '¿Seguro que deseas desactivar el uptime de la web { site }?',
      timeoutSettingsTitle: 'Considerar web caída tras...',
      timeoutSettingsText:
        'Al reducir este valor, establece un umbral para el tiempo de respuesta. Tu web se considera caída cuando supere el valor seleccionado sin responder.',
      sslTitle: 'Monitorización de SSL',
      sslText: 'Recibe alertas cuando vaya a caducar el certificado de seguridad (SSL) de tu página web.',
      sslWhenReceiveAlerts: '¿Cuándo quieres recibir las notificaciones?',
      sslActivateNotifications: 'Es necesario tener activadas las notificaciones',
      alerting: {
        alertText:
          'Esta configuración ya no es válida, por favor haz <a href="{link}" target="_blank" class="text-tiger-900">clic aquí</a> para acceder al panel de notificaciones y poder cambiarla o eliminarla.',
        empty: {
          title: 'No tienes ninguna configuración de notificaciones asignada',
          description: 'Crea una configuración nueva o asigna una existente a esta web.',
          configureNotifications: 'Configurar notificaciones',
        },
      },
      alertingAlert: {
        //  FIXME: This is temporal alert, only when is configured uptime notifications and not alerting
        title: 'Al hacer clic en confirmar, se eliminará la configuración de notificaciones para esta web',
        text: 'Para recibir notificaciones de Uptime Monitor, asigna una configuración de notificaciones a esta web desde la sección de notificaciones.',
      },
    },
    ssl: {
      sslKeyName: 'SSL',
      sslProperty: 'Propiedad',
      sslValue: 'Valor',
      cerfiticateDetails: 'Detalles del certificado',
      sslValidFrom: 'Valido desde',
      sslValidTo: 'Valido hasta',
      sslError: 'Error',
      sslSubject: 'Sujeto',
      sslSubjectCN: 'Nombre común (CN)',
      sslSubjectO: 'Organización (O)',
      sslSubjectC: 'Unidad organizativa (OU)',
      sslIssuer: 'Emisor',
      sslIssuerCN: 'Nombre común (CN)',
      sslIssuerO: 'Organización (O)',
      sslIssuerC: 'Unidad organizativa (OU)',
      sslFingerprintSha1: 'Huella digital SHA1',
      sslFingerprintSha256: 'Huella digital SHA256',
      sslFingerprintSha512: 'Huella digital SHA512',
      sslSerialNumber: 'Número de serie',
    },
  },
  backup: {
    title: 'Copias de seguridad',
    createBackup: 'Hacer Backup',
    createNewBackup: 'Crear Backup ahora',
    createNewBackupTitle: '¿Quieres hacer una copia de seguridad en este momento?',
    createNewBackupDescription:
      'La copia de seguridad cogerá la configuración que tengas guardada para esta web en la pestaña de configuración. Puedes revisarlo antes por si acaso.',
    totalBackups: '1 copia realizada | { number } copias realizadas',
    backupCreatedMessage: 'Tu copia de seguridad está siendo procesada...',
    deactivateBackupRecurrenceTitle: 'Desactivar backups automáticos',
    deactivateBackupRecurrenceText:
      'Si haces clic en confirmar se pararán los backups recurrentes para esta web. En caso de querer activarlos de nuevo, siempre podrás hacerlo desde la configuración de backups de la web.',
    deleteBackupTitle:
      '¿Quieres eliminar esta copia de seguridad? | ¿Quieres eliminar todas las copias de seguridad selecionadas?',
    deleteBackupText:
      'Si eliminas esta copia de seguridad de Modular se perderán los datos y no podrás recuperarla desde nuestra herramienta. Podrás mantenerla si la has descargado previamente a tu ordenador. | Si eliminas estas copias de seguridad de Modular se perderán los datos y no podrás recuperarlas desde nuestra herramienta. Podrás mantenerlas si las has descargado previamente a tu ordenador.',
    deleteMasterBackupTitle: '¿Quieres quitar esta copia de seguridad como maestra?',
    deleteMasterBackupText:
      'Si lo haces, este backup se eliminará en el momento de cumplirse {days} días desde su fecha de creación. Si ya han pasado más de {days} días se eliminará en unos minutos. A partir de ese momento no se podrá recuperar. Te recomendamos descargarla primero.',
    backupDeleteCompleted: 'El backup se ha borrado correctamente | Los backups se han borrado correctamente',
    cannotDeleteMasterBackup: 'No puedes borrar una copia maestra',
    reachedMasterBackupLimit: 'Has alcanzado el límite de copias maestras para esta web.',
    includedCannotBeEmpty: 'Debes seleccionar algún elemento para continuar.',
    markAsMasterBackup: 'Convertir en copia de seguridad maestra',
    unmarkAsMasterBackup: 'Desactivar copia de seguridad maestra',
    maxMasterBackupsExceeded: 'Has alcanzado el límite de copias maestras para esta web.',
    all: 'Todo',
    none: 'Nada',
    downloadZip: 'Descargar ZIP',
    status: {
      automatic: 'Automático',
      force_first: 'Automático',
      manual: 'Manual',
    },
    tooltip: {
      status: {
        excluded: '{type}: Excluido',
        pending: '{type}: pendiente',
        in_progress: '{type}: procesando',
        ready: '{type}: pendiente',
        upload_pending: '{type}: subiendo',
        uploading: '{type}: subiendo',
        done: '{type}: completado',
        failed_in_creation: '{type}: fallo en creación',
        failed_export_database: '{type}: fallo en exportación de base de datos',
        failed_export_files: '{type}: fallo en exportación de archivos',
        failed_in_processing: '{type}: fallo en procesamiento',
        failed_uploaded: '{type}: fallo en subida',
        failed_partial: '{type}: fallo parcial',
        failed: '{type}: fallo',
        failed_file_not_found: '{type}: archivo no encontrado',
        storage_exceeded: '{type}: límite de almacenamiento excedido',
        partial_error: '{type}: con errores parciales',
        error: '{type}: error',
        disabled: '{type}: deshabilitado',
      },
      type: {
        database: 'Base de datos',
        databaseInitial: 'B',
        themes: 'Temas',
        themesInitial: 'T',
        plugins: 'Plugins',
        pluginsInitial: 'P',
        core: 'WordPress',
        coreInitial: 'W',
        uploads: 'Medios',
        uploadsInitial: 'M',
        all: 'Backup Completo',
        ready: 'Preparado',
      },
    },
    noBackups: {
      title: 'Estamos preparando tu primera copia de seguridad',
      description:
        'Este proceso puede tardar unos minutos. En el momento que empiece a realizarse la copia te aparecerá debajo en el listado de backups.',
      button: 'Revisar configuración',
    },
    aside: {
      scheduleBackups: 'Programar backups',
      usedStorage: 'Almacenamiento usado',
      currentStorage: '{ current } de { total } usado',
      noBackups:
        'Todavía no tienes ninguna copia de seguridad. Aprende como activarlas en tus webs <a href="https://soporte.modulards.com/es/configurar-copias-de-seguridad-recurrentes" class="d-inline lh-xl text-sm btn btn-link btn-link-primary-dark text-decoration-underline shadow-none border-0 text-sm p-0 m-0 fw-medium" target="_blank" aria-label="Cómo activar las copias de seguridad">aquí</a>.',
      excludedFiles: 'Archivos excluidos',
      spaceFilled:
        'No se realizarán nuevas copias de seguridad hasta que tengas espacio o adquieras más con un plan PRO.',
    },
    configuration: {
      backupName: 'Nombre del backup',
      elementsToInclude: 'Elementos a incluir',
      elementsToIncludeText: 'Elige los elementos que quieres incluir en tus copias de seguridad.',
      core: 'WordPress y archivos',
      database: 'Base de datos',
      files: 'Archivos',
      backupModeTitle: 'Modo de creación',
      backupModeDescription:
        'Selecciona el método de creación de copias de seguridad. El modo normal genera copias en un solo bloque, mientras que el modo diferido las divide en bloques más pequeños. Aunque el modo normal es más rápido, podría no funcionar correctamente según tu proveedor de alojamiento web. Si esto ocurre, elige el modo diferido.',
      elementsRecurrenceTitle: 'Recurrencia',
      elementsRecurrenceText: 'Elige cada cuanto quieres que se hagan las copias de seguridad.',
      elementsRecurrenceExtraText:
        'Independientemente de tu selección la primera copia de seguridad se hará de manera instantánea. La siguiente, en la fecha que tu elijas. Y a partir de ahí en la fecha seleccionada con un mínimo de los días correspondientes a tu recurrencia entre medias.',
      scheduleRecurrence: 'Programar recurrencia',
      scheduleRecurrenceWeekly: 'Cada semana el',
      scheduleRecurrenceMonthly: 'Cada mes el',
      hour: 'Hora',
      hourTimezone: 'Por defecto usamos la zona horaria de tu navegador: {timezone}',
      backupAdvice:
        'Si tienes varias webs en el mismo servidor te recomendamos programar los backups con al menos media hora de diferencia para evitar errores.',
      filesExcluded: '({ count } Elemento excluido) | ({ count } Elementos excluidos)',
      tablesExcluded: '({ count } Tabla excluida) | ({ count } Tablas excluidas)',
      excludeFiles: 'Excluir archivos',
      selectFiles: 'Seleccionar archivos',
      excludeTables: 'Excluir tablas',
      selectTables: 'Seleccionar tablas',
      selectFilesExclude: 'Selecciona los archivos para excluir',
      selectTablesExclude: 'Selecciona las tablas para excluir',
      includeAll: 'Incluir todos',
      allFiles: 'Todos los archivos',
      allTables: 'Todas las tablas',
      tables: 'Tablas',
      excludedFiles: 'Archivos exluidos ({ count })',
      excludedTables: 'Tablas exluidas ({ count })',
      elementsRetentionTitle: 'Retención de Backups',
      elementsRetentionText:
        'Elige cuántos días quieres que se conserven los backups de esta web en Modular antes de ser eliminados.',
      elementsRetentionMonthly:
        'Las copias de seguridad que se realizan de manera mensual se conservan durante 45 días.',
      expertiseConfig: {
        title: 'Configuración experta',
        expertiseAlertText:
          'Configuración sólo para expertos. Te recomendamos no hacer cambios si no dispones de un amplio conocimiento sobre las capacidades de los servidores donde está alojada la web y el funcionamiento de los backups. Una mala configuración puede impedir la generación de los mismos.',
        maxFiles: {
          title: 'Nº máximo de archivos',
          description:
            'Establece el máximo de archivos a procesar por ciclo (por defecto 5000). Disminuir este valor es ideal para servidores con discos duros más antiguos y lentos.',
        },
        maxZipSize: {
          title: 'Tamaño máximo del ZIP',
          description: 'Define el tamaño máximo proximado de cada segmento del ZIP (128MB por defecto).',
        },
        maxWait: {
          title: 'Tiempo máximo de espera',
          description:
            'Tiempo máximo antes de cancelar la copia si el servidor tiene problemas (30 minutos por defecto).',
        },
      },
    },
    detail: {
      restoreBackup: 'Descarga o restaura esta copia de seguridad',
      backupDetails: 'Información de la copia de seguridad',
      restoreBackupDescription:
        'Aquí puedes descargar el archivo .zip con tu copia de seguridad o restaurarla desde Modular si lo necesitas (próximamente).',
      restoreToVersion: 'Restaurar a esta versión',
      downloadZip: 'Descargar ZIP',
      downloadCompleteZip: 'Zip completo',
      downloadParts: 'Por partes',
      wpVersion: 'Versión de WP',
      numPlugins: 'Plugins',
      activeTheme: 'Tema activo',
      postPublish: 'Post publicados',
      content: 'Contenido',
      contentDescription:
        'Aquí puedes visualizar las distintas secciones de tu copia de seguridad. Dependiendo de tu configuración y del tamaño de la web, estas secciones pueden estar subdivididas en fragmentos más pequeños.',
      couldntSave: 'No se ha podido guardar',
    },
    slider: {
      titleSlide0: 'Excluye archivos y tablas de base de datos de tus backups',
      textSlide0:
        'Optimiza tus copias de seguridad seleccionando qué archivos, carpetas o tablas específicas de la base de datos deseas excluir para guardar solo la información esencial.',
      titleSlide1: 'Guarda 3 copias de seguridad maestras para siempre',
      textSlide1:
        'Las copias de seguridad se eliminan después de 45 días. Protege hasta 3 copias para que siempren queden guardadas (hasta que tú decidas borrarlas).',
      titleSlide2: 'Haz backups instantáneos cuando lo necesites',
      textSlide2:
        'Si necesitas hacer una copia de seguridad antes de realizar cambios en tu web y no quieres esperar a la copia rogramada, haz backups instantáneos con 1 clic desde Modular.',
      titleSlide3: 'Elige la recurrencia que mejor se adapte a tu web',
      textSlide3:
        '¿Un mes te parece demasiado? Con nuestros planes PRO podrás hacer backups semanales e incluso diarios. Para que no tengas problema si cuentas con una web muy dinámica.',
    },
    empty: {
      emptyYTLink: 'https://www.youtube.com/embed/vJoGbtdCIdY',
      emptyText:
        'Las copias de seguridad son una de las tareas más importantes que se deben hacer en una web para un correcto mantenimiento. Con Modular puedes activar copias de seguridad automáticas eligiendo la recurrencia que prefieres. Hacer copias de seguridad instantáneas antes de realizar un cambio. Y, por supuesto, recuperarlas cuando hayas detectado un fallo en tu web o quieras volver atrás.',
      backupTitle1: 'Haz backups de lo que necesitas',
      backupReason1:
        'Puedes elegir si quieres hacer backups completos de tu web. Solo de archivos. Solo de base de datos. O incluso seleccionar si quieres excluir archivos específicos de la copia de seguridad.',
      backupTitle2: 'Recupéralos tú o hazlo de manera automática',
      backupReason2:
        'Podrás descargar los backups en un archivo .zip para restaurarlos por tu cuenta o, muy pronto, podrás restaurar los backups desde Modular con un simple clic.',
      backupTitle3: 'Backups fuera de tu hosting',
      backupReason3:
        'Creamos tu backup "en vivo", por lo que no será almacenado en tu servidor y no ocupará espacio en él. Para que si este falla, el backup esté 100% seguro en una localización diferente.',
      backupTitle4: 'Cumple con la RGPD',
      backupReason4:
        'Todos nuestros backups se realizan en servidores en la nube de AWS alojados en Europa, para que tú y tus clientes cumpláis con la RGPD.',
    },
    warnings: {
      title: 'Tienes alertas de salud que pueden afectar a tus backups',
      description: 'Puedes verificar el estado de salud de tu sitio en la sección de <a href="#" class="text-danger-dark">Seguridad & Salud</a>',
    },
  },
};
