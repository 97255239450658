<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
  name: 'MIcon',
});

const props = withDefaults(
  defineProps<{
    icon?: MIconTypes
    size?: FontSizes
    space?: string
  }>(),
  {
    icon: 'modular-icon',
    size: '',
    space: '',
  },
);

export type MIconTypes =
  | 'actions-minus'
  | 'actions-plus'
  | 'airline-plane'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'amazon'
  | 'apple'
  | 'arrow-caret-back'
  | 'arrow-caret-down'
  | 'arrow-caret-forward'
  | 'arrow-caret-up'
  | 'arrow-caret-down-outlined'
  | 'arrow-caret-left-outlined'
  | 'arrow-caret-right-outlined'
  | 'arrow-caret-up-outlined'
  | 'arrow-chevron-back'
  | 'arrow-chevron-down'
  | 'arrow-chevron-forward'
  | 'arrow-chevron-up'
  | 'arrow-down'
  | 'arrow-going-down'
  | 'arrow-going-up-alt'
  | 'arrow-going-up'
  | 'arrow-reply'
  | 'arrow-select'
  | 'arrow-deselect'
  | 'arrow-up'
  | 'at-sign'
  | 'attach'
  | 'auto-layout'
  | 'back-arrow'
  | 'back-undo'
  | 'backspace'
  | 'backward'
  | 'bag'
  | 'bio-fingerprint'
  | 'bitcoin'
  | 'blog'
  | 'bold'
  | 'box'
  | 'briefcase-portfolio'
  | 'bullets-numbered'
  | 'bullets'
  | 'bullhorn'
  | 'calandar'
  | 'call'
  | 'camera'
  | 'car-front'
  | 'car-side'
  | 'card-collection'
  | 'card'
  | 'cart'
  | 'cash-money'
  | 'cashapp'
  | 'cast'
  | 'chart-bar'
  | 'chart-pie'
  | 'check'
  | 'chip'
  | 'circle-actions-alert-info'
  | 'circle-actions-alert-question'
  | 'circle-actions-close'
  | 'circle-actions-success'
  | 'circle-actions-success-filled'
  | 'close-x'
  | 'color-palette'
  | 'comment-round'
  | 'comment'
  | 'compass'
  | 'component-1'
  | 'component-2'
  | 'component'
  | 'connection'
  | 'configure'
  | 'copy'
  | 'cursor-pointer'
  | 'cursor'
  | 'cut'
  | 'database'
  | 'delete'
  | 'device-desktop'
  | 'device-laptop'
  | 'device-mouse'
  | 'device-phone'
  | 'device-tablet'
  | 'device-watch'
  | 'disabled'
  | 'dollar'
  | 'download'
  | 'edit'
  | 'email-closed'
  | 'email-send-status'
  | 'exchange-transfer'
  | 'export'
  | 'fast-gage'
  | 'facebook'
  | 'feather'
  | 'feed-list'
  | 'file-structure'
  | 'file'
  | 'flag-bannor'
  | 'folder'
  | 'font-color'
  | 'font-heading'
  | 'font-underline'
  | 'forward-arrow'
  | 'forward-redo'
  | 'forward'
  | 'full-screen-expand-resize'
  | 'full-screen-resize-expand'
  | 'geo-locate'
  | 'gift'
  | 'going-down-alt'
  | 'google-maps'
  | 'google'
  | 'goverment-law-balance'
  | 'grid-feed-cards'
  | 'grid-menu'
  | 'hamburger-menu'
  | 'hashtag'
  | 'heart'
  | 'heat'
  | 'help'
  | 'hide'
  | 'home'
  | 'image'
  | 'inbox'
  | 'indent-less'
  | 'indent-more'
  | 'instagram'
  | 'italic'
  | 'key'
  | 'lab-beaker'
  | 'layout'
  | 'link'
  | 'linkedin'
  | 'location'
  | 'lock-unlocked'
  | 'lock'
  | 'log-in-circle'
  | 'log-in-square'
  | 'log-out-circle'
  | 'log-out-square'
  | 'mail'
  | 'map'
  | 'master-component'
  | 'mastercard'
  | 'menu-left'
  | 'menu-right'
  | 'microphone'
  | 'modular-icon'
  | 'more-vert'
  | 'more'
  | 'music-note'
  | 'notification-bell'
  | 'paste'
  | 'pause'
  | 'paypal'
  | 'pinterest'
  | 'play'
  | 'playlist'
  | 'pod-cast'
  | 'power-on-off'
  | 'printer'
  | 'profile'
  | 'qr-code'
  | 'refresh'
  | 'save-favorite-bookmark'
  | 'save-favorite-important'
  | 'scroll'
  | 'search'
  | 'send'
  | 'server'
  | 'settings'
  | 'sheild-with-check'
  | 'shreaded'
  | 'sound-muted'
  | 'sound-playing'
  | 'square'
  | 'stack'
  | 'star'
  | 'tag'
  | 'target'
  | 'three-people'
  | 'thumbs-up'
  | 'ticket'
  | 'time'
  | 'train-rail'
  | 'trophy'
  | 'truck'
  | 'twitter'
  | 'two-people'
  | 'type'
  | 'upload'
  | 'usb-thumb-drive'
  | 'venmo'
  | 'verified-badge'
  | 'video-camera'
  | 'video'
  | 'view'
  | 'visa'
  | 'weather-cloud'
  | 'weather-lightning'
  | 'weather-moon'
  | 'weather-partly-cloudly-night'
  | 'weather-partly-cloudy'
  | 'weather-rain'
  | 'weather-snow-1'
  | 'weather-snow'
  | 'weather-stars'
  | 'weather-sun'
  | 'weather-temperature'
  | 'weather-thunderstorms'
  | 'weather-tornado'
  | 'weather-umbrella'
  | 'weather-water-drop'
  | 'weather-wind'
  | 'whatsapp'
  | 'wifi'
  | 'world-net'
  | 'wordpress-go'
  | 'youtube'
  | 'power-on'
  | 'power-off'
  | 'star-empty'
  | 'star-fill'
  | 'zip-file'
  | 'smile-emoji'
  | 'lightbulb';

type FontSizes = '' | '4xs' | '3xs' | '2xs' | 'xxs' | 'xs' | 'sm' | 'md' | 'normal' | 'lg' | 'xl' | 'xxl';

const classes = computed(() => {
  let oClass = '';
  oClass += !!props.icon ? `mi-${props.icon}` : '';
  oClass += !!props.size ? ` text-${props.size}` : '';
  oClass += !!props.space ? ` ${props.space}` : '';
  return oClass;
});
</script>

<template>
  <span :class="classes" class="mi" />
</template>
