<script>
import { getHummanSize } from '@/helpers/generalHelper';

import { MediaLibraryCollection } from '@spatie/media-library-pro-vue3-collection';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VUploader',
  components: {
    MediaLibraryCollection,
  },
  props: {
    name: {
      required: false,
      type: String,
      default: '',
    },
    routePrefix: {
      required: false,
      type: String,
      default: 'api/files/temporary',
    },
    validationRules: {
      required: false,
      type: Object,
      default: () => ({
        accept: [
          'image/gif',
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/webp',
          'image/tif',
          'image/tiff',
        ],
        maxSizeInKB: 6144,
      }),
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    sortable: {
      default: true,
      type: Boolean,
    },
    maxItems: {
      required: false,
      type: Number,
      default: 1,
    },
    afterUpload: {
      type: Function,
      default: () => {},
    },
  },
  emits: [
    'removed',
    'change',
    'is-ready-to-submit-change',
    'has-uploads-in-progress-change',
    'isReadyToSubmitChange',
    'hasUploadsInProgressChange',
  ],
  data() {
    return {
      mediaLibrary: null,
      translations: {
        fileTypeNotAllowed: this.$t('uploader.fileTypeNotAllowed'),
        tooLarge: this.$t('uploader.tooLarge'),
        tooSmall: this.$t('uploader.tooSmall'),
        tryAgain: this.$t('uploader.tryAgain'),
        somethingWentWrong: this.$t('uploader.somethingWentWrong'),
        selectOrDragMax: this.$t('uploader.selectOrDragMax', { max: this.maxItems }, this.maxItems),
        selectOrDrag: this.$t('uploader.selectOrDrag'),
        file: {
          singular: this.$t('uploader.file.singular'),
          plural: this.$t('uploader.file.plural'),
        },
        anyImage: this.$t('uploader.anyImage'),
        anyVideo: this.$t('uploader.anyVideo'),
        goBack: this.$t('uploader.goBack'),
        dropFile: this.$t('uploader.dropFile'),
        dragHere: this.$t('uploader.dragHere'),
        remove: this.$t('uploader.remove'),
        name: this.$t('uploader.name'),
        download: this.$t('uploader.download'),
      },
    };
  },
  computed: {
    domain() {
      return import.meta.env.VITE_API_URL;
    },
    finalValidationRules() {
      return !!this.validationRules && Object.keys(this.validationRules).length > 0
        ? this.validationRules
        : {
            accept: ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/tif', 'image/tiff'],
            maxSizeInKB: 6144,
          };
    },
  },
  mounted() {
    this.translations = {
      fileTypeNotAllowed: this.$t('uploader.fileTypeNotAllowed'),
      tooLarge: this.$t('uploader.tooLarge'),
      tooSmall: this.$t('uploader.tooSmall'),
      tryAgain: this.$t('uploader.tryAgain'),
      somethingWentWrong: this.$t('uploader.somethingWentWrong'),
      selectOrDragMax: this.$t('uploader.selectOrDragMax', { max: this.maxItems }, this.maxItems),
      selectOrDrag: this.$t('uploader.selectOrDrag'),
      file: {
        singular: this.$t('uploader.file.singular'),
        plural: this.$t('uploader.file.plural'),
      },
      anyImage: this.$t('uploader.anyImage'),
      anyVideo: this.$t('uploader.anyVideo'),
      goBack: this.$t('uploader.goBack'),
      dropFile: this.$t('uploader.dropFile'),
      dragHere: this.$t('uploader.dragHere'),
      remove: this.$t('uploader.remove'),
      name: this.$t('uploader.name'),
      download: this.$t('uploader.download'),
    };

    this.mediaLibrary = this.$refs.mediaLibraryCollection.mediaLibrary;

    window.mediaLibraryTranslations = this.translations;

    const iconButton = document.querySelector('.media-library-button-info');

    iconButton.innerHTML = '<span class="mi mi-image"/>';
  },
  unmounted() {
    if (!!window?.mediaLibraryTranslations) {
      window.mediaLibraryTranslations = null;
    }

    this.mediaLibrary = null;
    this.translations = null;
  },
  methods: {
    getHummanSize,
    remove(object) {
      this.mediaLibrary.removeMedia(object.attributes.uuid);

      this.$emit('removed', null);
    },
    checkFileValidity(file) {
      return new Promise((resolve, reject) => {
        if (file.size <= (this.finalValidationRules.maxSizeInKB * 1000)) {
          resolve('File size is valid');
        } else {
          reject(new Error(this.$t('uploader.tooLarge')));
        }
      });
    },
  },
});
</script>

<template>
  <div class="md-uploader">
    <MediaLibraryCollection
      ref="mediaLibraryCollection"
      :after-upload="afterUpload"
      :before-upload="checkFileValidity"
      :max-items="maxItems"
      :multiple="multiple"
      :route-prefix="routePrefix"
      :sortable="sortable"
      :translations="translations"
      :upload-domain="domain"
      :validation-rules="finalValidationRules"
      :with-credentials="true"
      name="downloads"
    />
  </div>
</template>

<style lang="scss">
.media-library-add {
  .media-library-dropzone:not(.disabled):active,
  .media-library-dropzone:not(.disabled):focus,
  .media-library-dropzone:not(.disabled):hover {
    background-color: rgba(var(--md-primary-rgb), 0.05);
    border-color: rgba(var(--md-primary-rgb), 0.3);

    .media-library-placeholder .media-library-button {
      box-shadow: none !important;
    }
  }

  .media-library-dropzone::before {
    background-color: transparent !important;
  }

  .media-library-dropzone-add {
    padding: 0.25rem;
    border-radius: 0.25rem;
    border: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A3BDFF' stroke-width='2' stroke-dasharray='4%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");

    .media-library-help {
      color: var(--md-primary);
      font-weight: 600;
      font-size: 0.75rem;
      padding: 0;
    }
  }

  .media-library-placeholder {
    height: 96px !important;
    min-width: 96px !important;
    background-color: var(--md-gray-200);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-left: 0;

    &::before,
    &::after {
      content: none !important;
      display: none !important;
    }

    .media-library-button-info {
      color: var(--md-gray-900) !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
    }
  }
}

.media-library-items {
  border-color: var(--md-gray-300) !important;

  .media-library-item {
    background-color: var(--md-light);
  }

  .dragula-handle.media-library-row-drag {
    background-color: var(--md-gray-200);
  }
}
</style>
