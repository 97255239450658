<script lang="ts" setup>
import type { SiteServiceHealthParams } from '@/types/models/site/service/health/SiteHealth';
import api from '@/api';

import usePermissions from '@/composables/usePermissions';
import useConstantsHelper from '@/resources/constants/ConstantsHelper';

import useSiteStore from '@/stores/site/siteStore';

import { useSiteInformation } from '@/views/site/health/composables/useSiteInformation';

import { watchImmediate } from '@vueuse/core';
import { storeToRefs } from 'pinia';

import { computed } from 'vue';
import { EventSymbols } from '@/resources/symbols';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineOptions({
  name: 'TheSiteOverviewHealth',
});

defineProps<{
  site: string
}>();

const { can } = usePermissions();

const siteStore = useSiteStore();

const { currentSite } = storeToRefs(siteStore);

const hasCurrentSiteLoaded = computed(() => siteStore.hasCurrentSiteLoaded);

const canShowHealth = computed(() => can(PermissionEnumConst.HEALTH_SERVICES_SHOW));

const siteIsDisconnected = computed(() => !!currentSite.value && currentSite.value?.connectionStatus !== 'established');

const healthCriticalErrors = computed(() => currentSite.value?.healthCriticalErrors ?? 0);

const currentVersion = computed(() => siteStore?.currentSiteConnectorVersion || '0.0.0');
const updatedPlugin = computed(() => currentVersion.value.localeCompare('0.59.9', undefined, { numeric: true }) === 1);

async function handleTrackHealth(label: string) {
  await api.mixpanel.track('Site health', {
    'Website name': currentSite.value?.name,
    'Website url': currentSite.value?.uri,
    'Location': `Widget ${label}`,
  });
}

const { loadHealth, healthVersionsStatus } = useSiteInformation();

function getHealth() {
  const params: SiteServiceHealthParams = {
    only: 'basic_tests',
  };

  loadHealth(params);
}

watchImmediate(hasCurrentSiteLoaded, (value) => {
  if (value && siteStore.currentSiteIsConnected) {
    getHealth();
  }
});

// Event Bus created on handlerSocketEvents
const healthUpdatedEventBus = useEventBus<string>(EventSymbols.HEALTH_UPDATED);

healthUpdatedEventBus.on((event) => {
  if (event === EventSymbols.HEALTH_UPDATED.toString()) {
    getHealth();
  }
});
</script>

<template>
  <div
    id="site-overview-health"
    class="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-4 gy-4 gy-lg-0 gy-xl-4 gy-xxl-0 fw-medium mb-24 text-xs"
  >
    <div v-for="(item, index) in healthVersionsStatus" :key="index" class="col">
      <div
        v-if="item.status === 'loading'"
        :class="`bg-${item.background} text-${item.color}`"
        class="site-overview-health-item d-flex justify-content-between align-items-center gap-8 rounded-sm border border-2 p-8"
      >
        <div class="fw-medium text-nowrap text-xs">
          {{ $t(item.text) }}
          {{ item.currentVersion }}
        </div>

        <v-icon class="text-xxl fw-normal" icon="smile-emoji" />
      </div>

      <router-link
        v-else
        :class="`text-bg-${item.background}`"
        :to="{ name: 'sites.site.health.show' }"
        class="site-overview-health-item d-flex align-items-center justify-content-between text-decoration-none w-100 h-100 gap-8
          rounded-sm border-2 p-8"
        @click="handleTrackHealth($t(item.text))"
      >
        {{ $t(item.text) }}
        {{ item.currentVersion }}

        <v-icon
          :class="`text-${item.color}`"
          :icon="item.icon"
          class="site-overview-health-icon text-xxl fw-normal"
        />
      </router-link>
    </div>

    <div class="col">
      <div
        v-if="!currentSite || siteIsDisconnected"
        class="h-100 d-flex align-items-center justify-content-between w-100 gap-8 rounded-sm bg-gray-300 p-8 text-gray-600"
      >
        <template v-if="!currentSite">
          {{ $t('site.overview.health.checkSite') }}
        </template>
        <template v-else>
          {{ $t('site.overview.health.notAvailable') }}
        </template>
      </div>

      <router-link
        v-else-if="!canShowHealth"
        :to="{
          name: 'sites.site.health.show',
          hash: '#siteCriticalErrors',
        }"
        class="btn btn-icon btn-link h-100 w-100 justify-content-between position-relative fw-medium gap-8 rounded-sm bg-gray-300 p-8
          text-xs text-gray-600"
        @click="handleTrackHealth('Errors')"
      >
        {{ $t('site.overview.health.criticalErrors') }}

        <span class="badge bg-success text-light rounded-xs fw-medium ms-8 p-4 text-xs">
          {{ $t('general.shared.proTag') }}
        </span>
      </router-link>

      <router-link
        v-else-if="!updatedPlugin"
        :to="{
          name: 'sites.site.health.show',
        }"
        class="btn btn-icon btn-link h-100 w-100 justify-content-between position-relative text-bg-danger-light fw-medium gap-8
          rounded-sm bg-gray-300 p-8 text-xs"
        @click="handleTrackHealth('Errors')"
      >
        {{ $t('site.overview.health.notAvailable') }}
      </router-link>

      <router-link
        v-else
        :class="
          healthCriticalErrors > 0 || siteIsDisconnected
            ? 'text-bg-danger-light justify-content-between'
            : 'text-bg-success-light justify-content-center'
        "
        :to="{
          name: 'sites.site.health.show',
          hash: '#siteCriticalErrors',
        }"
        class="site-overview-health-item h-100 d-flex align-items-center w-100 text-decoration-none gap-8 rounded-sm p-8"
        @click="handleTrackHealth('Errors')"
      >
        <template v-if="healthCriticalErrors > 0">
          {{ $t('site.overview.health.criticalErrors') }}

          <span class="badge bg-danger rounded-xs fw-medium px-8 py-4 text-xs">
            {{ healthCriticalErrors }}
          </span>
        </template>

        <template v-else>
          {{ $t('site.overview.health.noCriticalErrors') }}
        </template>
      </router-link>
    </div>
  </div>
</template>
