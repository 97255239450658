<script lang="ts" setup>
import useRedirectToPayment from '@/composables/useRedirectToPayment';

import useBillingStore from '@/stores/billingStore';

import { computed } from 'vue';

defineOptions({
  name: 'VSubscriptionSidebar',
});

defineProps({
  title: {
    type: String,
    required: false,
    default: 'billing.sidebar.becomeAPro',
  },
  description: {
    type: String,
    required: false,
    default: 'billing.sidebar.sidebartext',
  },
  textButton: {
    type: String,
    required: false,
    default: 'billing.shared.getAPlanPrice',
  },
});

const { redirectToPayment } = useRedirectToPayment();

const billingStore = useBillingStore();

const subscription: any = computed(() => billingStore.subscriptions.plan);
const license: any = computed(() => billingStore.license);

const hasSubscription = computed(() => !!subscription.value);
</script>

<template>
  <div v-if="!hasSubscription && !license" id="the-subscription-aside" class="col col-md-6 col-xl-12 aside mb-16">
    <div class="aside-content aside-subscription radius-sm h-100 px-xl-16 bg-white p-32">
      <v-picture class="d-flex justify-content-center align-items-center h-auto" height="218" width="268">
        <img
          :alt="$t('billing.plan.noPlan')"
          class="mx-auto mb-8 w-80"
          src="@assets/img/click-here.png"
          srcset="@assets/img/click-here.png 2x, @assets/img/click-here.png 1x"
        >
      </v-picture>

      <div class="text-center">
        <p class="fw-semi mb-8 text-lg">
          {{ $t(title) }}
        </p>

        <p class="fw-medium mb-24 text-sm text-gray-500">
          {{ $t(description) }}
        </p>

        <VButton
          :aria-label="$t(textButton)"
          class="w-xl-100"
          size="sm"
          variant="pink"
          @click="redirectToPayment({ trackName: 'PRO Widget' })"
        >
          <span class="fw-semi">{{ $t(textButton) }}</span>
        </VButton>
      </div>
    </div>
  </div>
</template>
