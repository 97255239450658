import type { SocialNetwork } from '@/types/models/user/social/SocialNetwork';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class UserSocialService extends AbstractService {
  /**
   * Returns the social networks associated with the currently authenticated user.
   *
   * @returns {Promise<SocialNetwork[]>}
   */
  public retrieve(): Promise<SocialNetwork[]> {
    return this.request({
      method: apiEndpoints.meAuthSocialIndex.method,
      url: this.buildPath(apiEndpoints.meAuthSocialIndex),
    });
  }

  /**
   * Deletes the specified social network provider from the currently authenticated user's account.
   *
   * @param {string} provider
   * @returns {Promise<any>}
   */
  public delete(provider: string): Promise<any> {
    return this.request({
      method: apiEndpoints.authSocialDestroy.method,
      url: this.buildPath(apiEndpoints.authSocialDestroy, { provider }),
    });
  }
}
