import type { IUptimeServiceStoreParams, IUptimeStatsRetrieveParams } from '@/api/services/site/service/uptime/index';
import type { SiteServiceUptime } from '@/types/models/site/service/uptime/SiteServiceUptime';
import type { UptimeStats } from '@/types/models/site/service/uptime/stats/UptimeStats';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class UptimeService extends AbstractService {
  // TODO Refactor all service
  /**
   * Retrieves the Uptime Service for the current site.
   *
   * @param {string} serviceId
   * @returns {Promise<SiteServiceUptime>}
   */
  public retrieve(serviceId: number): Promise<SiteServiceUptime> {
    return this.request({
      method: apiEndpoints.uptimeServicesShow.method,
      url: this.buildPath(apiEndpoints.uptimeServicesShow, {
        uptime_service: serviceId,
      }),
    });
  }

  /**
   * Creates the Uptime Service for the current site.
   * @param {number} siteId What site to create the service for.
   * @param {IUptimeServiceStoreParams} data
   * @returns {Promise<SiteServiceUptime>}
   */
  public create(siteId: number, data: IUptimeServiceStoreParams): Promise<SiteServiceUptime> {
    return this.request({
      method: apiEndpoints.uptimeServicesStore.method,
      url: this.buildPath(apiEndpoints.uptimeServicesStore),
      data: {
        site: siteId,
        ...data,
      },
    });
  }

  /**
   * Updates the Uptime Service for the current site.
   * @param {number} serviceId What site to create the service for.
   * @param {IUptimeServiceStoreParams} data
   * @returns {Promise<SiteServiceUptime>}
   */
  public update(serviceId: number, data: IUptimeServiceStoreParams): Promise<SiteServiceUptime> {
    return this.request({
      method: apiEndpoints.uptimeServicesUpdate.method,
      url: this.buildPath(apiEndpoints.uptimeServicesUpdate, {
        uptime_service: serviceId,
      }),
      data: {
        ...data,
      },
    });
  }

  /**
   * Retrieves theUptime Statistics for a specific period.
   * @param serviceId
   * @param params
   * @returns {Promise<UptimeStats>}
   */
  public retrieveStats(serviceId: number, params: IUptimeStatsRetrieveParams): Promise<UptimeStats> {
    return this.request({
      method: apiEndpoints.uptimeServicesStatsShow.method,
      url: this.buildPath(apiEndpoints.uptimeServicesStatsShow, {
        uptime_service: serviceId,
      }),
      params,
      options: {
        cancellable: true,
        cancelKey: 'uptimeRetrieveStats',
      },
    });
  }
}
