import type { StripeProduct } from '@/types/models/organization/billing/product/StripeProduct';
import { BillingProductTypeEnumConst } from '@/types/enums/BillingProductTypeEnum';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

const { PLAN } = BillingProductTypeEnumConst;

export default class ProductService extends AbstractService {
  /**
   * Retrieves all the products of a specific type.
   *
   * @param {string | string[]} types
   * @returns {Promise<StripeProduct[]>}
   */
  public async all(types: string | string[] = PLAN): Promise<StripeProduct[]> {
    if (Array.isArray(types)) {
      const results: StripeProduct[] = [];

      for (const type of types) {
        const products = await this.all(type);

        results.push(...products);
      }

      return results;
    } else {
      return this.request({
        method: apiEndpoints.billingProductsIndex.method,
        url: this.buildPath(apiEndpoints.billingProductsIndex),
        params: { type: types },
      });
    }
  }
}
