import type { RouteRecordRaw } from 'vue-router';

import TheMenuDashboard from '@/components/global/menu/TheMenuDashboard.vue';
import TheTeamSidebar from '@/views/team/parts/TheTeamSidebar.vue';

import TheTeam from '@/views/team/TheTeam.vue';

const TheTeamEdit = () => import('@/views/team/TheTeamEdit.vue');

const teamRoutes: Array<RouteRecordRaw> = [
  {
    path: 'teams/:team',
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'team.show',
        components: {
          default: TheTeam,
          menu: TheMenuDashboard,
          sidebar: TheTeamSidebar,
        },
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'edit',
            name: 'team.update',
            components: {
              default: TheTeamEdit,
              menu: TheMenuDashboard,
              sidebar: TheTeamSidebar,
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default teamRoutes;

export { teamRoutes };
