import type { LanguagesType } from '@/helpers/langHelper';

import type { App, Plugin } from 'vue';
import DateHelper from '@/helpers/dateHelper';

import i18n from '@/lang/index';
import { Duration } from 'luxon';

interface NumberFormat {
  PERCENTAGE: 'percentage'
  NUMBER: 'number'
  CURRENCY: 'currency'
}

/**
 * Our awesome class to provide texts formatting.
 *
 * Just call to the different format methods to make it beauty and more readable and international.
 *
 * Could be interesting to implement formatjs if it makes us easier to beautify texts.
 * @link https://formatjs.io/docs/vue-intl/
 * @link https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 * @link https://moment.github.io/luxon/demo/global.html
 * @link https://moment.github.io/luxon/#/parsing
 */
export interface StrInterface {
  numberFormats: {
    PERCENTAGE: 'percentage'
    NUMBER: 'number'
    CURRENCY: 'currency'
  }

  /**
   * Receives a date as input, parses it with the provided date_format
   * and returns it formatted as the output_format says.
   *
   * @param date
   * @param format
   * @link https://moment.github.io/luxon/#/parsing?id=iso-8601
   * @link https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   * @returns {string}
   */
  formatDate: (date: any, format?: string) => string

  /**
   * Receives a date as input, parses it with the provided date_format and returns
   * it formatted as the output_format says.
   *
   * @param date
   * @param format
   * @link https://moment.github.io/luxon/#/parsing?id=iso-8601
   * @link https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   * @returns {string}
   */
  formatDateTime: (date: any, format?: string) => string

  /**
   * Receives a number and formats it to the available formats.
   *
   * @param num
   * @param format
   * @param locale
   * @param currency
   * @param decimals
   * @param prefix
   * @param suffix
   * @returns {string|*}
   */
  formatNumber: (num: any, format: string, { locale, currency, decimals, prefix, suffix }?: any) => string

  minutesAndSeconds: (seconds: any, minutesUnit: any, secondsUnit: any) => string

  parseSeconds: (seconds: string | number) => { hours: number, minutes: number, seconds: number }

  numberToPercent: (num: any, locale: any, prefix?: string, suffix?: string) => string

  formatNumberToThousand: (num: any) => string

  numberToNumber: (num: any, locale: any, decimals?: number, prefix?: string, suffix?: string) => string

  stringToCamel: (str: string) => string

  snakeToLittleCamel: (str: string) => string

  snakeToCamel: (str: string) => string

  camelToSnake: (str: string) => string

  convertKeysToSnakeCase: (obj: any) => any

  convertKeysToCamelCase: (obj: any) => any

  numberToCurrency: (num: any, locale: any, currency: any, prefix?: string, suffix?: string) => string

  formatBytes: (bytes: number | string) => string

  parseIntegerToDecimal: (value: number) => string | number

  formatDecimals: (num: number, digits: number, alwaysShowDecimals?: boolean, prefix?: string, suffix?: string) => string

  formatNumberToCompact: (num: number, locale?: LanguagesType) => string

  formatNumberToByteEquivalences: (value: number, actualUnit: string, newUnit: string) => number
}

class StringHelper implements StrInterface {
  numberFormats: NumberFormat = {
    PERCENTAGE: 'percentage',
    NUMBER: 'number',
    CURRENCY: 'currency',
  };

  protected locale: string;

  constructor() {
    this.locale = i18n.global.locale.value || 'es';
  }

  public formatDate = (date: any, format = 'dd/MM/yyyy', locale = 'es') =>
    DateHelper.parse(date).toUTC().setLocale(locale).toFormat(format);

  public formatDateTime = (date: any, format = 'dd/MM/yyyy HH:mm', locale = 'es') =>
    DateHelper.parse(date).toUTC().setLocale(locale).toFormat(format);

  public formatNumber(
    num: any,
    format: string,
    { locale, currency, decimals, prefix, suffix, maxDigits, minDigits }: any = {},
  ) {
    const tmpLocale: string = locale || this.locale;

    if (format === this.numberFormats.PERCENTAGE) {
      return this.numberToPercent(Number.parseFloat(num), tmpLocale, prefix, suffix, maxDigits, minDigits);
    }
    if (format === this.numberFormats.NUMBER) {
      return this.numberToNumber(Number.parseFloat(num), tmpLocale, decimals, prefix, suffix);
    }
    if (format === this.numberFormats.CURRENCY) {
      return this.numberToCurrency(Number.parseFloat(num), tmpLocale, currency, prefix, suffix);
    }

    return num;
  }

  /**
   * Returns the provided amount of seconds as a minutes and seconds counter.
   * <ul><li>2m 30sec</li></ul>
   *
   * @param seconds
   * @param minutesUnit
   * @param secondsUnit
   * @returns {string}
   */
  public minutesAndSeconds = (seconds: any, minutesUnit: any, secondsUnit: any) => {
    // TODO: Move this functionality to UQDate.js
    const time: Duration = Duration.fromObject({ seconds: Number.parseInt(seconds, 10) });

    return time.toFormat(`mm'${minutesUnit || 'm'}' ss'${secondsUnit || 's'}`);
  };

  /**
   * Returns the provided amount of hours, minutes and seconds individually.
   * <ul><li>1h 2m 30sec</li></ul>
   *
   * @param seconds
   * @returns {string}
   */
  public parseSeconds = (seconds: string | number) => {
    if (typeof seconds === 'number') {
      seconds = String(seconds);
    }
    // TODO: Move this functionality to UQDate.js
    const time = Duration.fromObject({ seconds: Number.parseInt(seconds, 10) });

    const x = time.toFormat('h@m@s').split('@');

    return {
      hours: Number.parseInt(x[0], 10),
      minutes: Number.parseInt(x[1], 10),
      seconds: Number.parseInt(x[2], 10),
    };
  };

  /**
   * Formats the received number as a percent. Number must be between 0 and 100.
   * <ul><li>20.45%</li></ul>
   *
   * @param num
   * @param locale
   * @param prefix
   * @param suffix
   * @returns {string|*}
   */
  public numberToPercent = (num: any, locale: any, prefix = '', suffix = '', maxDigits = 2, minDigits = 2) => {
    if (num < 0 || num > 100) {
      // TODO Mejorar procesos de errores
      return num;
    }

    return (
      (prefix || '')
      + (num / 100).toLocaleString(locale, {
        style: 'percent',
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      })
      + (suffix || '')
    );
  };

  /**
   * Formats the received number as thousand number with a dot as separator.
   * <ul><li>20.45%</li></ul>
   *
   * @param num
   * @returns {string|*}
   */
  public formatNumberToThousand = (num: any) => {
    if (typeof num === 'number') {
      num = num.toString();
    }

    return num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  /**
   * Formats the received number with the received locale.
   *
   * @param num
   * @param locale
   * @param decimals
   * @param prefix
   * @param suffix
   * @returns {string}
   */
  public numberToNumber = (num: any, locale: any, decimals = 2, prefix = '', suffix = '') => {
    let tmpNum = num;

    if (decimals !== null) {
      tmpNum = Number.parseFloat(tmpNum).toFixed(decimals);
    }

    tmpNum = num.toLocaleString();

    return (prefix || '') + tmpNum + (suffix || '');
  };

  /**
   * Formats a string variable into littleCamelCase
   * @param str
   * @returns {string}
   */
  public stringToCamel = (str: string) =>
    str
      .toLowerCase()
      .replace(/^\w|[A-Z]|\b\w/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
      .replace(/\s+/g, '');

  /**
   * Formats a snake_case variable into littleCamelCase
   * @param str
   * @returns {string}
   */
  public snakeToLittleCamel = (str: string) => str.replace(/[^a-z0-9]+(.)/gi, (m, chr) => chr.toUpperCase());

  /**
   * Formats a snake_case variable into CamelCase
   * @param str
   * @returns {string}
   */
  public snakeToCamel = (str: string) => {
    const result = this.snakeToLittleCamel(str);

    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  /**
   * Formats a camelCase variable into snake_case
   * @param str
   * @returns {string}
   */
  public camelToSnake = (str: string) => str.replace(/[A-Z]/g, c => `_${c.toLowerCase()}`);

  /**
   * Recursively converts all keys in an object from camelCase to snake_case
   * @param obj
   * @returns {object}
   */
  public convertKeysToSnakeCase = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(item => this.convertKeysToSnakeCase(item));
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const snakeCaseKey = this.camelToSnake(key);
        acc[snakeCaseKey] = this.convertKeysToSnakeCase(obj[key]);
        return acc;
      }, {} as any);
    } else {
      return obj;
    }
  };

  /**
   * Formats to camelCase any object given from snake_case
   * @returns {string}
   * @param obj
   */
  public convertKeysToCamelCase = (obj: any): any => {
    const newObj: any = {};

    Object.keys(obj).forEach((key) => {
      const camelKey = this.snakeToLittleCamel(key);

      if (obj[key] !== null && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        newObj[camelKey] = this.convertKeysToCamelCase(obj[key]);
      } else {
        newObj[camelKey] = obj[key];
      }
    });

    return newObj;
  };

  /**
   * Formats the received number with the received locale and currency.
   *
   * @param num
   * @param locale
   * @param currency
   * @param prefix
   * @param suffix
   * @returns {string}
   */
  public numberToCurrency(num: any, locale: any, currency: any, prefix = '', suffix = '') {
    return (
      (prefix || '')
      + num.toLocaleString(locale, {
        style: 'currency',
        currency,
      })
      + (suffix || '')
    );
  }

  /**
   * Formats a byte value into a human-readable string.
   *
   * This function takes a numeric byte value and converts it into a formatted string that appends
   * the appropriate unit (B, KB, MB, GB, TB, PB). The byte value is divided by 1024 repeatedly
   * until it is less than 1024, and then the appropriate unit is appended.
   *
   * @param bytes The byte value to format. Must be a non-negative number.
   * @returns A string representing the byte value with the appropriate unit appended.
   * @throws {Error} Will throw an error if the input bytes is negative.
   */
  public formatBytes(bytes: number | string): string {
    let localBytes = typeof bytes === 'string' ? +bytes : bytes;

    if (localBytes < 0) {
      throw new Error('Invalid input: bytes cannot be negative');
    }

    if (localBytes === 0) {
      return '0 B';
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let i;

    for (i = 0; localBytes > 1024; i++) {
      localBytes /= 1024;
    }

    return `${localBytes.toFixed(2)} ${units[i]}`;
  }

  /**
   * Converts a size in bytes to another unit.
   * @param value
   * @param actualUnit
   * @param newUnit
   * @returns The value converted according to the units provided
   * @throws {Error} Will throw an error if any of the units are invalid.
   */
  public formatNumberToByteEquivalences(value: number, actualUnit: string, newUnit: string): number {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const actualIndex = units.indexOf(actualUnit.toUpperCase());
    const newIndex = units.indexOf(newUnit.toUpperCase());

    if (actualIndex === -1 || newIndex === -1) {
      throw new Error('The units provided are not valid.');
    }

    const difference = newIndex - actualIndex;

    const factor = 1024 ** Math.abs(difference);

    return difference < 0 ? value * factor : value / factor;
  }

  /**
   * Parses an integer to a decimal number.
   *
   * @param {number} value - The integer to be parsed.
   * @returns {number} - The parsed decimal number.
   */
  public parseIntegerToDecimal(value: number): string | number {
    const dividedValue = value / 100;

    if (dividedValue % 1 === 0) {
      return dividedValue;
    }

    return dividedValue.toFixed(2);
  }

  /**
   * Format the received number to the decimal data and the current locale.
   *
   * This function takes a number and if it has decimals, rounds them to the number of digits specified.
   * You can also pass the parameter 'alwaysShowDecimals' to true to ALWAYS show the decimals.
   *
   * @param num
   * @param digits
   * @param alwaysShowDecimals
   * @param prefix
   * @param suffix
   */

  public formatDecimals(num: number, digits: number, alwaysShowDecimals = false, prefix = '', suffix = ''): string {
    if (alwaysShowDecimals || num % 1 !== 0) {
      return (
        (prefix || '')
        + num.toLocaleString(this.locale, {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        })
        + (suffix || '')
      );
    }

    return (prefix || '') + num + (suffix || '');
  }

  public formatNumberToCompact(num: number, locale: LanguagesType = 'en'): string {
    return num.toLocaleString(locale, {
      notation: 'compact',
    });
  }
}

const createFormatter: Plugin = {
  install(app: App) {
    const strInstance = new StringHelper();
    app.config.globalProperties.$str = strInstance;
    app.provide('str', strInstance);
  },
};

export { StringHelper };

export default createFormatter;
