export default {
  generation: {
    createdBy: 'Report created with',
    download: 'Download PDF',
  },
  overview: {
    globalState: 'Global overview',
    updates: 'Updates',
    plugins: 'Plugins',
    themes: 'Themes',
    wordpress: 'WordPress',
    translations: 'Translations',
    uptimeMonitor: 'Uptime monitor',
    state: 'Current status',
    percentUptime: '% Uptime',
    backups: 'Backups',
    activated: 'Activated',
    periodicity: 'Periodicity',
    backupsDone: 'Backups done',
    metrics: 'Metrics',
    performance: 'Performance',
    deviceTrackingTraffic: 'Traffic',
    byDevice: 'By device',
    customWorks: 'Other maintenance tasks',
    annotations: 'Annotations',
  },
  updates: {
    title: 'Updates',
    updatesDone: 'Has been updated:',
    name: 'Name',
    date: 'Date',
    version: 'Version',
    updatedItems: 'Updated { number } times',
  },
  uptime: {
    title: 'Uptime Monitoring',
    averageUptime: 'Average Uptime',
    upSince: 'Up since',
    checkDurationDaysHours: '{ days }d { hours }h',
    lastDown: 'Last Down',
    lastDays: 'Last {days} days',
    lastHours: 'Last {hours}h',
    activity: {
      uptime: 'Uptime',
      downtime: 'Downtime',
      title: 'Activity',
      lastHours: 'Last { number }h',
      statusFor: '{ status } since',
      upFor: 'Running for',
      downFor: 'Inactive since',
      unknownFor: 'Unknown since',
      pausedFor: 'Paused since',
      activityChanges: 'Activity changes',
      log: 'Log',
      responseTime: 'Response time { unit }',
      uptimePercentage: 'Uptime {min}-{max}%',
      uptimeUnknown: 'Unknown',
      up: 'Working',
      down: 'Inactive',
      unknown: 'Unknown',
      paused: 'Paused',
      upShort: 'Up',
      downShort: 'Down',
      uptimeStatus: 'Uptime status',
    },
  },
  backup: {
    title: 'Backups',
    lastBackup: 'Last backup',
    wpVersion: 'WP version',
    numPlugins: 'Plugins',
    size: 'Size',
    activeTheme: 'Active theme',
    postPublish: 'Post published',
  },
  analytics: {
    title: 'Analytics',
    visits: 'Page views',
    users: 'Users',
    page: 'Page',
    numberOfVisits: 'Nº of visits',
    newUsers: 'New users',
    numberOfUsers: 'Nº of users',
    recurrent: 'Returning users',
    bounceRate: 'Bounce Rate',
    bounceRateAlt: 'Bounce Rate',
    sessionLength: 'Session length',
    popularContent: 'Popular content',
    originChannel: 'Source channel',
    channel: 'Channel',
    citiesOfOrigin: 'Cities of origin',
    city: 'City',
    countriesOfOrigin: 'Countries of origin',
    country: 'Country',
    distributionDevice: 'Distribution by device',
    smartphone: 'Smartphone',
    desktop: 'Desktop',
  },
  performance: {
    title: 'Performance',
    updatedAt: 'Updated { date }',
  },
  customWorks: {
    title: 'Other tasks',
  },
  searchConsole: {
    title: 'Google search results',
    topQueries: 'Top 5 main queries',
    topPages: 'Top 5 main pages',
    topCountries: 'Top 5 top countries',
    topDevices: 'Top devices',
    topDates: 'Top 5 main dates',
    totalClicks: 'Total clicks',
    impressions: 'Impressions',
    averageCtr: 'Average CTR',
    averagePosition: 'Average Position',
    query: 'Query',
    queries: 'Queries',
    pages: 'Pages',
    page: 'Page',
    countries: 'Countries',
    country: 'Country',
    appearanceSearches: 'Appearance in Searches',
    dates: 'Dates',
    devices: 'Devices',
    device: 'Device',
    date: 'Date',
    clicks: 'Clicks',
    ctr: 'CTR',
    position: 'Position',
    desktop: 'Desktop',
    mobile: 'Mobile',
    tablet: 'Tablet',
    clicksTooltip:
      'Total clicks indicates the number of times users<br/>clicked to reach your website. The way this value is<br/>calculated depends on the type of search result it is.',
    impressionsTooltip:
      'Total impressions indicates the number of times a<br/>link to your website has been seen in the search results.<br/>The way this value is calculated varies depending on<br/>whether the results are images or other types of results,<br/>as in some cases it takes into account whether users<br/>have to scroll down the results page to get to the results.',
    ctrTooltip:
      'The average CTR is the percentage of impressions<br/>that generated clicks.',
    positionTooltip:
      'Average position indicates in which position in the<br/>search results your website appears on average.<br/>This is calculated by taking into account the highest<br/>position the website has each time it is displayed in<br/>the search results. The positions of specific pages<br/>are listed in the table below the chart. The use of some<br/>search functions, such as carousels or information<br/>panels, complicates the task of determining the position.',
  },
};
