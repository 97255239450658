<script lang="ts" setup>
import type { Concept } from '@/types/models/Concept';
import type { ComputedRef, InjectionKey } from 'vue';
import type { ToastInterface } from 'vue-toastification';

import api from '@/api';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import useValidation from '@/composables/useValidation';
import useBillingStore from '@/stores/billingStore';
import { BillingCountryEnum, BillingCountryEnumArray } from '@/types/enums/BillingCountryEnum';

import Multiselect from '@vueform/multiselect';

import { useField } from 'vee-validate';

import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';

defineOptions({
  name: 'TheChangeBillingAccount',
});

withDefaults(
  defineProps<{
    enableModalEdit?: boolean
  }>(),
  {
    enableModalEdit: false,
  },
);

const emits = defineEmits<{
  billingAccountUpdated: [value: void]
  billingAccountUpdating: [value: void]
  editingBilling: [value: boolean]
}>();

const toast: InjectionKey<ToastInterface> | any = inject('toast');

const { t } = useI18n();

const billingStore = useBillingStore();

const disabledButton = ref(false);

const countries: ComputedRef<Array<{ value: string, name: string | null, tax: boolean }>> = computed(() => {
  return BillingCountryEnumArray.map((c: Concept) => ({
    value: String(c.key),
    name: c.name,
    tax: !!c.value && !!c.value.stripeTax && c.value.taxRequired,
  }));
});

const rules = yup.object({
  fiscalName: yup.string().required().nullable(),
  idIdentity: yup
    .string()
    .nullable()
    .when('country', (country, schema) => {
      const [currentSchemaCountry] = country;
      const countryData = countries.value.find(c => c.value === currentSchemaCountry);

      return countryData && countryData?.tax ? schema.required() : schema;
    }),
  line1: yup.string().required().nullable(),
  line2: yup.string().nullable(),
  email: yup.string().email().nullable(),
  country: yup.string().required().nullable(),
  state: yup.string().required().nullable(),
  city: yup.string().required().nullable(),
  postalCode: yup.string().required().nullable(),
});

let initialValues = {};

const storedBillingAccount = computed(() => billingStore.billingAccount);

const editingBilling = ref(!storedBillingAccount.value);

if (!!storedBillingAccount.value) {
  initialValues = {
    fiscalName: storedBillingAccount.value.fiscalName,
    idIdentity: storedBillingAccount.value.idIdentity,
    line1: storedBillingAccount.value.line1,
    line2: storedBillingAccount.value.line2,
    email: storedBillingAccount.value.email,
    state: storedBillingAccount.value.state,
    city: storedBillingAccount.value.city,
    postalCode: storedBillingAccount.value.postalCode,
    country: storedBillingAccount.value.country || '',
  };
}

const form = useValidation(rules, initialValues);
const { value: fiscalName } = useField<string>('fiscalName');
const { value: idIdentity } = useField<string>('idIdentity');
const { value: line1 } = useField<string>('line1');
const { value: line2 } = useField<string>('line2');
const { value: email } = useField<string>('email');
const { value: country } = useField<string>('country');
const { value: state } = useField<string>('state');
const { value: city } = useField<string>('city');
const { value: postalCode } = useField<string>('postalCode');

const currentCountry = computed(() => countries.value.find(c => c.value === country.value));

function reloadValues() {
  if (!!storedBillingAccount.value) {
    initialValues = {
      fiscalName: storedBillingAccount.value.fiscalName,
      idIdentity: storedBillingAccount.value.idIdentity,
      line1: storedBillingAccount.value.line1,
      line2: storedBillingAccount.value.line2,
      email: storedBillingAccount.value.email,
      state: storedBillingAccount.value.state,
      city: storedBillingAccount.value.city,
      postalCode: storedBillingAccount.value.postalCode,
      country: storedBillingAccount.value.country || '',
    };

    form.setValues(initialValues);
  }
}

const { validate, toggleAccepted, setErrors, accepted, errors } = form;

async function onSubmit(showToast = true) {
  try {
    const valid = await validate();

    if (!valid.valid || (!valid.valid && editingBilling.value)) {
      editingBilling.value = false;

      emits('editingBilling', editingBilling.value);
    }

    toggleAccepted();
    disabledButton.value = true;
    emits('billingAccountUpdating');

    const values = {
      fiscal_name: fiscalName.value,
      country: country.value,
      line1: line1.value,
      line2: line2.value,
      email: email.value,
      state: state.value,
      city: city.value,
      postal_code: postalCode.value,
      id_identity: idIdentity.value ?? '',
    };

    await api.organization.billing.create(values);

    await billingStore.getBilling();

    toggleAccepted();
    disabledButton.value = false;
    editingBilling.value = false;

    await api.mixpanel.track('Change billing address');

    if (showToast) {
      toast.success(t('general.shared.updatedData'));
    }

    emits('billingAccountUpdated');
    emits('editingBilling', editingBilling.value);
  } catch (e: any) {
    toggleAccepted();
    disabledButton.value = false;

    if (!!e?.response?.data?.errors) {
      setErrors(e.response.data.errors);
    } else {
      toast.error(t('general.shared.error'));
    }

    throw e;
  }
}

watch(
  () => billingStore.billingAccount,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      reloadValues();
    }
  },
);

defineExpose({
  onSubmit,
});
</script>

<template>
  <div id="the-change-billing-account">
    <Component :is="enableModalEdit ? ModalBody : 'div'">
      <form
        v-if="(!!storedBillingAccount && !storedBillingAccount.isFilled) || enableModalEdit || editingBilling"
        id="billing-form"
        class="row gx-12"
        @submit.prevent="onSubmit()"
      >
        <div class="col-12 mb-12">
          <Multiselect
            v-model="country"
            :allow-absent="false"
            :can-clear="false"
            :can-deselect="false"
            :class="{ 'is-invalid border-danger border border-2': !!errors.country }"
            :classes="{ caret: 'icon-chevron-down text-sm icon' }"
            :object="false"
            :options="countries"
            :placeholder="$t('general.shared.selectCountry')"
            :searchable="true"
            label="name"
          />

          <span v-if="!!errors.country" class="invalid-feedback">
            {{ errors.country }}
          </span>
        </div>

        <div class="col-6 mb-12">
          <VInputTextFloating
            id="fiscalName"
            v-model="fiscalName"
            :label="`${$t('billing.team.bussinessName')} *`"
            :placeholder="$t('billing.team.bussinessName')"
            :yup-errors-variable="errors.fiscal_name || errors.fiscalName"
            autocomplete="off"
          />
        </div>

        <div class="col-12 col-lg-6 mb-12">
          <VInputTextFloating
            id="idIdentity"
            v-model="idIdentity"
            :label="`${$t('billing.team.nif')} ${currentCountry?.tax ? '*' : ''}`"
            :placeholder="$t('billing.team.nif')"
            :yup-errors-variable="errors.id_identity || errors.idIdentity"
            autocomplete="off"
          />
        </div>

        <div class="col-12 mb-12">
          <VInputTextFloating
            id="email"
            v-model="email"
            :label="`${$t('auth.shared.email')}`"
            :placeholder="$t('auth.shared.email')"
            :yup-errors-variable="errors.email"
            type="email"
            @keyup.enter="onSubmit"
          />
        </div>

        <div class="col-12 mb-12">
          <VInputTextFloating
            id="line1"
            v-model="line1"
            :label="`${$t('billing.team.direction1')} *`"
            :placeholder="$t('billing.team.direction1')"
            :yup-errors-variable="errors.line1"
            autocomplete="off"
            @keyup.enter="onSubmit"
          />
        </div>

        <div class="col-12 mb-12">
          <VInputTextFloating
            id="line2"
            v-model="line2"
            :label="$t('billing.team.direction2')"
            :placeholder="$t('billing.team.direction2')"
            :yup-errors-variable="errors.line2"
            autocomplete="off"
            @keyup.enter="onSubmit"
          />
        </div>

        <div class="col-12 col-lg-6 mb-12">
          <VInputTextFloating
            id="postalCode"
            v-model="postalCode"
            :label="`${$t('billing.team.postalCode')} *`"
            :placeholder="$t('billing.team.postalCode')"
            :yup-errors-variable="errors.postal_code || errors.postalCode"
            autocomplete="off"
            @keyup.enter="onSubmit"
          />
        </div>

        <div class="col-12 col-lg-6 mb-12">
          <VInputTextFloating
            id="city"
            v-model="city"
            :label="`${$t('billing.team.city')} *`"
            :placeholder="$t('billing.team.city')"
            :yup-errors-variable="errors.city"
            autocomplete="off"
            @keyup.enter="onSubmit"
          />
        </div>

        <div class="col-12">
          <VInputTextFloating
            id="state"
            v-model="state"
            :label="`${$t('billing.team.province')} *`"
            :placeholder="$t('billing.team.province')"
            :yup-errors-variable="errors.state"
            autocomplete="off"
            @keyup.enter="onSubmit"
          />
        </div>
      </form>

      <div v-else-if="!!storedBillingAccount">
        <div class="row">
          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('billing.team.bussinessName') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.fiscalName }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('billing.team.nif') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.idIdentity }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('auth.shared.email') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.email }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('general.shared.country') }}
            </p>

            <p class="fw-semi mb-0">
              {{ BillingCountryEnum[storedBillingAccount.country].name }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('billing.team.direction1') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.line1 }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('billing.team.direction2') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.line2 }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('billing.team.postalCode') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.postalCode }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('billing.team.province') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.state }}
            </p>
          </div>

          <div class="col-6 mb-16 text-sm">
            <p class="fw-medium mb-4 text-gray-500">
              {{ $t('billing.team.city') }}
            </p>

            <p class="fw-semi mb-0">
              {{ storedBillingAccount.city }}
            </p>
          </div>
        </div>
      </div>
    </Component>

    <Component :is="enableModalEdit ? ModalFooter : 'div'">
      <VButton
        v-if="enableModalEdit"
        :class="{ 'd-flex ms-auto mt-32': !enableModalEdit }"
        :disabled="disabledButton"
        :is-loading="accepted"
        size="sm"
        variant="primary"
        @click="onSubmit"
      >
        {{ $t('general.button.save') }}
      </VButton>

      <template v-else>
        <VButton
          v-if="!storedBillingAccount?.isFilled || editingBilling"
          :disabled="disabledButton"
          :is-loading="accepted"
          class="d-flex ms-auto mt-32"
          size="sm"
          variant="primary"
          @click="onSubmit"
        >
          {{ $t('billing.team.saveBillingData') }}
        </VButton>

        <VButton
          v-else
          class="d-flex ms-auto mt-32"
          size="sm"
          variant="link"
          @click="
            editingBilling = true;
            $emit('editingBilling', true);
          "
        >
          {{ $t('billing.team.editBillingData') }}
        </VButton>
      </template>
    </Component>
  </div>
</template>
