import type { RouteRecordRaw } from 'vue-router';

import TheMenuDashboard from '@/components/global/menu/TheMenuDashboard.vue';

import VCheckoutLayout from '@/layouts/VCheckoutLayout.vue';
import TheBillingSidebar from '@/views/billing/parts/sidebar/TheBillingSidebar.vue';

const billingRoutes: Array<RouteRecordRaw> = [
  {
    path: 'billing',
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'billing.show',
        components: {
          default: () => import('@/views/billing/TheBilling.vue'),
          menu: TheMenuDashboard,
          sidebar: TheBillingSidebar,
        },
        meta: {
          requiresAuth: true,
          events: {
            organization: ['.subscription.updated.confirmed'],
          },
        },
        props: {
          default: true,
        },
      },
      {
        path: 'plans',
        component: VCheckoutLayout,
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'plan-selection',
            name: 'billing.plans.select',
            components: {
              default: () => import('@/views/billing/TheBillingPlanSelection.vue'),
            },
            meta: {
              requiresAuth: true,
              isModalView: true,
              events: {
                organization: ['.subscription.updated.confirmed'],
              },
            },
          },
          {
            path: 'checkout',
            name: 'billing.plans.checkout',
            components: {
              default: () => import('@/views/billing/TheBillingCheckout.vue'),
            },
            meta: {
              requiresAuth: true,
              isModalView: true,
              events: {
                organization: ['.subscription.updated.confirmed'],
              },
            },
          },
          {
            path: 'checkout/payment',
            name: 'billing.plans.checkout.payment',
            components: {
              default: () => import('@/views/billing/TheBillingCheckoutPayment.vue'),
            },
            meta: {
              requiresAuth: true,
              isModalView: true,
              events: {
                organization: ['.subscription.updated.confirmed'],
              },
            },
          },
          {
            path: 'checkout/confirm',
            name: 'billing.plans.checkout.confirm',
            components: {
              default: () => import('@/views/billing/TheBillingCheckoutConfirm.vue'),
            },
            meta: {
              requiresAuth: true,
              isModalView: true,
              events: {
                organization: ['.subscription.updated.confirmed'],
              },
            },
          },
        ],
      },
    ],
  },
];

export default billingRoutes;

export { billingRoutes };
