<script lang="ts" setup>
import { cn } from '@/helpers/classNames';
import {
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue';
import { computed, type HTMLAttributes, ref } from 'vue';

defineOptions({
  name: 'DialogContent',
});

const props = withDefaults(defineProps<DialogContentProps & {
  id?: HTMLAttributes['id'] | null
  class?: HTMLAttributes['class']
  size?: '' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
  background?: 'gray-200' | 'light'
  elevated?: boolean
  persistent?: boolean
  overlayScrollable?: boolean
  bodyScrollable?: boolean
}>(), {
  id: null,
  class: '',
  size: '',
  background: 'light',
  elevated: false,
  persistent: false,
  overlayScrollable: false,
  bodyScrollable: false,
});

const emits = defineEmits<DialogContentEmits>();

const sizeComputed = computed(() => ({
  'ui-dialog-content-sm': props.size === 'sm',
  'ui-dialog-content-md': props.size === 'md',
  'ui-dialog-content-lg': props.size === 'lg',
  'ui-dialog-content-xl': props.size === 'xl',
  'ui-dialog-content-full': props.size === 'full',
}));

const isFullModal = computed(() => props.size === 'full');

const backgroundComputed = computed(() => ({
  'gray-200': 'bg-gray-200',
  'light': 'bg-light',
})[props.background],
);

const delegatedProps = computed(() => {
  const {
    class: _,
    size,
    background,
    ...delegated
  } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const persistentDialog = ref(false);

function animatePersistentDialog() {
  persistentDialog.value = true;

  setTimeout(() => {
    persistentDialog.value = false;
  }, 200);
}

function checkClosestClickedItem(event: Event) {
  if (props.persistent) {
    event.preventDefault();

    animatePersistentDialog();

    return false;
  }

  const target = event.target as HTMLElement;

  if (target?.closest('.Vue-Toastification__container'))
    return event.preventDefault();
  if (target?.closest('#md-auxiliar-modal'))
    return event.preventDefault();
  if (target?.closest('#__vue-devtools-container__'))
    return event.preventDefault();
  if (target?.closest('.modal[role="dialog"]'))
    return event.preventDefault();
}

function enableModalScroll(event: CustomEvent<{ originalEvent: PointerEvent }>) {
  const originalEvent = event.detail.originalEvent;
  const target = originalEvent.target as HTMLElement;
  if (originalEvent.offsetX > target.clientWidth || originalEvent.offsetY > target.clientHeight) {
    event.preventDefault();
  }
}
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="ui-dialog-overlay position-fixed ui-animate ui-animate-out"
      :class="[
        cn(props.elevated && 'ui-dialog-overlay-elevated', props.overlayScrollable && 'ui-dialog-overlay-scrollable'),
        { 'ui-dialog-overlay-disabled': isFullModal },
      ]"
    >
      <DialogContent
        :class="
          cn(
            'ui-dialog-content outline-none d-grid gap-32 border-none shadow-lg ui-animate ui-animate-out rounded-lg',
            props.elevated && 'ui-dialog-overlay-elevated',
            props.overlayScrollable && 'ui-dialog-overlay-scrollable',
            props.bodyScrollable && 'ui-dialog-body-scrollable',
            props.class,
            persistentDialog && 'ui-dialog-content-persistent',
            sizeComputed,
            backgroundComputed,
          )"
        aria-describedby="undefined"
        v-bind="forwarded"
        @interact-outside="checkClosestClickedItem($event)"
        @pointer-down-outside="enableModalScroll($event)"
      >
        <slot />
      </DialogContent>
    </DialogOverlay>
  </DialogPortal>
</template>
