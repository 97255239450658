import { cva, type VariantProps } from 'class-variance-authority'

export { default as Toggle } from './Toggle.vue'

export const toggleVariants = cva(
  'ui-toggle d-inline-flex align-items-center justify-content-center rounded-sm text-sm fw-medium transition-colors',
  {
    variants: {
      variant: {
        default: 'ui-toggle-group-item-default bg-transparent',
        primary: 'ui-toggle-primary',
        secondary: 'ui-toggle-secondary',
        outline:
          'ui-toggle-group-item-outline border bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground',
        dot: 'ui-toggle-dot rounded-pill border-transparent',
      },
      size: {
        // TODO Revisar el default
        default: '',
        sm: 'ui-toggle-sm px-8',
        lg: 'ui-toggle-lg px-12',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export type ToggleVariants = VariantProps<typeof toggleVariants>
