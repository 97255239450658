import { Concept } from '@/types/models/Concept';

export type SiteBackupItemTypeEnumKeys = 'all' | 'database' | 'core' | 'plugins' | 'themes' | 'uploads';

export const SiteBackupItemTypeEnumConst = {
  ALL: 'all',
  DATABASE: 'database',
  CORE: 'core',
  PLUGINS: 'plugins',
  THEMES: 'themes',
  UPLOADS: 'uploads',
} as const;

export const SiteBackupItemTypeEnum: Record<SiteBackupItemTypeEnumKeys, Concept> = {
  'all': {
    key: 'all',
    value: null,
    name: 'concept.site.backup.item.type.allName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.item.type'
  },
  'database': {
    key: 'database',
    value: null,
    name: 'concept.site.backup.item.type.databaseName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.item.type'
  },
  'core': {
    key: 'core',
    value: null,
    name: 'concept.site.backup.item.type.coreName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.item.type'
  },
  'plugins': {
    key: 'plugins',
    value: null,
    name: 'concept.site.backup.item.type.pluginsName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.item.type'
  },
  'themes': {
    key: 'themes',
    value: null,
    name: 'concept.site.backup.item.type.themesName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.item.type'
  },
  'uploads': {
    key: 'uploads',
    value: null,
    name: 'concept.site.backup.item.type.uploadsName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.backup.item.type'
  }
} as const;

export type SiteBackupItemTypeEnumType = (typeof SiteBackupItemTypeEnumConst)[keyof typeof SiteBackupItemTypeEnumConst];

export const SiteBackupItemTypeEnumArray = Object.values(SiteBackupItemTypeEnum);
