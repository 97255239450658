import type { PaymentMethod } from '@/types/models/organization/payment/PaymentMethod';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class PaymentService extends AbstractService {
  /**
   * Retrieves the default payment method of the organization.
   *
   * @returns {Promise<PaymentMethod>}
   */
  public retrieve(): Promise<PaymentMethod> {
    return this.request({
      method: apiEndpoints.paymentMethodsShow.method,
      url: this.buildPath(apiEndpoints.paymentMethodsShow),
    });
  }
}
