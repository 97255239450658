export default {
  faq1Title: '¿Cuántas webs puedo tener con mi plan contratado?',
  faq1Content:
    'Depende del plan que tengas contratado, podrás conectar a Modular un número de webs. Nuestro plan Starter permite conectar hasta 50 webs y nuestro plan Business webs ilimitadas.',
  faq2Title: '¿Puedo cancelar mi suscripción?',
  faq2Content:
    'Sí, puedes cancelar tu suscripción en cualquier momento y seguirás disfrutando de las funcionalidades y características de la misma hasta la próxima fecha de renovación.',
  faq3Title: '¿Cuándo se me cobra?',
  faq3Content:
    'Se te cobrará una vez al mes o una vez al año, dependiendo del ciclo de suscripción que elijas. Empezando el día que realizas la contratación del servicio.',
  faq4Title: '¿Qué métodos de pago están permitidos?',
  faq4Content:
    'Actualmente, puedes pagar tu suscripción a Modular con una tarjeta de débito o crédito, con Google Pay o con PayPal.',
  faq5Title: '¿Qué pasa si el plan de pago no me convence?',
  faq5Content:
    'Tienes 15 días para contactar con nosotros y decirnos que no quieres seguir con tu plan de pago y te devolveremos el dinero.',
  faq6Title: '¿Es Modular seguro?',
  faq6Content:
    'Sí. Usamos el protocolo oAuth2 para la autenticación de la conexión entre tu web y nuestros servidores. Gracias a este protocolo generamos tokens con caducidad y revocables en cualquier momento. Además, no almacenamos ninguna contraseña de usuario de WordPress.',
  faq7Title: '¿Cumple Modular con la GPRD?',
  faq7Content:
    'Sí, Modular cumple con la normativa europea de protección de datos y todos nuestros servidores y bases de datos están alojados en la Unión Europea. No vendemos tus datos a terceros ni lo haremos nunca.',
};
