import type { InstalledPayloadType, UpdatedPayloadType, UpgradedPayloadType } from '@/stores/manager';
import { StringHelper } from '@/helpers/stringHelper';

import useManagerStore from '@/stores/manager/managerStore';
import { EventSymbols } from '@/resources/symbols';
import useSiteStore from '@/stores/site/siteStore';

function handlerSocketEvents() {
  const handlerEvent = async (event: string, payload?: UpdatedPayloadType | UpgradedPayloadType | { site: string }) => {
    const managerStore = useManagerStore();
    const siteStore = useSiteStore();
    const str = new StringHelper();

    const parsedPayload = str.convertKeysToCamelCase(payload);

    switch (event) {
      case '.site.items.updated': {
        const localParsedPayload = parsedPayload as UpdatedPayloadType;

        managerStore.handleSocketEvent(localParsedPayload);

        break;
      }
      case '.site.items.managed': {
        const localParsedPayload = parsedPayload as UpgradedPayloadType;

        parsedPayload.siteItems = parsedPayload.siteItems.map(str.convertKeysToCamelCase);

        managerStore.handleActionSocketEvent(localParsedPayload);
        break;
      }
      case '.site.items.installed': {
        const localParsedPayload = parsedPayload as InstalledPayloadType;

        managerStore.handleInstalledItem(localParsedPayload);
        break;
      }
      case '.subscription.updated.confirmed': {
        break;
      }
      case '.site.health.updated': {
        const createSiteEventBus = useEventBus<string>(EventSymbols.HEALTH_UPDATED);

        if (!!siteStore.currentSiteSlug && !!parsedPayload.site && parsedPayload.site === siteStore.currentSiteSlug) {
          createSiteEventBus.emit(EventSymbols.HEALTH_UPDATED.toString(), location);
        }

        break;
      }
      default:
        console.error(event, payload);
    }
  };

  return { handlerEvent };
}

export { handlerSocketEvents };
export default handlerSocketEvents;
