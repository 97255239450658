export default {
  whiteLabel: {
    title: 'White Label',
    activeWhiteLabel: 'Enable White Label for the Modular Connector plugin on your websites',
    pluginConfiguration: 'Plugin Configuration',
    pluginName: 'Plugin name',
    pluginDescription: 'Plugin Description',
    authorName: 'Author\'s name',
    authorUrl: 'Author\'s URL',
    authorUrlInvalid: 'You have to add a valid URL, for example, https://www.myweb.com',
    wpPluginListPreview: 'WordPress plugins list preview',
    disableWhiteLabelTitle: 'Disable White Label',
    disableWhiteLabelText: 'This action will affect all your websites.',
    disableWhiteLabelDescription: 'By disabling the White Label, it will be applied to all your websites simultaneously.',
    disableWhiteLabelMessage: 'White Label deactivated. It may take a few minutes to be reflected in all your websites.',
    pluginExampleNameOne: 'Joinchat',
    pluginExampleNameTwo: 'Classic Editor',
    activate: 'Activate',
    deactivate: 'Deactivate',
    delete: 'Delete',
    connectionManager: 'Connection manager',
    pluginExampleDescription: 'This is an example to allow you to preview your changes.',
    pluginExampleVersion: 'Version 1.0.0',
    pluginExampleAuthor: 'By WordPress',
    pluginExampleViewDetails: 'View details',
    pluginAuthor:
      'By <a class="{ class }" href="{ href }" rel="noreferrer noopener" target="{ target }">{ author }</a>',
    pluginDefaultDescription:
      'Connect and manage your WordPress websites in an easier and more efficient way. Plugin and theme updates. Connection with Google Analytics to generate reports. And an application for your customer to understand and value your website. We call it #LaRevolucionDeLasWebs',
    modularConnector: 'Modular Connector',
    modularDS: 'Modular DS',
    toastMessage: 'Updated White Label settings for your account',
    alertInfoText:
      'The White Label will only work on sites that have the Modular Connector plugin updated to version 1.0.0 or higher.',
    empty: {
      emptyYTLink: 'https://www.youtube.com/embed/KZUM3PDjbUc',
      title: 'Modular DS plugin White Label',
      description:
        'Enable the White Label option and customize the Modular Connector plugin, essential for linking your WordPress site with Modular, with the information of your choice. This feature is ideal if you want to keep the use of Modular private from clients or collaborators, or to reinforce your brand image by customizing the plugin interface with your corporate data. Included in the Business plan.',
      becomeBusiness: 'Get Business',
      activate: 'Activate White Label',
    },
  },
  emailDomains: {
    link: 'DOMAINS & EMAILS',
    title: 'Customized domains | 1 custom domain | { count } custom domains',
    addDomain: 'Add custom domain',
    domain: 'Domain',
    dateInclusion: 'Date of inclusion',
    configureDomain: 'Show DNS configuration',
    removeDomain: 'Remove domain',
    selectThisDomain: 'Select as "ACTIVE"',
    inUse: 'In use',
    setDefaultToast: 'Now emails will be sent from the domain {domain}',
    deleteModalTitle: 'Do you want to delete this domain?',
    deleteModalText: 'If you delete this domain from Modular, you will not be able to use it as a sending domain in the scheduled maintenance reports.',
    deleteDomainCompleted: 'The domain has been deleted successfully',
    active: 'active',
    emailAddress: 'Email address',
    sendingAddress: 'Address from which you are sending',
    sendingAddressDescription:
      'The email address that will be shown as the sender in the sent reports. Add a custom domain to edit this address. Only the domain is editable.',
    freeSetDefaultToast: 'You have to have a PRO plan to select a custom domain name',
    create: {
      title: 'Add your domain',
      domainName: 'Your domain',
      domainNameText: 'You have to add a domain, for example, myweb.com or subdomain.myweb.com',
      domainNameInvalid: 'You have to add a valid domain, for example, myweb.com or subdomain.myweb.com',
      domainExistsError: 'You have already configured this domain. Try a different one.',
      toastMessage: 'Domain added successfully',
    },
    show: {
      title: 'DNS configuration',
      subtitle:
        'Add these DNS records to your domain provider or modify existing DNS records to verify that you are the owner of this domain.',
      verificationText: 'Domain verification can take up to 72 hours while changes to your records propagate.',
      type: 'Type',
      name: 'Name',
      value: 'Value',
      copy: 'Copy',
      pendingToastMessage:
        'We have not been able to verify the domain. DNS changes may take some time to be applied. Please try again in a few hours',
      successToastMessage: 'Domain successfully verified',
    },
    noResults: {
      title: 'You have not added any custom domains',
      subtitle: 'Add your domains to be able to change the email address to send your reports.',
      button: 'Add domain',
    },
  },
  accountsIntegrations: {
    title: 'Google accounts',
    connectedCount: 'Google Accounts | 1 account connected | {count} accounts connected',
    addAccount: 'Add Google account',
    addNewAccount: 'Add new account',
    table: {
      account: 'Account',
      associationDate: 'Association date',
      activeServices: 'Active services',
      reconnect: 'Reconnect',
      deleteAccount: 'Delete account',
      noAccess: 'No Access',
      noAccessText:
        'To use this account,<br> it is necessary to reconnect it by selecting<br> the required permissions.',
    },
    services: {
      search_console: 'Search Console',
      analytics: 'Analytics',
    },
    disconnectModal: {
      title: 'Delete Google Account',
      description:
        'If you disconnect your Google account, you will lose the connection to Analytics and Search Console on all the websites where you have linked a property or domain from this account.',
    },
    emptyState: {
      title: 'You have not connected any accounts at a global level',
      subtitle:
        'Add one to be able to share it across different websites and facilitate the process of connecting Analytics and Search Console on those sites.',
      button: 'Add Account',
    },
    associationModal: {
      selectAccount: 'Select existing account',
      editAccounts: 'Edit accounts',
      permissionAdvice:
        'You can only use accounts that have the Google service you are trying to connect to associated with them. If an account you have connected does not appear, re-add it by granting all permissions.',
    },
  },
};
