import type { App, Plugin } from 'vue';

import { POSITION, TYPE, useToast } from 'vue-toastification';

import 'vue-toastification/src/scss/index.scss';

export interface ToastPlugin {
  success: (message?: string) => void
  info: (message?: string) => void
  warning: (message?: string) => void
  error: (message?: string) => void
}
class Toast implements ToastPlugin {
  private toast = useToast();

  success(message = 'Operación realizada con éxito') {
    this.toast(message, {
      type: TYPE.SUCCESS,
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  info(message = 'Mensaje de información') {
    this.toast(message, {
      type: TYPE.INFO,
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  warning(message = 'Mensaje de adventencia') {
    this.toast(message, {
      type: TYPE.WARNING,
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  error(message = 'Error') {
    this.toast(message, {
      type: TYPE.ERROR,
      position: POSITION.BOTTOM_RIGHT,
    });
  }
}

const createToast: Plugin = {
  install(app: App) {
    const toastInstance = new Toast();
    app.config.globalProperties.$toast = toastInstance;
    app.provide('toast', toastInstance);
  },
};

export default createToast;
