import type { SimpleResponse } from '@/types/Api';
import type { SiteServiceHealth, SiteServiceHealthParams } from '@/types/models/site/service/health/SiteHealth';
import type { SiteInformation, SiteInformationGrouped } from '@/types/models/site/service/information/SiteInformation';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class HealthService extends AbstractService {
  /**
   * Retrieves the server health information for the current site.
   *
   * @param {number} serviceId
   * @param {SiteServiceHealthParams} params
   * @returns {Promise<SiteServiceHealth>} A promise that resolves to the server health information.
   */
  public async retrieve(serviceId: number, params?: SiteServiceHealthParams): Promise<SiteServiceHealth> {
    return this.request({
      method: apiEndpoints.healthServicesShow.method,
      url: this.buildPath(apiEndpoints.healthServicesShow, {
        health_service: serviceId,
      }),
      params,
    });
  }

  /**
   * Refreshes the server health information for the current site.
   *
   * @param {number} serviceId
   * @returns {Promise<SimpleResponse>} A promise that resolves to a simple response indicating the result of the refresh operation.
   */
  public refresh(serviceId: number): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.healthServicesRefresh.method,
      url: this.buildPath(apiEndpoints.healthServicesRefresh, {
        health_service: serviceId,
      }),
    });
  }

  /**
   * Returns server information from connected site
   *
   * @param {number} siteId
   * @returns {Promise<SiteInformation[]>}  A promise that resolves to the site information.
   */
  public async retrieveInformation(siteId: number): Promise<SiteInformationGrouped[]> {
    const response: SiteInformation[] = await this.request({
      method: apiEndpoints.healthServicesInformation.method,
      url: this.buildPath(apiEndpoints.healthServicesInformation, {
        site: siteId,
      }),
    });

    return response.reduce((acc: SiteInformationGrouped[], item: SiteInformation) => {
      const existingGroup = acc.find((group: SiteInformationGrouped) => group.type === item.type);

      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        acc.push({
          type: item.type,
          items: [item],
        });
      }

      return acc;
    }, [] as SiteInformationGrouped[]);
  }
}
