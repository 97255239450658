import type { Tag } from '@/types/models/organization/tag/Tag';
import type { Site } from '@/types/models/site/Site';
import type { MaybeRefOrGetter, Ref } from 'vue';
import useTagsStore from '@/stores/tag/tagsStore';

export function useSiteTags(site: MaybeRefOrGetter<Site | null>) {
  const tagsStore = useTagsStore();
  const siteTagsSelected: Ref<Tag[]> = ref([]);

  const localSite = computed(() => toValue(site));

  function syncTag(tagSynced: Tag) {
    if (!!localSite.value && !!localSite.value.id) {
      tagsStore.syncTag(tagSynced, localSite.value);
    }
  }

  // Sets the site tags on first load
  // Filters tags from the store to match the site tags, to avoid miss match between tags references
  watchEffect(() => {
    if (!!localSite.value?.tags && !!tagsStore.tags) {
      siteTagsSelected.value = tagsStore.tags.filter(
        tag => localSite.value?.tags?.some(
          siteTag => siteTag.id === tag.id,
        ),
      );
    }
  },
  );

  return {
    siteTagsSelected,
    syncTag,
  };
}
