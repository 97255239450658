<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

const props = defineProps<{
  class?: HTMLAttributes['class']
}>();
</script>

<template>
  <span class="fw-medium ms-auto text-sm text-gray-600" :class="[props.class]">
    <slot />
  </span>
</template>
