<script>
import { defineComponent } from 'vue';

/**
 * TODO DELETE ME
 */
export default defineComponent({
  name: 'ModalTitle',
  props: {
    as: {
      type: String,
      required: false,
      default: 'h5',
    },
  },
});
</script>

<template>
  <component :is="as" class="modal-title fw-semi text-lg" data-cy="modal-title">
    <slot />
  </component>
</template>
