import handlerSocketEvents from '@/socket/handlerSocketEvents';
import AbstractService from '@/socket/services/AbstractService';

export class OrganizationSocketService extends AbstractService {
  public listenEvents(events: string[], stop = false) {
    const { handlerEvent } = handlerSocketEvents();

    for (const event of events) {
      if (stop) {
        this.channel.stopListening(event);
      } else {
        this.channel.listen(event, (data: any) => handlerEvent(event, data));
      }
    }
  }

  /**
   * Event to listen if you have finished making the request to obtain all the items.
   * @param callback
   */
  public organizationPurchaseConfirmed(callback: (data: any) => void) {
    this.channel.listen('.subscription.updated.confirmed', callback);
  }

  /**
   * Event to stop listening if you have finished making the request to obtain all items.
   */
  public stopListeningOrganizationPurchaseConfirmed() {
    this.channel.stopListening('.subscription.updated.confirmed');
  }

  /**
   * Event to listen if site status has been updated
   */
  public organizationSiteConnectionUpdated() {
    return {
      listen: (callback: (data: any) => void) => this.channel.listen('.site.connection.updated', callback),
      stop: () => this.channel.stopListening('.site.connection.updated'),
    };
  }
}

export default (organizationID: string | number) => new OrganizationSocketService(`organization.${organizationID}`);
