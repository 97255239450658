import type { HttpClientOptions } from '@modular/sdk';
import { HttpClient } from '@modular/sdk';
// Service Imports
import AuthService from '@/api/services/AuthService';
import GeneralVulnerabilitiesService from '@/api/services/general/GeneralVulnerabilitiesService';
import MixpanelService from '@/api/services/MixpanelService';
import AlertingService from '@/api/services/organization/alerting/AlertingService';
import BillingService from '@/api/services/organization/billing/BillingService';
import ContactService from '@/api/services/organization/contact/ContactService';
import DomainService from '@/api/services/organization/domain/DomainService';
import ManagerStatusService from '@/api/services/organization/manager-status/ManagerStatusService';
import ManagerUpdateService from '@/api/services/organization/manager-update/ManagerUpdateService';
import OrganizationService from '@/api/services/organization/OrganizationService';
import PaymentService from '@/api/services/organization/payment/PaymentService';
import ProductService from '@/api/services/organization/product/ProductService';
import PublicReportService from '@/api/services/organization/public/PublicReportService';
import CheckoutService from '@/api/services/organization/subscription/checkout/CheckoutService';
import InvoiceService from '@/api/services/organization/subscription/invoice/InvoiceService';
import SubscriptionManageService from '@/api/services/organization/subscription/manage/SubscriptionManageService';
import SubscriptionService from '@/api/services/organization/subscription/SubscriptionService';
import TagsService from '@/api/services/organization/tags/TagsService';
import WhiteLabelService from '@/api/services/organization/white-label/WhiteLabelService';
import ConnectService from '@/api/services/site/connect/ConnectService';
import SiteManagerService from '@/api/services/site/manager/SiteManagerService';
import NoteService from '@/api/services/site/note/NoteService';
import AnalyticsService from '@/api/services/site/service/analytics/AnalyticsService';
import BackupService from '@/api/services/site/service/backup/BackupService';
import HealthService from '@/api/services/site/service/health/HealthService';
import PerformanceService from '@/api/services/site/service/performance/PerformanceService';
import AnnotationService from '@/api/services/site/service/report/annotation/AnnotationService';
import ManualReportService from '@/api/services/site/service/report/manual/ManualReportService';
import ScheduledReportService from '@/api/services/site/service/report/schedule/ScheduledReportService';
import TaskService from '@/api/services/site/service/report/task/ReportTaskService';
import SearchConsoleService from '@/api/services/site/service/search-console/SearchConsoleService';
import ServiceStatusService from '@/api/services/site/service/ServiceStatusService';
import UptimeService from '@/api/services/site/service/uptime/UptimeService';
import VulnerabilitiesService from '@/api/services/site/service/vulnerability/VulnerabilitiesService';
import SiteService from '@/api/services/site/SiteService';
import TreeService from '@/api/services/site/tree/TreeService';
import SocialAccountsService from '@/api/services/social/SocialAccountsService';
import TeamService from '@/api/services/team/TeamService';
import NotificationService from '@/api/services/user/notifications/NotificationService';
import UserSessionsService from '@/api/services/user/sessions/UserSessionsService';
import UserSocialService from '@/api/services/user/social/UserSocialService';
import TwoFactorService from '@/api/services/user/two-factor/TwoFactorService';
import UserService from '@/api/services/user/UserService';
import LicensesService from '@/api/services/organization/subscription/LicensesService';

class ApiClient extends HttpClient {
  public constructor(options: HttpClientOptions) {
    super(options);
  }

  public get auth(): AuthService {
    return new AuthService(this);
  }

  public get site() {
    return {
      connect: new ConnectService(this),
      manager: new SiteManagerService(this),
      general: new SiteService(this),
      note: new NoteService(this),
      service: {
        analytics: new AnalyticsService(this),
        status: new ServiceStatusService(this),
        backup: new BackupService(this),
        health: new HealthService(this),
        performance: new PerformanceService(this),
        searchConsole: new SearchConsoleService(this),
        uptime: new UptimeService(this),
        vulnerabilities: new VulnerabilitiesService(this),
        report: {
          manual: new ManualReportService(this),
          schedule: new ScheduledReportService(this),
          annotation: new AnnotationService(this),
        },
      },
      tree: new TreeService(this),
    };
  }

  public get report() {
    return new ManualReportService(this);
  }

  public get task() {
    return new TaskService(this);
  }

  public get team(): TeamService {
    return new TeamService(this);
  }

  public get user() {
    return {
      general: new UserService(this),
      sessions: new UserSessionsService(this),
      social: new UserSocialService(this),
      twoFactor: new TwoFactorService(this),
      notifications: new NotificationService(this),
    };
  }

  public get organization() {
    return {
      alerting: new AlertingService(this),
      accounts: new SocialAccountsService(this),
      billing: new BillingService(this),
      contact: new ContactService(this),
      domain: new DomainService(this),
      general: new OrganizationService(this),
      managerUpdate: new ManagerUpdateService(this),
      managerStatus: new ManagerStatusService(this),
      payment: new PaymentService(this),
      product: new ProductService(this),
      publicReport: new PublicReportService(this),
      subscription: {
        checkout: new CheckoutService(this),
        general: new SubscriptionService(this),
        licenses: new LicensesService(this),
        invoice: new InvoiceService(this),
        manage: new SubscriptionManageService(this),
      },
      whiteLabel: new WhiteLabelService(this),
      tags: new TagsService(this),
    };
  }

  public get mixpanel(): MixpanelService {
    return new MixpanelService(this);
  }

  public get general() {
    return {
      vulnerabilities: new GeneralVulnerabilitiesService(this),
    };
  }
}

export default ApiClient;
