import admin from '@/lang/locales/en/admin';
import alerting from '@/lang/locales/en/alerting';
import auth from '@/lang/locales/en/auth';
import billing from '@/lang/locales/en/billing';
import changelog from '@/lang/locales/en/changelog';
import concept from '@/lang/locales/en/concept';
import dashboard from '@/lang/locales/en/dashboard';
import discount from '@/lang/locales/en/discount';
import editor from '@/lang/locales/en/editor';
import faq from '@/lang/locales/en/faq';
import general from '@/lang/locales/en/general';
import health from '@/lang/locales/en/health';
import manager from '@/lang/locales/en/manager';
import menu from '@/lang/locales/en/menu';
import notifications from '@/lang/locales/en/notifications';
import opinion from '@/lang/locales/en/opinion';
import report from '@/lang/locales/en/report';
import searchConsole from '@/lang/locales/en/searchConsole';
import settings from '@/lang/locales/en/settings';
import site from '@/lang/locales/en/site';
import siteReports from '@/lang/locales/en/siteReports';
import statistics from '@/lang/locales/en/statistics';
import tags from '@/lang/locales/en/tags';
import team from '@/lang/locales/en/team';
import uploader from '@/lang/locales/en/uploader';
import user from '@/lang/locales/en/user';
import validation from '@/lang/locales/en/validation';
import vulnerability from '@/lang/locales/en/vulnerability';

export default {
  admin,
  auth,
  billing,
  changelog,
  concept,
  dashboard,
  discount,
  faq,
  general,
  health,
  manager,
  menu,
  opinion,
  report,
  searchConsole,
  settings,
  site,
  siteReports,
  statistics,
  tags,
  team,
  user,
  uploader,
  validation,
  vulnerability,
  alerting,
  notifications,
  editor,
};
