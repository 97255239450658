import type { PaginationResponse } from '@/types/Api';
import type { IComponent, IComponentSelection, WPComponentType } from '@/types/models/organization/manager/Component';
import type { IWPComponent, IWPPlooginsComponent } from '@/types/models/organization/manager/WPComponent';
import type { GlobalMetasType, PaginatedSiteItems } from '@/types/models/site/updater/SiteItem';
import type {
  ComponentCheckSelectionRequestType,
  ComponentManageRequestType,
  ComponentRequestType,
  SiteItemInstallationParams,
} from '@/views/manager';
import { StringHelper } from '@/helpers/stringHelper';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export interface ManagerCountAllowedActions {
  show: boolean
  hide: boolean
  upgrade: boolean
  deactivate: boolean
  activate: boolean
  delete: boolean
  connectorNotUpdated: boolean
}

export default class ManagerUpdateService extends AbstractService {
  private requestWithCleanup(params: ComponentRequestType): ComponentRequestType {
    ['type', 'include', 'only', 'sites', 'tags', 'wordpress', 'php', 'components'].forEach((key: keyof ComponentRequestType) => {
      const value = params[key];

      if (!!value && Array.isArray(value) && value.length === 0 || !params[key])
        delete params[key];
    });

    return params;
  }

  /**
   * Returns all Components
   *
   * @param {ComponentRequestType} params
   * @returns {Promise<IComponent[]>}
   */
  public async allComponents(params: ComponentRequestType): Promise<IComponent[]> {
    const localParams = this.requestWithCleanup(params);

    return await this.request({
      method: apiEndpoints.componentsIndex.method,
      url: this.buildPath(apiEndpoints.componentsIndex),
      params: localParams,
      options: {
        cancellable: true,
        cancelKey: 'manager-update-request',
      },
    });
  }

  /**
   * Returns all Site Items of a Component
   *
   * @param {ComponentRequestType} params
   * @param pagination
   * @returns {Promise<PaginatedSiteItems>}
   */
  public async retrieveSiteItems(params: ComponentRequestType, pagination: any): Promise<PaginatedSiteItems> {
    const localParams = this.requestWithCleanup(params);

    return await this.request({
      method: apiEndpoints.siteItemsIndex.method,
      url: this.buildPath(apiEndpoints.siteItemsIndex),
      params: { ...localParams, ...pagination },
      options: {
        paginate: true,
        cancellable: false,
      },
    });
  }

  /**
   * Returns all Site Items metas
   *
   * @param {ComponentRequestType} params
   * @returns {Promise<GlobalMetasType>}
   */
  public async retrieveMetas(params: ComponentRequestType): Promise<GlobalMetasType> {
    const localParams = this.requestWithCleanup(params);

    return await this.request({
      method: apiEndpoints.siteItemsMetas.method,
      url: this.buildPath(apiEndpoints.siteItemsMetas),
      params: localParams,
    });
  }

  /**
   * Returns selection count
   *
   * @param {ComponentManageRequestType} params
   * @returns {Promise<{count: number, allowedActions: ManagerCountAllowedActions}>}
   */
  public async retrieveSelectionCount(params: ComponentManageRequestType): Promise<{
    count: number
    allowedActions: ManagerCountAllowedActions
  }> {
    params.filters = this.requestWithCleanup(params.filters);

    return await this.request({
      method: apiEndpoints.managerItemsSelectionStatus.method,
      url: this.buildPath(apiEndpoints.managerItemsSelectionStatus),
      data: params,
      options: {
        cancellable: true,
        cancelKey: 'manager-update-count',
      },
    });
  }

  /**
   * Create a update request for the selected sites.
   *
   * @param {{sites: number[]} | null} data
   * @returns {Promise<any>}
   */
  public sync(data: { sites: number[] } | null): Promise<any> {
    return this.request({
      method: apiEndpoints.siteItemsUpdateReferences.method,
      url: this.buildPath(apiEndpoints.siteItemsUpdateReferences),
      data,
    });
  }

  /**
   * Manages the specified components.
   *
   * @param {ComponentManageRequestType} params
   * @returns {Promise<any>}
   */
  public manage(params: ComponentManageRequestType): Promise<any> {
    params.filters = this.requestWithCleanup(params.filters);

    return this.request({
      method: apiEndpoints.managerItemsManage.method,
      url: this.buildPath(apiEndpoints.managerItemsManage),
      data: params,
    });
  }

  /**
   * Processes to check the selection of components.
   *
   * @param {ComponentCheckSelectionRequestType} params
   * @returns {Promise<IComponentSelection[]>}
   */
  public checkSelection(params: ComponentCheckSelectionRequestType): Promise<IComponentSelection[]> {
    params.filters = this.requestWithCleanup(params.filters);

    return this.request({
      method: apiEndpoints.componentsCheckSelection.method,
      url: this.buildPath(apiEndpoints.componentsCheckSelection),
      data: params,
    });
  }

  /**
   * Installs the specified components.
   *
   * @param params
   * @returns {Promise<IComponentSelection[]>}
   */
  public install(params: SiteItemInstallationParams): Promise<IComponentSelection[]> {
    return this.request({
      method: apiEndpoints.siteItemsInstall.method,
      url: this.buildPath(apiEndpoints.siteItemsInstall),
      data: params,
    });
  }

  /**
   * Returns all plugins or themes of WordPress repository
   *
   * @param {WPComponentType} type
   * @param {{search: string, page: number, perPage: number}} params
   * @returns {Promise<PaginationResponse<IWPComponent>>}
   */
  public async allWComponents(type: WPComponentType, params: {
    search: string
    page: number
    perPage: number
  }): Promise<PaginationResponse<IWPComponent>> {
    const helper = new StringHelper();

    return await this.request({
      method: apiEndpoints.wordpressPluginIndex.method,
      url: this.buildPath(apiEndpoints.wordpressPluginIndex, { type }),
      params: helper.convertKeysToSnakeCase(params),
    });
  }

  /**
   * Returns all plugins of AI repository
   *
   * @param {{search: string, page: number, perPage: number}} params
   * @returns {Promise<PaginationResponse<IWPComponent>>}
   */
  public async allAiPlugins(params: {
    search: string
    page: number
    perPage: number
  }): Promise<PaginationResponse<IWPPlooginsComponent>> {
    const helper = new StringHelper();

    return await this.request({
      method: apiEndpoints.aiPluginIndex.method,
      url: this.buildPath(apiEndpoints.aiPluginIndex),
      params: helper.convertKeysToSnakeCase(params),
    });
  }
}
