export const PREMIUM_PLUGINS = [
  {
    key: 'wp-rocket',
    changelog: 'https://wp-rocket.me/changelog/',
  }, // WP Rocket
  {
    key: 'advanced-custom-fields-pro',
    changelog: 'https://www.advancedcustomfields.com/changelog/',
  }, // ACF
  {
    key: 'wp-seopress-pro',
    changelog:
      'https://www.seopress.org/support/changelogs/seopress-free-pro/',
  }, // SEOPress Pro
  {
    key: 'bb-ui-enhancements-1.1.1',
    changelog:
      'https://github.com/badabingbreda/bb-ui-enhancements/releases',
  }, // BB UI Enhancements
  {
    key: 'buddyboss-platform-pro',
    changelog:
      'https://www.buddyboss.com/resources/buddyboss-platform-releases/',
  }, // BuddyBoss Pro
  {
    key: 'buddyboss-platform',
    changelog:
      'https://www.buddyboss.com/resources/buddyboss-platform-releases/',
  }, // BuddyBoss Pro ; change slug
  {
    key: 'bb-code-settings-master',
    changelog: 'https://github.com/beaverbuilder/bb-code-settings',
  }, // Beaver Builder Code Settings
  {
    key: 'bb-plugin',
    changelog: 'https://www.wpbeaverbuilder.com/change-logs',
  }, // Beaver Builder Plugin (Standard Version) & Beaver Themer
  {
    key: 'happyfiles-pro',
    changelog: 'https://happyfiles.io/changelog',
  }, // HappyFiles Pro
  {
    key: 'perfmatters',
    changelog: 'https://perfmatters.io/docs/changelog',
  }, // Perfmatters
  {
    key: 'bbpowerpack',
    changelog: 'https://wpbeaveraddons.com/change-logs',
  }, // PowerPack for Beaver Builder
  {
    key: 'wpcodebox',
    changelog: 'https://wpcodebox.com/releases',
  }, // WP CodeBox
  {
    key: 'wp-job-manager',
    changelog:
      'https://wpjobmanager.com/changelog/wp-job-manager-applications',
  }, // WP Job Manager - Applications
  {
    key: 'elementor-pro',
    changelog: 'https://elementor.com/pro/changelog/',
  }, // Elementor Pro
  {
    key: 'presto-player-pro',
    changelog: 'https://prestoplayer.com/product/presto-player-pro/',
  }, // Presto Player Pro
  {
    key: 'yaymail-pro',
    changelog: 'https://docs.yaycommerce.com/yaymail/other-links/changelog',
  }, // YayMail Pro - WooCommerce Email Customizer
  {
    key: 'learndash-lms',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS
  {
    key: 'learndash-course-grid',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Course Grid
  {
    key: 'learndash-certificate-builder',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Certificate Builder
  {
    key: 'learndash-propanel',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - ProPanel
  {
    key: 'learndash-woocommerce',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - WooCommerce Integration
  {
    key: 'learndash-content-cloner',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash Content Cloner
  {
    key: 'learndash-group-registration',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash Group Registration
  {
    key: 'learndash-slack',
    changelog: 'https://www.learndash.com/changelog',
  }, // earnDash Slack Integration
  {
    key: 'learndash-achievements',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Achievements
  {
    key: 'learndash-notifications',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Notifications
  {
    key: 'ld-notifications',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Notifications
  {
    key: 'learndash-stripe',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Stripe Integration
  {
    key: 'learndash-zapier',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Zapier Integration
  {
    key: 'learndash-bbpress',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - bbPress Integration
  {
    key: 'learndash-integrity',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Integrity
  {
    key: 'learndash-memberpress',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - MemberPress Integration
  {
    key: 'learndash-paidmemberships',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Paid Memberships Pro
  {
    key: 'learndash-samcart',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Samcart Integration
  {
    key: 'learndash-samcart',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - The Events Calendar Integration
  {
    key: 'learndash-ld-tec-bb06eaa90523',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - The Events Calendar Integration
  {
    key: 'learndash-elementor',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Elementor
  {
    key: 'learndash-event-espresso',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS - Event Espresso
  {
    key: 'sfwd-lms',
    changelog: 'https://www.learndash.com/changelog',
  }, // LearnDash LMS
  {
    key: 'woocommerce-subscriptions',
    changelog:
      'https://dzv365zjfbd8v.cloudfront.net/changelogs/woocommerce-subscriptions/changelog.txt',
  }, // WooCommerce Subscriptions:
  {
    key: 'wp-social-ninja-pro',
    changelog: 'https://wpsocialninja.com/docs/change-log/',
  }, // WP Social Ninja Pro:
  {
    key: 'use-your-drive',
    changelog:
      'https://www.wpcloudplugins.com/wp-content/plugins/use-your-drive/_documentation/index.html#releasenotes',
  }, // WP Cloud Plugin Use-your-Drive (Google Drive)
  {
    key: 'custom-facebook-feed-pro',
    changelog:
      'https://smashballoon.com/doc/custom-facebook-feed-change-log/',
  }, //  Custom Facebook Feed Pro Personal
  {
    key: 'wpforms',
    changelog:
      'https://wpforms.com/docs/how-to-view-recent-changes-to-the-wpforms-plugin-changelog/#Changelog',
  }, //  WPForms
  {
    key: 'event-tickets-plus',
    changelog:
      'https://www.theeventscalendar.com/category/release-notes/event-tickets-plus/',
  }, //  Event Ticket plus
  {
    key: 'sitepress-multilingual-cms',
    changelog:
      'https://wpml.org/download/wpml-multilingual-cms/?section=changelog',
  }, //  WPML Multilingual CMS
  {
    key: 'revslider',
    changelog:
      'https://www.sliderrevolution.com/documentation/changelog/',
  }, //  Slider Revolution
  {
    key: 'wpml-string-translation',
    changelog:
      'https://wpml.org/download/wpml-string-translation/?section=changelog',
  }, //  WPML String Translation
  {
    key: 'all-in-one-wp-migration-unlimited-extension',
    changelog:
      'https://wordpress.org/plugins/all-in-one-wp-migration/#developers',
  }, //  All-in-One WP Migration Unlimited Extension
  {
    key: 'jet-engine',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-engine',
  }, //  JetEngine
  {
    key: 'js_composer',
    changelog:
      'https://kb.wpbakery.com/docs/preface/release-notes/',
  }, //  WPBakery Page Builder
  {
    key: 'seo-by-rank-math-pro',
    changelog:
      'https://rankmath.com/changelog/',
  }, //  Rank Math SEO PRO
  {
    key: 'astra-addon',
    changelog:
      'https://wpastra.com/whats-new/',
  }, //  Astra Pro
  {
    key: 'gravityforms',
    changelog:
      'https://docs.gravityforms.com/gravityforms-change-log/',
  }, //  Gravity Forms
  {
    key: 'jet-elements',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-elements',
  }, //   JetElements For Elementor
  {
    key: 'gp-premium',
    changelog:
      'https://generatepress.com/category/changelog/',
  }, //  GP Premium
  {
    key: 'perfmatters',
    changelog:
      'https://perfmatters.io/docs/changelog/',
  }, //  Perfmatters
  {
    key: 'jet-smart-filters',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-smart-filters',
  }, //  JetSmartFilters
  {
    key: 'ultimate-elementor',
    changelog:
      'https://ultimateelementor.com/whats-new/',
  }, //  Ultimate Addons for Elementor
  {
    key: 'duplicator-pro',
    changelog:
      'https://duplicator.com/knowledge-base/changelog/',
  }, //  Duplicator Pro
  {
    key: 'wp-seo-multilingual',
    changelog:
      'https://wpml.org/download/yoast-seo-multilingual/?section=changelog',
  }, //  WPML SEO
  {
    key: 'envato-market',
    changelog:
      'https://github.com/envato/wp-envato-market?tab=readme-ov-file',
  }, //  Envato Market
  {
    key: 'wordpress-seo-premium',
    changelog:
      'https://developer.yoast.com/changelog/yoast-seo-premium/',
  }, //  Yoast SEO Premium
  {
    key: 'jet-menu',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-menu',
  }, //  JetMenu
  {
    key: 'jet-blocks',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-blocks',
  }, //  JetBlocks For Elementor
  {
    key: 'jet-blocks',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-blocks',
  }, //  JetTabs For Elementor
  {
    key: 'jet-tabs',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-tabs',
  }, //  JetBlocks For Elementor
  {
    key: 'jet-tricks',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-tricks',
  }, //  JetTricks
  {
    key: 'ithemes-security-pro ',
    changelog:
      'https://solidwp.com/blog/category/product-updates/',
  }, //  Solid Security Pro
  {
    key: 'contact-form-7-multilingual',
    changelog:
      'https://wpml.org/download/contact-form-7-multilingual/?section=changelog',
  }, //  Contact Form 7 Multilingual
  {
    key: 'divi-essential',
    changelog:
      'https://www.divinext.com/divi-essential-changelog/',
  }, //  Divi Essential
  {
    key: 'jet-blog',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-blog',
  }, //  JetBlog For Elementor
  {
    key: 'jet-theme-core',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-theme-core',
  }, //  JetThemeCore
  {
    key: 'jet-woo-builder',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-woo-builder',
  }, //  JetWooBuilder For Elementor
  {
    key: 'wp-all-import-pro',
    changelog:
      'https://www.wpallimport.com/downloads/wp-all-import-pro/?changelog=1',
  }, //  WP All Import Pro
  {
    key: 'essential-addons-elementor',
    changelog:
      'https://essential-addons.com/changelog/',
  }, //  Essential Addons for Elementor - Pro
  {
    key: 'js_composer_salient',
    changelog:
      'https://themenectar.com/changelogs/salient.html',
  }, //  Salient WPBakery Page Builder
  {
    key: 'jet-search',
    changelog:
      'https://crocoblock.com/changelog/?plugin=jet-search',
  }, //  JetSearch
  {
    key: 'wp-mail-smtp-pro',
    changelog:
      'https://wpmailsmtp.com/docs/how-to-view-recent-changes-to-the-wp-mail-smtp-plugin-changelog/',
  }, //  WP Mail SMTP Pro
  {
    key: 'generateblocks-pro',
    changelog:
      'https://generateblocks.com/category/changelog/',
  }, //  GenerateBlocks Pro
  {
    key: 'fusion-builder',
    changelog:
      'https://avada.com/documentation/avada-changelog/',
  }, //  Avada Builder
  {
    key: 'fusion-core',
    changelog:
      'https://avada.com/documentation/avada-changelog/',
  }, //  Avada Core
  {
    key: 'salient-core',
    changelog:
      'https://themenectar.com/changelogs/salient.html',
  }, //  Salient Core
  {
    key: 'ultimate_vc_addons',
    changelog:
      'https://ultimate.brainstormforce.com/changelog/',
  }, //  Ultimate Addons for WPBakery Page Builder
];
