export default {
  shared: {
    email: 'Email',
    emailLong: 'E-mail address',
    password: 'Password',
    confirmPassword: 'Confirm password',
    name: 'Name',
    lastName: 'Last name',
  },
  forgot: {
    receiveEmail: 'You will receive an email to recover your password at this address.',
    receiveEmailSpam: 'If you don\'t receive it, it may be in your spam folder.',
    email: 'Enter your email address',
    backLogin: 'Back to login',
    recover: 'Reset',
    resend: 'Resend',
    emailSent: 'We have just sent you the recovery email. Check your spam folder.',
    emailReSent: 'We have just resent you the recovery email. Check your spam folder.',
    emailSentToast: 'Mail sent successfully.',
  },
  login: {
    googleLogin: 'Log in with google',
    googleSignUp: 'Sign up with google',
    registerEmail: 'Sign up with your email',
    haveAccount: 'I already have an account',
    loggingIn: 'Logging in...',
    redirect: 'Redirecting...',
    userAlreadyExists: 'The specified user already exists in our database.',
    confirm2faTitle: 'Confirm access to your account',
    confirm2faText: 'Confirm access to your account with the 6-digit code from your authentication app',
    confirm2faDevice: 'Don\'t have your phone with you?',
    confirm2faDeviceId: 'Modular ID',
    confirm2faDeviceWhatIsRecoveryCode: 'What is a recovery code?',
    confirm2faDeviceWhatIsRecoveryCodeLink: 'https://support.modulards.com/en/recovery-code-2fa',
    unexpectedError: 'An unexpected error has occurred. Please try again later.',
  },
  loginEmail: {
    logIn: 'Log in',
    logInWithEmail: 'or log in with your email',
    remember: 'Remember me',
    forgot: 'I have forgotten my password',
    register: 'Register for free',
  },
  register: {
    signUp: 'Sign up',
    createAccount: 'Create an account',
    alreadyAccount: 'Already have an account? Sign in',
    passwordValidation:
      'Must be at least 8 characters, one uppercase, one lowercase, one number and one special character',
    wpRepository: 'In the WordPress repository',
    manageAllWebsites: 'Manage all your WordPress websites from a single place', // este
    subtitle: '+2.000 agencies and freelancers already rely on Modular',
    automateTasks: 'Automate your web maintenance tasks',
    loyalty: 'Build customer loyalty',
    moreContracts: 'Get more maintenance contracts',
    passwordDescription:
      'The password must be at least 8 characters, one uppercase, one lowercase, one number and one special character.',
    termsAndConditions: 'Terms and conditions',
    termsAndConditionsText:
      'I have read and accept the <a class="text-decoration-underline text-xs fw-medium text-gray-500" href="{termsAndConditionsLink}" target="_blank" aria-label="Terms and conditions">terms and conditions</a>, the <a class="text-decoration-underline text-xs fw-medium text-gray-500" href="{dataProcessingLink}" target="_blank" aria-label="Data processing">data processing</a> contract and the <a class="text-decoration-underline text-xs fw-medium text-gray-500" href="{privacyPolicyLink}" target="_blank" aria-label="Privacy policy">privacy policy</a>.',
    termsConditionsValidation: 'You must accept the terms and conditions to continue',
  },
  reset: {
    insertNewPassword: 'Enter the new password',
    createNewPassword: 'Create new password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    congratulations: 'Congratulations!',
    successfulRecover:
      'Your password has been successfully reset. You can now log in again login and enter your new credentials.',
  },
  logout: {
    title: 'Close all sessions',
    text: 'Upon confirmation, all your devices will be logged out and as a security measure we will deactivate your password. You will receive an email to reset your password.',
    errorMessageTitle: 'The link is no longer valid',
    errorMessageText: 'It might have expired or has already been used once.',
  },
};
