export default {
  jaimeGarmar:
    'We manage more than 100 client websites and with Modular everything is easy, not only because of what it does, but also because of the human support behind it.',
  martaTorre:
    'I tried Modular because it is a Spanish project and because I saw the design and I liked it a lot. Then I stayed for everything they have and that they listen a lot to users.',
  pabloMoratinos:
    'With Modular I have discovered one of the SaaS that takes into account the feedback of its customers the most to add new features.',
  sebastianEchevarri:
    'I take care of the web maintenance of my clients and nothing better than being able to control that everything is working correctly from a single site.',
};
