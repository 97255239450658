<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import { useWindowSize } from '@vueuse/core';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Field, Form, useField } from 'vee-validate';
import { object, string, boolean as yupBoolean } from 'yup';

import type { IOpinion } from '@/stores/organizationStore';
import useOrganizationStore from '@/stores/organizationStore';
import useUserStore from '@/stores/userStore';

import api from '@/api';

import useValidation from '@/composables/useValidation';
import { CookieHelper } from '@/helpers/cookieHelper';
import { getSupportedApplicationLang } from '@/helpers/langHelper';
import MInputPassword from '@/components/vendor/basic/form/MInputPassword.vue';
import LogoAppSumo from '@assets/img/as-white.svg';
import { UserSourceEnumConst } from '@/types/enums/UserSourceEnum';

defineOptions({
  name: 'TheRegisterAppSumo',
});

const { t } = useI18n();
const userStore = useUserStore();
const organizationStore = useOrganizationStore();
const modules = [EffectFade, Autoplay];

const { width } = useWindowSize();

const { opinions } = storeToRefs(organizationStore);

const rules = object({
  name: string().required().label(t('auth.shared.name')),
  surname: string().required().label(t('auth.shared.lastName')),
  email: string().email().required().label(t('auth.shared.email')),
  password: string()
    .required()
    .matches(

      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%*?&"£^()_+{}:~<>|=[\];'#,./\\-])[\w$@!%*?&"£^()+{}:~<>|=[\];'#,./\\-]{8,}/,
      t('auth.register.passwordValidation'),
    )
    .label(t('auth.shared.password')),
  termsAccepted: yupBoolean()
    .required(t('auth.register.termsConditionsValidation'))
    .oneOf([true], t('auth.register.termsConditionsValidation'))
    .label(t('auth.register.termsAndConditions')),
});

const { validate, errors, accepted, toggleAccepted } = useValidation(rules, {});

const { value: email } = useField<string>('email');
const { value: name } = useField<string>('name');
const { value: surname } = useField<string>('surname');
const { value: password } = useField<string>('password');
const { value: termsAccepted } = useField<boolean>('termsAccepted');

const router = useRouter();

const code = computed(() => router.currentRoute.value.query.code);

const status = ref<string>('loaded');
const cState = reactive({
  apiMessage: '',
});

const isMobile = computed(() => width.value < 991.98);

const slides = computed(
  () =>
    opinions.value?.map(
      opinion =>
        ({
          name: opinion.name,
          opinion: t(`opinion.${opinion.opinion}`),
          src: new URL(`/resources/img/opinion/${opinion.image}`, import.meta.url).href,
          srcSet: `${new URL(`/resources/img/opinion/${opinion.image}`, import.meta.url).href} 2x, ${new URL(`/resources/img/opinion/${opinion.image}`, import.meta.url).href} 1x`,
        }) as IOpinion,
    ) ?? [],
);

async function register() {
  try {
    toggleAccepted();

    const validated = await validate();

    if (validated.valid && !!code.value) {
      status.value = 'loading';

      const language = getSupportedApplicationLang();

      const formData = {
        name: name.value,
        surname: surname.value,
        email: email.value,
        password: password.value,
        language,
        source: UserSourceEnumConst.APPSUMO,
        code: code.value,
      };

      await api.auth.register(formData);

      if (!!window.fpr) {
        await window.fpr('referral', { email: email.value });
      }

      userStore.setFirstLogin(true);

      await router.replace('/');

      status.value = 'loaded';
    }
  } catch (e: any) {
    console.error(e);

    if (e.response?.data?.errors) {
      if (e.response?.data?.errors.email) {
        cState.apiMessage = e.response?.data?.errors.email[0];
      } else {
        cState.apiMessage = t('general.shared.error');
      }
    } else {
      cState.apiMessage = t('general.shared.error');
    }
  } finally {
    status.value = 'loaded';

    toggleAccepted();
  }
}

onMounted(() => {
  const cookieHelper = CookieHelper;

  const utmObj = cookieHelper.getUtmParameters();

  api.mixpanel.trackGuest('View registration', utmObj);

  organizationStore.loadFaqsAndOpinions();
});
</script>

<template>
  <div id="register-appsumo-page" class="min-vh-100 position-relative" data-cy="register-appsumo-page">
    <section id="register-appsumo-content" class="mw-100">
      <header
        id="left-content-header"
        :class="{ 'bg-primary': isMobile }"
        class="d-flex flex-column flex-sm-row gap-sm-0 justify-content-between align-items-center z-index-2 text-light px-lg-40
          px-xl-40 px-xxl-64 pb-lg-0 pt-xl-48 gap-8 px-24 py-32"
      >
        <div
          class="d-flex flex-column flex-md-row flex-lg-column flex-xxl-row align-items-center justify-content-center gap-4 gap-md-16 gap-lg-4 gap-xxl-16 mb-8 mb-sm-0"
        >
          <div>
            <v-picture
              :height="34"
              :lazy="false"
              :width="150"
              class="md-logo-image align-self-start"
            >
              <img
                alt="Logo ModularDS"
                src="@assets/img/ModularDS-white-logo-huge.png"
                srcset="@assets/img/ModularDS-white-logo-huge.png 2x, @assets/img/ModularDS-white-logo-huge.png 1x"
              >
            </v-picture>
          </div>

          <div class="text-xs fw-medium mb-0">
            with
          </div>

          <LogoAppSumo class="md-appsumo-logo-image" :width="150" :height="34" viewBox="0 0 1180 183" />
        </div>

        <div class="align-self-end">
          <div class="d-flex text-warning justify-content-end gap-4">
            <v-icon v-for="index in 5" :key="index" :class="{ 'text-xxl': !isMobile }" icon="star-fill" />
          </div>

          <span class="fw-medium text-sm">{{ $t('auth.register.wpRepository') }}</span>
        </div>
      </header>

      <div
        id="left-content-body"
        :class="{ 'bg-primary': isMobile }"
        class="text-light z-index-2 px-lg-40 px-xxl-64 pt-lg-0 pb-xl-48 overflow-hidden px-24 py-32"
      >
        <h1 class="h4 lh-lg mb-8">
          {{ $t('auth.register.manageAllWebsites') }}
        </h1>

        <p class="fw-semi text-warning mb-64 text-lg">
          {{ $t('auth.register.subtitle') }}
        </p>

        <div id="register-appsumo-reviews-slider">
          <Swiper
            :autoplay="{
              delay: 5000,
              pauseOnMouseEnter: true,
            }"
            effect="fade"
            :fade-effect="{
              crossFade: true,
            }"
            :grab-cursor="true"
            :modules="modules"
            :slides-per-view="1"
            :space-between="8"
            class="w-100"
          >
            <SwiperSlide v-for="(review, index) in slides" :key="index">
              <div class="md-detail-item d-flex align-items-center gap-sm-24 text-light gap-12">
                <v-picture :height="isMobile ? 72 : 128" :width="isMobile ? 72 : 128" lazy>
                  <img :alt="review.name" :src="review.src" :srcset="review.srcSet" class="rounded-pill">
                </v-picture>

                <div>
                  <p class="mb-8">
                    {{ review.name }}
                  </p>

                  <p class="fw-semi mb-0">
                    {{ review.opinion }}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div
        id="register-appsumo-form"
        class="d-flex align-items-center justify-content-center z-index-2 px-xl-48 px-xxl-48"
      >
        <div class="auth-content px-sm-64 px-lg-40 px-xl-12 px-xxl-64 px-24 py-48">
          <Form
            id="register-appsumo"
            :validate-on-mount="false"
            :validation-schema="rules"
            @submit="register"
          >
            <fieldset class="mb-32">
              <legend class="fw-medium mb-16 text-sm text-gray-700">
                {{ $t('auth.login.registerEmail') }}
              </legend>

              <Field v-slot="{ field, handleChange }" v-model="name" name="name">
                <m-input-text-floating
                  id="register-appsumo-name"
                  :input-class="{ 'is-invalid': !!errors.name }"
                  :label="$t('auth.shared.name')"
                  :placeholder="$t('auth.shared.name')"
                  class="mb-8"
                  data-cy="register-appsumo-name"
                  v-bind="field"
                  :yup-errors-variable="errors.name"
                  @change="handleChange"
                />
              </Field>

              <Field v-slot="{ field, handleChange }" v-model="surname" name="surname">
                <MInputTextFloating
                  id="register-appsumo-surname"
                  :input-class="{ 'is-invalid': !!errors.surname }"
                  :label="$t('auth.shared.lastName')"
                  :placeholder="$t('auth.shared.lastName')"
                  class="mb-8"
                  data-cy="register-appsumo-surname"
                  v-bind="field"
                  :yup-errors-variable="errors.surname"
                  @change="handleChange"
                />
              </Field>

              <div class="form-floating mb-8">
                <Field
                  id="register-appsumo-email"
                  v-model="email"
                  :class="{ 'is-invalid': !!errors.email }"
                  :placeholder="$t('auth.shared.email')"
                  class="form-control"
                  data-cy="register-appsumo-email"
                  name="email"
                  type="email"
                />
                <label for="email">{{ $t('auth.shared.email') }}</label>

                <div v-auto-animate>
                  <span v-if="!!errors.email" class="d-block invalid-feedback">{{ errors.email }}</span>
                </div>
              </div>

              <Field v-slot="{ field, handleChange }" v-model="password" name="password">
                <MInputPassword
                  id="register-appsumo-password"
                  :input-class="{ 'is-invalid': !!errors.password }"
                  :label="$t('auth.shared.password')"
                  :placeholder="$t('auth.shared.password')"
                  class="mb-8"
                  data-cy="register-appsumo-password"
                  v-bind="field"
                  @change="handleChange"
                />

                <p :class="{ 'text-danger': !!errors.password }" class="lh-xl text-xs text-gray-500">
                  {{ $t('auth.register.passwordDescription') }}
                </p>
              </Field>

              <v-checkbox
                ref="register-appsumo-terms"
                v-model="termsAccepted"
                :yup-errors-variable="errors.termsAccepted"
                class="w-100"
                data-cy="register-appsumo-terms"
                label-class="h-auto"
              >
                <template #label>
                  <span
                    class="fw-medium text-xs text-gray-500"
                    v-html="
                      $t('auth.register.termsAndConditionsText', {
                        termsAndConditionsLink: 'https://modulards.com/condiciones-contratacion/',
                        dataProcessingLink: 'https://modulards.com/contrato-encargado-tratamiento/',
                        privacyPolicyLink: 'https://modulards.com/politica-privacidad/',
                      })
                    "
                  />
                </template>
              </v-checkbox>

              <div v-auto-animate>
                <v-alert
                  v-if="!!cState.apiMessage && cState.apiMessage?.length > 0"
                  class="text-dark align-items-center mt-16"
                  data-cy="alert-api-message"
                  variant="danger"
                  content-spacing="ms-8 fw-semi"
                >
                  {{ cState.apiMessage }}
                </v-alert>
              </div>
            </fieldset>

            <v-button
              :disabled="accepted"
              :is-loading="accepted"
              class="w-100 mb-8"
              data-cy="btn-create-account"
              type="submit"
              variant="primary"
              @click="register"
            >
              {{ $t('auth.register.createAccount') }}
            </v-button>

            <router-link
              id="to-login"
              :to="{ name: 'login' }"
              class="btn btn-link btn-link-primary-dark w-100 fw-semi justify-content-center px-24 py-16 shadow-none"
              data-cy="btn-have-account"
            >
              <span>{{ $t('auth.register.alreadyAccount') }}</span>
            </router-link>
          </Form>
        </div>
      </div>
    </section>
  </div>
</template>
