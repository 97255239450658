<script lang="ts" setup>
import { ContextMenuSeparator, type ContextMenuSeparatorProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<ContextMenuSeparatorProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ContextMenuSeparator class="ui-menu-separator my-4" :class="[props.class]" v-bind="delegatedProps" />
</template>
