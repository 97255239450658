export default {
  shared: {
    addTeam: 'Añadir equipo',
    team: 'Equipo',
    teams: 'Equipos',
    create: 'Crear nuevo equipo',
    name: 'Nombre del equipo',
    createTeam: 'Crear equipo',
    successCreate: 'Equipo creado',
    edit: 'Editar equipo',
    delete: 'Eliminar equipo',
    deletedTeam: 'Equipo eliminado',
  },
  delete: {
    alertTitle: 'No puedes borrar un equipo que tiene webs.',
    alertDescription: 'Elimina o mueve a otros equipos las webs dentro de este equipo para poder eliminarlo.',
  },
};
