import admin from '@/lang/locales/es/admin';
import alerting from '@/lang/locales/es/alerting';
import auth from '@/lang/locales/es/auth';
import billing from '@/lang/locales/es/billing';
import changelog from '@/lang/locales/es/changelog';
import concept from '@/lang/locales/es/concept';
import dashboard from '@/lang/locales/es/dashboard';
import discount from '@/lang/locales/es/discount';
import editor from '@/lang/locales/es/editor';
import faq from '@/lang/locales/es/faq';
import general from '@/lang/locales/es/general';
import health from '@/lang/locales/es/health';
import manager from '@/lang/locales/es/manager';
import menu from '@/lang/locales/es/menu';
import notifications from '@/lang/locales/es/notifications';
import opinion from '@/lang/locales/es/opinion';
import report from '@/lang/locales/es/report';
import searchConsole from '@/lang/locales/es/searchConsole';
import settings from '@/lang/locales/es/settings';
import site from '@/lang/locales/es/site';
import siteReports from '@/lang/locales/es/siteReports';
import statistics from '@/lang/locales/es/statistics';
import tags from '@/lang/locales/es/tags';
import team from '@/lang/locales/es/team';
import uploader from '@/lang/locales/es/uploader';
import user from '@/lang/locales/es/user';
import validation from '@/lang/locales/es/validation';
import vulnerability from '@/lang/locales/es/vulnerability';

export default {
  admin,
  auth,
  billing,
  changelog,
  concept,
  dashboard,
  discount,
  faq,
  general,
  health,
  manager,
  menu,
  opinion,
  report,
  searchConsole,
  settings,
  site,
  siteReports,
  statistics,
  tags,
  team,
  user,
  uploader,
  validation,
  vulnerability,
  alerting,
  notifications,
  editor,
};
