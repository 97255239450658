<script setup lang="ts">
import { DropdownMenuSubTrigger, type DropdownMenuSubTriggerProps, useForwardProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<DropdownMenuSubTriggerProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <DropdownMenuSubTrigger
    v-bind="forwardedProps"
    class="ui-menu-sub-trigger d-flex align-items-center rounded-xs fw-medium px-16 text-sm"
  >
    <slot />
    <v-icon class="ui-menu-sub-trigger-icon ms-auto" icon="chevron-right" size="2xs" />
  </DropdownMenuSubTrigger>
</template>
