<script setup lang="ts">
import VUploader from '@/components/vendor/upload/VUploader.vue';

import { ref } from 'vue';

defineOptions({
  name: 'VUploadMediaModal',
});

withDefaults(defineProps<{
  validationRules?: Record<string, any>
  multiple: boolean
  sortable: boolean
  maxItems: number
  backdropOverContent?: boolean
  routePrefix?: string
}>(), {
  validationRules: () => ({}),
  multiple: true,
  sortable: true,
  maxItems: 1,
  backdropOverContent: false,
  routePrefix: 'api/files/temporary',
});

const emit = defineEmits(['closed', 'closed-with-media']);

const open = defineModel('open', { type: Boolean, required: true, default: false });

const disableButton = ref(false);
const media = ref<any[]>([]);

const uploader = ref<InstanceType<typeof VUploader> | null>(null);

function onChange() {
  if (!!uploader.value) {
    media.value = (uploader.value?.mediaLibrary as any)?.state?.media;
  }
}

function handleClose() {
  if (!!media.value) {
    emit('closed-with-media', media.value);
  } else {
    emit('closed');
  }
}
</script>

<template>
  <Dialog
    v-model:open="open"
    @update:open="(val) => !val ? $emit('closed') : null"
  >
    <DialogContent
      size="md"
      elevated
    >
      <DialogHeader>
        <DialogTitle>
          {{ $t('siteReports.addImage') }}
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <VUploader
          ref="uploader"
          :after-upload="onChange"
          :max-items="maxItems"
          :multiple="multiple"
          :sortable="sortable"
          :validation-rules="validationRules"
          :route-prefix="routePrefix"
          data-cy="modal-uploader"
          @removed="onChange"
        />
      </DialogBody>

      <DialogFooter>
        <VButton
          data-cy="btn-confirm-media"
          :disabled="disableButton || media.length === 0"
          :is-loading="disableButton"
          size="sm"
          variant="primary"
          @click="handleClose"
        >
          {{ $t('general.button.confirm') }}
        </VButton>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
