import api from '@/api';
import { useToast } from '@/composables/useToast';
import useTaskStore from '@/stores/task/taskStore';
import type { TaskIndexRequest, TaskStoreRequest, TaskUpdateRequest } from '@/api/services/site/service/report/task';
import type { SiteTask, SiteTaskMapped } from '@/types/models/site/task/SiteTask';
import { RouterLink } from 'vue-router';

function useTask() {
  const { toastError, toastSuccess, toastCustomSuccess } = useToast();

  const taskStore = useTaskStore();

  const {
    loadedTasks,
    tasksMap,
    tasksPaginated,
  } = storeToRefs(taskStore);

  const loadTasks = async (params: TaskIndexRequest, map = true) => {
    try {
      loadedTasks.value = false;

      tasksPaginated.value = await api.task.all(params, map);

      tasksMap.value = new Map(tasksPaginated.value.data.map((task: SiteTask | SiteTaskMapped) => [task.id, task]));
    } catch (e: any) {
      console.error('Error loading tasks', e);

      throw e;
    } finally {
      loadedTasks.value = true;
    }
  }

  const getTask = async (taskId: number) => {
    try {
      return await api.task.retrieve(taskId);
    } catch (e: any) {
      console.error('Error getting the task', e);
    }
  };

  const { t } = useI18n();

  const createTask = async (data: TaskStoreRequest, slug?: string) => {
    try {
      await api.task.create(data);

      if (!!slug) {
        // TODO Implement this
        toastCustomSuccess(h('span', {}, [
          h('span', { class: 'me-16' }, t('siteReports.tasks.successCreate')),
          h(RouterLink, {
            class: 'btn btn-light btn-2xs me-8',
            to: { name: 'sites.site.task.index', params: { site: slug } },
          }, [
            h('span', {}, t('general.button.view')),
          ]),
        ]));
      } else {
        toastSuccess('siteReports.tasks.successCreate');
      }
    } catch (e: any) {
      console.error('Error creating task', e);

      toastError();
    }
  };

  const updateTask = async (taskId: number, data: TaskUpdateRequest) => {
    try {
      const response = await api.task.update(taskId, data);

      toastSuccess('siteReports.tasks.successEdit');

      return response;
    } catch (e: any) {
      console.error('Error updating task', e);

      toastError();
    }
  };

  const deleteTasks = async (taskIds: number[]) => {
    try {
      await api.task.delete(taskIds);

      toastSuccess('siteReports.tasks.deleteTaskCompleted');
    } catch (e) {
      console.error('Error deleting task', e);

      toastError();
    }
  }

  return {
    loadTasks,
    getTask,
    createTask,
    updateTask,
    deleteTasks,
  };
}

export { useTask };
export default useTask;
