<script lang="ts" setup>
import { useSiteCreate } from '@/composables/site/useSiteCreate';

defineOptions({
  name: 'DashboardSitesEmptyState',
});

const props = withDefaults(
  defineProps<{
    mode: 'tags' | 'default'
  }>(),
  {
    mode: 'default',
  },
);

defineEmits(['clearFilters']);

const { handleCreateSite } = useSiteCreate();

const isDefaultEmptyState = computed(() => props.mode === 'default');
</script>

<template>
  <div
    :class="{ 'justify-content-start': isDefaultEmptyState, 'justify-content-lg-center': !isDefaultEmptyState }"
    class="d-flex align-items-center flex-wrap flex-lg-nowrap mt-64"
    data-cy="team-empty-state"
  >
    <div
      :class="{ 'col-lg-6 col-xxl-7': isDefaultEmptyState, 'col-lg-6 col-xxl-5': !isDefaultEmptyState }"
      class="col-12 mb-8 me-36"
    >
      <h3 class="h5 fw-semi">
        <template v-if="isDefaultEmptyState">
          {{ $t('dashboard.emptyState.default.firstSite') }}
        </template>
        <template v-else>
          {{ $t('dashboard.emptyState.tags.noResultTitle') }}
        </template>
      </h3>

      <p class="text-gray-500 mb-16" :class="{ 'text-sm fw-medium': isDefaultEmptyState }">
        <template v-if="isDefaultEmptyState">
          {{ $t('dashboard.emptyState.default.firstSiteDescription') }}
        </template>
        <template v-else>
          {{ $t('dashboard.emptyState.tags.noResultText') }}
        </template>
      </p>

      <VButton
        v-if="isDefaultEmptyState"
        class="btn-icon text-decoration-none mb-16"
        data-cy="connect-first-site"
        variant="primary"
        @click="handleCreateSite('Dashboard empty state')"
      >
        <v-icon class="me-8" icon="plus" size="xs" />
        <span>{{ $t('dashboard.emptyState.default.connectSite') }}</span>
      </VButton>

      <p
        v-if="isDefaultEmptyState"
        class="fw-medium text-xs"
        v-html="$t('dashboard.emptyState.default.howToConnect')"
      />

      <VButton v-else variant="outline-primary" size="sm" @click="$emit('clearFilters')">
        {{ $t('general.button.clearFilters') }}
      </VButton>
    </div>

    <v-picture
      :class="{ 'image-empty-state': isDefaultEmptyState, 'image-empty-state-tags': !isDefaultEmptyState }"
      height="282"
      lazy
      width="374"
    >
      <img
        v-if="isDefaultEmptyState"
        :alt="$t('general.shared.teamImageAlt')"
        class="image"
        src="@assets/img/dashboard/team-work.png"
        srcset="@assets/img/dashboard/team-work.png 2x, @assets/img/dashboard/team-work.png 1x"
      >
      <img
        v-else
        :alt="$t('general.shared.teamImageAlt')"
        class="image"
        src="@assets/img/dashboard/empty-state-tags.png"
        srcset="@assets/img/dashboard/empty-state-tags.png 2x, @assets/img/dashboard/empty-state-tags.png 1x"
      >
    </v-picture>
  </div>
</template>

<style lang="scss" scoped>
.image-empty-state-tags {
  max-width: 250px;
}
</style>
