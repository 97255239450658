export default {
  all: 'All',
  upgradesAvailable: 'Available updates',
  availableUpdates: '1 Update available | {total} Updates available | {total} Updates available',
  successReloadItemsText: 'Reloading data. It may take a few minutes for all pending updates to appear.',
  hiddenUpdates: 'Updates hidden',
  plugin: 'plugin | plugins',
  theme: 'theme | themes',
  core: 'WordPress',
  withVulnerabilities: 'With vulnerabilities',
  selectStatus: 'Select item status',
  synchronized: 'Versions synchronized',
  reloadData: 'Reload',
  installPlugin: 'Install plugin',
  installTheme: 'Install theme',
  searchType: 'Search { type }',
  selectAll: 'Select all',
  deselect: 'Deselect',
  hideUpdates: 'Hide',
  showUpdates: 'Show',
  activateUpdates: 'Activate',
  deactivateUpdates: 'Deactivate',
  connectorNotUpdated: 'Some of your websites are using an outdated version of the Modular Connector plugin. Update to the latest version to take advantage of all available features, such as activating, deactivating, and installing plugins/themes.',
  connectorNotUpdatedShort: 'Update Modular Connector on your websites first',
  connectorNotUpdatedSiteShort: 'Update Modular Connector on this website first',
  updateType: 'Update { type }',
  refreshItems: 'New data available',
  selectedItems: '{ count } items selected | { count } item selected | { count } items selected',
  cannotReloadText: 'The update list can only be refreshed every 5 minutes.',
  updateAvailable: 'Update available',
  updateHidden: 'Hidden update',
  updateDisabled: '<span class="text-capitalize">{ type }</span> disabled',
  componentItemDisabled: 'This { type } is disabled',
  themeActive: 'Active theme',
  noFilterMatch: 'Oops! We found nothing',
  noFilterMatchText: 'It looks like there are no sites in your Modular account matching the filters you applied. Try another combination.',
  lostConnection: 'Connection with your website has been lost. Reconnect to view this information.',
  lostConnectionText: 'Discover the most common reasons and how to fix it in <a href="https://support.modulards.com/en/conection-web-error" target="_blank" rel="noreferrer noopener" aria-label="Support">this article</a>. If the problem persists, we would greatly appreciate it if you could email us at <a href="mailto:{email}" aria-label="Contact by email">{email}</a> so we can help you fix it.',
  noSitesConnection: 'You have not connected any websites yet',
  noSitesConnectionText: 'Why don\'t you connect your first website?',
  noHiddenUpdates: 'You have no hidden updates',
  noHiddenUpdatesText: 'Learn about hidden updates <a href="https://support.modulards.com/en/updater-global#:~:text=others%20without%20updating.-,Hide%20updates,-If%20you%20prefer" target="_blank" rel="noreferrer noopener" aria-label="Support">here</a>.',
  noPendingUpdates: 'No pending updates',
  noPendingUpdatesText: 'Everything running smoothly for now.',
  connectWeb: 'Connect site',
  noUpdates: 'Nothing to update!',
  deleteTheme: 'Delete theme | Delete themes',
  deletePlugin: 'Delete plugin | Delete plugins',
  deletePluginModalText: 'The plugin will be removed from this website. | The plugins will be removed from the selected websites.',
  deleteThemeModalText: 'The theme will be removed from this website. | The themes on the selected websites will be removed.',
  activateTheme: 'Activate theme | Activate themes',
  activateThemeModalTitle: 'Important notice',
  activateThemeModalText: 'Activating the new themes will automatically deactivate the current active theme of the selected websites.',
  activateThemeModalTextOneSite: 'Activating the new theme will automatically deactivate the currently active theme on this website.',
  freeBanner: {
    title: 'Take advantage of all of Modular\'s features',
    description: 'Become<span class=\'badge bg-success text-light rounded-xs text-xs fw-medium p-4 mx-4\'>PRO</span>to install plugins in bulk, make daily backups, etc...',
  },
  installation: {
    zipWarningTitle: 'Check your ZIP files before uploading',
    zipWarningDescription: 'Modular does not verify the ZIP files you upload to your sites, so you should ensure they are the correct files. We recommend downloading plugins/themes from the official WordPress repository or their official website',
    installUrl: 'Install from URL',
    installAI: 'Use AI',
    installZip: 'Install from a ZIP file',
    searchPlaceholder: 'Search plugin on WordPress.org',
    name: 'Name',
    rating: 'Rating',
    requirements: 'Requirements',
    lastUpdate: 'Last update',
    selectSites: 'Select websites',
    activeInstallationsCount: '+{ count } active installations',
    atLeast: 'At least',
    noData: 'No data',
    searchWeb: 'Search website',
    button: 'Install',
    progress: {
      title: 'We have started with the installation!',
      subtitle: 'You can see the progress below',
      installationError: 'Installation error',
      installationSuccess: 'Installation completed',
    },
    plugin: {
      title: 'Install plugin',
      installUrlDescription: 'Enter the URL of the plugin you want to install. Quickly add plugins from any website.',
      installAIDescription: 'Describe the functionality you need and our AI will take care of everything.',
      installZipDescription: 'Select the plugin ZIP file from your device. Reinstall that plugin you already downloaded.',
      modalConfirm: {
        title: 'Plugin installation',
        alertTitle: 'Are you sure you want to install this plugin on the selected websites?',
        alertTitleWeb: 'Are you sure you want to install this plugin on this website?',
        alertTitleWebName: 'Are you sure you want to install this plugin on {site}?',
        activate: 'Activate plugin after installation',
        overwrite: 'Overwrite plugin if you have already installed it',
      },
      steps: {
        step1: {
          url: {
            title: 'URL Search',
            description: 'Where is your file hosted?',
            content: {
              title: 'Plugin package URL',
              description: 'Use a publicly accessible download link, a Dropbox link, or a Google Drive link to your plugin package (.zip) to install it on your websites.',
            },
          },
          ai: {
            title: 'Use AI',
            description: 'Tell us what you are looking for',
            content: {
              title: 'Write your prompt',
              description: 'Write a description of the functionality you need for your WordPress website. Our AI tool will find the perfect plugin for you. Like if it was magic.',
              placeholder: 'Describe the functionality you need...',
              by: 'by',
            },
          },
          zip: {
            title: 'Install from a ZIP file',
            description: 'Upload the file from your computer.',
            content: {
              title: 'Upload your own file',
              description: 'Select the file directly from your device to be able to install it on your websites.',
            },
          },
        },
        step2: {
          title: 'Website selection',
          description: 'Select where you want to install it',
          content: {
            title: 'Your websites',
            noResults: 'There seems to be no plugin called “<span class=\'text-dark fw-semi\'>{search}</span>” or similar. Try another combination.',
          },
        },
      },
    },
    theme: {
      title: 'Install theme',
      installUrlDescription: 'Enter the URL of the theme you want to install. Quickly add themes from any website.',
      installZipDescription: 'Select the theme ZIP file from your device. Reinstall that theme you already downloaded.',
      modalConfirm: {
        title: 'Theme installation',
        alertTitle: 'Are you sure you want to install this theme on the selected websites?',
        alertTitleWeb: 'Are you sure you want to install this theme on this website?',
        alertTitleWebName: 'Are you sure you want to install this theme on {site}?',
        activatePlugin: 'Activate theme after installation (will deactivate currently active themes)',
        overwrite: 'Overwrite theme if already installed',
      },
      steps: {
        step1: {
          url: {
            title: 'URL Search',
            description: 'Where is your file hosted?',
            content: {
              title: 'Theme package URL',
              description: 'Use a publicly accessible download link, a Dropbox link, or a Google Drive link to your theme package (.zip) to install it on your websites.',
            },
          },
          zip: {
            title: 'Install from a ZIP file',
            description: 'Upload the file from your computer',
            content: {
              title: 'Upload your own file',
              description: 'Select the file directly from your device to be able to install it on your websites.',
            },
          },
        },
        step2: {
          title: 'Website selection',
          description: 'Select where you want to install it',
          content: {
            title: 'Your websites',
            noResults: 'There seems to be no theme called “<span class=\'text-dark fw-semi\'>{search}</span>” or similar. Try another combination.',
          },
        },
      },
    },
  },
}
