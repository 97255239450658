<script>
// FIXME Not in TS because of mime typing not working
import mime from 'mime';

export default {
  name: 'VPicture',
  props: {
    webp: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // TODO debe ser compatible con purgecss
    cssClass() {
      const $slot = this.$slots.default();
      const $attrs = $slot[0].props;

      return $attrs.class;
    },
    file() {
      const $slot = this.$slots.default();
      const $attrs = $slot[0].props;

      const $src = /\.([0-9a-z]+)(?:[\\?#]|$)/i.exec($attrs.src);
      const extension = $src ? $src[1] : null;
      const version = $src?.input.split($src[0]).pop();
      const originalPath = $src ? `${$src.input.split('.').slice(0, -1).join('.')}.${extension}` : $attrs.src;

      return {
        extension,
        src: $attrs.src,
        srcset: $attrs.srcset ? $attrs.srcset : $attrs.src,
        mimetype: mime.getType(extension),
        ver: version,
        path: originalPath,
        dataCy: $attrs['data-cy'],
        alt: $attrs.alt,
        original: true,
      };
    },
    sources() {
      const { file } = this;

      if (!this.webp) {
        return [file];
      }

      // check if exists webp
      const webpPath = file.path.replace(new RegExp(`.${file.extension}`, 'g'), '.webp');
      const webpSrc = file.src.replace(new RegExp(`.${file.extension}`, 'g'), '.webp');
      const webpSrcset = file.srcset.replace(new RegExp(`.${file.extension}`, 'g'), '.webp');

      return [
        {
          extension: 'webp',
          src: webpSrc,
          srcset: webpSrcset,
          mimetype: 'image/webp',
          ver: file.ver,
          alt: file.alt,
          path: webpPath,
          original: false,
          dataCy: file.dataCy,
        },
        file,
      ];
    },
  },
};
</script>

<template>
  <picture>
    <template v-for="(source, key) in sources" :key="key">
      <source :srcset="source.srcset" :type="source.mimetype">

      <img
        v-if="source.original"
        :alt="source.alt"
        :class="cssClass"
        :data-cy="source.dataCy"
        :height="height"
        :loading="lazy ? 'lazy' : 'eager'"
        :src="source.src"
        :width="width"
      >
    </template>
  </picture>
</template>
