<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@/helpers/classNames';

defineOptions({
  name: 'DialogFooter',
});

const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>

<template>
  <div
    :class="
      cn(
        'ui-dialog-footer d-flex flex-column-reverse flex-sm-row justify-content-sm-end row-gap-sm-8 gap-8',
        props.class,
      )
    "
  >
    <slot />
  </div>
</template>
