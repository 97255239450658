<script lang="ts" setup>
import api from '@/api';

import TheSiteCreateConnection from '@/components/site/create/TheSiteCreateConnection.vue';

import { EventSymbols } from '@/resources/symbols';

defineOptions({
  name: 'VSiteCreate',
});

withDefaults(
  defineProps<{
    title?: string
    formLegend?: string
  }>(),
  {
    title: 'site.create.new',
    formLegend: 'site.create.title',
  },
);

const openModal = ref<boolean>(false);
const openConnection = ref<boolean>(false);

// Event Bus created on Composable useSiteCreate
const createSiteEventBus = useEventBus<string>(EventSymbols.CREATE_SITE);

async function tryToAdd(location: string) {
  await api.mixpanel.track('Try to add website', {
    location,
  });
}

createSiteEventBus.on((event, payload) => {
  if (event === EventSymbols.CREATE_SITE.toString()) {
    openModal.value = true;
  }

  openModal.value = true;

  tryToAdd(payload || '');
});

function closeConnectionManager() {
  openConnection.value = false;
  openModal.value = false;
}
</script>

<template>
  <v-modal
    v-if="openModal"
    id="site-create-modal"
    backdrop="false"
    :backdrop-static="false"
    data-cy="site-create-modal"
    size="fullscreen"
    @closed="
      openModal = false;
      openConnection = false;
    "
    @show="openConnection = true"
  >
    <modal-header>
      <modal-title class="text-xxl fw-bold">
        {{ $t(title) }}
      </modal-title>
    </modal-header>

    <modal-body class="d-flex justify-content-center position-relative h-auto px-0">
      <TheSiteCreateConnection
        v-if="openConnection"
        ref="connection"
        :legend="formLegend"
        @close-modal="closeConnectionManager"
      />
    </modal-body>

    <modal-footer class="px-80 px-xl-96 pb-xl-24">
      <div id="the-site-create-footer" />
    </modal-footer>
  </v-modal>
</template>

<style lang="scss" scoped>
.modal-content {
  padding-bottom: 1rem !important;
}

.modal-body {
  overflow-x: hidden;
}

.btn-back {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 2;
}

.form-fade-move,
.form-fade-enter-active,
.form-fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.form-fade-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.form-fade-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.form-fade-leave-active,
.form-fade-enter-active {
  position: absolute;
}
</style>
