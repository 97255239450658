import type { ManagerCountAllowedActions } from '@/api/services/organization/manager-update/ManagerUpdateService';
import type {
  ComponentSocketEventMapped,
  CumulativeActions,
  CumulativeComponents,
  IManagerStore,
  InstalledPayloadType,
  ManagerActionKeys,
  SiteItemSocket,
  UpdatedPayloadType,
  UpgradedPayloadType,
} from '@/stores/manager/index';
import type { SiteItemTypeEnumKeys } from '@/types/enums/SiteItemTypeEnum';
import { SiteItemTypeEnumConst } from '@/types/enums/SiteItemTypeEnum';

import type { SiteManagerActionEnumType } from '@/types/enums/SiteManagerActionEnum';
import type { ManagerRequestOnlyKeys } from '@/types/ManagerUpdateRequestEnums';
import type { IComponent, IComponentSelection } from '@/types/models/organization/manager/Component';
import type { Tag } from '@/types/models/organization/tag/Tag';
import type { GlobalMetasType } from '@/types/models/site/updater/SiteItem';
import type {
  ComponentCheckSelectionRequestType,
  ComponentManageRequestType,
  ComponentRequestType,
  ComponentSelectionRequestType,
  SiteManagerTypeEnumType,
} from '@/views/manager';
import type { ComputedRef, Reactive, Ref } from 'vue';
import { computed, ref } from 'vue';
import api from '@/api';
import { EventSymbols } from '@/resources/symbols';
import { createComponentProcessor, trackManagerAction } from '@/stores/manager/functions';

import useOrganizationStore from '@/stores/organizationStore';
import useSiteStore from '@/stores/site/siteStore';
import { ComponentRequestIncludeEnumConst } from '@/types/enums/ComponentRequestIncludeEnum';
import { ComponentRequestOnlyEnumConst } from '@/types/enums/ComponentRequestOnlyEnum';
import { SiteManagerTypeEnumConst } from '@/types/SiteManagerTypeEnum';

import { useRoute } from 'vue-router';

const useManagerStore = defineStore('manager', (): IManagerStore => {
  const { PLUGIN } = SiteItemTypeEnumConst;
  const {
    UPDATABLE: ONLY_UPDATABLE,
    HIDDEN: ONLY_HIDDEN,
    VISIBLE,
    HAS_VULNERABILITIES,
  } = ComponentRequestOnlyEnumConst;

  const { VULNERABILITIES, SITE_ITEMS_COUNT, MIN_VERSION, NEW_VERSION, UPDATABLE, PROCESSING, HIDDEN, HAS_INACTIVE }
    = ComponentRequestIncludeEnumConst;

  const route = useRoute();

  const siteStore = useSiteStore();

  const newSynchronizedSites = ref(false);
  const syncSitesRequested = ref(false);
  const siteItemInstalled = ref(false);

  const sitesItemInstalled = ref<{ id: number, success: boolean }[]>([]);

  const loadedComponents = ref(false);
  const loadedAllManager = ref(false);

  const globalMetas: Ref<GlobalMetasType | null> = ref(null);

  const type: Ref<SiteItemTypeEnumKeys> = ref(PLUGIN);
  const tags: Ref<Tag[]> = ref([]);
  const visibilityStatus: Ref<typeof ONLY_HIDDEN | typeof VISIBLE | 'all'> = ref(VISIBLE);
  const hasVulnerabilities: Ref<boolean> = ref(false);

  const componentsMap = ref<Map<number, IComponent>>(new Map());

  const selectedCount: Ref<number> = ref(0);
  const allowedActions: Ref<ManagerCountAllowedActions> = ref({
    show: false,
    hide: false,
    upgrade: false,
    deactivate: false,
    activate: false,
    delete: false,
    connectorNotUpdated: true,
  });

  const componentSelection = ref<IComponentSelection[]>([]);

  const reset = () => {
    newSynchronizedSites.value = false;
    syncSitesRequested.value = false;
    siteItemInstalled.value = false;

    sitesItemInstalled.value = [];

    loadedComponents.value = false;
    loadedAllManager.value = false;

    globalMetas.value = null;
    type.value = PLUGIN;
    tags.value = [];

    visibilityStatus.value = VISIBLE;
    hasVulnerabilities.value = false;

    componentsMap.value.clear();

    selectedCount.value = 0;

    allowedActions.value = {
      show: false,
      hide: false,
      upgrade: false,
      deactivate: false,
      activate: false,
      delete: false,
      connectorNotUpdated: true,
    };

    componentSelection.value = [];
  };

  const currentSite = computed(() => route.params.site);

  // If we dont have site param, we are not in a site, so it is global
  const isGlobal = computed(() => currentSite.value === undefined);
  const currentSiteId = computed(() =>
    !!currentSite.value && !!siteStore.currentSite ? Number(siteStore.currentSite.id) : null,
  );
  const tagIds = computed(() => tags.value.map(tag => Number(tag.id)));
  const changedFilters = computed(() => tags.value.length > 0 || hasVulnerabilities.value);

  const location: ComputedRef<SiteManagerTypeEnumType> = computed(() =>
    isGlobal.value ? SiteManagerTypeEnumConst.GLOBAL : SiteManagerTypeEnumConst.SITE,
  );

  const cumulativeComponents: Reactive<CumulativeComponents> = reactive(new Map());

  const managerRequest: ComputedRef<ComponentRequestType> = computed(() => {
    const statuses: ManagerRequestOnlyKeys[] = hasVulnerabilities.value ? [HAS_VULNERABILITIES] : [];

    if (visibilityStatus.value !== 'all')
      statuses.push(visibilityStatus.value, ONLY_UPDATABLE);

    return {
      type: [type.value],
      only: [...statuses, ComponentRequestOnlyEnumConst.SITE_CONNECTED],
      sites: !!siteStore.currentSite ? [Number(siteStore.currentSite.id)] : [],
      tags: tagIds.value,
    };
  });

  const cleanSelection: ComputedRef<IComponentSelection[]> = computed(() => {
    return componentSelection.value.filter((c: IComponentSelection) => c.all || c.included.length > 0);
  });

  const clearFilters = async () => {
    tags.value = [];
    hasVulnerabilities.value = false;
  };

  const loadGlobalMetas = async () => {
    const localParams: ComponentRequestType = { ...managerRequest.value };

    delete localParams.type;

    globalMetas.value = await api.organization.managerUpdate.retrieveMetas(localParams);
  };

  const generateComponentSelection = async () => {
    componentSelection.value = [];

    componentsMap.value.forEach((component) => {
      componentSelection.value.push({
        id: Number(component.id),
        all: false,
        included: [],
        excluded: [],
        check: 'empty',
        notListed: [],
      });
    });
  };

  const loadComponentSelection = async () => {
    if (componentSelection.value.length === 0)
      return;

    const componentsMap: ComponentSelectionRequestType[] = componentSelection.value.map(
      ({ id, all, included, excluded, check, notListed: not_listed }) => ({
        id,
        all,
        included,
        excluded,
        check,
        not_listed,
      }),
    );

    const localParams: ComponentCheckSelectionRequestType = {
      filters: { ...managerRequest.value },
      components: componentsMap,
    };

    componentSelection.value = await api.organization.managerUpdate.checkSelection(localParams);
  };

  const loadComponents = async (checkSelection = false) => {
    cumulativeComponents.clear();

    const components = await api.organization.managerUpdate.allComponents({ ...managerRequest.value });

    componentsMap.value = new Map(components.map((component: IComponent) => [Number(component.id), component]));

    if (checkSelection && componentSelection.value.length > 0) {
      await loadComponentSelection();
    } else {
      await generateComponentSelection();
    }

    loadedComponents.value = true;
  };

  const loadComponentsMetas = async (componentsRequest?: number[]) => {
    const localParams: ComponentRequestType = {
      ...managerRequest.value,
      include: [VULNERABILITIES, SITE_ITEMS_COUNT, MIN_VERSION, NEW_VERSION, UPDATABLE, PROCESSING, HIDDEN, HAS_INACTIVE],
      fields: {
        component: ['id'],
      },
    };

    if (!!componentsRequest && componentsRequest.length > 0) {
      localParams.components = componentsRequest;
    }

    const response = await api.organization.managerUpdate.allComponents(localParams);

    if (!!componentsRequest && componentsRequest.length > 0) {
      componentsRequest.forEach((componentId) => {
        const componentExistsResponse = response.find(component => Number(component.id) === componentId);

        if (!componentExistsResponse) {
          componentsMap.value.delete(componentId);
          componentSelection.value = componentSelection.value.filter(component => component.id !== componentId);
        } else {
          const componentFound = componentsMap.value.get(componentId);

          if (componentFound) {
            const componentType = componentFound.type;
            const newComponentData = { ...componentFound, ...componentExistsResponse } as IComponent;
            newComponentData.type = componentType;
            componentsMap.value.set(Number(componentExistsResponse.id), newComponentData);
          }
        }
      });
    } else {
      response.forEach((meta) => {
        const componentFound = componentsMap.value.get(Number(meta.id));

        if (componentFound) {
          const componentType = componentFound.type;
          const newComponentData = { ...componentFound, ...meta } as IComponent;
          newComponentData.type = componentType;
          componentsMap.value.set(Number(meta.id), newComponentData);
        }
      });
    }
  };

  const getSelectedCount = async () => {
    if (cleanSelection.value.length !== 0) {
      const localFilters: ComponentManageRequestType = {
        filters: { ...managerRequest.value },
        location: location.value,
        type: type.value,
        components: [...cleanSelection.value],
      };

      const countResponse = await api.organization.managerUpdate.retrieveSelectionCount(localFilters);

      selectedCount.value = countResponse.count;
      allowedActions.value = countResponse.allowedActions;
    } else {
      selectedCount.value = 0;
    }
  };

  // region Data management before the API action request
  const handleActionEvent = async (action: SiteManagerActionEnumType) => {
    switch (action) {
      case 'deactivate':
      case 'activate':
      case 'delete':
      case 'upgrade':
        createComponentProcessor(componentsMap, cleanSelection.value, action).setToProcessing();
        break;
      case 'hide':
      case 'show':
        if (
          (visibilityStatus.value === 'visible' && action === 'hide')
          || (visibilityStatus.value === ONLY_HIDDEN && action === 'show')
        ) {
          createComponentProcessor(componentsMap, cleanSelection.value, action).hideShowRemove();
          break;
        }

        createComponentProcessor(componentsMap, cleanSelection.value, action).setToHiddenVisible();
        break;
    }

    await generateComponentSelection();
  };

  const manageAction = async (action: SiteManagerActionEnumType) => {
    const localParams: ComponentManageRequestType = {
      filters: { ...managerRequest.value },
      location: location.value,
      type: type.value,
      components: [...cleanSelection.value],
      action,
    };

    const affectedSelectedCount = toValue(selectedCount);

    const affectedComponentsIds: Array<number> = localParams.components.map(component => component.id);

    const affectedComponentsSlugs = Array.from(componentsMap.value.values())
      .filter(component => affectedComponentsIds.includes(Number(component.id)))
      .map(component => component.slug);

    await handleActionEvent(action);

    await api.organization.managerUpdate.manage(localParams);

    await loadGlobalMetas();

    trackManagerAction(affectedComponentsSlugs, toValue(type), action, affectedSelectedCount, toValue(isGlobal));
  };

  // endregion Data management before the API action request

  // region Handle update socket event
  const cumulativeSyncSites: Reactive<Map<number, UpdatedPayloadType>> = reactive(new Map());
  let timeoutId: NodeJS.Timeout | null = null;

  const organizationStore = useOrganizationStore();

  const doHandleSocketEvent = async () => {
    try {
      const cumulativeSyncSitesClone = new Map(cumulativeSyncSites);
      cumulativeSyncSites.clear();

      const noSitesUpdated = Array.from(cumulativeSyncSitesClone.values()).every(site => !site.haveBeenUpdated);

      const currentSiteFound = cumulativeSyncSitesClone.get(Number(currentSiteId.value));
      const currentSiteNotUpdated = !isGlobal.value && (!currentSiteFound || !currentSiteFound.haveBeenUpdated);

      if (cumulativeSyncSitesClone.size === 0 || noSitesUpdated || currentSiteNotUpdated) {
        return;
      }

      // Force load items if first time connecting site
      const siteHasNoItems = !isGlobal.value && componentsMap.value.size === 0;

      if (syncSitesRequested.value || siteHasNoItems || (siteItemInstalled.value && !currentSiteNotUpdated)) {
        if (siteItemInstalled.value)
          siteItemInstalled.value = false;
        await Promise.all([organizationStore.manager(), loadGlobalMetas(), loadComponents()]);
        await loadComponentsMetas();

        loadedAllManager.value = true;
      } else {
        newSynchronizedSites.value = true;
      }
    } catch (e: any) {
      console.error(e);
    } finally {
      syncSitesRequested.value = false;
    }
  };

  const handleSocketEvent = async (response: UpdatedPayloadType) => {
    cumulativeSyncSites.set(response.site, response);

    if (cumulativeSyncSites.size === organizationStore.sitesUsed) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      await doHandleSocketEvent();
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(async () => {
      timeoutId = null;
      await doHandleSocketEvent();
    }, isGlobal.value ? 10000 : 1000);
  };
  // endregion Handle update socket event

  // region Handle Action Socket Events
  const handleSocketActionEvent = (action: ManagerActionKeys, componentsSocketMapped: ComponentSocketEventMapped) => {
    switch (action) {
      case 'upgrade':
        if (visibilityStatus.value !== 'all') {
          createComponentProcessor(componentsMap, componentsSocketMapped, action, true)
            .removeProcessing()
            .setNewValues()
            .delete();
          break;
        }
        createComponentProcessor(componentsMap, componentsSocketMapped, action, true).removeProcessing().setNewValues();
        break;
      case 'deactivate':
      case 'activate': {
        createComponentProcessor(componentsMap, componentsSocketMapped, action, true).removeProcessing().setNewValues();
        break;
      }
      case 'delete':
        createComponentProcessor(componentsMap, componentsSocketMapped, action, true)
          .removeProcessing()
          .setNewValues()
          .delete();
        break;
    }
  };

  const mapCumulativeComponents = (cumulativeComponents: CumulativeComponents) => {
    cumulativeComponents.forEach((cumulativeAction: CumulativeActions, componentId) => {
      Object.keys(cumulativeAction).forEach((action: ManagerActionKeys) => {
        // @ts-expect-error-next-line
        const localAction: Map<number, SiteItemSocket> = cumulativeAction[action];

        if (localAction.size === 0)
          return;

        const siteItemsMap: Map<number, SiteItemSocket> = new Map(localAction);

        const componentMapped: ComponentSocketEventMapped = {
          componentId,
          siteItemsMap,
        };

        handleSocketActionEvent(action, componentMapped);
      });
    });
  };

  const doHandleActionSocketEvents = async () => {
    const cumulativeComponentsClone: CumulativeComponents = new Map(cumulativeComponents);

    cumulativeComponents.clear();

    const componentKeys = Array.from(cumulativeComponentsClone.keys());

    if (!isGlobal.value) {
      const oldThemeActive = Array.from(componentsMap.value.values()).find(component => !component.hasInactive);

      if (!!oldThemeActive) {
        componentKeys.push(Number(oldThemeActive.id));
      }
    }

    mapCumulativeComponents(cumulativeComponentsClone);
    await Promise.all([organizationStore.manager(), loadGlobalMetas(), loadComponentsMetas(componentKeys)]);
    await loadComponentSelection();
  };

  /**
   * Forces to be at least once in 10 seconds to not saturate API
   */
  const throttledActionSocketFn = useThrottleFn(
    async () => {
      await doHandleActionSocketEvents();
    },
    10000,
    true,
  );

  const handleActionSocketEvent = async (response: UpgradedPayloadType) => {
    response.siteItems.forEach((siteItem) => {
      const componentFound = cumulativeComponents.get(siteItem.componentId);

      if (!!componentFound) {
        // @ts-expect-error-next-line
        componentFound[response.action].set(response.site, {
          ...siteItem,
          success: true,
        });
      } else {
        const actions: CumulativeActions = {
          activate: new Map(),
          deactivate: new Map(),
          upgrade: new Map(),
          delete: new Map(),
        };

        // @ts-expect-error-next-line
        actions[response.action].set(response.site, siteItem);

        cumulativeComponents.set(siteItem.componentId, actions);
      }
    });

    await throttledActionSocketFn();
  };
  // endregion Handle Action Socket Events

  // region Handle installed item
  let installationTimeoutId: NodeJS.Timeout | null = null;
  const handleInstalledItem = (response: InstalledPayloadType) => {
    sitesItemInstalled.value.push({
      id: response.site,
      success: response.success,
    });

    if (installationTimeoutId) {
      clearTimeout(installationTimeoutId);
    }

    installationTimeoutId = setTimeout(async () => {
      const someFailure = sitesItemInstalled.value.some(site => !site.success);

      if (someFailure) {
        const createSiteEventBus = useEventBus<string>(EventSymbols.ITEM_INSTALLATION_FAILURE);

        createSiteEventBus.emit(EventSymbols.ITEM_INSTALLATION_FAILURE.toString(), location);
      }
    }, 10000);
  };
  // endregion Handle installed item

  return {
    cumulativeSyncSites,

    newSynchronizedSites,
    syncSitesRequested,
    siteItemInstalled,
    loadedComponents,
    loadedAllManager,

    globalMetas,
    type,
    tags,
    visibilityStatus,
    hasVulnerabilities,
    managerRequest,
    changedFilters,

    componentsMap,
    selectedCount,
    allowedActions,

    isGlobal,
    componentSelection,
    cleanSelection,

    sitesItemInstalled,

    reset,
    clearFilters,
    loadGlobalMetas,
    loadComponentSelection,
    loadComponents,
    loadComponentsMetas,
    getSelectedCount,
    manageAction,
    generateComponentSelection,

    handleSocketEvent,
    handleActionSocketEvent,
    handleInstalledItem,
  };
});

export { useManagerStore };
export default useManagerStore;
