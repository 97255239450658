<script lang="ts" setup>
import type { Vulnerability } from '@/types/models/site/service/vulnerabilities/Vulnerability';
import type { SiteItemChangelog } from '@/views/manager';
import type { InjectionKey, Ref } from 'vue';
import type { ToastInterface } from 'vue-toastification';

import api from '@/api';
import userManagerStore from '@/stores/manager/managerStore';

import TheManagerVulnerabilityItem from '@/views/manager/parts/TheManagerVulnerabilityItem.vue';

import { storeToRefs } from 'pinia';

import { computed, inject, onBeforeMount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

defineOptions({
  name: 'TheManagerVulnerabilityModal',
});

const props = defineProps<{
  item: SiteItemChangelog
}>();

const toast: InjectionKey<ToastInterface> | any = inject('toast');

const { t } = useI18n();

// To determine tracker event property
const managerStore = userManagerStore();
const { isGlobal } = storeToRefs(managerStore);

const loaded = ref(false);

const vulnerabilities: Ref<Vulnerability[] | null> = ref(null);

const isVulnerabilitySevere = computed(() => {
  if (!vulnerabilities.value)
    return false;

  return vulnerabilities.value.some(v => v.score !== null && v.score >= 7);
});

onBeforeMount(async () => {
  try {
    if (!!props.item.version) {
      vulnerabilities.value = await api.general.vulnerabilities.retrieve(
        props.item.type,
        props.item.slug,
        props.item.version,
      );
    }
  } catch (e) {
    console.error(e);

    toast.error(t('general.shared.error'));
  } finally {
    loaded.value = true;
  }
});

onMounted(() => {
  api.mixpanel.track('View vulnerability', {
    type: props.item.type,
    name: props.item.name,
    slug: props.item.slug,
    updater: isGlobal.value ? 'global' : 'web',
  });
});
</script>

<template>
  <v-modal id="site-updates-item-vulnerability-modal" class-name="modal-dialog-scrollable">
    <modal-header>
      <modal-title class="d-flex flex-column">
        <span class="d-flex align-items-center fw-medium">
          <span>{{ item.name }}</span>
          <span
            v-if="isVulnerabilitySevere"
            class="badge text-bg-danger-dark rounded-xs fw-medium text-uppercase ms-8 text-xs"
          >
            {{ $t('vulnerability.severity.high') }}
          </span>
        </span>
        <span class="fw-medium text-success-dark text-sm">
          {{ $t('site.overview.updates.actualVersion', { version: item.version }) }}
        </span>
      </modal-title>
    </modal-header>

    <modal-body class="pb-32">
      <div v-if="!loaded">
        <template v-for="n in 2" :key="n">
          <hr v-if="n !== 1" class="my-32">

          <div class="placeholder-glow">
            <span class="placeholder" style="width: 100px; height: 19px" />
          </div>

          <div class="placeholder-glow mb-8">
            <span class="placeholder" style="width: 200px; height: 19px" />
          </div>

          <ul>
            <li v-for="m in 4" :key="m">
              <div class="placeholder-glow">
                <span class="placeholder w-100" style="height: 19px" />
              </div>
            </li>
          </ul>
        </template>
      </div>

      <template v-else-if="!!vulnerabilities && vulnerabilities.length > 0">
        <template v-for="(vulnerability, index) in vulnerabilities" :key="index">
          <TheManagerVulnerabilityItem
            :vulnerability="vulnerability"
            :item-name="props.item.name"
            :item-type="props.item.type"
            :item-slug="props.item.slug"
            class="mb-16"
          />

          <hr v-if="index + 1 !== vulnerabilities?.length" class="mb-24">
        </template>
      </template>

      <div v-else>
        {{ $t('site.overview.updates.notVulnerabilitiesFound') }}
      </div>
    </modal-body>
  </v-modal>
</template>

<style lang="scss" scoped>
.loader-container {
  height: 6.25rem;
}
</style>
