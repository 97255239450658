import type { ServiceTypeEnumKeys } from '@/types/enums/ServiceTypeEnum';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import api from '@/api';
import useModalUtils from '@/composables/useModal';
import { useToast } from '@/composables/useToast';
import useSiteStore from '@/stores/site/siteStore';
import { useI18n } from 'vue-i18n';

function useToggleServiceStatus(service: ServiceTypeEnumKeys) {
  const { t } = useI18n();
  const { toastError } = useToast();
  const { Modal } = useModalUtils();

  const siteStore = useSiteStore();

  const toggleServiceStatus = async <T>() => {
    try {
      if (!!siteStore.currentSiteId) {
        return await api.site.service.status.toggle(
          siteStore.currentSiteId,
          service,
        ) as T;
      }
    } catch (e: any) {
      console.error(e);
      toastError();
    }

    return undefined;
  };

  const enableService = async <T>() => {
    await api.mixpanel.track(`Reactivate ${service}`, {
      'Website url': siteStore.currentSiteUri,
    });
    return await toggleServiceStatus() as T;
  };

  async function disableService<T>(options: {
    title: string
    text: string
  }): Promise<T | null> {
    return new Promise((resolve) => {
      Modal.delete({
        title: t(options.title),
        text: t(options.text),
        alertTitle: '',
        leftButtonText: t('general.button.disable'),
        leftButtonLoadingText: t('general.button.loading'),
        leftButtonCallback: async () => {
          if (!!siteStore.currentSiteId) {
            // Only Uptime can emit event
            if (service === ServiceTypeEnumConst.UPTIME) {
              await api.mixpanel.track(`Pause ${service}`, {
                'Website url': siteStore.currentSiteUri,
              });
            }

            const result = await toggleServiceStatus<T>();
            resolve(result as T);
          } else {
            resolve(null);
          }
        },
        closeModalCallback: () => {
          resolve(null);
        },
      });
    });
  }

  return {
    toggleServiceStatus,
    enableService,
    disableService,
  };
}

export { useToggleServiceStatus };
export default useToggleServiceStatus;
