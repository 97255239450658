import type { SiteBackupItemTypeEnumType } from '@/types/enums/SiteBackupItemTypeEnum';
import type { BackupItemStatus } from '@/types/models/site/service/backup/SiteBackup';
import handlerSocketEvents from '@/socket/handlerSocketEvents';
import AbstractService from '@/socket/services/AbstractService';

export interface BackupUpdateStatusSocketResponse {
  id: number
  status: string
}

export interface BackupUpdateItemSocketResponse {
  id: number
  type: SiteBackupItemTypeEnumType
  batch: number
  status: BackupItemStatus
  offset: number
  total_items: number
  site_backup_id: number
}

export interface BackupCalculateItemsSocketResponse {
  site_backup_id: number
}

export class SiteSocketService extends AbstractService {
  public listenEvents(events: string[], stop = false) {
    const { handlerEvent } = handlerSocketEvents();

    for (const event of events) {
      if (stop) {
        this.channel.stopListening(event);
      } else {
        this.channel.listen(event, (data: any) => handlerEvent(event, data));
      }
    }
  }

  /**
   * Event to listen if a report has been generated
   * @param callback
   */
  public siteReportGenerated(callback: (data: { id: number, status: string }) => void) {
    this.channel.listen('.site.report.generated', callback);
  }

  /**
   * Event to stop listening if a report has been generated
   */
  public stopListeningSiteReportGenerated() {
    this.channel.stopListening('.site.report.generated');
  }

  /**
   * Event to listen if a report has been generated
   */
  public siteBackup() {
    return {
      listenBackupStatus: (callback: (data: BackupUpdateStatusSocketResponse) => void) =>
        this.channel.listen('.site.backup.changed.status', callback),
      listenSiteBackupItemUpdate: (callback: (data: BackupUpdateItemSocketResponse) => void) =>
        this.channel.listen('.site.backup.item.changed.status', callback),
      listenSiteBackupCalculated: (callback: (data: BackupCalculateItemsSocketResponse) => void) =>
        this.channel.listen('.site.backup.items.calculated', callback),
      stop: () => {
        this.channel.stopListening('.site.backup.changed.status');
        this.channel.stopListening('.site.backup.item.changed.status');
        this.channel.stopListening('.site.backup.items.calculated');
      },
    };
  }

  // /**
  //  * Event to listen if you have finished making the request to refresh the site health.
  //  * @param callback
  //  */
  // public siteHealthRefresh(callback: (data: any) => void) {
  //   this.channel.listen('.site.health.updated', callback);
  // }
  //
  // /**
  //  * Event to stop listening if you have finished making the request to refresh the site health.
  //  */
  // public stopListeningSiteHealthRefresh() {
  //   this.channel.stopListening('.site.health.updated');
  // }
}

export default (site: string | number) => new SiteSocketService(`site.${site}`);
