<script lang="ts" setup>
import { useSiteCreate } from '@/composables/site/useSiteCreate';
import useAppStore from '@/stores/appStore';
import useManagerStore from '@/stores/manager/managerStore';
import useOrganizationStore from '@/stores/organizationStore';
import useSiteStore from '@/stores/site/siteStore';
import { computed } from 'vue';

defineOptions({
  name: 'TheManagerEmptyStates',
});

defineProps<{
  changedFilters: boolean
}>();

const managerStore = useManagerStore();

const visibilityStatus = computed(() => managerStore.visibilityStatus);

const organizationStore = useOrganizationStore();
const sitesUsed = computed(() => organizationStore.sitesUsed);

const siteStore = useSiteStore();
const currentSite = computed(() => siteStore.currentSite);
const isSiteConnectionLost = computed(() => (!!currentSite.value && currentSite.value.connectionStatus !== 'established'));

const { handleCreateSite } = useSiteCreate();

const appStore = useAppStore();
const isDark = computed(() => appStore.darkMode);

const blobEmptyStateSvg = computed(() => `<svg xmlns="http://www.w3.org/2000/svg" width="448" height="240" viewBox="0 0 195 118" fill="none"><path opacity="0.3" d="M59.5504 11.0248C31.2478 17.7112 8.88249 38.8391 2.31629 66.016C-2.56433 86.2378 1.0584 110.971 34.5498 114.658C106.438 122.576 183.013 117.833 192.195 85.0567C201.378 52.2807 183.535 3.83846 142.949 0.35777C119.835 -1.61691 85.1422 4.95078 59.5504 11.0248Z" fill="${isDark.value ? '#686868' : '#E6E6E6'}"/></svg>`);
</script>

<template>
  <div
    class="d-flex justify-content-center align-items-center h-100 updates-empty-done py-16"
    data-cy="no-updates"
  >
    <div class="position-relative d-flex justify-content-center">
      <v-picture
        class="updates-image-container z-index-2 me-24"
        height="224"
        width="224"
      >
        <img
          v-if="isSiteConnectionLost"
          :alt="$t('manager.lostConnection')"
          class="updates-error-image"
          src="@assets/img/site-connection-fail.png"
          srcset="@assets/img/site-connection-fail.png 2x, @assets/img/site-connection-fail.png 1x"
        >
        <img
          v-else-if="changedFilters"
          :alt="$t('manager.noFilterMatch')"
          class="updates-image"
          src="@assets/img/manager/question-manager.png"
          srcset="@assets/img/manager/question-manager.png 2x, @assets/img/manager/question-manager.png 1x"
        >
        <img
          v-else-if="!!sitesUsed && sitesUsed === 0"
          :alt="$t('manager.noFilterMatch')"
          class="updates-image"
          src="@assets/img/manager/no-connection-manager.png"
          srcset="@assets/img/manager/no-connection-manager.png 2x, @assets/img/manager/no-connection-manager.png 1x"
        >
        <img
          v-else-if="visibilityStatus === 'hidden'"
          :alt="$t('manager.noHiddenUpdates')"
          class="updates-image"
          src="@assets/img/manager/hidden-manager.png"
          srcset="@assets/img/manager/hidden-manager.png 2x, @assets/img/manager/hidden-manager.png 1x"
        >
        <img
          v-else
          :alt="$t('manager.noPendingUpdates')"
          class="updates-image"
          src="@assets/img/manager/running-manager.png"
          srcset="@assets/img/manager/running-manager.png 2x, @assets/img/manager/running-manager.png 1x"
        >
      </v-picture>

      <span v-dompurify-html="blobEmptyStateSvg" class="position-absolute start-0 z-index-1" />

      <div
        class="updates-text-container d-flex flex-column justify-content-center position-relative z-index-2"
        :class="{ 'col-5': isSiteConnectionLost || changedFilters }"
      >
        <h4 class="text-normal">
          <template v-if="isSiteConnectionLost">
            {{ $t('manager.lostConnection') }}
          </template>
          <template v-else-if="changedFilters">
            {{ $t('manager.noFilterMatch') }}
          </template>
          <template v-else-if="!!sitesUsed && sitesUsed === 0">
            {{ $t('manager.noFilterMatch') }}
          </template>
          <template v-else-if="visibilityStatus === 'hidden'">
            {{ $t('manager.noHiddenUpdates') }}
          </template>
          <template v-else>
            {{ $t('manager.noPendingUpdates') }}
          </template>
        </h4>

        <p class="mb-0 text-gray-500 fw-medium text-sm">
          <span
            v-if="isSiteConnectionLost"
            v-html="$t('manager.lostConnectionText', {
              link: 'https://soporte.modulards.com/es/error-conexion-web',
              email: 'soporte@modulards.com',
            })"
          />

          <span v-else-if="changedFilters">
            {{ $t('manager.noFilterMatchText') }}
          </span>

          <span v-else-if="!!sitesUsed && sitesUsed === 0">
            {{ $t('manager.noSitesConnectionText') }}
          </span>

          <span v-else-if="visibilityStatus === 'hidden'">
            <span v-html="$t('manager.noHiddenUpdatesText')" />
          </span>

          <span v-else>
            {{ $t('manager.noPendingUpdatesText') }}
          </span>
        </p>

        <VButton
          v-if="!changedFilters && !!sitesUsed && sitesUsed === 0"
          id="manager-create-web"
          class="btn-icon text-decoration-none fit-content fw-semi px-40 mt-16"
          data-cy="manager-create-web"
          size="sm"
          variant="primary"
          @click="handleCreateSite('Manager')"
        >
          {{ $t('manager.connectWeb') }}
        </VButton>
      </div>
    </div>
  </div>
</template>
