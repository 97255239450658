import type { DateTime } from 'luxon';

// import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

// import useUserStore from '@/stores/userStore';
import DateHelper from '@/helpers/dateHelper';
import { useI18n } from 'vue-i18n';

function useDate() {
  // const userStore = useUserStore();
  const { locale } = useI18n();

  // const { userLogged: user } = storeToRefs(userStore);

  const defaultTimezone = ref(DateHelper.getUserBrowserTimezone());
  const defaultDateFormat = ref(DateHelper.getBrowserDateFormat());
  const defaultTimeFormat = ref(DateHelper.getBrowserTimeFormat() === '12h' ? 'h:mm a' : 'HH:mm');

  // 'Europe/Madrid' - user.value?.preferredTimezone
  const preferredTimezone = computed(() => defaultTimezone.value);
  // 'dd/MM/yyyy' - user.value?.preferredDateFormat
  const preferredDateFormat = computed(() => defaultDateFormat.value);
  // 'h:mm a' || 'HH:mm' - user.value?.preferredTimeFormat
  const preferredTimeFormat = computed(() => defaultTimeFormat.value);

  const parseDateToUTC = (date: string | Date | DateTime) => DateHelper.parse(date).toUTC().toFormat('yyyy-MM-dd');

  const parseDateToTimeFormat = (date: Date | DateTime | string | number) => {
    const dateToParse = DateHelper.parse(date);

    return dateToParse.setZone(preferredTimezone.value).toFormat(preferredTimeFormat.value);
  };

  const parseDateToPreferredFormat = (date: Date | DateTime | string | number, format?: string) => {
    const dateToParse = DateHelper.parse(date).setLocale(locale.value).setZone(preferredTimezone.value);

    if (format) {
      return dateToParse.toFormat(format);
    }

    return dateToParse.toFormat(preferredDateFormat.value);
  };

  return {
    parseDateToUTC,
    parseDateToPreferredFormat,
    parseDateToTimeFormat,
    preferredTimezone,
    preferredDateFormat,
    preferredTimeFormat,
    defaultTimezone,
    defaultDateFormat,
    defaultTimeFormat,
  };
}

export default useDate;
