<script lang="ts" setup>
import useRedirectToPayment from '@/composables/useRedirectToPayment';
import useAppStore from '@/stores/appStore';
import useOrganizationStore from '@/stores/organizationStore';
import { computed } from 'vue';

defineOptions({
  name: 'VUpgradeFreeUserBanner',
});

const props = withDefaults(defineProps<{
  title: string
  description: string
  trackName: string
  decorations?: boolean
}>(), {
  decorations: false,
});

const { redirectToPayment } = useRedirectToPayment();

function redirectToPro() {
  redirectToPayment({ trackName: props.trackName });
}

const appStore = useAppStore();
const organizationStore = useOrganizationStore();

const darkMode = computed(() => appStore.darkMode);
const price = computed(() => organizationStore.prices.pro.yearly.value);
</script>

<template>
  <div class="position-relative upgrade-free-user-banner-container my-4">
    <div
      :class="{ 'bg-gray-900 text-light': !darkMode, 'bg-gray-100 text-dark': darkMode }"
      class="upgrade-free-user-banner d-flex flex-column flex-xl-row justify-content-between align-items-center position-relative rounded-sm
            px-24 py-16"
    >
      <div class="pe-xl-16 mb-xl-0 mb-24">
        <h4 class="fw-semi text-lg mb-4">
          {{ $t(title) }}
        </h4>

        <p class="fw-medium text-sm mb-0" v-html="$t(description)" />
      </div>

      <div class="d-flex flex-column flex-md-row justify-content-between">
        <span
          v-dompurify-html="$t('billing.fromPriceInterval', { price, interval: $t('general.shared.month') })"
          class="d-flex align-items-center gap-4 me-md-32 mb-md-0 mb-16"
        />

        <VButton class="fw-semi lh-xl" variant="primary" @click="redirectToPro">
          {{ $t('billing.sidebar.becomeAPro') }}
        </VButton>
      </div>
    </div>

    <span v-if="decorations" class="decoration decoration-triangle text-warning h4" />
    <span v-if="decorations" class="decoration decoration-square text-info h4" />
    <span v-if="decorations" class="decoration decoration-cross text-light-blue h1" />
  </div>
</template>

<style lang="scss" scoped>
.upgrade-free-user-banner-container {
  .upgrade-free-user-banner {
    z-index: 10;
  }

  .decoration {
    position: absolute;
    z-index: 20;

    &.decoration-triangle {
      top: -15%;
      right: 25%;
    }

    &.decoration-square {
      bottom: -20%;
      right: -2%;
    }

    &.decoration-cross {
      z-index: 5 !important;
      bottom: -30%;
      left: 12%;
    }
  }

  @media (width >= 1200px) {
    .decoration {
      &.decoration-triangle {
        top: -25%;
        right: 10%;
      }

      &.decoration-square {
        bottom: -35%;
        right: -0.5%;
      }

      &.decoration-cross {
        top: 65%;
        left: 10%;
      }
    }
  }
}
</style>
