import type { ISiteUptimeStore } from '@/stores/site/uptime/index';
import type { SiteServiceUptime } from '@/types/models/site/service/uptime/SiteServiceUptime';
import type { UptimeStats } from '@/types/models/site/service/uptime/stats/UptimeStats';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import useSiteStore from '@/stores/site/siteStore';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';
import { SiteServiceStatusEnumConst } from '@/types/enums/SiteServiceStatusEnum';
import { defineStore } from 'pinia';

export const useUptimeStore = defineStore('siteUptime', (): ISiteUptimeStore => {
  const loadedUptime = ref(false);
  const loadedUptimeStats = ref(false);

  const uptimeDateRange = ref('7-days');

  const uptimeService: Ref<SiteServiceUptime | null> = ref(null);
  const uptimeStats: Ref<UptimeStats | null> = ref(null);

  const reset = () => {
    uptimeService.value = null;
    uptimeStats.value = null;
    loadedUptime.value = false;

    loadedUptimeStats.value = false;

    uptimeDateRange.value = '7-days';
  };

  const siteStore = useSiteStore();

  const siteServicesUptimeService = computed(() => (siteStore.siteServices?.find(service => service.type === ServiceTypeEnumConst.UPTIME)));

  const siteHasUptimeServiceEnabled = computed(
    () => siteServicesUptimeService.value?.isActive,
  );

  const uptimeServiceId = computed(() => {
    return siteServicesUptimeService.value?.id;
  });

  const uptimeIsEnabled = computed(
    () => (
      uptimeService.value?.status === SiteServiceStatusEnumConst.ACTIVE
    ),
  );

  const uptimeIsConfigured = computed(() => (siteServicesUptimeService.value?.isConfigured));

  const uptimeIsConfiguredInactive = computed(() => (
    siteServicesUptimeService.value?.isConfigured && uptimeService.value?.status === SiteServiceStatusEnumConst.INACTIVE
  ));

  return {
    uptimeService,
    uptimeStats,

    loadedUptime,
    loadedUptimeStats,

    uptimeDateRange,

    uptimeServiceId,

    siteHasUptimeServiceEnabled,
    uptimeIsEnabled,

    uptimeIsConfigured,
    uptimeIsConfiguredInactive,

    reset,
  };
});
