<script lang="ts" setup>
import type { IconTypes } from '@/components/vendor/basic/icon/VIcon.vue';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import api from '@/api';

import TheSiteReportCreateTask from '@/components/site/reports/tasks/TheSiteReportCreateTask.vue';

import useModal from '@/composables/useModal';
import useSiteFunctions from '@/composables/useSiteFunctions';

import useSiteStore from '@/stores/site/siteStore';

import { useUptimeStore } from '@/stores/site/uptime/uptimeStore';

import { ChangeStatusEnumConst } from '@/types/enums/ChangeStatusEnum';
import { storeToRefs } from 'pinia';

defineOptions({
  name: 'TheSiteHeaderContent',
});

const props = withDefaults(
  defineProps<{
    leftHeaderClass?: string
    showUptime?: boolean
    showTaskButton?: boolean
  }>(),
  {
    leftHeaderClass: 'me-24',
    showUptime: false,
    showTaskButton: false,
  },
);

const siteStore = useSiteStore();
const { currentSiteSlug, currentSiteName, currentSite } = storeToRefs(siteStore);

const uptimeStore = useUptimeStore();
const uptimeService = computed(() => uptimeStore.uptimeService);

const { parseUri } = useSiteFunctions();

const siteParsedUri = computed(() => parseUri(currentSite.value?.host, currentSite.value?.path, true));

const { openModal, modalToShow, closeModal } = useModal();

const changeStatus = computed(() =>
  props.showUptime && !!uptimeService.value?.currentChange?.status ? uptimeService.value?.currentChange.status : '',
);

const changeStatusData: ComputedRef<{
  icon: IconTypes
  color: string
  background: string
  text: string
}> = computed(() => {
  let result = {
    icon: 'question' as IconTypes,
    color: 'text-gray-500',
    background: 'bg-gray-400',
    text: 'report.uptime.activity.unknown',
  };

  if (changeStatus.value === ChangeStatusEnumConst.UP) {
    result = {
      icon: 'arrow-up' as IconTypes,
      color: 'text-success-dark',
      background: 'bg-success-light',
      text: 'report.uptime.activity.upShort',
    };
  } else if (changeStatus.value === ChangeStatusEnumConst.DOWN) {
    result = {
      icon: 'arrow-down' as IconTypes,
      color: 'text-danger',
      background: 'bg-danger-light',
      text: 'report.uptime.activity.downShort',
    };
  } else if (changeStatus.value === ChangeStatusEnumConst.UNKNOWN) {
    result = {
      icon: 'pause' as IconTypes,
      color: 'text-warning-medium',
      background: 'bg-warning-light',
      text: 'report.uptime.activity.paused',
    };
  }

  return result;
});

function handleCreateTask() {
  openModal('the-site-report-create-task');

  api.mixpanel.track('Try to add task', {
    'Website name': currentSiteName.value,
    'Website url': currentSiteSlug.value,
    'Location': 'Overview site nav button',
  });
}
</script>

<template>
  <div class="header-content d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-baseline w-100 flex-column flex-xl-row" :class="leftHeaderClass">
      <h1 :class="{ 'placeholder-glow': !currentSite }" class="site-title h5 mb-0 me-8">
        <span v-if="!!currentSite" data-cy="site-title">{{ currentSite.name }}</span>
        <span v-else class="placeholder" style="width: 200px" />
      </h1>

      <a
        v-if="!!currentSite"
        :href="currentSite.uri"
        class="btn btn-link btn-link-primary-dark site-domain fw-semi lh-xl mb-0 py-0 text-sm"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ siteParsedUri }}
      </a>

      <span v-else class="placeholder-glow">
        <span class="placeholder" style="width: 200px" />
      </span>

      <template v-if="showUptime">
        <div
          v-if="!!currentSite"
          :class="changeStatusData.color"
          class="site-uptime d-flex align-items-baseline ms-8"
          :title="$t('report.uptime.activity.uptimeStatus')"
        >
          <v-icon
            :class="{
              'site-uptime-up': changeStatusData.icon === 'arrow-up',
            }"
            :icon="changeStatusData.icon"
            class="site-uptime-icon fw-semi text-sm"
            space="me-4"
          />

          <span :class="changeStatusData.color" class="text-uppercase fw-semi text-sm">
            {{ $t(changeStatusData.text) }}
          </span>
        </div>

        <span v-else class="placeholder-glow me-8">
          <span class="placeholder" style="width: 45px" />
        </span>
      </template>
    </div>

    <template v-if="showTaskButton">
      <VButton class="btn-icon btn-outline-primary h-fit" size="2xs" @click="handleCreateTask">
        <m-icon icon="actions-plus" space="me-4" />
        {{ $t('siteReports.tasks.task') }}
      </VButton>

      <TheSiteReportCreateTask
        v-if="modalToShow === 'the-site-report-create-task'"
        @closed="closeModal"
      />
    </template>

    <slot name="header-right" />
  </div>
</template>

<style lang="scss" scoped>
.site-domain {
  color: var(--md-primary);

  &:hover {
    color: var(--md-secondary);
  }
}

.site-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 16ch;
  overflow: hidden;
  
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    max-width: 10ch;
  }
}
</style>
