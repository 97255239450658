import type { RouteRecordRaw } from 'vue-router';

import VSocialAuthLayout from '@/layouts/VSocialAuthLayout.vue';

const TheSocialAuth = () => import('@/views/social/TheSocialAuth.vue');

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    component: VSocialAuthLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'social/google/confirm',
        name: 'auth.google.confirm',
        component: TheSocialAuth,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default authRoutes;

export { authRoutes };
