<script lang="ts" setup>
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue';
import { cn } from '@/helpers/classNames';
import { CheckboxIndicator, CheckboxRoot, useForwardPropsEmits } from 'radix-vue';
import type { HTMLAttributes, WritableComputedRef } from 'vue';

const props = defineProps<Omit<CheckboxRootProps, 'checked'> & {
  class?: HTMLAttributes['class']
  square?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
  checked?: WritableComputedRef<boolean> | boolean | 'indeterminate'
}>();
const emits = defineEmits<CheckboxRootEmits>();

const delegatedProps = computed(() => {
  const { class: _, square, size, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <CheckboxRoot
    :class="
      cn('ui-checkbox transition-colors',
         square && 'ui-checkbox-square',
         {
           'ui-checkbox-xs': props.size === 'xs',
           'ui-checkbox-sm': props.size === 'sm',
           'ui-checkbox-lg': props.size === 'lg',
         },
         props.class)"
    v-bind="forwarded"
  >
    <CheckboxIndicator class="ui-checkbox-indicator d-flex h-100 w-100 align-items-center justify-content-center">
      <slot>
        <m-icon v-if="checked === 'indeterminate'" class="text-light h-100 d-flex align-items-center" icon="actions-minus" :size="square ? '3xs' : ''" />
        <m-icon v-else class="text-light" icon="check" :size="square ? '2xs' : ''" />
      </slot>
    </CheckboxIndicator>
  </CheckboxRoot>
</template>
