import type { SimpleResponse } from '@/types/Api';
import type { TagSyncPayload } from '@/types/models/organization/tag/Tag';
import type { Site } from '@/types/models/site/Site';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';
import type { SiteActiveServices, SiteFiltersType } from '@/stores/site';

export default class SiteService extends AbstractService {
  /**
   * Retrieves a single Site
   *
   * @param {string} site
   * @param params
   * @returns {Promise<Site>}
   */
  public retrieve(site: string, params?: SiteFiltersType): Promise<Site> {
    return this.request({
      method: apiEndpoints.sitesShow.method,
      url: this.buildPath(apiEndpoints.sitesShow, { site }),
      params,
      options: {
        cancellable: true,
        cancelKey: 'siteRetrieve',
      },
    });
  }

  /**
   * Retrieves a single Site active services with their ID
   *
   * @param {string} site
   * @returns {Promise<Site>}
   */
  public retrieveServices(site: string): Promise<SiteActiveServices[]> {
    return this.request({
      method: apiEndpoints.sitesServicesIndex.method,
      url: this.buildPath(apiEndpoints.sitesServicesIndex, { site }),
    });
  }

  /**
   * Creates a single Site
   *
   * @param site
   * @returns {Promise<Site>}
   */
  public create(site: {
    name: string
    provider: 'wp'
    uri: string
    team: number
  }): Promise<Site> {
    return this.request({
      method: apiEndpoints.sitesStore.method,
      url: this.buildPath(apiEndpoints.sitesStore),
      data: site,
    });
  }

  /**
   * Updates the current Site
   *
   * @param {number} siteId
   * @param {Record<string, any>} data
   * @returns {Promise<Site>}
   */
  public update(siteId: number, data: Record<string, any>): Promise<Site> {
    return this.request({
      method: apiEndpoints.sitesUpdate.method,
      url: this.buildPath(apiEndpoints.sitesUpdate, {
        site: siteId,
      }),
      data,
    });
  }

  /**
   * Deletes the current Site
   *
   * @param {string} site
   * @returns {Promise<SimpleResponse>}
   */
  public delete(siteId: number): Promise<SimpleResponse> {
    return this.request({
      method: 'delete',
      url: this.buildPath(apiEndpoints.sitesDestroy, {
        site: siteId,
      }),
    });
  }

  /**
   * Regenerates the screenshot for the current site.
   *
   * @param {string} siteId
   * @returns {Promise<SimpleResponse>}
   */
  public renewScreenshot(siteId: number): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.screenshotServicesRenew.method,
      url: this.buildPath(apiEndpoints.screenshotServicesRenew, {
        site: siteId,
      }),
    });
  }

  /**
   * Syncs a tag for the current site.
   *
   * @param {string} site
   * @param {TagSyncPayload} data
   * @returns {Promise<Site>}
   */
  public syncTags(site: number, data: TagSyncPayload): Promise<Site> {
    return this.request({
      method: 'patch',
      url: this.buildPath(apiEndpoints.siteTagsToggle, {
        site,
      }),
      data,
    });
  }
}
