import type { RouteRecordRaw } from 'vue-router';

import TheMenuDashboard from '@/components/global/menu/TheMenuDashboard.vue';
import VLayout from '@/layouts/VLayout.vue';
import VModalLayout from '@/layouts/VModalLayout.vue';
import alertingRoutes from '@/router/modules/alertingRoutes';
import billingRoutes from '@/router/modules/billingRoutes';
import siteRoutes from '@/router/modules/siteRoutes';

import teamRoutes from '@/router/modules/teamRoutes';

const TheHome = () => import('@/views/global/TheHome.vue');
const TheRecent = () => import('@/views/global/TheRecent.vue');
const TheDashboardSidebar = () => import('@/components/dashboard/sidebar/TheDashboardSidebar.vue');
const TheUserProfileHeaderContent = () => import('@/components/user/header/TheUserProfileHeaderContent.vue');

const TheUserProfileSidebar = () => import('@/components/user/sidebar/TheUserProfileSidebar.vue');
const TheUserProfileConfirm = () => import('@/views/global/TheUserProfileConfirm.vue');
const TheUserProfileSettings = () => import('@/views/global/TheUserProfileSettings.vue');
const TheUserProfileConnection = () => import('@/views/global/TheUserProfileConnection.vue');
const TheUserProfile = () => import('@/views/global/TheUserProfile.vue');

const TheSettings = () => import('@/views/settings/TheSettings.vue');
const TheWhiteLabel = () => import('@/views/settings/white-label/TheWhiteLabel.vue');
const TheEmailDomains = () => import('@/views/settings/email-domains/TheEmailDomains.vue');
const TheGoogleAccounts = () => import('@/views/settings/account-integrations/TheAccountsIntegrations.vue');
const TheChangelog = () => import('@/views/changelog/TheChangelog.vue');
const TheUpdateManager = () => import('@/views/manager/TheUpdateManager.vue');
const TheInstallationManager = () => import('@/views/manager/installation/TheInstallationManager.vue');

const homeRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: VLayout,
    props: true,
    meta: {
      requiresAuth: true,
      transition: 'fade-in',
    },
    children: [
      ...teamRoutes,
      ...siteRoutes,
      ...billingRoutes,
      ...alertingRoutes,
      {
        path: '',
        name: 'me.sites.index',
        components: {
          default: TheHome,
          menu: TheMenuDashboard,
          sidebar: TheDashboardSidebar,
        },
        props: {
          default: false,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'sites',
        name: 'sites',
        components: {
          default: TheRecent,
          menu: TheMenuDashboard,
        },
        props: {
          default: false,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'manager',
        alias: ['updates'],
        name: 'manager.update.index',
        components: {
          default: TheUpdateManager,
          menu: TheMenuDashboard,
        },
        meta: {
          requiresAuth: true,
          events: {
            organization: ['.site.items.updated', '.site.items.managed', '.site.items.deleted', '.site.items.installed'],
          },
        },
        props: {
          default: true,
        },
        children: [
          {
            path: ':type/install',
            name: 'manager.component.install',
            components: {
              default: TheInstallationManager,
            },
            meta: {
              requiresAuth: true,
              isModalView: true,
              events: {
                organization: ['.site.items.installed'],
              },
            },
            props: true,
          },
        ],
      },
      {
        path: 'profile',
        name: 'me.show',
        components: {
          default: TheUserProfile,
          menu: TheMenuDashboard,
          sidebar: TheUserProfileSidebar,
        },
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'me.profile',
            props: true,
            components: {
              default: TheUserProfileSettings,
              menu: TheMenuDashboard,
              headerContent: TheUserProfileHeaderContent,
              sidebar: TheUserProfileSidebar,
            },
            meta: {
              requiresAuth: true,
              shouldShowTransition: true,
            },
          },
          {
            path: 'connection',
            name: 'me.connection',
            props: true,
            components: {
              default: TheUserProfileConnection,
              menu: TheMenuDashboard,
              headerContent: TheUserProfileHeaderContent,
              sidebar: TheUserProfileSidebar,
            },
            meta: {
              requiresAuth: true,
              shouldShowTransition: true,
            },
          },
          {
            path: 'confirm',
            name: 'me.social.confirm',
            component: TheUserProfileConfirm,
            meta: {
              requiresAuth: true,
              shouldShowTransition: false,
            },
          },
        ],
      },
      {
        path: 'settings',
        name: 'settings.index',
        redirect: { name: 'settings.white-label.index' },
        components: {
          default: TheSettings,
          menu: TheMenuDashboard,
        },
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'white-label',
            name: 'settings.white-label.index',
            props: true,
            components: {
              menu: TheMenuDashboard,
              default: TheWhiteLabel,
            },
          },
          {
            path: 'domains',
            name: 'settings.domains.index',
            props: true,
            components: {
              menu: TheMenuDashboard,
              default: TheEmailDomains,
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'accounts',
            name: 'settings.accounts-integrations.index',
            props: true,
            components: {
              menu: TheMenuDashboard,
              default: TheGoogleAccounts,
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/changelog',
    name: 'changelog',
    component: VModalLayout,
    props: {
      title: 'changelog.title',
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'changelog.index',
        props: true,
        components: {
          default: TheChangelog,
        },
      },
    ],
  },
];

export default homeRoutes;

export { homeRoutes };
