<script lang="ts" setup>
import TheSiteHeaderContent from '@/components/site/header/TheSiteHeaderContent.vue';

import { useSiteTags } from '@/composables/site/useSiteTags';

import useScrollToElement from '@/composables/useScrollToElement';
import useSiteStore from '@/stores/site/siteStore';

import TheUpdateManager from '@/views/manager/TheUpdateManager.vue';
import TheSiteMetricsLighthouseReport from '@/views/site/overview/analytics/TheSiteMetricsLighthouseReport.vue';
import TheSiteOverviewBackups from '@/views/site/overview/backup/TheSiteOverviewBackups.vue';
import TheSiteOverviewHealth from '@/views/site/overview/health/TheSiteOverviewHealth.vue';

import TheSiteOverviewSearchConsole from '@/views/site/overview/search-console/TheSiteOverviewSearchConsole.vue';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

defineOptions({
  name: 'TheSiteOverview',
});

defineProps({
  site: {
    type: String,
    required: true,
  },
});

const route = useRoute();
const { scrollToElement } = useScrollToElement();

const siteStore = useSiteStore();

const currentSite = computed(() => siteStore.currentSite);

const { siteTagsSelected, syncTag } = useSiteTags(currentSite);

onMounted(() => {
  if (!!route.hash) {
    const sectionElement: HTMLElement | null = document.getElementById(route.hash.slice(1));

    if (!!sectionElement) {
      scrollToElement(route.hash.slice(1), 'start', 1000);
    }
  }
});
</script>

<template>
  <div id="site-overview-page" class="w-100 mb-120">
    <div id="site-overview">
      <TheSiteHeaderContent class="mb-16" show-task-button show-uptime />

      <VSiteDisconnectAlert />

      <VTagsCombobox v-model="siteTagsSelected" class="mb-24" location="Overview" sync-tag @selected="syncTag" />

      <TheSiteOverviewHealth :site="site" />

      <TheSiteOverviewBackups :site="site" />

      <TheUpdateManager id="site-update-manager" />

      <TheSiteMetricsLighthouseReport :site="site" />

      <TheSiteOverviewSearchConsole />
    </div>

    <slot name="sidebar" />
  </div>
</template>

<style lang="scss" scoped>
#site-overview-page {
  display: grid;

  :deep(#site-overview) {
    grid-area: area-overview;
  }

  :deep(#site-sidebar-actions) {
    grid-area: area-actions;
  }

  :deep(#site-sidebar-note) {
    grid-area: area-note;
  }

  :deep(#overview-aside-report) {
    grid-area: area-report;
  }

  :deep(#overview-aside-uptime) {
    grid-area: area-uptime;
  }

  :deep(#overview-aside-trusted) {
    grid-area: area-trusted;
  }

  @media (width >= 1200px) {
    grid-template-areas: 'area-overview area-sidebar';
    grid-template-columns: auto 18.75rem;
    grid-column-gap: 3rem;

    :deep(#right-sidebar) {
      grid-area: area-sidebar;
    }
  }

  @media (width <= 1199.98px) {
    overflow-x: auto;
    grid-template-areas:
      'area-actions'
      'area-note'
      'area-overview'
      'area-report'
      'area-uptime'
      'area-trusted';
    grid-template-columns: 1fr;

    :deep(#right-sidebar) {
      display: contents;
    }
  }
}
</style>
