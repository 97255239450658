<script lang="ts">
import type { MIconTypes } from '@/components/vendor/basic/icon/MIcon.vue';
import type { Media } from '@/types/models/Media';

import type { PropType } from 'vue';
import VUploadMediaModal from '@/components/vendor/upload/VUploadMediaModal.vue';

import useModalUtils from '@/composables/useModal';
import { usePicture } from '@/composables/usePicture';
import { getHummanSize } from '@/helpers/generalHelper';

import { deAttribute } from '@modular/sdk';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VMediaUploadComponent',
  components: {
    VUploadMediaModal,
  },
  props: {
    files: {
      required: false,
      type: Object as PropType<Media> | PropType<Media[]> | null,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: 'siteReports.addImage',
    },
    validationRules: {
      required: false,
      type: Object,
      default: () => {},
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    sortable: {
      default: true,
      type: Boolean,
    },
    maxItems: {
      required: false,
      type: Number,
      default: 1,
    },
    backdropOverContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    addImageClasses: {
      type: String,
      required: false,
      default: '',
    },
    optional: {
      type: Boolean,
      required: false,
      default: false,
    },
    routePrefix: {
      required: false,
      type: String,
      default: 'api/files/temporary',
    },
    icon: {
      required: false,
      type: String as PropType<MIconTypes>,
      default: 'image',
    },
  },
  emits: ['after-upload'],
  setup() {
    return {
      ...useModalUtils(),
    };
  },
  data() {
    return {
      imageErrored: false,
      mediaFiles: [] as Media[],
      reloadImage: false,
    };
  },
  watch: {
    files: {
      handler(newVal) {
        if (!!newVal) {
          this.loadFiles();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    usePicture,
    getHummanSize,
    loadThumb(media: Media[]) {
      if (!!media) {
        if (this.multiple) {
          this.mediaFiles = media;

          this.mediaFiles.map((file: Media) => deAttribute(file));

          if (!!this.maxItems && this.mediaFiles.length > this.maxItems) {
            this.mediaFiles.splice(0, this.mediaFiles.length - this.maxItems);
          }
        } else {
          this.mediaFiles = deAttribute(media);
        }
        this.$emit('after-upload', this.mediaFiles);
      }

      this.closeModal();
    },
    removeMedia(uuid: string | undefined) {
      const indexOfFileToRemove = this.mediaFiles.findIndex((media: any) => media.uuid === uuid);

      this.mediaFiles.splice(indexOfFileToRemove, 1);

      this.$emit('after-upload', this.mediaFiles);
    },
    loadFiles() {
      if (!!this.files) {
        if (Array.isArray(this.files) && this.files.length > 0) {
          if ('attributes' in this.files[0]) {
            this.mediaFiles = this.files.map((file: Media) => deAttribute(file));
          } else {
            this.mediaFiles = this.files;
          }
        } else if (typeof this.files === 'object' && Object.keys(this.files).length > 0) {
          if ('attributes' in this.files) {
            this.mediaFiles = [deAttribute(this.files)];
          } else {
            this.mediaFiles = [this.files as any];
          }
        } else {
          this.mediaFiles = [];
        }
      }
    },
  },
});
</script>

<template>
  <div class="md-media-upload w-100">
    <div
      v-if="
        (!!mediaFiles && mediaFiles.length > 0)
          || (typeof mediaFiles === 'object' && Object.keys(mediaFiles).length > 0)
      "
      class="d-flex flex-column"
    >
      <template v-for="media in mediaFiles" :key="!!media?.id ? media.id : media?.uuid">
        <div
          v-if="!!media && Object.keys(media).length > 0"
          class="show-image d-flex align-items-center border-1 mb-8 border p-4"
          data-cy="show-image"
        >
          <div class="image-box me-16" :class="media.extension === 'zip' ? 'rounded-xs ratio ratio-1x1 bg-primary-light' : 'bg-gray-400'">
            <div v-if="media.extension === 'zip'" class="d-flex justify-content-center align-items-center w-100 fw-semi">
              <m-icon class="text-gray-900" icon="zip-file" />
            </div>

            <img
              v-else-if="!!media?.fileName && !!media?.url"
              :alt="media.fileName"
              :src="media.url"
              :srcset="usePicture(media, 'preview').value.srcset"
              class="h-100"
            >
            <img
              v-else-if="!!media?.name && !!media?.previewUrl"
              :alt="media.name"
              :src="media.previewUrl"
              class="h-100"
            >
          </div>

          <div class="fw-medium w-100 text-sm">
            <p v-if="!!media?.name" class="text-gray-600 mb-4">
              {{ media.name }}
            </p>

            <p v-if="!!media?.size" class="mb-0 text-gray-500">
              {{ getHummanSize(media?.size) }}
            </p>

            <VButton
              data-cy="btn-media-delete"
              class="btn-link-primary-dark btn-icon fw-medium mb-0 text-sm text-gray-500"
              variant="link"
              @click="removeMedia(media?.uuid)"
            >
              {{ $t('general.button.delete') }}

              <m-icon icon="delete" class="ms-8" size="md" />
            </VButton>
          </div>
        </div>
      </template>
    </div>

    <VButton
      v-if="!!maxItems ? mediaFiles.length < maxItems : true"
      class="add-image d-flex align-items-start border-1 w-100 mb-8 border p-4 text-start"
      :class="[addImageClasses, { 'align-items-center': !files || (!!files && Object.keys(files).length === 0) }]"
      data-cy="add-image"
      @click="modalToShow = 'modal'"
    >
      <div class="image-box rounded-xs ratio ratio-1x1 me-16 bg-gray-300">
        <div v-if="!!title" class="d-flex justify-content-center align-items-center w-100 fw-semi">
          <m-icon class="text-gray-600" :icon="icon" />
        </div>
      </div>

      <div class="fw-medium w-100 text-sm">
        <p class="text-primary mb-0">
          {{ $t(title) }}
        </p>

        <p v-if="optional" class="text-gray-500 mt-4">
          {{ $t('user.optional') }}
        </p>
      </div>
    </VButton>

    <VUploadMediaModal
      :open="modalToShow === 'modal'"
      :backdrop-over-content="backdropOverContent"
      :max-items="maxItems - mediaFiles.length"
      :multiple="multiple"
      :sortable="sortable"
      :validation-rules="validationRules"
      :route-prefix="routePrefix"
      @closed="closeModal"
      @closed-with-media="loadThumb"
    />
  </div>
</template>

<style lang="scss" scoped>
.md-media-upload {
  max-width: 32rem;
  width: 100%;

  .show-image,
  .add-image {
    transition: background-color 0.3s ease-out;

    .image-box {
      max-width: 6rem;

      img {
        object-fit: cover;
      }
    }
  }

  .add-image {
    &:hover {
      cursor: pointer;
      background-color: var(--md-gray-200);
    }
  }
}
</style>
