<script>
import { defineComponent } from 'vue';

/**
 * TODO DELETE ME
 */
export default defineComponent({
  name: 'ModalFooter',
});
</script>

<template>
  <div class="modal-footer border-top-0">
    <slot />
  </div>
</template>
