import type { YupSchema } from 'vee-validate';
import { useForm } from 'vee-validate';

import { ref } from 'vue';

/**
 * Composable function to manage form validation using Vee-Validate.
 *
 * @param {any} schema - The Vee-Validate schema object for validation rules.
 * @param {any} [initialValues] - The initial values of the form fields.
 *
 * @returns {object} An object containing various properties and methods for form validation.
 * - meta: Information regarding form's validation state.
 * - values: Current values of all form fields.
 * - errors: Errors associated with the form fields.
 * - accepted: A ref containing a boolean indicating if the form is accepted.
 * - setErrors: Function to set errors manually.
 * - setFieldError: Function to set a specific field's error.
 * - resetForm: Function to reset the form.
 * - setValues: Function to set form values.
 * - setTouched: Function to set touched fields.
 * - setFieldTouched: Function to set a specific field as touched.
 * - setFieldValue: Function to set a specific field's value.
 * - validate: Function to validate the form.
 * - validateField: Function to validate a specific field.
 * - resetField: Function to reset a specific field.
 * - toggleAccepted: Function to toggle the 'accepted' state.
 *
 * @example
 * const {
 *   meta,
 *   values,
 *   errors,
 *   accepted,
 *   setErrors,
 *   setFieldError,
 *   resetForm,
 *   setValues,
 *   setTouched,
 *   setFieldTouched,
 *   setFieldValue,
 *   validate,
 *   validateField,
 *   resetField,
 *   toggleAccepted,
 * } = useValidation(mySchema, myInitialValues);
 */
function useValidation(schema: YupSchema, initialValues?: any) {
  const {
    meta,
    values,
    errors,
    setErrors,
    setFieldError,
    setValues,
    setTouched,
    setFieldTouched,
    setFieldValue,
    resetForm,
    validate,
    validateField,
    resetField,
  } = useForm({
    validationSchema: schema,
    initialValues,
    validateOnMount: false,
  });

  const accepted = ref(false);

  const toggleAccepted = () => {
    accepted.value = !accepted.value;
  };

  return {
    meta,
    values,
    errors,
    accepted,
    setErrors,
    setFieldError,
    resetForm,
    setValues,
    setTouched,
    setFieldTouched,
    setFieldValue,
    validate,
    validateField,
    resetField,
    toggleAccepted,
  };
}

export default useValidation;
