<script lang="ts">
import api from '@/api';
import { useAppStore } from '@/stores/appStore';

import { useUserStore } from '@/stores/userStore';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheEmailVerifyAlert',
  data() {
    return {
      emailVerified: true,
      disableButton: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userLogged']),
    ...mapState(useAppStore, ['darkMode']),
  },
  watch: {
    async userLogged(value, oldValue) {
      if (value?.id !== oldValue?.slug) {
        await this.checkEmailVerification();
      }
    },
  },
  beforeMount() {
    this.checkEmailVerification();
  },
  methods: {
    async checkEmailVerification() {
      const user = await this.userLogged;

      if (!!user && user.emailVerifiedAt === null) {
        this.emailVerified = false;
      }
    },
    async verifyEmail() {
      try {
        this.disableButton = !this.disableButton;

        await api.user.general.sendEmailVerificationNotification();

        this.disableButton = !this.disableButton;

        this.emailVerified = true;

        this.$toast.success(this.$t('general.verifyEmail.confirmEmailSent'));
      } catch (e: any) {
        console.error(e);

        this.$toast.error(this.$t('general.shared.error'));
      }
    },
  },
});
</script>

<template>
  <div v-if="userLogged" id="verify-alert" class="position-fixed translate-middle-x" data-cy="email-verify-alert">
    <Transition appear name="slide-up">
      <div
        v-if="!emailVerified"
        class="alert alert-primary d-flex flex-column flex-md-row align-items-center px-32 py-12"
        role="alert"
      >
        <span class="verify-title mb-md-0 me-md-32 mb-16">{{ $t('general.verifyEmail.title') }}</span>

        <VButton
          :disabled="disableButton"
          class="text-nowrap"
          size="sm"
          :variant="darkMode ? 'primary-dark btn-primary' : 'primary'"
          @click="verifyEmail"
        >
          <template v-if="disableButton">
            {{ $t('general.button.loading') }}
          </template>
          <template v-else>
            {{ $t('general.verifyEmail.resend') }}
          </template>
        </VButton>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
#verify-alert {
  left: 50%;
  bottom: 1rem;
  z-index: 1030;

  @media (width <= 992px) {
    width: 70%;
  }

  @media (width <= 768px) {
    width: calc(100% - 2rem);
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition-delay: 2s;
}

[data-bs-theme='dark'] {
  #verify-alert .alert {
    background-color: var(--md-primary) !important;

    .verify-title {
      color: var(--md-primary-light) !important;
    }
  }
}
</style>
