<script lang="ts" setup>
import type { MIconTypes } from '@/components/vendor/basic/icon/MIcon.vue';

import useOrganizationStore from '@/stores/organizationStore';

import { computed } from 'vue';

defineOptions({
  name: 'TheMenuMain',
});

const organizationStore = useOrganizationStore();

// TODO Change me when organizations have been implemented with multiple users
const userSitesNumber = computed(() => organizationStore.sitesLimit?.existing?.value);
const siteItemsValue = computed(() => organizationStore.siteItems?.total?.value);

const menuItems = computed(() => [
  {
    name: 'me.sites.index',
    ariaLabel: 'Home',
    icon: 'home' as MIconTypes,
    label: 'menu.home',
    extraContent: null,
    dataCy: 'link-home',
  },
  {
    name: 'sites',
    ariaLabel: 'My Sites',
    icon: 'file-structure' as MIconTypes,
    label: 'menu.mySites',
    renderExtraContent: () => {
      if (userSitesNumber.value === null || userSitesNumber.value === undefined) {
        return h('span', { class: 'placeholder-glow' }, [
          h('span', { class: 'placeholder', style: 'width: 10px; height: 16px' }),
        ]);
      } else if (userSitesNumber.value > 0) {
        return h('span', { class: 'ms-2' }, ` (${userSitesNumber.value})`);
      } else {
        return null;
      }
    },
    dataCy: 'link-my-sites',
  },
  {
    name: 'manager.update.index',
    ariaLabel: 'WP Management',
    icon: 'exchange-transfer' as MIconTypes,
    label: 'menu.wpManagement',
    renderExtraContent: () => {
      if (siteItemsValue.value) {
        return h(
          'span',
          { class: 'badge bg-primary rounded-xs text-light ms-auto lh-xl px-8 py-4' },
          siteItemsValue.value,
        );
      } else {
        return null;
      }
    },
    dataCy: 'link-manager-update',
  },
]);
</script>

<template>
  <nav aria-labelledby="main-navigation-label" class="sidebar-nav position-relative">
    <h2 id="main-navigation-label" class="visually-hidden">
      {{ $t('menu.mainNavigation') }}
    </h2>

    <ul id="main-navigation" class="nav" role="menubar">
      <li v-for="item in menuItems" :key="item.name" class="nav-item" role="none">
        <router-link
          :aria-label="item.ariaLabel"
          :data-cy="item.dataCy"
          :to="{ name: item.name }"
          class="nav-link px-16 py-10"
          role="menuitem"
        >
          <m-icon :icon="item.icon" />
          <span>{{ $t(item.label) }}</span>

          <template v-if="!!item.renderExtraContent">
            <component :is="item.renderExtraContent()" />
          </template>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
#main-navigation .nav-item {
  &:not(&:last-child) {
    margin-bottom: 0.5rem;
  }
}
</style>
