import type { ISiteBackupStoreState, SiteBackupExtendedType } from '@/stores/site/backup/index';
import type { SiteServiceBackup } from '@/types/models/site/service/backup/SiteServiceBackup';
import useSiteStore from '@/stores/site/siteStore';

import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';

import { SiteServiceStatusEnumConst } from '@/types/enums/SiteServiceStatusEnum';
import { defineStore } from 'pinia';
import { computed } from 'vue';

const useBackupStore = defineStore('siteBackup', (): ISiteBackupStoreState => {
  const currentBackup: Ref<SiteBackupExtendedType | null> = ref(null);
  const backups: Ref<SiteBackupExtendedType[] | null> = ref(null);

  const lastBackups: Ref<SiteBackupExtendedType[] | null> = ref(null);
  const loadedBackups = ref(false);

  const backupsService: Ref<SiteServiceBackup | null> = ref(null);
  const loadedBackupsService = ref(false);

  const reset = () => {
    currentBackup.value = null;
    backups.value = null;
    lastBackups.value = null;
    loadedBackups.value = false;
    backupsService.value = null;
    loadedBackupsService.value = false;
  }

  const siteStore = useSiteStore();

  const siteServicesBackupService = computed(
    () => (siteStore.siteServices?.find(service => service.type === ServiceTypeEnumConst.BACKUP)),
  );

  const siteHasBackupsServiceEnabled = computed(
    () => siteServicesBackupService.value?.isActive,
  );

  const backupsServiceId = computed(() => (siteServicesBackupService.value?.id));

  const backupsIsEnabled = computed(
    () => (
      backupsService.value?.status === SiteServiceStatusEnumConst.ACTIVE
    ),
  );

  const backupsIsConfigured = computed(() => (siteServicesBackupService.value?.isConfigured));

  return {
    currentBackup,
    backups,

    lastBackups,
    loadedBackups,

    backupsService,
    loadedBackupsService,

    siteHasBackupsServiceEnabled,

    backupsServiceId,
    backupsIsEnabled,
    backupsIsConfigured,

    reset,
  };
});

export { useBackupStore };
export default useBackupStore;
