import { Concept } from '@/types/models/Concept';

export type BillingCountryEnumKeys = 'af' | 'ax' | 'al' | 'dz' | 'ad' | 'ao' | 'ai' | 'aq' | 'ag' | 'ar' | 'am' | 'aw' | 'ac' | 'au' | 'at' | 'az' | 'bs' | 'bh' | 'bd' | 'bb' | 'by' | 'be' | 'bz' | 'bj' | 'bm' | 'bt' | 'bo' | 'ba' | 'bw' | 'bv' | 'br' | 'io' | 'vg' | 'bn' | 'bg' | 'bf' | 'bi' | 'kh' | 'cm' | 'ca' | 'cv' | 'bq' | 'ky' | 'cf' | 'td' | 'cl' | 'cn' | 'co' | 'km' | 'cg' | 'cd' | 'ck' | 'cr' | 'ci' | 'hr' | 'cw' | 'cy' | 'cz' | 'dk' | 'dj' | 'dm' | 'do' | 'ec' | 'eg' | 'sv' | 'gq' | 'er' | 'ee' | 'sz' | 'et' | 'fk' | 'fo' | 'fj' | 'fi' | 'fr' | 'gf' | 'pf' | 'tf' | 'ga' | 'gm' | 'ge' | 'de' | 'gh' | 'gi' | 'gr' | 'gl' | 'gd' | 'gp' | 'gu' | 'gt' | 'gg' | 'gn' | 'gw' | 'gy' | 'ht' | 'hn' | 'hk' | 'hu' | 'is' | 'in' | 'id' | 'iq' | 'ie' | 'im' | 'il' | 'it' | 'jm' | 'jp' | 'je' | 'jo' | 'kz' | 'ke' | 'ki' | 'xk' | 'kw' | 'kg' | 'la' | 'lv' | 'lb' | 'ls' | 'lr' | 'ly' | 'li' | 'lt' | 'lu' | 'mo' | 'mg' | 'mw' | 'my' | 'mv' | 'ml' | 'mt' | 'mq' | 'mr' | 'mu' | 'yt' | 'mx' | 'md' | 'mc' | 'mn' | 'me' | 'ms' | 'ma' | 'mz' | 'mm' | 'na' | 'nr' | 'np' | 'nl' | 'nc' | 'nz' | 'ni' | 'ne' | 'ng' | 'nu' | 'mk' | 'no' | 'om' | 'pk' | 'ps' | 'pa' | 'pg' | 'py' | 'pe' | 'ph' | 'pn' | 'pl' | 'pt' | 'pr' | 'qa' | 're' | 'ro' | 'ru' | 'rw' | 'ws' | 'sm' | 'st' | 'sa' | 'sn' | 'rs' | 'sc' | 'sl' | 'sg' | 'sx' | 'sk' | 'si' | 'sb' | 'so' | 'za' | 'gs' | 'kr' | 'ss' | 'es' | 'es_cn' | 'es_ce' | 'es_ml' | 'lk' | 'bl' | 'sh' | 'kn' | 'lc' | 'mf' | 'pm' | 'vc' | 'sr' | 'sj' | 'se' | 'ch' | 'tw' | 'tj' | 'tz' | 'th' | 'tl' | 'tg' | 'tk' | 'to' | 'tt' | 'ta' | 'tn' | 'tr' | 'tm' | 'tc' | 'tv' | 'ug' | 'ua' | 'ae' | 'gb' | 'us' | 'uy' | 'uz' | 'vu' | 'va' | 've' | 'vn' | 'wf' | 'eh' | 'ye' | 'zm' | 'zw';

export const BillingCountryEnumConst = {
  AFGHANISTAN: 'af',
  ALAND_ISLANDS: 'ax',
  ALBANIA: 'al',
  ALGERIA: 'dz',
  ANDORRA: 'ad',
  ANGOLA: 'ao',
  ANGUILLA: 'ai',
  ANTARCTICA: 'aq',
  ANTIGUA_AND_BARBUDA: 'ag',
  ARGENTINA: 'ar',
  ARMENIA: 'am',
  ARUBA: 'aw',
  ASCENSION_ISLAND: 'ac',
  AUSTRALIA: 'au',
  AUSTRIA: 'at',
  AZERBAIJAN: 'az',
  BAHAMAS: 'bs',
  BAHRAIN: 'bh',
  BANGLADESH: 'bd',
  BARBADOS: 'bb',
  BELARUS: 'by',
  BELGIUM: 'be',
  BELICE: 'bz',
  BENIN: 'bj',
  BERMUDA: 'bm',
  BHUTAN: 'bt',
  BOLIVIA: 'bo',
  BOSNIA_AND_HERZEGOVINA: 'ba',
  BOTSWANA: 'bw',
  BOUVET_ISLAND: 'bv',
  BRAZIL: 'br',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'io',
  BRITISH_VIRGIN_ISLANDS: 'vg',
  BRUNEI: 'bn',
  BULGARIA: 'bg',
  BURKINA_FASO: 'bf',
  BURUNDI: 'bi',
  CAMBODIA: 'kh',
  CAMEROON: 'cm',
  CANADA: 'ca',
  CAPE_VERDE: 'cv',
  CARIBBEAN_NETHERLANDS: 'bq',
  CAYMAN_ISLANDS: 'ky',
  CENTRAL_AFRICAN_REPUBLIC: 'cf',
  CHAD: 'td',
  CHILE: 'cl',
  CHINA: 'cn',
  COLOMBIA: 'co',
  COMOROS: 'km',
  CONGO_BRAZZAVILLE: 'cg',
  CONGO_KINSHASA: 'cd',
  COOK_ISLANDS: 'ck',
  COSTA_RICA: 'cr',
  COTE_D_IVOIRE: 'ci',
  CROATIA: 'hr',
  CURACAO: 'cw',
  CYPRUS: 'cy',
  CZECH_REPUBLIC: 'cz',
  DENMARK: 'dk',
  DJIBOUTI: 'dj',
  DOMINICA: 'dm',
  REPUBLICA_DOMINICANA: 'do',
  ECUADOR: 'ec',
  EGYPT: 'eg',
  EL_SALVADOR: 'sv',
  EQUATORIAL_GUINEA: 'gq',
  ERITREA: 'er',
  ESTONIA: 'ee',
  ESWATINI: 'sz',
  ETHIOPIA: 'et',
  FALKLAND_ISLANDS: 'fk',
  FAROE_ISLANDS: 'fo',
  FIJI: 'fj',
  FINLAND: 'fi',
  FRANCE: 'fr',
  FRENCH_GUIANA: 'gf',
  FRENCH_POLYNESIA: 'pf',
  FRENCH_SOUTHERN_TERRITORIES: 'tf',
  GABON: 'ga',
  GAMBIA: 'gm',
  GEORGIA: 'ge',
  GERMANY: 'de',
  GHANA: 'gh',
  GIBRALTAR: 'gi',
  GREECE: 'gr',
  GREENLAND: 'gl',
  GRENADA: 'gd',
  GUADELOUPE: 'gp',
  GUAM: 'gu',
  GUATEMALA: 'gt',
  GUERNSEY: 'gg',
  GUINEA: 'gn',
  GUINEA_BISSAU: 'gw',
  GUYANA: 'gy',
  HAITI: 'ht',
  HONDURAS: 'hn',
  HONG_KONG_SAR_CHINA: 'hk',
  HUNGARY: 'hu',
  ICELAND: 'is',
  INDIA: 'in',
  INDONESIA: 'id',
  IRAQ: 'iq',
  IRELAND: 'ie',
  ISLE_OF_MAN: 'im',
  ISRAEL: 'il',
  ITALY: 'it',
  JAMAICA: 'jm',
  JAPAN: 'jp',
  JERSEY: 'je',
  JORDAN: 'jo',
  KAZAKHSTAN: 'kz',
  KENYA: 'ke',
  KIRIBATI: 'ki',
  KOSOVO: 'xk',
  KUWAIT: 'kw',
  KYRGYZSTAN: 'kg',
  LAOS: 'la',
  LATVIA: 'lv',
  LEBANON: 'lb',
  LESOTHO: 'ls',
  LIBERIA: 'lr',
  LIBYA: 'ly',
  LIECHTENSTEIN: 'li',
  LITHUANIA: 'lt',
  LUXEMBOURG: 'lu',
  MACAO_SAR_CHINA: 'mo',
  MADAGASCAR: 'mg',
  MALAWI: 'mw',
  MALAYSIA: 'my',
  MALDIVES: 'mv',
  MALI: 'ml',
  MALTA: 'mt',
  MARTINIQUE: 'mq',
  MAURITANIA: 'mr',
  MAURITIUS: 'mu',
  MAYOTTE: 'yt',
  MEXICO: 'mx',
  MOLDOVA: 'md',
  MONACO: 'mc',
  MONGOLIA: 'mn',
  MONTENEGRO: 'me',
  MONTSERRAT: 'ms',
  MOROCCO: 'ma',
  MOZAMBIQUE: 'mz',
  MYANMAR_BURMA: 'mm',
  NAMIBIA: 'na',
  NAURU: 'nr',
  NEPAL: 'np',
  NETHERLANDS: 'nl',
  NEW_CALEDONIA: 'nc',
  NEW_ZEALAND: 'nz',
  NICARAGUA: 'ni',
  NIGER: 'ne',
  NIGERIA: 'ng',
  NIUE: 'nu',
  NORTH_MACEDONIA: 'mk',
  NORWAY: 'no',
  OMAN: 'om',
  PAKISTAN: 'pk',
  PALESTINIAN_TERRITORIES: 'ps',
  PANAMA: 'pa',
  PAPUA_NEW_GUINEA: 'pg',
  PARAGUAY: 'py',
  PERU: 'pe',
  PHILIPPINES: 'ph',
  PITCAIRN_ISLANDS: 'pn',
  POLAND: 'pl',
  PORTUGAL: 'pt',
  PUERTO_RICO: 'pr',
  QATAR: 'qa',
  REUNION: 're',
  ROMANIA: 'ro',
  RUSSIA: 'ru',
  RWANDA: 'rw',
  SAMOA: 'ws',
  SAN_MARINO: 'sm',
  SAO_TOME_AND_PRINCIPE: 'st',
  SAUDI_ARABIA: 'sa',
  SENEGAL: 'sn',
  SERBIA: 'rs',
  SEYCHELLES: 'sc',
  SIERRA_LEONE: 'sl',
  SINGAPORE: 'sg',
  SINT_MAARTEN: 'sx',
  SLOVAKIA: 'sk',
  SLOVENIA: 'si',
  SOLOMON_ISLANDS: 'sb',
  SOMALIA: 'so',
  SOUTH_AFRICA: 'za',
  SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS: 'gs',
  SOUTH_KOREA: 'kr',
  SOUTH_SUDAN: 'ss',
  SPAIN: 'es',
  SPAIN_CANARY_ISLANDS: 'es_cn',
  SPAIN_CEUTA: 'es_ce',
  SPAIN_MELILLA: 'es_ml',
  SRI_LANKA: 'lk',
  ST_BARTHELEMY: 'bl',
  ST_HELENA: 'sh',
  ST_KITTS_AND_NEVIS: 'kn',
  ST_LUCIA: 'lc',
  ST_MARTIN: 'mf',
  ST_PIERRE_AND_MIQUELON: 'pm',
  ST_VINCENT_AND_GRENADINES: 'vc',
  SURINAME: 'sr',
  SVALBARD_AND_JAN_MAYEN: 'sj',
  SWEDEN: 'se',
  SWITZERLAND: 'ch',
  TAIWAN: 'tw',
  TAJIKISTAN: 'tj',
  TANZANIA: 'tz',
  THAILAND: 'th',
  TIMOR_LESTE: 'tl',
  TOGO: 'tg',
  TOKELAU: 'tk',
  TONGA: 'to',
  TRINIDAD_AND_TOBAGO: 'tt',
  TRISTAN_DA_CUNHA: 'ta',
  TUNISIA: 'tn',
  TURKEY: 'tr',
  TURKMENISTAN: 'tm',
  TURKS_AND_CAICOS_ISLANDS: 'tc',
  TUVALU: 'tv',
  UGANDA: 'ug',
  UKRAINE: 'ua',
  UNITED_ARAB_EMIRATES: 'ae',
  UNITED_KINGDOM: 'gb',
  UNITED_STATES: 'us',
  URUGUAY: 'uy',
  UZBEKISTAN: 'uz',
  VANUATU: 'vu',
  VATICAN_CITY: 'va',
  VENEZUELA: 've',
  VIETNAM: 'vn',
  WALLIS_AND_FUTUNA: 'wf',
  WESTERN_SAHARA: 'eh',
  YEMEN: 'ye',
  ZAMBIA: 'zm',
  ZIMBABWE: 'zw',
} as const;

export const BillingCountryEnum: Record<BillingCountryEnumKeys, Concept> = {
  'af': {
    key: 'af',
    value: {"stripe_tax":false},
    name: 'Afghanistan (AF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ax': {
    key: 'ax',
    value: {"stripe_tax":false},
    name: 'Åland Islands (AX)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'al': {
    key: 'al',
    value: {"stripe_tax":false},
    name: 'Albania (AL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'dz': {
    key: 'dz',
    value: {"stripe_tax":false},
    name: 'Algeria (DZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ad': {
    key: 'ad',
    value: {"stripe_tax":false},
    name: 'Andorra (AD)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ao': {
    key: 'ao',
    value: {"stripe_tax":false},
    name: 'Angola (AO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ai': {
    key: 'ai',
    value: {"stripe_tax":false},
    name: 'Anguilla (AI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'aq': {
    key: 'aq',
    value: {"stripe_tax":false},
    name: 'Antarctica (AQ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ag': {
    key: 'ag',
    value: {"stripe_tax":false},
    name: 'Antigua & Barbuda (AG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ar': {
    key: 'ar',
    value: {"stripe_tax":false},
    name: 'Argentina (AR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'am': {
    key: 'am',
    value: {"stripe_tax":false},
    name: 'Armenia (AM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'aw': {
    key: 'aw',
    value: {"stripe_tax":false},
    name: 'Aruba (AW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ac': {
    key: 'ac',
    value: {"stripe_tax":false},
    name: 'Ascension Island (AC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'au': {
    key: 'au',
    value: {"stripe_tax":"sa_vat","tax_required":false},
    name: 'Australia (AU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'at': {
    key: 'at',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Austria (AT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'az': {
    key: 'az',
    value: {"stripe_tax":false},
    name: 'Azerbaijan (AZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bs': {
    key: 'bs',
    value: {"stripe_tax":false},
    name: 'Bahamas (BS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bh': {
    key: 'bh',
    value: {"stripe_tax":false},
    name: 'Bahrain (BH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bd': {
    key: 'bd',
    value: {"stripe_tax":false},
    name: 'Bangladesh (BD)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bb': {
    key: 'bb',
    value: {"stripe_tax":false},
    name: 'Barbados (BB)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'by': {
    key: 'by',
    value: {"stripe_tax":false},
    name: 'Belarus (BY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'be': {
    key: 'be',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Belgium (BE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bz': {
    key: 'bz',
    value: {"stripe_tax":false},
    name: 'Belice (BZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bj': {
    key: 'bj',
    value: {"stripe_tax":false},
    name: 'Benin (BJ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bm': {
    key: 'bm',
    value: {"stripe_tax":false},
    name: 'Bermuda (BM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bt': {
    key: 'bt',
    value: {"stripe_tax":false},
    name: 'Bhutan (BT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bo': {
    key: 'bo',
    value: {"stripe_tax":false},
    name: 'Bolivia (BO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ba': {
    key: 'ba',
    value: {"stripe_tax":false},
    name: 'Bosnia & Herzegovina (BA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bw': {
    key: 'bw',
    value: {"stripe_tax":false},
    name: 'Botswana (BW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bv': {
    key: 'bv',
    value: {"stripe_tax":false},
    name: 'Bouvet Island (BV)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'br': {
    key: 'br',
    value: {"stripe_tax":"br_cnpj","tax_required":false},
    name: 'Brazil (BR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'io': {
    key: 'io',
    value: {"stripe_tax":false},
    name: 'British Indian Ocean Territory (IO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'vg': {
    key: 'vg',
    value: {"stripe_tax":false},
    name: 'British Virgin Islands (VG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bn': {
    key: 'bn',
    value: {"stripe_tax":false},
    name: 'Brunei (BN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bg': {
    key: 'bg',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Bulgaria (BG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bf': {
    key: 'bf',
    value: {"stripe_tax":false},
    name: 'Burkina Faso (BF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bi': {
    key: 'bi',
    value: {"stripe_tax":false},
    name: 'Burundi (BI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'kh': {
    key: 'kh',
    value: {"stripe_tax":false},
    name: 'Cambodia (KH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cm': {
    key: 'cm',
    value: {"stripe_tax":false},
    name: 'Cameroon (CM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ca': {
    key: 'ca',
    value: {"stripe_tax":"ca_bn","tax_required":false},
    name: 'Canada (CA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cv': {
    key: 'cv',
    value: {"stripe_tax":false},
    name: 'Cape Verde (CV)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bq': {
    key: 'bq',
    value: {"stripe_tax":false},
    name: 'Caribbean Netherlands (BQ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ky': {
    key: 'ky',
    value: {"stripe_tax":false},
    name: 'Cayman Islands (KY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cf': {
    key: 'cf',
    value: {"stripe_tax":false},
    name: 'Central African Republic (CF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'td': {
    key: 'td',
    value: {"stripe_tax":false},
    name: 'Chad (TD)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cl': {
    key: 'cl',
    value: {"stripe_tax":"cl_tin","tax_required":false},
    name: 'Chile (CL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cn': {
    key: 'cn',
    value: {"stripe_tax":false},
    name: 'China (CN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'co': {
    key: 'co',
    value: {"stripe_tax":false},
    name: 'Colombia (CO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'km': {
    key: 'km',
    value: {"stripe_tax":false},
    name: 'Comoros (KM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cg': {
    key: 'cg',
    value: {"stripe_tax":false},
    name: 'Congo - Brazzaville (CG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cd': {
    key: 'cd',
    value: {"stripe_tax":false},
    name: 'Congo - Kinshasa (CD)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ck': {
    key: 'ck',
    value: {"stripe_tax":false},
    name: 'Cook Islands (CK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cr': {
    key: 'cr',
    value: {"stripe_tax":false},
    name: 'Costa Rica (CR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ci': {
    key: 'ci',
    value: {"stripe_tax":false},
    name: 'Côte d’Ivoire (CI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'hr': {
    key: 'hr',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Croatia (HR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cw': {
    key: 'cw',
    value: {"stripe_tax":false},
    name: 'Curaçao (CW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cy': {
    key: 'cy',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Cyprus (CY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'cz': {
    key: 'cz',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Czech Republic (CZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'dk': {
    key: 'dk',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Denmark (DK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'dj': {
    key: 'dj',
    value: {"stripe_tax":false},
    name: 'Djibouti (DJ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'dm': {
    key: 'dm',
    value: {"stripe_tax":false},
    name: 'Dominica (DM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'do': {
    key: 'do',
    value: {"stripe_tax":false},
    name: 'República Dominicana (DO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ec': {
    key: 'ec',
    value: {"stripe_tax":false},
    name: 'Ecuador (EC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'eg': {
    key: 'eg',
    value: {"stripe_tax":false},
    name: 'Egypt (EG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sv': {
    key: 'sv',
    value: {"stripe_tax":false},
    name: 'El Salvador (SV)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gq': {
    key: 'gq',
    value: {"stripe_tax":false},
    name: 'Equatorial Guinea (GQ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'er': {
    key: 'er',
    value: {"stripe_tax":false},
    name: 'Eritrea (ER)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ee': {
    key: 'ee',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Estonia (EE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sz': {
    key: 'sz',
    value: {"stripe_tax":false},
    name: 'Eswatini (SZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'et': {
    key: 'et',
    value: {"stripe_tax":false},
    name: 'Ethiopia (ET)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'fk': {
    key: 'fk',
    value: {"stripe_tax":false},
    name: 'Falkland Islands (FK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'fo': {
    key: 'fo',
    value: {"stripe_tax":false},
    name: 'Faroe Islands (FO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'fj': {
    key: 'fj',
    value: {"stripe_tax":false},
    name: 'Fiji (FJ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'fi': {
    key: 'fi',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Finland (FI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'fr': {
    key: 'fr',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'France (FR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gf': {
    key: 'gf',
    value: {"stripe_tax":false},
    name: 'French Guiana (GF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pf': {
    key: 'pf',
    value: {"stripe_tax":false},
    name: 'French Polynesia (PF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tf': {
    key: 'tf',
    value: {"stripe_tax":false},
    name: 'French Southern Territories (TF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ga': {
    key: 'ga',
    value: {"stripe_tax":false},
    name: 'Gabon (GA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gm': {
    key: 'gm',
    value: {"stripe_tax":false},
    name: 'Gambia (GM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ge': {
    key: 'ge',
    value: {"stripe_tax":"ge_vat","tax_required":false},
    name: 'Georgia (GE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'de': {
    key: 'de',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Germany (DE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gh': {
    key: 'gh',
    value: {"stripe_tax":false},
    name: 'Ghana (GH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gi': {
    key: 'gi',
    value: {"stripe_tax":false},
    name: 'Gibraltar (GI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gr': {
    key: 'gr',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Greece (GR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gl': {
    key: 'gl',
    value: {"stripe_tax":false},
    name: 'Greenland (GL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gd': {
    key: 'gd',
    value: {"stripe_tax":false},
    name: 'Grenada (GD)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gp': {
    key: 'gp',
    value: {"stripe_tax":false},
    name: 'Guadeloupe (GP)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gu': {
    key: 'gu',
    value: {"stripe_tax":false},
    name: 'Guam (GU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gt': {
    key: 'gt',
    value: {"stripe_tax":false},
    name: 'Guatemala (GT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gg': {
    key: 'gg',
    value: {"stripe_tax":false},
    name: 'Guernsey (GG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gn': {
    key: 'gn',
    value: {"stripe_tax":false},
    name: 'Guinea (GN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gw': {
    key: 'gw',
    value: {"stripe_tax":false},
    name: 'Guinea-Bissau (GW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gy': {
    key: 'gy',
    value: {"stripe_tax":false},
    name: 'Guyana (GY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ht': {
    key: 'ht',
    value: {"stripe_tax":false},
    name: 'Haiti (HT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'hn': {
    key: 'hn',
    value: {"stripe_tax":false},
    name: 'Honduras (HN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'hk': {
    key: 'hk',
    value: {"stripe_tax":"hk_br","tax_required":false},
    name: 'Hong Kong SAR China (HK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'hu': {
    key: 'hu',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Hungary (HU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'is': {
    key: 'is',
    value: {"stripe_tax":false},
    name: 'Iceland (IS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'in': {
    key: 'in',
    value: {"stripe_tax":"in_gst","tax_required":false},
    name: 'India (IN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'id': {
    key: 'id',
    value: {"stripe_tax":false},
    name: 'Indonesia (ID)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'iq': {
    key: 'iq',
    value: {"stripe_tax":false},
    name: 'Iraq (IQ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ie': {
    key: 'ie',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Ireland (IE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'im': {
    key: 'im',
    value: {"stripe_tax":false},
    name: 'Isle of Man (IM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'il': {
    key: 'il',
    value: {"stripe_tax":false},
    name: 'Israel (IL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'it': {
    key: 'it',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Italy (IT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'jm': {
    key: 'jm',
    value: {"stripe_tax":false},
    name: 'Jamaica (JM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'jp': {
    key: 'jp',
    value: {"stripe_tax":false},
    name: 'Japan (JP)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'je': {
    key: 'je',
    value: {"stripe_tax":false},
    name: 'Jersey (JE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'jo': {
    key: 'jo',
    value: {"stripe_tax":false},
    name: 'Jordan (JO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'kz': {
    key: 'kz',
    value: {"stripe_tax":false},
    name: 'Kazakhstan (KZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ke': {
    key: 'ke',
    value: {"stripe_tax":false},
    name: 'Kenya (KE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ki': {
    key: 'ki',
    value: {"stripe_tax":false},
    name: 'Kiribati (KI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'xk': {
    key: 'xk',
    value: {"stripe_tax":false},
    name: 'Kosovo (XK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'kw': {
    key: 'kw',
    value: {"stripe_tax":false},
    name: 'Kuwait (KW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'kg': {
    key: 'kg',
    value: {"stripe_tax":false},
    name: 'Kyrgyzstan (KG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'la': {
    key: 'la',
    value: {"stripe_tax":false},
    name: 'Laos (LA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'lv': {
    key: 'lv',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Latvia (LV)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'lb': {
    key: 'lb',
    value: {"stripe_tax":false},
    name: 'Lebanon (LB)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ls': {
    key: 'ls',
    value: {"stripe_tax":false},
    name: 'Lesotho (LS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'lr': {
    key: 'lr',
    value: {"stripe_tax":false},
    name: 'Liberia (LR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ly': {
    key: 'ly',
    value: {"stripe_tax":false},
    name: 'Libya (LY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'li': {
    key: 'li',
    value: {"stripe_tax":false},
    name: 'Liechtenstein (LI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'lt': {
    key: 'lt',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Lithuania (LT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'lu': {
    key: 'lu',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Luxembourg (LU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mo': {
    key: 'mo',
    value: {"stripe_tax":false},
    name: 'Macao SAR China (MO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mg': {
    key: 'mg',
    value: {"stripe_tax":false},
    name: 'Madagascar (MG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mw': {
    key: 'mw',
    value: {"stripe_tax":false},
    name: 'Malawi (MW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'my': {
    key: 'my',
    value: {"stripe_tax":false},
    name: 'Malaysia (MY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mv': {
    key: 'mv',
    value: {"stripe_tax":false},
    name: 'Maldives (MV)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ml': {
    key: 'ml',
    value: {"stripe_tax":false},
    name: 'Mali (ML)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mt': {
    key: 'mt',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Malta (MT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mq': {
    key: 'mq',
    value: {"stripe_tax":false},
    name: 'Martinique (MQ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mr': {
    key: 'mr',
    value: {"stripe_tax":false},
    name: 'Mauritania (MR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mu': {
    key: 'mu',
    value: {"stripe_tax":false},
    name: 'Mauritius (MU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'yt': {
    key: 'yt',
    value: {"stripe_tax":false},
    name: 'Mayotte (YT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mx': {
    key: 'mx',
    value: {"stripe_tax":false},
    name: 'México (MX)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'md': {
    key: 'md',
    value: {"stripe_tax":false},
    name: 'Moldova (MD)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mc': {
    key: 'mc',
    value: {"stripe_tax":false},
    name: 'Monaco (MC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mn': {
    key: 'mn',
    value: {"stripe_tax":false},
    name: 'Mongolia (MN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'me': {
    key: 'me',
    value: {"stripe_tax":false},
    name: 'Montenegro (ME)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ms': {
    key: 'ms',
    value: {"stripe_tax":false},
    name: 'Montserrat (MS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ma': {
    key: 'ma',
    value: {"stripe_tax":false},
    name: 'Morocco (MA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mz': {
    key: 'mz',
    value: {"stripe_tax":false},
    name: 'Mozambique (MZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mm': {
    key: 'mm',
    value: {"stripe_tax":false},
    name: 'Myanmar (Burma) (MM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'na': {
    key: 'na',
    value: {"stripe_tax":false},
    name: 'Namibia (NA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'nr': {
    key: 'nr',
    value: {"stripe_tax":false},
    name: 'Nauru (NR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'np': {
    key: 'np',
    value: {"stripe_tax":false},
    name: 'Nepal (NP)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'nl': {
    key: 'nl',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Netherlands (NL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'nc': {
    key: 'nc',
    value: {"stripe_tax":false},
    name: 'New Caledonia (NC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'nz': {
    key: 'nz',
    value: {"stripe_tax":false},
    name: 'New Zealand (NZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ni': {
    key: 'ni',
    value: {"stripe_tax":false},
    name: 'Nicaragua (NI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ne': {
    key: 'ne',
    value: {"stripe_tax":false},
    name: 'Niger (NE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ng': {
    key: 'ng',
    value: {"stripe_tax":false},
    name: 'Nigeria (NG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'nu': {
    key: 'nu',
    value: {"stripe_tax":false},
    name: 'Niue (NU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mk': {
    key: 'mk',
    value: {"stripe_tax":false},
    name: 'North Macedonia (MK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'no': {
    key: 'no',
    value: {"stripe_tax":false},
    name: 'Norway (NO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'om': {
    key: 'om',
    value: {"stripe_tax":false},
    name: 'Oman (OM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pk': {
    key: 'pk',
    value: {"stripe_tax":false},
    name: 'Pakistan (PK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ps': {
    key: 'ps',
    value: {"stripe_tax":false},
    name: 'Palestinian Territories (PS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pa': {
    key: 'pa',
    value: {"stripe_tax":false},
    name: 'Panamá (PA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pg': {
    key: 'pg',
    value: {"stripe_tax":false},
    name: 'Papua New Guinea (PG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'py': {
    key: 'py',
    value: {"stripe_tax":false},
    name: 'Paraguay (PY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pe': {
    key: 'pe',
    value: {"stripe_tax":false},
    name: 'Perú (PE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ph': {
    key: 'ph',
    value: {"stripe_tax":false},
    name: 'Philippines (PH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pn': {
    key: 'pn',
    value: {"stripe_tax":false},
    name: 'Pitcairn Islands (PN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pl': {
    key: 'pl',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Poland (PL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pt': {
    key: 'pt',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Portugal (PT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pr': {
    key: 'pr',
    value: {"stripe_tax":false},
    name: 'Puerto Rico (PR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'qa': {
    key: 'qa',
    value: {"stripe_tax":false},
    name: 'Qatar (QA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  're': {
    key: 're',
    value: {"stripe_tax":false},
    name: 'Réunion (RE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ro': {
    key: 'ro',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Romania (RO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ru': {
    key: 'ru',
    value: {"stripe_tax":false},
    name: 'Russia (RU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'rw': {
    key: 'rw',
    value: {"stripe_tax":false},
    name: 'Rwanda (RW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ws': {
    key: 'ws',
    value: {"stripe_tax":false},
    name: 'Samoa (WS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sm': {
    key: 'sm',
    value: {"stripe_tax":false},
    name: 'San Marino (SM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'st': {
    key: 'st',
    value: {"stripe_tax":false},
    name: 'São Tomé & Príncipe (ST)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sa': {
    key: 'sa',
    value: {"stripe_tax":"sa_vat","tax_required":false},
    name: 'Saudi Arabia (SA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sn': {
    key: 'sn',
    value: {"stripe_tax":false},
    name: 'Senegal (SN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'rs': {
    key: 'rs',
    value: {"stripe_tax":false},
    name: 'Serbia (RS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sc': {
    key: 'sc',
    value: {"stripe_tax":false},
    name: 'Seychelles (SC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sl': {
    key: 'sl',
    value: {"stripe_tax":false},
    name: 'Sierra Leone (SL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sg': {
    key: 'sg',
    value: {"stripe_tax":false},
    name: 'Singapore (SG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sx': {
    key: 'sx',
    value: {"stripe_tax":false},
    name: 'Sint Maarten (SX)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sk': {
    key: 'sk',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Slovakia (SK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'si': {
    key: 'si',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Slovenia (SI)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sb': {
    key: 'sb',
    value: {"stripe_tax":false},
    name: 'Solomon Islands (SB)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'so': {
    key: 'so',
    value: {"stripe_tax":false},
    name: 'Somalia (SO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'za': {
    key: 'za',
    value: {"stripe_tax":false},
    name: 'South Africa (ZA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gs': {
    key: 'gs',
    value: {"stripe_tax":false},
    name: 'South Georgia & South Sandwich Islands (GS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'kr': {
    key: 'kr',
    value: {"stripe_tax":"kr_brn","tax_required":false},
    name: 'South Korea (KR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ss': {
    key: 'ss',
    value: {"stripe_tax":false},
    name: 'South Sudan (SS)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'es': {
    key: 'es',
    value: {"stripe_tax":"es_cif","tax_required":true},
    name: 'España (ES)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'es_cn': {
    key: 'es_cn',
    value: {"stripe_tax":"es_cif","tax_required":true},
    name: 'España - Islas Canarias (ES-CN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'es_ce': {
    key: 'es_ce',
    value: {"stripe_tax":"es_cif","tax_required":true},
    name: 'España - Ceuta (ES-CE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'es_ml': {
    key: 'es_ml',
    value: {"stripe_tax":"es_cif","tax_required":true},
    name: 'España - Melilla (ES-ML)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'lk': {
    key: 'lk',
    value: {"stripe_tax":false},
    name: 'Sri Lanka (LK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'bl': {
    key: 'bl',
    value: {"stripe_tax":false},
    name: 'St. Barthélemy (BL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sh': {
    key: 'sh',
    value: {"stripe_tax":false},
    name: 'St. Helena (SH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'kn': {
    key: 'kn',
    value: {"stripe_tax":false},
    name: 'St. Kitts & Nevis (KN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'lc': {
    key: 'lc',
    value: {"stripe_tax":false},
    name: 'St. Lucia (LC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'mf': {
    key: 'mf',
    value: {"stripe_tax":false},
    name: 'St. Martin (MF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'pm': {
    key: 'pm',
    value: {"stripe_tax":false},
    name: 'St. Pierre & Miquelon (PM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'vc': {
    key: 'vc',
    value: {"stripe_tax":false},
    name: 'St. Vincent & Grenadines (VC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sr': {
    key: 'sr',
    value: {"stripe_tax":false},
    name: 'Suriname (SR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'sj': {
    key: 'sj',
    value: {"stripe_tax":false},
    name: 'Svalbard & Jan Mayen (SJ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'se': {
    key: 'se',
    value: {"stripe_tax":"eu_vat","tax_required":true},
    name: 'Sweden (SE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ch': {
    key: 'ch',
    value: {"stripe_tax":false},
    name: 'Switzerland (CH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tw': {
    key: 'tw',
    value: {"stripe_tax":false},
    name: 'Taiwan (TW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tj': {
    key: 'tj',
    value: {"stripe_tax":false},
    name: 'Tajikistan (TJ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tz': {
    key: 'tz',
    value: {"stripe_tax":false},
    name: 'Tanzania (TZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'th': {
    key: 'th',
    value: {"stripe_tax":false},
    name: 'Thailand (TH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tl': {
    key: 'tl',
    value: {"stripe_tax":false},
    name: 'Timor-Leste (TL)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tg': {
    key: 'tg',
    value: {"stripe_tax":false},
    name: 'Togo (TG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tk': {
    key: 'tk',
    value: {"stripe_tax":false},
    name: 'Tokelau (TK)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'to': {
    key: 'to',
    value: {"stripe_tax":false},
    name: 'Tonga (TO)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tt': {
    key: 'tt',
    value: {"stripe_tax":false},
    name: 'Trinidad & Tobago (TT)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ta': {
    key: 'ta',
    value: {"stripe_tax":false},
    name: 'Tristan da Cunha (TA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tn': {
    key: 'tn',
    value: {"stripe_tax":false},
    name: 'Tunisia (TN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tr': {
    key: 'tr',
    value: {"stripe_tax":false},
    name: 'Turkey (TR)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tm': {
    key: 'tm',
    value: {"stripe_tax":false},
    name: 'Turkmenistan (TM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tc': {
    key: 'tc',
    value: {"stripe_tax":false},
    name: 'Turks & Caicos Islands (TC)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'tv': {
    key: 'tv',
    value: {"stripe_tax":false},
    name: 'Tuvalu (TV)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ug': {
    key: 'ug',
    value: {"stripe_tax":false},
    name: 'Uganda (UG)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ua': {
    key: 'ua',
    value: {"stripe_tax":false},
    name: 'Ukraine (UA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ae': {
    key: 'ae',
    value: {"stripe_tax":"ae_trn","tax_required":false},
    name: 'United Arab Emirates (AE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'gb': {
    key: 'gb',
    value: {"stripe_tax":"gb_vat","tax_required":false},
    name: 'United Kingdom (GB)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'us': {
    key: 'us',
    value: {"stripe_tax":"us_ein","tax_required":false},
    name: 'United States (US)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'uy': {
    key: 'uy',
    value: {"stripe_tax":false},
    name: 'Uruguay (UY)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'uz': {
    key: 'uz',
    value: {"stripe_tax":false},
    name: 'Uzbekistan (UZ)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'vu': {
    key: 'vu',
    value: {"stripe_tax":false},
    name: 'Vanuatu (VU)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'va': {
    key: 'va',
    value: {"stripe_tax":false},
    name: 'Vatican City (VA)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  've': {
    key: 've',
    value: {"stripe_tax":false},
    name: 'Venezuela (VE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'vn': {
    key: 'vn',
    value: {"stripe_tax":false},
    name: 'Vietnam (VN)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'wf': {
    key: 'wf',
    value: {"stripe_tax":false},
    name: 'Wallis & Futuna (WF)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'eh': {
    key: 'eh',
    value: {"stripe_tax":false},
    name: 'Western Sahara (EH)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'ye': {
    key: 'ye',
    value: {"stripe_tax":false},
    name: 'Yemen (YE)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'zm': {
    key: 'zm',
    value: {"stripe_tax":false},
    name: 'Zambia (ZM)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  },
  'zw': {
    key: 'zw',
    value: {"stripe_tax":false},
    name: 'Zimbabwe (ZW)',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.country'
  }
} as const;

export type BillingCountryEnumType = (typeof BillingCountryEnumConst)[keyof typeof BillingCountryEnumConst];

export const BillingCountryEnumArray = Object.values(BillingCountryEnum);
