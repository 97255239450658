<script lang="ts" setup>
import type { NotificationBase } from '@/helpers/notifications/NotificationBase';

import type { PaginationResponse } from '@/types/Api';
import type { INotification } from '@/types/models/user/notifications/Notification';
import api from '@/api';
import { useToast } from '@/composables/useToast';
import { BackupFailedNotification } from '@/helpers/notifications/BackupFailedNotification';

import { ManagerActivationFailedNotification } from '@/helpers/notifications/ManagerActivationFailedNotification';

import { ManagerDeactivationFailedNotification } from '@/helpers/notifications/ManagerDeactivationFailedNotification';

import { ManagerDeletionFailedNotification } from '@/helpers/notifications/ManagerDeletionFailedNotification';
import { ManagerInstallationFailedNotification } from '@/helpers/notifications/ManagerInstallationFailedNotification';
import { ManagerUpgradedFailedNotification } from '@/helpers/notifications/ManagerUpgradedFailedNotification';
import { ReportAutomaticFailedNotification } from '@/helpers/notifications/ReportAutomaticFailedNotification ';
import { SiteConnectionLostNotification } from '@/helpers/notifications/SiteConnectionLostNotification';
import { UptimeDownNotification } from '@/helpers/notifications/UptimeDownNotification';
import { UptimeSSLExpirationNotification } from '@/helpers/notifications/UptimeSSLExpirationNotification';
import { UptimeUpNotification } from '@/helpers/notifications/UptimeUpNotification';
import { VulnerabilityDiscoveredNotification } from '@/helpers/notifications/VulnerabilityDiscoveredNotification ';
import { EventSymbols } from '@/resources/symbols';
import useUserStore from '@/stores/userStore';
import { AlertItemTypeConst } from '@/types/enums/AlertItemType';
import { useInfiniteScroll } from '@vueuse/core';
import { computed, h, reactive, ref } from 'vue';

defineOptions({
  name: 'TheHeaderNotifications',
});

const openPopover = ref(false);
const userStore = useUserStore();
const { notificationsCount } = storeToRefs(userStore);
const { toastError } = useToast();

// Event Bus created on ManagerStore
const itemInstallationFailureEventBus = useEventBus<string>(EventSymbols.ITEM_INSTALLATION_FAILURE);

const viewAll = ref(false);
const viewMode = ref<'all' | 'unread' | 'read'>('unread');

const isRequestingData = ref(false);
const dataRequested = ref(false);

const loadMoreTrigger = ref<HTMLDivElement | null>(null);

const currentPage = ref(1);

const dataResponse = reactive<PaginationResponse<INotification>>({
  data: [] as INotification[],
  links: [],
  meta: {
    currentPage: 1,
    lastPage: 0,
  },
});

const hasData = computed(() => !!dataResponse.data && dataResponse.data.length > 0);

const canLoadMore = computed(() => {
  if (!!dataResponse?.meta && !!dataResponse?.meta?.currentPage && !!dataResponse?.meta?.lastPage) {
    return dataResponse?.meta && dataResponse?.meta?.currentPage < dataResponse?.meta?.lastPage;
  }

  return false;
});

const numberToPlacehold = computed(() => {
  if (!dataRequested.value) {
    return 15;
  }

  if (!!dataResponse.meta?.total && !!dataResponse.meta?.to && !!dataResponse.meta?.perPage) {
    const remainingItems = dataResponse.meta?.total - dataResponse.meta?.to;
    return Math.min(dataResponse.meta.perPage, remainingItems > 0 ? remainingItems : 0) || 0;
  }

  return 0;
});

async function requestData(reset = false) {
  if (isRequestingData.value) {
    return;
  }

  if (reset) {
    dataRequested.value = false;
    dataResponse.data = [];
    dataResponse.meta = {
      currentPage: 1,
      lastPage: 0,
    };

    currentPage.value = 1;
  }

  try {
    isRequestingData.value = true;

    const response = await api.user.notifications.retrieve({
      read: viewMode.value,
      page: currentPage.value,
      per_page: 15,
    });

    currentPage.value = currentPage.value + 1;

    dataResponse.meta = response.meta;

    if (!dataResponse.data) {
      dataResponse.data = response.data;
    } else {
      dataResponse.data.push(...response.data);
    }

    dataRequested.value = true;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return undefined;
  } finally {
    isRequestingData.value = false;
  }
}

const { isLoading: isLoadingMore } = useInfiniteScroll(
  loadMoreTrigger,
  async () => {
    if (!isLoadingMore.value && canLoadMore.value) {
      await requestData();
    }
  },
  { distance: 0, interval: 1000 },
);

async function markOneAsRead(notification: INotification) {
  try {
    if (!viewAll.value) {
      dataResponse.data = dataResponse.data.filter(n => n.id !== notification.id);
    }

    if (notification) {
      if (!notification.readAt) {
        notification.readAt = new Date().toISOString();

        notificationsCount.value = notificationsCount.value! - 1;
      } else {
        notification.readAt = null;

        notificationsCount.value = notificationsCount.value! + 1;
      }
    }

    await api.user.notifications.markAsRead(notification.id);
  } catch (e: any) {
    console.error(e);
    toastError();
  }
}

const markingAllAsRead = ref(false);

async function markAllAsRead() {
  try {
    markingAllAsRead.value = true;

    await api.user.notifications.markAllAsRead();

    dataResponse.data.forEach(n => (n.readAt = new Date().toISOString()));

    notificationsCount.value = 0;

    if (!viewAll.value) {
      dataResponse.data = [];
    }
  } catch (e: any) {
    console.error(e);
    toastError();
  } finally {
    markingAllAsRead.value = false;
  }
}

function handleCheckViewAll(value: boolean) {
  viewMode.value = value ? 'all' : 'unread';

  requestData(true);
}

function buildNotificationComponent(notificationData: INotification): ReturnType<typeof h> {
  const { createdAt, readAt, updatedAt, data } = notificationData;

  let notification: NotificationBase;

  switch (notificationData.type) {
    case AlertItemTypeConst.UPTIME_DOWN:
      notification = new UptimeDownNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.UPTIME_UP:
      notification = new UptimeUpNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.UPTIME_SSL_EXPIRATION:
      notification = new UptimeSSLExpirationNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.BACKUP_FAILED:
      notification = new BackupFailedNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.SITE_CONNECTION_LOST:
      notification = new SiteConnectionLostNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.MANAGER_UPGRADED_FAILED:
      notification = new ManagerUpgradedFailedNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.REPORT_AUTOMATIC_FAILED:
      notification = new ReportAutomaticFailedNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.VULNERABILITY_DISCOVERED:
      notification = new VulnerabilityDiscoveredNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.MANAGER_INSTALLATION_FAILED:
      notification = new ManagerInstallationFailedNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.MANAGER_DELETION_FAILED:
      notification = new ManagerActivationFailedNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.MANAGER_DEACTIVATION_FAILED:
      notification = new ManagerDeactivationFailedNotification(createdAt, readAt, updatedAt, data);
      break;
    case AlertItemTypeConst.MANAGER_ACTIVATION_FAILED:
      notification = new ManagerDeletionFailedNotification(createdAt, readAt, updatedAt, data);
      break;
    default:
      return h('div', 'Unknown notification type');
  }

  return notification.render();
}

itemInstallationFailureEventBus.on((event) => {
  if (event === EventSymbols.ITEM_INSTALLATION_FAILURE.toString()) {
    userStore.getNotificationsCount();
    if (!!dataResponse.data && dataResponse.data.length > 0) {
      requestData(true);
    }
  }
});
</script>

<template>
  <Popover
    v-model:open="openPopover"
    @update:open="(val) => (dataResponse.data.length === 0 && !dataRequested ? requestData(val) : null)"
  >
    <PopoverTrigger as-child>
      <VButton class="position-relative me-8 p-8 text-gray-600" variant="transparent">
        <m-icon icon="notification-bell" size="lg" />
        <span
          v-if="!!notificationsCount && notificationsCount > 0"
          class="md-notification-badge badge text-xxs bg-danger position-absolute text-light fw-bold px-2 py-0 text-center"
        >
          {{ notificationsCount }}
        </span>
      </VButton>
    </PopoverTrigger>

    <PopoverContent
      align="center"
      class="border-0 bg-white pb-32 pe-4 ps-32 pt-32 shadow-md"
      style="width: 424px; height: 540px"
    >
      <div class="d-flex align-items-center justify-content-between mb-24 me-28">
        <VButton
          id="only-unread"
          class="fw-semi text-xs"
          variant="link"
          @click="
            viewAll = !viewAll;
            handleCheckViewAll(viewAll);
          "
        >
          <template v-if="viewAll">
            {{ $t('notifications.panel.viewUnread') }}
          </template>
          <template v-else>
            {{ $t('notifications.panel.viewAll') }}
          </template>
        </VButton>

        <VButton
          v-show="hasData && !!notificationsCount && notificationsCount > 0"
          :disabled="markingAllAsRead"
          class="fw-semi text-xs"
          variant="link"
          @click="markAllAsRead"
        >
          {{ $t('notifications.panel.markAllAsRead') }}
        </VButton>
      </div>

      <div v-if="hasData" class="h-100 overflow-y-auto pe-24" style="max-height: 27rem">
        <ul v-auto-animate="{ duration: 200 }" class="list-unstyled mb-0">
          <template v-for="(notification, index) in dataResponse.data" :key="notification.id">
            <li class="position-relative">
              <div class="d-flex flex-column text-sm">
                <Component
                  :is="buildNotificationComponent(notification)"
                  @click="
                    openPopover = false;
                    markOneAsRead(notification);
                  "
                >
                  <TooltipProvider>
                    <TooltipRoot>
                      <TooltipTrigger as-child>
                        <VButton
                          v-if="!notification.readAt"
                          class="md-notification-read d-flex align-items-center justify-content-center position-absolute end-0 top-0 p-2"
                          emit-event-data
                          size="sm"
                          variant="transparent"
                          @click.prevent.stop="markOneAsRead(notification)"
                        >
                          <span class="dot d-block" />
                        </VButton>
                      </TooltipTrigger>

                      <TooltipContent align="center" side="bottom">
                        <template v-if="!notification.readAt">
                          {{ $t('notifications.panel.markAsRead') }}
                        </template>
                        <template v-else>
                          {{ $t('notifications.panel.markAsUnread') }}
                        </template>
                      </TooltipContent>
                    </TooltipRoot>
                  </TooltipProvider>

                  <VButton
                    class="fw-semi text-xs"
                    emit-event-data
                    variant="link"
                    @click.prevent.stop="markOneAsRead(notification)"
                  >
                    <template v-if="!notification.readAt">
                      {{ $t('notifications.panel.markAsRead') }}
                    </template>
                    <template v-else>
                      {{ $t('notifications.panel.markAsUnread') }}
                    </template>
                  </VButton>
                </Component>
              </div>
            </li>

            <v-separator v-if="index < dataResponse.data.length - 1" class="my-8" />
          </template>
        </ul>

        <div v-if="canLoadMore">
          <m-loader :animate-on-mount="false" :model-value="true" class="position-relative mb-8 mt-24" size="sm" />
        </div>

        <span v-if="canLoadMore" ref="loadMoreTrigger" class="d-block w-100" style="height: 1px" />

        <p
          v-show="dataResponse.meta.currentPage === dataResponse.meta.lastPage && !canLoadMore"
          class="fw-medium text-balance pt-24 text-center text-sm text-gray-600"
        >
          {{ $t('notifications.panel.all30Days') }}
        </p>
      </div>

      <ul v-else-if="!dataRequested && isRequestingData" class="list-unstyled pe-16">
        <li v-for="n in numberToPlacehold" :key="n">
          <div class="position-relative d-flex flex-column mb-8 text-sm">
            <div class="placeholder-glow pe-16">
              <span class="placeholder" style="width: 350px;" />
              <span class="placeholder" style="width: 300px;" />
              <span class="placeholder" style="width: 200px;" />
            </div>
          </div>

          <v-separator v-if="n < 6" class="my-8" />
        </li>
      </ul>

      <div v-else class="d-flex align-items-center h-100 pb-24 pe-24">
        <v-picture class="d-flex justify-content-center h-auto" style="max-width: 125px">
          <img
            alt="No notifications"
            src="@assets/img/notifications/inbox-empty.png"
            srcset="@assets/img/notifications/inbox-empty.png 2x, @assets/img/notifications/inbox-empty.png 1x"
          >
        </v-picture>

        <div class="d-flex flex-column ms-12">
          <span class="fw-bold mb-4">{{ $t('notifications.panel.noPendingTitle') }}</span>
          <p class="fw-medium mb-0 text-sm text-gray-500">
            {{ $t('notifications.panel.noPending') }}
          </p>
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>

<style lang="scss" scoped>
.md-notification-badge {
  min-width: 0.75rem;
  border-radius: 0.125rem;
  top: 0.25rem !important;
  right: 0.0625rem;
  line-height: 1rem;
}

.md-notification-read {
  width: 1rem;
  height: 1rem;

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    transition:
      background-color 0.3s,
      transform 0.2s;
    background-color: #009cd6;
  }
}
</style>
