<script lang="ts" setup>
import type { IconTypes } from '@/components/vendor/basic/icon/VIcon.vue';
import type { StrInterface } from '@/helpers/stringHelper';

import type { Tag } from '@/types/models/organization/tag/Tag';
import type { Site } from '@/types/models/site/Site';
import useSiteFunctions from '@/composables/useSiteFunctions';
import useAppStore from '@/stores/appStore';
import { BackupScheduleFrequencyEnum } from '@/types/enums/BackupScheduleFrequencyEnum';

import { PermissionEnumConst } from '@/types/PermissionEnum';

import LogoGoogle from '@assets/img/google/LogoGoogle.svg';
import { computed, inject, toRefs } from 'vue';

import { useI18n } from 'vue-i18n';
import { can } from '@/helpers/permissionsHelper';

defineOptions({
  name: 'DashboardSitesItemList', // dashboard-sites-item-list
});

const props = defineProps<{
  site: Site
  lastTimeVisited: string
  isConnectionEstablished: boolean
  isConnectionLost: boolean
  isConnectionOnError: boolean
  isConnectionOnErrorOrLost: boolean
  changeStatusText: string
  changeStatus: string
  state: {
    reconnectionTestIsLoading: boolean
    reconnectionResult: string
  }
  changeStatusData: {
    icon: IconTypes
    color: string
    background: string
  }
  loadingLoginButton: boolean
  lastBackupHasError: boolean
}>();

defineEmits<{
  (e: 'launchTracking'): void
  (e: 'testConnection'): void
  (e: 'loginWordpress', site: string, team: string): void
  (e: 'syncTag', tag: Tag): void
}>();

const { t } = useI18n();

const $str: StrInterface = inject('str')!;

const { parseUri } = useSiteFunctions();

const { site: localSite } = toRefs(props);

const {
  SITES_MANAGER_LOGIN,
  SITES_OAUTH_CLIENT_CONFIRM,
  BACKUP_SERVICES_STORE,
  SITE_BACKUPS_RETRY,
  BACKUP_SERVICES_SHOW,
  HEALTH_SERVICES_REPORT,
  VULNERABILITY_SERVICES_SHOW,
  COMPONENTS_INDEX,
  PERFORMANCE_SERVICES_SHOW,
  UPTIME_SERVICES_SHOW,
  SITES_SHOW,
} = PermissionEnumConst;

const tagsModel = defineModel<Tag[]>('tags', {
  required: true,
});

const healthCriticalErrors = computed(() => localSite.value?.healthCriticalErrors ?? 0);
const updatableItemsCount = computed(() => localSite.value?.updatableItemsCount ?? 0);

const appStore = useAppStore();

const isDark = computed(() => appStore.darkMode);

const backupTooltipData = computed(() => {
  let result
    = !!localSite.value?.backupService?.lastRequest
      ? `${t('general.shared.last')}: ${$str.formatDateTime(localSite.value.backupService.lastRequest)}`
      : '';

  const next
    = !!localSite.value?.backupService?.nextRequest
      ? `${t('general.shared.next')}: ${$str.formatDateTime(localSite.value.backupService.nextRequest)}`
      : '';

  if (!!next && next.length > 0) {
    if (!!result && result.length > 0) {
      result += `<br>${next}`;
    } else {
      result += next;
    }
  }

  return result ?? ' ';
});

const responseTime = computed(() => {
  let result = '--';

  if (!!localSite.value?.performance?.responseTime?.value) {
    result = $str.formatNumberToThousand(
      $str.numberToNumber(localSite.value?.performance?.responseTime?.value, null, 0),
    );
    result += localSite.value.performance.responseTime.unit;
  }

  return result;
});
</script>

<template>
  <tr
    :class="{
      'site-on-error': changeStatus === 'down',
      'bg-danger-extra-light': changeStatus === 'down' && !isDark,
      'bg-gray-100': changeStatus === 'down' && isDark,
    }"
    class="site-list-mode"
    data-cy="items-list"
  >
    <td class="p-0">
      <div class="site-name-inner d-flex align-items-center ps-8">
        <v-tooltip v-can="UPTIME_SERVICES_SHOW" :disabled="!changeStatusText">
          <div :class="changeStatusData.color" class="site-uptime d-flex flex-column align-items-center">
            <span
              v-if="changeStatusData.icon === 'arrow-up'"
              class="text-success text-uppercase text-2xs fw-semi"
            >
              {{ $t('general.shared.up') }}
            </span>

            <v-icon
              :class="{
                'site-uptime-up text-xs': changeStatusData.icon === 'arrow-up',
                'text-sm': changeStatusData.icon !== 'arrow-up',
              }"
              :icon="changeStatusData.icon"
              class="site-uptime-icon"
              space=""
            />
          </div>

          <template #popper>
            <span class="text-uppercase fw-semi">{{ changeStatusText }}</span>
          </template>
        </v-tooltip>

        <div class="d-flex flex-column ms-8">
          <div class="d-flex align-items-center">
            <VTagsCombobox
              v-model="tagsModel"
              location="Tags shortcut"
              sync-tag
              type="dots"
              @selected="$emit('syncTag', $event)"
            />

            <m-permission-provider :permission="SITES_SHOW">
              <router-link
                :to="{
                  name: 'sites.site.show',
                  params: {
                    site: localSite.slug,
                  },
                }"
                class="site-name d-flex flex-column text-decoration-none text-dark text-hover-primary stretched-link fw-semi text-xs"
                @click="$emit('launchTracking')"
              >
                <span data-cy="list-site-name">{{ localSite.name }}</span>
              </router-link>

              <template #fallback>
                <span
                  class="site-name d-flex flex-column text-dark fw-semi text-xs"
                  data-cy="list-site-name"
                >
                  {{ site.name }}
                </span>
              </template>
            </m-permission-provider>
          </div>

          <a
            v-if="!!localSite?.uri"
            :href="localSite.uri"
            class="site-uri position-relative text-decoration-none fw-medium text-2xs z-index-2 text-hover-primary text-gray-600"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ parseUri(localSite.host, localSite.path) }}
          </a>
        </div>
      </div>
    </td>

    <td v-if="!isConnectionOnErrorOrLost" v-can="PERFORMANCE_SERVICES_SHOW">
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex justify-content-center align-items-center google-logo h-50 mb-4 me-8">
            <LogoGoogle height="16" viewBox="0 0 32 32" width="16" />
          </div>

          <div class="response-time-logo d-flex justify-content-center align-items-center h-50">
            <v-icon class="text-warning me-8" icon="fast-gage" />
          </div>
        </div>

        <div class="d-flex flex-column align-items-start justify-content-center">
          <span
            v-if="!!localSite.performance?.score"
            class="response-time d-flex align-items-center lh-1 text-2xs fw-medium h-50 mb-4 text-gray-600"
          >
            {{ `${localSite.performance.score}/100` }}
          </span>
          <span
            v-else
            class="response-time d-flex align-items-center lh-1 text-2xs fw-medium h-50 mb-4 text-gray-600"
          >
            --
          </span>

          <span class="response-time d-flex align-items-center lh-1 text-2xs fw-medium h-50 text-gray-600">
            {{ responseTime }}
          </span>
        </div>
      </div>
    </td>

    <td
      v-if="!isConnectionOnErrorOrLost && (can(COMPONENTS_INDEX) || can(HEALTH_SERVICES_REPORT) || can(VULNERABILITY_SERVICES_SHOW))"
    >
      <div class="d-flex justify-content-center gap-2">
        <template v-if="updatableItemsCount > 0 || healthCriticalErrors > 0">
          <span
            v-if="updatableItemsCount > 0"
            v-can="COMPONENTS_INDEX"
            v-tooltip.bottom="{
              content: $t('site.shared.numUpdates', { num: updatableItemsCount }, updatableItemsCount),
              html: true,
            }"
            class="badge md-badge-updates d-flex align-items-center rounded-xs lh-md fw-medium bg-primary-light text-primary-dark text-2xs
              p-4"
          >
            {{ updatableItemsCount }}
            <m-icon icon="exchange-transfer" space="ms-2" />
          </span>

          <span
            v-if="healthCriticalErrors > 0 && (can(HEALTH_SERVICES_REPORT) || can(VULNERABILITY_SERVICES_SHOW))"
            v-tooltip.bottom="{
              content: $t('health.numCriticalErrors', { num: healthCriticalErrors }, healthCriticalErrors),
              html: true,
            }"
            class="badge md-badge-errors d-flex align-items-center rounded-xs lh-md fw-medium bg-danger-light text-danger text-2xs p-4"
          >
            {{ healthCriticalErrors }}
            <v-icon icon="sad-emoji" space="ms-2" />
          </span>
        </template>

        <div v-else>
          <v-icon class="text-success" icon="check" />
        </div>
      </div>
    </td>

    <td
      v-if="!isConnectionOnErrorOrLost && (can(BACKUP_SERVICES_STORE) || can(SITE_BACKUPS_RETRY) || can(BACKUP_SERVICES_SHOW))"
    >
      <div class="d-flex justify-content-center">
        <router-link
          v-if="!localSite.backupService || localSite?.backupService?.status === 'inactive'"
          v-can="BACKUP_SERVICES_STORE"
          :to="{
            name: 'sites.site.backup.index',
            params: {
              site: localSite.slug,
            },
          }"
          class="btn btn-link btn-link-primary-dark fw-semi p-4 text-sm"
          size="sm"
          variant="link"
          @click="$emit('launchTracking')"
        >
          {{ $t('general.button.enable') }}
        </router-link>

        <router-link
          v-else-if="lastBackupHasError"
          v-can="SITE_BACKUPS_RETRY"
          v-tooltip.bottom="$t('dashboard.backupErrorText')"
          :class="[
            {
              'bg-danger text-light': isDark,
              'bg-danger-light text-dark': !isDark,
            },
          ]"
          :to="{
            name: 'sites.site.backup.index',
            params: {
              site: localSite.slug,
            },
          }"
          class="btn btn-link-dark-primary backup-badge text-2xs fw-medium d-flex align-items-center rounded-xs gap-4 border-0 p-8"
          size="sm"
          variant="link"
          @click="$emit('launchTracking')"
        >
          <span
            :class="[{ 'bg-danger': !isDark, 'bg-danger-dark': isDark }]"
            class="backup-dot rounded-pill"
          />
          {{ $t('general.button.retry') }}
        </router-link>

        <template v-else>
          <div
            v-can="BACKUP_SERVICES_SHOW"
            v-tooltip.bottom="{
              content: backupTooltipData,
              html: true,
              disabled: !localSite?.backupService?.lastRequest && !localSite?.backupService?.nextRequest,
            }"
            :class="[
              {
                'bg-success-dark text-light': isDark,
                'bg-success-light text-dark': !isDark,
              },
            ]"
            class="badge backup-badge fw-medium d-flex align-items-center rounded-xs text-2xs gap-4 p-8"
          >
            <span
              :class="[{ 'bg-success': !isDark, 'bg-success-light': isDark }]"
              class="backup-dot rounded-pill"
            />

            <template
              v-if="!!localSite.backupService.schedule?.frequency && !!BackupScheduleFrequencyEnum[localSite.backupService.schedule.frequency].name"
            >
              {{ $t(BackupScheduleFrequencyEnum[localSite.backupService?.schedule?.frequency].name ?? '')
              }}
            </template>
          </div>
        </template>
      </div>
    </td>

    <td v-if="!isConnectionOnErrorOrLost">
      <div class="d-flex align-items-center">
        <m-button
          v-can="SITES_MANAGER_LOGIN"
          v-tooltip="$t('general.button.toWordPress')"
          :aria-label="$t('general.button.toWordPress')"
          :data-cy="`site-card-go-to-wp-${localSite.slug}`"
          :disabled="!isConnectionEstablished || loadingLoginButton"
          class="btn-wordpress fw-medium border-0 p-4 text-sm shadow-none"
          size="icon"
          variant="linkDarkPrimary"
          @click="$emit('loginWordpress', localSite.team?.slug ?? '', localSite?.slug ?? '')"
        >
          <m-icon icon="wordpress-go" size="md" space="me-4" />
          <span v-if="!!localSite?.coreVersion" class="text-decoration-underline">{{ localSite.coreVersion
          }}
          </span>
          <span v-else>--</span>
        </m-button>
      </div>
    </td>

    <td v-if="!isConnectionOnErrorOrLost">
      <slot />
    </td>

    <td v-if="isConnectionLost || isConnectionOnError" class="position-relative p-0" colspan="5">
      <div
        class="site-connection-overlay d-flex justify-content-center align-items-center position-absolute h-100 w-100 start-0 top-0"
      >
        <transition mode="out-in" name="fade">
          <div
            v-if="!state.reconnectionTestIsLoading"
            class="d-flex justify-content-center align-items-center"
          >
            <v-icon class="me-8" icon="connection" />

            <m-button
              v-if="isConnectionLost"
              v-can="SITES_OAUTH_CLIENT_CONFIRM"
              class="btn-reconnect"
              size="xs"
              variant="primaryDark"
              @click="$emit('testConnection')"
            >
              {{ $t('general.button.reconnect') }}
            </m-button>

            <router-link
              v-else
              v-can="SITES_OAUTH_CLIENT_CONFIRM"
              :to="{ name: 'sites.site.connect', params: { site: localSite.slug } }"
              class="btn btn-primary btn-primary-dark btn-xs btn-reconnect text-xs"
            >
              <span class="site-name">{{ $t('general.button.connect') }}</span>
            </router-link>
          </div>

          <div
            v-else-if="state.reconnectionResult === 'loading'"
            class="d-flex align-items-center text-xs text-white"
          >
            <span class="md-small-load-bar">
              <span class="line" />
              <span class="inside-line increase" />
            </span>

            <span class="d-block mt-16">{{ $t('dashboard.connectingLoad') }}</span>
          </div>

          <div
            v-else-if="state.reconnectionResult === 'valid'"
            class="d-flex align-items-center text-xs text-white"
          >
            <span>{{ $t('dashboard.connectionSuccess') }}</span>
          </div>

          <div
            v-else-if="state.reconnectionResult === 'invalid'"
            class="d-flex flex-column justify-content-center align-items-center text-center text-xs text-white"
          >
            <span>{{ $t('dashboard.connectionFailed') }}</span>
            <span>{{ $t('dashboard.connectionFailedDesc') }}</span>
          </div>
        </transition>
      </div>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.site-list-mode {
  td {
    padding-block: 0.25rem;
    position: relative;
    border-top: 0.0625rem solid var(--md-gray-400);
    border-bottom: 0.0625rem solid var(--md-gray-400);

    &:first-child {
      border-left: 0.0625rem solid var(--md-primary);
    }

    &:last-child {
      border-right: 0.0625rem solid var(--md-gray-400);
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }

  &.site-on-error td:first-child {
    border-left: 0.0625rem solid var(--md-danger);
  }

  .site-name,
  .site-uri {
    max-width: 10rem;
  }

  .site-name-inner {
    min-height: 3.25rem;
  }

  .site-uptime {
    width: 1.5rem;

    .site-uptime-icon.site-uptime-up {
      transform: rotate(45deg);
    }
  }

  .site-connection-overlay .icon {
    font-size: 1.75rem;
  }

  .backup-badge {
    width: fit-content;

    .backup-dot {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  /* stylelint-disable */
  .btn-wordpress {
    color: var(--md-gray-500);

    &:hover {
      color: var(--md-primary);
    }
  }

  /* stylelint-enable */

  .uptime-status {
    transform: rotateY(0deg) rotate(45deg);
  }

  .response-time-logo {
    font-size: 0.9rem;
  }

  .response-time {
    width: 3.5rem;
  }

  /* stylelint-disable */
  .md-small-load-bar {
    top: 1rem;
    transform: none;
  }

  /* stylelint-enable */

  .google-logo :deep(img) {
    max-width: 1.25rem;
  }
}

/* stylelint-disable */
[data-bs-theme='dark'] {
  .site-list-mode {
    /* stylelint-disable */
    .btn-wordpress {
      color: var(--md-gray-600);

      &:hover {
        color: var(--md-primary);
      }
    }

    /* stylelint-enable */
  }
}

/* stylelint-enable */
</style>
