<script lang="ts" setup>
import api from '@/api';
import useValidation from '@/composables/useValidation';

import { useField } from 'vee-validate';
import { inject, onMounted, onUnmounted, ref, toRefs } from 'vue';

import { useI18n } from 'vue-i18n';

import { object, string } from 'yup';

const props = defineProps<Props>();

const emit = defineEmits(['saved', 'closed', 'deleted']);

const { t } = useI18n();

const toast: any = inject('toast');

interface Props {
  siteName: string
  siteId: number
  siteNote: string | null
}

const open = ref(false);

const { siteNote } = toRefs(props);

const rules = object({
  note: string()
    .nullable(),
});

const initialValues = {
  note: siteNote.value ?? '',
};

const { accepted, meta, errors, toggleAccepted } = useValidation(rules, initialValues);

const { value: note } = useField<string>('note');

function handleClose() {
  open.value = false;

  setTimeout(() => {
    emit('closed');
  }, 200);
}

async function saveNote() {
  if (meta.value.dirty && !!props.siteId) {
    try {
      toggleAccepted();
      await api.site.note.update(props.siteId, { note: note.value });

      emit('saved', note.value);

      handleClose();
      toast.success(t('site.overview.note.saveSiteNoteSuccess'));
    } catch (e) {
      console.error(e);
    } finally {
      toggleAccepted();
    }
  } else {
    handleClose();
  }
}

onMounted(async () => {
  open.value = true;
});

onUnmounted(async () => {
  open.value = false;
});
</script>

<template>
  <Dialog v-model:open="open" :default-open="true" @update:open="handleClose">
    <DialogContent id="site-overview-note" overlay-scrollable persistent size="lg">
      <DialogHeader>
        <DialogTitle>
          {{ $t('site.overview.note.noteOfSite', { name: siteName }) }}
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <m-editor
          id="site-note-field"
          v-model="note"
          :character-limit="1000"
          :editor-class="{ 'is-invalid': !!errors.note }"
          :placeholder="$t('site.overview.note.noteOfSitePlaceholder')"
          autocomplete="off"
          class="text-sm rounded-0"
        />

        <span v-if="!!errors.note" class="d-block invalid-feedback">{{ errors.note }}</span>
      </DialogBody>

      <DialogFooter>
        <VButton :disabled="accepted" size="sm" variant="dark" @click="saveNote">
          <span>{{ $t('general.button.save') }}</span>
        </VButton>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss">
#site-overview-note {
  background-color: #ffe0a5 !important;
  border-radius: 0;
  position: relative;
  clip-path: polygon(100% 0, 100% calc(100% - 1.585rem), calc(100% - 1.585rem) 100%, 0 100%, 0 0);

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
  }

  &::before {
    border-style: solid;
    border-width: 1.5rem 1.5rem 0 0;
    border-color: #ffbd63 transparent;
  }

  &::after {
    border-style: solid;
    border-width: 0 0 1.5rem 1.5rem;
    border-color: #f6f7ff transparent;
  }

  .ui-dialog-close {
    color: var(--md-gray-800);
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }

  #site-note-field {
    .ui-editor {
      --ui-editor-bg: #ffe0a5;
      --ui-editor-button-active-color: var(--md-light);
      --ui-editor-placeholder-color: var(--md-gray-600);
      --ui-editor-button-active-bg: var(--md-gray-900);
      --ui-editor-button-hover-bg: var(--md-gray-900);
      --ui-editor-button-hover-color: var(--md-gray-300);

      border: 1px solid var(--md-gray-600) !important;
      border-radius: 0 !important;
    }

    .ui-editor-chars-limit {
      color: var(--md-gray-700);
    }
  }
}

[data-bs-theme='dark'] {
  #site-overview-note {
    background-color: var(--md-gray-200) !important;

    #site-note-field .ui-editor {
      --ui-editor-bg: var(--md-gray-200) !important;
      --ui-editor-button-hover-bg: var(--md-gray-700);
      --ui-editor-placeholder-color: var(--md-gray-500);
      --ui-editor-button-hover-color: var(--md-gray-200);
    }
  }
}
</style>
