import type { PaginationResponse } from '@/types/Api';
import type { AlertItemTypeKeys } from '@/types/enums/AlertItemType';
import type { OnOffStatusEnumType } from '@/types/enums/OnOffStatusEnum';
import type { IAlert } from '@/types/models/organization/alerting/Alert';
import { StringHelper } from '@/helpers/stringHelper';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class AlertingService extends AbstractService {
  /**
   * Retrieves all alerts
   *
   * @returns {Promise<any>}
   */
  public all(params: {
    include?: Array<string>
    append?: Array<string>
    sites?: Array<number>
    types?: Array<AlertItemTypeKeys>
  } | undefined = {}, pagination: {
    paginate: boolean
    per_page: number
    page: number
  }): Promise<IAlert[] | PaginationResponse<IAlert>> {
    return this.request({
      method: apiEndpoints.alertsIndex.method,
      url: this.buildPath(apiEndpoints.alertsIndex),
      params: {
        per_page: pagination.per_page,
        page: pagination.page,
        ...params,
      },
      options: {
        cancellable: true,
        cancelKey: 'allAlerts',
        paginate: pagination.paginate,
      },
    });
  }

  /**
   * Retrieves one alert
   *
   * @returns {Promise<any>}
   */
  public retrieve(id: string | number): Promise<IAlert> {
    return this.request({
      method: apiEndpoints.alertsShow.method,
      url: this.buildPath(apiEndpoints.alertsShow, { alert: id }),
      params: {
        include: ['alert_items', 'contacts'], // Always include alert items and contacts
      },
    });
  }

  /**
   * Creates a new alert
   *
   * @param data
   * @param items
   * @returns {Promise<any>}
   */
  public create(
    data: {
      name?: string
      emailChannel?: boolean
      smsChannel?: boolean
      default?: boolean
    },
    items: Record<string, boolean>,
  ): Promise<IAlert> {
    const helper = new StringHelper();

    // FIXME Teóricamente esto ya no hace falta
    const localData = { ...helper.convertKeysToSnakeCase(data), ...items };

    return this.request({
      method: apiEndpoints.alertsStore.method,
      url: this.buildPath(apiEndpoints.alertsStore),
      data: localData,
    });
  }

  /**
   * Updates the alert information
   *
   * @param id
   * @param data
   * @param items
   * @returns {Promise<any>}
   */
  public update(
    id: string | number,
    data: {
      name?: string
      emailChannel?: boolean
      smsChannel?: boolean
      default?: boolean
      status?: OnOffStatusEnumType
    },
    items?: Record<string, boolean>,
  ): Promise<IAlert> {
    const helper = new StringHelper();

    // FIXME Teóricamente esto ya no hace falta
    const localData = { ...helper.convertKeysToSnakeCase(data), ...items };

    return this.request({
      method: apiEndpoints.alertsUpdate.method,
      url: this.buildPath(apiEndpoints.alertsUpdate, { alert: id }),
      data: localData,
    });
  }

  /**
   * Updates the alert information
   *
   * @param id
   * @param request
   * @returns {Promise<any>}
   */
  public syncSites(
    id: string | number,
    request: {
      all: boolean
      included: Array<number>
      excluded: Array<number>
    },
  ): Promise<IAlert> {
    return this.request({
      method: apiEndpoints.alertsSitesSync.method,
      url: this.buildPath(apiEndpoints.alertsSitesSync, { alert: id }),
      data: request,
    });
  }

  /**
   * Updates the alert contacts
   *
   * @param id
   * @param contacts
   * @returns {Promise<any>}
   */
  public syncContacts(id: string | number, contacts: Array<number>): Promise<IAlert> {
    return this.request({
      method: apiEndpoints.alertsContactsSync.method,
      url: this.buildPath(apiEndpoints.alertsContactsSync, { alert: id }),
      data: {
        contacts,
      },
    });
  }

  /**
   * Deletes an alert
   *
   * @param id
   * @returns {Promise<any>}
   */
  public delete(id: string | number): Promise<any> {
    return this.request({
      method: apiEndpoints.alertsDestroy.method,
      url: this.buildPath(apiEndpoints.alertsDestroy, { alert: id }),
    });
  }
}
