import { Concept } from '@/types/models/Concept';

export type BillingProductTypeEnumKeys = 'plan' | 'addon';

export const BillingProductTypeEnumConst = {
  PLAN: 'plan',
  ADDON: 'addon',
} as const;

export const BillingProductTypeEnum: Record<BillingProductTypeEnumKeys, Concept> = {
  'plan': {
    key: 'plan',
    value: null,
    name: 'concept.billing.product.type.planName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.product.type'
  },
  'addon': {
    key: 'addon',
    value: null,
    name: 'concept.billing.product.type.addonName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'billing.product.type'
  }
} as const;

export type BillingProductTypeEnumType = (typeof BillingProductTypeEnumConst)[keyof typeof BillingProductTypeEnumConst];

export const BillingProductTypeEnumArray = Object.values(BillingProductTypeEnum);
