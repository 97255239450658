import { StringHelper } from '@/helpers/stringHelper';

export interface IUtmParameters {
  [key: string]: string
}

/**
 * CookieHelper provides methods to interact with cookies, specifically for retrieving and parsing UTM parameters.
 */
class CookieHelper {
  protected static str = new StringHelper();

  /**
   * Retrieves the value of a specific cookie by name.
   * @param {string} cookieName - The name of the cookie whose value is to be retrieved.
   * @returns {string | null} The value of the cookie if found, null otherwise.
   */
  public static getCookieValue(cookieName: string): string | null {
    const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
    if (match) {
      return match[2];
    }
    return null;
  }

  /**
   * Parses a cookie's value into UTM parameters if they exist, and converts all keys to camel case.
   * @param {string} cookieName - The name of the cookie from which to parse UTM parameters.
   * @returns {IUtmParameters} An object containing the UTM parameters with keys converted to camel case.
   */
  public static getParsedCookie(cookieName: string): IUtmParameters {
    const refererUtmCookie = this.getCookieValue(cookieName);
    const cookieParsedToJSON = refererUtmCookie ? JSON.parse(decodeURIComponent(refererUtmCookie)) : {};

    let utmObj: Record<string, string> = {};

    Object.keys(cookieParsedToJSON).forEach((key) => {
      if (key.startsWith('utm_')) {
        utmObj[key] = cookieParsedToJSON[key];
      }
    });

    utmObj = this.str.convertKeysToCamelCase(utmObj);

    return utmObj;
  }

  /**
   * Retrieves and combines UTM parameters from both the 'referer_utm' and 'referer_utm_last' cookies.
   * Parameters from 'referer_utm_last' are suffixed with 'Last' to distinguish them.
   * @returns {Record<string, string>} An object containing the combined UTM parameters.
   */
  public static getUtmParameters() {
    const utm = this.getParsedCookie('referer_utm');
    const utmLast = this.getParsedCookie('referer_utm_last');
    const resultObj = { ...utm };

    if (utmLast) {
      Object.keys(utmLast).forEach((key) => {
        resultObj[`${key}Last`] = utmLast[key];
      });
    }

    return resultObj;
  }
}

export default new CookieHelper();

export { CookieHelper };
