<script setup lang="ts">
import { DialogClose, type DialogCloseProps } from 'radix-vue'

defineOptions({
  name: 'DialogClose',
});

const props = defineProps<DialogCloseProps>()
</script>

<template>
  <DialogClose
    v-bind="props"
    class="ui-dialog-close btn btn-link btn-sm fw-medium"
  >
    <slot />
  </DialogClose>
</template>
