<script lang="ts" setup>
import { useField } from 'vee-validate';

import { computed, ref, toRefs } from 'vue';

defineOptions({
  name: 'MInputPassword',
});

const props = withDefaults(
  defineProps<{
    id: string
    name: string
    label: string
    value?: string | undefined
    inputClass?: string | object
    placeholder?: string
    autocomplete?: string
    variant?: string
    ignorePasswordManagers?: boolean
    yupErrorsVariable?: string
    disabled?: boolean
  }>(),
  {
    name: '',
    value: undefined,
    inputClass: '',
    placeholder: '',
    autocomplete: '',
    label: '',
    variant: '',
    ignorePasswordManagers: false,
    yupErrorsVariable: '',
    disabled: false,
  },
);

const showPassword = ref(false);

const { name, value: propInputValue } = toRefs(props);

const showPasswordType = computed(() => (showPassword.value ? 'text' : 'password'));
const computedClasses = computed(() => ({
  'form-control-sm': props.variant === 'sm',
  'is-invalid': !!props.yupErrorsVariable,
}));

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(name, undefined, {
  initialValue: propInputValue.value,
});
</script>

<template>
  <div class="form-floating">
    <input
      :id="name"
      :autocomplete="autocomplete"
      :class="[{ 'is-invalid': !!errorMessage }, computedClasses, inputClass]"
      :name="name"
      :placeholder="placeholder"
      :type="showPasswordType"
      :value="inputValue"
      class="form-control"
      @blur="handleBlur"
      @change="handleChange"
      @input="handleChange"
    >

    <label v-if="label" :for="name">{{ label }}</label>

    <span v-auto-animate>
      <span v-if="yupErrorsVariable" class="d-block invalid-feedback">
        {{ yupErrorsVariable }}
      </span>
    </span>

    <VButton
      :class="showPassword ? 'slashed' : ''"
      :disabled="!inputValue"
      class="btn-show border-0"
      @click="showPassword = !showPassword"
    >
      <v-icon icon="eye" />
    </VButton>
  </div>
</template>

<style lang="scss" scoped>
.form-floating {
  position: relative;

  .form-control {
    padding-right: 2.5rem;
  }
}

.btn-show {
  position: absolute;
  top: 0;
  right: 0;
  height: 3.75rem;
  width: 1.5rem;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;

  &:focus-visible {
    outline: 2px solid var(--md-gray-800);
    outline-offset: 1px;
  }

  &.slashed::before {
    content: '';
    position: absolute;
    height: 1.25rem;
    width: 0.125rem;
    background-color: var(--md-gray-600);
    transform: rotate(45deg);
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
