<script setup lang="ts">
import api from '@/api';
import useSiteStore from '@/stores/site/siteStore';
import { computed } from 'vue';

defineOptions({
  name: 'VSiteDisconnectAlert', // v-site-disconnect-alert
});

const siteStore = useSiteStore();

const currentSite = computed(() => siteStore.currentSite);
const currentSiteIsConnected = computed(() => siteStore.currentSiteIsConnected);
const currentSiteSlug = computed(() => siteStore.currentSiteSlug);

async function trackManualReconnect() {
  await api.mixpanel.track('Manual reconnect', {
    'Website name': currentSite.value?.name,
    'Website url': currentSite.value?.uri,
    'Location': 'Site alert',
  });
}
</script>

<template>
  <div v-auto-animate>
    <v-alert v-if="!!currentSite && !currentSiteIsConnected" classes="p-0 mb-32" variant="danger" :show-icon="false">
      <div class="d-flex justify-content-between">
        <p class="fw-medium mb-0 py-16 ps-16 text-xs">
          {{ $t('site.overview.updates.cannotConnectSite') }}
        </p>

        <div class="d-flex ms-auto h-auto ps-24 text-white">
          <div class="vr opacity-100" />
        </div>

        <router-link
          v-if="!!currentSite"
          :to="{ name: 'sites.site.connect', params: { site: currentSiteSlug } }"
          class="btn btn-transparent d-flex align-items-center h-auto"
          data-cy="alert-site-connect"
          @click="trackManualReconnect"
        >
          <span class="site-name fw-semi text-decoration-none text-sm">
            {{ $t('general.button.reconnectShort') }}
          </span>
        </router-link>
      </div>
    </v-alert>
  </div>
</template>
