export default {
  personalData: 'Datos personales',
  addImage: 'Añadir imagen',
  optional: '(opcional)',
  actualPassword: 'Contraseña actual',
  actualPasswordToChangeEmail: 'Introduce tu contraseña actual para cambiar tu correo electrónico',
  myProfile: 'Mi perfil',
  earlyAdopter: 'Early Adopter',
  confirmPassword: 'Confirma tu contraseña',
  confirmToChangeProfile: 'Para poder editar tu perfil, necesitamos que confirmes tu contraseña.',
  social: {
    socialLogin: 'Proveedor del inicio de sesión',
    googleLogo: 'Google Logo',
    googleAccount: 'Cuenta de Google',
    enableSocial: 'Conecta una cuenta de Google para poder iniciar sesión directamente con ella en Modular.',
    disableSocial:
      'Si desconectas tu cuenta de Google deberás iniciar sesión con usuario y contraseña y establecer una contraseña si no la has creado anteriormente.',
    socialNetworkAlreadyExistsError: 'La red social especificada ya existe en nuestra base de datos.',
    disableSocialAccountModalTitle: 'Desactivar cuenta de Google',
    disableSocialTextWithPasswordText:
      'Una vez desactivada no podrás acceder a tu cuenta de Modular a través de tu cuenta de Google. Tendrás que utilizar el email de tu cuenta ({email}) y la contraseña.',
    disableSocialTextWithPasswordSubtitle: 'Te enviaremos un email para establecer tu contraseña',
  },
  doubleFA: {
    title: 'Autenticación en 2 pasos (2FA)',
    preTitle: 'Autenticación en 2 pasos',
    text: 'Incrementa la seguridad de acceso a tu cuenta',
    descriptionLabel: 'Evita que otras personas puedan acceder a tu cuenta.',
    descriptionText:
      'Activa la autenticación en 2 pasos para protegerte incluso en el caso de que hayan conseguido averiguar la contraseña que utilizas de alguna manera. De esta forma, incluso si te roban las credenciales siempre quedará ese segundo paso en el que tú tienes que verificar el inicio de sesión.',
    recoveryTitle: 'Recuperación de acceso',
    recoveryDescription: 'Confirma el acceso a tu cuenta con tu código de recuperación de la autenticación en 2 pasos.',
    recoveryFieldTag: 'Código de recuperación',
    faSecret: 'Secret: {secret}',
    faType: 'Tipo: TOTP (contraseña de un solo uso basada en el tiempo)',
    faTDigits: 'Dígitos: 6',
    faTAlgorithm: 'Algoritmo: SHA1',
    faTInterval: 'Intervalo: 30',
    modalConfigTitle: 'Configurar Autenticación en 2 pasos (2FA)',
    modalConfigDescription: 'Sigue estos pasos para empezar a recibir los códigos de 6 dígitos en tu móvil.',
    modalConfigPhase1:
      'Instala en tu dispositivo una aplicación de autenticación (por ejemplo FreeOTP, Google Authenticator...)',
    modalConfigPhase2: 'Abre la aplicación de autenticación en tu dispositivo móvil',
    modalConfigPhase3: 'Escanea con la aplicación el código QR que se muestra abajo',
    endConfigText:
      'Para terminar la configuración, introduce el código de 6 dígitos que te muestra la aplicación de autenticación en tu dispositivo móvil. En el caso de FreeOTP, el código se muestra cuando escribes en la entrada MODULAR de la aplicación de autenticación.',
    modalDisableTitle: 'Desactivar Autenticación en 2 pasos (2FA)',
    modalDisableText:
      'Si desactivas la autenticación en 2 pasos, no podrás proteger tu cuenta con esta opción. ¿Seguro que quieres desactivarla?',
    recoveryKeys: 'Claves de recuperación',
    modalSaveRecoveryKeys:
      'Guarda las claves de recuperación de 2FA de tu cuenta en un lugar seguro. Estas claves son muy importantes en caso de que pierdas tu dispositivo o no puedas acceder a tu aplicación de autenticación.',
    copyRecoveryCodes: 'Copiar claves',
  },
  languages: {
    selectLanguage: 'Selecciona un idioma',
    spanish: 'Español',
    english: 'Inglés',
  },
  connectionDevices: 'Conexión & Dispositivos',
  currentSession: 'Sesión actual',
  otherSessions: 'Otras sesiones abiertas',
  disconnectAlertMessage: 'Aquí puedes ver y desconectarte de las sesiones que tienes abiertas en otros dispositivos.',
  noOtherSessionTitle: 'No tienes otras sesiones abiertas en este momento',
  noOtherSessionText: 'Si inicias sesión en otro dispositivo, aparecerá aquí',
  thisBrowser: 'Este navegador',
  lastUsed: 'Usado por última vez el { date } (UTC)',
  ip: 'IP: { value }',
  disconnectAllSessionsTitle: 'Cerrar todas las otras sesiones abiertas',
  disconnectAllSessionsText:
    'Si cierras tus otras sesiones abiertas tendrás que volver a iniciar sesión manualmente para poder usar Modular en los dispositivos de la lista.',
  passwordMessage:
    'Al cambiar tu contraseña, se cerrarán todas tus sesiones de Modular activas excepto la que estás utilizando en este momento.',
  dateAndTimezoneFormats: 'Formatos de fecha y zona horaria',
  selectTimezone: 'Selecciona una zona horaria',
  timezone: 'Zona horaria',
  dateTimeFormat: {
    label: 'Formato de hora',
    twelveHours: 'Formato 12h (6:30pm)',
    twentyFourHours: 'Formato 24h (18:30)',
  },
  dateFormat: {
    label: 'Formato de fecha',
    ddmmyyyy: 'dd/mm/yyyy',
    mmddyyyy: 'mm/dd/yyyy',
    yyyymmdd: 'yyyy-mm-dd',
  },
  timeFormat: 'Formato de hora',
};
