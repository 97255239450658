<script lang="ts" setup>
import useMetrics from '@/composables/metrics/useMetrics';
import useMetricsStore from '@/stores/site/metrics/metricsStore';

import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';

import VWidgetSearchConsole from '@/views/site/metrics/search-console/modules/VWidgetSearchConsole.vue';
import LogoGoogle from '@assets/img/google/LogoGoogle.svg';
import { watchImmediate } from '@vueuse/core';
import { ref } from 'vue';

defineOptions({
  name: 'TheSiteOverviewSearchConsole',
});

const metricsStore = useMetricsStore();

const { loadedSearchConsole } = storeToRefs(metricsStore);

const searchConsoleIsEnabled = computed(() => metricsStore.searchConsoleIsEnabled);

const loadedSearchConsoleReport = computed(() => metricsStore.loadedSearchConsoleReport);
const searchConsoleReport = computed(() => metricsStore.searchConsoleReport);

const { SEARCH_CONSOLE } = ServiceTypeEnumConst;

const {
  metricsDatesFields,
  loadMetricService,
  loadSearchConsoleReport,
} = useMetrics(SEARCH_CONSOLE);

// Only load Widget when visible
const searchConsoleWidget = ref<HTMLElement | null>(null);
const targetIsVisible = useElementVisibility(searchConsoleWidget);

watchImmediate([targetIsVisible, searchConsoleIsEnabled], async ([targetValue, isEnabled]) => {
  if (targetValue && !searchConsoleReport.value && isEnabled) {
    if (!metricsStore.searchConsoleService) {
      await loadMetricService();
    }

    await loadSearchConsoleReport({
      start: metricsDatesFields.value.start,
      end: metricsDatesFields.value.end,
    });
  }

  // If search is not enabled, we can consider it loaded
  if (!isEnabled) {
    loadedSearchConsole.value = true;
  }
});
</script>

<template>
  <div
    id="site-overview-search-console-container"
    ref="searchConsoleWidget"
    class="position-relative card mb-xl-0 mb-32"
  >
    <div id="site-overview-search-console" class="card-body">
      <Transition mode="out-in" name="fade-in">
        <!-- Placeholder loading -->
        <div
          v-if="!loadedSearchConsole || (searchConsoleIsEnabled && !loadedSearchConsoleReport)"
          class="text-gray-500"
        >
          <div class="placeholder-glow mb-8">
            <div class="placeholder" style="width: 155px" />
          </div>

          <div class="placeholder-glow mb-12">
            <div class="placeholder w-100 mb-8" style="height: 76px" />
            <div class="placeholder w-100" style="height: 40px" />
          </div>

          <div class="placeholder-glow">
            <div class="placeholder" style="height: 12px; width: 265px" />
          </div>
        </div>

        <!-- Loaded not activated -->
        <div v-else-if="!searchConsoleIsEnabled || !searchConsoleReport">
          <div class="search-console-header d-flex justify-content-between align-items-center mb-24">
            <div class="metrics-title fw-semi">
              {{ $t('site.overview.searchConsole.title') }}
            </div>
          </div>

          <div class="search-console-body">
            <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between w-100">
              <div class="col-12 col-lg-6 image position-relative">
                <v-picture height="153" lazy width="370">
                  <img
                    alt="Empty Search Console chart"
                    class="md-image-empty-chart w-100 w-lg-auto mw-100"
                    src="@assets/img/search-console-overview.png"
                    srcset="@assets/img/search-console-overview.png 2x, @assets/img/search-console-overview.png 1x"
                  >
                </v-picture>

                <span class="md-image-gradient position-absolute w-100 h-100 start-0 top-0" />
              </div>

              <div class="col-12 col-lg-6 d-flex flex-column align-items-end text-end">
                <p class="fw-semi text-primary mb-0">
                  {{ $t('site.overview.searchConsole.title') }}
                </p>

                <p class="fw-medium mb-8 text-pretty text-xs text-gray-500">
                  {{ $t('site.overview.searchConsole.description') }}
                </p>

                <router-link
                  :to="{ name: 'sites.site.service.search-console.show' }"
                  class="btn btn-outline-gray btn-sm btn-icon fw-semi"
                >
                  <div class="me-8">
                    <LogoGoogle height="16" viewBox="0 0 32 32" width="16" />
                  </div>

                  <span class="text-sm">{{ $t('site.overview.searchConsole.connect') }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- Active: Load graph -->
        <VWidgetSearchConsole
          v-else
          :chart-height="144"
          :data-selectable="false"
          :date-range="7"
          :ended-date="metricsDatesFields.end"
          :search-console="searchConsoleReport"
          :started-date="metricsDatesFields.start"
          class-chart-container="search-console-chart-container-sm"
          class-graphic-stat="graphic-stat-sm"
          class-main-panel="mb-24"
          show-metrics-date
          show-title
        />
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#site-overview-search-console-container {
  min-height: 16rem;

  #site-overview-search-console {
    min-height: 12rem;
  }
}
</style>
