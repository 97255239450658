<script lang="ts" setup>
import type { InjectionKey } from 'vue';
import type { ToastInterface } from 'vue-toastification';
import api from '@/api';

import VModal from '@/components/vendor/modal/VModal.vue';
import useValidation from '@/composables/useValidation';
import { EventSymbols } from '@/resources/symbols';

import useUserStore from '@/stores/userStore';

import { useField } from 'vee-validate';

import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { object, string } from 'yup';

defineOptions({
  name: 'VTeamCreate',
});

const toast: InjectionKey<ToastInterface> | any = inject('toast');

const userStore = useUserStore();

const disableButton = ref<boolean>(false);
const teamModal = ref<InstanceType<typeof VModal> | null>(null);

const openModal = ref<boolean>(false);

// Event Bus created on Composable useTeamCreate
const createTeamEventBus = useEventBus<string>(EventSymbols.CREATE_TEAM);

createTeamEventBus.on((event) => {
  if (event === EventSymbols.CREATE_TEAM.toString()) {
    openModal.value = true;
  }
});

const { t } = useI18n();

const rules = object({
  name: string()
    .required()
    .max(60, t('validation.string.max', { max: 60 })),
});

const form = useValidation(rules);

const { errors, accepted, toggleAccepted, meta, setFieldValue, setFieldError, resetField } = form;

const { value: name } = useField<string>('name');

const router = useRouter();

async function onSubmit() {
  try {
    toggleAccepted();
    disableButton.value = true;

    const team = await api.team.create(name.value);

    toast.success(t('team.shared.successCreate'));

    await userStore.refreshTeams();

    toggleAccepted();
    disableButton.value = false;

    if (!!teamModal.value) {
      teamModal.value.hide();
    }

    await router.replace({
      name: 'team.show',
      params: { team: team.slug },
    });

    resetField('name');
  } catch (e: any) {
    toggleAccepted();
    disableButton.value = false;

    throw e;
  }
}

function closeModal() {
  resetField('name');

  openModal.value = false;
}
</script>

<template>
  <VModal v-if="openModal" id="team-create-modal" ref="teamModal" data-cy="team-create-modal" @closed="closeModal">
    <template #header>
      <modal-title>
        {{ $t('team.shared.create') }}
      </modal-title>
    </template>

    <template #body>
      <div class="form-floating w-100">
        <input
          id="name"
          v-model="name"
          :class="{ 'is-invalid': !!errors.name }"
          :placeholder="$t('general.shared.name')"
          autocomplete="off"
          class="form-control"
          data-cy="team-create-name"
          type="text"
          @keyup.enter="onSubmit"
        >

        <label for="name">{{ $t('team.shared.name') }}</label>

        <span v-if="!!errors.name" class="invalid-feedback">{{ errors.name }}</span>
      </div>
    </template>

    <template #footer>
      <VButton
        :disabled="!meta.valid || disableButton"
        :is-loading="accepted"
        data-cy="btn-team-create-confirm"
        size="sm"
        variant="primary"
        @click="onSubmit"
      >
        {{ $t('team.shared.createTeam') }}
      </VButton>
    </template>
  </VModal>
</template>
