<script lang="ts" setup>
import type { SiteItemTypeEnumKeys } from '@/types/enums/SiteItemTypeEnum';
import type { RemovableRef } from '@vueuse/core';
import type { InjectionKey, Ref } from 'vue';
import type { ToastInterface } from 'vue-toastification';

import api from '@/api';
import VInputSearch from '@/components/vendor/basic/form/VInputSearch.vue';
import useConstantsHelper from '@/resources/constants/ConstantsHelper';
import useManagerStore from '@/stores/manager/managerStore';
import useSiteStore from '@/stores/site/siteStore';

import { SiteItemTypeEnumConst } from '@/types/enums/SiteItemTypeEnum';
import { useIntervalFn, useLocalStorage } from '@vueuse/core';

import { storeToRefs } from 'pinia';

import { computed, inject, ref, watch } from 'vue';

import { useI18n } from 'vue-i18n';

defineOptions({
  name: 'TheManagerFilters',
});

const emit = defineEmits(['changedType', 'changedStatus', 'changedFilter', 'syncSitesRequested', 'clearFilters']);

const { PLUGIN, CORE, THEME } = SiteItemTypeEnumConst;

const toast: InjectionKey<ToastInterface> | any = inject('toast');
const { t } = useI18n();

const search = defineModel<string>('search', {
  default: '',
  required: false,
});

const siteStore = useSiteStore();
const { currentSite } = storeToRefs(siteStore);

const managerStore = useManagerStore();
const {
  syncSitesRequested,
  globalMetas,
  type,
  tags: tagsSelected,
  visibilityStatus,
  hasVulnerabilities,
  loadedComponents,
  isGlobal,
} = storeToRefs(managerStore);

interface StatusesOptionsType {
  id: number
  label: string
  count: null | number
  value: 'all' | 'visible' | 'hidden'
}

// TODO: Esto tendría que venir de API, de momento lo hace el front
const statusesOptions: Ref<StatusesOptionsType[]> = ref([
  {
    id: 1,
    label: t('manager.all'),
    count: null,
    value: 'all',
  },
  {
    id: 2,
    label: t('manager.upgradesAvailable'),
    count: null,
    value: 'visible',
  },
  {
    id: 3,
    label: t('manager.hiddenUpdates'),
    count: null,
    value: 'hidden',
  },
]);

// region SyncedAt
const { config } = useConstantsHelper();
const { updaterReloadTimeout } = config;

const lastSyncedGlobal: RemovableRef<string | null> = useLocalStorage('lastSyncedGlobal', null);
const currentLastSynced = ref('');
const currentDate = ref(new Date().toISOString());

const currentLastSyncedGlobal = computed(() => {
  if (!currentSite.value) {
    return lastSyncedGlobal.value ? new Date(lastSyncedGlobal.value).toISOString() : '';
  }
  return '';
});

const isButtonDisabledByDate = computed(() => {
  const lastSynced = !currentSite.value ? currentLastSyncedGlobal.value : currentLastSynced.value;

  if (!lastSynced) {
    return false;
  }

  const lastDate = new Date(lastSynced);
  const difference = new Date(currentDate.value).getTime() - lastDate.getTime();

  return difference < updaterReloadTimeout;
});

useIntervalFn(() => {
  currentDate.value = new Date().toISOString();
}, 30000);

function saveReloadDate() {
  const currentIsoDate = new Date().toISOString();

  if (!currentSite.value) {
    lastSyncedGlobal.value = currentDate.value;
  } else {
    currentSite.value.syncedAt = currentIsoDate;
    currentLastSynced.value = currentIsoDate;
  }
}

watch(
  () => currentSite.value?.syncedAt,
  (newSyncedAt) => {
    if (!!currentSite.value && newSyncedAt) {
      currentLastSynced.value = newSyncedAt;
    }
  },
  { immediate: true },
);
// endregion SyncedAt

function reloadUpdaterData(currentTab: SiteItemTypeEnumKeys) {
  if (currentTab !== type.value) {
    type.value = currentTab;
    emit('changedType');
  }
}

async function syncSites() {
  syncSitesRequested.value = true;
  managerStore.generateComponentSelection();

  // Do this before saveReloadDate due to not being requested if we use isButtonDisabledByDate
  const isButtonDisabled = isButtonDisabledByDate.value;

  saveReloadDate();

  let params = null;

  if (!!currentSite.value) {
    params = {
      sites: [Number(currentSite.value.id)],
    };
  }

  if (!isButtonDisabled) {
    await api.organization.managerUpdate.sync(params);
  }

  await api.mixpanel.track('Refresh updater', {
    Location: !currentSite.value ? 'Updater' : 'Website',
  });

  if (!currentSite.value) {
    toast.success(t('manager.successReloadItemsText'));
  }
}

function trackEvent(eventName: string) {
  if (!eventName)
    return;

  api.mixpanel.track(eventName, {
    Location: isGlobal.value ? 'Updater' : 'Website',
  });
}
</script>

<template>
  <div id="manager-filters" class="mb-16">
    <Tabs :default-value="PLUGIN" :model-value="type" class="w-100 mb-8" @update:model-value="reloadUpdaterData">
      <TabsList :class="{ disabled: syncSitesRequested }">
        <TabIndicator />

        <TabsTrigger
          :disabled="syncSitesRequested"
          class="me-16 px-0 px-2 text-sm"
          data-cy="global-visible-updates"
          value="plugin"
        >
          <span class="text-capitalize">{{ $t('manager.plugin', 2) }}
            <template v-if="!!globalMetas">({{ globalMetas.plugin.total }})</template>
          </span>

          <span
            v-if="!!globalMetas && globalMetas.plugin.updatable > 0"
            class="badge text-bg-danger text-3xs rounded-xs ms-4 px-6 py-4"
          >
            {{ globalMetas.plugin.updatable }}
          </span>
        </TabsTrigger>

        <TabsTrigger
          :disabled="syncSitesRequested"
          class="me-16 px-0 px-2 text-sm"
          data-cy="global-hidden-updates"
          value="theme"
        >
          <span class="text-capitalize">{{ $t('manager.theme', 2) }}
            <template v-if="!!globalMetas">({{ globalMetas.theme.total }})</template>
          </span>

          <span
            v-if="!!globalMetas && globalMetas.theme.updatable > 0"
            class="badge text-bg-danger text-3xs rounded-xs ms-4 px-6 py-4"
          >
            {{ globalMetas.theme.updatable }}
          </span>
        </TabsTrigger>

        <TabsTrigger
          :disabled="syncSitesRequested"
          class="me-16 px-0 px-2 text-sm"
          data-cy="global-hidden-updates"
          value="core"
        >
          <span>{{ $t('manager.core') }}
            <template v-if="!!globalMetas">({{ globalMetas.core.total }})</template>
          </span>

          <span
            v-if="!!globalMetas && globalMetas.core.updatable > 0"
            class="badge text-bg-danger text-3xs rounded-xs ms-4 px-6 py-4"
          >
            {{ globalMetas.core.updatable }}
          </span>
        </TabsTrigger>
      </TabsList>
    </Tabs>

    <div class="d-flex justify-content-between flex-wrap gap-16">
      <Select
        v-model="visibilityStatus"
        :disabled="syncSitesRequested || !loadedComponents"
        @update:model-value="emit('changedStatus')"
      >
        <select-trigger variant="flat">
          <select-value :placeholder="$t('manager.selectStatus')" />
        </select-trigger>

        <select-content class="w-100">
          <select-group>
            <select-item v-for="status in statusesOptions" :key="status.id" :value="status.value">
              <span class="d-flex align-items-center">
                <span class="me-8">{{ status.label }}</span>

                <span v-if="!!status.count" class="badge text-bg-primary text-3xs rounded-xs ms-4 px-6 py-4">
                  {{ status.count }}
                </span>
              </span>
            </select-item>
          </select-group>
        </select-content>
      </Select>

      <div :class="isGlobal ? 'gap-16' : 'justify-content-end'" class="d-flex ms-auto">
        <div class="d-flex gap-16">
          <Tooltip :content="$t('manager.cannotReloadText')" :disabled="!isButtonDisabledByDate">
            <VButton
              :aria-label="$t('manager.reloadData')"
              :class="{ 'ms-auto': !isGlobal }"
              :disabled="isButtonDisabledByDate || syncSitesRequested"
              class="h-100 align-self-end"
              size="sm"
              variant="outline-gray"
              @click="syncSites"
            >
              <span>
                {{ $t('manager.reloadData') }}
              </span>
            </VButton>
          </Tooltip>

          <router-link
            v-if="type === PLUGIN || type === THEME"
            :aria-label="type === PLUGIN ? $t('manager.installPlugin') : $t('manager.installTheme')"
            :to="{
              name: isGlobal ? 'manager.component.install' : 'sites.site.manager.component.install',
              params: { type },
            }"
            class="btn btn-primary btn-sm border-1"
            @click="trackEvent(`Try to install ${type}`)"
          >
            <m-icon icon="actions-plus" size="xs" />
            {{ type === PLUGIN ? $t('manager.installPlugin') : $t('manager.installTheme') }}
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="isGlobal" class="d-flex justify-content-between align-items-center mt-16 gap-40">
      <div class="d-flex">
        <span class="fw-semi me-12 text-nowrap pt-4 text-sm">{{ $t('general.shared.filterBy') }}</span>

        <div class="d-flex w-100 flex-wrap gap-4">
          <VTagsCombobox
            v-model="tagsSelected"
            :create="false"
            :disabled="syncSitesRequested || !loadedComponents"
            :edit="false"
            location="Manager"
            emit-changed-on-close
            type="filter"
            @changed-tags="$emit('changedFilter')"
          />

          <VButton
            :class="{ active: hasVulnerabilities }"
            :disabled="syncSitesRequested || !loadedComponents"
            class="rounded-xs text-nowrap"
            size="2xs"
            variant="combobox-select"
            @click="
              hasVulnerabilities = !hasVulnerabilities;
              emit('changedFilter');
            "
          >
            <span class="d-flex align-items-center gap-16">
              {{ $t('manager.withVulnerabilities') }}

              <checkbox :checked="hasVulnerabilities" size="xs" square />
            </span>
          </VButton>

          <VButton
            v-if="tagsSelected.length > 0 || hasVulnerabilities"
            :aria-label="$t('general.button.clearFilters')"
            :disabled="syncSitesRequested"
            :title="$t('general.button.clearFilters')"
            class="fw-semi border-0 px-8 py-4"
            size="sm"
            variant="link-dark-primary"
            @click="$emit('clearFilters')"
          >
            {{ $t('general.button.clearFilters') }}
          </VButton>
        </div>
      </div>

      <VInputSearch
        v-if="type !== CORE"
        id="input-search-manager"
        v-model="search"
        :disabled="syncSitesRequested || !loadedComponents"
        :placeholder="$t('manager.searchType', { type: $t(`manager.${type}`) })"
        class="col-4 col-xxl-3"
        input-class="py-12"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-outline-gray:disabled {
  background-color: var(--md-gray-400);
}
</style>
