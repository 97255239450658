<script lang="ts" setup>
import { SelectScrollDownButton, type SelectScrollDownButtonProps, useForwardProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<SelectScrollDownButtonProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <SelectScrollDownButton
    class="d-flex cursor-default align-items-center justify-content-center py-4"
    :class="[props.class]"
    v-bind="forwardedProps"
  >
    <slot>
      <m-icon icon="arrow-chevron-down" size="sm" />
    </slot>
  </SelectScrollDownButton>
</template>
