import type { Site } from '@/types/models/site/Site';
import type { InjectionKey } from 'vue';

import type { ToastInterface } from 'vue-toastification';
import api from '@/api';

import useModal from '@/composables/useModal';
import useOrganizationStore from '@/stores/organizationStore';

import useUserStore from '@/stores/userStore';

import { useI18n } from 'vue-i18n';

interface DeleteSiteOptions {
  toastOnSuccess?: boolean
  refreshDataOnSuccess?: boolean
}

export function useSiteDelete() {
  const { t } = useI18n();

  const toast: InjectionKey<ToastInterface> | any = inject('toast');

  const { Modal } = useModal();

  const organizationStore = useOrganizationStore();
  const userStore = useUserStore();

  const route = useRoute();

  const doDeleteSite = async (siteId: number, options?: DeleteSiteOptions) => {
    await api.site.general.delete(siteId);

    if (options?.toastOnSuccess) {
      toast.success(t('general.modal.successDeleteSite'));
    }

    const promises = [userStore.refreshTeams(), organizationStore.manager()];

    if (route.params.team) {
      // FIXME THIS BREAKS
      // promises.push(teamStore.refreshSites(route.params.team));
    }

    if (options?.refreshDataOnSuccess) {
      await Promise.all(promises);
    }
  };

  const handleDeleteSite = (
    site: Site,
    callback?: () => void,
    options: DeleteSiteOptions = { toastOnSuccess: true, refreshDataOnSuccess: true },
  ) => {
    Modal.delete({
      title: t('general.modal.delete', { name: site.name }),
      text: t('general.modal.confirmDeleteSite'),
      leftButtonCallback: async () => {
        await doDeleteSite(site.id, options);

        if (callback) {
          callback();
        }
      },
    });
  };

  return {
    handleDeleteSite,
  };
}
