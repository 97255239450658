<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useField } from 'vee-validate';

const props = withDefaults(
  defineProps<{
    id: string;
    label: string;
    value: string | undefined;
    name: string;
    type?: string;
    disabled?: boolean;
    variant?: string;
    inputClass?: any;
    placeholder?: string;
    autocomplete?: string;
    ignorePasswordManagers?: boolean;
    yupErrorsVariable?: string;
  }>(),
  {
    type: 'text',
    name: '',
    value: undefined,
    disabled: false,
    variant: '',
    inputClass: '',
    placeholder: '',
    autocomplete: '',
    ignorePasswordManagers: false,
    yupErrorsVariable: '',
  }
);

const computedClasses = computed(() => ({
  'form-control': !['checkbox', 'radio'].includes(props.type),
  'form-control-sm': props.variant === 'sm',
  'is-invalid': !!props.yupErrorsVariable,
}));


const { name, value: propInputValue } = toRefs(props);

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(name, undefined, {
  initialValue: propInputValue.value,
});
</script>

<template>
  <div class="form-floating">
    <input
      :id="id"
      :name="name"
      :value="inputValue"
      :autocomplete="autocomplete"
      :class="['form-control', { 'is-invalid': !!errorMessage }, computedClasses, inputClass]"
      :data-1p-ignore="ignorePasswordManagers ? '' : null"
      :data-lpignore="ignorePasswordManagers"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="type"
      @input="handleChange"
      @change="handleChange"
      @blur="handleBlur"
    />
    
    <label v-if="!!label" :for="id">{{ label }}</label>
    
    <div v-auto-animate>
      <span v-if="!!yupErrorsVariable" class="d-block invalid-feedback">
        {{ yupErrorsVariable }}
      </span>
    </div>
  </div>
</template>