import { Concept } from '@/types/models/Concept';

export type SiteItemTypeEnumKeys = 'core' | 'plugin' | 'theme' | 'translation';

export const SiteItemTypeEnumConst = {
  CORE: 'core',
  PLUGIN: 'plugin',
  THEME: 'theme',
  TRANSLATION: 'translation',
} as const;

export const SiteItemTypeEnum: Record<SiteItemTypeEnumKeys, Concept> = {
  'core': {
    key: 'core',
    value: null,
    name: 'concept.site.item.type.coreName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.item.type',
    status: 'active'
  },
  'plugin': {
    key: 'plugin',
    value: null,
    name: 'concept.site.item.type.pluginName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.item.type',
    status: 'active'
  },
  'theme': {
    key: 'theme',
    value: null,
    name: 'concept.site.item.type.themeName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.item.type',
    status: 'active'
  },
  'translation': {
    key: 'translation',
    value: null,
    name: 'concept.site.item.type.translationName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'site.item.type',
    status: 'active'
  }
} as const;

export type SiteItemTypeEnumType = (typeof SiteItemTypeEnumConst)[keyof typeof SiteItemTypeEnumConst];

export const SiteItemTypeEnumArray = Object.values(SiteItemTypeEnum);
