import Tracker from '@/helpers/trackerHelper';

import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class MixpanelService extends AbstractService {
  /**
   * Tracks mixpanel event
   *
   * @param {string} name
   * @param data
   * @returns {Promise<any>}
   */
  public track(name: string, data?: any): Promise<void> {
    return this.request({
      method: 'post',
      url: this.buildPath(apiEndpoints.trackerStore),
      data: {
        name,
        data,
      },
      headers: Tracker.getTracker(),
      options: {
        caseTransform: 'none',
      },
    });
  }

  /**
   * Tracks mixpanel event
   *
   * @param {string} name
   * @param data
   * @returns {Promise<any>}
   */
  public trackGuest(name: string, data?: any): Promise<void> {
    return this.request({
      method: 'post',
      url: this.buildPath(apiEndpoints.trackerStoreGuest),
      data: {
        name,
        data,
      },
      headers: Tracker.getTracker(),
      options: {
        caseTransform: 'none',
      },
    });
  }
}
