export default {
  noData: 'Sin datos',
  selectPropertyDomain: 'Selecciona una propiedad de Search Console',
  selectPropertyDomainText:
    'Debes seleccionar una propiedad a la que tengas acceso desde la cuenta de Google que acabas de conectar.',
  changePropertyDomain: 'Cambia la propiedad de Search Console',
  changePropertyDomainText:
    'Puedes cambiar la propiedad de Search Console que has elegido entre las propiedades a las que tiene acceso tu cuenta de Google.',
  selectProperty: 'Selecciona una propiedad',
  noResults: 'Sin resultados para {search}',
  unlinkAccount: 'Desvincular cuenta de Google',
  unlinkAccountExplain:
    'Vas a desvincular tu cuenta de Google para esta web. No podrás ver tu rendimiento en los resultados de búsqueda en Modular hasta que vuelvas a reconectar la cuenta y selecciones una propiedad de Search Console. ¿Quieres continuar?',
  frequentlyQuestionsResults: 'Preguntas frecuentes sobre los resultados enriquecidos',
  translatedResults: 'Resultados traducidos',
  changeSearchConsoleProperty: 'Cambiar propiedad de Search Console',
  goToSearchConsole: 'Ir a Search Console',
  reportError:
    'Wops! Parece que hay algún problema al obtener el reporte de Search Console. Revisa la configuración de tu cuenta de Google y vuelve a intentarlo.',
  empty: {
    emptyYTLink: 'https://www.youtube.com/embed/szv-l2OcLZs',
    title: 'Search Console',
    emptyText:
      'Conecta una propiedad de Google Search Console para consultar desde Modular el rendimiento en tu web en las búsquedas de Google. ',
    statisticsTitle1: 'Analiza el rendimiento de tu web en Google',
    statisticsReason1:
      'Estadísticas básicas de Google Search Console, que incluyen las consultas, páginas de destino, países y dispositivos con mejores métricas. Para entender mejor el tráfico orgánico de tu web.',
    statisticsTitle2: 'Inclúyelas en los reportes',
    statisticsReason2:
      'Una vez que conectes tu propiedad de Google Search Console, podrás mostrar las estadísticas de rendimiento en Google también en los reportes para tus clientes.',
  },
};
