import type { ServiceTypeEnumKeys } from '@/types/enums/ServiceTypeEnum';
import type { SiteServiceBackup } from '@/types/models/site/service/backup/SiteServiceBackup';
import type { SiteServiceUptime } from '@/types/models/site/service/uptime/SiteServiceUptime';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class ServiceStatusService extends AbstractService {
  /**
   * Toggles the status of a specific service type for the current site.
   *
   * @param siteId
   * @param serviceType
   * @returns {Promise<any>}
   */
  public toggle(siteId: number, serviceType: ServiceTypeEnumKeys): Promise<SiteServiceBackup | SiteServiceUptime> {
    return this.request({
      method: apiEndpoints.servicesStatusToggle.method,
      url: this.buildPath(apiEndpoints.servicesStatusToggle, {
        site: siteId,
        type: serviceType,
      }),
    });
  }
}
