import type { Site } from '@/types/models/site/Site';

import type { PaginatedSites, SitesMeta } from '@/types/models/site/Sites';
import type { Team } from '@/types/models/team/Team';
import type { MaybeRefOrGetter, Ref } from 'vue';

import api from '@/api';

/**
 * Composable that request sites for a team using infinite scroll
 */
export function useTeamSitesInfiniteScroll(
  loadMoreTrigger: Ref<HTMLElement | null>,
  team: MaybeRefOrGetter<Team | undefined>,
) {
  const isRequestingData = ref(false);
  const dataRequested = ref<boolean>(false);
  const currentPage = ref(1);

  const dataResponse = reactive<PaginatedSites>({
    data: [] as Site[],
    links: [],
    included: [],
    meta: {
      currentPage: 1,
      lastPage: 0,
    } as SitesMeta,
  });

  const reset = () => {
    isRequestingData.value = false;
    dataRequested.value = false;
    currentPage.value = 1;
    dataResponse.data = [];
    dataResponse.meta = {
      currentPage: 1,
      lastPage: 0,
      from: null,
      path: null,
      perPage: null,
      to: null,
      total: null,
      totalDisconnectedSites: 0,
      totalUpdatableItems: 0,
    };
  };

  const localTeam = ref<Team | undefined>(toValue(team));

  watch(() => unref(team), (newTeam) => {
    if (newTeam) {
      localTeam.value = toValue(newTeam);
      reset();
    }
  });

  const hasData = computed(() => !!dataResponse.data && dataResponse.data.length > 0);

  const canLoadMore = computed(() => {
    if (!!dataResponse?.meta && !!dataResponse?.meta?.currentPage && !!dataResponse?.meta?.lastPage) {
      return dataResponse?.meta && dataResponse?.meta?.currentPage < dataResponse?.meta?.lastPage;
    }

    return false;
  });

  const numberToPlacehold = computed(() => {
    if (!dataRequested.value && !!localTeam.value && !!localTeam.value.sitesCount) {
      return localTeam.value.sitesCount < 12 ? localTeam.value.sitesCount : 12;
    }

    if (!!dataResponse.meta?.total && !!dataResponse.meta?.to && !!dataResponse.meta?.perPage) {
      const remainingItems = dataResponse.meta?.total - dataResponse.meta?.to;
      return Math.min(dataResponse.meta.perPage, remainingItems > 0 ? remainingItems : 0) || 0;
    }

    return 0;
  });

  const requestData = async () => {
    if (isRequestingData.value || !localTeam.value) {
      return;
    }

    try {
      isRequestingData.value = true;

      const response = await api.organization.general.sites({
        page: currentPage.value,
        per_page: 20,
        team: [localTeam.value.id],
        order: { visited_at: 'desc' },
        options: {
          cancellable: false,
        },
      });

      currentPage.value = currentPage.value + 1;

      dataResponse.data!.push(...response.data);
      dataResponse.meta = response.meta;
    } catch (error) {
      console.error('Error fetching sites:', error);
      return undefined;
    } finally {
      isRequestingData.value = false;
      dataRequested.value = true;
    }
  };

  const { isLoading: isLoadingMore } = useInfiniteScroll(
    loadMoreTrigger,
    async () => {
      if (!isLoadingMore.value && canLoadMore.value) {
        await requestData();
      }
    },
    { distance: 0, interval: 1000 },
  );

  return {
    dataRequested,
    currentPage,
    dataResponse,
    hasData,
    canLoadMore,
    numberToPlacehold,
    requestData,
    isLoadingMore,
    reset,
  };
}
