<script lang="ts">
import { defineComponent } from 'vue';

/**
 * @deprecated since v0.30
 */
export default defineComponent({
  name: 'VNav',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    role: {
      type: String,
      required: false,
      default: null,
    },
  },
});
</script>

<template>
  <ul :id="id" :role="role" class="nav">
    <slot />
  </ul>
</template>
