<script lang="ts" setup>
import { TabsList, type TabsListProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<TabsListProps & { class?: HTMLAttributes['class'], size?: '' | 'lg' }>();

const delegatedProps = computed(() => {
  const { class: _, size, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <TabsList
    class="ui-tabs-list position-relative d-inline-flex align-items-center justify-content-center"
    :class="[props.class,
             {
               'ui-tabs-list-lg': size === 'lg',
             },
    ]"
    v-bind="delegatedProps"
  >
    <slot />
  </TabsList>
</template>
