<script lang="ts" setup>
import type { SiteItem } from '@/types/models/site/updater/SiteItem';
import type { SiteItemChangelog } from '@/views/manager';

import api from '@/api';
import useModalUtils from '@/composables/useModal';
import usePermissions from '@/composables/usePermissions';
import useManagerStore from '@/stores/manager/managerStore';
import useOrganizationStore from '@/stores/organizationStore';

import useSiteStore from '@/stores/site/siteStore';

import { SiteStatusEnumConst } from '@/types/enums/SiteStatusEnum';

import TheManagerActions from '@/views/manager/parts/TheManagerActions.vue';
import TheManagerChangelogModal from '@/views/manager/parts/TheManagerChangelogModal.vue';
import TheManagerEmptyStates from '@/views/manager/parts/TheManagerEmptyStates.vue';
import TheManagerFilters from '@/views/manager/parts/TheManagerFilters.vue';
import TheManagerUpdateComponent from '@/views/manager/parts/TheManagerUpdateComponent.vue';
import TheManagerVulnerabilityModal from '@/views/manager/parts/TheManagerVulnerabilityModal.vue';
import { go as fuzzysortGo } from 'fuzzysort';
import { storeToRefs } from 'pinia';
import { computed, type ComputedRef, onBeforeUnmount, ref, watch } from 'vue';
import { PermissionEnumConst } from '@/types/PermissionEnum';
import type { IComponent } from '@/types/models/organization/manager/Component';

defineOptions({
  name: 'TheUpdateManager',
});

withDefaults(
  defineProps<{
    id?: string
  }>(),
  {
    id: 'update-manager',
  },
);
const { closeModal, openModal, modalToShow } = useModalUtils();
const { can } = usePermissions();

const siteStore = useSiteStore();
const organizationStore = useOrganizationStore();

const managerStore = useManagerStore();
const {
  newSynchronizedSites,
  isGlobal,
  syncSitesRequested,
  loadedComponents,
  loadedAllManager,
  componentsMap,
  componentSelection,
  changedFilters,
} = storeToRefs(managerStore);

const currentItemToModal = ref<any | null>(null);
const search = ref('');
const newSyncSites = ref(false);

const forceReloadItems = ref(false);

const { ORGANIZATION_UPTIME_NOTIFICATION_SMS } = PermissionEnumConst;

const someItemProcessing: ComputedRef<boolean> = computed(() => {
  return Array.from(componentsMap.value.values()).some((c: IComponent) => !!c.processing && c.processing > 0);
});

const debouncedSearch = useDebounce(search, 300);

const componentsFiltered = computed(() => {
  return fuzzysortGo(debouncedSearch.value, Array.from(componentsMap.value.values()), {
    key: 'name',
    all: true,
    threshold: 0.5,
  }).map(result => ({
    ...result.obj,
    name: result.highlight(),
  }));
});

function showBanner(index: number) {
  if (index !== 8)
    return;

  return isGlobal.value && !can(ORGANIZATION_UPTIME_NOTIFICATION_SMS) && componentsFiltered.value.length >= 10;
}

async function loadComponents(checkSelection = false, noLoadedComponents = true) {
  try {
    if (noLoadedComponents) {
      loadedComponents.value = false;
    }

    await managerStore.loadComponents(checkSelection);
    newSyncSites.value = false;
    await managerStore.loadComponentsMetas();
  } catch (e: any) {
    if (e?.code !== 'ERR_CANCELED') {
      console.error(e);
    }
  }
}

async function loadInitData(checkSelection = false, noLoadedComponents = true) {
  try {
    await Promise.all([managerStore.loadGlobalMetas(), loadComponents(checkSelection, noLoadedComponents)]);
  } catch (e: any) {
    if (e?.code !== 'ERR_CANCELED') {
      console.error(e);
    }
  } finally {
    loadedAllManager.value = true;
  }
}

function handleOpenChangelogModal(item: SiteItemChangelog) {
  if (!!item) {
    currentItemToModal.value = item;
    openModal('update-item-changelog-modal');
  }
}

function handleOpenVulnerabilityModal(item: SiteItem) {
  if (!!item) {
    currentItemToModal.value = item;
    openModal('update-item-vulnerability-modal');
  }
}

async function handleSetAllComponents(value: boolean) {
  componentSelection.value.forEach((c) => {
    const componentFound = componentsMap.value.get(c.id);
    if (!componentFound)
      return;

    c.all = value;
    c.check = value ? 'all' : 'empty';
    c.included = [];
    c.excluded = [];
  });

  await managerStore.loadComponentSelection();
}

function reloadManagerData() {
  newSyncSites.value = true;
  organizationStore.manager();
  loadInitData(false, false);

  newSynchronizedSites.value = false;
}

const unUpdatedModularPluginCount = ref(0);

async function loadModularUnUpdatedPluginCount() {
  try {
    unUpdatedModularPluginCount.value = await api.organization.general.sitesSelectionCount({
      only: ['connector_updatable'],
      connection_status: [SiteStatusEnumConst.SUCCESS],
    });
  } catch (e: any) {
    console.error(e);
  }
}

watch(
  () => componentSelection.value,
  async () => {
    await managerStore.getSelectedCount();
  },
  { deep: true },
);

watch(
  () => siteStore.currentSite,
  (value, oldValue) => {
    if (!oldValue && !!value && value.connectionStatus !== SiteStatusEnumConst.SUCCESS) {
      loadedComponents.value = true;
    }

    if (!isGlobal.value && !!value && value.connectionStatus === SiteStatusEnumConst.SUCCESS) {
      if (!!oldValue && oldValue.connectionStatus !== SiteStatusEnumConst.SUCCESS) {
        syncSitesRequested.value = true;
      }

      if (!oldValue) {
        loadInitData();
      }
    }
  },
  { immediate: true },
);

let checkItemsProcessingIntervalId: any;

watch([someItemProcessing, loadedAllManager], ([isProcessing, isLoaded]) => {
  if (isLoaded) {
    if (isProcessing) {
      checkItemsProcessingIntervalId = setInterval(() => {
        forceReloadItems.value = true;
      }, 3 * 60 * 1000);
    } else {
      forceReloadItems.value = false;

      if (checkItemsProcessingIntervalId) {
        clearInterval(checkItemsProcessingIntervalId);
      }
    }
  }
}, { immediate: true });

onMounted(() => {
  if (isGlobal.value) {
    loadInitData();
    loadModularUnUpdatedPluginCount();
  }
});

onBeforeUnmount(() => {
  if (checkItemsProcessingIntervalId) {
    clearInterval(checkItemsProcessingIntervalId);
  }

  managerStore.reset();
});
</script>

<template>
  <div :id="id" :class="{ 'mb-32': !isGlobal }" class="update-manager">
    <v-alert
      v-if="unUpdatedModularPluginCount > 0"
      variant="tiger"
      classes="mb-16"
      show-icon
      icon-class="text-xl"
    >
      <p class="fw-medium text-sm mb-0">
        {{ $t('manager.connectorNotUpdated') }}
      </p>
    </v-alert>

    <section
      :class="{ 'site-update-manager': !isGlobal }"
      class="update-manager-container w-100 bg-light d-flex flex-column px-24 pt-16 shadow-sm position-relative"
      :style="{ height: isGlobal ? `calc(100vh - ${unUpdatedModularPluginCount > 0 ? '17rem' : '11rem'})` : '' }"
    >
      <TheManagerFilters
        v-model:search="search"
        @changed-filter="loadInitData(true)"
        @changed-type="search = ''; loadComponents()"
        @changed-status="loadInitData()"
        @sync-sites-requested="syncSitesRequested = true"
        @clear-filters="
          managerStore.clearFilters();
          loadInitData(true);
        "
      />

      <m-loader
        id="manager-loader"
        :model-value="syncSitesRequested || newSyncSites"
        :animate-on-mount="false"
        class="position-absolute start-0 end-0 bottom-0 h-auto"
        size="sm"
      />

      <div
        :class="{
          'loading': syncSitesRequested || newSyncSites,
          'empty h-100': loadedComponents && componentsFiltered.length === 0,
        }"
        class="manager-component-container pb-12"
      >
        <template v-if="loadedComponents || (!isGlobal && !siteStore.currentSite) || forceReloadItems">
          <div v-auto-animate class="d-flex justify-content-center">
            <VButton
              v-if="newSynchronizedSites || forceReloadItems"
              :aria-label="$t('manager.refreshItems')"
              class="btn-pill btn-primary d-flex align-items-center mb-8 px-16 py-8"
              size="sm"
              variant="primary-dark"
              @click="reloadManagerData()"
            >
              <m-icon icon="refresh" size="md" class="fw-bold me-4" />
              <span class="text-nowrap">{{ $t('manager.refreshItems') }}</span>
            </VButton>
          </div>

          <ul
            v-if="!!componentsFiltered && componentsFiltered.length > 0"
            v-auto-animate
            class="m-0 p-0 pe-8"
            data-cy="manager-component-list"
          >
            <li v-for="(component, index) in componentsFiltered" :key="component.id" class="list-group-item">
              <TheManagerUpdateComponent
                :class="{ 'border-bottom-0': showBanner(index) || index === componentsFiltered.length - 1 }"
                :model-value="component"
                @open-changelog="handleOpenChangelogModal"
                @open-vulnerability="handleOpenVulnerabilityModal"
              />

              <v-upgrade-free-user-banner
                v-if="showBanner(index)"
                title="manager.freeBanner.title"
                description="manager.freeBanner.description"
                track-name="Manager banner"
              />
            </li>
          </ul>

          <template v-else>
            <TheManagerEmptyStates :changed-filters="changedFilters || !!search" />
          </template>
        </template>

        <ul v-else class="m-0 p-0" data-cy="manager-component-list">
          <li v-for="n in 5" :key="n" class="d-flex py-16 pe-24 ps-8">
            <span class="placeholder-glow w-100">
              <span class="placeholder w-100" style="height: 20px" />
            </span>
          </li>
        </ul>
      </div>
    </section>

    <TheManagerActions
      v-if="
        (loadedComponents || (!isGlobal && !siteStore.currentSite))
          && !!componentsFiltered
          && componentsFiltered.length > 0
      "
      @select-all="handleSetAllComponents"
    />

    <TheManagerChangelogModal
      v-if="modalToShow === 'update-item-changelog-modal' && !!currentItemToModal"
      :item="currentItemToModal"
      @closed="closeModal"
    />

    <TheManagerVulnerabilityModal
      v-if="modalToShow === 'update-item-vulnerability-modal' && !!currentItemToModal"
      :item="currentItemToModal"
      @closed="closeModal()"
    />

    <router-view v-slot="{ Component, route }">
      <template v-if="route.name === 'sites.site.manager.component.install' || route.name === 'manager.component.install'">
        <Component :is="Component" @installed="loadInitData" />
      </template>
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
.update-manager-container {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.site-update-manager {
    height: 30rem;
  }
}

.manager-actions {
  box-shadow: -26.2px -37.8px 88px 0px rgba(146, 119, 191, 0.16),
  -37.8px 37.8px 37.8px 0px rgba(255, 255, 255, 0.16);
}

#manager-loader {
  top: 8.125rem;
}

.manager-component-container {
  &:not(.empty) {
    flex-grow: 1;
    overflow-y: auto;
  }

  &.loading {
    opacity: 0.6;
  }
}
</style>
