export default {
  title: 'Notifications',
  titleCount: '{num} setting | {num} settings',
  empty: {
    create: 'Create setting',
    title: 'Custom notification settings',
    description: 'Create custom configurations with different types of notifications through the channel of your choice and assign them to your websites to be always up to date with what\'s happening on them.',
    alertingReason1: 'What\'s important to you',
    alertingDescription1: 'Choose which notifications you want to receive, at what time and on which devices. You can create as many different configurations as you want.',
    alertingReason2: 'Stay informed about everything',
    alertingDescription2: 'Add to your notification settings backup errors, website crashes or new critical vulnerabilities detected.',
    emptyYTLink: 'https://www.youtube.com/embed/2mOosdoG9DI?si=QDUBmi6UOoqt_Vmy',
    emptyYTLinkHref: 'https://youtu.be/2mOosdoG9DI?si=gPPymlYKKszgmR42',
  },
  delete: {
    modal: {
      title: 'Do you want to delete the configuration {name}?',
      text:
        'If you delete this setting, you will no longer receive notifications about the events you have configured. Are you sure you want to delete it?',
    },
    toast: {
      success: 'The configuration has been successfully deleted',
      error: 'An error occurred while deleting the configuration',
    },
  },
  table: {
    stateAndName: 'State and name',
    name: 'Name',
    contact: 'Method of contact',
    type: 'Type',
    medium: 'Medium',
    sites: 'Webssites',
    phone: 'Phone',
    emailAndPhone: 'Email, phone',
    email: 'Email',
    app: 'Only App',
    disableAlert: 'Disable alert',
    enableAlert: 'Enable alert',
    default: 'Default',
    removeAsDefault: 'Remove as default',
    setAsDefault: 'Set as default',
    defaultTooltip: 'This alert will be associated with all new websites you add.',
    showConfiguration: 'View configuration',
  },
  featureHelp: {
    title: 'Learn all about notification settings',
    description: 'We tell you what Modular\'s notification settings are, what they are for, and how you can create them for your websites.',
    watchVideo: 'Watch video',
  },
  create: {
    availableSMS: 'Available by SMS',
    siteSelector: {
      applyToNewDescription: 'Apply this setting to all new websites you add.',
      applyToNewLabel: 'Apply to new websites',
      name: 'Name',
      team: 'Team',
      noSites: 'We have not found any websites',
      sitesSelected: 'Selected sites',
    },
    steps: {
      step1: {
        title: 'General configuration',
        description: 'Name, active notifications...',
        subtitle: 'Notifications',
        subtitleDescription: 'All communications will be notified through the application and by email (if a forwarding address is set up), unless otherwise indicated below.',
        hasAnyAlertEnabledAlert: 'You must select at least one notification',
        form: {
          alertName: 'Setting name',
        },
      },
      step2: {
        title: 'Websites',
        description: 'To which websites does it apply',
        subtitle: 'Website selection',
      },
      step3: {
        title: 'Delivery',
        description: 'How do you want to receive notifications',
        subtitle: 'Delivery channels',
        smsValidationError: 'Enter a valid phone number',
        smsRequiredValidationError: 'If you want to receive notifications via SMS, you must enter a phone number',
        prefixRequiredValidationError: 'You must enter a prefix',
        emailRequiredValidationError: 'If you want to receive notifications via email, you must enter at least one email',
      },
    },
    toast: {
      success: 'The configuration has been saved correctly',
    },
  },
  toast: {
    status: {
      success: 'Configuration status changed correctly.',
      error: 'An error occurred while editing the configuration.',
    },
  },
  statusItems: {
    connector: 'Connection',
    backupFailed: 'Failed backup',
    backupFailedDescription: 'We will notify you when a backup cannot be completed successfully.',
    connectorSiteDisconnected: 'Disconnected website',
    connectorSiteDisconnectedDescription: 'We will notify you when one of your websites loses connection with Modular',
    reportAutomaticGenerated: 'Error in the generation of a scheduled report',
    reportAutomaticGeneratedDescription: 'We will notify you when there is an error in the generation of a scheduled report.',
    sslCertExpirationWarning: 'SSL certificate expiration warning',
    sslCertExpirationWarningDescription: 'We will notify you when the SSL certificate is about to expire. You must have it active in your site\'s Uptime settings.',
    uptimeDowntime: 'Website down',
    uptimeDowntimeDescription: 'We will notify you when we detect that your website is down based on your Uptime settings.',
    uptimeUptime: 'Website up and running again',
    uptimeUptimeDescription: 'We will notify you when the website is back up and running.',
    vulnerabilityDiscoveryDate: 'New vulnerability detected',
    vulnerabilityDiscoveryDateDescription: 'We will notify you when we find a new vulnerability on your website.',
  },
  sending: {
    email: 'Email',
    emailText: 'Choose as many email addresses as you want to receive notifications.',
    sms: 'SMS',
    smsText: 'Choose a phone number to receive your notifications.',
    smsTextExtra: '<span class="fw-semi">Important</span>: Only critical Uptime notifications will be sent by SMS. Website down and website up and running again.',
  },
};
