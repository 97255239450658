<script lang="ts" setup>
import { ScrollAreaCorner, ScrollAreaRoot, type ScrollAreaRootProps, ScrollAreaViewport } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

import ScrollBar from './ScrollBar.vue';

// import { cn } from '@/lib/utils'

const props = defineProps<ScrollAreaRootProps &
  {
    class?: HTMLAttributes['class']
    barSize?: '' | 'sm'
    maxHeight?: string
  }>();

const delegatedProps = computed(() => {
  const { class: _, asChild: __, barSize: ___, maxHeight, ...delegated } = props;

  return delegated;
});

const computedMaxHeight = computed(() => {
  return props.maxHeight ? { maxHeight: props.maxHeight } : {};
});
</script>

<template>
  <ScrollAreaRoot
    class="ui-scroll-area-root position-relative"
    :class="[props.class]"
    v-bind="delegatedProps"
    :style="computedMaxHeight"
  >
    <ScrollAreaViewport
      class="ui-scroll-area h-100 w-100"
      :style="computedMaxHeight"
    >
      <slot />
    </ScrollAreaViewport>
    <ScrollBar :bar-size="barSize" />
    <ScrollAreaCorner />
  </ScrollAreaRoot>
</template>

<style lang="scss" scoped>
.ui-scroll-area-root {
  overflow: hidden;
}

.ui-scroll-area {
  border-radius: inherit;
}
</style>
