import { AbstractService } from '@modular/sdk';
import { License } from '@/types/models/organization/license/License';
import { apiEndpoints } from '@/types/routes/routes';

export default class LicensesService extends AbstractService {
  /**
   * Returns a list of all active subscriptions of the organization.
   *
   * @returns {Promise<any[]>}
   */
  public async all(): Promise<License> {
    const licenses = await this.request({
      method: apiEndpoints.licensesIndex.method,
      url: this.buildPath(apiEndpoints.licensesIndex),
    });

    return licenses.find((license: License) => license.status === 'enabled');
  }
}
