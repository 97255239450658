import type { App, ComponentPublicInstance, ComputedOptions, MethodOptions, Plugin, Ref } from 'vue';
import { inject, reactive } from 'vue';

export type TextLayout =
  | Ref<ComponentPublicInstance<any, any, any, ComputedOptions, MethodOptions>>
  | string
  | undefined;

export interface IModalOptions {
  title?: string
  text?: string
  subtitle?: string
  alertTitle?: string
  alertVariant?: string
  textLayout?: TextLayout
  textLayoutClasses?: string
  showCloseX?: boolean
  showAlertTitle?: boolean
  backdropStatic?: boolean
  showBackdrop?: boolean
  textClasses?: string
  modalClasses?: string
  modalSize?: string
  subtitleClasses?: string
  rightButtonText?: string
  rightButtonClasses?: string
  rightButtonLoadingText?: string
  rightButtonDisabled?: boolean
  rightButtonCallback?: () => Promise<void> | void
  leftButtonText?: string
  leftButtonClasses?: string
  leftButtonLoadingText?: string | boolean
  leftButtonDisabled?: boolean
  leftButtonCallback?: () => Promise<void> | void
  closeModalCallback?: () => Promise<void> | void
  closeOnActionCallback?: boolean
  alertDescriptionElement?: () => any
}

export enum ModalType {
  DELETE = 'delete',
  GO_PRO = 'goPro',
  CONFIRM = 'confirm',
}

export interface IModalState {
  isVisible: boolean
  type: ModalType | null
  options: any
  open: (type: ModalType, options?: IModalOptions) => void
  delete: (options?: IModalOptions) => void
  confirm: (options?: IModalOptions) => void
  goPro: (options?: IModalOptions) => void
  close: () => void
}

const ModalSymbol = Symbol.for('modalState');

/**
 * @returns {IModalState}
 */
export function getModalInjectionkey(): IModalState {
  const modal = inject<IModalState>(Symbol.keyFor(ModalSymbol)!);

  if (!modal) {
    throw new Error('No Modal provided.');
  }

  return modal;
}

function provideModalController(app: App) {
  const state = reactive<IModalState>({
    isVisible: false,
    type: null,
    options: {},
    open: (type: ModalType, options: IModalOptions = {}) => {
      state.type = type;
      state.options.value = options;
      state.isVisible = true;
    },
    delete: (options: IModalOptions = {}) => {
      state.type = ModalType.DELETE;
      state.options.value = options;
      state.isVisible = true;
    },
    confirm: (options: IModalOptions = {}) => {
      state.type = ModalType.CONFIRM;
      state.options.value = options;
      state.isVisible = true;
    },
    goPro: (options: IModalOptions = {}) => {
      state.type = ModalType.GO_PRO;
      state.options.value = options;
      state.isVisible = true;
    },
    close: () => {
      state.isVisible = false;
    },
  });

  app.provide(Symbol.keyFor(ModalSymbol)!, state);

  return state;
}

const createModal: Plugin = {
  install(app: App) {
    const state = provideModalController(app);
    app.provide('$modal', state);
    app.config.globalProperties.$modal = state;
  },
};

export default createModal;
