<script lang="ts" setup>
import { ComboboxInput, type ComboboxInputProps, useForwardProps } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<ComboboxInputProps & {
  class?: HTMLAttributes['class']
  size?: 'sm' | ''
  spacing?: string
  type?: '' | 'transparent'
}>(), {
  class: '',
  size: '',
  spacing: 'px-16 py-4',
  type: '',
});

const delegatedProps = computed(() => {
  const { class: _, size: __, type: ___, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <div class="position-relative">
    <ComboboxInput
      :class="[
        props.class,
        {
          'form-control-sm rounded-sm text-sm': size === 'sm',
          'bg-transparent': type === 'transparent',
        },
        spacing,
      ]"
      class="ui-combobox-input form-control w-100 outline-none shadow-none px-16 py-8"
      v-bind="{ ...forwardedProps, ...$attrs }"
    />
    <slot name="cancel" />
  </div>
</template>
