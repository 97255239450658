export default {
  goToAnalytics: 'Go to Analytics',
  noProperties: 'It appears that your account does not have any {property} properties associated.',
  noAccount: 'It appears that your Google account does not have this {property} account associated with it.',
  selectProperty: 'Select an Analytics property',
  selectPropertyText:
    'You must select a Google Analytics account and property in order to synchronize your data. Only compatible with GA4 properties. ',
  changeProperty: 'Change the Analytics property',
  changePropertyText:
    'You can change the Google Analytics account or property you have chosen from the accounts and properties your Google account has access to.',
  selectAccount: 'Select an account',
  property: 'Property',
  view: 'View',
  noResults: 'No results for {search}',
  connectModular: 'Connect Modular with Google Analytics to start viewing your stats in the app (for now)',
  loginGoogle: 'Connect Google account',
  confirmingConnectionTitle: 'Confirming with Google',
  lastDays: '{days} days',
  unlinkAccount: 'Unlink this account from Analytics',
  unlinkAccountShort: 'Unlink account',
  unlinkAccountExplain:
    'You are going to unlink your Analytics account for this website. Your report will not be avaliable in Modular until you reconnect your Analytics account. Are you sure?',
  ga4Selector: '{name} (Google Analytics V4)',
  mayLateMinutes: 'It may take 5-10 minutes',
  calculating: 'Calculating report...',
  accountInformation: 'Account information',
  googleAccount: 'Google Account',
  analyticsAccount: 'Analytics Account',
  changeGoogleAccount: 'Change Google account',
  changeAnalyticsPropertyt: 'Change Analytics property',
  reportError:
    'Wops! There seems to be a problem getting the Analytics report. Please check your Google account settings and try again.',
  empty: {
    emptyYTLink: 'https://www.youtube.com/embed/eq5Lstap0s4',
    title: 'Google Analytics',
    emptyText:
      'Connect your Google Analytics account to show in Modular the main data of your client\'s website and thus be able to add them to the reports. Both with Google Analytics Universal and Google Analytics 4 properties.',
    statisticsTitle1: 'Basic statistics for your client',
    statisticsReason1:
      'Make your client understand how their website is performing. How many people visit it, from where they do it and which pages are the most successful.',
    statisticsTitle2: 'Connected to reports',
    statisticsReason2:
      'Once you connect your Google Analytics account, you can also add the stats to the client reports.',
  },
};
