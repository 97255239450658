export default {
  overview: 'Visión global',
  errorContacts: 'Debes añadir al menos un destinatario para continuar',
  errorLogoFile: 'Debes añadir un logo para continuar',
  errorTitle: 'Debes añadir un nombre para continuar',
  reports: 'Reportes',
  report: 'Reporte',
  newReport: 'Nuevo Reporte',
  createReport: 'Crear reporte',
  reportsImage: 'Imagen de reportes',
  makeWorkStandOut: 'Haz que tu trabajo destaque',
  makeWorkStandOutDescription:
    'Saca el máximo partido a tu trabajo enviando reportes automáticos a tus clientes con estadísticas, detalles de las tareas de mantenimiento y mucho más...',
  firstReport: '¡Crea tu primer reporte!',
  firstReportDescription:
    'Crea un equipo para comenzar a construir tus webs con ModularDS. Puedes crear tantos equipos de trabajo y webs como necesites.',
  totalReports: '{total} Reportes',
  totalReportsSent: 'Enviados ({total})',
  totalReportsPendingShipment: 'Pendiente de envío ({total})',
  sent: 'Enviado',
  draft: 'Borrador',
  reportPeriod: 'Periodo del reporte',
  statusAndActions: 'Estado y acciones',
  status: 'Estado',
  sending: 'Envío',
  send: 'Enviar',
  searchReport: 'Buscar reporte',
  searchTask: 'Buscar tarea',
  generalConfiguration: 'Configuración general',
  generalConfigurationDescription: 'Nombre, personalización...',
  content: 'Contenido',
  contentDescription: 'Selecciona lo que incluye',
  reportingShipping: 'Reporte y envío',
  reportingShippingDescription: 'PDF, envío, programación...',
  reportName: 'Nombre del reporte',
  language: 'Idioma',
  selectLanguage: 'Selecciona un idioma',
  dates: 'Fechas',
  lastWeek: 'Última semana',
  lastQuarter: 'Último trimestre',
  customize: 'Personalizar',
  template: 'Plantilla',
  included: 'Incluida',
  logo: 'Logotipo de tu cliente',
  logoRecommendedSize:
    'Añade el logo de la web del reporte para que aparezca en el centro de la portada (tamaño recomendado 800x800px).',
  addImage: 'Agregar imagen',
  changeImage: 'Cambiar imagen',
  addImageDescription: 'o arrastra y suelta la imagen aquí',
  mainColor: 'Color principal',
  mainColorDescription:
    'Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra. Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra',
  secondaryColor: 'Color secundario',
  secondaryColorDescription:
    'Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra. Egestas aliquet netus venenatis lectus mauris, nibh vulputate a, nunc sit pharetra',
  offBranding: 'Personaliza el reporte con tu logo',
  offBrandingDescription:
    'Añade tu logo para sustituir el branding de “Creado con Modular” en las páginas del reporte (tamaño recomendado 800x800px).',
  offBrandingEmptyStateTitle: 'Branding personalizado',
  offBrandingEmptyStateDescription:
    'Hazte PRO para añadir tu logo y sustituir el branding de “Creado con Modular” en las páginas de tu reporte.',
  welcomeTitle: 'Mensaje de bienvenida',
  welcomeDescription: 'Escribe aquí el mensaje de bienvenida para que tu cliente lea en la primera página del reporte.',
  closingTitle: 'Mensaje final',
  closingDescription:
    'Escribe las conclusiones o despedida que quieras que aparezca al final del reporte para tu cliente.',
  messageContent: 'Contenido del mensaje',
  introductionReportTitle: 'Escribe el título de la introducción',
  introductionReportContent: 'Añade un pequeño texto explicativo para esta sección del reporte',
  introductionReportAnalyticsLogo: 'Incluir logo de Google Analytics',
  introductionReportSearchLogo: 'Incluir logo de Google Search Console',
  closingReportTitle: 'Escribe el título del cierre',
  closingReportContent: 'Introduce un mensaje final del reporte',
  summaryAndClosing: 'Resumen y Cierre',
  notes: 'Anotaciones',
  notesOrAnnexesText:
    '¿Has realizado otros trabajos que no has creado como tareas? ¿Quieres añadir algo para tu cliente? Aquí puedes escribir lo que necesites para que aparezca en el reporte.',
  addTask: 'Tareas adicionales',
  addTaskDescriptionScheduled:
    'Al activar esta opción, se incluirán en el reporte las tareas adicionales que hayas ido introduciendo en la sección de “Tareas adicionales” que hay en la página de reportes y que correspondan con las fechas o el periodo concreto que va a recoger el reporte.',
  addTaskDescriptionScheduled2Line:
    'Incluir tareas adicionales te permite dar valor a tu trabajo de cara al cliente y comunicar con mayor precisión todas esas cosas que haces en el día a día para tus clientes y que no siempre se visibilizan.',
  addTaskDescriptionManual:
    'Al activar esta opción se incluirán en el reporte las tareas que hayas introducido en la sección de “Tareas adicionales” y que coincidan con las fechas marcadas en el reporte.',
  addTaskNotTask:
    'No tienes tareas adicionales en el periodo seleccionado. Para más información sobre las tareas adicionales <a href="https://soporte.modulards.com/es/gestor-de-tareas-reportes" id="task-help" target="_blank" aria-label="Abrir soporte tareas" rel="noreferrer noopener">pulsa aquí</a>.',
  viewTasks: 'Ver las tareas ({count})',
  selectTheSections: 'Selecciona las secciones que quieres en tu reporte',
  performance: 'Performance',
  uptime: 'Activa Uptime monitor',
  uptimeText: 'Mantente alerta de todo lo que ocurra con tu web, monitoriza su actividad y recibe alertas',
  analytics: 'Analytics',
  updates: 'Updates',
  annotationTitle: 'Título de la anotación',
  addAnnotation: 'Añadir anotación',
  unverified: 'Sin verificar',
  pendingConnection: 'Pendiente de conectar',
  pendingEnableFeature: 'Pendiente de activar',
  verified: 'Verificado',
  sectionIntroductionTitle: 'Introducción de la sección:',
  taskIntroductionTitle: 'Mensaje para el cliente',
  taskIntroductionText: 'Introduce una descripción del trabajo realizado',
  backups: {
    noBackupsShort: 'Configura las copias de seguridad',
    noBackupsConfigure:
      'Configura los backups para esta web. Recurrencia, hora o día de la semana, directorios y archivos a copiar, etc...',
  },
  customWork: {
    addWork: 'Añadir anotación',
    hoursSpent: 'Horas dedicadas',
    addImages: 'Adjuntar imágenes',
  },
  emptyState: {
    emptyYTLink: 'https://www.youtube.com/embed/zSEOlevUC5M',
    reportDescription:
      'Demuestra a tu clientes el valor de tu trabajo generando reportes que recojan todas las tareas y datos de su web. Actualizaciones realizadas, copias de seguridad, resumen de Uptime, estadísticas de Google Analytics y resumen de rendimiento.',
    reportTitle1: 'Un reporte completo y versatil',
    reportReason1:
      'Elige qué secciones quieres mostrar en el reporte y cuales no y en apenas un par de minutos generaremos toda la información referente a la web y las tareas y estadísticas de la misma.',
    reportTitle2: 'Personalízalo con tu marca',
    reportReason2:
      'Añade tu logo y el de tu cliente a la plantilla de Modular y personaliza el reporte para que se sienta 100% tuyo.',
    reportTitle3: 'Añade cualquier tarea extra',
    reportReason3:
      'Con el módulo de trabajos a medida podrás añadir cualquier tarea que hayas realizado incluyendo nombre, descripción, fecha, horas empleadas y hasta capturas de pantalla.',
    reportTitle4: 'Visualización en formato PDF o web',
    reportReason4:
      'Con Modular puedes generar tus reportes en PDF y mandar el archivo a tus clientes o enviarles directamente un link para que lo vean en formato web. Para que te diferencies de la competencia.',
    taskPretitle:
      'Las tareas te permiten compartir con tus clientes actuaciones puntuales y específicas del mantenimiento de sus webs.',
    taskPretitleDescription: 'Crea tantas tareas como actuaciones o actividades de mantenimiento necesites',
  },
  deleteReportTitle: '¿Quieres eliminar este reporte? | ¿Quieres eliminar todos los reportes selecionados?',
  deleteReportText:
    'Si eliminas este reporte de Modular no podrás volver a acceder a él desde la herramienta. Te recomendamos descargarlo previamente para no perderlo. | Si eliminas estos reportes de Modular no podrás volver a acceder a ellos desde la herramienta. Te recomendamos descargarlos previamente para no perderlos.',
  deleteReportScheduleTitle: '¿Quieres eliminar esta programación?',
  deleteReportScheduleText:
    'Si eliminas esta programación de reportes de Modular no podrás volver a acceder a ella desde la herramienta.',
  deleteReportCompleted: 'El reporte se ha borrado correctamente',
  deleteReportScheduleCompleted: 'La programación se ha borrado correctamente',
  finalSection: {
    yourReport: 'Tu reporte',
    yourReportText:
      'Aquí tienes el reporte de tu web. Puedes descargarlo en PDF o verlo y compartirlo en formato web para que cualquiera lo pueda ver sin descargarlo.',
    recipientsTitle: 'Destinatarios',
    recipientsDescription: 'A quién quieres enviar el reporte.',
    sendDetailsTitle: 'Detalles de Envío',
    sendDetailsText: 'Elige si quieres que el informe se envíe automáticamente cada vez que se genere.',
    sendMessageTitle: 'Mensaje',
    sendMessageText: 'Escribe el nombre del remitente del email, el asunto y el texto que quieres incorporar. ',
    viewReport: 'Ver reporte',
    sendNow: 'Enviar ahora',
    scheduleSend: 'Programar reporte/envío automático',
    emailFrom: 'Nombre del remitente',
    emailFromPlaceholder: 'Remitente',
    emailSubject: 'Asunto del email',
    emailSubjectPlaceholder: 'Asunto',
    emailBody: 'Cuerpo del mensaje',
    emailBodyPlaceholder: 'Introduce el contenido del mensaje que quieras enviar',
    emailBodyPdf: 'Enviar PDF como archivo adjunto',
    notReadyReport: 'Parece que tu reporte todavía no está listo...',
    notReadyReportDescription:
      'Este proceso puede tardar unos minutos. Si sales podrás consultar el estado desde el listado de reportes.',
    reportErrorStatusTitle: 'Ha habido un error al generar tu reporte',
    reportErrorStatusText:
      'Alguna de las secciones del reporte no se ha podido crear. Puedes intentar generar un reporte nuevo o contactar con nosotros en <a href=\'mailto:{url}\' aria-label=\'Contactar por correo electrónico\'>{url}</a> para que podamos ayudarte a resolverlo.',
    smallPreviewReport: 'Esto es una pequeña preview de tu reporte',
    noSend: 'No enviar',
    sendEmail: 'Enviar por email',
    addRecipients: 'Añadir destinatarios',
    alertMessage:
      'Indica dónde quieres que aparezca el link al reporte usando: [report_link]<br/>Si no lo añades o no marcas la opción de archivo, aparecerá al final del cuerpo del mensaje.',
    generateReportSend: 'Programar reporte',
    errorEmailSubject: 'El asunto del email es obligatorio',
    errorEmailMessage: 'El cuerpo del email es obligatorio',
    sendingAddress: 'Dirección desde la que se envía',
    sendingAddressDescription: 'La dirección de correo que se mostrará como remitente en los reportes enviados',
    sendingAddressText:
      'Para enviar los emails desde un dominio personalizado, necesitas añadir y verificar uno en los ajustes generales de tu cuenta.',
    responseAddress: 'Dirección de respuesta',
    responseAddressDescription: 'Dirección a la que pueden responder los destinatarios.',
    goSettingsDomain: 'Ir a ajustes para cambiar el dominio',
  },
  generateReport: 'Generar reporte',
  scheduledReport: 'Programar reporte',
  generateReportDescription: 'Obtén tu reporte',
  generateScheduledReportDescription: 'Envío, destinatarios...',
  errorScheduleRange: 'Debe elegir un intervalo de fechas para continuar',
  errorScheduleHour: 'Debe elegir una hora para continuar',
  errorContentEmpty: 'Debe seleccionar al menos un elemento para continuar',
  generating: {
    almostReady: '¡Tu reporte casi está listo!',
    savingChanges: '¡Guardando tus cambios!',
    generatingReport: 'Estamos generando el reporte...',
    generated: '¡Tu reporte ha sido generado!',
    scheduled: '¡Reporte programado!',
    generatedDescription:
      'Aquí tienes el reporte de tu web. Puedes descargarlo en PDF o verlo y compartirlo en formato web para que cualquiera lo pueda ver sin descargarlo.',
    generateScheduleWeekly: 'Tu reporte se generará cada semana los { day }, empezando el { date }.',
    generateScheduleMonthly: 'Tu reporte se generará cada mes el día { day }, empezando el { date }.',
    generateScheduleDaily: 'Tu reporte se generará cada día a las { time }, empezando el { date }.',
    repeatEach: 'Repetir cada',
    saveAsScheduledReport: 'Guardar como reporte programado',
    convertReportRecurring: 'Convertir a reporte programado',
  },
  upcomingReports: {
    title: 'Reportes programados',
    newReport: 'Crear reporte programado',
    scheduleRecurrenceWeekly: 'Cada semana el { time }',
    scheduleRecurrenceDaily: 'Cada día a las { time }',
    scheduleRecurrenceMonthly: 'Cada mes el día { time }',
    scheduleRecurrenceQuarterly: 'Cada trimestre',
    knowMoreAboutScheduledReports: 'Saber más sobre los reportes programados',
    knowMoreAboutScheduledReportsLink: 'https://soporte.modulards.com/es/reportes-programados',
    emptyState: {
      title: 'Crea tu primer reporte programado',
      description:
        'Los clientes que reciben reportes programados entienden mejor tu trabajo y <span class="text-primary fw-medium">son más fieles.</span>',
    },
  },
  preview: {
    yourReport: 'Tu reporte',
    editReport: 'Editar reporte',
    shareReport: 'Compartir reporte',
  },
  yourReports: 'Tus reportes',
  stillNoReports: 'Todavía no se ha generado ningún reporte.',
  recurring: 'Programados',
  monthly: 'Mensuales',
  weekly: 'Semanales',
  annual: 'Anuales',
  noRecurrence: 'No programados',
  generatedLastYear: 'Generados el último año',
  generated: 'generado',
  generateds: 'Generados',
  recurringReport: 'Reporte programado',
  recurringReportDescription: 'Lo puedes programar para que se genere de forma programado cada mes, semana...',
  timelyReport: 'Reporte Puntual',
  timelyReportDescription: 'Una vez creado se genera una única vez.<br/>No se generará de manera recurrente.',
  generatingTitle: 'generando',
  becomeAPro: 'Hazte PRO aquí',
  reportDates: 'Fechas del reporte',
  reportData: 'Datos del reporte',
  reportFrequencyDescription:
    'El periodo de cada reporte comprenderá desde el { dayStart } a las 00:00 hasta el { dayEnd } a las 23:59',
  scheduleRecurrenceQuarterly: 'Cada 3 meses empezando el',
  updateReport: 'Actualizar reporte',
  quarterShort: '(T{ number })',
  thisWeek: 'Esta semana',
  custom: 'Personalizado',
  additionalTasks: 'Tareas adicionales',
  creatingReport: 'Creando reporte',
  editingReport: 'Editando reporte',
  previewReport: 'Previsualizar reporte',
  downloadReport: 'Descargar reporte',
  deleteReport: 'Eliminar reporte',
  error: 'error',
  schedule: {
    reportScheduleFrecuencyWeeklyCalc:
      'Se generará cada semana los {day}. Fecha del próximo reporte: {date} a las {hour}.',
    reportScheduleFrecuencyMonthlyCalc: 'Se generará cada mes. Fecha del próximo reporte: {date} a las {hour}.',
    reportScheduleFrecuencyQuarterlyCalc: 'Se generará cada trimestre. Fecha del próximo reporte: {date} a las {hour}.',
  },
  tasks: {
    task: 'Tarea',
    tasks: 'Tareas adicionales',
    createAdditionalTask: 'Crear tarea adicional',
    editAdditionalTask: 'Editar tarea adicional',
    successCreate: 'Tarea creada con éxito.',
    successEdit: 'Tarea editada con éxito.',
    introductionReportContent: 'Introduce una explicación del trabajo realizado',
    createTask: 'Crear tarea',
    tasksCreated: 'Tareas creadas ({ count })',
    taskCreatedDate: 'F. Creación',
    taskDate: 'F. Tarea',
    hours: 'Horas',
    deleteTaskTitle: '¿Quieres eliminar esta tarea? | ¿Quieres eliminar todas las tareas selecionados?',
    deleteTaskText:
      'Si eliminas esta tarea de Modular no podrás volver a acceder a ella. | Si eliminas estas tareas de Modular no podrás volver a acceder a ellas.',
    deleteTaskCompleted: 'La tarea se ha borrado correctamente',
  },
  details: {
    reportDetails: 'Detalles del reporte',
    creation: 'Creación',
    recurrence: 'Recurrencia',
    sendingDetails: 'Detalles del envío',
    recipients: 'Destinatarios',
    convertReportScheduled: 'Convertir reporte en programado',
    reportSections: 'Secciones que contiene el reporte',
    attemptSend: 'Intento de envío { date }',
    overview: 'Visión global',
    analytics: 'Estadísticas',
    performance: 'Rendimiento',
    upgrade: 'Actualizaciones',
    uptime: 'Uptime Monitor',
    backup: 'Copias de seguridad',
    task: 'Tareas adiccionales',
    sent: 'Enviado',
    received: 'Recibido',
    opened: 'Abierto {date}',
    error: 'Fallo en la entrega',
    saveRecurrence: 'Guardar recurrencia',
    searchConsole: 'Search Console',
  },
};
