<script lang="ts" setup>
import {
  SelectContent,
  type SelectContentEmits,
  type SelectContentProps,
  SelectPortal,
  SelectViewport,
  useForwardPropsEmits,
} from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';
import { SelectScrollDownButton, SelectScrollUpButton } from '.';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<SelectContentProps & { class?: HTMLAttributes['class'] }>(),
  {
    position: 'popper',
    class: '',
  },
);
const emits = defineEmits<SelectContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <SelectPortal>
    <SelectContent
      class="ui-select-content position-relative top-0 rounded-sm border bg-gray-300 fw-normal"
      :class="[
        { 'ui-animate': position === 'popper' },
        props.class,
      ]"
      v-bind="{ ...forwarded, ...$attrs }"
    >
      <SelectScrollUpButton />
      <SelectViewport
        class="py-12"
        :class="[
          { 'ui-select-content-viewport': position === 'popper' },
        ]"
      >
        <slot />
      </SelectViewport>
      <SelectScrollDownButton />
    </SelectContent>
  </SelectPortal>
</template>
