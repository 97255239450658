<script lang="ts" setup>
import {
  ContextMenuSubContent,
  type DropdownMenuSubContentEmits,
  type DropdownMenuSubContentProps,
  useForwardPropsEmits,
} from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<DropdownMenuSubContentProps & { class?: HTMLAttributes['class'] }>();
const emits = defineEmits<DropdownMenuSubContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <ContextMenuSubContent
    class="ui-menu-sub-content ui-animate fw-medium rounded-sm border px-8 py-12 text-sm shadow"
    :class="[props.class]"
    v-bind="forwarded"
  >
    <slot />
  </ContextMenuSubContent>
</template>
