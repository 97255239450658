import StorageHelper from '@/helpers/storage/storageHelper';

export interface IUserConfig {
  siteOrderColumn?: string
  siteOrderSort?: string
  siteViewMode?: string
  language?: string
  timezone?: string
}

class UserConfigStorage extends StorageHelper<'userConfig'> {
  getConfig(): IUserConfig | null {
    const configData = this.get('userConfig');
    return configData ? JSON.parse(configData) : null;
  }

  setConfig(config: IUserConfig): void {
    this.set(
      'userConfig',
      JSON.stringify({
        ...this.getConfig(),
        ...config,
      }),
    );
  }

  removeConfig(): void {
    this.remove('userConfig');
  }
}

export default new UserConfigStorage();
