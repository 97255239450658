import App from '@/App.vue';
import { vCan } from '@/directives/can';

import createFormatter from '@/helpers/stringHelper';
import i18n from '@/lang';
import initDatadogRUM from '@/plugins/Datadog';
import createGtm from '@/plugins/GTM';
import createModal from '@/plugins/ModalPlugin';
import createToast from '@/plugins/Toast';
import createTooltip from '@/plugins/Tooltip';

import router from '@/router';

import { autoAnimatePlugin } from '@formkit/auto-animate/vue';

import * as Popper from '@popperjs/core';
import { MotionPlugin } from '@vueuse/motion';
import { Collapse, Dropdown, Modal } from 'bootstrap';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import vSelect from 'vue-select';

import Toast from 'vue-toastification';

import 'floating-vue/dist/style.css';
import '../resources/scss/app.scss';

// IMPORTANT! Always first
initDatadogRUM();

const Vue = createApp(App);

const pinia = createPinia();

Vue.use(pinia);
Vue.use(router);
Vue.use(i18n);
Vue.use(Toast);
Vue.use(createGtm);
Vue.use(createToast);
Vue.use(createModal);
Vue.use(createTooltip);
Vue.use(MotionPlugin);
Vue.use(autoAnimatePlugin);

Vue.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    svg: {
      USE_PROFILES: { svg: true },
    },
  },
});

Vue.component('VSelect', vSelect);

window.Popper = Popper;
window.Collapse = Collapse;
window.Dropdown = Dropdown;
window.Modal = Modal;

Vue.use(createFormatter);

// Directives
Vue.directive('can', vCan);

Vue.mount('#app');
