import { ref, watch } from 'vue';
import { useDocumentVisibility, useTimeoutFn } from '@vueuse/core';
import { useSocketStore } from '@/stores/socketStore';

export function useSocketTimeout() {
  const isVisible = useDocumentVisibility();
  const socketStore = useSocketStore();
  const timeoutDuration = 60 * 60 * 1000;
  const isTimeoutActive = ref(false);

  const { forcedDisconnection } = storeToRefs(socketStore);
  const localForcedDisconnection = ref(false);

  const { start, stop } = useTimeoutFn(() => {
    socketStore.disconnectSockets();

    forcedDisconnection.value = true;
    localForcedDisconnection.value = true;
  }, timeoutDuration, { immediate: false });

  watch(
    isVisible,
    (visible) => {
      if (visible === 'hidden') {
        if (!isTimeoutActive.value) {
          start();
          isTimeoutActive.value = true;
        }
      } else {
        if (isTimeoutActive.value) {
          stop();
          isTimeoutActive.value = false;
        }

        if (localForcedDisconnection.value) {
          socketStore.reconnectSockets();
          socketStore.reconnectToChannels();

          localForcedDisconnection.value = false;
        }
      }
    },
    { immediate: true },
  );
}
