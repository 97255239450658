import type { LocaleObject } from 'yup';
import userConfigStorage from '@/helpers/storage/userConfigStorage';
import i18n from '@/lang';
import validationEn from '@/lang/locales/en/validation';
import validationEs from '@/lang/locales/es/validation';
import { setLocale } from 'yup';

export type LanguagesType = 'es' | 'en';
export type AdditionalSpanishLocales = 'gl' | 'eu' | 'ca';
export type AdditionalLanguagesType = 'fr' | 'pt' | 'fi' | 'de';
export type AdditionalLanguagesForReportType = LanguagesType | AdditionalLanguagesType | 'ca' | string;

export const supportedLanguages: LanguagesType[] = ['es', 'en'];

export const additionalSpanishLocales: AdditionalSpanishLocales[] = ['gl', 'eu', 'ca'];
export const additionalLanguagesForReport: AdditionalLanguagesForReportType[] = ['es', 'en', 'ca', 'fr', 'pt', 'fi', 'de'];

/**
 * Returns the language parameter from the url if exists
 *
 * @returns {string | null}
 */
function getLanguageFromParams(): string | null {
  const params = new URLSearchParams(window.location.search);

  return params.get('language') || '';
}

export function getNavigatorLanguage(): string {
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language;
  return !!navigatorLocale ? navigatorLocale.trim().split(/-|_/)[0] : '';
}

function getNavigatorOrParamLanguage(): string {
  const navigatorLocale = getNavigatorLanguage();

  const languageFromParams = getLanguageFromParams();

  return languageFromParams || navigatorLocale;
}

export function getAnyValidLanguage(): string {
  const navigatorLocale = getNavigatorLanguage();

  const languageFromParams = getLanguageFromParams();

  const languageFromLocalStorage = userConfigStorage.getConfig()?.language;

  return languageFromParams || languageFromLocalStorage || navigatorLocale || 'en';
};

function checkSupportedLangOrCompat(language: string): LanguagesType | AdditionalSpanishLocales | string {
  if (supportedLanguages.includes(language as LanguagesType)) {
    return language as LanguagesType;
  } else if (additionalSpanishLocales.includes(language as AdditionalSpanishLocales)) {
    return 'es' as AdditionalSpanishLocales;
  } else {
    return 'en';
  }
}

export function getSupportedApplicationLang() {
  const language = getNavigatorOrParamLanguage();

  return checkSupportedLangOrCompat(language);
}

export function getLocale() {
  const languageFromParams = getLanguageFromParams();
  const navigatorLanguage = getNavigatorLanguage();

  let locale: LanguagesType | AdditionalLanguagesType | string = languageFromParams || navigatorLanguage;

  if (!!languageFromParams && additionalLanguagesForReport.includes(languageFromParams)) {
    locale = languageFromParams;
  } else {
    const spanishLocales = ['es', 'gl', 'eu', 'ca'];

    if (spanishLocales.includes(locale as LanguagesType)) {
      locale = 'es';
    } else {
      locale = 'en';
    }
  }

  return locale;
}

function setValidationLocale(locale: string) {
  const validationLocale = {
    es: validationEs as LocaleObject,
    en: validationEn as LocaleObject,
  };

  setLocale(validationLocale[locale as LanguagesType]);
}

export function changeLanguage(language: LanguagesType | string) {
  let tempLang = 'en';

  if (supportedLanguages.includes(language as LanguagesType)) {
    tempLang = language;
  } else if (additionalSpanishLocales.includes(language as AdditionalSpanishLocales)) {
    tempLang = 'es';
  } else {
    tempLang = 'en';
  }

  const doc = document.querySelector('html');

  if (doc && !!language) {
    doc.setAttribute('lang', tempLang);
  }

  userConfigStorage.setConfig({
    language: tempLang,
  });

  i18n.global.locale.value = tempLang;

  setValidationLocale(tempLang);
}
