<script lang="ts" setup>
import type { MIconTypes } from '@/components/vendor/basic/icon/MIcon.vue';
import type { ComputedRef } from 'vue';

import type { RouteLocationRaw } from 'vue-router';

import api from '@/api';

import NewBadge from '@/components/global/menu/parts/NewBadge.vue';
import useSiteStore from '@/stores/site/siteStore';
import { CollapsibleRoot } from 'radix-vue';

const props = defineProps({
  siteSlug: {
    type: String,
    required: true,
  },
});

const route = useRoute();

export interface MenuItem {
  id: string
  icon?: MIconTypes
  label: string
  route: RouteLocationRaw
  class?: string
  ariaLabel: string
  isNewFeature?: boolean
  dataCy?: string
  event?: string
  checkActive?: boolean
  children?: Array<Omit<MenuItem, 'children'>>
  defaultOpen?: boolean
  renderExtraContent?: () => VNode | null
}

const siteSlug = computed(() => props.siteSlug);

const siteStore = useSiteStore();

const currentSite = computed(() => siteStore.currentSite);

const currentRouteName = computed(() => (!!route.name ? String(route.name) : ''));
const currentRouteIsInMetricsMenu = computed(() =>
  ['sites.site.service.search-console.show', 'sites.site.analytics.show'].includes(currentRouteName.value),
);

// This MUST BE a computed property
const menuItems: ComputedRef<MenuItem[]> = computed(() => [
  {
    id: 'site-menu-overview',
    icon: 'chart-pie' as MIconTypes,
    label: 'menu.overview',
    ariaLabel: 'menu.overview',
    dataCy: 'site-overview-link',
    route: {
      name: 'sites.site.show',
      params: {
        site: siteSlug.value,
      },
    },
  },
  {
    id: 'site-menu-metrics',
    icon: 'arrow-going-up-alt' as MIconTypes,
    label: 'menu.metrics',
    ariaLabel: 'menu.metricsAriaLabel',
    checkActive: currentRouteIsInMetricsMenu.value,
    defaultOpen: currentRouteIsInMetricsMenu.value,
    route: {
      name: 'sites.site.metrics.show',
    },
    children: [
      {
        id: 'site-menu-analytics',
        label: 'menu.analytics',
        ariaLabel: 'menu.analytics',
        route: { name: 'sites.site.analytics.show' },
      },
      {
        id: 'site-menu-search-console',
        label: 'menu.searchConsole',
        ariaLabel: 'menu.searchConsole',
        route: { name: 'sites.site.service.search-console.show' },
        isNewFeature: false,
      },
    ],
  },
  {
    id: 'site-menu-uptime-monitor',
    icon: 'fast-gage' as MIconTypes,
    label: 'menu.uptimeMonitor',
    class: 'router-link-parent-active',
    ariaLabel: 'menu.uptimeMonitor',
    dataCy: 'site-uptime-link',
    route: { name: 'sites.site.uptime.check.stats.show' },
  },
  {
    id: 'site-menu-backups',
    icon: 'database' as MIconTypes,
    label: 'menu.backups',
    class: 'router-link-parent-active',
    ariaLabel: 'menu.backups',
    dataCy: 'site-backups-link',
    route: { name: 'sites.site.backup.index' },
  },
  {
    id: 'site-menu-reports',
    icon: 'bullets' as MIconTypes,
    label: 'menu.reports',
    class: 'router-link-parent-active',
    ariaLabel: 'menu.reports',
    dataCy: 'site-reports-link',
    route: { name: 'sites.site.reports.show' },
  },
  {
    id: 'site-menu-health-safety',
    icon: 'smile-emoji' as MIconTypes,
    label: 'menu.healthSafety',
    ariaLabel: 'menu.healthSafety',
    dataCy: 'site-health-link',
    route: { name: 'sites.site.health.show' },
    event: 'View site health',
  },
]);

function handleTrack(event: string) {
  if (!!event) {
    api.mixpanel.track(event, {
      'Website name': currentSite.value?.name,
      'Website url': currentSite.value?.uri,
      'Location': 'Side menu',
    });
  }
}
</script>

<template>
  <div class="sidebar-nav">
    <ul class="nav" role="menubar">
      <li v-for="item in menuItems" :id="item.id" :key="item.id" class="nav-item" role="none">
        <router-link
          v-if="!!item.route && !item.children"
          :aria-label="$t(item.ariaLabel)"
          :class="item.class"
          :data-cy="item.dataCy"
          :to="item.route"
          class="nav-link py-12"
          @click="item.event ? handleTrack(item.event) : null"
        >
          <m-icon v-if="!!item.icon" :icon="item.icon" aria-hidden="true" />
          <span class="nav-link-label text-sm">{{ $t(item.label) }}</span>

          <NewBadge v-if="item?.isNewFeature" />
        </router-link>

        <template v-else>
          <CollapsibleRoot v-slot="{ open }" :default-open="item.defaultOpen" class="w-100">
            <CollapsibleTrigger
              :class="[
                item.class,
                {
                  'router-link-active router-link-parent-active': !open && item.checkActive,
                },
              ]"
              class="btn w-100 nav-link border-0 py-12 pe-20"
            >
              <span class="d-flex justify-content-between align-items-center w-100">
                <m-icon v-if="!!item.icon" :icon="item.icon" aria-hidden="true" />
                <span class="nav-link-label text-sm">{{ $t(item.label) }}</span>

                <m-icon :class="{ open }" class="icon-arrow" icon="arrow-caret-down" size="2xs" space="ms-auto" />
              </span>
            </CollapsibleTrigger>

            <CollapsibleContent>
              <ul :id="`submenu-${item.id}`" class="nav nav-sub">
                <li
                  v-for="subitem in item.children"
                  :id="subitem.id"
                  :key="subitem.id"
                  class="nav-item mb-0"
                  role="presentation"
                >
                  <router-link
                    v-if="!!subitem.route"
                    :aria-label="$t(subitem.ariaLabel)"
                    :class="subitem.class"
                    :to="subitem.route"
                    class="nav-link py-12 pe-8"
                  >
                    <m-icon v-if="!!subitem.icon" :icon="subitem.icon" aria-hidden="true" />
                    <span class="text-sm">{{ $t(subitem.label) }}</span>

                    <NewBadge v-if="subitem?.isNewFeature" />
                  </router-link>
                </li>
              </ul>
            </CollapsibleContent>
          </CollapsibleRoot>
        </template>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.nav-sub .nav-link {
  padding-left: 3.25rem !important;
}
</style>
