import type { ITreeDirectoryFileRequest } from '@/api/services/site/tree/index';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class TreeService extends AbstractService {
  /**
   * Returns WordPress Directory from connected site
   *
   * @param siteId
   * @param {string} path
   * @returns {Promise<any>}
   */
  public directory(siteId: number, path: string): Promise<ITreeDirectoryFileRequest[]> {
    return this.request({
      method: apiEndpoints.sitesDirectoryTreeShow.method,
      url: this.buildPath(apiEndpoints.sitesDirectoryTreeShow, {
        site: siteId,
      }),
      params: { path },
    });
  }

  /**
   * Returns WordPress Database from connected site
   *
   * @returns {Promise<any>}
   * @param siteId
   */
  public database(siteId: number): Promise<ITreeDirectoryFileRequest[]> {
    return this.request({
      method: apiEndpoints.sitesDatabaseTreeShow.method,
      url: this.buildPath(apiEndpoints.sitesDatabaseTreeShow, {
        site: siteId,
      }),
    });
  }
}
