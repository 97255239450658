<script lang="ts" setup>
import useUptime from '@/composables/uptime/useUptime';
import { useUptimeStore } from '@/stores/site/uptime/uptimeStore';
import TheSiteOverviewUptimeActivatedState
  from '@/views/site/overview/sidebar/parts/TheSiteOverviewSidebarUptimeActivatedState.vue';
import { watchImmediate } from '@vueuse/core';
import { ref } from 'vue';
import api from '@/api';
import useSiteStore from '@/stores/site/siteStore';
import { useToast } from '@/composables/useToast';

defineOptions({
  name: 'TheSiteOverviewUptime', // the-site-overview-uptime
});

const uptimeStore = useUptimeStore();
const uptimeStats = computed(() => uptimeStore.uptimeStats);
const loadedUptimeStats = computed(() => uptimeStore.loadedUptimeStats);
const uptimeIsConfigured = computed(() => uptimeStore.uptimeIsConfigured);

const siteStore = useSiteStore();
const currentSite = computed(() => siteStore.currentSite);

const { loadedUptime } = storeToRefs(uptimeStore);

const {
  loadUptimeService,
  loadUptimeStats,
  createUptimeService,
} = useUptime();

const enablingUptime = ref(false);

const showPlaceholder = computed(() => {
  return (!loadedUptime.value && !uptimeIsConfigured.value) || (uptimeIsConfigured.value && !loadedUptimeStats.value);
});

const { toastSuccess } = useToast();

async function handleCreateUptimeService() {
  enablingUptime.value = true;

  await createUptimeService();

  await api.mixpanel.track('Activate Uptime', {
    'Website url': currentSite.value?.uri,
    'Uptime interval': 30,
  });

  toastSuccess('site.uptime.shared.uptimeEnabled');

  enablingUptime.value = false;
}

watchImmediate(() => uptimeIsConfigured.value, async (isConfigured, wasConfigured) => {
  if (!uptimeStats.value && isConfigured && !wasConfigured) {
    await loadUptimeService();
    await loadUptimeStats();
  }

  // If uptime is not enabled, we can consider it loaded
  if (!isConfigured && !wasConfigured) {
    loadedUptime.value = true;
  }
});
</script>

<template>
  <div id="overview-aside-uptime" class="col-12 col-lg-6 col-xl-12 aside mb-16">
    <div class="aside-content aside-uptime-monitor radius-sm position-relative bg-white p-16 pt-24">
      <div v-if="showPlaceholder">
        <div class="fw-semi lh-xl mb-16 text-gray-600">
          {{ $t('site.sidebar.uptimeMonitor') }}
        </div>

        <div class="placeholder-glow d-flex flex-column justify-content-between mb-16">
          <span class="d-block placeholder lh-xl w-100 mb-8" />
          <span class="d-block placeholder lh-xl w-100 mb-8" />
          <span class="d-block placeholder lh-xl" style="width: 100px" />
        </div>
      </div>

      <div
        v-else-if="!uptimeIsConfigured"
        id="the-site-overview-uptime-deactivate"
        data-cy="site-overview-uptime-deactivate"
      >
        <div class="fw-semi mb-16 text-gray-600">
          {{ $t('site.sidebar.uptimeMonitor') }}
        </div>

        <p class="fw-medium mb-8 text-sm">
          {{ $t('site.sidebar.uptimeMonitorDescription') }}
        </p>

        <p class="fw-medium text-sm text-gray-500">
          {{ $t('site.sidebar.activateFree') }}
        </p>

        <VButton
          :disabled="enablingUptime"
          class="btn-icon w-100 justify-content-center"
          data-cy="btn-overview-uptime-activate"
          size="sm"
          variant="outline-success"
          @click="handleCreateUptimeService"
        >
          <v-icon class="me-12" icon="fast-gage" />
          <template v-if="enablingUptime">
            {{ $t('general.sidebar.activating') }}
          </template>
          <template v-else>
            {{ $t('general.sidebar.activateUptimeMonitor') }}
          </template>
        </VButton>
      </div>

      <TheSiteOverviewUptimeActivatedState
        v-else
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aside-uptime-monitor {
  min-height: 11.875rem;

  .uptime-status {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: var(--md-gray-400);
    }

    .icon-question {
      padding: 0.65rem;
      border: 0.125rem solid;
      border-radius: 50rem;
    }
  }
}
</style>
