<script lang="ts" setup>
import type { IconTypes } from '@/components/vendor/basic/icon/VIcon.vue';
import type { Site } from '@/types/models/site/Site';
import type { Team } from '@/types/models/team/Team';
import type { ComputedRef } from 'vue';

import api from '@/api';
import DashboardSitesItemCard from '@/components/dashboard/sites/item/DashboardSitesItemCard.vue';
import DashboardSitesItemList from '@/components/dashboard/sites/item/DashboardSitesItemList.vue';
import DashboardSitesItemMenuContext from '@/components/dashboard/sites/item/menu/DashboardSitesItemMenuContext.vue';

import DashboardSitesItemMenuDropdown from '@/components/dashboard/sites/item/menu/DashboardSitesItemMenuDropdown.vue';
import { useSiteTags } from '@/composables/site/useSiteTags';

import { useLoginWordpress } from '@/composables/useLoginToWordpress';

import DateHelper from '@/helpers/dateHelper';
import useConstantsHelper from '@/resources/constants/ConstantsHelper';
import useAppStore from '@/stores/appStore';
import useTeamStore from '@/stores/teamStore';

import { ChangeStatusEnumConst } from '@/types/enums/ChangeStatusEnum';
import { SiteStatusEnumConst } from '@/types/enums/SiteStatusEnum';
import { computed, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

defineOptions({
  name: 'DashboardSitesItem', // dashboard-sites-item
});

const props = withDefaults(defineProps<{
  site: Site
  team?: Team | null
  mode: string
  dataCy?: string
}>(), {
  mode: 'card',
  team: null,
  dataCy: '',
});

const emits = defineEmits(['delete-site', 'edit-site', 'isReconnectingSite', 'createTask', 'updateSite', 'editSite']);
const { t } = useI18n();
const router = useRouter();

const teamStore = useTeamStore();

const { stringConstant } = useConstantsHelper();

const { site: localSite } = toRefs(props);

const { UNKNOWN, DOWN, UP } = ChangeStatusEnumConst;
const { SUCCESS, LOST } = SiteStatusEnumConst;

const ERROR_STATUS_FOR_RETRY = stringConstant('errorStatusForBackupRetry');
const ERROR_MAX_ATTEMPTS_FOR_RETRY = stringConstant('errorMaxAttemptsForBackupRetry');

const state = reactive({
  reconnectionTestIsLoading: false,
  reconnectionResult: 'loading',
});

// region Conection Status & Metas
const isConnectionEstablished = computed(() => props.site?.connectionStatus === SUCCESS);
const isConnectionLost = computed(() => props.site?.connectionStatus === LOST);
const isConnectionOnError = computed(() => !isConnectionEstablished.value);
const isConnectionOnErrorOrLost = computed(() => isConnectionLost.value || isConnectionOnError.value);

/**
 * Parse last time a user visited a site
 */
const lastTimeVisited = computed((): string => {
  if (!!props.site.visitedAt) {
    const difference = DateHelper.durationForHumans(props.site.visitedAt, null, true, false, true, true, true);

    return !!difference
      ? t('site.shared.viewedAt', { time: difference })
      : t('site.shared.viewedAt', { time: `1${t('general.shared.minutesShort')}` });
  }

  return '';
});
// endregion Conection Status & Metas

// region Backup
const lastBackupHasError = computed(
  () =>
    !!props.site?.backupService?.lastBackup
    && ERROR_STATUS_FOR_RETRY.includes(props.site?.backupService?.lastBackup?.status)
    && props.site?.backupService?.lastBackup?.attempts >= ERROR_MAX_ATTEMPTS_FOR_RETRY,
);
// endregion Backup

// region Uptime
const appStore = useAppStore();

const isDark = computed(() => appStore.darkMode);

const changeStatus = computed(() =>
  !!props.site?.uptimeService?.currentChange?.status ? props.site.uptimeService.currentChange.status : '',
);
const changeStatusText = computed(() =>
  changeStatus.value === UNKNOWN ? t('report.uptime.activity.paused') : changeStatus.value,
);

const changeStatusData: ComputedRef<{
  icon: IconTypes
  color: string
  background: string
}> = computed(() => {
  let result = {
    icon: 'question' as IconTypes,
    color: isDark.value ? 'text-gray-800' : 'text-gray-500',
    background: 'bg-gray-400',
  };

  if (changeStatus.value === UP) {
    result = {
      icon: 'arrow-up' as IconTypes,
      color: isDark.value && props.mode === 'card' ? 'text-success-light' : 'text-success',
      background: isDark.value ? 'bg-success' : 'bg-success-light',
    };
  } else if (changeStatus.value === DOWN) {
    result = {
      icon: 'arrow-down' as IconTypes,
      color: isDark.value && props.mode === 'card' ? 'text-danger-light' : 'text-danger',
      background: isDark.value ? 'bg-danger' : 'bg-danger-light',
    };
  } else if (changeStatus.value === UNKNOWN) {
    result = {
      icon: 'pause' as IconTypes,
      color: isDark.value && props.mode === 'card' ? 'text-warning-light' : 'text-warning',
      background: isDark.value ? 'bg-warning' : 'bg-warning-light',
    };
  }

  return result;
});
// endregion Uptime

// region Tags
const { siteTagsSelected, syncTag } = useSiteTags(localSite);

// endregion Tags

// region Functions
const { loginWordpress, isLoading: loadingLoginButton } = useLoginWordpress();

async function handleFailReconnection() {
  state.reconnectionResult = 'invalid';

  setTimeout(async () => {
    state.reconnectionTestIsLoading = false;

    await api.mixpanel.track('Manual reconnect', {
      'Website name': props.site.name,
      'Website url': props.site.uri,
      'Location': 'Failed Try to reconnect website',
    });

    await router.push({
      name: 'sites.site.connect',
      params: {
        site: props.site.slug,
      },
    });
  }, 2000);
}

/**
 * Confirms connection with WP Core
 */
async function testConnection() {
  try {
    state.reconnectionTestIsLoading = true;

    emits('isReconnectingSite', true);

    await api.mixpanel.track('Try to reconnect website', {
      'Website name': props.site.name,
      'Website url': props.site.uri,
    });

    const result = await api.site.connect.confirmConnection(props.site.id);

    if (result.connectionStatus === SiteStatusEnumConst.SUCCESS) {
      state.reconnectionResult = 'valid';

      const tmpSite = await api.site.general.retrieve(props.site.slug, {
        include: ['team'],
      });

      setTimeout(async () => {
        emits('updateSite', tmpSite);
      }, 2000);

      if (!!props.site?.team?.id) {
        await teamStore.refreshSites(props.site.team.id);
      }
    } else {
      await handleFailReconnection();
    }
  } catch (e: any) {
    console.error(e);

    await handleFailReconnection();
  } finally {
    emits('isReconnectingSite', false);

    setTimeout(async () => {
      state.reconnectionTestIsLoading = false;
    }, 2000);
  }
}

function launchTracking() {
  if (!!props.site) {
    api.mixpanel.track('View Website', {
      'Website name': props.site.name,
      'Website url': props.site.uri,
      'Location': props.mode === 'card' ? 'Dashboard card' : 'Dashboard list',
    });
  }
}
// endregion Functions
</script>

<template>
  <DashboardSitesItemMenuContext
    v-model:tags="siteTagsSelected"
    :class="{ 'site-card position-relative d-flex flex-column': mode === 'card' }"
    :disabled="isConnectionOnErrorOrLost"
    :site="localSite"
    @login-wordpress="loginWordpress(localSite?.id ?? '')"
    @launch-tracking="launchTracking"
    @create-task="$emit('createTask', $event)"
    @edit-site="$emit('editSite', localSite)"
    @sync-tag="syncTag"
  >
    <template v-if="mode === 'card'">
      <DashboardSitesItemCard
        v-model:tags="siteTagsSelected"
        :change-status-data="changeStatusData"
        :is-connection-established="isConnectionEstablished"
        :is-connection-lost="isConnectionLost"
        :is-connection-on-error="isConnectionOnError"
        :is-connection-on-error-or-lost="isConnectionOnErrorOrLost"
        :last-backup-has-error="lastBackupHasError"
        :last-time-visited="lastTimeVisited"
        :loading-login-button="loadingLoginButton"
        :site="localSite"
        :state="state"
        @launch-tracking="launchTracking"
        @login-wordpress="loginWordpress(localSite?.id ?? '')"
        @test-connection="testConnection"
        @sync-tag="syncTag"
      >
        <DashboardSitesItemMenuDropdown
          v-model:tags="siteTagsSelected"
          :disabled="isConnectionOnErrorOrLost"
          :mode="mode"
          :site="localSite"
          class="btn-options d-flex fw-semi z-index-2 ms-auto border-0 bg-transparent py-4 pe-8 ps-4 text-lg shadow-none"
          @login-wordpress="loginWordpress(localSite?.id ?? '')"
          @launch-tracking="launchTracking"
          @create-task="$emit('createTask', $event)"
          @edit-site="$emit('editSite', localSite)"
          @sync-tag="syncTag"
        >
          <v-icon icon="union" size="md" />
        </DashboardSitesItemMenuDropdown>
      </DashboardSitesItemCard>
    </template>
    <template v-else>
      <DashboardSitesItemList
        v-model:tags="siteTagsSelected"
        :change-status="changeStatus"
        :change-status-data="changeStatusData"
        :change-status-text="changeStatusText"
        :is-connection-established="isConnectionEstablished"
        :is-connection-lost="isConnectionLost"
        :is-connection-on-error="isConnectionOnError"
        :is-connection-on-error-or-lost="isConnectionOnErrorOrLost"
        :last-backup-has-error="lastBackupHasError"
        :last-time-visited="lastTimeVisited"
        :loading-login-button="loadingLoginButton"
        :site="localSite"
        :state="state"
        @launch-tracking="launchTracking"
        @login-wordpress="loginWordpress(localSite?.id ?? '')"
        @test-connection="testConnection"
        @sync-tag="syncTag"
      >
        <DashboardSitesItemMenuDropdown
          v-model:tags="siteTagsSelected"
          :data-cy="`site-list-menu-${localSite.slug}`"
          :mode="mode"
          :site="localSite"
          class="fw-semi ms-auto border-0 bg-transparent p-4 text-lg text-gray-500 shadow-none"
          @login-wordpress="loginWordpress(localSite?.id ?? '')"
          @launch-tracking="launchTracking"
          @create-task="$emit('createTask', $event)"
          @edit-site="$emit('editSite', localSite)"
          @sync-tag="syncTag"
        >
          <v-icon icon="union" size="md" />
        </DashboardSitesItemMenuDropdown>

        <tr class="bg-transparent">
          <td class="p-0" />
        </tr>
      </DashboardSitesItemList>
    </template>
  </DashboardSitesItemMenuContext>
</template>
