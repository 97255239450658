<script lang="ts" setup>
import type { Site } from '@/types/models/site/Site';
import type { Team } from '@/types/models/team/Team';

import api from '@/api';

import VNav from '@/components/vendor/basic/nav/VNav.vue';

import { useTeamSitesInfiniteScroll } from '@/composables/team/useTeamSitesInfiniteScroll';

import useTeamStore from '@/stores/teamStore';
import useUserStore from '@/stores/userStore';
import { deepEqual } from '@/helpers/generalHelper';

defineOptions({
  name: 'TheMenuTeamsCollapsible',
});

const props = defineProps<{
  team: Team
}>();

const teamStore = useTeamStore();
const collapsibleOpen = ref<boolean>(false);

const currentTeam = computed(() => teamStore.currentTeam);
const isCurrentTeam = computed(() => !!currentTeam.value && currentTeam.value.id === props.team.id);

async function cleanCurrentTeam(slug: string) {
  if (!!currentTeam.value && slug !== currentTeam.value?.slug) {
    await teamStore.cleanCurrentTeam();
  }
}
function trackSiteEnter(site: Site) {
  api.mixpanel.track('View Website', {
    'Website name': site.name,
    'Website url': site.uri,
    'Location': 'Dashboard Menu',
  });
}

const loadMoreTrigger = ref<HTMLElement | null>(null);

const { dataRequested, dataResponse, hasData, canLoadMore, numberToPlacehold, reset, requestData, isLoadingMore }
  = useTeamSitesInfiniteScroll(loadMoreTrigger, props.team);

const userStore = useUserStore();

const globalTeams = computed(() => userStore.teams);

const exactTeam = computed(() => globalTeams.value.find(team => team.id === props.team.id));

// Tracks loaded teams to reload data when a site team has changed
watch(
  () => exactTeam.value,
  (newVal, oldVal) => {
    if (!!hasData && !deepEqual(newVal, oldVal)) {
      // Cleans meta filters
      reset();

      requestData();
    }
  },
);

watch(
  () => isCurrentTeam.value,
  (newValue) => {
    collapsibleOpen.value = newValue;

    if (collapsibleOpen.value && !dataRequested.value) {
      requestData();
    }
  },
  { immediate: true },
);
</script>

<template>
  <MCollapsible
    v-model:open="collapsibleOpen"
    as="li"
    class="nav-item position-relative"
    @update:open="!hasData ? requestData() : () => {}"
  >
    <div class="nav-group btn-group d-flex position-relative">
      <router-link
        :data-cy="`link-team-${team.slug}`"
        :to="{ name: 'team.show', params: { team: team.slug } }"
        class="nav-link"
        @click="cleanCurrentTeam(team.slug)"
      >
        <span class="team-name">{{ team.name }}</span>
      </router-link>

      <CollapsibleTrigger
        v-if="!!team?.sitesCount && team.sitesCount > 0"
        :class="{ open: collapsibleOpen }"
        class="btn btn-transparent btn-sidebar dropdown-toggle position-absolute z-3 rounded-0 h-100 d-flex align-items-center end-0 border-0 px-16 py-8 outline-none"
        @mouseenter="!hasData ? requestData() : () => {}"
        @focusin="!hasData ? requestData() : () => {}"
      >
        <m-icon
          :class="{ open: collapsibleOpen }"
          class="text-gray-500 ms-auto transition-transform me-0"
          icon="arrow-caret-down-outlined"
          size="2xs"
        />
      </CollapsibleTrigger>
    </div>

    <CollapsibleContent class="nav-teams-collapsible">
      <div class="nav-teams--collapsible m-scroll-sm me-2 overflow-y-auto overflow-x-hidden pe-8">
        <VNav v-if="hasData && dataRequested" :id="`menu-${team.slug}`" class="submenu p-0">
          <li v-for="site in dataResponse.data" :key="site.id" class="nav-item mb-0">
            <router-link
              :to="{ name: 'sites.site.show', params: { site: site.slug } }"
              class="nav-link fw-normal pe-8 ps-36"
              @click="trackSiteEnter(site)"
            >
              <span class="site-name">{{ site.name }}</span>
            </router-link>
          </li>

          <template v-if="isLoadingMore && canLoadMore">
            <li v-for="n in 3" :key="n" class="nav-item mb-0">
              <span class="placeholder-glow nav-link fw-normal h-100 lh-md pe-8 ps-20">
                <span class="placeholder" style="width: 120px" />
              </span>
            </li>
          </template>

          <li v-if="canLoadMore" ref="loadMoreTrigger" />
        </VNav>

        <ul v-else class="nav submenu p-0">
          <li v-for="n in numberToPlacehold" :key="n" class="nav-item mb-0">
            <span class="placeholder-glow nav-link fw-normal h-100 lh-md pe-8 ps-20">
              <span class="placeholder" style="width: 120px" />
            </span>
          </li>
        </ul>
      </div>
    </CollapsibleContent>
  </MCollapsible>
</template>

<style lang="scss" scoped>
#site-sidebar .sidebar-nav .nav .nav-item .nav-link {
  padding-right: 2.5rem;
}

.nav-item {
  .nav-teams--collapsible .submenu {
    max-height: 7.5rem;
  }

  .team-name,
  .site-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .team-name {
    max-width: 195px;
  }

  .site-name {
    max-width: 240px;
  }
}

.submenu {
  max-height: 12rem;

  .nav-item {
    height: 2.5rem;
  }
}
</style>
