import type { SearchConsoleServiceRetrieveReportRequest } from '@/api/services/site/service/search-console/index';
import type { SimpleResponse } from '@/types/Api';
import type {
  ISearchConsoleReport,
  ISiteServiceSearchConsole,
} from '@/types/models/site/service/search-console/ISiteServiceSearchConsole';
import type { SiteSearchConsoleAccount } from '@/types/models/site/service/search-console/SiteSearchConsoleAccount';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class SearchConsoleService extends AbstractService {
  /**
   * Retrieves the Search Console service for the current site.
   *
   * @returns {Promise<ISiteServiceSearchConsole>}
   * @param serviceId
   */
  public async retrieve(serviceId: number): Promise<ISiteServiceSearchConsole> {
    return this.request({
      method: apiEndpoints.searchConsoleServicesShow.method,
      url: this.buildPath(apiEndpoints.searchConsoleServicesShow, {
        search_console_service: serviceId,
      }),
      options: {
        cancellable: true,
        cancelKey: `retrieveSearchService-${serviceId}`,
      },
    });
  }

  /**
   * Retrieves a report from the Search Console Service for the current site.
   * @param service Service ID
   * @param start Start report date
   * @param end End report date
   * @param limit
   * @returns {Promise<ISearchConsoleReport>}
   */
  public async retrieveReport({ service, start, end, limit }: SearchConsoleServiceRetrieveReportRequest): Promise<ISearchConsoleReport> {
    return this.request({
      method: apiEndpoints.searchConsoleServicesReport.method,
      url: this.buildPath(apiEndpoints.searchConsoleServicesReport, {
        search_console_service: service,
      }),
      params: {
        started_at: start,
        ended_at: end,
        ...(!!limit && { limit }),
      },
      options: {
        cancellable: true,
        cancelKey: 'retrieveReport',
      },
    });
  }

  /**
   * Retrieves all Google Search Console accounts for the current site.
   * @returns {Promise<SiteSearchConsoleAccount[]>}
   * @param serviceId
   */
  public retrieveAccounts(serviceId: number): Promise<SiteSearchConsoleAccount[]> {
    return this.request({
      method: apiEndpoints.searchConsoleServicesAccounts.method,
      url: this.buildPath(apiEndpoints.searchConsoleServicesAccounts, {
        search_console_service: serviceId,
      }),
    });
  }

  /**
   * Updates the Search Console Service for the current site.
   * @param serviceId
   * @param data
   * @returns {Promise<ISiteServiceSearchConsole>}
   */
  public update(serviceId: number, data: { domain: string }): Promise<ISiteServiceSearchConsole> {
    return this.request({
      method: apiEndpoints.searchConsoleServicesUpdate.method,
      url: this.buildPath(apiEndpoints.searchConsoleServicesUpdate, {
        search_console_service: serviceId,
      }),
      data,
    });
  }

  /**
   * Deletes the Search Console Service for the current site.
   *
   * @returns {Promise<SimpleResponse>}
   * @param serviceId
   */
  public delete(serviceId: number): Promise<SimpleResponse> {
    return this.request({
      method: apiEndpoints.searchConsoleServicesDestroy.method,
      url: this.buildPath(apiEndpoints.searchConsoleServicesDestroy, {
        search_console_service: serviceId,
      }),
    });
  }
}
