<script lang="ts" setup>
import type { Site } from '@/types/models/site/Site';
import type { InjectionKey } from 'vue';
import { computed, inject, onMounted, onUnmounted, reactive, ref, toRefs, watch } from 'vue';
import type { ToastInterface } from 'vue-toastification';
import api from '@/api';
import { useSiteDelete } from '@/composables/site/useSiteDelete';

import usePermissions from '@/composables/usePermissions';
import useValidation from '@/composables/useValidation';
import useSiteStore from '@/stores/site/siteStore';

import useUserStore from '@/stores/userStore';
import { storeToRefs } from 'pinia';
import { useField } from 'vee-validate';

import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { object, string } from 'yup';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineOptions({
  name: 'TheSiteEdit',
});

const props = withDefaults(defineProps<{
  siteData?: Site | null
  redirectOnClose?: boolean
  emitClose?: boolean
}>(), {
  siteData: null,
  redirectOnClose: true,
  emitClose: false,
});

const emit = defineEmits(['closed', 'changedName', 'deleteSite']);

const toast: InjectionKey<ToastInterface> | any = inject('toast');

const userStore = useUserStore();
const siteStore = useSiteStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const { can } = usePermissions();

const { SITES_DESTROY, SCREENSHOT_SERVICES_RENEW } = PermissionEnumConst;

const { currentSite } = storeToRefs(siteStore);

const { siteData: propsSite } = toRefs(props);

const disableButton = ref(false);
const selectedTeam = reactive<{
  name: string
  slug: string
  id: string | number
}>({
  name: '',
  slug: '',
  id: 0 as string | number,
});

const localSite = computed(() => propsSite.value || currentSite.value);

const teams = computed(() => userStore.teams);
const computedTeams = computed(() =>
  teams.value.map((team: any) => ({
    name: team.name,
    slug: team.slug,
    id: team.id,
  })),
);

const open = ref(false);

function handleClose() {
  open.value = false;

  setTimeout(() => {
    emit('closed');
  }, 200);
}

const rules = object({
  name: string()
    .required()
    .max(40, t('validation.string.max', { max: 40 })),
});

let initialValues: Record<string, string> = {
  name: localSite.value?.name || '',
};

const form = useValidation(rules, initialValues);

const { values, errors, accepted, toggleAccepted } = form;

const { value: name } = useField<string>('name');

function reloadValues() {
  if (!!localSite.value) {
    initialValues = {
      name: localSite.value?.name,
    };

    form.setValues(initialValues);
  }
}

async function onSubmit() {
  try {
    toggleAccepted();
    disableButton.value = true;

    const newSite = await api.site.general.update(localSite.value!.id, {
      name: values.name,
      team: selectedTeam.id,
    });

    toast.success(t('general.shared.savedData'));

    emit('changedName', newSite);

    await userStore.refreshTeams();

    // Update the current site if it's on the site view (exclude dashboard sites from setting the current site)
    if (route.params.site) {
      siteStore.currentSite = newSite;

      await router.replace({
        name: route.name,
        params: {
          site: newSite.slug,
        },
      });
    }

    handleClose();
  } catch (e: any) {
    console.error(e);
  } finally {
    toggleAccepted();
    disableButton.value = false;
  }
}

function setInitialTeam() {
  if (!!localSite.value?.team && !!teams.value && Object.keys(teams.value).length > 0) {
    const teamSlugToFind = !!route.params.team ? route.params.team : localSite.value.team.slug;

    const team = teams.value.find((item: any) => teamSlugToFind === item.slug);

    if (!!team) {
      selectedTeam.name = team.name;
      selectedTeam.slug = team.slug;
      selectedTeam.id = team.id as number;
    }
  }
}

function onSelectTeam() {
  const team = teams.value.find((item: any) => selectedTeam.slug === item.slug);

  if (!!team) {
    selectedTeam.name = team.name;
    selectedTeam.slug = team.slug;
    selectedTeam.id = team.id as number;
  }
}

const { handleDeleteSite } = useSiteDelete();

async function deleteSite() {
  if (!!localSite.value) {
    handleDeleteSite(localSite.value, async () => {
      emit('deleteSite', localSite.value);

      if (props.redirectOnClose) {
        await router.push({
          name: 'team.show',
          params: { team: localSite.value?.team?.slug },
        });
      } else {
        open.value = false;
      }
    });
  }
}

async function refreshThumbnail() {
  if (!localSite.value)
    return;

  await api.site.general.renewScreenshot(localSite.value.id);

  toast.success(t('site.edit.successRefreshThumbnail'));
}

async function trackManualReconnect() {
  emit('closed')

  await api.mixpanel.track('Manual reconnect', {
    'Website name': localSite.value?.name,
    'Website url': localSite.value?.uri,
    'Location': 'Site settings',
  });
}

watch(
  () => siteStore.currentSite,
  (newVal, oldVal) => {
    if (newVal !== oldVal && !!newVal) {
      setInitialTeam();

      reloadValues();
    }
  },
);

watch(
  () => teams,
  (newVal, oldVal) => {
    if (newVal !== oldVal && !(Object.keys(oldVal).length > 0)) {
      setInitialTeam();
    }
  },
);

onMounted(async () => {
  open.value = true;

  if (!!localSite.value?.name) {
    name.value = localSite.value?.name;
  }

  setInitialTeam();
});

onUnmounted(async () => {
  open.value = false;
});
</script>

<template>
  <Dialog
    :open="open"
    size="md"
    @update:open="(val) => !val ? handleClose() : null"
  >
    <DialogContent id="site-edit-modal" size="md">
      <DialogHeader>
        <DialogTitle>
          {{ $t('site.edit.settings') }}
        </DialogTitle>
      </DialogHeader>

      <DialogBody>
        <VInputTextFloating
          id="change-the-site-name"
          v-model="name"
          :label="$t('general.shared.name')"
          :placeholder="$t('general.shared.name')"
          :yup-errors-variable="errors?.name"
          autocomplete="off"
          class="mb-16"
          data-cy="site-edit-name"
          @keyup.enter="onSubmit"
        />

        <div v-if="!!teams" class="col-12">
          <VSelect
            key="slug"
            v-model="selectedTeam.slug"
            :clearable="false"
            :loading="Object.keys(teams).length === 0"
            :options="computedTeams"
            :placeholder="$t('site.create.team')"
            :reduce="(t: any) => t.slug"
            data-cy="site-edit-select-team"
            label="name"
            @option:selected="onSelectTeam"
          >
            <template #header>
              <span class="v-select--header fw-medium text-gray-600">{{ $t('team.shared.team') }}</span>
            </template>

            <template #no-options="{ search, searching }">
              <template v-if="searching">
                {{ $t('statistics.noResults', { search }) }}
              </template>
            </template>

            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <v-icon icon="chevron-down" size="sm" />
              </span>
            </template>
          </VSelect>
        </div>

        <div v-if="!!localSite && !!userStore.permissionsLoaded" class="site-settings-actions row gx-32 mt-16">
          <div class="col">
            <router-link
              v-if="!!localSite?.team && !!localSite?.slug"
              :to="{ name: 'sites.site.connect', params: { site: localSite.slug } }"
              class="btn btn-transparent d-flex flex-column justify-content-center align-items-center w-100 p-0"
              data-cy="settings-connect-site"
              @click="trackManualReconnect"
            >
              <span class="icon d-flex justify-content-center align-items-center rounded-xs mb-8 text-gray-600">
                <span class="mi mi-refresh text-xxl" />
              </span>

              <div class="lh-xl text-center text-sm text-gray-600">
                {{ $t('site.edit.reconnectSite') }}
              </div>
            </router-link>
          </div>

          <div class="col">
            <VButton
              v-if="can(SCREENSHOT_SERVICES_RENEW)"
              class="d-flex flex-column justify-content-center align-items-center w-100 p-0"
              data-cy="settings-refresh-thumbnail-site"
              variant="transparent"
              @click="refreshThumbnail"
            >
              <span class="icon d-flex justify-content-center align-items-center rounded-xs mb-8 text-gray-600">
                <span class="mi mi-image text-xxl" />
              </span>

              <div class="lh-xl text-center text-sm text-gray-600">
                {{ $t('site.edit.refreshThumbnail') }}
              </div>
            </VButton>
          </div>

          <div class="col">
            <VButton
              v-if="can(SITES_DESTROY)"
              class="d-flex flex-column justify-content-center align-items-center w-100 p-0"
              data-cy="settings-delete-site"
              variant="transparent"
              @click="deleteSite"
            >
              <span class="icon d-flex justify-content-center align-items-center rounded-xs mb-8 text-gray-600">
                <span class="mi mi-delete text-danger-dark text-xxl" />
              </span>

              <div class="lh-xl text-center text-sm text-gray-600">
                {{ $t('site.edit.delete') }}
              </div>
            </VButton>
          </div>
        </div>

        <div v-else class="row gx-32 mt-24">
          <div class="col">
            <div class="d-flex flex-column">
              <span class="placeholder-glow mb-8">
                <span class="placeholder" style="width: 100%; height: 72px" />
              </span>
              <span class="placeholder-glow mb-8">
                <span class="placeholder" style="width: 100%" />
              </span>
            </div>
          </div>

          <div class="col">
            <div class="d-flex flex-column">
              <span class="placeholder-glow mb-8">
                <span class="placeholder" style="width: 100%; height: 72px" />
              </span>
              <span class="placeholder-glow mb-8">
                <span class="placeholder" style="width: 100%" />
              </span>
            </div>
          </div>

          <div class="col">
            <div class="d-flex flex-column">
              <span class="placeholder-glow mb-8">
                <span class="placeholder" style="width: 100%; height: 72px" />
              </span>
              <span class="placeholder-glow mb-8">
                <span class="placeholder" style="width: 100%" />
              </span>
            </div>
          </div>
        </div>
      </DialogBody>

      <DialogFooter>
        <VButton
          :disabled="disableButton"
          :is-loading="accepted"
          data-cy="site-edit-save"
          variant="primary"
          @click="onSubmit"
        >
          {{ $t('general.button.saveChanges') }}
        </VButton>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped>
.site-settings-actions {
  .btn {
    &:disabled {
      border: none;
    }

    .icon {
      width: 100%;
      height: 4.5rem;
      transition: all 0.3s ease-out;
      background-color: var(--md-gray-300);
    }

    &:not(.disabled) {
      &:hover .icon {
        background-color: var(--md-gray-400);
      }

      &:active .icon {
        background-color: var(--md-gray-500);
      }
    }
  }
}
</style>
