<script lang="ts" setup>
import type { SitesAppendFilter, SitesIncludeFilter } from '@/api/services/organization/OrganizationService';
import type { Tag } from '@/types/models/organization/tag/Tag';
import type { Site } from '@/types/models/site/Site';

import type { Team } from '@/types/models/team/Team';
import DashboardSitesEmptyState from '@/components/dashboard/sites/DashboardSitesEmptyState.vue';
import DashboardSitesFilters from '@/components/dashboard/sites/DashboardSitesFilters.vue';
import DashboardSitesPlaceholder from '@/components/dashboard/sites/DashboardSitesPlaceholder.vue';

import DashboardSitesItem from '@/components/dashboard/sites/item/DashboardSitesItem.vue';

import DashboardWidgetDisconnectedAlert
  from '@/components/dashboard/sites/widgets/DashboardWidgetDisconnectedAlert.vue';
import DashboardWidgetUpdater from '@/components/dashboard/sites/widgets/DashboardWidgetUpdater.vue';
import { useSitesInfiniteScroll } from '@/composables/sites/useSitesInfiniteScroll';
import useModal from '@/composables/useModal';
import useOrganizationStore from '@/stores/organizationStore';
import { useTagsStore } from '@/stores/tag/tagsStore';

import { useUserStore } from '@/stores/userStore';
import { PermissionEnumConst } from '@/types/PermissionEnum';
import TheSiteEdit from '@/views/site/TheSiteEdit.vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { can } from '@/helpers/permissionsHelper';

defineOptions({
  name: 'DashboardSites',
});

const props = withDefaults(
  defineProps<{
    showAside?: boolean
    showUpdaterWidget?: boolean
    limit?: boolean
    isTeamView?: boolean
    currentTeam?: Team | null
    // Needed to force reload sites when team changes
    currentTeamSlug?: string | null
    dataCy?: string
    location: 'Dashboard' | 'My sites' | 'Team page'
  }>(),
  {
    showAside: false,
    showUpdaterWidget: false,
    limit: false,
    isTeamView: false,
    currentTeam: null,
    currentTeamSlug: '',
    dataCy: '',
  },
);

const {
  BACKUP_SERVICES_STORE,
  SITE_BACKUPS_RETRY,
  BACKUP_SERVICES_SHOW,
  COMPONENTS_INDEX,
  HEALTH_SERVICES_REPORT,
  VULNERABILITY_SERVICES_SHOW,
  PERFORMANCE_SERVICES_SHOW,
} = PermissionEnumConst;

const slots = useSlots();
const organizationStore = useOrganizationStore();
const tagsStore = useTagsStore();
const tags = computed(() => tagsStore.tags);

const { modalToShow, closeModal } = useModal();

const route = useRoute();

const dashboardWidgetDisconnectedAlert = ref<InstanceType<typeof DashboardWidgetDisconnectedAlert> | null>(null);

const isSiteReconnectionInProgress = ref(false);
const hasSidebarVisible = ref(false);
const feedType = ref<'grid' | 'list'>('grid');

const totalSiteItemsToUpdate = computed(() => organizationStore.siteItems?.total?.value || 0);

const userStore = useUserStore();
const userStoreTeams = computed(() => userStore.teams);

const currentTeamHasSites = computed(() => {
  const foundTeam = userStoreTeams.value.find((team: Team) => team.slug === props.currentTeamSlug);

  return (
    props.isTeamView && !!userStoreTeams.value && !!foundTeam && !!foundTeam.sitesCount && foundTeam?.sitesCount > 0
  );
});

const orderBy = ref('visited_at-desc');
const tagsSelected = ref<Tag[]>([]);

const queryTags = computed(() => route.query.tags);
const isAnyTagSelected = computed(() => !!tagsSelected.value && tagsSelected.value.length > 0);

const include = ref<SitesIncludeFilter[]>(['team', 'backup_service', 'uptime_service', 'screenshot', 'analytics_service', 'tags']);
const append = ref<SitesAppendFilter[]>(['updatable_items_count']);

const teamIds = computed(() => {
  if (!props.currentTeam)
    return [];
  return [Number(props.currentTeam.id)];
});

const {
  loadMoreTrigger,
  dataRequested,
  isRequestingData,
  currentPage,
  dataResponse,
  canLoadMore,
  numberToPlacehold,
  isLoadingMore,
  disconnectedSitesCount,
  requestData,
  clearFilters,
  resetAndFetch,
} = useSitesInfiniteScroll(
  {
    orderBy,
    filters: {
      tagsFilter: tagsSelected,
      include,
      append,
    },
    limit: props.limit ?? false,
    isTeamView: props.isTeamView,
  },
);

const openSiteEditModal = ref(false);
let siteToEdit = {} as Site;

function setOpenSiteEditModal(value: boolean) {
  openSiteEditModal.value = value;

  if (!value) {
    siteToEdit = {} as Site;
  }
}

/**
 * Function to execute after emit from DashboardSitesItem
 * Which is a callback from useSiteDelete composable
 */
function deleteSiteCallback(site: Site) {
  if (!dataResponse?.data) {
    return;
  }

  const siteIndex = dataResponse?.data.findIndex(({ id }: any) => id === site.id);

  if (siteIndex !== -1) {
    dataResponse.data.splice(siteIndex, 1);
  }

  setOpenSiteEditModal(false);
}

function editSite(site: Site) {
  siteToEdit = site;
  setOpenSiteEditModal(true);
}

function updateSiteData(site: Site) {
  if (!dataResponse.data)
    return;

  const siteIndex = dataResponse.data.findIndex(({ id }: any) => id === site.id);

  if (siteIndex < 0)
    return;

  const foundSite = dataResponse.data[siteIndex];

  foundSite.name = site.name;
  foundSite.slug = site.slug;
  foundSite.connectionStatus = site.connectionStatus;

  if (props.isTeamView && !!site.team && site.team.slug !== dataResponse.data[siteIndex]?.team?.slug) {
    dataResponse.data.splice(siteIndex, 1);
  }

  if (
    site.connectionStatus === 'established'
    && dashboardWidgetDisconnectedAlert.value
    && !!disconnectedSitesCount.value
    && disconnectedSitesCount.value > 0
  ) {
    dashboardWidgetDisconnectedAlert.value.loadDisconnectedSites(true);
  }
}

const CREATE_TASK_MODAL = 'the-site-report-create-task';
const siteForTask = ref<Site | null>(null);

function handleCreateTask(site: Site) {
  siteForTask.value = site;

  modalToShow.value = CREATE_TASK_MODAL;

  api.mixpanel.track('Try to add task', {
    'Website name': site.name,
    'Website url': site.slug,
    'Location': 'Contextual menu',
  });
}

function tagRemoved(tagId: number) {
  tagsSelected.value = tagsSelected.value.filter(tag => tag.id !== tagId);

  currentPage.value = 1;
  dataResponse.data = [];
  dataRequested.value = false;

  requestData(true, teamIds.value);
}

/**
 * Watches team change (in menu) to set loader or empty state
 */
watch(
  () => props.currentTeamSlug,
  async (newVal) => {
    // To force loader when is changing team and it has not loaded
    if (newVal) {
      dataRequested.value = false;
    }
  },
  { immediate: true },
);
/**
 * Watches team change (in menu) to fetch sites
 */
watchImmediate(
  () => props.currentTeam,
  async (newVal, oldVal) => {
    // To reload sites when team finally changes
    // with !isRequestingData to avoid double request
    if (!!newVal && newVal?.id !== oldVal?.id && !isRequestingData.value) {
      await resetAndFetch(teamIds.value);
    }
  },
);

watch(() => queryTags.value, (value) => {
  if (!!value && Array.isArray(value) && value.length > 0) {
    value.forEach((tagId: string) => {
      const found = tags.value.find(tag => tag.id === Number(tagId));

      if (!!found) {
        tagsSelected.value = [found];

        resetAndFetch(teamIds.value);
      }
    });
  }
});

onMounted(async () => {
  const sidebar = !!slots ? slots.sidebar?.()[0] : undefined;

  if (!!sidebar?.children && Array.isArray(sidebar.children) && sidebar.children.length > 0) {
    hasSidebarVisible.value = true;
  }

  if (!!queryTags.value && Array.isArray(queryTags.value) && queryTags.value.length > 0) {
    queryTags.value.forEach((tagId: string) => {
      const found = tags.value.find(tag => tag.id === Number(tagId));

      if (!!found) {
        tagsSelected.value = [found];
      }
    });
  }

  if (!props.isTeamView) {
    await requestData(false, teamIds.value);
  }
});
</script>

<template>
  <div :data-cy="dataCy" class="w-100 d-flex flex-column flex-xl-row mb-120">
    <section
      id="sites"
      :class="{ 'mb-xl-0 mb-48 me-48': hasSidebarVisible, 'empty-sidebar': !hasSidebarVisible }"
      class="w-100"
    >
      <DashboardSitesFilters
        v-model:feed="feedType"
        v-model:order="orderBy"
        v-model:tags="tagsSelected"
        :location="location"
        :sites-are-empty="!!dataResponse.data && dataResponse.data.length === 0"
        :sites-have-loaded="dataRequested"
        @update-order="resetAndFetch"
        @changed-tags="resetAndFetch"
        @tag-removed="tagRemoved"
        @clear-filters="clearFilters(true)"
      >
        <template #options>
          <slot name="options" />
        </template>
      </DashboardSitesFilters>

      <dashboard-widget-review
        v-if="!isTeamView && limit"
      />

      <DashboardWidgetDisconnectedAlert
        ref="dashboardWidgetDisconnectedAlert"
        :sites-without-connection-count="disconnectedSitesCount"
        :team-id="currentTeam?.id"
      />

      <DashboardWidgetUpdater
        :items-to-update-on-sites-count="totalSiteItemsToUpdate"
        :show-updater-widget="showUpdaterWidget"
      />

      <!-- Placeholders -->
      <DashboardSitesPlaceholder
        v-if="(currentTeamHasSites && !dataRequested) || (!isTeamView && !dataRequested)"
        :feed-type="feedType"
      />

      <div
        v-else-if="!!dataResponse?.data && dataResponse?.data.length > 0"
        :class="{ 'mb-48': hasSidebarVisible }"
        class="position-relative mt-16"
      >
        <template v-if="!!dataResponse?.data && dataResponse?.data.length > 0">
          <transition mode="out-in" name="fade-in-fast">
            <!-- Base grid for card mode -->

            <div v-if="feedType === 'grid'" key="items-grid-wrap" class="items-grid" data-cy="items-grid">
              <DashboardSitesItem
                v-for="site in dataResponse.data"
                :key="`site-card-${site.slug}`"
                :data-cy="`site-card-${site.slug}`"
                :site="site as Site"
                :team="currentTeam"
                mode="card"
                @update-site="updateSiteData"
                @delete-site="deleteSiteCallback"
                @edit-site="editSite"
                @create-task="handleCreateTask"
                @is-reconnecting-site="(event) => (isSiteReconnectionInProgress = event)"
              />

              <template v-if="isLoadingMore && !limit">
                <DashboardSitesPlaceholder
                  :feed-type="feedType"
                  :number-of-items="numberToPlacehold"
                  without-wrapper
                />
              </template>
            </div>

            <!-- Base table for type list -->
            <div v-else key="items-list-wrap" class="table-responsive" data-cy="items-list">
              <table class="sites-table table">
                <thead>
                  <tr class="text-xs">
                    <th class="pe-0 ps-8">
                      {{ $t('site.shared.web') }}
                    </th>
                    <th v-can="PERFORMANCE_SERVICES_SHOW" class="text-center">
                      {{ $t('site.shared.performance') }}
                    </th>
                    <th
                      v-if="can(COMPONENTS_INDEX) || can(HEALTH_SERVICES_REPORT) || can(VULNERABILITY_SERVICES_SHOW)"
                      class="text-center"
                    >
                      {{ $t('site.shared.updatesErrors') }}
                    </th>
                    <th
                      v-if="can(BACKUP_SERVICES_STORE) || can(SITE_BACKUPS_RETRY) || can(BACKUP_SERVICES_SHOW)"
                      class="text-center"
                    >
                      {{ $t('site.shared.backup') }}
                    </th>
                    <th class="fit">
                      {{ $t('site.shared.wordpress') }}
                    </th>
                    <th class="fit" />
                  </tr>
                </thead>

                <tbody>
                  <DashboardSitesItem
                    v-for="site in dataResponse.data"
                    :key="`site-card-${site.slug}`"
                    :data-cy="`site-card-${site.slug}`"
                    :is-reconnecting-any-site="isSiteReconnectionInProgress"
                    :site="site as Site"
                    :team="currentTeam"
                    mode="list"
                    @update-site="updateSiteData"
                    @delete-site="deleteSiteCallback"
                    @edit-site="editSite"
                    @create-task="handleCreateTask"
                    @is-reconnecting-site="(event) => (isSiteReconnectionInProgress = event)"
                  />

                  <template v-if="isLoadingMore && !limit">
                    <DashboardSitesPlaceholder
                      :feed-type="feedType"
                      :number-of-items="numberToPlacehold"
                      without-wrapper
                    />
                  </template>
                </tbody>
              </table>
            </div>
          </transition>

          <div v-if="canLoadMore && !limit" ref="loadMoreTrigger" />
        </template>

        <slot v-if="!!dataResponse?.data && dataResponse?.data.length > 0" name="actions" />
      </div>

      <!-- Empty state -->
      <DashboardSitesEmptyState
        v-else
        :mode="isAnyTagSelected ? 'tags' : 'default'"
        @clear-filters="clearFilters"
      />
    </section>

    <slot name="sidebar" />

    <TheSiteEdit
      v-if="openSiteEditModal && !!siteToEdit"
      :redirect-on-close="false"
      :site-data="siteToEdit"
      emit-close
      @closed="setOpenSiteEditModal(false)"
      @delete-site="deleteSiteCallback"
      @changed-name="updateSiteData"
    />

    <the-site-report-create-task
      v-if="!!siteForTask?.slug && !!siteForTask?.team?.slug && modalToShow === CREATE_TASK_MODAL"
      :site-id="siteForTask.id"
      @closed="
        modalToShow = '';
        closeModal;
        siteForTask = null;
      "
    />
  </div>
</template>

<style lang="scss" scoped>
#sites {
  // 100% - dashboard space - dashboard spacing
  @media (width >= 1200px) {
    &:not(.empty-sidebar) {
      max-width: calc(100% - 18.75rem - 3rem);
    }
  }

  tbody::before {
    content: '-';
    display: block;
    line-height: 0.375rem;
    color: transparent;
  }

  :deep(#sites-order-selector)::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0.125rem;
    left: 0.5rem;
    right: 0.75rem;
    background-color: var(--md-primary);
    border-radius: 5rem;
  }

  :deep(.items-grid) {
    display: grid;
    gap: 1rem;
    grid-auto-rows: minmax(15rem, max-content);
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }

  .sites-table {
    border-collapse: separate;
    border-spacing: 0 0.0625rem;
  }

  .site-updates-notification {
    .btn-update {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .placeholder-card {
    background-color: var(--md-light);
    background-clip: border-box;
    border-radius: 0.5rem;
    border: 1px solid var(--md-gray-400);

    &:nth-child(2) {
      opacity: 0.8;
    }

    &:nth-child(3) {
      opacity: 0.6;
    }

    .site-image {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    .card-image {
      max-height: 8.125rem;
    }
  }

  .image-empty-state {
    max-width: 23.5rem;
  }
}
</style>
