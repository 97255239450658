import type { OauthClient } from '@/types/models/site/OauthClient';
import type { Site } from '@/types/models/site/Site';
import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class ConnectService extends AbstractService {
  /**
   * Connects the current site to WordPress with the specified credentials.
   *
   * @param {number} site
   * @param data
   * @returns {Promise<any>}
   */
  public connect(site: number, data: {
    username: string
    password: string
    path: string
  }) {
    return this.request({
      method: apiEndpoints.sitesConnect.method,
      url: this.buildPath(apiEndpoints.sitesConnect, {
        site,
      }),
      data,
    });
  }

  /**
   * Returns private access token to WordPress manual connection
   *
   * @param {number} site
   * @returns {Promise<OauthClient>}
   */
  public retrieveOauthClient(site: number): Promise<OauthClient> {
    return this.request({
      method: apiEndpoints.sitesOauthClientShow.method,
      url: this.buildPath(apiEndpoints.sitesOauthClientShow, {
        site,
      }),
    });
  }

  /**
   * Validates the connection of the current site to Wordpress.
   *
   * @param {string} site
   * @returns {Promise<Site>}
   */
  public confirmConnection(site: number): Promise<Site> {
    return this.request({
      method: apiEndpoints.sitesOauthClientConfirm.method,
      url: this.buildPath(apiEndpoints.sitesOauthClientConfirm, {
        site,
      }),
    });
  }
}
