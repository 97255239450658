<script setup lang="ts">
import useAppStore from '@/stores/appStore';
import { computed } from 'vue';

withDefaults(
  defineProps<{
    text?: string
    size?: 'sm' | 'md'
    spacing?: string
  }>(),
  {
    text: 'general.shared.new',
    size: 'sm',
    spacing: 'px-12 py-8',
  },
);

const appStore = useAppStore();
const darkMode = computed(() => appStore.darkMode);

const yellowSvg = ref(
  '<svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg"><path d="M9.88659 2.19596C10.2908 1.49583 10.0509 0.600583 9.35079 0.196361C8.65067 -0.207862 7.75542 0.032016 7.3512 0.732142L5.46411 4.0007L2.19594 2.11379C1.49581 1.70956 0.600564 1.94944 0.196352 2.64957C-0.207861 3.34969 0.0320252 4.24495 0.732152 4.64917L4.00032 6.53608L2.11358 9.80404C1.70936 10.5042 1.94925 11.3994 2.64938 11.8036C3.3495 12.2079 4.24475 11.968 4.64896 11.2679L6.53571 7.9999L9.80406 9.88692C10.5042 10.2911 11.3994 10.0513 11.8036 9.35114C12.2079 8.65101 11.968 7.75576 11.2678 7.35153L7.9995 5.46452L9.88659 2.19596Z" fill="#FFBD63" /></svg>',
);

const blueSvg = ref(
  '<svg fill="none" height="9" viewBox="0 0 10 9" width="10" xmlns="http://www.w3.org/2000/svg"><path d="M0.875267 1.12521C0.410945 1.17507 0.176969 1.70678 0.45411 2.08228L5.19322 8.50334C5.47037 8.87884 6.05077 8.81651 6.23795 8.39114L9.43874 1.11743C9.62592 0.692065 9.2795 0.222689 8.81518 0.272552L0.875267 1.12521Z" fill="#00E5E5" /></svg>',
);
</script>

<template>
  <span
    class="new-tag d-flex align-items-center rounded-pill overflow-visible"
    :class="[spacing]"
  >
    <span
      :class="{ 'text-dark': darkMode, 'text-white': !darkMode, 'text-2xs': size === 'sm', 'text-sm': size === 'md' }"
      class="fw-semi lh-1 text-uppercase"
    >
      {{ $t(text) }}
    </span>

    <span v-dompurify-html="yellowSvg" class="yellow-x" />

    <span v-dompurify-html="blueSvg" class="blue-triangle" />
  </span>
</template>

<style lang="scss" scoped>
.new-tag {
  right: 1rem;
  margin-left: auto;
  display: block;
  background: linear-gradient(85.1deg, #610bef -11.34%, #ef0baf 118.81%);
  animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .yellow-x,
  .blue-triangle {
    position: absolute;
  }

  .yellow-x {
    top: -0.125rem;
    right: 0;
    transform: translateY(-50%);
  }

  .blue-triangle {
    bottom: 0.25rem;
    left: 0;
    transform: translate(-50%, 50%);
  }

  @keyframes scale-in-center {
    0% {
      transform: scale(0);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

/*:deep(.new-tag) {
  position: absolute;
  right: 1rem;
  margin-left: auto;
  display: block;
  background: linear-gradient(85.1deg, #6308f7 -11.34%, #df25b8 118.81%);

  .yellow-x,
  .blue-triangle {
    position: absolute;
  }

  .yellow-x {
    top: -0.125rem;
    right: 0;
    transform: translateY(-50%);
  }

  .blue-triangle {
    bottom: 0.25rem;
    left: 0;
    transform: translate(-50%, 50%);
  }
}*/
</style>
