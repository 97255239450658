<script lang="ts" setup>
import { SelectScrollUpButton, type SelectScrollUpButtonProps, useForwardProps } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';

const props = defineProps<SelectScrollUpButtonProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <SelectScrollUpButton
    class="d-flex cursor-default align-items-center justify-content-center py-4"
    :class="[props.class]"
    v-bind="forwardedProps"
  >
    <slot>
      <m-icon icon="arrow-chevron-up" size="sm" />
    </slot>
  </SelectScrollUpButton>
</template>
