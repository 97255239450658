<script lang="ts" setup>
import {
  ContextMenuContent,
  type ContextMenuContentEmits,
  type ContextMenuContentProps,
  ContextMenuPortal,
  useForwardPropsEmits,
} from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

const props = withDefaults(
  defineProps<
    ContextMenuContentProps & { class?: HTMLAttributes['class'], variant?: 'light' | 'dark', size?: '' | 'sm' | 'lg' }
  >(),
  {
    sideOffset: 4,
    variant: 'light',
    size: '',
  },
);

const emits = defineEmits<ContextMenuContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, variant, size, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <ContextMenuPortal>
    <ContextMenuContent
      :class="{
        'ui-menu-content-dark': variant === 'dark',
        'ui-menu-content-light': variant === 'light',
        'ui-menu-content-sm': size === 'sm',
        'ui-menu-content-lg': size === 'lg',
      }"
      class="ui-menu-content ui-animate ui-animate-out fw-medium rounded-sm border px-8 py-12 text-sm shadow"
      v-bind="forwarded"
    >
      <slot />
    </ContextMenuContent>
  </ContextMenuPortal>
</template>
