<script lang="ts" setup>
import type { SitesRequestOptions } from '@/api/services/organization/OrganizationService';

import type { Sites } from '@/types/models/site/Sites';
import api from '@/api';
import useAppStore from '@/stores/appStore';

import { SiteStatusErrorEnumArray } from '@/types/SiteStatusOwnEnum';

import { CollapsibleRoot } from 'radix-vue';
import { computed, ref, watch } from 'vue';

defineOptions({
  name: 'DashboardWidgetDisconnectedAlert',
});

const props = withDefaults(
  defineProps<{
    sitesWithoutConnectionCount: number
    teamId?: string | number | null
  }>(),
  {
    sitesWithoutConnectionCount: 0,
    teamId: null,
  },
);

const appStore = useAppStore();

const isDark = computed(() => appStore.darkMode);

const localSitesWithoutConnectionCount = ref<number | null>(null);

const loadingSitesWithoutConnection = ref(true);
const canReloadData = ref(true);

const sitesWithoutConnectionList = ref<Sites | null>(null);

const open = ref(false);

watch(
  () => props.sitesWithoutConnectionCount,
  (newValue) => {
    if (newValue !== null) {
      localSitesWithoutConnectionCount.value = newValue;
    }
  },
);

const isRequestingData = ref(false);

/**
 * If meta retrieves any disconnected site, calls all disconnected sites data on click to avoid unnecessary calls
 */
async function loadDisconnectedSites(forceLoader: boolean) {
  if (isRequestingData.value)
    return;
  try {
    if (forceLoader) {
      loadingSitesWithoutConnection.value = true;
    }

    isRequestingData.value = true;

    if (loadingSitesWithoutConnection.value || canReloadData.value) {
      const params: SitesRequestOptions = {
        connection_status: [...SiteStatusErrorEnumArray],
        page: 1,
        // FIXME This should be dynamic based on meta and requesting more when lazy loading or lazy loaded
        per_page: 50,
        filters: {
          include: ['team'],
        },
        options: {
          cancellable: false,
        },
      };

      if (!!props.teamId) {
        params.team = [Number(props.teamId)];
      }
      const response = await api.organization.general.sites(params);

      if (!response.data) {
        return;
      }
      sitesWithoutConnectionList.value = response.data;

      localSitesWithoutConnectionCount.value = response.data.length || 0;
    }
  } catch (e) {
    console.error(e);
  } finally {
    isRequestingData.value = false;
    loadingSitesWithoutConnection.value = false;
    canReloadData.value = false;
  }
}

watch(
  () => props.sitesWithoutConnectionCount,
  (newValue) => {
    if (newValue) {
      open.value = false;
      localSitesWithoutConnectionCount.value = newValue;
      canReloadData.value = true;
      sitesWithoutConnectionList.value = null;
    }
  },
);

defineExpose({
  loadDisconnectedSites,
});
</script>

<template>
  <v-alert
    v-if="!!localSitesWithoutConnectionCount && localSitesWithoutConnectionCount > 0"
    :show-icon="false"
    classes="sites-widget border-1 border-danger rounded-xs border p-0 mb-8"
    content-spacing="p-0"
    variant="danger"
  >
    <CollapsibleRoot id="disconnectedSites" v-model:open="open" @update:open="loadDisconnectedSites(false)">
      <CollapsibleTrigger
        class="btn btn-transparent d-flex align-items-center justify-content-between fw-medium rounded-xs w-100 text-danger-dark px-16 py-8 text-sm shadow-none"
        @focusin="loadDisconnectedSites(false)"
        @mouseenter="loadDisconnectedSites(false)"
      >
        <span class="d-flex align-items-center text-start">
          <v-icon class="me-8" icon="filled-alert-info" size="xl" />

          {{ $t('dashboard.connectionLost', localSitesWithoutConnectionCount) }}
        </span>

        <span class="ms-auto text-nowrap">
          {{ $t('site.shared.seeDetail') }}
        </span>

        <v-icon :class="{ open }" class="ms-8" icon="arrow-caret-down" size="2xs" />
      </CollapsibleTrigger>

      <CollapsibleContent class="px-16">
        <ul
          v-if="!loadingSitesWithoutConnection && !!sitesWithoutConnectionList && sitesWithoutConnectionList.length > 0"
          class="ps-48 pt-8 text-sm"
        >
          <li v-for="site in sitesWithoutConnectionList" :key="site.id" class="text-danger-dark p-0">
            <router-link
              :to="{
                name: 'sites.site.connect',
                params: { site: site.slug },
              }"
              class="btn btn-xs btn-icon text-decoration-underline text-danger-dark p-4"
            >
              {{ site.name }}
            </router-link>
          </li>
        </ul>

        <ul v-else class="ps-24 pt-8" :class="[{ 'text-light': isDark }]">
          <li v-for="n in sitesWithoutConnectionCount" :key="n" class="placeholder-glow" style="height: 26px">
            <span class="placeholder mb-4 text-gray-900" style="width: 150px" />
          </li>
        </ul>
      </CollapsibleContent>
    </CollapsibleRoot>
  </v-alert>
</template>

<style lang="scss" scoped>
.sites-widget {
  min-height: 2.5rem;
}
</style>
