import type { ManagerSitesCount } from '@/api/services/organization/manager-status/ManagerStatusService';
import type { Organization } from '@/types/models/organization/Organization';
import type { ComputedRef, Ref } from 'vue';
import { ref } from 'vue';

import api from '@/api';

import useConstantsHelper from '@/resources/constants/ConstantsHelper';
import { defineStore } from 'pinia';
import useSocketStore from '@/stores/socketStore';

interface StorageValueFormat {
  value: number
  format: string
}

interface OrganizationStorage {
  used: StorageValueFormat
  total: StorageValueFormat
}

interface SiteItems {
  total: StorageValueFormat
}

export interface IFaq {
  title: string
  content: string
}

export interface IOpinion {
  name: string
  opinion: string
  image?: string
  src?: string
  srcSet?: string
}

interface StoreProducts {
  pro: {
    monthly: {
      value: number
      format: string
    }
    yearly: {
      value: number
      format: string
    }
  }
}

interface IOrganizationStore {
  organization: Ref<Organization | null>

  storage: Ref<OrganizationStorage | null>
  sitesLimit: Ref<ManagerSitesCount | null>
  siteItems: Ref<SiteItems | null>

  faqs: Ref<IFaq[] | null>
  opinions: Ref<IOpinion[] | null>
  prices: Ref<StoreProducts>

  reset: () => void
  manager: () => void
  loadFaqsAndOpinions: () => void
  init: () => void

  sitesUsed: ComputedRef<number | undefined>
  sitesTotal: ComputedRef<number | undefined>
  sitesLeft: ComputedRef<number | undefined>
  storagePercentage: ComputedRef<number>
  storagePercentageLeft: ComputedRef<number>
}

const useOrganizationStore = defineStore('organization', (): IOrganizationStore => {
  const organization: Ref<Organization | null> = ref(null);

  const storage: Ref<OrganizationStorage | null> = ref(null);
  const sitesLimit: Ref<ManagerSitesCount | null> = ref(null);
  const siteItems: Ref<SiteItems | null> = ref(null);

  const faqs: Ref<IFaq[] | null> = ref(null);
  const opinions: Ref<IOpinion[] | null> = ref(null);
  const prices: Ref<StoreProducts> = ref({
    pro: {
      monthly: {
        value: 34,
        format: '34,00',
      },
      yearly: {
        value: 29,
        format: '29,00',
      },
    },
  });

  const reset = () => {
    organization.value = null;
    storage.value = null;
    sitesLimit.value = null;
    siteItems.value = null;
    faqs.value = null;
    opinions.value = null;
  };

  const { locale } = useI18n();

  const connectOrganizationSocket = () => {
    if (!!organization.value) {
      const socketStore = useSocketStore();

      socketStore.subscribeOrganization(organization.value.id);
    }
  }

  const init = async () => {
    try {
      organization.value = await api.organization.general.retrieve();
    } catch (e: any) {
      console.error('Could not load organization', e)
    } finally {
      connectOrganizationSocket();
    }
  }

  const manager = async () => {
    const data = await api.organization.managerStatus.retrieve();

    storage.value = data.storage;
    sitesLimit.value = data.sites;
    siteItems.value = data.siteItems;
  };

  const loadFaqsAndOpinions = async () => {
    if (!faqs.value || !opinions.value) {
      const dataToRequest = [];

      if (!faqs.value) {
        dataToRequest.push('faqs');
      }
      if (!opinions.value) {
        dataToRequest.push('opinions');
      }

      const { faqs: constantFaqs, opinions: constantOpinions } = useConstantsHelper();

      let validOpinions = constantOpinions;

      if (locale.value === 'en') {
        // Due to Marta Torre wrote about Spanish tool
        validOpinions = validOpinions.filter(op => op.showOnEnglish);
      }

      faqs.value = constantFaqs as IFaq[];
      opinions.value = validOpinions as IOpinion[];
    }
  };

  const sitesUsed = computed(() => sitesLimit.value?.used?.value);
  const sitesTotal = computed(() => sitesLimit.value?.total?.value);
  const sitesLeft = computed(() => {
    if (sitesLimit.value) {
      return sitesLimit.value.total.value - sitesLimit.value.used.value;
    }

    return 0;
  });

  const storagePercentage = computed(() => {
    let stg = 0;

    if (!!storage.value && storage.value.total.value !== 0) {
      stg = 100 - ((storage.value.total.value - storage.value.used.value) / storage.value.total.value) * 100;
    }

    return +stg.toFixed(2);
  });

  const storagePercentageLeft = computed(() => {
    if (storage.value && storage.value.total.value !== 0) {
      const storagePercentage
        = 100 - ((storage.value.total.value - storage.value.used.value) / storage.value.total.value) * 100;
      return +(100 - storagePercentage).toFixed(2);
    }

    return 0;
  });

  return {
    organization,

    storage,
    sitesLimit,
    siteItems,
    faqs,
    opinions,
    prices,

    sitesUsed,
    sitesTotal,
    sitesLeft,
    storagePercentage,
    storagePercentageLeft,

    init,
    reset,
    manager,
    loadFaqsAndOpinions,
  };
});

export { useOrganizationStore };
export default useOrganizationStore;
