import type { Session } from '@/types/models/user/sessions/Session';
import type { User } from '@/types/models/user/User';
import { AbstractService } from '@modular/sdk';
import { apiEndpoints } from '@/types/routes/routes';

export default class UserSessionsService extends AbstractService {
  /**
   * Retrieves the Browser Sessions of the authenticated user.
   *
   * @returns {Promise<Session[]>}
   */
  public all(): Promise<Session[]> {
    return this.request({
      method: apiEndpoints.browserSessionsIndex.method,
      url: this.buildPath(apiEndpoints.browserSessionsIndex),
    });
  }

  /**
   * Logs out the user from all Other Browser Sessions.
   *
   * @returns {Promise<User>}
   */
  public delete(): Promise<User> {
    return this.request({
      method: apiEndpoints.browserSessionsDestroy.method,
      url: this.buildPath(apiEndpoints.browserSessionsDestroy),
    });
  }
}
