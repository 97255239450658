<script lang="ts">
import type { RouteRecord } from 'vue-router';
import TheHeader from '@/components/global/header/TheHeader.vue';
import TheMenu from '@/components/global/menu/TheMenu.vue';

import TheCountdown from '@/components/global/TheCountdown.vue';

import TheDiscountPopup from '@/components/global/TheDiscountPopup.vue';
import TheEmailVerifyAlert from '@/components/global/TheEmailVerifyAlert.vue';
import VSiteCreate from '@/components/site/create/VSiteCreate.vue';
import VTeamCreate from '@/components/team/VTeamCreate.vue';
import { useSiteCreate } from '@/composables/site/useSiteCreate';

import usePermissions from '@/composables/usePermissions';
import useConstantsHelper from '@/resources/constants/ConstantsHelper';

import useBillingStore from '@/stores/billingStore';
import { useDownloadStore } from '@/stores/downloadStore';
import useOrganizationStore from '@/stores/organizationStore';
import { useUserStore } from '@/stores/userStore';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from 'vue';
import useSocketStore from '@/stores/socketStore';

export default defineComponent({
  name: 'VLayout',
  components: {
    VSiteCreate,
    VTeamCreate,
    TheDiscountPopup,
    TheCountdown,
    TheMenu,
    TheHeader,
    TheEmailVerifyAlert,
  },
  props: {
    team: {
      type: String,
      required: false,
      default: '',
    },
    site: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const { can } = usePermissions();
    const { stringConstant } = useConstantsHelper();
    const userStore = useUserStore();

    const billingStore = useBillingStore();
    const { planSubscription, loadedSubscriptions } = storeToRefs(billingStore);

    const arePermissionsLoaded = computed(() => Object.keys(userStore.permissions).length > 0 || false);

    const isCountdownModeActive = computed(() => import.meta.env.VITE_COUNTDOWN_MODE === 'true' || false);

    const isServerErrorMode = computed(() => import.meta.env.VITE_SERVER_ERROR_MODE === 'true' || false);

    const socketStore = useSocketStore();
    const disconnectedFromSockets = computed(() => socketStore.forcedDisconnection || false);

    const isMaintenanceModeActive = computed(() => import.meta.env.VITE_MAINTENANCE_MODE === 'true' || false);

    const isAFreeUser = computed(
      () => arePermissionsLoaded.value && loadedSubscriptions.value && !planSubscription.value,
    );

    const specialDates = {
      start: DateTime.utc(2024, 6, 22, 23, 0, 0),
      end: DateTime.utc(2024, 6, 28, 22, 59, 59),
    };

    const { locale } = useI18n();

    const shouldShowBannerAndCountdown = computed(
      () => isCountdownModeActive.value && isAFreeUser.value && locale.value === 'es',
    );

    const isDevelopmentModeActive = computed(
      () =>
        import.meta.env.VITE_NODE_ENV === 'development' && (import.meta.env.VITE_DEVELOPMENT_MODE === 'true' || false),
    );

    const layoutAlertActive = computed(() => isDevelopmentModeActive.value || shouldShowBannerAndCountdown.value || isServerErrorMode.value || isMaintenanceModeActive.value || disconnectedFromSockets.value);

    const {
      isSupported,
      data,
    } = useBroadcastChannel({ name: 'change-language' });

    watch(data, async (newVal, oldVal) => {
      if (isSupported && newVal !== oldVal)
        await userStore.refreshUser();
    });

    const createSiteTitle = ref('site.create.new');
    const createSiteLegend = ref('site.create.title');

    const organizationStore = useOrganizationStore();

    // Create first site for User
    // TODO Move this to another place
    const {
      handleCreateSite,
    } = useSiteCreate();

    const isFirstLogin = computed(() => userStore.isFirstLogin);
    const sitesLimit = computed(() => organizationStore.sitesLimit);

    watch(
      [isFirstLogin, sitesLimit],
      ([newValFirstLogin], [sitesLimitLoaded]) => {
        if (newValFirstLogin && !!sitesLimitLoaded) {
          createSiteTitle.value = 'dashboard.emptyState.default.firstLoginSite';
          createSiteLegend.value = 'dashboard.emptyState.default.firstLoginSiteTitle';

          handleCreateSite();

          userStore.setFirstLogin(false);
        }
      },
      {
        immediate: true,
      },
    );

    const showBanner = ref(false);

    const reload = () => {
      window.location.reload();
    };

    return {
      can,
      stringConstant,
      showBanner,
      shouldShowBannerAndCountdown,
      isDevelopmentModeActive,
      isCountdownModeActive,
      isServerErrorMode,
      disconnectedFromSockets,
      layoutAlertActive,
      isAFreeUser,
      specialDates,
      arePermissionsLoaded,
      loadedSubscriptions,
      createSiteTitle,
      createSiteLegend,
      isMaintenanceModeActive,

      socketStore,
      reload,
    };
  },
  computed: {
    ...mapState(useDownloadStore, ['isDownloading']),
    ...mapState(useUserStore, ['userLogged']),
    currentRouteSidebar() {
      const currentRoute = this.$route.matched.find((route: RouteRecord) => route.name === this.$route.name);

      if (!!currentRoute) {
        return currentRoute.components?.sidebar;
      }

      return null;
    },
  },
});
</script>

<template>
  <div
    id="layout"
    :class="{ 'layout--alert': layoutAlertActive }"
  >
    <TheCountdown
      v-if="isCountdownModeActive && shouldShowBannerAndCountdown"
      :enabled="isCountdownModeActive && isAFreeUser"
      :special-dates="specialDates"
      @open-modal="showBanner = true"
    />

    <div v-else-if="isMaintenanceModeActive" id="layout-alert" class="development-mode">
      <div class="alert alert-danger d-flex justify-content-center align-items-center h-100 rounded-0 p-8">
        <v-icon class="text-danger rotate-180" icon="circle-actions-alert-info" space="me-8" />
        <span class="fw-semi text-sm">
          {{ $t('general.maintenanceMode') }}
        </span>
      </div>
    </div>

    <div v-else-if="isDevelopmentModeActive" id="layout-alert" class="development-mode">
      <div class="alert alert-danger d-flex justify-content-center align-items-center h-100 rounded-0 p-8">
        <v-icon class="text-danger" icon="circle-actions-alert-info" space="me-8" />
        <span class="fw-semi text-sm">Estás en modo pre-producción. ¡It's not a bug, it's a Feature! 🚧🔬</span>
      </div>
    </div>

    <div v-else-if="isServerErrorMode" id="layout-alert" class="development-mode">
      <div class="alert text-bg-warning d-flex justify-content-center align-items-center h-100 rounded-0 p-8">
        <v-icon icon="circle-actions-alert-info" space="me-8" />
        <span class="fw-semi text-sm">{{ $t('general.serverDown') }}</span>
      </div>
    </div>

    <div v-else-if="disconnectedFromSockets" id="layout-alert" class="development-mode">
      <div class="alert text-bg-warning d-flex justify-content-center align-items-center h-100 rounded-0 p-8">
        <v-icon icon="circle-actions-alert-info" space="me-8" />
        <span class="fw-semi text-sm me-16">{{ $t('general.disconnectedSocket') }}</span>

        <VButton class="px-12" size="2xs" variant="dark" @click="reload()">
          {{ $t('manager.reloadData') }}
          <MIcon class="ms-4" icon="refresh" />
        </VButton>

        <VButton
          class="position-absolute end-0"
          size="sm"
          variant="transparent"
          @click="socketStore.forcedDisconnection = false"
        >
          <MIcon class="fw-medium" icon="close-x" size="md" />
        </VButton>
      </div>
    </div>

    <TheMenu>
      <template #menu />
    </TheMenu>

    <main id="main-container">
      <TheHeader>
        <router-view v-slot="{ Component }" name="headerContent">
          <component :is="Component" />
        </router-view>
      </TheHeader>

      <div v-if="!!userLogged" id="main-content" class="container-fluid">
        <router-view v-slot="{ Component }">
          <component :is="Component">
            <template #sidebar="props">
              <component :is="currentRouteSidebar" :props="props" :site="site" :team="team" />
            </template>
          </component>
        </router-view>
      </div>
    </main>

    <TheEmailVerifyAlert />

    <TheDiscountPopup
      v-if="isCountdownModeActive && shouldShowBannerAndCountdown"
      v-model:show-banner="showBanner"
      :enabled="isCountdownModeActive && isAFreeUser"
      :special-dates="specialDates"
    />

    <the-downloader v-if="isDownloading" />

    <VSiteCreate :form-legend="createSiteLegend" :title="createSiteTitle" />

    <VTeamCreate />
  </div>
</template>
