<script lang="ts" setup>
import api from '@/api';
import { computed } from 'vue';

defineOptions({
  name: 'DashboardWidgetReview',
});

const isReviewBannerEnabled = import.meta.env.VITE_BANNER_REVIEW === 'true';

const dashboardConfig = useStorage('dashboard-config', {
  showReviewBannerDate: null as string | null,
});

const showReviewBanner = computed(() => {
  if (!isReviewBannerEnabled)
    return false;

  const lastSeenDateStr = dashboardConfig.value.showReviewBannerDate;

  if (!lastSeenDateStr)
    return true;

  const lastSeenDate = new Date(lastSeenDateStr);
  const now = new Date();

  const diffInDays = (now.getTime() - lastSeenDate.getTime()) / (1000 * 60 * 60 * 24);
  return diffInDays > 7;
});

function closeBanner() {
  dashboardConfig.value.showReviewBannerDate = new Date().toISOString();
}

function handleSendEvent() {
  api.mixpanel.track('Leave review', {
    location: 'Banner',
  });
}
</script>

<template>
  <div v-auto-animate>
    <div
      v-if="showReviewBanner"
      id="dashboard-review-us"
      class="position-relative overflow-hidden flex-column text-decoration-none rounded-sm w-100 p-16 mb-16"
    >
      <h4 class="h5 position-relative z-1 mb-8">
        {{ $t('dashboard.reviewBanner.title') }}
      </h4>

      <div class="d-flex align-items-center justify-content-between gap-8">
        <p class="text-sm text-pretty fw-medium mb-0">
          {{ $t('dashboard.reviewBanner.text') }}
        </p>

        <span class="decoration decoration-triangle text-info position-absolute" />

        <a
          class="btn btn-primary text-nowrap"
          :href="$t('dashboard.reviewBanner.link')"
          rel="noreferrer"
          target="_blank"
          @click="handleSendEvent()"
        >
          {{ $t('general.header.reviewUs') }}
        </a>
      </div>

      <VButton
        id="dashboard-review-us-close"
        class="position-absolute text-xs fw-medium z-2"
        variant="link"
        @click="showReviewBanner = false; closeBanner()"
      >
        {{ $t('general.button.close') }}
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#dashboard-review-us {
  background-color: var(--md-cyan);

  .decoration-triangle {
    font-size: 48px;
    top: 0;
    right: 40%;
    transform: translateY(-50%) rotate(45deg);
    transition: transform 0.6s ease-in-out;
    animation: spin 10s linear infinite;
    animation-play-state: paused;

    @media (width < 1366px) {
      right: 30%;
    }

    @media (width < 1199px) {
      right: 20%;
    }
  }

  &:hover .decoration-triangle {
    animation-play-state: running;

    @media (prefers-reduced-motion: reduce) {
      animation-play-state: paused !important;
    }
  }

  #dashboard-review-us-close {
    top: 0.75rem;
    right: 1rem;
  }
}
</style>
