import type { ISitemetricsStoreState } from '@/stores/site/metrics/index';
import type { SiteAnalyticsReport } from '@/types/models/site/service/analytics/SiteAnalyticsReport';
import type { SiteServiceAnalytics } from '@/types/models/site/service/analytics/SiteServiceAnalytics';
import type {
  ISearchConsoleReport,
  ISiteServiceSearchConsole,
} from '@/types/models/site/service/search-console/ISiteServiceSearchConsole';
import useSiteStore from '@/stores/site/siteStore';
import { ServiceTypeEnumConst } from '@/types/enums/ServiceTypeEnum';

import { SiteServiceStatusEnumConst } from '@/types/enums/SiteServiceStatusEnum';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const useMetricsStore = defineStore('siteAnalytics', (): ISitemetricsStoreState => {
  const loadedAnalytics = ref(false);
  const loadedAnalyticsReport = ref(false);

  const analyticsService = ref<SiteServiceAnalytics | null>(null);
  const analyticsReport = ref<SiteAnalyticsReport | null>(null);

  const loadedSearchConsole = ref(false);
  const loadedSearchConsoleReport = ref(false);

  const searchConsoleService = ref<ISiteServiceSearchConsole | null>(null);
  const searchConsoleReport = ref<ISearchConsoleReport | null>(null);

  const siteStore = useSiteStore();

  const siteServicesAnalyticsService = computed(
    () => (siteStore.siteServices?.find(service => service.type === ServiceTypeEnumConst.ANALYTICS)),
  );

  const analyticsServiceId = computed(() => (siteServicesAnalyticsService.value?.id));

  const analyticsIsEnabled = computed(
    () => (
      siteServicesAnalyticsService.value?.isActive || analyticsService.value?.status === SiteServiceStatusEnumConst.ACTIVE
    ),
  );

  const siteServicesSearchConsoleService = computed(
    () => (siteStore.siteServices?.find(service => service.type === ServiceTypeEnumConst.SEARCH_CONSOLE)),
  );

  const searchConsoleServiceId = computed(
    () => (siteServicesSearchConsoleService.value?.id
    ),
  );

  const searchConsoleIsEnabled = computed(
    () => (
      siteServicesSearchConsoleService.value?.isActive || searchConsoleService.value?.status === SiteServiceStatusEnumConst.ACTIVE
    ),
  );

  const clearAnalyticsReport = () => {
    analyticsReport.value = null;
  };

  const clearAnalytics = () => {
    analyticsService.value = null;
    clearAnalyticsReport();
  };

  const clearSearchConsoleReport = () => {
    searchConsoleReport.value = null;
  };

  const clearSearchConsole = () => {
    searchConsoleService.value = null;
    clearSearchConsoleReport();
  };

  const reset = () => {
    clearAnalytics();
    clearSearchConsole();

    loadedAnalytics.value = false;
    loadedAnalyticsReport.value = false;

    loadedSearchConsole.value = false;
    loadedSearchConsoleReport.value = false;
  };

  return {
    loadedAnalytics,
    loadedAnalyticsReport,
    analyticsService,
    analyticsReport,

    analyticsIsEnabled,
    analyticsServiceId,

    loadedSearchConsole,
    loadedSearchConsoleReport,
    searchConsoleService,
    searchConsoleReport,

    searchConsoleServiceId,
    searchConsoleIsEnabled,

    clearAnalyticsReport,
    clearAnalytics,
    reset,
  };
});

export { useMetricsStore };

export default useMetricsStore;
