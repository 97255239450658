export default {
  toolbar: {
    bold: 'Negrita',
    italic: 'Cursiva',
    underline: 'Subrayar',
    strike: 'Tachado',
    blockquote: 'Cita',
    code: 'Código',
    codeBlock: 'Bloque de código',
    link: 'Insertar/editar un enlace',
    orderedList: 'Lista numerada',
    bulletList: 'Lista con viñetas',
    alignLeft: 'Alinear a la izquierda',
    alignCenter: 'Alinear al centro',
    alignRight: 'Alinear a la derecha',
    alignJustify: 'Justificar',
    setUrl: 'Ingresar URL',
    clean: 'Borrar formato',
  },
};
