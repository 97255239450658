import { Concept } from '@/types/models/Concept';

export type ComponentRequestOnlyEnumKeys = 'updatable' | 'updated' | 'hidden' | 'visible' | 'hasVulnerabilities' | 'siteConnected';

export const ComponentRequestOnlyEnumConst = {
  UPDATABLE: 'updatable',
  UPDATED: 'updated',
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
  HAS_VULNERABILITIES: 'hasVulnerabilities',
  SITE_CONNECTED: 'siteConnected',
} as const;

export const ComponentRequestOnlyEnum: Record<ComponentRequestOnlyEnumKeys, Concept> = {
  'updatable': {
    key: 'updatable',
    value: null,
    name: 'concept.component.request.only.updatableName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.only'
  },
  'updated': {
    key: 'updated',
    value: null,
    name: 'concept.component.request.only.updatedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.only'
  },
  'hidden': {
    key: 'hidden',
    value: null,
    name: 'concept.component.request.only.hiddenName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.only'
  },
  'visible': {
    key: 'visible',
    value: null,
    name: 'concept.component.request.only.visibleName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.only'
  },
  'hasVulnerabilities': {
    key: 'hasVulnerabilities',
    value: null,
    name: 'concept.component.request.only.hasVulnerabilitiesName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.only'
  },
  'siteConnected': {
    key: 'siteConnected',
    value: null,
    name: 'concept.component.request.only.siteConnectedName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.only'
  }
} as const;

export type ComponentRequestOnlyEnumType = (typeof ComponentRequestOnlyEnumConst)[keyof typeof ComponentRequestOnlyEnumConst];

export const ComponentRequestOnlyEnumArray = Object.values(ComponentRequestOnlyEnum);
