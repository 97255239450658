import { apiEndpoints } from '@/types/routes/routes';
import { AbstractService } from '@modular/sdk';

export default class SubscriptionManageService extends AbstractService {
  /**
   * Returns a URL for the billing portal to update the specified subscription.
   *
   * @param {string} product
   * @param {string} price
   * @returns {Promise<string>}
   */
  public update(product: string, price: string): Promise<string> {
    return this.request({
      method: apiEndpoints.subscriptionsManageUpdate.method,
      url: this.buildPath(apiEndpoints.subscriptionsManageUpdate),
      params: {
        product,
        price,
      },
    });
  }

  /**
   * Returns a URL for the billing portal to update the payment method of the organization.
   *
   * @returns {Promise<string>}
   */
  public paymentMethod(): Promise<string> {
    return this.request({
      method: apiEndpoints.subscriptionsManagePaymentMethod.method,
      url: this.buildPath(apiEndpoints.subscriptionsManagePaymentMethod),
    });
  }

  /**
   * Returns a URL for the billing portal to cancel the specified subscription.
   *
   * @param {number} subscription
   * @returns {Promise<string>}
   */
  public cancel(subscription: number): Promise<string> {
    return this.request({
      method: apiEndpoints.subscriptionsManageCancel.method,
      url: this.buildPath(apiEndpoints.subscriptionsManageCancel),
      params: {
        subscription,
      },
    });
  }

  /**
   * Resumes the specified subscription.
   *
   * @param {number} subscription
   * @returns {Promise<any>}
   */
  public resume(subscription: number): Promise<any> {
    return this.request({
      method: apiEndpoints.subscriptionsManageResume.method,
      url: this.buildPath(apiEndpoints.subscriptionsManageResume),
      params: {
        subscription,
      },
    });
  }
}
