<script lang="ts" setup>
import VBackupSidebar from '@/components/global/sidebar/VBackupSidebar.vue';

import VOffer from '@/components/global/sidebar/VOffer.vue';
import VRightSidebar from '@/components/global/sidebar/VRightSidebar.vue';
import VTrustedPerson from '@/components/global/sidebar/VTrustedPerson.vue';
import usePermissions from '@/composables/usePermissions';
import { PermissionEnumConst } from '@/types/PermissionEnum';

defineOptions({
  name: 'TheTeamSidebar',
});

const { can } = usePermissions();

const userCanUpdateTeam = computed(() => can(PermissionEnumConst.TEAMS_UPDATE));
</script>

<template>
  <VRightSidebar>
    <div class="w-100 mb-16 ms-auto pt-4">
      <router-link
        :class="{ disabled: !userCanUpdateTeam }"
        :to="{ name: userCanUpdateTeam ? 'team.update' : '' }"
        class="btn btn-link btn-icon fw-semi border-0 text-sm"
        replace
        data-cy="edit-team-button"
      >
        <span class="icon icon-pencil me-8 text-lg" />
        <span>{{ $t('team.shared.edit') }}</span>
      </router-link>
    </div>

    <VTrustedPerson />

    <VBackupSidebar />

    <VOffer />
  </VRightSidebar>
</template>
