<script lang="ts" setup>
import { ProgressIndicator, ProgressRoot, type ProgressRootProps } from 'radix-vue';

import { computed, type HTMLAttributes } from 'vue';

type ProgressVariant = 'primary' | 'primary-dark' | 'secondary';

const props = withDefaults(
  defineProps<
    ProgressRootProps & {
      class?: HTMLAttributes['class']
      variant?: ProgressVariant
      lightining?: boolean
    }
  >(),
  {
    modelValue: 0,
    variant: 'primary',
    lightining: false,
  },
);

const delegatedProps = computed(() => {
  const { class: _, lightining, ...delegated } = props;

  return delegated;
});

const variantClass = computed(() => {
  return `ui-progress-${props.variant}`;
});
</script>

<template>
  <ProgressRoot
    class="ui-progress position-relative rounded-pill h-2 w-full overflow-hidden"
    :class="
      [
        variantClass,
        props.class,
      ]
    "
    v-bind="delegatedProps"
  >
    <ProgressIndicator
      class="ui-progress-indicator h-100 w-100 d-flex"
      :class="[
        variantClass,
        { 'ui-progress-lightning': lightining },
      ]"
      :style="`transform: translateX(-${100 - (props.modelValue ?? 0)}%);`"
    />
  </ProgressRoot>
</template>

<style lang="scss" scoped>
.ui-progress {
  height: .5rem;
  width: 100%;
  --ui-pogress-bg-opacity: 0.4;
  --ui-pogress-bg: var(--md-primary-rgb);
  --ui-progress-indicator-bg: var(--md-primary);

  background-color: rgba(var(--ui-pogress-bg), var(--ui-pogress-bg-opacity));

  @keyframes lightning {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  .ui-progress-indicator {
    --md-bg-opacity: 1;
    transition: all 0.2s ease-out;
    border-radius: .25rem;

    background-color: var(--ui-progress-indicator-bg);

    &.ui-progress-lightning {
      background-image: linear-gradient(
          120deg,
          rgba(255, 255, 255, 0.1) 30%,
          rgba(255, 255, 255, 0.4) 50%,
          rgba(255, 255, 255, 0.1) 70%
      );
      background-size: 200% 100%;
      animation: lightning 3s infinite linear;
    }
  }

  &.ui-progress-secondary {
    --ui-pogress-bg: var(--md-secondary-rgb);
    --ui-progress-indicator-bg: var(--md-secondary);
  }
}
</style>
