import { Concept } from '@/types/models/Concept';

export type ComponentRequestIncludeEnumKeys = 'site' | 'vulnerabilities' | 'site_items_count' | 'min_version' | 'new_version' | 'updatable' | 'processing' | 'hidden' | 'status';

export const ComponentRequestIncludeEnumConst = {
  SITE: 'site',
  VULNERABILITIES: 'vulnerabilities',
  SITE_ITEMS_COUNT: 'site_items_count',
  MIN_VERSION: 'min_version',
  NEW_VERSION: 'new_version',
  UPDATABLE: 'updatable',
  PROCESSING: 'processing',
  HIDDEN: 'hidden',
  HAS_INACTIVE: 'status',
} as const;

export const ComponentRequestIncludeEnum: Record<ComponentRequestIncludeEnumKeys, Concept> = {
  'site': {
    key: 'site',
    value: null,
    name: 'concept.component.request.include.siteName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'vulnerabilities': {
    key: 'vulnerabilities',
    value: null,
    name: 'concept.component.request.include.vulnerabilitiesName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'site_items_count': {
    key: 'site_items_count',
    value: null,
    name: 'concept.component.request.include.siteItemsCountName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'min_version': {
    key: 'min_version',
    value: null,
    name: 'concept.component.request.include.minVersionName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'new_version': {
    key: 'new_version',
    value: null,
    name: 'concept.component.request.include.newVersionName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'updatable': {
    key: 'updatable',
    value: null,
    name: 'concept.component.request.include.updatableName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'processing': {
    key: 'processing',
    value: null,
    name: 'concept.component.request.include.processingName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'hidden': {
    key: 'hidden',
    value: null,
    name: 'concept.component.request.include.hiddenName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  },
  'status': {
    key: 'status',
    value: null,
    name: 'concept.component.request.include.statusName',
    shortName: null,
    description: null,
    class: null,
    color: null,
    icon: null,
    permission: null,
    type: 'component.request.include'
  }
} as const;

export type ComponentRequestIncludeEnumType = (typeof ComponentRequestIncludeEnumConst)[keyof typeof ComponentRequestIncludeEnumConst];

export const ComponentRequestIncludeEnumArray = Object.values(ComponentRequestIncludeEnum);
